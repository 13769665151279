<div class="mb-6" style="min-height: 90vh;" >	

	<div class="container" id="mobile-filters">
		<div class="row input-fields">
			<div class="col-12">
				<div class="mb-4" *ngIf="searchInput != '' && hasSearched == true">
					<a mat-button class="fs-5 fw-semibold link-secondary" (click)="searchInput = '';resetFilter('')"><i class="fas fa-chevron-left fa-fw me-1"></i>Search results for {{searchedValue}}</a>
				</div>

				<mat-form-field [floatLabel]="'never'" class="form-search-input w-100 tealborder">
					<input 
						[(ngModel)]="searchInput" 
						matInput 
						placeholder="Search for deals" 
						type="search" 
						[matAutocomplete]="auto"
						(ngModelChange)="searchAutocomplete()"
						(keydown.enter)="changeModel()"/>
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeModel()">
						<mat-option class="suggestion-opt search-query"
							*ngFor="let option of querySuggestions"
							[value]="option.document.q">
							<span class="text-muted">{{ option.document.q }}</span>
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
		<div class="row mt-4 px-1">
			<div class="col-6 px-1">
				<!-- <div class="sort-select">
					<mat-form-field appearance="standard" [floatLabel]="'never'">
						<mat-label>Filters</mat-label>
						<mat-select data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters">
						</mat-select>
					</mat-form-field>
				</div> -->

				<div class="sort-select">
					<mat-form-field appearance="standard" [floatLabel]="'never'">
						<mat-label class="d-flex align-items-center" >
							<span>Filters  </span> 					
							<span  *ngIf="this.selectedParlons.length + this.selectedCategories.length + this.selectedLocations.length > 0" class="badge">{{this.selectedParlons.length + this.selectedCategories.length + this.selectedSubCategories.length + this.selectedLocations.length}}</span> 
					
						 </mat-label>
						<mat-select (click)="openDialog()" style="height: 25px;">		
												
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="col-6 px-1">
				<div class="sort-select ms-auto">
					<mat-form-field appearance="standard" [floatLabel]="'never'">
						<mat-label>Sort</mat-label>
						<mat-select (selectionChange)="sortByFilter($event.value)" style="height: 25px;">
							<mat-option [value]="option" *ngFor="let option of sort">{{ option.viewValue }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="row mt-5 px-1"  *ngIf="this.sortingType.value == 'location'">
			<div class="col-12">
				<mat-form-field [floatLabel]="'never'" class="form-search-input primary w-100">
					<span matPrefix><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
					<input matInput placeholder="Enter an address" type="text" 
						[(ngModel)]="addressInput" 
						[matAutocomplete]="autoAddress"
						(ngModelChange)="addressAutocomplete()"/>
					<mat-autocomplete #autoAddress="matAutocomplete">
						<mat-option class="suggestion-opt search-query"
							*ngFor="let option of addressSuggestions"
							(click)="selectAddress(option.location)"
							[value]="option.formattedAddress">
							<span class="text-muted">{{ option.formattedAddress }}</span>
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
		
	</div>
	<section class="s-parlon-deals-banner mb-6 d-none d-md-block" >
		<img src="{{ resourceLink + banner['deal_grid_list_banner'] }}?tr=w-2560" alt=""
			class="img-fluid img-hero-banner-desktop w-100">
		<img src="{{ resourceLink + banner['deal_grid_list_banner_tablet'] }}?tr=w-1200" alt=""
			class="img-fluid img-hero-banner-tablet w-100">
		<img src="{{ resourceLink + banner['deal_grid_list_banner_mobile'] }}" alt=""
			class="img-fluid img-hero-banner-mobile w-100">
	</section>
	<div ></div>
	<section #container  class="s-search-bar mb-5 d-none d-md-block" id="all-deals-section" >
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-md-4 d-none">
					<!-- Location Address input    here... -->
					<mat-form-field [floatLabel]="'never'" class="form-search-input w-100 tealborder">
						<span matPrefix><!--<i class="far fa-location-dot fa-fw text-body mx-4"></i>--></span>
						<input data-bs-toggle="modal" data-bs-target="#googleMap" (click)="googleMap()" id="mapSearch" matInput placeholder="Enter an address" type="text" [(ngModel)]="mapInput"/>
						<span matSuffix *ngIf="mapInput != ''" (click)="clearInput()"><i class="far fa-xmark"></i></span>
						<span matSuffix data-bs-toggle="modal" data-bs-target="#googleMap" (click)="googleMap()"><i style="color: #46b0a8" class="fal fa-map-location-dot" ></i></span>
					</mat-form-field>
				</div>
			</div>
		</div>
	</section>

	<!-- Map Modal Section -->
	<ngx-smart-modal [customClass]="'wide-modal-custom'" #mapModal identifier="mapModal" [dismissable] = "false" (onClose)="modalClose()">
		<ng-container>
			<div class="modal-body">
				<div class="modal-form-field mt-4 mb-3">
					<h2 class="h2 text-center mb-4">Select your exact location</h2>

					<mat-form-field [floatLabel]="'never'" class="form-search-input">
						<span matPrefix id="modalIcon"><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
						<input id="pickAdd" matInput placeholder="Search Location" type="text" [(ngModel)]="mapModalInput"/>
						<span matSuffix *ngIf="mapModalInput != ''" (click)="clearModalInput()"><i class="far fa-xmark"></i></span>
					</mat-form-field>
				</div>
				<div id="mapBody" class="w-100 h-80"></div>
				<div class="row justify-content-center">
					<button [disabled]="!isMarker" mat-flat-button id="selectLoc" (click)="selectLoc()" color="secondary" class="col-md-6 py-3">Use This Location</button>
				</div>
			</div>
		</ng-container>
	</ngx-smart-modal>

	<section class="s-main-content" >
		<div class="container">
			<div class="row flex-main-layout gx-md-4 gx-xl-6">
				<aside class="col-md-4 col-lg-3 d-none d-md-block ">
					<deal-filters
						[selectedParlons]="selectedParlons"
						[selectedCategories]="selectedCategories"
						[selectedLocations]="selectedLocations"
						[selectedSubCategories]="selectedSubCategories"
						[locationsV2]="locationsV2"
						[parlonsV2]="parlonsV2"
						[facet_locations]="facet_locations"
						[categoriesV2]="categoriesV2"
						(categoryEventV2)="getCategories($event)"
						(subCategoryEventV2)="getSubCategoriesV2($event)"
						(parlonEvent)="getParlonV2($event)"
						(locationEvent)="getLocation($event)"						
						(resetEvent)="resetFilter($event)"
						[dealsCategories]="dealsCategories"
						[filterByUrl]="filterUrl"></deal-filters>
				</aside>

				<main class="col-12 col-md-8 col-lg-9">
					<!-- navigation result controller -->
					<nav mat-tab-nav-bar class="nav-tab d-none" *ngIf="isMapHasLoc">
						<p mat-tab-link class="fs-5 fw-semibold mb-0" *ngFor="let link of navLinks" (click)="actNavLink = link; navResult(link)" [active]="actNavLink == link">{{link}}</p>
					</nav>
					
					<div class="mb-4 d-none d-md-block" *ngIf="searchedValue != '' && hasSearched == true">
						<a mat-button class="fs-5 fw-semibold link-secondary" (click)="searchInput = '';resetFilter('')"><i class="fas fa-chevron-left fa-fw me-1"></i>Search results for {{searchedValue}}</a>
					</div>
					

					<div class="row d-none d-md-flex">
						<div class="col-10">
							<mat-form-field [floatLabel]="'never'" class="form-search-input w-100 tealborder">
								<input 
									[(ngModel)]="searchInput" 
									matInput 
									placeholder="Search for deals" 
									type="search" 
									[matAutocomplete]="auto"
									(ngModelChange)="searchAutocomplete()"
									(keydown.enter)="changeModel()"/>
								<mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeModel()">
									<mat-option class="suggestion-opt search-query"
										*ngFor="let option of querySuggestions"
										[value]="option.document.q">
										<span class="text-muted">{{ option.document.q }}</span>
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</div>
						<div class="col-2">
							<button mat-flat-button color="secondary" (click)="changeModel()" class="w-100 p-0 h-100" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters"><span matSuffix><i style="color: #fff" class="fa-solid fa-magnifying-glass" ></i></span> Search</button>
						</div>
					</div>

					<div class="row justify-content-between mt-3 align-items-center d-none d-md-flex">
						<div class="col-auto">
							<p class="fs-5 fw-semibold mb-0 d-none" *ngIf="isMapHasLoc">{{(actNavLink == 'Around you') ? 'Showing deals near you' : 'All deals'}}</p>
							<p class="mb-0">{{totalRecords}} results</p>
						</div>

						<div class="col-auto">
							<!-- Sort dropdown here... -->
							<div class="d-flex">
								<div class="sort-select">
									<mat-form-field appearance="standard" [floatLabel]="'never'">
										<mat-label>Sort</mat-label>
										<mat-select (selectionChange)="sortByFilter($event.value)" #mySelect>
											<mat-option [value]="option" *ngFor="let option of sort">{{ option.viewValue }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
	
								<mat-form-field *ngIf="this.sortingType.value == 'location'" [floatLabel]="'never'" class="form-search-input address-input primary ms-5">
									<span matPrefix><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
									<input matInput placeholder="Enter an address" type="text" 
										[(ngModel)]="addressInput" 
										[matAutocomplete]="autoAddress"
										(ngModelChange)="addressAutocomplete()"/>
									<mat-autocomplete #autoAddress="matAutocomplete">
										<mat-option class="suggestion-opt search-query"
											*ngFor="let option of addressSuggestions"
											(click)="selectAddress(option.location)"
											[value]="option.formattedAddress">
											<span class="text-muted">{{ option.formattedAddress }}</span>
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</div>
					</div>

					<p class="mb-0 d-flex d-md-none">{{totalRecords}} results</p>

					<div  infiniteScroll
					[infiniteScrollDistance]="1"
					[infiniteScrollThrottle]="100"
					(scrolled)="dealScroll()" class="deal-grid-list">
						<div class="row row-cols-2 row-cols-md-2 row-cols-lg-3 gx-md-4 gx-xl-5 gy-6 mb-6 mt-2" *ngIf="initialLoad == true">
							<div class="col" *ngFor="let deal of deals$; index as i">
								<!-- <h1>hello</h1> -->
								<deal-card-template [position]="i+1" [queryID]="queryId" [userID]="userId" [isLoggedIn]="isLoggedIn"  [locations]="deal.locations" [data]="deal" [parlon_name]="deal.parlon_name" [selectedLocations]="selectedLocations" ></deal-card-template>
							</div>
							<div class="w-100" *ngIf="deals$.length == 0"><p class="text-center">No Results Found</p></div>
						</div>

						<!-- <div class="">
							<div class="">
							
								<div class=" d-flex justify-content-center">
									<button class="border" *ngIf="nbPages != deal_config.currentPage+1  && deals$.length>0 " (click)="showMoreDeals()" mat-raised-button>SHOW MORE</button>
								</div>

								<mat-spinner  *ngIf="isLoading && nbPages != deal_config.currentPage+1  && deals$.length>0"></mat-spinner>



								
							</div>
						</div> -->

						<div class="d-flex justify-content-center">
							<mat-spinner [color]="'accent'" [strokeWidth]="9" [diameter]="65"  *ngIf="isLoading && nbPages != deal_config.currentPage+1  && deals$.length>0" ></mat-spinner>	
							<!-- <mat-spinner [color]="'accent'"  *ngIf="isLoading && nbPages != deal_config.currentPage+1  && deals$.length>0"></mat-spinner>							  -->
						</div>						 
					</div>
				</main>
			</div>
		</div>
	</section>
</div>


<!-- Filter drawer (mobile only) -->
<!-- <div id="offcanvasFilters" class="offcanvas offcanvas-start" tabindex="-1">
	<div class="offcanvas-body offcanvas-body d-flex flex-column justify-content-between align-items-between">
		<deal-filters
			[selectedParlons]="selectedParlons"
			[selectedCategories]="selectedCategories"
			[locationsV2]="locationsV2"
			[parlonsV2]="parlonsV2"
			[categoriesV2]="categoriesV2"
			(categoryEventV2)="getCategoriesV2($event)"
			(subCategoryEventV2)="getSubCategoriesV2($event)"
			(parlonEvent)="getParlonV2($event)"
			(locationEvent)="getLocation($event)"			
			(resetEvent)="resetFilter($event)"
			[dealsCategories]="dealsCategories"
			[filterByUrl]="filterUrl">
		</deal-filters>
		<div>
			<button mat-flat-button color="secondary" class="w-100 py-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters">APPLY FILTERS</button>
		</div>
	</div>
</div> -->

<button id="toTop-button" (click)="goToTop()" class="d-flex align-items-center justify-content-center">
	<mat-icon size="18"> <mat-icon>keyboard_arrow_up</mat-icon> </mat-icon>
</button>
