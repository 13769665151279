<div class="card card-parlon-template bg-transparent">
	<div class="card-body pos-relative p-0">
		<a [routerLink]="['/parlon',data.slug]" class="stretched-link">
			<div class="ratio ratio-2x1 mb-3">
				<img src="{{getSalonImage(data)}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">

				<div class="parlon-logo">
					<div class="ratio ratio-1x1">
						<img src="{{ resource + data.logo }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
					</div>
				</div>
			</div>
		</a>

		<div>
			<p class="parlon-name fs-4 fw-medium w-80 w-md-100 mb-0">{{data.business_name}}</p>
			<p class="parlon-location fs-6 text-muted clamp-1 mb-4"><span *ngFor="let location of data['distinct_locations']; let i = index">{{location['location_name']}}<ng-container *ngIf="i != data['distinct_locations'].length - 1">, </ng-container> </span></p>
			<p class="small text-muted mb-0" *ngIf="data.active_deal_count > 0"><i class="fak fa-deals fa-fw me-2"></i>{{data.active_deal_count}} ongoing deals</p>
		</div>
	</div>
</div>