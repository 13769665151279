import { Component, OnInit } from '@angular/core';
import { OgService } from '../../services/og/og.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content/content.service';
import { UserAccountService } from '../../services/user-account/user-account.service';

@Component({
  selector: 'app-og-invite-welcome',
  templateUrl: './og-invite-welcome.component.html',
  styleUrls: ['./og-invite-welcome.component.scss']
})
export class OgInviteWelcomeComponent implements OnInit {

  query: string;
  isLoaded = false;
  isSuccess = false;
  isFailed = false;
  isPending = false;
  data: object;
  response: object;
  resource = environment.resource;
  ogContent;

  constructor(
    private OgService: OgService,
    private router: Router,
    private ContentService: ContentService,
    private UserAccountService: UserAccountService,
  ) {
    this.data = {
      transaction_status_id: ''
    }
   }

  ngOnInit() {
    this.successDetails();
    this.ContentService.ogContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.ogContent = data;
      }
    });
  }

  successDetails() {
    let page =this;
    let url = window.location.href;
    page.isLoaded = false;
    page.query = url.split('?').pop();
    if(page.query != null) {
      page.OgService.getPurchaseStatus(page.query).subscribe((data: {}) => {
        if(data['success'])  {
          page.isSuccess = true;
          page.data = data;
          page.isLoaded = true;
          page.UserAccountService.setActiveUser(data);
      } else if(data['status'] == "failed") {
            page.isFailed = true;
            page.response = data;
            page.isLoaded = true;
        }
      });
    }
    // if(page.successId != null) {
    //   page.data['transaction_status_id'] =  page.successId;
    //   page.OgService.getPurchaseStatus(page.data).subscribe((data: {}) => {
    //     localStorage.removeItem('buyerInfo');
    //     localStorage.removeItem('bagCount');
    //     localStorage.removeItem('bagId');
    //     page.isSuccess = true;
    //     page.response = data;
    //     page.isLoaded = true;
    //   });
    // }
    // if(page.failedId != null) {
    //   page.data['transaction_status_id'] =  page.failedId;
    //   page.OgService.getPurchaseStatus(page.data).subscribe((data: {}) => {
    //     page.isFailed = true;
    //     page.response = data;
    //     page.isLoaded = true;
    //   });
    // }

  }

}
