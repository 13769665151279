import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ContentService } from '../../services/content/content.service';


@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {

  resource = environment.resource;
  
  contentLoaded = false;
  theme = '';
  contentData: any = [];

  footerDetail:any;

  visible:boolean = false;

  footerHidden = [
    {
      'route': '/beauty-bag-deals',
      'type': 'dynamic'
    },
    {
      'route': '/beauty-bag-checkout',
      'type': 'full'
    },
    {
      'route': '/beauty-bag-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/gifting',
      'type': 'dynamic'
    },
    {
      'route': '/promo-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/register',
      'type': 'dynamic'
    },
    {
      'route': '/glife',
      'type': 'dynamic'
    },
    {
      'route': '/payment-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/payments/',
      'type': 'dynamic'
    },
    {
      'route': '/book-appointment',
      'type': 'dynamic'
    },
    {
      'route': '/booking-status',
      'type': 'dynamic'
    },
    {
      'route': '/beauty-bash/teaser',
      'type': 'dynamic'
    },
    {
      'route': '/beauty-bash/checkout',
      'type': 'dynamic'
    },
    {
      'route': '/beauty-bash/bash-event-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/booking-payment',
      'type': 'dynamic'
    },
    {
      'route': '/voucher-booking-status',
      'type': 'dynamic'
    },
  ];

  teaser = environment.teaser;

  constructor(
    private ContentService: ContentService,
    private router: Router) { }

  selectedRoute
  ngOnInit() {

    if(this.teaser == true) {
      this.footerHidden.push({
        'route': '/',
        'type': 'full'
      })
    }

    let initialLoadUrl = this.router.url;
    if(initialLoadUrl.startsWith('/search/')){
      this.selectedRoute = 'search';
      
    }else if (initialLoadUrl.startsWith('/mobile/')) {
      this.selectedRoute = 'mobile';
      ;
    }
    else{
      this.selectedRoute='default';
      
    }
    
    this.router.events.subscribe(event => {
      this.checkRoutesHidden();

      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (url.startsWith('/search/')) {
          this.selectedRoute = 'search';
          ;
        }else if (url.startsWith('/mobile/')) {
          this.selectedRoute = 'mobile';
          ;
        }
        else{
          this.selectedRoute='default';        
        }

        // beauty bash theme 
        if (url.startsWith('/beauty-bash')) {
          this.theme = 'beauty-bash-theme';
        } else {
          this.theme = '';
        }
      }
      
    });

    this.checkRoutesHidden();

    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentData = data;
        this.contentLoaded = true;
        this.footerDetail = data['footer'];
        // console.log(111,data);

      }
    });


    // this.ContentService.getContentGlobal().subscribe(data=>{
    //   // console.log(data);
    //   this.footerDetail = data.data.footer;
    // })


  }

  checkRoutesHidden() {
    // console.log('footer new')

    let visible = true;
    this.footerHidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          visible = false;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          visible = false;
        }
      } 
    });

    this.visible = visible;
    // return visible;
  }

}
