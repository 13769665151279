<div>
  <div class="container">
    <div class="row-d-none d-lg-block">
      <div class="col-12">
        <ngx-skeleton-loader count="1" appearance="bar" [theme]="{height: '14px', width: '180px'}"> </ngx-skeleton-loader>
        <div class="mb-6"></div>
        <h2><ngx-skeleton-loader count="1" appearance="bar" [theme]="{width: '256px'}"> </ngx-skeleton-loader></h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ngx-skeleton-loader count="1" appearance="bar" [theme]="{height: '256px'}"> </ngx-skeleton-loader>    
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 col-xxl-3">
        <div class="row justify-content-center">
          <div class="col-8 col-md-5 col-lg-12">
            <ngx-skeleton-loader count="1" appearance="bar" [theme]="{height: '180px'}"> </ngx-skeleton-loader>
          </div>

          <div class="col-11 col-md-7 text-center text-md-start d-lg-none">
            <h3><ngx-skeleton-loader count="1" appearance="bar" [theme]="{width: '180px'}"></ngx-skeleton-loader></h3>
            <ngx-skeleton-loader count="3" appearance="bar" [theme]="{height: '16px', width: '192px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" appearance="bar" [theme]="{height: '32px', width: '32px'}"></ngx-skeleton-loader>
          </div>

          <div class="col-12 d-none d-lg-block">
            <ngx-skeleton-loader count="1" appearance="bar" [theme]="{height: '48px'}"></ngx-skeleton-loader>

            <ngx-skeleton-loader count="3" appearance="bar" [theme]="{height: '512px'}"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-8 col-xxl-9">
        <div class="row justify-content-center">
          <div class="col-auto d-lg-none">
            <ngx-skeleton-loader count="5" appearance="bar" [theme]="{ height: '32px', width: '32px', margin: '0 2rem' }"></ngx-skeleton-loader>
          </div>

          <div class="col-auto d-none d-lg-block">
            <ngx-skeleton-loader count="4" appearance="bar" [theme]="{ height: '32px', width: '128px', margin: '0 2rem' }"></ngx-skeleton-loader>
          </div>
        </div>

        <div class="row">
          <div class="col-12 pt-6">
            <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '48px' }"></ngx-skeleton-loader>
          </div>

          <div class="row justify-content-center gy-3 mb-4">
            <div class="col-10 col-md-8 col-lg-6">
              <h4><ngx-skeleton-loader count="1" appearance="bar"></ngx-skeleton-loader></h4>
            </div>

            <div class="col-12">
              <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '480px' }"></ngx-skeleton-loader>
            </div>
          </div>

          <div class="row justify-content-center gy-3 mb-4">
            <div class="col-10 col-md-8 col-lg-6">
              <h4><ngx-skeleton-loader count="1" appearance="bar"></ngx-skeleton-loader></h4>
            </div>

            <div class="col-12">
              <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '480px' }"></ngx-skeleton-loader>
            </div>
          </div>

          <div class="row justify-content-center gy-3 mb-4">
            <div class="col-10 col-md-8 col-lg-6">
              <h4><ngx-skeleton-loader count="1" appearance="bar"></ngx-skeleton-loader></h4>
            </div>

            <div class="col-12">
              <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '480px' }"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="content">
  <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px', width: '200px'}"> </ngx-skeleton-loader>
  <h3><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '40px', width: '150px'}"> </ngx-skeleton-loader></h3>
  <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '250px'}"> </ngx-skeleton-loader>
</div>

<div class="content">

  <div class="columns p-1">

    <div class="column is-3 has-text-centered">
      <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '250px'}"> </ngx-skeleton-loader>
    </div>

    <div class="column is-9">

      <div class="info">
        <div class="type">
          <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '40px', width:'60px'}"> </ngx-skeleton-loader>
          <h3 class="name"><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '40px', width: '150px' }"> </ngx-skeleton-loader></h3>
        </div>
        <div class="description">
          <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '40px'}"> </ngx-skeleton-loader>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="columns is-mobile">
        <div class="column">

          <div>
            <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '150px'}"> </ngx-skeleton-loader></h5>
            <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></h5>
          </div>

          <div class="spacer"></div>

          <div>
            <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '140px'}"> </ngx-skeleton-loader></h5>
            <ul class="payment-method">
              <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></h5>
              <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></h5>
            </ul>
          </div>

        </div>

        <div class="column">

          <div>
            <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '80px'}"> </ngx-skeleton-loader></h5>
            <ul>
              <li><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></li>
              <li><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></li>
            </ul>
          </div>

          <p></p>

          <div>
            <h5><h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '80px'}"> </ngx-skeleton-loader></h5></h5>
            <div class="scm-links">
              <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></h5>
              <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></h5>
                <h5><ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '20px',  width: '100px'}"> </ngx-skeleton-loader></h5>
              </div>
          </div>
          
        </div>
      </div>

    </div>
  </div>

</div>

<div class="content">
  <div class="columns">
    <div class="column is-3">
      <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '500px'}"> </ngx-skeleton-loader>
    </div>
    <div class="column is-9">
      <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '70px'}"> </ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="bar" [theme]="{ height: '350px'}"> </ngx-skeleton-loader>
    </div>
  </div>
</div> -->