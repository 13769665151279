<section id="payment-link-checkpoint" *ngIf="isLoaded == true">
    <!-- Payment page banner -->
    <div>
      <a href="#">
        <img src="assets/images/payment-banner.png" alt="" class="img-fluid img-hero-banner-desktop w-100">
        <img src="assets/images/payment-banner.png" alt="" class="img-fluid img-hero-banner-tablet w-100">
        <img src="assets/images/payment-banner.png" alt="" class="img-fluid img-hero-banner-mobile w-100">
      </a>
    </div>

    <div class="container py-6 py-md-7">
        <div class="row row-payment-main-layout gx-lg-12 gy-6 gy-lg-0">
          <!-- Main content -->
          <div class="col-12 col-xl-8 col-content content">
              <div class="row justify-content-center logo-and-business-name-layout gx-4">
                <div class="col-5 col-md-4 col-lg-12 order-md-2 d-lg-none">
                  <div class="mb-5">
                    <div class="ratio ratio-img-payment-link-logo">
                      <img src="{{resourceLink + paymentData['parlon_logo']}}" alt="" class="img-fluid obj-fit-contain">
                    </div>
                  </div>		
                </div>
      
                <div class="col-12 col-md-8 col-lg-12 order-md-1">
                  <h1 class="h1 text-center mb-3">{{paymentData['parlon_name']}} - {{paymentData['branch_name']}}</h1>
                </div>
              </div>
          
            <!-- Payment Form -->
				    <payment-form  [details]="paymentData"></payment-form>

          </div>

          <!-- Right side -->
          <div class="col-12 col-xl-4 col-sidebar d-none d-lg-block">
          </div>
        </div>
    </div>



</section>