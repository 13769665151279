import {
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouteReuseStrategy,
  } from '@angular/router';
  
  export class CustomReuseStrategy implements RouteReuseStrategy {
    private handlers: { [key: string]: DetachedRouteHandle } = {};
  
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
      // Customize when a route should be detached and preserved

      return route.routeConfig.path === 'all-parlon-deals';
    //   return true; // Detach and preserve all routes
    }
  
    store(
      route: ActivatedRouteSnapshot,
      handle: DetachedRouteHandle | null
    ): void {
      // Store the detached route handle
      if (handle) {
        this.handlers[route.routeConfig.path || ''] = handle;
      }
    }
  
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
      // Customize when a route should be reattached
      return !!route.routeConfig && !!this.handlers[route.routeConfig.path || ''];
    }
  
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
      // Retrieve the detached route handle
      return route.routeConfig ? this.handlers[route.routeConfig.path || ''] : null;
    }
  
    shouldReuseRoute(
      future: ActivatedRouteSnapshot,
      current: ActivatedRouteSnapshot
    ): boolean {
      // Customize when a route should be reused
      return future.routeConfig === current.routeConfig;
    }
  }
  