import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpSuccessComponent } from './otp-success.component';
import { MaterialModule } from '../../material';
import {RouterModule} from '@angular/router';
import { OtpSuccessRouterModule } from "./otp-success-routing.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    OtpSuccessRouterModule
  ],
  declarations: [OtpSuccessComponent]
})
export class OtpSuccessModule { }
