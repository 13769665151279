import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import * as Rx from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-membership-checkout',
  templateUrl: './membership-checkout.component.html',
  styleUrls: ['./membership-checkout.component.scss']
})
export class MembershipCheckoutComponent implements OnInit {

  parlon: any = [];
  isLoaded = false;
  resourceLink = this.rest.resourceLink();
  fields = "";
  slug: string;
  showHidden = "";
  membershipForm: FormGroup;
  selectedMembership: object;
  hasSelected = false;
  activePromo: object;

  constructor(
    private route: ActivatedRoute,
    private rest: RestService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getSingleSalon();
  }

  selectMembership(data) {
    if(data) {
      this.selectedMembership = data;
      this.hasSelected = true;
    } else { 
      this.hasSelected = false;
    }
  }

  applyCodeHandler(data) {
    if(data) {
      this.activePromo = data;
    }
  }

  getSingleSalon() {
    let page = this;
    page.isLoaded = false;
    this.slug = this.route.snapshot.paramMap.get("slug");
    this.fields = '?where=slug;' + this.slug + "&&relationships&&showMemberShipBranches&&showhidden";
    this.route.queryParams.subscribe(params => {
      this.showHidden = params['showHidden'];
    });
    this.showHidden = (this.showHidden === 'true') ? '&&showhidden' :'';
    this.rest.getParlons(this.fields).subscribe((data: {}) => {
      this.parlon = data['data'];
      page.isLoaded = true;
    });
  }
}
