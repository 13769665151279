import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { BeautyBashService } from '../../services/beauty-bash/beauty-bash.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-beauty-bash-listing',
  templateUrl: './beauty-bash-listing.component.html',
  styleUrls: ['./beauty-bash-listing.component.scss']
})
export class BeautyBashListingComponent implements OnInit {

  deals = [];
  contentData: any = [];
  contentLoaded = false;
  resource = environment.resource;
  startDate;
  daysDiff:any = 0;
  hoursDiff:any = 0;
  minDiff:any = 0;
  secDiff:any = 0;
  duration:any;
  constructor(
    private ContentService: ContentService,
    private BeautyBashService: BeautyBashService,
    private el: ElementRef,
  ) { }

  ngOnInit() {
    let app = this;
    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentData = data;
        app.startDate = moment(this.contentData['bash_event']['to']);
        setInterval(function(){
          let currentDate = moment();
          app.duration = moment.duration(app.startDate.diff(currentDate));
          app.daysDiff = Math.floor(app.duration.asDays());
          app.hoursDiff = Math.floor(app.duration.asHours() - (app.daysDiff * 24));
          app.minDiff = Math.floor(app.duration.asMinutes() - ((app.daysDiff * 1440) + (app.hoursDiff * 60)));
          app.secDiff = Math.floor(app.duration.asSeconds() - ((app.daysDiff * 86400) + (app.hoursDiff * 3600) + (app.minDiff * 60)));
      }, 1000);
        this.BeautyBashService.getBashDeals(this.contentData['bash_event']['id']).subscribe((data) => {
          app.deals = data['data']['bash_deals'];
          app.contentLoaded = true;
        });
      }
    });
  }

  @HostListener('mousemove', ['$event'])
  mouseMove($event: MouseEvent) {
    const el = this.el.nativeElement;
    const cursor = el.querySelector('.custom-pointer');
    cursor.style.transform = "translate("+$event.pageX+"px, "+$event.pageY+"px)";
  }

}
