<!-- <div class="btn-drawer-back">
	<a href="#" class="link-text fw-medium">Back</a>
</div> -->




<!-- Use `*ngIf` to toggle views -->


<div class="row flex-account gx-0 gy-6" *ngIf="!isSuccess">
	<!-- Forgot password -->
	<div class="col-12 col-login">
		<h3 class="h3 fw-semibold mb-3">Forgot password?</h3>
		<p class="mb-6">Enter the email associated with your account and we'll send a link to reset your password.</p>

		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<mat-form-field appearance="fill">
				<mat-label>Email address</mat-label>
				<input matInput placeholder="Email address" id="email" formControlName="email">
			</mat-form-field>

			<div *ngIf="showError" style="margin-top: -15px; margin-bottom:5px;">
				<mat-error>{{messageError}}</mat-error>
			</div>

			<button [disabled]="!form.valid || isSubmitted" mat-flat-button color="secondary" class="btn-submit mb-5" type="submit">
				Send link
			</button>
		</form>
	</div>
	<div class="d-flex align-items-center">
		<button mat-raised-button color="primary" (click)="hideForgotPassword()"  class="link-text fw-medium">Go back to sign in</button>
	</div>
</div>

<div class="row flex-form-success gx-0 gy-6"  *ngIf="isSuccess">
	<div class="col-12">
		<div class="text-center mb-5">
			<i class="fas fa-envelope fa-4x fa-fw text-primary"></i>
		</div>

		<div class="text-center mb-6">
			<h3 class="h3 fw-semibold mb-4">Check your email</h3>
			<p class="mb-0">We sent a password reset link to<br/><span class="fw-medium">{{email.value}}</span></p>
		</div>

		<div class="d-grid gap-3 mb-5">			
			<!-- <button mat-flat-button color="secondary" class="btn-submit">
				Open Mail app
			</button> -->

			<button (click)="hideForgotPassword()" mat-stroked-button color="secondary" class="btn-submit" data-bs-dismiss="offcanvas">
				I'll check later
			</button>
		</div>

		<p class="text-center">Didn't receive anything? <button [ngClass]="isSubmittedResend ? 'is-disabled': 'is-active' " [disabled]="isSubmittedResend" 
			(click)="resend()" class="fw-semibold">Resend email</button></p>
	</div>
</div>