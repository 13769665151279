<img src="assets/images/beauty-bash/percent-yellow.svg" class="d-none d-md-block custom-pointer" *ngIf="contentLoaded == true">
<div class="bash-deals-list" >
  <div class="bash-banner">
    <img src="assets/images/beauty-bash/bash-banner-dk2.png" class="img-fluid w-100 bash-banner-img">
    <img src="{{resource}}BeautyBash/beauty-bash.svg" class="bash-banner-logo">
  </div>
  <div class="headline container">
    <h2 class="h2 fw-semibold pt-3">Join Parlon’s Beauty Bash before it’s too late! </h2>
    <h4 class="h4">ONE ACCOUNT = ONE PURCHASE PER BASH EVENT</h4>
    <div class="time-dial pt-3">
      <div class="dial-col">
        <div class="dial">
          <span *ngIf="daysDiff > 0">{{daysDiff}}</span>
          <span *ngIf="daysDiff < 1">X</span>
        </div> 
        <span class="label gold">DAYS</span>
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{hoursDiff}}</span>
        </div> 
        <span class="label gold">HOURS</span>
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{minDiff}}</span>
        </div> 
        <span class="label gold">MINUTES</span> 
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{secDiff}}</span>
        </div> 
        <span class="label gold">SECONDS</span> 
      </div>
    </div>
  </div>
  <div id="bash-deals-checkpoint"></div>
  <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top">
  <div class="sheet">
    <div class="row deals-list">
      <div class="col-md-4 col-6 px-1" *ngFor="let deal of deals; let i = index">
        <bash-deal-card [data]="deal"></bash-deal-card>
      </div>
    </div>
    <img src="{{resource}}BeautyBash/lightning.svg" alt="" class="lightning-1">
    <img src="{{resource}}BeautyBash/sparkle-2.png" alt="" class="sparkle-1">
    <img src="{{resource}}BeautyBash/sparkle-3.png" alt="" class="sparkle-2">
    <img src="{{resource}}BeautyBash/lightning.svg" alt="" class="lightning-2">
  </div>
  <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot">
  <div class="circle top-right"></div>
  <div class="circle bot-left"></div>
</div>