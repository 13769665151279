import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ContentService } from '../../services/content/content.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanActivate {
  constructor(private router: Router,private ContentService: ContentService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Promise<boolean>(resolve => {
        this.ContentService
        .getFeatures()
        .toPromise()
        .then((res) => {
          if(res['registration'] == true) {
             resolve(true);
          } else {
             this.router.navigate(["/"]);
             resolve(false); 
          } 
       })
       .catch(() => {
         this.router.navigate(["/"]);
         resolve(false);
        });
     });
  }
}
