import { Component, OnInit, Input } from '@angular/core';
import { UserAccountService } from '../../../../services/user-account/user-account.service';

@Component({
  selector: 'bag-details',
  templateUrl: './bag-details.component.html',
  styleUrls: ['./bag-details.component.scss']
})
export class BagDetailsComponent implements OnInit {

  @Input() data:any;
   isLoggedIn = false;

  constructor(private userService:UserAccountService) { }

  ngOnInit() {
    this.userService.getLoggedInUser.subscribe(data=>{
      if(data != null){
        // alert(1);
        this.isLoggedIn = true;

      }               
    });
  }

}
