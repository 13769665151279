<div class="card card-search-result-parlon bg-light-3">
	<div class="card-body pb-0 pos-relative">
		<div class="row flex-result-parlon gx-4">
			<div class="col-4 col-md-3 col-img">
				<div class="ratio ratio-4x3">
					<img src="{{ parlon['parlon_logo'] }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
				</div>
			</div>

			<div class="col-8 col-md-9 col-content">
				<a [routerLink]="['/parlon', parlon['slug']]" class="stretched-link link-text">
					<p class="fs-5 fw-medium mb-1">{{ parlon['parlon_name'] }}</p>
				</a>
				<p class="small mb-0" *ngIf="parlon['bookable']">
					<span class="fw-medium" style="color: var(--bs-orange)">Booking available</span>
				</p>
			</div>
		</div>

		<div class="border-top mt-3 pt-3" *ngIf="parlon['services'].length > 0">
			<ul class="list-unstyled list-parlon-services mb-0">
				<li *ngFor="let service of parlon['services']">
					<p class="small mb-0" [innerHTML]="service | safehtml"></p>
				</li>
			</ul>
		</div>
	</div>

	<div class="card-body pt-0">
		<div class="border-top mt-3 pt-3" *ngIf="parlon['branches'].length > 1">
			<mat-accordion>
				<mat-expansion-panel hideToggle>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<p class="small fw-semibold mb-0">See all {{ parlon['branches'].length }} branches</p>
						</mat-panel-title>

						<mat-panel-description class="justify-content-end">
							<div class="expansion-icon small">
								<i class="fas fa-chevron-down fa-fw"></i>
							</div>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<div>
						<p class="mb-0">
							<span *ngFor="let branch of parlon['branches']">{{ branch.name }}&nbsp;<span class="fs-6 fw-semibold"> • </span>&nbsp;</span>
						</p>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</div>