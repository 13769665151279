<div class="mb-6">
	<div class="slider">
		<ngx-siema [options]="options">
			<ngx-siema-slide *ngFor="let photo of branch['photo']; let i=index">
			<img src="{{resourceLink + photo.image}}" class="photo" (click)="open(i)" alt="{{photo.alt}}" title="{{photo.title}}">
			</ngx-siema-slide>
		</ngx-siema>

		<a (click)="prev()" class="prev-arrow" *ngIf="branch['photo'].length > 4"><i class="far fa-chevron-left fa-fw"></i></a>
		<a (click)="next()" class="next-arrow" *ngIf="branch['photo'].length > 4"><i class="far fa-chevron-right fa-fw"></i></a>
	</div>
</div>
