import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { BookingListComponent } from './booking-list.component';
import { ParlonCardTemplateComponent } from './components/parlon-card-template/parlon-card-template.component';
import { RouterModule } from '@angular/router';
import { BookingListRouterModule } from "./booking-list-routing.module";

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    RouterModule,
    BookingListRouterModule
  ],
  declarations: [BookingListComponent, ParlonCardTemplateComponent]
})
export class BookingListModule { }
