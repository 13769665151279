import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { environment } from '../../../environments/environment';
import { ContentService } from '../../services/content/content.service';
@Component({
  selector: 'app-otp-success',
  templateUrl: './otp-success.component.html',
  styleUrls: ['./otp-success.component.scss']
})
export class OtpSuccessComponent implements OnInit, OnDestroy {

  constructor(private userAccount:UserAccountService, private router:Router,
    private contentService: ContentService) { }

  $existing:boolean = false; 
  $firstName:string="";

  $activeUserSub:Subscription|undefined; 
  $userLoggedIn:Subscription|undefined;

  hideSuccessMessage = environment.hide_user_success_message;
  contentData:any;  

  ngOnInit() {
    this.contentService.getFeatures().subscribe((data)=>{      
      this.contentData = data;
    })    
    this.$activeUserSub =  this.userAccount.getActiveUser.subscribe((data:any)=>{
      if(!data){
        this.router.navigate(['/']);
      } 
     this.$firstName = data.user.first_name; 
     if(data.customer_record.details.length>0){
      this.$existing = true;
     }else{
      this.$existing = false;
     }
    })
  }
  
  ngOnDestroy(): void {
      if(this.$activeUserSub)
        this.$activeUserSub.unsubscribe();
  }

}
