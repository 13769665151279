import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../../services/booking/booking.service';
import { SalonsService } from '../../services/salons/salons.service';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { environment } from '../../../environments/environment';
import { DealsService } from '../../services/deals/deals.service';
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment';
import { NetcoreService } from '../../services/netcore/netcore.service';

declare var Flickity: any;

@Component({
  selector: 'app-purchased-deals-booking',
  templateUrl: './purchased-deals-booking.component.html',
  styleUrls: ['./purchased-deals-booking.component.scss']
})
export class PurchasedDealsBookingComponent implements OnInit {

  dealsBooking;
  resource = environment.resource;
  data;
  isLoaded = false;
  api_token = null;
  reference_id = null;
  selectedBookings = [];
  isFormValid = false;
  error_message = '';
  submit_loading = false;
  

  constructor(
    private el: ElementRef,
    private BookingService: BookingService,
    private router: Router,
    private UserAccountService:UserAccountService,
  ) { }

  ngOnInit() {

    this.UserAccountService.getLoggedInUser.subscribe(data=>{
        
      if(data){
        this.api_token = data.data;
      }

    });

    Flickity.prototype.getSelectedCellInnerTextNumbers = function() {
      const selectedCells = this.selectedCells;
      const numberRegex = /\d+/g; // Regular expression to match numbers
      const selectedCellInnerTextNumbers = selectedCells.map(function(cell) {
        const text = cell.element.innerText;
        const numbers = text.match(numberRegex);
        return numbers ? numbers.join('') : ''; // Concatenate matched numbers, or an empty string if none found
      });
    
      return selectedCellInnerTextNumbers;
    };

    Flickity.prototype.getSelectedCellInnerIds = function() {
      const selectedCells = this.selectedCells;      
      const selectedCellInnerTextNumbers = selectedCells.map(function(cell) {
        const text = cell.element.id;        
        return text;
      });
    
      return selectedCellInnerTextNumbers;
    };

    Flickity.prototype.getMonth  = function( selectedDates){
      selectedDates.sort();
      const highestDate = selectedDates[selectedDates.length - 1];
      const dateObj = new Date(highestDate);
      const month = dateObj.toLocaleString('default', { month: 'long' });
      const year = dateObj.getFullYear();
      const formattedDate = `${month} ${year}`;
      document.getElementById('month-year').innerHTML = formattedDate;
      return formattedDate

    }

    this.dealsBooking = JSON.parse(localStorage.getItem('bookDeals'));
    let deals = [];
    this.selectedBookings = [];
    this.dealsBooking['deals'].forEach(element => {
      deals.push(
        {
          "deal_id": element['deal_id'],
          "branch_id": element['branch_id']
        },
      )
    });
    this.BookingService.getCheckoutDetailsForDealsPurchased({deals:deals}).subscribe((data: {}) => {
      this.data = data;
      data['data'].forEach(element => {
        element['timeslots'] = [];
        this.selectedBookings.push({
          'date':null,
          'time':null,
          'notes':''
        });
      });
      this.isLoaded = true;
      setTimeout(() => {
        for (let index = 0; index < data['data'].length; index++) {
          const el = this.el.nativeElement;
          let elem = el.querySelector('#flickity-date-picker-'+index);
          let flkty = new Flickity(elem, {
            autoPlay: false,
            contain: true,
            cellAlign: 'center',
            cellSelector: '.slide',
            pageDots: false,
            prevNextButtons: false,
            groupCells: true
          });
          let flkty_prev = el.querySelector('#flickity-date-picker-'+index+' .btn-flickity-prev');
          console.log(flkty_prev);
          let flkty_next = el.querySelector('#flickity-date-picker-'+index+' .btn-flickity-next');
          flkty_prev.addEventListener('click', function() {                    
            flkty.previous(true);
          });

          flkty_next.addEventListener('click', function() {          
            console.log('next');
            flkty.next(true);
          });
          const carouselWidth = flkty.cells.reduce((totalWidth, cell) => totalWidth + cell.element.clientWidth, 0);
          const containerWidth = flkty.viewport.getBoundingClientRect().width;
        
          if (carouselWidth <= containerWidth) {
            flkty_prev.style.display = 'none';
            flkty_next.style.display = 'none';
          } else {
            flkty_prev.style.display = 'block';
            flkty_next.style.display = 'block';
          }

          if (flkty.selectedIndex === 0) {
            flkty_prev.setAttribute('disabled', 'true');
            flkty_prev.classList.add('disabled');
          } else {
            flkty_prev.removeAttribute('disabled');
            flkty_prev.classList.remove('disabled');
          }
          
          const selectedIds = flkty.getSelectedCellInnerIds();
          flkty.getMonth(selectedIds)
          flkty.on('change', function(item) {         
            const selectedIds = flkty.getSelectedCellInnerIds();
            flkty.getMonth(selectedIds)
            
            if (flkty.selectedIndex === 0) {
              flkty_prev.setAttribute('disabled', 'true');
              flkty_prev.classList.add('disabled');
            } else {
              flkty_prev.removeAttribute('disabled');
              flkty_prev.classList.remove('disabled');
            }
        
            if (flkty.selectedIndex === flkty.slides.length - 1) {
              flkty_next.setAttribute('disabled', 'true');
              flkty_next.classList.add('disabled');
            } else {
              flkty_next.removeAttribute('disabled');
              flkty_next.classList.remove('disabled');
            }
          });
        }
      }, 500);  
   });
  }

  selectDate(date,branch_id,index) {
    this.data['data'][index]['timeslots'] = [];
    this.selectedBookings[index]['date'] = date; 
    this.isFormValid = false;
    this.BookingService.getTimeSlots(branch_id, date).subscribe(data =>{
      this.data['data'][index]['timeslots'] = data;
      this.selectedBookings[index]['time'] = null; 
    })
  }

  selectTime(time,index){
    this.selectedBookings[index]['time'] = moment(time).format('hh:mm a');
    let valid = true;
    this.selectedBookings.forEach(e => {
      if(e['date'] == null || e['time'] == null)
       valid = false;
    });
    this.isFormValid = valid;
  }

  requestBooking(){
    let requestData = {};
    let app = this;
    if(this.api_token == null) {
      requestData['reference_id'] = this.dealsBooking['id'];
    } else {
      requestData['api_token'] = this.api_token;
    }
    let bookings = [];
    this.data['data'].forEach(function (value, i) {
      let d = {};
      d['branch_id'] = value['branch']['id'];
      let deals = [];
      value['deals'].forEach(e => {
        deals.push(e['deal_id']);
      });
      d['deals'] = deals;
      d['date'] = app.selectedBookings[i]['date'];
      d['time'] = app.selectedBookings[i]['time'];
      d['notes'] = app.selectedBookings[i]['notes'];
      bookings.push(d);
    });
    requestData['bookings'] = bookings;
    this.submit_loading = true;
    this.error_message = '';
    app.BookingService.DealsBookingRequest(requestData).subscribe(data => { 
      this.submit_loading = false;
      if(data['status'] == true) {
        localStorage.setItem('DealsBookingResponse',JSON.stringify(data));
        setTimeout(() => {
          this.router.navigate(['/voucher-booking-status']);
        }, 1000);  
      } else {
        this.error_message = data['message'];
      }
    });
  }


}
