import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material';
import { MainNavComponent } from './main-nav.component';
import { UserDrawerDashboardComponent } from './components/user-drawer-dashboard/user-drawer-dashboard.component';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { CommonElementsModule } from '../../common';
import { GlobalNavComponent } from './components/global-nav/global-nav.component';
import { DealNavComponent } from './components/deal-nav/deal-nav.component';
import { SearchNavComponent } from './components/search-nav/search-nav.component';
import { NocategNavComponent } from './components/nocateg-nav/nocateg-nav.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserForgotPasswordComponent } from './components/user-forgot-password/user-forgot-password.component';
import { LogoonlyNavComponent } from './components/logoonly-nav/logoonly-nav.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule } from '@angular/forms';
import { MegaMenuComponent } from './components/mega-menu/mega-menu.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BeautyBashNavComponent } from './components/beauty-bash-nav/beauty-bash-nav.component';

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    FormsModule,
    NgxSmartModalModule
  ],
  declarations: [
    MainNavComponent,
    UserDrawerDashboardComponent,
    UserLoginComponent,
    GlobalNavComponent,
    DealNavComponent,
    SearchNavComponent,
    NocategNavComponent,
    UserForgotPasswordComponent,
    LogoonlyNavComponent,
    MegaMenuComponent,
    BeautyBashNavComponent
  ],
  exports: [ 
    MainNavComponent
  ]
})
export class MainNavModule { }