<div class="bash-teaser" *ngIf="contentLoaded">
  <!-- <img src="{{resource}}BeautyBash/bb-logo-listing.png" class="img-fluid"> -->
  <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top">
  <div class="sheet">
    <img src="{{resource}}BeautyBash/beauty-bash.svg" class="img-fluid bash-logo">
    <div class="h2 fw-semibold pt-3 text-center" [innerHTML]="contentData['bash_event']['description']"></div>
    <div class="time-dial pt-3">
      <div class="dial-col">
        <div class="dial">
          <span *ngIf="daysDiff > 0">{{daysDiff}}</span>
          <span *ngIf="daysDiff < 1">X</span>
        </div> 
        <span class="label">DAYS</span>
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{hoursDiff}}</span>
        </div> 
        <span class="label">HOURS</span>
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{minDiff}}</span>
        </div> 
        <span class="label">MINUTES</span> 
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{secDiff}}</span>
        </div> 
        <span class="label">SECONDS</span> 
      </div>
    </div>
    <button [routerLink]="['/register']" mat-flat-button color="secondary" class="py-2 create-account-btn px-5">
      CREATE A PARLON ACCOUNT
    </button>  
    <p class="m-0 fw-semibold py-4 text-center">Bookmark this page and create a Parlon account to get notified! </p>
    <div class="d-flex gap-4 pt-2">
      <a href="{{contentData['footer']['footer_ig']}}" class="social-links" target="_blank">
        <i class="fab fa-instagram fa-2x fa-fw"></i>
      </a>

      <a href="{{contentData['footer']['footer_fb']}}" class="social-links" target="_blank">
        <i class="fab fa-facebook fa-2x fa-fw"></i>
      </a>

      <a href="{{contentData['footer']['footer_tiktok']}}" class="social-links" target="_blank">
        <i class="fab fa-tiktok fa-2x fa-fw"></i>
      </a>

      <a href="{{contentData['footer']['footer_linkedin']}}" class="social-links" target="_blank">
        <i class="fab fa-linkedin fa-2x fa-fw"></i>
      </a>
    </div>
    <img src="{{resource}}BeautyBash/lightning.svg" alt="" class="lightning-1">
    <img src="{{resource}}BeautyBash/sparkle-1.png" alt="" class="sparkle-1 d-sm-none d-md-block">
    <div class="savings-display"><p class="copy m-0">Up to <span style="color: #F40076;font-weight: 800;display: inline-block;" [innerHTML]="contentData['bash_event']['savings_display']"></span></p></div>
  </div>
  <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot">
  <div class="circle top-right"></div>
  <div class="circle bot-left"></div>
</div>