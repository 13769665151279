<form [formGroup]="checkoutForm">
  <mat-vertical-stepper [linear]="isLinear" #stepper>
  
  
    <mat-step formGroupName="paymentFormGroup">

      
      <ng-template matStepLabel>Apply Promo Code</ng-template>

      <div class="card bg-light mb-5 payment-checkpoint">
        <div class="card-body">

          <!-- PROMO CODE -->
          <promo-code [promocodeValue]="data['promo_code']" [loading]="promoCodeLoading" (promoCodeEmit)="applyCodeHandler($event)"></promo-code>
        </div>
      </div>

       <!-- CANDIES  -->

       <app-candies-form (candiesAmountEvent)="applyCandies($event)" *ngIf="api_token && candies['allCandies'] > 0" [candies]="candies" [candies_discount_api]="candies_discount"
       [api_token]="api_token" [bag_id]="form_data['bag_id']" [bagInfo]="data" class="test"></app-candies-form>

      <div class="d-lg-none mb-5">
        <bag-details [data]="data['summary']"></bag-details>
      </div>

      
      <div class="payment-method-accordion">
        <mat-accordion>
          <mat-radio-group formControlName="paymentChannel">
            <ng-container *ngFor="let channel of payment_channels">
              
              <mat-expansion-panel 
                *ngIf="checkPaymentChannel(channel, 'custom_credit_card')">
                <mat-expansion-panel-header (click)="paymentOptionClick(channel['channel_code'])">
                  <div class="row row-payment-channel gx-4">
                    <div class="col">
                      <mat-radio-button value="{{channel['channel_code']}}">				
                        {{channel['channel_name']}}
                      </mat-radio-button>
                    </div>
                    <div class="col-auto">
                      <div class="card-logo">
                        <img src="{{resource + channel['logo']}}">
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>
    
                <div>
                  <p class="mb-2">Your payment shall be processed safely and securely by a third-party payment gateway provider. For your security, we do not store your credit card information.</p>
                  <credit-card-form [paymentFormGroup]="checkoutForm.get('paymentFormGroup')"></credit-card-form>
                </div>
            
              </mat-expansion-panel>

              <mat-expansion-panel class="nopad"
              *ngIf="checkPaymentChannel(channel, 'generic')">
              <mat-expansion-panel-header  (click)="paymentOptionClick(channel['channel_code'])">
                <div class="w-100 row-payment-channel">
                  <div class="row">                              
                    <mat-radio-button value="{{channel['channel_code']}}">	
                      <div > {{channel['channel_name']}}</div>
                    </mat-radio-button>                                        
                  </div>
                  <div>
                    <div class="card-logo ps-2 pe-2">
                      <img src="{{resource + channel['logo']}}">
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            </ng-container>
          </mat-radio-group>
        </mat-accordion>
      </div>

     
      

      <div class="mt-3 mb-6">
        <mat-checkbox id="eVoucherPolicy" class="eVoucherPolicy" color="primary" formControlName="eVoucherPolicy"> 
          <span *ngIf="data['summary']['original_discounted_price'] > 0">
            By clicking the Pay Now button, I confirm that I have read and agree to <a href="https://legal.parlon.ph/post/terms-of-use" target="_blank">Parlon Terms of Use</a>, E-Voucher Additional Terms and Conditions and <a href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
          </span>
          
          <span *ngIf="data['summary']['original_discounted_price'] <= 0">
            By clicking the Claim Your Free Deal button, I confirm that I have read and agree to <a href="https://legal.parlon.ph/post/terms-of-use" target="_blank">Parlon Terms of Use</a>, E-Voucher Additional Terms and Conditions and <a href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
          </span>
        
        </mat-checkbox> 
      </div>

      <div class="mt-3 mb-6"  *ngIf="isTransactionFailed == true">
        <p class="text-danger"><strong>Oh no, we failed to process your payment /  payment processing failed. </strong><br>
          {{error_message}} 
          </p>
      </div>

      <div class="p-6 d-md-none"></div>
      
      <div class="d-grid pay-btn">
        <div class="d-md-none mb-4">
          <div class="row align-items-center">
            <div class="col-6">
              <p class="fw-bold mb-0">Total (inc. VAT)</p>
              <p class="text-muted mb-0">{{data['summary']['total_redeemer']}} Redeemers</p>
            </div>
            <div class="col-6">
              <p class="text-end fw-bold tabular-nums mb-0">₱{{data['summary']['original_discounted_price'] | number}}</p>
            </div>
          </div>
        </div>
        <button mat-flat-button color="secondary" class="p-3 " type="submit" (click)="onSubmit()">
          <span *ngIf="data['summary']['original_discounted_price'] > 0">PAY NOW</span>
          <span *ngIf="data['summary']['original_discounted_price'] <= 0">CLAIM YOUR FREE DEAL</span>
          <mat-icon *ngIf="loading"><mat-spinner color="accent" diameter="20">
          </mat-spinner></mat-icon>
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</form>
<form id="cc-form" style="display: none;">
  <input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br/>
  <input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br/>
  <input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br/>
  <input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
</form>

<ngx-smart-modal #voucherModal identifier="voucherModal" [dismissable] = "false">
	<ng-container>
		<div class="modal-body p-2">
			<div class="row">
				<div class="col-12">
					<bag-details [data]="data['summary']"></bag-details>
				</div>
				<div class="col-md-6 d-flex justify-center align-center flex-column offset-md-3 mt-4 mb-4">
					<div class="text-center">
						<h4 class="text-secondary fw-semibold mb-2">Are you sure you want to push through with this transaction?</h4>
						<h4 class="text-muted fw-semibold mb-4">{{data['promo_code']?.code}}</h4>
					</div>
					<div class="row">
						<div class="col">
							<button mat-flat-button color="secondary" class="p-1 w-100" type="button" [disabled]="loading" (click)="requestPayment()">
								YES
								<mat-icon *ngIf="loading"><mat-spinner color="accent" diameter="20">
								</mat-spinner></mat-icon>
							</button>
						</div>
						<div class="col">
							<button mat-stroked-button color="primary" class="p-1 w-100" (click)="voucherModal.close()">
								NO
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>