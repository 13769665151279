import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../../services/settings/settings.service';
import { BeautyBashService } from '../../services/beauty-bash/beauty-bash.service';
import { DealsService } from '../../services/deals/deals.service';
import { ContentService } from '../../services/content/content.service';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare var My2c2p: any;

@Component({
  selector: 'app-beauty-bash-checkout',
  templateUrl: './beauty-bash-checkout.component.html',
  styleUrls: ['./beauty-bash-checkout.component.scss']
})
export class BeautyBashCheckoutComponent implements OnInit {

  constructor(
    private SettingsService: SettingsService,
    private route: ActivatedRoute,
    private ContentService: ContentService,
    private BeautyBashService:BeautyBashService,
    private DealsService:DealsService,
    private _formBuilder: FormBuilder,
    private UserAccountService: UserAccountService,
    private NgxSmartModalService: NgxSmartModalService,
    private el: ElementRef,
  ) { 
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
  }

  cc: object;

  hidePasswordIcon:boolean = true;

  resource = environment.resource;
  contentData: any = [];
  payment_channels;
  user: any;
  is_authenticated = false;
  isLoaded = false;
  deal = {};
  token = null;
  sign_in:FormGroup = this._formBuilder.group({
    email:['', [Validators.required, Validators.email]],
    password:['', Validators.required]
  });
  branch_selected = null;
  sign_submitted = false;
  get email(){return this.sign_in.get('email')};
  get password(){return this.sign_in.get('password')};
  inValidEmailOrPassword:boolean = false;
  selected_channel:string="";
  bought_date:string="";
  checkoutForm: FormGroup;
  isLoading = false;
  has_bought = false;
  policy = false;
  pay_loading = false;

  ngOnInit() {
    this.checkoutForm = new FormGroup({
      'paymentFormGroup': new FormGroup({
        'creditCard': new FormControl(null, Validators.required),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
      })
    });
    let slug = this.route.snapshot.paramMap.get("slug");
    this.SettingsService.getPaymentChannels('bash').subscribe(data=>{
      if(data != null) {
        this.payment_channels = data;
      }
    });
    this.UserAccountService.getLoggedInUser.subscribe(data=>{
      if(data != null) {        
        this.user = data;
        this.token = this.user['data'];
        this.is_authenticated = true;
      }
      this.ContentService.pulledContent.subscribe((data) => {
        if(!(data === undefined || data.length == 0)) { 
          this.contentData = data;
          this.BeautyBashService.getBashDeal(slug,this.contentData['bash_event']['id'],this.token).subscribe((data: {}) => {  
            if(data['data']['user_has_bought']) {
              this.has_bought = data['data']['user_has_bought'];
              this.bought_date = data['data']['date_purchased']['date'];
            }
            this.deal = data['data']['bash_deals'][0];
            this.isLoaded = true;
          });
        }
      });
    })
  }

  submitSignin() {
    if (this.sign_in.invalid) {
      return;
    }
    this.inValidEmailOrPassword = false;
    this.sign_submitted = true;
    let json = {email:this.email.value, password: this.password.value};
    let app = this;
    this.UserAccountService.login2(json).subscribe((data:any)=>{
      this.sign_submitted = false;
      if(data.success==false){
        if(data.message=="One time pin has been sent to your email. Please verify to continue."){
          const now = new Date()
          const expiry =  now.setMinutes(now.getMinutes() - 5);
          localStorage.setItem('otp-expiration',JSON.stringify( expiry));
        }      
        this.inValidEmailOrPassword = true;
      }else{
        this.UserAccountService.setActiveUser(data);
        this.user = data;
        this.token = this.user['data'];
        this.is_authenticated = true;
      }
    })
  }

  paymentOptionClick(target) {
    // let element: HTMLElement = document.getElementById(target) as HTMLElement;
    // console.log(element);
    this.selected_channel = target;
  }

  submitRequest(){
    this.isLoading = true;
    
    const branch_select: HTMLElement = this.el.nativeElement.querySelector('#select-branch');
    const voucherPolicy: HTMLElement = this.el.nativeElement.querySelector('#eVoucherPolicy');
    if(this.branch_selected == null) {
      const topOffset = 150; // Adjust the value as needed
      branch_select.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      branch_select.classList.add('error');
      return false;
     } else {
      branch_select.classList.remove('error');
     }

     if(this.policy == false) {
      voucherPolicy.classList.add('cb-error');
      return false;
     } else {
      voucherPolicy.classList.remove('cb-error');
     }

    let payload = {
      "bash_event_id":this.contentData['bash_event']['id'],
      "bash_deal_id": this.deal['id'],
      "branch_id": this.branch_selected['id'],
      "apiToken":this.token,
      "payment_channel_code": this.selected_channel,
     }

    if(this.selected_channel == 'parlon_credit_card' || this.selected_channel  == 'parlon_maya') {       
      // end of validate
      // alert(1);
      this.cc['cardnumber'] = this.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      this.cc['cvv'] = this.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      this.cc['month'] = this.checkoutForm.value.paymentFormGroup.ccMonth;
      this.cc['year'] = this.checkoutForm.value.paymentFormGroup.ccYear.toString();
      payload["payment_channel_code"] = this.selected_channel ;
      
      
      
      // alert(payload['securePayToken']);
     }

     setTimeout(()=>{
      My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {      
        payload['securePayToken'] = encryptedData.encryptedCardInfo;
      });

      if(this.selected_channel == 'parlon_maya') {
        let maya_fields = {
          card: {
            number:this.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, ''),
            expMonth:this.checkoutForm.value.paymentFormGroup.ccMonth,
            expYear:this.checkoutForm.value.paymentFormGroup.ccYear.toString(),
            cvc:this.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '')
          }
        };
        this.pay_loading = true;
        this.DealsService.getMayaToken(maya_fields).subscribe((response) => {
          payload['securePayToken'] = response['paymentTokenId'];
          this.requestPayment(payload);
        });
        return;
      }
      this.requestPayment(payload)
     },200)
          
  }

  requestPayment(payload) {
    this.pay_loading = true;
    this.BeautyBashService.requestPayment(payload,this.token).subscribe(data => { 
      setTimeout(() => {
        this.pay_loading = true;
        if(data['status'] == true && data['isRedirect'] == true){          
          window.location.href = data['response']['data'];
        }
      }, 1000);
      
    },()=>{
      this.isLoading = false;
    });
  }

  selectBranch(value) {
    this.branch_selected = value;
  }

  successRegister(e){
    this.NgxSmartModalService.getModal('registerModal').close();
    this.NgxSmartModalService.getModal('otpModal').open();
  }

  openRegisterModal() {
    this.NgxSmartModalService.getModal('registerModal').open();
  }

  otpEvent(e) {
    this.NgxSmartModalService.getModal('otpModal').close();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const el = this.el.nativeElement;
    try {
      let navbar = el.querySelector('.nav-extension'); 
      let scrollTargetPosition = 100;  

      if (window.scrollY > scrollTargetPosition) {
        navbar.classList.add("is-scrolled");
      } else {
        navbar.classList.remove("is-scrolled");;
      }
    } catch(e) { }
  }

  logout() {
    this.UserAccountService.logout();
    this.is_authenticated = false;
    this.sign_in.get('email').patchValue('');
    this.sign_in.get('password').patchValue('');
  }

}
