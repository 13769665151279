<section class="overflow-hidden">
	<div class="pb-9 pt-9 " style="background-color: #FFFAF7;">
		<div class="container" >		
			<div class="row justify-content-center gy-1 gy-md-1">
				<div class="col-12 col-md-9 col-lg-8 col-xl-7">
					<div class="d-flex justify-content-center">
						<img src="https://parlonph.s3.ap-southeast-1.amazonaws.com/assets/images/OG/OG_Logo_Colored.svg" alt="" class="mb-6 logo">
					</div>
					
					<div class="text-center">	
						<div id="SalonAndWellness"></div>					
						<p class="">Enjoy glowing perks from over {{brandsCount}} Parlon lifestyle partners nationwide.</p>
					</div>
					<!-- hide temporarily -->
					<!-- <div>
						<mat-form-field [floatLabel]="'never'" class="form-search-input w-100">		
							<input [(ngModel)]="searchInput"  (ngModelChange)="changeModel()" matInput placeholder="Search for Parlon OG Perks" type="search" />								
							<span matPrefix><i class="far fa-magnifying-glass fa-lg fa-fw"></i></span>				
						</mat-form-field>
					</div> -->
				</div>
				
			
				<!-- hide temporarily -->
				<!-- <div class="col-12" id="SalonAndWellness">
					
					<div *ngIf="categories.length>0"  class="justify-content-center flickity flickity-og-categories flickity-overflow row gx-6">
						<div *ngFor="let category of categories" class="slide">
							<app-category  (click)="selectCategory(category)"  [isActive]="category.is_selected" [catDetail]="category">	</app-category>
						</div>														
					</div>												
	
				</div> -->


				<div class="be-an-og d-flex justify-content-center" routerLink="/be-an-og-member" *ngIf="!isLoggedIn || !isOgMember">					
					<button class="ms-2 " mat-flat-button
					 class="show-me">SIGN UP TO BE A PARLON OG</button>
				</div>
	
				<div class="be-an-og d-flex justify-content-center" routerLink="/og-card"  *ngIf="isOgMember">
					<button class="ms-2 " mat-flat-button
					 class="show-me">SHOW ME MY OG CARD</button>
				</div>
			</div>
	</div>
	
	</div>
</section>

<section class="bg-white py-5 py-lg-3 mt-5">
	
	<div class="container">
		<div class="row align-items-center mb-4" >
			<div class="col-12 col-md">
				<h3 class="h3 fw-semibold mb-0" >OG Perks</h3>
			</div>

			<div class="col-md-auto d-none d-md-block">
				<a href="#modal-filters" data-bs-toggle="modal" class="fw-medium">
					<i class="fas fa-filter-list fa-fw me-1"></i>Filters
					<span class="text-filter-counter ms-1" *ngIf="countFilter>0">{{countFilter}}</span>
				</a>
			</div>
		</div>

		<div class="main-content">

			<!--
				NOTE: Use `*ngIf` to toggle view between grid and no results screen; remove `d-none` to show content
			-->

			<div class="row">
				<div class="col-12 mb-9 ">
					<mat-tab-group #tabGroup color="secondary" dynamicHeight class="tab-og-perks-view">
						
						<!-- Catalog view -->
						<mat-tab>
							<ng-template mat-tab-label>
								<span class="fs-6"><i class="far fa-images fa-fw me-2"></i>Catalog</span>
							</ng-template>
	
							<div class="pt-6 pt-md-8">
								
								<div class="row justify-content-end mb-6 d-md-none">
									<div class="col-auto">
										<a href="#modal-filters" data-bs-toggle="modal" class="fw-medium">
											<i class="fas fa-filter-list fa-fw me-1"></i>Filters
											<span class="text-filter-counter ms-1" *ngIf="countFilter>0">{{countFilter}}</span>
										</a>
									</div>
								</div>


								<div class="row justify-content-center mt-5" *ngIf="offers.length==0 && isLoading">
									<div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
										<div class="ratio ratio-1x1 d-block w-75 w-lg-80 w-xxl-100 mx-auto mb-7">
											<img src="assets/images/OG/og-perk-search-no-result.svg" alt="" loading="lazy" class="img-fluid obj-fit-contain">
										</div>
					
										<div class="text-center">
											<h6 class="h6">Sorry! We couldn't find any offers for that search</h6>											
										</div>
									</div>
								</div>
	
								<div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-sm-4 gx-md-6 gx-lg-4 gx-xl-5 gy-6 mb-7">
									<div class="col" *ngFor="let offer of offers | paginate: offer_config">
										<perk-card-img-template [offerDetail]="offer"></perk-card-img-template>
									</div>										
								</div>
	
								<div class="d-flex justify-content-center mt-9" *ngIf="offers.length>0">
									<pagination-template #offerApi="paginationApi" [id]="offer_config.id" (pageChange)="pageChangeEventOffer($event)">
										<div class="ngx-pagination">
											<div class="pagination-previous" [class.disabled]="offerApi.isFirstPage()">
												<a *ngIf="!offerApi.isFirstPage()" (click)="offerApi.previous()">
												</a>
											</div>
				
											<div class="page" *ngFor="let page of offerApi.pages" [class.current]="offerApi.getCurrent() === page.value">
												<a (click)="offerApi.setCurrent(page.value)" *ngIf="offerApi.getCurrent() !== page.value">
													<span>{{ page.label }}</span>
												</a>
												<div *ngIf="offerApi.getCurrent() === page.value">
													<span>{{ page.label }}</span>
												</div>
											</div>
				
											<div class="pagination-next" [class.disabled]="offerApi.isLastPage()">
												<a *ngIf="!offerApi.isLastPage()" (click)="offerApi.next()">
												</a>
											</div>
										</div>
									</pagination-template>
								</div>
							</div>
						</mat-tab>
	
						<!-- List view -->
						<mat-tab >
							<ng-template mat-tab-label>
								<span class="fs-6"><i class="fas fa-list-ul fa-fw me-2"></i>Brands</span>
							</ng-template>
	
							<div class="pt-6 pt-md-8">
								<div class="row justify-content-end mb-6 d-md-none">
									<div class="col-auto">
										<a href="#modal-filters" data-bs-toggle="modal" class="fw-medium">
											<i class="fas fa-filter-list fa-fw me-1"></i>Filters
											<span class="text-filter-counter ms-1" *ngIf="countFilter>0">{{countFilter}}</span>
										</a>
									</div>
								</div>
								

							<div class="row justify-content-center mt-5" *ngIf="brands.length==0 && isLoading">
								<div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
									<div class="ratio ratio-1x1 d-block w-75 w-lg-80 w-xxl-100 mx-auto mb-7">
										<img src="assets/images/OG/og-perk-search-no-result.svg" alt="" loading="lazy" class="img-fluid obj-fit-contain">
									</div>
				
									<div class="text-center">
										<h6 class="h6">Sorry! We couldn't find any brands for that search</h6>
										<!-- <p class="text-muted mb-0">Try changing your search or location!</p> -->
									</div>
								</div>
							</div>


								<div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-lg-4 gx-xl-5 gy-4 gy-md-6 mb-7">
									<div [id]="brand.name+brand.objectID" class="col" *ngFor="let brand of brands">
										<perk-card-template (click)="selectBrand(brand)" [brandDetail]="brand"></perk-card-template>
									</div>									
								</div>

								<!-- <div class="d-flex justify-content-center mt-9" *ngIf="brands.length>0">
									<pagination-template #brandApi="paginationApi" [id]="brand_config.id" (pageChange)="pageChangeEventBrand($event)">
										<div class="ngx-pagination">
											<div class="pagination-previous" [class.disabled]="brandApi.isFirstPage()">
												<a *ngIf="!brandApi.isFirstPage()" (click)="brandApi.previous()">
												</a>
											</div>
				
											<div class="page" *ngFor="let page of brandApi.pages" [class.current]="brandApi.getCurrent() === page.value">
												<a (click)="brandApi.setCurrent(page.value)" *ngIf="brandApi.getCurrent() !== page.value">
													<span>{{ page.label }}</span>
												</a>
												<div *ngIf="brandApi.getCurrent() === page.value">
													<span>{{ page.label }}</span>
												</div>
											</div>
				
											<div class="pagination-next" [class.disabled]="brandApi.isLastPage()">
												<a *ngIf="!brandApi.isLastPage()" (click)="brandApi.next()">
												</a>
											</div>
										</div>
									</pagination-template>
								</div> -->
							</div>
						</mat-tab>

						<!--dont delete this commented temporarily -->
						<!-- <mat-tab *ngIf="isOgMember">
							<ng-template mat-tab-label>
								<span class="fs-6"><i class="fa-regular fa-gift-card me-2"></i>Redeemed OG Offers</span>
							</ng-template>
							<div class="pt-6 pt-md-8">
								<app-no-redeem  (tabSelected)="onTabSelected($event)"></app-no-redeem>
								<div class="row gx-5 mt-7 gy-5">
									<div class="col-lg-4 col-md-6 col-sm-12">
										<app-redeemed-offer></app-redeemed-offer>
									</div>	
									<div class="col-lg-4 col-md-6 col-sm-12" >
										<app-redeemed-offer></app-redeemed-offer>
									</div>	
									<div class="col-lg-4 col-md-6 col-sm-12">
										<app-redeemed-offer></app-redeemed-offer>
									</div>	
																		
								</div>
								
							</div>							

						</mat-tab> -->
					</mat-tab-group>
				</div>
			</div>

			
		</div>
	</div>
</section>

<!-- Filter modal -->
<div id="modal-filters" class="modal modal-perk-filters fade" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onClose()">
	<div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down">
		<div class="modal-content">
			<div class="modal-body p-5">
				<div class="row justify-content-end">
					<div class="col-auto">
						<a href="#" class="link-text" data-bs-dismiss="modal">
							<i class="far fa-xmark fa-lg fa-fw"></i>
						</a>
					</div>
				</div>

				<div class="row" >
					<div class="col-12 og-type-modal" >
						<h6 class="h6 fw-semibold mb-4">Parlon OG Perks Type</h6>						
						<div class="filter-checklist">
							<mat-checkbox [(ngModel)]="selectedFreebie" (change)="updateSelection('selectedFreebie', selectedFreebie)">Freebie</mat-checkbox>
							<mat-checkbox [(ngModel)]="selectedDiscount" (change)="updateSelection('selectedDiscount', selectedDiscount)">Discount</mat-checkbox>
							<mat-checkbox [(ngModel)]="selectedSpecial" (change)="updateSelection('selectedSpecial', selectedSpecial)">I'm special!</mat-checkbox>							
						</div>
					</div>

					<div class="col-12">
						<hr class="my-6">
					</div>
					<div class="filter-container" #myDiv>
						<div class="col-12" class="filter">
							<h6 class="h6 fw-semibold mb-4">Brands</h6>
							<!-- {{appliedBrandsForFilter|json}} -->
							<div class="filter-checklist">
								<mat-checkbox *ngFor="let brand of allBrandsForFilter" [(ngModel)]="brand.selected">{{brand.brand_name}}</mat-checkbox>							
							</div>
						</div>
	
						<div class="col-12 mt-6 filter-buttons">
							<div class="d-grid gap-3">
								<button data-bs-dismiss="modal" (click)="applyFilter()" mat-flat-button color="primary" class="btn-filter-action" >Apply filters</button>
								<button data-bs-dismiss="modal" (click)="resetFilter()" mat-stroked-button color="primary" class="btn-filter-action">Reset filters</button>
							</div>
						</div>

					</div>
					
				</div>
			</div>
		</div>
	</div>
</div>