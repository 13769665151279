<div class="card bg-gray-fa rounded-3 mt-lg-0 mb-0">
	<div class="card-body">
		<h4 class="h4 fw-bold mb-4"><span class="text-primary"><i class="fak fa-beauty-bag fa-lg fa-fw"></i></span> My Beauty Bag</h4>
		<div class="row row-cols-1 gx-md-8">
			<div class="col">
				<div *ngFor="let deal of data['summaryPerParlon'] | keyvalue">
					<p class="fw-bold mb-0">{{deal.value.name}}</p>
					<div class="row" *ngFor="let item of deal.value.items | keyvalue">
						<div class="col-8">
							<p class="mb-0">{{item.value.item_name}}</p>
							<p class="text-muted mb-0">QTY: {{item.value.quantity}}</p>																					
						</div>
						<div class="col-4">
							<p class="text-end tabular-nums mb-0">₱{{item.value.total | number}}</p>
							
						</div>
					</div>

					<!-- beauty bad candy static -->
					<div class="mt-1 d-flex align-items-center justify-content-between" *ngIf="deal.value.brandedCandiesUsed > 0">
						
						<div class="col-9 d-flex align-items-center candies-applied-container">
							<img  width="25" src="https://parlon.s3.ap-southeast-1.amazonaws.com/Candies/candy-pink.svg" alt="">
							<span class="text-primary">{{deal.value.name}} Candies Applied</span>																
						</div>
						<div class="text-primary">
							-₱{{deal.value.brandedCandiesUsed | number}}
						</div>					
					</div>

					<hr>
				</div>

				<table class="table table-borderless table-sm">
					<tbody>
						<tr>
							<td class="text-muted ps-0">Subtotal ({{data['total_redeemer']}} Redeemers)<span></span></td>
							<td class="text-end tabular-nums pe-0">₱{{data['original_deal_price'] | number}}</td>
						</tr>
						<tr *ngIf="data['candies_discount'] > 0">
							<td>
								<div class="d-flex align-items-center candies-applied-container" >
									<img  width="25" src="https://parlon.s3.ap-southeast-1.amazonaws.com/Candies/candy-pink.svg" alt="">
									<span class="text-primary">Parlon Candies Applied</span>																
								</div>
							</td>
							<td class="text-end tabular-nums pe-0 ">
								<span class="text-primary">
									-₱{{data['candies_discount'] | number}}
								</span>
							</td>
						</tr>

						<tr *ngIf="data['coupon_discount'] > 0">
							<td class="text-primary ps-0"><i class="far fa-tag mr-2"></i> Coupon Discount</td>
							<td class="text-primary text-end tabular-nums pe-0"> -₱{{data['coupon_discount'] | number}}</td>
						</tr>

					</tbody>
				</table>

				<hr>

				<div class="row align-items-center">
					<div class="col-6">
						<p class="fw-bold mb-0">Total (inc. VAT)</p>
					</div>
					<div class="col-6">
						<p class="text-end fw-bold tabular-nums mb-0">₱{{data['original_discounted_price'] | number}}</p>
					</div>
				</div>
				<div *ngIf="isLoggedIn">
					<hr>
					<p class="text-muted">You'll earn <span class="fw-bold">{{data['candy_earnings'] | number:'1.0-0'}} </span>Parlon Candies from this purchase! </p> 
				</div>
								
			</div>						
		</div>
	</div>
</div>	


<!-- <div class="card shadow-sm card-container">
	<div *ngIf="isLoggedIn" class="card-body">					
		<div class="d-flex align-items-center">
			<img width="28px" height="28px" src="https://parlon.s3.ap-southeast-1.amazonaws.com/Candies/earn-candy-icon.svg" alt="">
			<span class="fw-bold ms-2">Candies you'll earn after purchase</span>
		</div>

		<div class="d-flex justify-content-between mt-2">
			<span>Parlon Candies</span>
			<span>+148</span>
		</div>

		<div class="d-flex justify-content-between">
			<span>The Secret Lounge Candies</span>
			<span>+17</span>
		</div>

		<div class="d-flex justify-content-between">
			<span>Benibana Beauty Hub Candies</span>
			<span>+17</span>
		</div>
		
		<hr>
		
		<div class="d-flex justify-content-between">
			<span class="fw-bold">Total Candies of different kinds</span>
			<span class="fw-bold">+221</span>
		</div>
		
	</div>

</div> -->