import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ContentService } from '../../services/content/content.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-beauty-bash-teaser',
  templateUrl: './beauty-bash-teaser.component.html',
  styleUrls: ['./beauty-bash-teaser.component.scss']
})
export class BeautyBashTeaserComponent implements OnInit {

  contentData: any = [];
  contentLoaded = false;
  startDate;
  daysDiff:any = 0;
  hoursDiff:any = 0;
  minDiff:any = 0;
  secDiff:any = 0;
  duration:any;
  resource = environment.resource;

  constructor(
    private ContentService: ContentService
  ) { }

  ngOnInit() {
    let app = this;
  //   setInterval(function(){
  //     let currentDate = moment();
  //     app.duration = moment.duration(app.startDate.diff(currentDate));
  //     app.daysDiff = Math.floor(app.duration.asDays());
  //     app.hoursDiff = Math.floor(app.duration.asHours() - (app.daysDiff * 24));
  //     app.minDiff = Math.floor(app.duration.asMinutes() - ((app.daysDiff * 1440) + (app.hoursDiff * 60)));
  // }, 1000);
    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentData = data;
        app.startDate = moment(this.contentData['bash_event']['from']);
        this.contentLoaded = true;
        setInterval(function(){
            let currentDate = moment();
            app.duration = moment.duration(app.startDate.diff(currentDate));
            app.daysDiff = Math.floor(app.duration.asDays());
            app.hoursDiff = Math.floor(app.duration.asHours() - (app.daysDiff * 24));
            app.minDiff = Math.floor(app.duration.asMinutes() - ((app.daysDiff * 1440) + (app.hoursDiff * 60)));
            app.secDiff = Math.floor(app.duration.asSeconds() - ((app.daysDiff * 86400) + (app.hoursDiff * 3600) + (app.minDiff * 60)));
        }, 1000);
      }
    });
  }

}
