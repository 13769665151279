import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Event, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ContentService } from '../../services/content/content.service';
import * as Rx from 'rxjs';
import { environment } from '../../../environments/environment';
import * as bootstrap from 'bootstrap';
import { DealFilterMobileServiceService } from '../../services/deal-filter-mobile-service/deal-filter-mobile-service.service';
import { AnnouncementbarService } from '../../services/announcementbar/announcementbar.service';


declare var Flickity: any;

@Component({
  selector: 'app-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styleUrls: ['./nav-drawer.component.scss']
})
export class NavDrawerComponent implements OnInit,AfterViewInit{

  rxSubscription: Rx.Subscription;
  contentData;
  contentLoaded = false;
  resource = environment.resource;
  features: any = [];
  isAnnouncementBarExisting:boolean = false;

  selectedCountry:any = "Philippines";

  constructor(
    private el: ElementRef,
    private router:Router,
    private ContentService: ContentService,
    private dealFilterService:DealFilterMobileServiceService,
    private announcementService:AnnouncementbarService
  ) { }

  ngAfterViewInit() {
    this.announcementService.isAnnouncementBarExisting$.subscribe(data=>{
      this.isAnnouncementBarExisting = data;      
    })
  }

  selectCountry(event:any){
    if(this.selectedCountry=='Philippines')
      window.location.href ="https://www.parlon.ph/";
    else
      window.location.href = "https://www.parlon.sg/";  
    
  }

  ngOnInit() {
    const el = this.el.nativeElement;
    setTimeout(() => {
      let elem = el.querySelector('.flickity-drawer-deal-collections');
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: false
      });
    }, 500);

    this.selectedCountry = environment.country;   

    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentLoaded = true;
        this.contentData = data;
      }
    });

    this.rxSubscription = this.router.events.subscribe(event => {   
      if (event instanceof NavigationEnd) {
        const drawer = document.querySelectorAll('.offcanvas');
        const backdrop = document.querySelectorAll('.offcanvas-backdrop');
        document.body.style.overflow = "unset";
        for (let i = 0; i < drawer.length; i++) {
          drawer[i].classList.remove('show');
          drawer[i].classList.add('hide');
        }
        for (let i = 0; i < backdrop.length; i++) {
          backdrop[i].classList.remove('show');
          backdrop[i].classList.add('hide');
        }
      }
    });

    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
    });
  }

  navigateCategory(value){
    this.dealFilterService.updateReloadSubject(1);
    this.router.navigate(['/all-parlon-deals'], { 
      queryParams: {c:value},
    })
  }
  navigateSubCategory(c,sc){
    this.dealFilterService.updateReloadSubject(1);
    this.router.navigate(['/all-parlon-deals'],{ 
      queryParams: {c,sc},
    })
  }

}
