<nav class="navbar navbar-expand py-3 beauty-bash" aria-hidden="true" [ngClass]="nav_display">
  <div class="container desktop d-flex justify-content-between overflow-hidden">
    <a routerLink="/beauty-bash/deals">
      <img src="{{resource}}BeautyBash/beauty-bash.png" alt="" class="img-fluid img-navbar">
    </a>
    <div class="time-dial" [ngClass]="timer_display">
      <div class="dial-col">
        <div class="dial">
          <span *ngIf="daysDiff > 0">{{daysDiff}}</span>
          <span *ngIf="daysDiff < 1">X</span>
        </div> 
        <span class="label gold">DAYS</span>
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{hoursDiff}}</span>
        </div> 
        <span class="label gold">HOURS</span>
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span>{{minDiff}}</span>
        </div> 
        <span class="label gold">MINUTES</span> 
      </div>
      <div class="divider">
        :
      </div>
      <div class="dial-col">
        <div class="dial">
          <span >{{secDiff}}</span>
        </div> 
        <span class="label gold">SECONDS</span> 
      </div>
    </div>
    <a routerLink="/" class="nav-link d-none d-md-block"><span>Back to Parlon</span> <img src="assets/images/logo_outline.svg" width="25" class="ms-1"></a>
    <a [routerLink]="['/']" class="d-sm-block d-md-none nav-link">
      <span [ngClass]="back_display" class="me-4">Back to Parlon</span><img src="assets/images/logo_outline.svg" alt="" class="img-fluid img-navbar-logo">
    </a>
  </div>
</nav>