import { Component, OnInit, ElementRef, Input, HostListener, Inject, ViewChild } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { Router, Event, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import * as Rx from 'rxjs';
import { WINDOW } from '@ng-toolkit/universal';
import { DealsService } from '../../services/deals/deals.service';
import { environment } from '../../../environments/environment';

import { UserAccountService } from '../../services/user-account/user-account.service';
import { DrawerService } from '../../services/drawer/drawer.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AlertService } from '../../services/alert.service';
import { DatePipe } from '@angular/common';
import { env } from 'process';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { dateValidator } from '../../validators/date-validator';
import { AnnouncementbarService } from '../../services/announcementbar/announcementbar.service';

declare var Flickity: any;

enum NavbarType {
  GLOBAL,
  SERVICES,
  DEALS,
  SEARCH,
  MOBILE_SEARCH,
  NO_CATEGORY,
  LOGO_ONLY,
  PARLON_INNER,
  BEAUTY_BASH
}


@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  

  constructor(
    private el: ElementRef,
    private ContentService: ContentService,
    private router:Router,
    private DealsService:DealsService,
    private userService:UserAccountService,
    private drawerService:DrawerService, 
    private smartModal: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public datepipe: DatePipe,
    private announcementService:AnnouncementbarService
  ) { }

  @ViewChild(UserLoginComponent) userLogin : UserLoginComponent;

  NavbarType = NavbarType;
  navbarTypeSubject$ = new Rx.BehaviorSubject<NavbarType>(NavbarType.GLOBAL);
  navbarType$ = this.navbarTypeSubject$.asObservable();
  contentLoaded = false;
  contentData;
  rxSubscription: Rx.Subscription;
  protected _onDestroy = new Rx.Subject<void>();
  cartCount:any = 0;
  resource = environment.resource;
  navEmpty = environment.emptyNav;
  fbRegForm: FormGroup;
  formSubmitted = false;
  formLoading = false;

  isVisible = false;

  isRouteHidden = false;

  navTypes = [
    {
      'route': '/beauty-bag-deals',
      'nav_type': NavbarType.DEALS
    },
    {
      'route': '/search',
      'nav_type': NavbarType.SEARCH
    },
    {
      'route': '/mobile/search',
      'nav_type': NavbarType.MOBILE_SEARCH
    },
    {
      'route': '/all-parlon-deals',
      'nav_type': NavbarType.NO_CATEGORY
    },
    {
      'route': '/beauty-bag-checkout',
      'nav_type': NavbarType.LOGO_ONLY
    },
    {
      'route': '/gifting',
      'nav_type': NavbarType.NO_CATEGORY
    },
    {
      'route': '/parlon/',
      'nav_type': NavbarType.PARLON_INNER
    },
    {
      'route': '/book-appointment',
      'nav_type': NavbarType.LOGO_ONLY
    },
    {
      'route': '/purchased-deal-appointment',
      'nav_type': NavbarType.LOGO_ONLY
    },
    {
      'route': '/beauty-bash/',
      'nav_type': NavbarType.BEAUTY_BASH
    },
    {
      'route': '/booking-payment',
      'nav_type': NavbarType.LOGO_ONLY
    },
  ];


  navHidden = [
    {
      'route': '/beauty-bag-deals',
      'type': 'full'
    },
    {
      'route': '/beauty-bag-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/verify',
      'type': 'dynamic'
    },
    {
      'route': '/gifting',
      'type': 'dynamic'
    },
    {
      'route': '/promo-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/glife',
      'type': 'dynamic'
    },
    {
      'route': '/payment-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/payments/',
      'type': 'dynamic'
    },
    {
      'route': '/booking-status',
      'type': 'dynamic'
    },
    {
      'route': '/voucher-booking-status',
      'type': 'dynamic'
    }
  ];
  
  

  selectedRoute="default";
  hideSearchDropDown = false;
  
  isLoggedIn:boolean = false;
  isForgotPassword:boolean = false;
  teaser = environment.teaser;

  hideDropdown = false;
  
  ngOnInit() {

   

    if(this.teaser == true) {
      this.navHidden.push({
        'route': '/',
        'type': 'full'
      })
    }

    this.drawerService.isShowForgotPassword.subscribe(data=>{
      this.isForgotPassword = data;
    })  

    this.userService.getLoggedInUser.subscribe(data=>{
      this.isLoggedIn = data ? true : false;     
    })

    this.DealsService.currentCountCart.subscribe(count => { 
      this.cartCount = count;
      if(!count) {
        this.cartCount = localStorage.getItem('bagCount');
      }
    });

    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentLoaded = true;
        this.contentData = data;
        // added by angelito because the older code causes performance issue and to fix 
        //  Unnecessary space between top nav and left nav drawer in mobile
        this.announcementHidden();
      }
    });
    this.checkNavType(this.router.url);
    this.rxSubscription = this.router.events.subscribe(event => {   
      
      if (event instanceof NavigationEnd) {
        const { url } = event;
        this.checkNavType(url);
      }
    });


    let initialLoadUrl = this.router.url;
    if(initialLoadUrl.startsWith('/search/')){
      this.selectedRoute = 'search';
      this.hideSearchDropDown = true
      // navbar.style.display='none';
    }else if (initialLoadUrl.startsWith('/mobile/')) {
      this.selectedRoute = 'mobile';
      this.hideSearchDropDown = true;
      // navbar.style.display='none';
    }
    else{
      this.selectedRoute='default';
      this.hideSearchDropDown = false
      // navbar.style.display='block';
    }


    this.router.events.subscribe(event => {      
      if (event instanceof NavigationEnd) {        
        const { url } = event;
        if (url.startsWith('/search/')) {
          this.selectedRoute = 'search';
          this.hideSearchDropDown = true;
          // navbar.style.display='none';
        }else if (url.startsWith('/mobile/')) {
          this.selectedRoute = 'mobile';
          this.hideSearchDropDown = true;
          // navbar.style.display='none';
        }
        else{
          this.selectedRoute='default';
          this.hideSearchDropDown = false;
          // navbar.style.display='block';
        }
        this.checkRoutesHidden();
      }
      
    });

    this.fbRegForm = this.formBuilder.group({
      first_name: ['',[ Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contact_number: ['',[ Validators.required]],
      birthday: ['',[Validators.required,dateValidator]],
      access_token: ['',[ Validators.required]],
      social_id: ['',[ Validators.required]],
      acceptTerms: [false, [Validators.requiredTrue]]
  });
  this.checkRoutesHidden();


  }

  checkRoutesHidden() {
    let visible = true;
    this.navHidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          visible = false;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          visible = false;
        }
      } 
    });

    this.isRouteHidden = visible;

    return visible;
  }

  // announcementHidden() {
  //   console.log('announcementHidden')
  //   this.router.events.subscribe((event)=>{
  //     console.log(event)
  //     if (event instanceof NavigationEnd) {
        
  //       let visible = false;
  //       if(this.contentLoaded == true && (this.selectedRoute != 'mobile' &&  this.selectedRoute != 'search' )) {
  //         visible = true;
  //       }
  //       const hidden = [
  //         {
  //           'route': '/all-parlon-deals',
  //           'type': 'full'
  //         },
  //         {
  //           'route': '/beauty-bag-deals',
  //           'type': 'dynamic'
  //         },
  //         {
  //           'route': '/beauty-bag-checkout',
  //           'type': 'dynamic'
  //         },
  //       ];
  //       hidden.forEach(route => {
  //         if(route['type'] == 'full') {
  //           if (this.router.url === route['route']){
  //             visible = false;
  //           }
  //         } else {
  //           if (this.router.url.includes(route['route'])){
  //             visible = false;
  //           }
  //         } 
  //       });

  //       console.log(visible)

  //       this.announcementService.updateAnnouncementBarExistingStatus(visible);

  //       this.isVisible = visible;
  //         }
  //       })

    
  // }
  
  announcementHidden() {      
    const hidden = [
      {
        'route': '/all-parlon-deals',
        'type': 'full'
      },
      {
        'route': '/beauty-bag-deals',
        'type': 'dynamic'
      },
      {
        'route': '/beauty-bag-checkout',
        'type': 'dynamic'
      },
    ];
  
    const currentUrl = this.router.url;
    this.updateAnnouncementBarVisibility(currentUrl, hidden);
  
    this.router.events.subscribe((event) => {      
      if (event instanceof NavigationStart) {
        const url = (event as NavigationStart).url;
        this.updateAnnouncementBarVisibility(url, hidden);
      }

      if (event instanceof NavigationEnd) {
        const url = (event as NavigationEnd).url;
        this.updateAnnouncementBarVisibility(url, hidden);
      }
    });
  }
  
  private updateAnnouncementBarVisibility(url: string, hidden: any[]): void {
    let visible = true;
  
    if (this.contentLoaded && this.selectedRoute !== 'mobile' && this.selectedRoute !== 'search') {
      for (const route of hidden) {
        if (route.type === 'full' && url === route.route) {
          visible = false;
          break;
        } else if (route.type === 'dynamic' && url.includes(route.route)) {
          visible = false;
          break;
        }
      }
    } else {
      visible = false;
    }      
    this.announcementService.updateAnnouncementBarExistingStatus(visible);
    this.isVisible = visible;
  }
  
  
  

  ngOnDestroy() {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }

    this._onDestroy.next();
    this._onDestroy.complete();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    // Parlon deals mobile nav on scroll
    
    const scrollable = document.querySelector('.scrollable')
    const isScrolled = "is-scrolled";
    const dealsCheckpoint = document.getElementById('deals-checkpoint');
    const el = this.el.nativeElement;
    const navbar = el.querySelector('.navbar.is-hidden-on-home');
    this.hideDropdown =true;
    let search_bar = el.querySelector('#search-dialog-box');
    search_bar.classList.remove('show');       
    let scrollTargetPosition = 150;
    if (dealsCheckpoint != null) {
      const elementOffsetTop = dealsCheckpoint.offsetTop;
      if (elementOffsetTop >= window.pageYOffset) {
        scrollable.classList.remove(isScrolled);
      } else {
        scrollable.classList.add(isScrolled);
      }
    }

    if(this.router.url == '/') {
      try {
        if (navbar.getAttribute('aria-hidden') == 'true' && window.scrollY > scrollTargetPosition) {
          navbar.setAttribute('aria-hidden', false);
          
          navbar.classList.add('showing');
          setTimeout(() => {
            navbar.classList.add('show');
            navbar.classList.remove('showing');
          }, 200);
  
        } else if (navbar.getAttribute('aria-hidden') == 'false' && window.scrollY < scrollTargetPosition) {
          navbar.setAttribute('aria-hidden', true);
  
          navbar.classList.add('showing');
          navbar.classList.remove('show');
          search_bar.classList.remove('show');
          setTimeout(() => {
            navbar.classList.remove('showing');
          }, 200);
        }
      } catch(e) { }
    }

  }

  checkNavType(url) {
    this.navbarTypeSubject$.next(NavbarType.GLOBAL);
    this.navTypes.forEach(e => {
      if(url.startsWith(e['route'])) {
        this.navbarTypeSubject$.next(e['nav_type']);
      }
    });
  }

  showSearch() {
    const el = this.el.nativeElement;
    let search_bar = el.querySelector('#search-dialog-box');
    search_bar.classList.toggle('show');
    window.setTimeout(function () { 
      document.getElementById('search-input-field').focus(); 
    }, 500); 
  }

  fbAuthHandler(data) {
    if(data) {
      this.fbRegForm.patchValue({
        first_name: data['first_name'],
        last_name: data['last_name'],
        email: data['email'],
        contact_number: data['contact_number'],
        birthday: data['birthday'],
        access_token: data['access_token'],
        social_id: data['social_id']
      });
      this.smartModal.setModalData({data
      }, 'fbAuthModal', true);
      this.smartModal.getModal('fbAuthModal').open();
    }
  }

  fbRegisterSubmit() {
    this.formSubmitted = true;
    this.formLoading = true;

    // stop here if form is invalid
    if (this.fbRegForm.invalid) {
        this.formLoading = false; 
        return;
    }

    try {
      const payload = {
        "provider": "facebook",
        "access_token": this.fbRegForm.value.access_token,
        "social_id": this.fbRegForm.value.social_id,
        "user_details": {
            "first_name": this.fbRegForm.value.first_name,
            "last_name": this.fbRegForm.value.last_name,
            "email": this.fbRegForm.value.email,
            "birthday": this.datepipe.transform(this.fbRegForm.value.birthday, 'yyyy-MM-dd'),
            "contact_number": this.fbRegForm.value.contact_number
        }
      };

      this.userService.registerWithFB(payload).subscribe((data: {}) => {
        if(data['success'] == true) {
          const jsonData = {
            success:true,
            data:data['data'],
            user: data['user'],
            candies:data['candies'],
            bookings: data['bookings'],
            vouchers: data['vouchers'],
            bagSummary: data['bagSummary'],
            og:data['og']
          };
          this.userService.setActiveUser(jsonData);
          this.smartModal.getModal('fbAuthModal').close();
          this.router.navigate(['/']);
          this.formLoading = false; 
        } else {
          // Swal.fire({
          //   type: 'error',
          //   title: 'Oops...',
          //   text: 'Something went wrong!'
          // })
          this.alertService.error(data['data']);
          this.formLoading = false; 
        }
      });   
    } catch (error) {
      console.log(error)
  }}

  callFbShowAuth(){
    this.userLogin.fbAuthModalShow();
  }

  // convenience getter for easy access to form fields
  get f() { return this.fbRegForm.controls; }

}

