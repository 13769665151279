import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'content-banner',
  templateUrl: './content-banner.component.html',
  styleUrls: ['./content-banner.component.scss']
})
export class ContentBannerComponent implements OnInit {

  @Input() data:any;
  resource = environment.resource;

  constructor() { }

  ngOnInit() {
  }

}
