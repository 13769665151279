import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }

    private extractData(res: Response) {
      let body = res;
      return body || { };
    }

    getCategory(category): Observable<any> {
      return this.http.get(endpoint + 'categories/' + category, httpOptions).pipe(
        map(this.extractData),
        catchError(this.handleError('getCategory'))
      );
    }

    getCategories(fields = ""): Observable<any> {
      return this.http.get(endpoint + 'categories' + fields, httpOptions).pipe(
        map(this.extractData));
    }

    getAllDealsByCategory(): Observable<any> {
      return this.http.get(endpoint + 'deals/get-all-deals-by-category', httpOptions).pipe(
        map(this.extractData),
        catchError(e => {
          return of (null)
        })
      )
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        //  better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
        this.router.navigate(['/']);
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
}
