import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SharedService } from '../../../../../services/shared.service';
import { ActivatedRoute } from '@angular/router';
import * as Rx from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { User } from '../../../../../_models/user.model';
import { AuthService } from '../../../../../services/auth.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.scss']
})
export class ServiceCategoryComponent implements OnDestroy, OnInit {

  @Input()
  data: any[];
  branch: any[];
  selectedService = [];
  currency = environment.currency;

  // currentUser: User;

  selectedServices$: Rx.Observable<any>;
  currentServicesSubscription?: Rx.Subscription;

  constructor(
    private _shared: SharedService,
    private route: ActivatedRoute,
    private smartModal: NgxSmartModalService,
    private auth: AuthService
  ) { 
    // this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit() {
    this.branch = this.route.parent.snapshot.data['branch'];
    this.currentServicesSubscription = this._shared.currentService.subscribe(
      service => {
        this.selectedService = service;
      },
    );
    this.selectedServices$ = this._shared.currentService;
  }

  selectService(service_id, header_id, service_label, price) {
    let selected = {
      'service_id': service_id,
      'header_id': header_id,
      'service': service_label,
      'price':price
      // 'user_token': this.currentUser
    };

    if(this.isServiceSelected(service_id, header_id)) {
      this.selectedService = this.unselectService(
        service_id,
        header_id,
      );
    } else {
      this.selectedService.push(selected);
    }
    this._shared.selectService([...this.selectedService]);
  }

  unselectService(service_id, header_id) {
    return this.selectedService.filter(selected =>
      selected.header_id !== header_id
      || selected.service_id !== service_id
    );
  }

  showModal(selectedService) {
    const parentService = this.findParentService(
      this.branch['services'],
      selectedService.parent,
    );

    const servicesPricesMap = parentService.headers.map((header, index) => ({
      service_id : selectedService.id,
      service_label : selectedService.label,
      service_visibility : selectedService.visibility,
      name: header,
      header_id: selectedService.headers[index].id,
      price: selectedService.prices[index],
    }));

    this.smartModal.setModalData({
      serviceOptions: {
        label: selectedService.label,
        services: servicesPricesMap,
      },
    }, 'serviceModal', true);

    this.smartModal.getModal('serviceModal').open();
  }
  
  remove(arr, item) {
    let a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
      item = a[--L];
        while ((ax= arr.indexOf(item)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
  }

  findParentService(services, parentId) {
    if (!services) {
      return undefined;
    }

    let parentService = services.find(service => service.id === parentId);

    if (!parentService) {
      for (let index = 0; index < services.length; index++) {
        parentService = this.findParentService(services[index].services, parentId);

        if (parentService) {
          break;
        }
      }
    }
    return parentService;
  }

  isServiceSelected(serviceId, headerId) {
    return !!this.selectedService.find(
      service =>
        service.service_id === serviceId
        && service.header_id === headerId,
    );
  }

  ngOnDestroy() {
    if (this.currentServicesSubscription) {
      this.currentServicesSubscription.unsubscribe();
    } 
  }

}
