import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { BookAppointmentMessageComponent } from './book-appointment-message.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule, MaterialModule, RouterModule
  ],
  declarations: [BookAppointmentMessageComponent]
})
export class BookAppointmentMessageModule { }
