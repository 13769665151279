import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../app/material';
import { MatAutocompleteModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {SafehtmlPipe} from "./pipes/safehtml.pipe";
import {SafeUrlPipe} from "./pipes/safeUrl.pipe";
import { NumNotRoundPipe } from './pipes/num-not-round.pipe';
import { MilitaryToStandardTimePipe } from './pipes/military-to-standard-time.pipe';

import { AnnouncementBannerComponent } from './components/announcement-banner/announcement-banner.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterNewComponent } from './components/footer-new/footer-new.component';
import { FooterNavComponent } from './components/footer-nav/footer-nav.component';
import { NavbarNewComponent } from './components/navbar-new/navbar-new.component';
import { NavDrawerComponent } from './components/nav-drawer/nav-drawer.component';
import { AreaSelectComponent } from './components/area-select/area-select.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchInputV2Component } from './components/search-input-v2/search-input-v2.component';
import { PageBannerComponent } from './components/page-banner/page-banner.component';
import { PartnerCardComponent } from './components/partner-card/partner-card.component';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { OtherParlonsCardComponent } from './components/other-parlons-card/other-parlons-card.component';
import { DealsCardComponent } from './components/deals-card/deals-card.component';
import { ServicesCardComponent } from './components/services-card/services-card.component';
import { NavTabsComponent } from './modules/parlon-inner/components/nav-tabs/nav-tabs.component';
import { BeautyCardComponent } from './components/beauty-card/beauty-card.component';
import { EmptyContainerComponent } from './components/empty-container/empty-container.component';
import { AdvisoryComponent } from './components/advisory/advisory.component';
import { SectionBannerComponent } from './components/section-banner/section-banner.component'
import { BlogCardComponent } from './components/blog-card/blog-card.component'
import { AlertComponent } from './components/alert/alert.component';
import { FullPageLoadingComponent } from './components/full-page-loading/full-page-loading.component';
import { FeaturedDealsCardComponent } from './components/featured-deals-card/featured-deals-card.component';
import { DealCardRecommendationComponent } from './modules/deals/components/deal-card-recommendation/deal-card-recommendation.component';

import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchResultDealCardTemplateComponent } from './components/search-result-deal-card-template/search-result-deal-card-template.component';
import { SearchResultParlonCardTemplateComponent } from './components/search-result-parlon-card-template/search-result-parlon-card-template.component';
import { SearchResultNotFoundTemplateComponent } from './components/search-result-not-found-template/search-result-not-found-template.component';
import { DealCardTemplateComponent } from './components/deal-card-template/deal-card-template.component';
import { PerkCardImgTemplateComponent } from './components/perk-card-img-template/perk-card-img-template.component';
import { PerkCardTemplateComponent } from './components/perk-card-template/perk-card-template.component';
import { GlowgetterStickyButtonComponent } from './components/glowgetter-sticky-button/glowgetter-sticky-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewSearchNavBarComponent } from './components/new-search-nav-bar/new-search-nav-bar.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar.component';
import { AlreadyVerifiedDialogComponent } from './components/already-verified-dialog/already-verified-dialog.component';
import { OtpFormComponent } from './components/otp-form/otp-form.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { OgFloatingButtonComponent } from './components/og-floating-button/og-floating-button.component';

@NgModule({
  declarations: [
    AreaSelectComponent,
    SearchInputComponent,
    SearchInputV2Component,
    NewSearchNavBarComponent,
    PageBannerComponent,
    PartnerCardComponent,
    HeaderTitleComponent,
    OtherParlonsCardComponent,
    FooterComponent,
    FooterNewComponent,
    FooterNavComponent,
    AnnouncementBannerComponent,
    NavbarComponent,
    NavbarNewComponent,
    NavDrawerComponent,
    DealsCardComponent,
    ServicesCardComponent,
    BeautyCardComponent,
    EmptyContainerComponent,
    NavTabsComponent,
    AdvisoryComponent,
    SectionBannerComponent,
    BlogCardComponent,
    AlertComponent,
    FeaturedDealsCardComponent,
    DealCardRecommendationComponent,
    FullPageLoadingComponent,

    // Global components
    HeroBannerComponent,
    SearchBarComponent,
    SearchResultDealCardTemplateComponent,
    SearchResultParlonCardTemplateComponent,
    SearchResultNotFoundTemplateComponent,
    DealCardTemplateComponent,
    PerkCardImgTemplateComponent,
    PerkCardTemplateComponent,
    GlowgetterStickyButtonComponent,
    SafeUrlPipe,
    SafehtmlPipe,
    NumNotRoundPipe,
    MilitaryToStandardTimePipe,
    ErrorDialogComponent,
    RegistrationFormComponent,
    ErrorSnackBarComponent,
    AlreadyVerifiedDialogComponent,
    OtpFormComponent,
    OgFloatingButtonComponent
  ],
  exports: [
    AreaSelectComponent,
    SearchInputComponent,
    SearchInputV2Component,
    PageBannerComponent,
    PartnerCardComponent,
    HeaderTitleComponent,
    OtherParlonsCardComponent,
    AnnouncementBannerComponent,
    FooterComponent, //old
    FooterNewComponent,
    FooterNavComponent,
    NavbarComponent, //old
    NavbarNewComponent,
    NavDrawerComponent,
    DealsCardComponent,
    ServicesCardComponent,
    BeautyCardComponent,
    EmptyContainerComponent,
    NavTabsComponent,
    AdvisoryComponent,
    SectionBannerComponent,
    BlogCardComponent,
    AlertComponent,
    FeaturedDealsCardComponent,
    DealCardRecommendationComponent,
    FullPageLoadingComponent,

    // Global components
    HeroBannerComponent,
    SearchBarComponent,
    SearchResultDealCardTemplateComponent,
    SearchResultParlonCardTemplateComponent,
    SearchResultNotFoundTemplateComponent,
    DealCardTemplateComponent,
    PerkCardImgTemplateComponent,
    PerkCardTemplateComponent,
    GlowgetterStickyButtonComponent,
    NewSearchNavBarComponent,
    SafehtmlPipe,
    SafeUrlPipe,
    NumNotRoundPipe,
    MilitaryToStandardTimePipe,
    ErrorDialogComponent,
    RegistrationFormComponent,
    ErrorSnackBarComponent,
    AlreadyVerifiedDialogComponent,
    OtpFormComponent,
    OgFloatingButtonComponent
  ],
  imports: [
    CommonModule, 
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [],
  entryComponents: [ErrorDialogComponent,ErrorSnackBarComponent,AlreadyVerifiedDialogComponent]
})

export class CommonElementsModule { }