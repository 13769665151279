import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { RestService } from '../../../../services/rest.service';
import { SharedService } from '../../../../services/shared.service';
import { delay, filter, map, reduce, switchMap, take, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import * as Rx from 'rxjs';

@Component({
  selector: 'app-glife-service-list',
  templateUrl: './glife-service-list.component.html',
  styleUrls: ['./glife-service-list.component.scss']
})
export class GlifeServiceListComponent implements OnInit {

  isLoaded = false;
  slug: string;
  branchGlife = [];
  resourceLink = this.rest.resourceLink();
  selectedService = [];

  selectedServices$: Rx.Observable<any>;
  currentServicesSubscription?: Rx.Subscription;

  constructor(
    private smartModal: NgxSmartModalService,
    private rest: RestService,
    private route: ActivatedRoute,
    private _shared: SharedService,
    private _title: Title, 
  ) { }

  ngOnInit() {
    this.getParlonBranchGlife();
    this.selectedServices$ = this._shared.currentService;

    const storedSelectedServices = JSON.parse(localStorage.getItem('selectedServices'));
    if (storedSelectedServices) {
      this._shared.selectService(storedSelectedServices);
      this.selectedService = storedSelectedServices;
    } 
  }

  getParlonBranchGlife() {
    let page = this;
    page.isLoaded = false;
    this.slug = this.route.snapshot.paramMap.get('slug')

    this.rest.getParlonBranchGlife(this.slug).subscribe(data => {
      this.branchGlife = data;
      this._title.setTitle(this.branchGlife['parlon']['business_name'] + " | Parlon");
      page.isLoaded = true;
    })
  }

  selectService(deal,service) {
    let selected = {
      'deal' : deal,
      'service': service
    }

    if(this.isServiceSelected(deal, service)) {
      this.selectedService = this.unselectService(
        deal, 
        service
      );
    } else {
      this.selectedService.push(selected);
    }

    localStorage.setItem('selectedServices', JSON.stringify(this.selectedService));
    this._shared.selectService([...this.selectedService]);
  }

  isServiceSelected(deal, services) {
    return !!this.selectedService.find(
      service =>
        ((service.deal && service.deal.id) === (deal && deal.id))
        && ((service.service && service.service.id) === (services && services.id))
    )
  }

  unselectService(deal, service) {
    return this.selectedService.filter(
      selected =>
        (deal && ((selected.deal && selected.deal.id) !== (deal && deal.id)))
        || (service && ((selected.service && selected.service.id) !== (service && service.id)))
    )
  }

  showModal() {
    this.smartModal.getModal('serviceModal').open();
  }

  getTotal() {
    let dealsPrices = this.selectedService
      .map(({ deal }) => deal ? deal.deal_price : undefined);
    let servicesPrices = this.selectedService
      .map(({ service }) => service ? service.price : undefined);

    const prices = ([...dealsPrices, ...servicesPrices]).filter(price => !!price);

    const sum = prices.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);

    return sum;
  }

  onBranchSelect(slug) {
    window.location.replace('/glife-services/'+slug);
    localStorage.clear();
  }

  trackById(_, item) {
    return item.id;
  }

  isDisabled() { 
    if(this.selectedService.length > 0) {
      return '';
    }
    return "disabled"
  }

  showDealModal(deal) {
    this.smartModal.setModalData({deal
    }, 'dealModal', true);    
    this.smartModal.getModal('dealModal').open();
  }
}
