import { Component, OnInit, Input, ViewChild, Output,EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DealFilterMobileServiceService } from '../../services/deal-filter-mobile-service/deal-filter-mobile-service.service';
import { Subscription } from 'rxjs';
import { E } from '@angular/core/src/render3';


@Component({
  selector: 'app-deal-filter-mobile',
  templateUrl: './deal-filter-mobile.component.html',
  styleUrls: ['./deal-filter-mobile.component.scss']
})
export class DealFilterMobileComponent implements OnInit, OnDestroy {

  dealsLocations: any = [];
  dealsParlons: any = [];
  allLocationsSelected=false;
  allParlonsSelected=false;
  selectedLocations: any = [];
  @Input() selectedParlons: any = [];
  @Input() selectedCategories: any = [];
  @Input() dealsCategories: any = [];
  @Input() filterByUrl: any = {};
  panelOpenState = false;

  @ViewChild('selectLocation') selectLocation: MatSelect;
  @ViewChild('selectParlon') selectParlon: MatSelect;

  @Output() locationEvent = new EventEmitter<any>();
  @Output() parlonEvent = new EventEmitter<any>();
  @Output() categoryEvent = new EventEmitter<any>();
  @Output() resetEvent = new EventEmitter<any>();


  @Output() categoryEventV2 = new EventEmitter<any>();
  @Output() subCategoryEventV2 = new EventEmitter<any>();

  @Input() locationsV2 = [];
  @Input() parlonsV2 = [];
  @Input() categoriesV2 = [];
  @Input() facet_locations: any = [];

  selectedIndex  = -1;
  selectedSubCat = "-1";

  data:any;

  @Output() eventEmitter: EventEmitter<any> = new EventEmitter();

  dealFilterSubscription:Subscription|undefined
  constructor(public dialogRef: MatDialogRef<DealFilterMobileComponent>,
    private dealFilterService:DealFilterMobileServiceService)  { 
  }

  

  sendCategoryEvent(value): void {
    // Emit the event
    let json = {
      functionName:"getCategoriesV2",
      data:value
    }
    this.eventEmitter.emit(json);
  }


  sendSubCategoryEvent(value): void {
    // Emit the event
    let json = {
      functionName:"getSubCategoriesV2",
      data:value
    }
    this.eventEmitter.emit(json);
  }

  sendParlonEvent(value): void {
    // Emit the event
    let json = {
      functionName:"getParlonV2",
      data:value
    }
    this.eventEmitter.emit(json);
  }

  sendLocationsEvent(value): void {
    // Emit the event
    let json = {
      functionName:"getLocations",
      data:value
    }
    this.eventEmitter.emit(json);
  }

  sendEvent(value, functionName){
    let json = {
      functionName:functionName,
      data:''
    }
    this.eventEmitter.emit(json);
  }


  ngAfterViewInit() {  
     
  }


  ngOnDestroy(): void {
    if(this.dealFilterSubscription)
    this.dealFilterSubscription.unsubscribe() 
  }

  ngOnInit() {
    this.dealFilterSubscription =this.dealFilterService.getData().subscribe(data => {
      // Update the local data property      
      this.data = data;
      this.categoriesV2 = this.data.categoriesV2;
      this.parlonsV2 = this.data.parlonsV2;
      this.locationsV2 = this.data.locationsV2;
      this.selectedParlons = this.data.selectedParlons;
      this.selectedCategories = this.data.selectedCategories;
    });

    
  }

  ngOnChanges() {
    // this.filterFragment();
  }


  resetFilter2(){

    // this.resetEvent.emit();
    this.sendEvent(null,'resetFilter')
  }


  sortByCategory(category,value) {
    setTimeout(()=>{
      let findItem = this.categoriesV2.findIndex((item)=>{
        return item.slug == category.slug
      })
      if(this.categoriesV2[findItem].subcategories){
        this.categoriesV2[findItem].subcategories.forEach((subcat,indexSub)=>{
          this.categoriesV2[findItem].subcategories[indexSub].show = value;
        });
      }
      let categories = this.categoriesV2.filter((item)=>{
        return item.show == true; 
      }).map((item) => {
        return item.slug;
      });
      this.sendCategoryEvent(this.categoriesV2);
    })
    
  }

  sortBySubCategory(category,value,index) {
    setTimeout(()=>{
      let category_value = value;
      let findItem = this.categoriesV2.findIndex((item)=>{
        return item.slug == category.slug
      })
      this.categoriesV2[findItem].subcategories.forEach((subcat,indexSub)=>{
        if(this.categoriesV2[findItem].subcategories[indexSub].show == true && indexSub != index)
          category_value = true;
      });
      this.categoriesV2[findItem].show = false;
      this.sendCategoryEvent(this.categoriesV2);
    })
  }


  sortByLocationV2(){

    let locations =this.locationsV2.filter((item)=>{
      return item.completed == true; 
    }).map((item) => {
      return item.highlighted;
    });
    this.selectedLocations = locations;
    // this.parlonEvent.emit(parlons);
    this.sendLocationsEvent(locations);
    // alert(1);
  }

  sortByParlonV2(){
    let parlons =this.parlonsV2.filter((item)=>{
      return item.completed == true; 
    }).map((item) => {
      return item.highlighted;
    });
    
    this.selectedParlons = parlons;
    // this.parlonEvent.emit(parlons);
    this.sendParlonEvent(parlons);
    // alert(1);
  }


  showSub(name){
    // alert(1);

    let findItem = this.categoriesV2.findIndex((item)=>{
      return item.slug.toLowerCase() == name.toLowerCase()
    })    

    if(this.categoriesV2[findItem].show){           
      this.categoriesV2.forEach((item,index)=>{      
          this.categoriesV2[index].show = false; 
          if(item.subcategories){
            item.subcategories.forEach((subcat,indexSub)=>{
              item.subcategories[indexSub].show =false;
            })         
          }          
      })   
      this.selectedIndex = -1;  
      this.categoryEventV2.emit('');
      this.selectedCategories = [];
    }else{
             
      this.categoriesV2[findItem].show = true;    
      this.selectedCategories = [this.categoriesV2[findItem]]    ;  
      this.categoriesV2.forEach((item,index)=>{
        if(index != findItem){
          this.categoriesV2[index].show = false;
        }  
        if(item.subcategories){
          item.subcategories.forEach((subcat,indexSub)=>{
            item.subcategories[indexSub].show =false;
          })  
        }          
        
      })
      this.selectedIndex = findItem;
      this.sendCategoryEvent(this.categoriesV2[findItem].category_name);
    
    }

  }

  clickSub(sub){
    let catIndex =-1;
    let subCatIndex = -1;
    let subCatSlug="";
    this.categoriesV2.forEach((category, categoryIndex)=>{
      let findItem = category.subcategories.findIndex((item,index)=>{
        return item.slug == sub.slug
      })
      
      if(findItem>-1){
        catIndex = categoryIndex
        subCatIndex = findItem
        subCatSlug = this.categoriesV2[categoryIndex].subcategories[findItem].slug;
      }      
    })
    if(subCatSlug==this.selectedSubCat){
      this.categoriesV2[catIndex].subcategories[subCatIndex].show = false;
      this.selectedSubCat = '-1';
      this.subCategoryEventV2.emit('');
    }else{
      this.selectedSubCat = this.categoriesV2[catIndex].subcategories[subCatIndex].slug;      
      this.categoriesV2[catIndex].subcategories.forEach((item)=>{
        if(item.slug != this.selectedSubCat){
          item.show = false
        } else {
          this.categoriesV2[catIndex].subcategories[subCatIndex].show = true;
        }
      });
      this.sendSubCategoryEvent(this.selectedSubCat);
    }
    
  }

  expand(option){
    if(option['expanded'] == true) {
      option['expanded'] = false;
    } else{
      option['expanded'] = true;
    }
  }

  closeDialog(){
    this.dialogRef.close({clicked:true})
  }


}
