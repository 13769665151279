import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlifeService } from '../../../../../../services/glife/glife.service';
import { RestService } from '../../../../../../services/rest.service';

declare var my: any;

@Component({
  selector: 'glife-checkout-form',
  templateUrl: './glife-checkout-form.component.html',
  styleUrls: ['./glife-checkout-form.component.scss']
})
export class GlifeCheckoutFormComponent implements OnInit, OnDestroy {
  
  isLinear = false;
  isLoaded = false;
  loading = false;
  submitted = false;
  isTotalZero = false;
  hasBooking = true;
  isTransactionFailed = false;
  details = "Book now";

  activePromo: object;
  activeCode = '';
  message = '';
  form_data: object;
  booking_details: object;
  currentDate;
  failMessage = '';

  GlifeCheckoutForm: FormGroup;
  @Input() total: number;
  @Input() services: any;
  @Input() user_info: any;
  @Input() deals: any;
  @Input() branch: any;
  @Output() promoCode = new EventEmitter();

  constructor(
    private el: ElementRef,
    private rest: RestService,
    private glifeService:GlifeService
  ) {
    this.form_data = {
      branch_id: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      booking_date: '',
      booking_time: '',
      services: {},
      deals: {}
    };
    this.booking_details = {
      booking_date: '',
      booking_time: ''
    };
   }


  $glifeSubscription:Subscription|undefined;

  ngOnDestroy(){
    if(this.$glifeSubscription)
    this.$glifeSubscription.unsubscribe()
  }

  ngOnInit() {
    this.currentDate = new Date();
    let app = this;
    this.GlifeCheckoutForm = new FormGroup({
      'personalDetailsFormGroup': new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        're_enter_email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
      }),
      'promoFormGroup': new FormGroup({
        'promoCode': new FormControl(null),
        'bookingDate': new FormControl(null, Validators.required),
        'bookingTime': new FormControl(null, Validators.required),
        'eVoucherPolicy': new FormControl(false,Validators.requiredTrue),
      }), 
    });
    if(this.branch['glife_bookable'] == "No") {
      this.hasBooking = false;
    }

    this.$glifeSubscription =  this.glifeService.getGlifeUserInfo.subscribe((data:any)=>{
      app.GlifeCheckoutForm.controls.personalDetailsFormGroup.setValue({
        first_name: data.FIRST_NAME || '',
        last_name: data.LAST_NAME || '',
        email: data.EMAIL_ADDRESS || '',
        re_enter_email: data.EMAIL_ADDRESS || '',
        contact_number: data.GCASH_NUMBER || ''
      });
    })

    // app.GlifeCheckoutForm.controls.personalDetailsFormGroup.setValue({
    //   first_name: app.user_info['first_name'],
    //   last_name: app.user_info['last_name'],
    //   email: app.user_info['email'],
    //   re_enter_email: app.user_info['email'],
    //   contact_number: app.user_info['contact_number']
    // });
  }

  // convenience getter for easy access to form fields
  get f() { return this.GlifeCheckoutForm.get('personalDetailsFormGroup') }

  applyCodeHandler(data) {
    if(data) {
     this.activePromo = data;
     this.promoCode.emit(data);
     this.activeCode = this.GlifeCheckoutForm.value.promoFormGroup.promoCode;
     if(this.activePromo['total'] == 0) {
       this.isTotalZero = true;
     } else {
      this.isTotalZero = false;
     }
    } else {
      this.activeCode = '';
      this.promoCode.emit({});
      this.isTotalZero = false;
    }
  }

  onSubmit(){
    this.submitted = true
    let app = this;
    Object.keys(app.GlifeCheckoutForm.controls.personalDetailsFormGroup['controls']).forEach(function(key) {
      app.GlifeCheckoutForm.controls.personalDetailsFormGroup['controls'][key].markAsTouched();
    });
    if(app.GlifeCheckoutForm.controls.personalDetailsFormGroup.status == "INVALID") {
      app.scrollToFirstInvalidControl();
      return false;
    }
    app.form_data['branch_id'] = app.branch['id'];
    app.form_data['first_name'] = app.GlifeCheckoutForm.value.personalDetailsFormGroup.first_name;
    app.form_data['last_name'] = app.GlifeCheckoutForm.value.personalDetailsFormGroup.last_name;
    app.form_data['email'] = app.GlifeCheckoutForm.value.personalDetailsFormGroup.email;
    app.form_data['mobile'] = app.GlifeCheckoutForm.value.personalDetailsFormGroup.contact_number;
    app.form_data['promo_code'] = app.GlifeCheckoutForm.value.promoFormGroup.promoCode;
    app.form_data['services'] = app.services;
    app.form_data['deals'] = app.deals;
    if(app.hasBooking == true) {
      if(app.booking_details['booking_date'] != '' && app.booking_details['booking_time'] != '') {
        app.form_data['booking_date'] = app.booking_details['booking_date'];
        app.form_data['booking_time'] = app.booking_details['booking_time'];
      } else {
        const glife_booking = document.querySelector("#booking-section");
        glife_booking.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        return false;
      }
    } else {
      app.form_data['booking_date'] = '';
      app.form_data['booking_time'] = '';
    }
    if(app.GlifeCheckoutForm.value.promoFormGroup.eVoucherPolicy == false) {
      return false;
    }
    app.loading = true;
    app.rest.glifePayCheckout(app.form_data).subscribe((data: {}) => {
      // window.location.href = data['actionForm']['redirectionUrl'];
      app.loading = false;
      if(data['status'] == true) {
        my.postMessage({'data': data,'action':'Pay'});
        // window.location.replace(data['message']['actionForm']['redirectionUrl']);
      } else {
        app.isTransactionFailed = true;
        app.failMessage = data['message'];
      }
    });
  }

  changeDetails(e) {
    this.details = e.tab.textLabel;
    if(e.tab.textLabel == 'Book later') {
      this.hasBooking = false;
    } else {
      this.hasBooking = true;
    }
  }

  changeBooking(data) {
    this.booking_details = data;
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid"
    );
    firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }
  
}
