import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-parlon-candies',
  templateUrl: './parlon-candies.component.html',
  styleUrls: ['./parlon-candies.component.scss']
})
export class ParlonCandiesComponent implements OnInit {

  constructor() { }
  @Input() parlonCandies = 0;
  @Input() appliedCandies = 0;
  @Input() limit = 0;
  usedCandy = 0;
  edit = true;
  @Output() applyCandy = new EventEmitter();
  isViewAllCandiesPage  = true;
  ngOnInit() {
    if(this.appliedCandies > 0) {
      this.edit = false;
    }
    this.usedCandy = this.appliedCandies;
  }
  

  apply(){
    let data = {
      "candies_amount": this.usedCandy,
      "brand": 0
    }
    this.applyCandy.emit(data);
    if(this.usedCandy > 0) {
      this.edit = false;
    } else {
      this.edit = true;
    }
    this.appliedCandies = this.usedCandy;
  }

  removeCandy(){
    this.usedCandy = this.appliedCandies = 0;
    this.apply();
  }

}
