import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'parlons-nearby',
  templateUrl: './parlons-nearby.component.html',
  styleUrls: ['./parlons-nearby.component.scss']
})
export class ParlonsNearbyComponent implements OnInit {

  @Input() data;
  @Input() stackable:boolean = false;
  @Input() index:any;

  resource = environment.imagekit;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-parlons-nearby');
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        freeScroll: true,
        freeScrollFriction: 0.075,
        pageDots: false,
        prevNextButtons: false,
        autoPlay: true,
        wrapAround: true
      });
    }, 500);
  }
}
