<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- To set number of slides, change it on CSS
	See example below.
-->

<section class="py-5 py-md-7 overflow-x-hidden">
	<div class="container">
		<div class="row align-items-center justify-content-between justify-content-md-start mb-4 mb-md-5">
			<div class="col-auto">
				<h2 class="h2 fw-semibold mb-0">{{data['bestie_picks_title']}}</h2>
			</div>

			<div class="col-12">
				<p class="text-header-subtitle mt-1 mb-0">{{data['bestie_picks_subtitle']}}</p>
			</div>
		</div>

		<div class="flickity flickity-deal-reels flickity-overflow row gx-4">
			<div class="slide" *ngFor="let pick of data['bestie_picks']">
				<div class="card card-deal-reels text-bg-light-3">
					<div class="card-body pos-relative p-0">
						<a href="/beauty-bag-deals/{{pick['deal']['slug']}}" class="stretched-link">
							<div class="ratio ratio-9x16 mt-4 mb-3">
								<video autoplay muted loop playsinline [muted]="true">
									<source src="{{resource + pick['video_url']}}" type="video/mp4">
								</video>


								<div class="deal-parlon-logo">
									<div class="ratio ratio-1x1">
										<img src="{{resource + pick['parlon']['image']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
									</div>
								</div>
							</div>
						</a>

						<div class="px-2 px-sm-3">
							<p class="deal-parlon small text-muted w-md-80 mb-1">{{pick['parlon']['business_name']}}</p>
							<p class="deal-name fs-6 fw-medium clamp-3 clamp-md-5 mb-5">{{pick['deal']['deal_name']}}</p>

							<p class="deal-price fw-medium">₱ {{pick['deal']['deal_price'] | number}} <span class="og-price ms-2">₱ {{pick['deal']['orig_price'] | number}}</span></p>
						</div>


						<div class="deal-savings-banner">
							<p *ngIf="pick['deal']['discount_type'] == 'Percentage Discount'">{{ pick['deal']['discount_value'] | number }}% OFF</p>
							<p *ngIf="pick['deal']['discount_type'] == 'Value Discount'">Save PHP {{ pick['deal']['discount_value'] | number }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>