import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class GiftingService {

  constructor(private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }

    getGiftingData(): Observable<any> {
      return this.http.get(endpoint + 'promo-purchase/details', httpOptions).pipe(
        map(this.extractData));
    }

    paymentRequest(data): Observable<any> {
      return this.http.post(endpoint + 'promo-purchase/create-payment-request', data, httpOptions).pipe(
        map(this.extractData));
    }

    getPurchaseStatus(data): Observable<any> {
      return this.http.post(endpoint + 'promo-purchase/get-promo-purchase-status', data, httpOptions).pipe(
        map(this.extractData));
    }

    private extractData(res: Response) {
      let body = res;
      return body || { };
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        //  better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
        this.router.navigate(['/']);
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
}