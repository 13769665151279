import { Component, OnInit , Input, AfterViewInit} from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { LightboxComponent } from '../lightbox/lightbox.component';
declare var Flickity: any;
@Component({
  selector: 'app-google-review-card',
  templateUrl: './google-review-card.component.html',
  styleUrls: ['./google-review-card.component.scss']
})
export class GoogleReviewCardComponent implements OnInit, AfterViewInit {
   

  constructor(private _lightbox: Lightbox,private _lighboxConfig: LightboxConfig,
    public dialog: MatDialog) {
    
   }
   

  @Input() data:any;

  _album: any = [];

  openDialog(photos:any[],selectedPhoto:string): void {
    console.log(photos);
    const dialogRef = this.dialog.open(LightboxComponent, {      
      data: {photos,selectedPhoto},      
      height:'auto',
      panelClass: 'custom-dialog-panel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
    });
  }

  ngAfterViewInit(): void {
    let elem = document.querySelector('.flickity');

    setTimeout(()=>{
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        freeScroll: true,
        freeScrollFriction: 0.075,
        pageDots: false,
        prevNextButtons: false,
        // wrapAround: true
      });
      flkty.on('dragStart', () => flkty.slider.style.pointerEvents = 'none');
      flkty.on('dragEnd', () => flkty.slider.style.pointerEvents = 'auto');
    },500)

    
  }



  humanReadableDate(dateString) {
    const date:any = new Date(dateString);
    const now:any = new Date();
  
    const timeDifference = now - date;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
  
    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days === 1) {
      return '1 day ago';
    } else if (days < 30) {
      return `${days} days ago`;
    } else if (months === 1) {
      return '1 month ago';
    } else if (months < 12) {
      return `${months} months ago`;
    } else if (years === 1) {
      return '1 year ago';
    } else {
      return `${years} years ago`;
    }
  }
    
  ngOnInit() {        
    this.data.dateReviewed = this.humanReadableDate(this.data.dateReviewed);
    const rating = this.data.starCount; // Replace this with your actual rating
    const roundedRating = Math.round(rating);
    const stars = document.querySelectorAll('.star');
    Array.from(stars).forEach((star, index) => {
      if (index < roundedRating) {
        star.classList.add('active');
      }
    });
    
    if(this.data.photos){
      if(this.data.photos.length>0){
        this.data.photos.forEach(element => {
          const src = element;
          const album = {
             src: src,        
          };
    
          this._album.push(album);
        });
      }

      console.log(this._album);
    }

  
   
    console.log(this.data);
  }

  open(index: number): void {
    // open lightbox
    event.preventDefault();
    this._lightbox.open(this._album, index,{centerVertically:true, wrapAround: true, showImageNumberLabel: true ,alwaysShowNavOnTouchDevices:true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
