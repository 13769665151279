<div class="py-6 py-md-7">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-11 col-md-10 col-xxl-7 text-center">
				<img src="/assets/images/brb_illustration.png" alt="" class="img-fluid d-block mx-auto mb-5" style="max-height:400px;">

				<h3 class="h3 text-primary fw-bold">We are doing some glow-up! We’ll be right back soon. 💓</h3>
				<h3 class="h3 mb-8">In the meantime, you may feel the Parlon Vibe by subscribing to "Parlon Vibe🌟" on Spotify or read our <a href="https://glowgetter.parlon.ph/" target="_blank" rel="noopener noreferrer" class="link-static">Glow Getter stories</a>.</h3>
				<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/4IKoiFUW91QRCJNuwNICzH?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
			</div>
		</div>
	</div>
</div>