<!-- Sticky navigation bar -->
<nav class="sticky-top">
	<div class="navbar-branch">
		<div class="container">
			<div class="row">
				<div class="col-4 col-md-3">
					<div class="ratio ratio-1x1">
						<img src="{{ resourceLink + branchGlife['parlon']['logo'] }}" alt="" class="img-fluid obj-fit-contain">
					</div>
				</div>

				<div class="col-8 col-md-9">
					<h4 class="h4">{{ branchGlife['parlon']['business_name'] }}</h4>

					<p class="mb-1">Selected Branch: <span class="fw-semibold">{{ branchGlife['branch_name'] }}</span></p>
					<a class="link-secondary"  (click)="showModal()">CHANGE <i class="far fa-chevron-right fa-sm fa-fw"></i></a>
				</div>
			</div>
		</div>
	</div>
</nav>

<div class="section-deals py-6" *ngIf="branchGlife['glife_deals'] != null">
	<div class="container">
		<div class="mb-6">
			<carousel [photos]="branchGlife['photos']"></carousel>
		</div>

		<div class="row align-items-center gx-4 mb-4">
			<div class="col-auto">
				<img src="assets/images/logo-icon.png" alt="" class="img-fluid" style="width: 3rem;">
			</div>
	
			<div class="col">
				<p class="fs-5 text-primary text-uppercase fw-semibold mb-1">Special deals for you</p>
				<p class="small mb-0">Curated by Parlon</p>
			</div>
		</div>

		<ul class="list-unstyled list-deals">
			<li *ngFor="let deal of branchGlife['glife_deals']; trackBy: trackById">
				<div class="row flex-deal-list-item align-items-center gx-4">
					<div class="col-auto col-checkbox">
						<service-check 
							[deal]="deal" 
							[selectedServices]="selectedServices$ | async" 
							(click)="selectService(deal)"
						>
						</service-check>
					</div>
	
					<div class="col col-content">
						<div class="row flex-deal-list-item-content gx-5 gx-md-7">
							<div class="col-auto col-img">
								<div class="ratio ratio-1x1 rounded-2 overflow-hidden">
									<img src="{{ resourceLink + deal['primary_photo'] }}" alt="" class="img-fluid obj-fit-cover">
								</div>
								<a class="text-primary" (click)="showDealModal(deal)"><small><b><u>View Terms</u></b></small></a>
							</div>
	
							<div class="col col-desc">
								<div class="text-deals-container">
									<div class="text-percentage-badge">
										<span class="price" *ngIf="deal['discount_type'] == 'Percentage Discount'">
											{{ deal['discount'] | number }}% off
										</span>

										<span class="price" *ngIf="deal['discount_type'] == 'Value Discount'">
											<span class="fw-semibold">Save</span> 
											PHP {{ deal['discount'] | number }}
										</span>
									</div>
		
									<p class="fw-semibold mb-2">{{ deal['label'] }}</p>
									<p class="text-price">
										<span class="current-price">PHP {{ deal['deal_price'] | number }}</span>
										<span class="og-price">PHP {{ deal['original_price'] | number }}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>

<div class="section-services py-6" *ngIf="branchGlife['glife_services'].length > 0">
	<div class="container">
		<h5 class="h5 text-primary text-uppercase fw-semibold">Services</h5>
		<p class="mb-5">Get that glow!</p>

		<ul class="list-unstyled list-service-categories">
			<li class="category-item" *ngFor="let glife_services of branchGlife['glife_services']; let i = index">
				<h6 class="h6 text-secondary fw-semibold mb-3">{{ glife_services.name }}</h6>

				<ul class="list-unstyled list-service-items">
					<li class="service-item" *ngFor="let service of glife_services.services; trackBy: trackById">
						<div class="row flex-service-list-item gx-4">
							<div class="col-auto col-checkbox">
								<service-check 
									[services]="service" [selectedServices]="selectedServices$ | async" 
									(click)="selectService(null,service)"
								>
								</service-check>
							</div>

							<div class="col">
								<div class="text-service-container">
									<div class="row mb-3">
										<div class="col">
											<p class="text-service-item-title">{{ service.name }}</p>
										</div>
	
										<div class="col-auto">
											<p class="text-price">PHP {{ service.price | number }}</p>
										</div>
									</div>

									<p class="text-service-item-desc">{{ service.description }}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</div>

<!-- Bottom offset for checkout button -->
<div class="mt-8"></div>

<!-- Persistent checkout button on bottom -->
<div class="container-checkout">
	<div class="card bg-secondary text-white pos-relative" [ngClass]="isDisabled()">
		<div class="card-body p-3">
			<div class="d-grid grid-checkout-layout">
				<div class="cell cell-service-count">
					<div class="container-checkout-service-count-pill">
						<span class="count" *ngIf="selectedService.length > 0;then oneService; else noServices">
						</span>

						<ng-template #oneService>
							<div *ngIf="selectedService.length == 1; else moreServices">
								<span class="count">{{ selectedService.length }}</span>
								<span>&nbsp;service</span>
							</div>
							<ng-template #moreServices>
								<span class="count">{{ selectedService.length }}</span>
								<span>&nbsp;services</span>
							</ng-template>
						</ng-template>

						<ng-template #noServices>
							<span class="count">0</span>
							<span>&nbsp;service</span>
						</ng-template>
					</div>
				</div>

				<div class="cell cell-checkout">
					<a href="#" [routerLink]="['/glife-checkout', slug]" 
					class="stretched-link" ><p class="text-white text-center text-uppercase fw-medium mb-0">Checkout</p></a>
				</div>

				<div class="cell cell-subtotal">
					<p class="text-checkout-price mb-0">
						<span class="currency">PHP&nbsp;</span>
						<span class="amount">{{ getTotal() | number }}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Service Modal -->
<ngx-smart-modal #serviceModal identifier="serviceModal" [dismissable] = "false">
	<!-- *ngIf="serviceModal.hasData()" -->
	<ng-container>
		<div class="modal-header border-0 p-2 pt-4">
			<h5 class="modal-title text-secondary">
			</h5>
		</div>
		<div class="modal-body p-2">
			<div class="col-12 col-md-8">
				<h2 class="h2 text-center mb-4">Select Branch</h2>

				<div class="branch-select mb-3">
					<mat-form-field [class.mat-elevation-z8] appearance="standard" [floatLabel]="'never'">
						<mat-select>
							<mat-option *ngFor="let branch of branchGlife['parlon']['branches']" [value]="branch" (click)="onBranchSelect(branch['slug'])">
								{{ branch['branch_name'] }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="row flex-branch-address gx-3">
					<div class="col-auto col-icon">
						<i class="fas fa-map-marker-alt fa-fw text-secondary"></i>
					</div>

					<div class="col">
						<p class="mb-0">{{ branchGlife['address'] }}</p>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>

<ngx-smart-modal #dealModal identifier="dealModal" [dismissable]="false">
	<ng-container *ngIf="dealModal.hasData()">
		<div class="modal-body p-2">
			<div class="text-deals-desc mb-5 mb-lg-0">
				<p class="text-primary fw-semibold"><i class="far fa-calendar-alt mr-1"></i> Validity Period: <br class="d-md-none">{{ dealModal.getData().deal['valid_from'] | date: 'MMMM dd, yyyy' }} - {{ dealModal.getData().deal['valid_to'] | date: 'MMMM dd, yyyy' }}</p>
				<div [innerHTML]="dealModal.getData().deal['long_description']"></div>
			</div>
			<!-- The Additional Terms and Conditions -->
			<div class="my-6 my-md-7" id="addtlTerms" *ngIf="dealModal.getData().deal['fine_print']">
				<p class="fs-6 fw-medium">Additional Terms and Conditions</p>

				<div class="card card-fine-print mb-5">
					<div class="card-body p-0">
						<p [innerHTML]="dealModal.getData().deal['fine_print']"></p>
					</div>
				</div>

			</div>
		</div>
	</ng-container>
</ngx-smart-modal>
