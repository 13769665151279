import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { OgInviteWelcomeComponent } from './og-invite-welcome.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  declarations: [OgInviteWelcomeComponent]
})
export class OgInviteWelcomeModule { }
