import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'deal-reels',
  templateUrl: './deal-reels.component.html',
  styleUrls: ['./deal-reels.component.scss']
})
export class DealReelsComponent implements OnInit {

  @Input() data;
  resource = environment.resource;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-deal-reels');
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        autoPlay: 3500,
        freeScroll: true,
        freeScrollFriction: 0.075,
        pageDots: false,
        prevNextButtons: false
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }

}
