import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { RouterModule } from '@angular/router';
import { MultiBookingThankyouComponent } from './multi-booking-thankyou.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  declarations: [MultiBookingThankyouComponent]
})
export class MultiBookingThankyouModule { }
