import { Component, OnInit, Input, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DealsService } from '../../../../services/deals/deals.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UserAccountService } from '../../../../services/user-account/user-account.service';
import { SettingsService } from '../../../../services/settings/settings.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { forEach } from '@angular/router/src/utils/collection';
import { NetcoreService } from '../../../../services/netcore/netcore.service';
import { CandiesService } from '../../../../services/candies/candies.service';

declare let fbq:Function;


declare var My2c2p: any;

@Component({
  selector: 'bag-payment',
  templateUrl: './bag-payment.component.html',
  styleUrls: ['./bag-payment.component.scss']
})
export class BagPaymentComponent implements OnInit, OnDestroy {

  cc: object;
  @Input() data;
  @Input() promoCodeLoading;
  selected_channel:string="";
  checkoutForm: FormGroup;
  isTotalZero = false;
  loading = false;
  isTransactionFailed = false;
  submitted = false;
  error_message = '';
  api_token = null;
  key = '';
  candies;
  candies_discount:number = 0;
  @Output() candyEvent = new EventEmitter<number>();
  @Output() promoCodeEmit = new EventEmitter();
  resource = environment.resource;


  form_data: object;
  payment_channels;

  
  constructor(
    private el: ElementRef,
    private DealService: DealsService,
    private smartModal: NgxSmartModalService,
    private UserAccountService: UserAccountService,
    private activatedRoute:ActivatedRoute,
    private SettingsService: SettingsService,
    private NetcoreService:NetcoreService,
    private candiesService:CandiesService
  ) {
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
    this.form_data = {
      'payment_channel_code': '',
      'promo_code': '',
      'bag_id': localStorage.getItem('bagId'),
      'securePayToken': '',
      'affiliate':'',
      'affiliate_codes':{}
    }
   }

  isLinear = false;
  paymentOption = "";

  isGcashOnly = false;
  
  $gcashSub:Subscription|undefined;

  isLoadedCandies:boolean  = false;

  shopBack:any;

  ngOnDestroy(){
    if(this.$gcashSub)
      this.$gcashSub.unsubscribe();    
  }

  checkIfOneHourExpired(date:string){
    // Given date string
    const dateString = date;

    // Parse the given date string into a Date object
    const givenDate:any = new Date(dateString);

    // Get the current date and time
    const currentDate:any = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - givenDate;

    // Convert 1 hour to milliseconds (3600 seconds)
    const oneHourInMilliseconds = 3600 * 1000;

    // Check if 1 hour has passed
    if (timeDifference >= oneHourInMilliseconds) {
      return "Expired"
    } else {
      return "Valid"
    }
  }


  ngOnInit() {
    // console.log(101,this.data)

    // console.log(  );
 
    
    this.$gcashSub =  this.activatedRoute.queryParams.subscribe(params => {
      if(JSON.parse(localStorage.getItem('gcashOnly')).gcashonly == true ){
        this.isGcashOnly = true;  
      }     
    });

    this.candiesService.isLoadingCandies$.subscribe((data)=>{
      // console.log(96, data);
      if(data){
        this.isLoadedCandies = true;
        
      }
    })  

    

    this.key = this.generateKey();
    this.checkoutForm = new FormGroup({
      'paymentFormGroup': new FormGroup({
        'promoCode': new FormControl(null),
        'creditCard': new FormControl(null, Validators.required),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
        'paymentChannel': new FormControl(null, Validators.required),
        'eVoucherPolicy': new FormControl(false,Validators.requiredTrue),
      })
    });
    this.UserAccountService.getLoggedInUser.subscribe(data=>{
   
      if(data != null){
        this.api_token = data.data;
        this.candies_discount =this.data.summary.candies_discount;
        this.candies = this.data['candies'];
      }               
    });
    this.SettingsService.getPaymentChannels('deals').subscribe(data=>{
      if(data != null) {
        this.payment_channels = data;
      }
    })
  }

  get f() { return this.checkoutForm.get('paymentFormGroup') }

  paymentOptionClick(target) {
    // let element: HTMLElement = document.getElementById(target) as HTMLElement;
    // console.log(element);

    this.selected_channel = target;

    this.checkoutForm.controls.paymentFormGroup.patchValue({
      paymentChannel:target
    });
  }

  onSubmit(){
    this.submitted = true
    let app = this;    
    this.shopBack = JSON.parse(localStorage.getItem('shopBack'));

    if(this.shopBack){
      if(this.checkIfOneHourExpired(this.shopBack.date ) == 'Valid'){
        this.form_data['affiliate'] = "shopback";
        this.form_data['affiliate_codes']['transaction_id'] = this.shopBack.transaction_id
      }
    }    
    if(this.form_data['affiliate']==''){
      delete this.form_data['affiliate'];
      delete this.form_data['affiliate_codes'];
    } 
    

    if(app.data['promo_code'] != null) {
      app.form_data['promo_code'] = app.data['promo_code']['code'];
    }
    
    //applying candies have delay so i added this for extra protection
    if(!this.isLoadedCandies)
      return false;
    
    // paymentFormGroup credit card
    if((this.selected_channel == null || this.selected_channel == '') && app.data['summary']['original_discounted_price'] > 0) {
      const paymentSection: HTMLElement = this.el.nativeElement.querySelectorAll(
        ".payment-checkpoint"
      );
      paymentSection[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}); 
      return false;
    }

    if((this.selected_channel == 'parlon_credit_card' || this.selected_channel == 'parlon_maya') && app.data['summary']['original_discounted_price'] > 0) {

      if(app.checkoutForm.controls.paymentFormGroup.status == null) {
        app.scrollToFirstInvalidControl();
        return false;
      }
     
      // end of validate
      app.cc['cardnumber'] = app.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      app.cc['cvv'] = app.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      app.cc['month'] = app.checkoutForm.value.paymentFormGroup.ccMonth;
      app.cc['year'] = app.checkoutForm.value.paymentFormGroup.ccYear.toString();

    }

    if(app.data['require_card_validation'] == true) {
      if(app.data['card_validation_type'] == 'First') {
        app.form_data['cardDigits'] = app.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, '').substring(0,app.data['card_digits_to_send']);
      }
    }
    
    setTimeout(() => {
      app.form_data['payment_channel_code'] = this.selected_channel;
      // if(!(app.activePromo // 👈 null and undefined check
      //   && Object.keys(app.activePromo).length === 0
      //   && Object.getPrototypeOf(app.activePromo) === Object.prototype)) {
      //     if(app.activePromo != undefined) {
      //       if(app.activePromo['total'] == 0) {
      //         app.form_data['payment_channel_code'] = null;
      //       }
      //       app.form_data['promo_code'] = app.dealsForm.value.paymentFormGroup.promoCode;
      //     }
      // }
      if(app.data['summary']['original_discounted_price'] <= 0) {    
        if(app.checkoutForm.value.paymentFormGroup.eVoucherPolicy == false) {
          return false;
        }
        app.smartModal.getModal('voucherModal').open();
        return false;
      } else {
        if(this.selected_channel == '') {
          return false;
        }
        My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {
          app.form_data['securePayToken'] = encryptedData.encryptedCardInfo;
        });
        if(app.checkoutForm.value.paymentFormGroup.eVoucherPolicy == false) {
          const eVoucherPolicy: HTMLElement = this.el.nativeElement.querySelectorAll(
            ".eVoucherPolicy"
          );
          eVoucherPolicy[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}); 
          return false;
        }
        if(app.form_data['payment_channel_code'] == 'parlon_maya') {
          let maya_fields = {
            card: {
              number:app.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, ''),
              expMonth:app.checkoutForm.value.paymentFormGroup.ccMonth,
              expYear:app.checkoutForm.value.paymentFormGroup.ccYear.toString(),
              cvc:app.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '')
            }
          };
          this.loading = true;
          app.DealService.getMayaToken(maya_fields).subscribe((response) => {
              app.form_data['securePayToken'] = response['paymentTokenId'];
              app.requestPayment();
          });
          return;
        }
        app.requestPayment();
      }
      
    }, 200);
    
  }

  requestPayment() {
    let app = this;

    // console.log(205,app.form_data);
    



    if(this.api_token != null)
      app.form_data['api_token'] = app.api_token;
    
    if(this.candies_discount > 0)
      app.form_data['candies_amount'] = this.candies_discount;
    app.loading = true;
    let fb_contents = [];
    this.data['deals'].forEach(e => {
      fb_contents.push({
        id: e['deal_id'],
        quantity: e['redeemers_count']
      })
    });
    fbq('track', 'InitiateCheckout', { 
      value: this.data['summary']['original_discounted_price'],
      currency: environment.currency,
      contents: fb_contents,
      content_type: 'product', // constant
    });
    let bag_deals = [];
    app.data['deals'].forEach(e => {
      let branch  = [];
      e['redeemers'].forEach(r => {
        branch.push(r['branch']);
      });
      let deal = {
        'deal_id': e['deal_id'],
        'deal':e['name'],
        'dealcategory': e['deal_categories'],
        'dealoriginalprice':  e['original_price'],
        'dealdiscount': e['actual_discount'],
        'dealprice': e['deal_price'],
        'dealsubcategory':  e['deal_subcategories'],
        'dealbranch': branch,
        'merchant': e['parlon'],
        'quantity': e['redeemers_count'],
        'forwhom': e['buying_for']
      };
      bag_deals.push(deal);
    });

    app.DealService.bagCheckout(app.form_data,this.key).subscribe((data) => {
      try {
        this.NetcoreService.dispatch('checkout bag', data['cart_details']);
        data['purchased_items'].forEach(deal => {
          let netcore_data = deal
          netcore_data['promocode'] = data['cart_details']['promo_code'];
          netcore_data['amount'] = deal['finalamount'];
          delete netcore_data.dateUnix;
          this.NetcoreService.dispatch('checkout deal', netcore_data);
        });
      } catch(e) { }
      
      if(data['status'] == true && data['isRedirect'] == true){
        setTimeout(() => {
          window.location.href = data['response']['data'];
        }, 1000);
      } else {
        this.key = this.generateKey();
        app.error_message = data['message'];
        if(data['error_code'] == "PROMO_INVALID")
          this.promoCodeEmit.emit(null);
        app.loading = false;
        app.isTransactionFailed = true;
      }
    },
    (error) => {
      console.log(error);
      app.loading = false;
      app.isTransactionFailed = true;
    })
  }

  applyCandies(data){
    // console.log('apply candies event', data);
    this.candyEvent.emit(data);
    this.selected_channel = '';
    this.checkoutForm.controls.paymentFormGroup.patchValue({
      paymentChannel:''
    });
    console.log(this.checkoutForm);
  }

  applyCodeHandler(data) {
    this.promoCodeEmit.emit(data);
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid"
    );
    firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  generateKey() {
    const  d = Date.now();
    return btoa((Math.random() * 62 ).toString() + d);
  }

  checkPaymentChannel(channel, template){
    let enabled = false;
    if(this.data['summary']['original_discounted_price'] > 0  && channel['template'] == template && this.data['summary']['original_discounted_price'] > channel['minimum_value']) {
      enabled = true;
      if(this.data['restrict_channels'] == true) {
        if(this.data['allowed_channels'].includes(channel['channel_code'])) {
          enabled = true
        } else {
          enabled = false;
        }
      }
    }
    return enabled
  }

}
