import { Component, OnInit, OnDestroy, ElementRef,HostListener , Input} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { SearchService } from '../../services/search/search.service';
import { TypesenseSearchService } from '../../services/typesense-search/typesense-search.service';
import { environment } from '../../../environments/environment';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { AlgoliaInsightService } from '../../services/algolia-insight/algolia-insight.service';

@Component({
  selector: 'search-input-v2',
  templateUrl: './search-input-v2.component.html',
  styleUrls: ['./search-input-v2.component.scss']
})
export class SearchInputV2Component implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private rest: RestService,
    private _shared: SharedService,
    private el: ElementRef,
    private searchService:SearchService,
    private TypesenseSearchService:TypesenseSearchService,
    private userService:UserAccountService,
    private algoliaInsightService:AlgoliaInsightService) { }


    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
      if(this.fromNav){
        this.inputSearch ='';
        this.hideDropDown = true;      
      setTimeout(()=>{
        this.hideDropDown = false
      },500)
      }      

    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
      this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;
    }

    public getScreenWidth: any;
    public getScreenHeight: any;


    @Input() isShowButton:boolean = false;
    @Input() hideDropDown:boolean = false;
    @Input() fromNav:boolean = false;    


    inputSearch: string = "";
    locationSelected: any = '';
    areas: any = [];
    toStr = JSON.stringify;
    myControl = new FormControl('');
  
  
    options: string[] = ['Eyelash extensions', 'Hair-color and treatment', 'Brazilian blowout','Eyelash extensions', 'Hair-color and treatment', 'Brazilian blowout'];
    // options: string[] = [];
  
    recentSearches:string[] = [];
    popularSearches:any[] = [];
    recentViewed:any;
    searchWord:string;  
    location:string="";  
    parlons:any[] = [];
    deals:any[]= [];
    countDeals:any[] = [] ;
    countParlons:any[]= [];
    resource = environment.resource;    
    selectedOption:string =''
    filteredOptions: Observable<string[]>;
    $userSubscription:Subscription|undefined;
    userId='';
    isLoggedIn;      
    queryID;
    position;  
    
    debounceTimeoutId:any;
    isLoadingSearchInput:boolean = false;
  
    /** control for the MatSelect filter keyword */
    public areaFilterCtrl: FormControl = new FormControl();
  
    /** list of areas filtered by search keyword */
    public filteredAreas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  
    protected _onDestroy = new Subject<void>();
  
  
    discountedPrice = (price:number, discount:number) =>{
      let d = price * (discount /100);
  
      return price - d;
    }
  

    clickedObjectIDs(deal:any){
      // alert(JSON.stringify(deal))
      let {objectID,queryID} = deal;    
      let indexName = environment.deals; 
      let insightType="";         
      const urlWithParams = '/beauty-bag-deals/' + deal['slug'];
      if(insightType!="")
      this.router.navigate([urlWithParams], { queryParams: { 'insight-type': insightType } });
      else
      this.router.navigate([urlWithParams]);
    } 
  
    changeModel(){
      // alert(1);
      // console.log(this.inputSearch)

      if(this.inputSearch.length==0){              
          this.parlons = [];
      }else{
        this.isLoadingSearchInput = true;
        // Call the search function after the debounce time has passed
        let loc:any;
        if(this.locationSelected.length!='')
          loc = JSON.parse(this.locationSelected);
        else
          loc = "";

        this.TypesenseSearchService.search(this.inputSearch, loc.location_name).subscribe((data)=>{
            data['results'].forEach(res => {
              if(res['request_params']['collection_name'] == 'Deals') {
              this.deals = res['hits'].map((item:any,index)=>{
                return{
                position:index+1,  
                searchInput:this.inputSearch,
                objectID:'',
                queryID:'',
                discountType:item['document'].discount_type,
                discountValue:item['document'].discount_value,
                deal_id: item['document'].id,
                deal_name:item['document'].name,
                deal_price:item['document'].deal_price, 
                original_price: item['document'].original_price, 
                merchant_name: item['document'].parlon, 
                merchant_count: item['document'].branches.length, 
                deal_img:this.resource+item['document'].logo, 
                parlon_logo:this.resource+ item['document'].parlon_logo, 
                slug:item['document'].slug
                }
              })
              } else if(res['request_params']['collection_name'] == 'Parlons') {
                this.parlons = res['hits'].map((item:any)=>({
                  searchInput:this.inputSearch,
                  queryID:'',
                  objectID:'',
                  slug:item['document'].parlon_slug,
                  branches:item['document'].branches,
                  parlon_name:item['document'].parlon_name,
                  parlon_logo:this.resource+ item['document'].logo,
                  branch_count:item['document'].branch_count
                }));
              }
            });
        })
        
      }  
    }
    
    
    redirectIfMobile(){
      if(this.getScreenWidth<=480){
        this.router.navigate(['/mobile/search']);
      }      
    }
  
  
    ngOnInit() {
      // alert(1);
      this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;      
      // this.searchService.getPopularSearches().subscribe((data)=>{
      //   this.popularSearches = data.hits.splice(0,6);
      //   // console.log('popular searches',data)
      // })
        
      this.recentSearches = this.searchService.getRecentSearches();      
      this.recentViewed = [];          
      this.rest.getLocations().subscribe((data: {}) => {
        this.areas = data['data'];
        this.filteredAreas.next(this.areas);
      });
      this._shared.searchAreaValue.subscribe(area => {
        this.locationSelected = area;
      });
  
      this.areaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAreas();
      });

      this.$userSubscription= this.userService.getLoggedInUser.subscribe((data:any)=>{
        if(data){        
          if(data.data){
            this.isLoggedIn = true;
            this.userId = data.user.id;          
          }               
        }
      });
  
    }
  
  
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  
  selectOption(event){
    // console.log("selected",event.option.value)
    
    let deal = this.deals.find((item)=>{
      return item.slug == event.option.value;
    })
    
    
    if(deal){
      this.router.navigate(['/', 'deals','checkout',event.option.value]);
    }

    let find = this.parlons.find((item)=>{
      return item.parlon_slug == event.option.value;
    })

    if(find){
      this.router.navigate(['/', 'parlon',find.slug]);
    }

    this.selectedOption = event.option.value
  }
  
    searchSubmit() {
      this._shared.changeSearchArea(this.locationSelected);
      let location = (this.locationSelected != "" ? JSON.parse(this.locationSelected) :  null );
      let slug = location != null ? location['slug'] : "all";
      // console.log("input", this.inputSearch, this.selectedOption)
      // console.log(slug)
      if(slug != 'all' ||  this.inputSearch != '' ){
        if(this.inputSearch != '' || this.selectedOption != ''){
          this.router.navigate(['/search',slug,this.inputSearch || this.selectedOption]);
          this.searchService.setRecentSearches(this.recentSearches,this.inputSearch || this.selectedOption);
        }      
        else 
          this.router.navigate(['/search',slug]);  
      }

      
        
    }
  
    onSelectValueChange(value){
      this.parlons =[];
      this.deals=[];
      this._shared.changeSearchArea(this.locationSelected);
    }
  
    searchfocusOut() {
      setTimeout(() => {
        let field = (document.getElementById('search-dialog-box') as HTMLElement);
        field.classList.remove('show');
        (document.getElementById('search-input-field') as HTMLInputElement).value = '';
      }, 500);
    }
  
    protected filterAreas() {
      if (!this.areas) {
        return;
      }
      // get the search keyword
      let search = this.areaFilterCtrl.value;
      if (!search) {
        this.filteredAreas.next(this.areas);
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the areas
      this.filteredAreas.next(
        this.areas.filter(area => area.location_name.toLowerCase().indexOf(search) > -1)
      );
    }

}
