<!--
	NOTE:
	- Get the height of the navigation bar and set the top offset of the sticky navigation jumplist.
	- Add `show` class to `sticky-jumplist` when scrolled to show sticky navigation jumplist.
-->

<!-- <app-hero-banner></app-hero-banner> -->
<app-search-bar></app-search-bar>

<section class="py-6 py-md-8">
	<div class="container">
		<div id="sticky-jumplist" class="sticky-jumplist">
			<div class="row flex-letter-jumplist justify-content-center gx-md-8">
				<div class="col-auto content">
					<ul class="nav nav-letter-jumplist">
						
						<li class="nav-item" *ngFor="let list of salonList">
							<a class="nav-link link-secondary" *ngIf="list['salons'].length > 0">{{list.letter}}</a>
						</li>
	
					</ul>
				</div>
			</div>
		</div>
		
		<h2 class="h2 fw-semibold mb-5">{{pageData['title']}}</h2>
		<p>{{pageData['description']}}</p>

		<!-- Jumplist -->
		<div class="mt-6 mt-md-8 mb-5 mb-md-7">
			<div class="row flex-letter-jumplist justify-content-center gx-md-8">
				<div class="col-auto content">
					<ul class="nav nav-letter-jumplist">

						<li class="nav-item" *ngFor="let list of salonList">
							<a href="/booking#{{list.letter}}" class="nav-link link-secondary" *ngIf="list['salons'].length > 0">{{list.letter}}</a>
						</li>

					</ul>
				</div>
			</div>
		</div>

		<div class="content-list">
			<ng-container *ngFor="let list of salonList">
				<div class="content-panel" *ngIf="list['salons'].length > 0">
					<div class="panel-header">
						<div class="d-flex align-items-center gap-5">
							<div class="col-auto">
								<h2 id="{{list.letter}}" class="h2 text-header-title">{{list.letter}}</h2>					
							</div>
							
							<div class="col">
								<hr class="panel-header-divider">
							</div>
						</div>
					</div>
	
					<div class="panel-body">
						<div class="row row-cols-2 row-cols-lg-3 gx-4 gy-6">
							<div class="col" *ngFor="let salon of list['salons']">
								<parlon-card-template [data]="salon"></parlon-card-template>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			
		</div>
	</div>
</section>