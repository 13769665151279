<div class="card deals-list-card">
	<a [routerLink]="['/beauty-bag-deals', deal['slug']]">
		<div class="card-body p-0">
			<div class="row gx-4 gx-lg-5 gy-3">
				<div class="col-5 col-md-4 col-lg-12">
					<div class="ratio ratio-img-deals-list-card">
						<img src="{{ resourceLink + deal['primary_photo'] }}" alt="" class="img-fluid obj-fit-cover">

						<!-- Use `pink` or `orange` class -->
						<div class="category-deals-grid pink">
						<p class="price-deals fw-normal mb-0">
							<span class="price" *ngIf="deal['discount_type'] == 'Value Discount'">
								<span class="fw-semibold">Save</span> 
								PHP {{ deal['discount_value'] | number }}
							</span>

							<span class="price" *ngIf="deal['discount_type'] == 'Percentage Discount'">
								{{ deal['discount_value'] | number }}% OFF
							</span>
						</p>
					</div>
					</div>
				</div>

				<div class="col-7 col-md-8 col-lg-12">

					<!-- Use `pink` or `orange` class -->
					<div class="category-deals-inline mb-3 pink">
						<p class="price-deals fw-semibold mb-0">
							<span class="price" *ngIf="deal['discount_type'] == 'Value Discount'">
								<span class="fw-semibold">Save</span> 
								PHP {{ deal['discount_value'] | number }}
							</span>

							<span class="price" *ngIf="deal['discount_type'] == 'Percentage Discount'">
								{{ deal['discount_value'] | number }}% OFF
							</span>
						</p>
					</div>
					<h5 class="h5 text-body fw-semibold clamp-2 mb-1">
						{{ deal['name'] }}
					</h5>

					<p class="mb-0">
						<span class="text-primary fs-6">PHP {{deal['deal_price'] | number:'1.2-2'}}</span>&nbsp;
						<span class="small text-muted text-decoration-line-through">PHP {{deal['original_price']}}</span>
					</p>
				</div>
			</div>
		</div>
	</a>
</div>