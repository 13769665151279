import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrbPageComponent } from './brb-page.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BrbPageComponent]
})
export class BrbPageModule { }
