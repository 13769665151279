<div class="bash-thankyou" *ngIf="isLoaded == true">
  <div class="container">
    <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top">
    <div class="sheet" *ngIf="result['status'] == 'Success'">
      <img src="{{resource}}BeautyBash/bbsuccess.svg" class="bbsuccess-img">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <h2 class="fw-semibold mb-4 ">Thank you for buying, {{result['first_name']}}!</h2>
          <p class="mb-4">Congratulations! You were able to grab a Parlon Beauty Bash Deal! The e-voucher is on its way to your email. Please check your promotions tab or spam folder if you can’t find it in your inbox.</p>
          
          <div class="card yellow-bg mb-7 mt-lg-0 mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <img src="{{resource + result['image']}}" class="w-100">
                </div>
                <div class="col-md-6">
                  <div class="col" style="text-align: left;">
                    <h3 class="h3 mb-2">{{result['bash_deal_name']}}</h3>
                    <div class="d-flex mb-5 align-items-center">
                      <img src="{{resource + result['salon_logo']}}" class="salon-logo" width="20">
                      <p class="fw-semibold mx-3 mb-0">{{result['salon_name']}}</p>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-6">
                        <p class="mb-0">Original Price</p>
                      </div>
                      <div class="col-6">
                        <p class="text-end tabular-nums mb-0"><del>₱{{result['original_price'] | number}}</del></p>
                      </div>
                    </div>
  
                    <hr>
  
                    <table class="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td class="ps-0">Discount</td>
                          <td class="text-end tabular-nums pe-0">- ₱{{result['discount'] | number}}</td>
                        </tr>
  
                      </tbody>
                    </table>
  
                    <hr>
  
                    <div class="row align-items-center">
                      <div class="col-6">
                        <p class="fw-bold mb-0">Beauty Bash Price</p>
                      </div>
                      <div class="col-6">
                        <p class="text-end fw-bold tabular-nums mb-0">₱{{result['beauty_bash_price'] | number}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 class="fw-semibold mb-4">We have so many deals in Parlon. Check them out.</h4>
          <button mat-flat-button color="secondary" class="py-2 w-100 mb-4" [routerLink]="['/all-parlon-deals']">
            CHECK OUT PARLON DEALS
          </button>
        </div>
      </div>
    </div>
    <div class="sheet" *ngIf="result['status'] == 'Pending'">
      <img src="{{resource}}BeautyBash/bbsuccess.svg" class="bbsuccess-img">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <h2 class="fw-semibold mb-4 ">Bestie, your transaction is being processed.</h2>
          <p class="mb-4">Hello Bestie! We are waiting for a payment confirmation from our payment gateway. It usually takes around 30-60 seconds. Once confirmed you will receive an email containing the vouchers you purchased. You can chose to wait or close this page. </p>
          <h4 class="fw-semibold mb-4">We have so many deals in Parlon. Check them out.</h4>
          <button mat-flat-button color="secondary" class="py-2 w-100 mb-4" [routerLink]="['/all-parlon-deals']">
            CHECK OUT PARLON DEALS
          </button>
        </div>
      </div>
    </div>
    <div class="sheet" *ngIf="result['status'] == 'Failed'">
      <img src="{{resource}}BeautyBash/bbsoldout.svg" class="bbsuccess-img">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <h2 class="fw-semibold mb-4 ">Oh No!</h2>
          <p class="mb-4">We were unable to charge your account for this transaction, bestie. Kindly double check your details or choose another payment method. Then, try again. </p>
        </div>
      </div>
    </div>
    <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot">
  </div>
</div>