import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { InitParams } from 'ngx-facebook';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  visible: boolean;

  constructor(private http: HttpClient, private router: Router) { 
    this.visible = false;
  }

  private activeParlon = new BehaviorSubject<Array<any>>([]);
  currentParlon = this.activeParlon.asObservable();
  
  private activeBranch = new BehaviorSubject<Array<any>>([]);
  currentBranch = this.activeBranch.asObservable();

  private parlonAreas = new BehaviorSubject<Array<any>>([]);
  currentAreas = this.parlonAreas.asObservable();

  private areaID = new BehaviorSubject("");
  selectedAreaID = this.areaID.asObservable();

  private searchArea = new BehaviorSubject("");
  searchAreaValue = this.searchArea.asObservable();

  private selectedService = new BehaviorSubject<Array<any>>([]);
  currentService = this.selectedService.asObservable();

  private selectedDeal = new BehaviorSubject<Array<any>>([]);
  currentDeal = this.selectedDeal.asObservable();

  private selectedFilter = new BehaviorSubject<Array<any>>([]);
  filteredValue = this.selectedFilter.asObservable();

  private activeDeal = new BehaviorSubject<Array<any>>([]);
  currentdeal = this.activeDeal.asObservable();

  private activeDealPrice = new BehaviorSubject<Array<any>>([]);
  currentdealPrice = this.activeDealPrice.asObservable();

  changeActiveParlon(parlon) {
    this.activeParlon.next(parlon);
  }

  changeActiveBranch(branch) {
    this.activeBranch.next(branch);
  }

  changeAreas(areas) {
    this.parlonAreas.next(areas);
  }

  changeSelectedAreaID(id) {
    this.areaID.next(id);
  }

  changeSearchArea(value) {
    this.searchArea.next(value);
  }

  changeSelectedFilter(value) {
    this.selectedFilter.next(value);
  }

  selectService(value) {
    this.selectedService.next(value);
  }
  
  selectDeal(value) {
    this.selectedDeal.next(value);
  }

  changeActiveDeal(deal) {
    this.activeDeal.next(deal);
  }

  changeActiveDealPrice(price) {
    this.activeDealPrice.next(price);
  }

  convertToDate(value) {
    if(value != null) {
      let timeTokens = value.split(':');
      if(isNaN(timeTokens[0])) {
        return value;
      } else {
        let date = new Date(1970,0,1, timeTokens[0], timeTokens[1]);
        return moment(date).format('hh:mm A');
      }
    }
  }

  fbParams() {
    const fbParams:InitParams  = {
      appId            : '498991750655823',
      xfbml            : true,
      version          : 'v4.0'
    };
    return  fbParams;
  }
  
}
