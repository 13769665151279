export const environment = {
  production: true,
  api: 'https://api.parlon.sg/api/',
  booking: 'https://api.parlon.sg/booking/',
  deals:'prod_Deals_sg',
  parlons:'prod_Parlon_sg',
  deals_price_desc:'prod_Deals_sg_price_desc',
  deals_price_asc:'prod_Deals_sg_price_asc',
  og_offers:'prod_OG_Offers',
  og_brands:'prod_OG_Brands',
  resource: "https://parlon-sg-bucket.s3.ap-southeast-1.amazonaws.com/",
  imagekit: "https://ik.imagekit.io/parlon/sg/",
  currency: "SGD",
  currencySign: "S$",
  showLocation: false,
  emptyNav: true,
  teaser: false,
  hide_user_success_message:true,
  country:'Singapore',
  typesense_url:'https://rk27dfaiw9n3t6svp-1.a1.typesense.net/',
  typesense_key:'wkBdaHgfGJRTHwlMWfQIPl5ru0eZ1fJD',
  maya_token: 'cGstYkM4MklMOFl6aGtTdGRVZnNkOUkxS01MZEJhWXFoS0VRZ3E3d0g4U1BUcTo=',
  maya_url:'https://pg.paymaya.com/payments/v1/payment-tokens'
};