import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../_models/user.model';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(platformId)) {
      authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
    } else {
      authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
    }
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authData
      })
    }
  }
  
  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  validateEmail(email) {
    return this.http.post(endpoint + 'user/validate-email', email, httpOptions).pipe(map(this.extractData))
  }
 
  registerUser(user) {
    return this.http.post(endpoint + 'user/register', user, httpOptions).pipe(map(this.extractData))
  }
  

  update(user: User) {
      return this.http.put(endpoint + 'user/' + user.id, user);
  }

  delete(id: number) {
      return this.http.delete(endpoint + 'user/' + id);
  }

  getAll() {
    return this.http.get<User[]>(endpoint + 'user');
  }

  getById(id: number) {
      return this.http.get(endpoint+ 'user/' + id);
  }



  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // send the error to remote logging infrastructure
      console.error(error); // log to console instead

      //  better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`); 
      this.router.navigate(['/']);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
