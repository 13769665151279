<div class="pt-6">
	<table class="table table-operating-hours table-sm table-borderless">
		<tbody>
			<tr>
				<td>Sunday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['sunday_opening'])}} - {{convertToDate(branch['sunday_closing'])}}</td>
			</tr>
			<tr>
				<td>Monday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['monday_opening'])}} - {{convertToDate(branch['monday_closing'])}}</td>
			</tr>
			<tr>
				<td>Tuesday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['tuesday_opening'])}} - {{convertToDate(branch['tuesday_closing'])}}</td>
			</tr>
			<tr>
				<td>Wednesday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['wednesday_opening'])}} - {{convertToDate(branch['wednesday_closing'])}}</td>
			</tr>
			<tr>
			<td>Thursday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['thursday_opening'])}} - {{convertToDate(branch['thursday_closing'])}}</td>
			</tr>
			<tr>
				<td>Friday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['friday_opening'])}} - {{convertToDate(branch['friday_closing'])}}</td>
			</tr>
			<tr>
				<td>Saturday</td>
				<td class="text-end tabular-nums">
					{{convertToDate(branch['saturday_opening'])}} - {{convertToDate(branch['saturday_closing'])}}</td>
			</tr>
		</tbody>
	</table>

	<div class="mt-7">
		<p *ngIf="website != null">Website:<br />
			<a href="{{ parlon['website'] }}" target="_blank">{{ website }}</a></p>
	
		<p class="fw-semibold mb-2" *ngIf="parlon['facebook'] != null || parlon['twitter'] != null || parlon['instagram'] != null">Follow {{ parlon['business_name'] }}</p>
	
		<div class="row gx-4">
			<div class="col-auto" *ngIf="parlon['facebook'] != null">
				<a href="{{ parlon['facebook'] }}" target="_blank">
					<i class="fab fa-facebook fa-2x fa-fw"></i>
				</a>
			</div>
	
			<div class="col-auto" *ngIf="parlon['instagram'] != null">
				<a href="{{ parlon['instagram'] }}" target="_blank">
					<i class="fab fa-instagram fa-2x fa-fw"></i>
				</a>
			</div>
	
			<div class="col-auto" *ngIf="parlon['youtube'] != null">
				<a href="{{ parlon['youtube'] }}" target="_blank">
					<i class="fab fa-youtube fa-2x fa-fw"></i>
				</a>
			</div>

			<div class="col-auto" *ngIf="parlon['tiktok'] != null">
				<a href="{{ parlon['tiktok'] }}" target="_blank">
					<i class="fa-brands fa-tiktok fa-2x fa-fw"></i>
				</a>
			</div>
		</div>
	</div>
</div>