<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- If the Flickity has page dots, add `has-dots` class.
	- To set number of slides, change it on CSS
-->

<section class="bg-light-1 py-5 py-md-7 overflow-hidden">
	<div class="container">
		<h2 class="h2 fw-semibold text-center mb-4 mb-md-6">{{data.title}}</h2>

		<div class="row justify-content-center">
			<div class="col-12 col-lg-10 col-xl-11">
				<div id="flickity-bookings-{{index}}" class="flickity flickity-bookings flickity-overflow has-dots row gx-5">
					<div class="slide" *ngFor="let booking of data.partners">
						<a href="/parlon/{{booking['slug']}}" class="link-static">
							<div class="card card-parlon-booking">
								<div class="card-body p-0">
									<div class="d-grid grid-parlon-booking-item">
										<div class="cell cell-img">
											<div class="ratio ratio-4x3">
												<img src="{{resource + booking['logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
											</div>
										</div>

										<div class="cell cell-content">
											<h4 class="h4 mb-0">{{booking['business_name']}}</h4>
											<p class="fw-light mt-2 mb-0" *ngIf="booking['active_deals_count'] > 0">{{booking['active_deals_count']}} deals available</p>
										</div>
									</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>