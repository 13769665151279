<form class="form-promo-code">
  <div class="row" *ngIf="promocodeValue == null">
    <div class="col-md-8">
      <mat-form-field appearance="fill" [floatLabel]="'never'" class="w-100">
        <mat-label>Enter code</mat-label>
        <input matInput placeholder="Enter code" id="promoCode" name="promoCode" [(ngModel)]="promoCodeInput">
      </mat-form-field>
      <mat-error id="promo-error" class="d-none error-msg">Invalid Promo Code</mat-error>
      <mat-error id="login-error" class="d-none">You must be logged in to your Parlon account to use that promo code</mat-error>
    </div>
    <div class="col-md-4">    
      <button mat-flat-button color="primary" class="text-uppercase w-100 p-3" type="submit" (click)="applyCode()" [disabled]="!promoCodeInput || loading">
        Apply
        <mat-icon *ngIf="loading"><mat-spinner color="accent" diameter="20">
        </mat-spinner></mat-icon>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="promocodeValue != null">
    <div class="col-md-8 code-label">
      <h3 class="h -secondary">{{promocodeValue['code']}}</h3>
    </div>
    <div class="col-md-4">    
      <button mat-flat-button color="primary" class="text-uppercase w-100 p-3" type="submit" (click)="removeCode()" [disabled]="loading">
        Remove
        <mat-icon *ngIf="loading"><mat-spinner color="accent" diameter="20">
        </mat-spinner></mat-icon>
      </button>
    </div>
  </div>
  <div class="">
    	<!-- Tablet & Desktop layout -->
      <div class="row row-partner-logos gx-5 d-md-flex d-sm-none d-none mb-md-0" *ngIf="partners.length > 1">
        <div class="col col-auto">
          <p class="fw-normal text-muted mb-0">Use your code from</p>
        </div>

        <div class="col-auto partner-logo" *ngFor="let partner of partners">
          <img src="{{partner['logo']}}" alt="" class="img-fluid obj-fit-contain">
        </div>

      </div>

      <!-- Mobile layout -->
			<div class="d-sm-block d-md-none d-lg-none"  *ngIf="partners.length > 1">
					<div class="row row-partner-logos gx-4 my-3">
            <div class="">
              <p class="fw-normal text-muted">Use your code from</p>
            </div>
    
						<div class="col partner-logo" *ngFor="let partner of partners">
              <img src="{{partner['logo']}}" alt="" class="img-fluid obj-fit-contain">
						</div>

					</div>
      </div>
  </div>
</form>
