import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class TypesenseSearchService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(platformId)) {
      authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
    } else {
      authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
    }
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authData
      })
    }
  }

   configUrl=environment.typesense_url+"multi_search";



   search(query="*",location="all",page=1):Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-TYPESENSE-API-KEY':environment.typesense_key
    });
    const requestOptions = { headers: headers };
    if(query == null || query == '') {
      query="*";
    }
    let json = {
      "searches": [
        {
          "collection": "Deals",
          "q": query,
          "per_page":50,
          "page":page,
          "query_by": "name,categories.category_name,categories.subcategories,parlon"
        },
        {
          "collection": "Parlons",
          "q": query,
          "per_page":50,
          "page":page,
          "query_by": "parlon_name"
        }
      ]
    }
    if(location != 'all' && location != '') {
      json['searches'][0]['filter_by'] = "locations:="+location;
      json['searches'][1]['filter_by'] = "locations:="+location;
    }
    return this.http.post(this.configUrl, json, requestOptions);
    
  }


}