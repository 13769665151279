<!--
	NOTE:
	- Get the height of the navigation bar and set the top offset of the sticky navigation jumplist.
	- Add `show` class to `sticky-jumplist` when scrolled to show sticky navigation jumplist.
-->

<!-- <app-hero-banner></app-hero-banner> -->
<app-search-bar *ngIf="showSearchBar && isLoadedSearchBar"></app-search-bar>

<section class="py-6 py-md-8">
	<div class="container">
		<div id="sticky-jumplist" class="sticky-jumplist">
			<div class="row flex-sticky-jumplist-main align-items-center">
				<div class="col-auto col-view">
					<ul class="nav nav-pills nav-view-type" role="tablist">
	
						<li class="nav-item">
							<button type="button" id="grid-view-tab" class="nav-link active" role="tab"
								data-bs-toggle="pill" data-bs-target="#tabpanel-grid-view"
								aria-controls="tabpanel-grid-view" aria-selected="false">
									<i class="fas fa-grid-2 fa-lg fa-fw"></i>
							</button>
						</li>

						<li class="nav-item">
							<button type="button" id="list-view-tab" class="nav-link " role="tab"
								data-bs-toggle="pill" data-bs-target="#tabpanel-list-view"
								aria-controls="tabpanel-list-view" aria-selected="true">
									<i class="fas fa-list-ul fa-lg fa-fw"></i>
							</button>
						</li>
					</ul>
				</div>

				<div class="col col-jumplist">
					<div class="row flex-letter-jumplist justify-content-center gx-md-8">
						<div class="col-auto content">
							<ul class="nav nav-letter-jumplist">
								<li class="nav-item" *ngFor="let list of salonList">
									<a href="/parlons#{{view}}-{{list.letter}}" class="nav-link link-secondary" *ngIf="list['salons'].length > 0">{{list.letter}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row align-items-center mb-5">
			<div class="col">
				<h2 class="h2 fw-semibold mb-0">{{pageData['title']}}</h2>
			</div>

			<div class="col-auto pe-sm-0">
				<ul class="nav nav-pills nav-view-type" role="tablist">
					
					<li class="nav-item" >
						<button type="button" id="grid-view-tab" class="nav-link active" role="tab"
							data-bs-toggle="pill" data-bs-target="#tabpanel-grid-view"
							aria-controls="tabpanel-grid-view" aria-selected="false" (click)="changeView('grid')">
								<i class="fas fa-grid-2 fa-lg fa-fw"></i>
						</button>
					</li>
					
					<li class="nav-item">
						<button type="button" id="list-view-tab" class="nav-link " role="tab"
							data-bs-toggle="pill" data-bs-target="#tabpanel-list-view"
							aria-controls="tabpanel-list-view" aria-selected="true" (click)="changeView('list')">
								<i class="fas fa-list-ul fa-lg fa-fw"></i>
						</button>
					</li>

				</ul>
			</div>
		</div>

		<p class="mb-6">{{pageData['description']}}</p>
		
		<!-- Legend -->
		<div class="d-flex flex-wrap gap-6 gap-sm-7 gap-md-8">
			<div class="col-auto px-0">
				<div class="row align-items-center gx-4">
					<div class="col-auto">
						<div class="d-flex align-items-center justify-content-center img-legend-icon">
							<i class="fak fa-booking fa-fw"></i>
						</div>						
					</div>

					<div class="col-auto">
						<span class="text-muted">Booking available</span>
					</div>
				</div>
			</div>

			<div class="col-auto px-0">
				<div class="row align-items-center gx-4">
					<div class="col-auto">
						<div class="d-flex align-items-center justify-content-center img-legend-icon">
							<i class="fak fa-deals fa-fw"></i>
						</div>						
					</div>

					<div class="col-auto">
						<span class="text-muted">Deals available</span>
					</div>
				</div>
			</div>
		</div>

		<!-- Jumplist -->
		<div class="mt-6 mt-md-8 mb-5 mb-md-7">
			<div class="row flex-letter-jumplist justify-content-center gx-md-8">
				<div class="col-auto content">
					<ul class="nav nav-letter-jumplist">
						<li class="nav-item" *ngFor="let list of salonList">
							<a href="/parlons#{{view}}-{{list.letter}}" class="nav-link link-secondary" *ngIf="list['salons'].length > 0">{{list.letter}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- Tab content -->
		<div class="tab-content">
			<div id="tabpanel-list-view" class="tab-pane fade " role="tabpanel" tabindex="0" aria-labelledby="list-view-tab">
				<list-view [data]="salonList"></list-view>
			</div>

			<div id="tabpanel-grid-view" class="tab-pane fade show active" role="tabpanel" tabindex="0" aria-labelledby="grid-view-tab">
				<grid-view [data]="salonList"></grid-view>	
			</div>
		</div>
	</div>
</section>
