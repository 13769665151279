import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { CommonElementsModule } from '../../common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GiftingComponent } from './gifting.component';
import { GiftingFormComponent } from './components/gifting-form/gifting-form.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { NgxCcModule } from 'ngx-cc';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CommonElementsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxCcModule
  ],
  declarations: [GiftingComponent, GiftingFormComponent, CreditCardFormComponent]
})
export class GiftingModule { }
