import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DrawerService } from '../../../../services/drawer/drawer.service';
import { UserAccountService } from '../../../../services/user-account/user-account.service'
import { ContentService } from '../../../../services/content/content.service';
import { environment } from '../../../../../environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AttachSession } from 'protractor/built/driverProviders';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
    private userService: UserAccountService,
    private drawerService: DrawerService,
    private ContentService: ContentService,
    private router: Router,
    private smartModal: NgxSmartModalService,
    private socialAuthService: AuthService) { }

  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  })

  hidePasswordIcon: boolean = true;
  get email() { return this.form.get('email') };
  get password() { return this.form.get('password') };
  inValidEmailOrPassword: boolean = false;
  submitted: boolean = false;
  $loginSub: Subscription | undefined;
  bagId = localStorage.getItem('bagId');
  ogContent;
  resource = environment.resource;
  signRequest = false;
  @Output() fbAuthEmitter = new EventEmitter();


  ngOnDestroy() {
    if (this.$loginSub)
      this.$loginSub.unsubscribe()
  }

  ngOnInit() {
    this.ContentService.ogContent.subscribe((data) => {
      if (!(data === undefined || data.length == 0)) {
        this.ogContent = data;
      }
    });

    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        
        switch (user['provider']) {
          case 'FACEBOOK':
            if (this.signRequest == true) {
              if(!user['email']) {
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'We can’t get your email from Facebook. Please go to your Facebook settings and allow access to your email, then try again.'
                });
                return;
              }
              const payload = {
                "provider": "facebook",
                "access_token": user['authToken'],
                "social_id": user['id']
              };
              this.userService.verifyFBuser(payload).subscribe((data) => {
                let user_data = {
                  "access_token": user['authToken'],
                  "social_id": user['id'],
                  "first_name": user['facebook']['first_name'],
                  "last_name": user['facebook']['last_name'],
                  "email": user['facebook']['email'] || '',
                  "birthday": user['facebook']['birthday'] || '',
                  "contact_number": '',
                  "new_user": true
                };
                switch (data['code']) {
                  case 'USER-LINKING-READY':
                    user_data['first_name'] = data['user']['first_name'];
                    user_data['last_name'] = data['user']['last_name'];
                    user_data['contact_number'] = data['user']['contact_number'];
                    user_data['birthday'] = data['user']['birthday'];
                    user_data['email'] = data['user']['email'];
                    user_data['new_user'] = false;
                    this.fbAuthEmitter.emit(user_data);
                    this.smartModal.getModal('fbAuthModal').open();
                    break;
                  case 'NEW-USER':
                    this.fbAuthEmitter.emit(user_data);
                    this.smartModal.getModal('fbAuthModal').open();
                    break;
                  case 'AUTHENTICATED':
                    const jsonData = {
                      success: true,
                      data: data['data'],
                      user: data['user'],
                      candies: data['candies'],
                      bookings: data['bookings'],
                      vouchers: data['vouchers'],
                      bagSummary: data['bagSummary'],
                      og: data['og']
                    };
                    this.userService.setActiveUser(jsonData);
                    this.router.navigate(['/']);
                    break;
                  default:
                    break;
                }
              });
            }
            break;
          default:
            break;
        }
      }

    });
  }


  forgotPassword() {
    this.drawerService.setForgotPassword(true);
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.inValidEmailOrPassword = false;
    this.submitted = true;
    let json = { email: this.email.value, password: this.password.value };
    if (this.bagId != null)
      json['bag_id'] = this.bagId;
    this.userService.login2(json).subscribe((data: any) => {
      this.submitted = false;
      if (data.success == false) {
        if (data.message == "One time pin has been sent to your email. Please verify to continue.") {
          const now = new Date()
          const expiry = now.setMinutes(now.getMinutes() - 5);
          localStorage.setItem('otp-expiration', JSON.stringify(expiry));
          this.router.navigate(['verify', this.email.value]);
        }
        this.inValidEmailOrPassword = true;
      } else {
        this.userService.setActiveUser(data);
      }
    })
  }

  fbAuthModalShow() {
    this.signRequest = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    
    this.smartModal.getModal('registerSelect').close();
    // this.smartModal.getModal('fbAuthModal').open();
  }

  openSelect() {
    this.smartModal.getModal('registerSelect').open();
  }

}
