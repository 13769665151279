import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-branded-candies',
  templateUrl: './branded-candies.component.html',
  styleUrls: ['./branded-candies.component.scss']
})
export class BrandedCandiesComponent implements OnInit {

  @Input() brandedCandy;
  @Input() summary;
  appliedCandies = 0;
  limit = 0;
  usedCandy = 0;
  edit = true;
  @Output() applyCandy = new EventEmitter();

  resource = environment.resource;

  constructor() { }
  isViewAllCandiesPage  = true;
  ngOnInit() {
    this.appliedCandies = this.summary['brandedCandiesUsed'];
    if(this.appliedCandies > 0) {
      this.edit = false;
    }
    this.usedCandy = Math.floor(this.appliedCandies);
    let sum = 0;
    this.summary['items'].forEach(e => {
      sum += e['total'];
    });
    this.limit = sum;
  }

  apply(){
    let data = {
      "candies_amount": this.usedCandy,
      "brand": this.brandedCandy['parlon_id']
    }
    this.applyCandy.emit(data);
    if(this.usedCandy > 0) {
      this.edit = false;
    } else {
      this.edit = true;
    }
    this.appliedCandies = this.usedCandy;
  }

}
