<form id="paymentLinkForm" [formGroup]="paymentLinkForm" class="payment-form-container">

 <div class="mb-2" formGroupName="paymentDetailsFormGroup">
    <mat-form-field appearance="fill" class="w-100 payment-link-amount-input">
      <mat-label>Amount</mat-label>

      <input matInput type="number" [(ngModel)]="amount" placeholder="Amount *" name="amount" formControlName="amount" required>
      <!-- <mat-error *ngIf="f.amount.errors?.required">The amount field is required.</mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100 ">
      <mat-label>Email Address</mat-label>
      <input matInput placeholder="Email Address (optional)" id="email" name="email" formControlName="email">

      <!-- <mat-error *ngIf="f.email?.errors">
        <div *ngIf="f.email.errors?.required">The email field is required.</div>
        <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
      </mat-error> -->
    </mat-form-field>

    <div style="margin-top:-1.25em;"><small> We will send payment confirmation to this email address.</small></div>
  </div>

  <!-- Payment methods -->
  <h5 class="h5 fw-semibold mt-5">Select your payment method</h5>

  <div class="payment-method-accordion pt-4" formGroupName="paymentFormGroup">
    <mat-accordion>
      <mat-radio-group formControlName="paymentChannel">
        <mat-expansion-panel *ngIf="channels.includes('parlon_credit_card')">
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_credit_card">
                  Credit/Debit Card
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="card-logo">
                  <img src="/assets/images/payment-logos/visa.png" alt="visa-and-mastercard">
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div>
            <p class="mb-2">Your payment shall be processed safely and securely by a third-party payment gateway
              provider. For your security, we do not store your credit card information.</p>

            <!-- CREDIT CARD FORM -->
            <payment-credit-card-form [paymentFormGroup]="paymentLinkForm.get('paymentFormGroup')"></payment-credit-card-form>
          </div>

        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="channels.includes('parlon_maya')">
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_maya">
                  Credit/Debit Card
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="card-logo">
                  <img src="/assets/images/payment-logos/visa.png" alt="paymaya">
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div>
            <p class="mb-2">Your payment shall be processed safely and securely by a third-party payment gateway
              provider. For your security, we do not store your credit card information.</p>

            <!-- CREDIT CARD FORM -->
            <payment-credit-card-form [paymentFormGroup]="paymentLinkForm.get('paymentFormGroup')"></payment-credit-card-form>
          </div>

        </mat-expansion-panel>


        <mat-expansion-panel class="nopad" *ngIf="channels.includes('parlon_credit_card_redirect')">
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_credit_card_redirect">
                  Credit/Debit Card Payment
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="card-logo">
                  <img src="/assets/images/payment-logos/visa.png" alt="gcash">
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel class="nopad" *ngIf="channels.includes('parlon_gcash')">
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_gcash">
                  GCash
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="card-logo">
                  <img src="/assets/images/payment-logos/gcash.png" alt="gcash">
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel class="nopad" *ngIf="channels.includes('parlon_grabpay')">
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_grabpay">
                  Grab Pay
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="card-logo">
                  <img src="/assets/images/payment-logos/grabpay.png" alt="grabpay">
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel class="nopad" *ngIf="channels.includes('parlon_ipp')" >
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_ipp" disabled="{{amount<3000}}">
                  Metrobank Installment (3 months with 0% interest)
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="card-logo">
                  <img src="/assets/images/payment-logos/metrobank.png" alt="grabpay">
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel class="nopad" *ngIf="channels.includes('parlon_webpay')">
          <mat-expansion-panel-header>
            <div class="row row-payment-channel gx-4">
              <div class="col">
                <mat-radio-button value="parlon_webpay">
                  Direct Debit with UBP or BPI
                </mat-radio-button>
              </div>
              <div class="col-auto">
                <div class="d-flex">
                  <div class="card-logo">
                    <img src="/assets/images/payment-logos/bpi.png" alt="bpi">
                  </div>
                  <div class="card-logo">
                    <img src="/assets/images/payment-logos/unionbank.png" alt="metrobank">
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <div class="mt-3 mb-6">
          <mat-checkbox id="eVoucherPolicy" color="primary" formControlName="eVoucherPolicy">
            By clicking the Pay Now button, I confirm that I have read and agree to <a
              href="https://legal.parlon.ph/post/terms-of-use" target="_blank">Parlon Terms of Use</a> and <a
              href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
          </mat-checkbox>
        </div>

        <!-- PAYMENT ERROR MESSAGE -->
        <div class="mt-3 mb-6"  *ngIf="isTransactionFailed == true">
          <p class="text-danger"><strong>Oh no, we failed to process your payment /  payment processing failed. </strong><br>
            We were unable to charge your account for this transaction, bestie. Kindly double check your details (if you are using a credit/debit card, make sure you are entering the correct expiry date format) or choose another payment method. Then, try again. Thank you!
            </p>
        </div>

        <div class="d-grid mb-4">
          <!-- BUY NOW BUTTON -->
          <button mat-flat-button color="secondary" class="p-3" type="submit" [disabled]="loading" (click)="onSubmit()">
            <span class="text-uppercase">Pay now</span>
            <!-- <span *ngIf="isTotalZero == true">CLAIM YOUR FREE DEAL</span> -->
            <!-- <mat-icon *ngIf="loading">
              <mat-spinner color="accent" diameter="20">
              </mat-spinner>
            </mat-icon> -->
          </button>
        </div>


      </mat-radio-group>
    </mat-accordion>
  </div>
</form>

<form id="cc-form" style="display: none;">
  <input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br/>
  <input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br/>
  <input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br/>
  <input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
</form>
