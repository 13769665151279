import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserResetPasswordComponent } from './user-reset-password.component';

const routes: Routes = [
    { path:"", component: UserResetPasswordComponent }
];

@NgModule({
    exports: [RouterModule],
    imports:[RouterModule.forChild(routes)]
})

export class UserResetPasswordRouterModule{}