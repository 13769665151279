<!-- 
	NOTE:
	- Add `d-none` to `search-box` container to hide. Only when stacked search layout is enabled.
-->


<section class="pos-relative">
	<!-- Single banner only -->
	<a href="{{data[0]['link_out_url']}}" *ngIf="data.length == 1">
		<div class="ratio ratio-banner" >
			<img src="{{resource + data[0]['mobile_img']}}?tr=w-2560" alt="" loading="lazy" class="img-fluid mobile obj-fit-cover">
			<img src="{{resource + data[0]['tablet_img']}}?tr=w-2560" alt="" loading="lazy" class="img-fluid tablet obj-fit-cover">
			<img src="{{resource + data[0]['desktop_img']}}?tr=w-2560" alt="" loading="lazy" class="img-fluid desktop obj-fit-cover">
		</div>
	</a>

	<!-- Flickity // remove `d-none` -->
	<div class="flickity flickity-banner" *ngIf="data.length > 1">
		<div class="slide" *ngFor="let slide of data">
			<a href="{{slide['link_out_url']}}">
				<div class="ratio ratio-banner">
					<img src="{{resource + slide['mobile_img']}}?tr=w-2560" alt="" loading="lazy" class="img-fluid mobile obj-fit-cover">
					<img src="{{resource + slide['tablet_img']}}?tr=w-2560" alt="" loading="lazy" class="img-fluid tablet obj-fit-cover">
					<img src="{{resource + slide['desktop_img']}}?tr=w-2560" alt="" loading="lazy" class="img-fluid desktop obj-fit-cover">
				</div>
			</a>
		</div>
	</div>

	<!-- Search box -->
	<!-- <div class="search-box">
		<div class="container">
			<search-input-v2></search-input-v2>
		</div>
	</div> -->
</section>