import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'ad-space-slider',
  templateUrl: './ad-space-slider.component.html',
  styleUrls: ['./ad-space-slider.component.scss']
})
export class AdSpaceSliderComponent implements OnInit {

  @Input() data;
  @Input() index:any;
  resource = environment.resource;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;
      // let elem = el.querySelector('.flickity-ad-banners');
      let elem = el.querySelector('#flickity-ad-banners-'+this.index);
      let flkty = new Flickity(elem, {
        contain: true,
        autoPlay: 3500,
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: false
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }

}
