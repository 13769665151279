<div class="card featured-deals-list-card">
	<div class="card-body p-0">
		<div class="row gx-4 gx-md-5 gy-3">
			<div class="col-5 col-md-4 col-lg-12">
				<div class="ratio ratio-img-deals-list-card">
					<img src="{{ featuredDeals['thumb'] }}" alt="" class="img-fluid obj-fit-cover">

					<div class="category-deals-grid pink">
		
						<p class="price-deals fw-semibold mb-0">
							<span class="price" *ngIf="featuredDeals['discount_type'] == 'Value Discount'">
								SAVE PHP {{ featuredDeals['discount_value'] | number }}</span>

							<span class="price" *ngIf="featuredDeals['discount_type'] == 'Percentage Discount'">
								{{ featuredDeals['discount_value'] | number }}% OFF</span>
						</p>
					</div>
				</div>

				
			</div>
	
			<div class="col-7 col-md-8 col-lg-12">
				<div class="category-deals-inline mb-3 pink">
	
					<p class="price-deals fw-semibold mb-0">
						<span class="price" *ngIf="featuredDeals['discount_type'] == 'Value Discount'">
							SAVE PHP {{ featuredDeals['discount_value'] | number }}</span>

						<span class="price" *ngIf="featuredDeals['discount_type'] == 'Percentage Discount'">
							{{ featuredDeals['discount_value'] | number }}% OFF</span>
					</p>
				</div>

				<h5 class="h5 text-body fw-semibold clamp-2 mb-1">{{ featuredDeals['name'] }}</h5>

				<p class="mb-0">
					<span class="text-secondary fs-6">PHP {{ featuredDeals['deal_price'] | number }}</span>&nbsp;
					<span class="small text-muted text-decoration-line-through">PHP {{ featuredDeals['original_price']  | number }}</span>
				</p>
			</div>
		</div>
	</div>
</div>
