import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../../../services/rest.service';

@Component({
  selector: 'parlon-details',
  templateUrl: './parlon-details.component.html',
  styleUrls: ['./parlon-details.component.scss']
})
export class ParlonDetailsComponent implements OnInit {

  constructor(private rest:RestService) { }

  @Input()
  data: any[];
  @Input()
  branch: any[];
  contact: any[];
  resourceLink = this.rest.resourceLink();

  @Input() showReview = false;

  @Input() starCount:number;
  totalStarCount = 83;

  
  ngOnInit() {
    this.contact = this.branch['contact_numbers'].split(';');
  }

}
