import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { DealsService } from '../../../../services/deals/deals.service';
import { environment } from '../../../../../environments/environment';

export interface Deal {
  deal: object;
  parlon: object;
}

@Component({
  selector: 'deal-nav',
  templateUrl: './deal-nav.component.html',
  styleUrls: ['./deal-nav.component.scss']
})
export class DealNavComponent implements OnInit {

  deal: any;
  resource = environment.resource;
  @Input() cartCount:any;
  @Output() searchEmit = new EventEmitter();

  constructor(
    private DealsService:DealsService
  ) { }

  ngOnInit() {
    this.DealsService.currentdeal.subscribe(deal => { 
      this.deal = deal;
    });
  }

  showSearch() {
    this.searchEmit.emit(null);
  }

}
