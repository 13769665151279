import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'memberships-slider',
  templateUrl: './memberships-slider.component.html',
  styleUrls: ['./memberships-slider.component.scss']
})
export class MembershipsSliderComponent implements OnInit {

  @Input() data;
  resource = environment.resource;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-memberships');
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        autoPlay: true,
        freeScroll: true,
        freeScrollFriction: 0.075,
        pageDots: false,
        prevNextButtons: false,
        on: {
          ready: function() {
            // Make cards same height

            window.addEventListener('resize', function(e) {
              let slide = el.querySelectorAll('.slide');

              // Unset height of cards and let Flickity get the tallest height in the slider
              for (let i = 0; i < slide.length; i++) {
                slide[i].style.height = "unset";
              }
              flkty.resize();

              // Re-set card to 100%
              setTimeout(() => {
                for (let i = 0; i < slide.length; i++) {
                  slide[i].style.height = "100%";
                }
              }, 50);
            }, true);
          }
        }
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }

}
