import { 
  MatButtonModule, 
  MatCheckboxModule, 
  MatFormFieldModule, 
  MatSelectModule, 
  MatInputModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule, 
  MatProgressSpinnerModule,
  DateAdapter, 
  MAT_DATE_FORMATS, 
  MAT_DATE_LOCALE,
  MatStepperModule,
  MatExpansionModule,
  MatRadioModule,
  MatDialogModule,
  } 
from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';

import { NgModule } from '@angular/core';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper'

export const DefaultTooltipOptions: TooltipOptions = {
  'placement': 'bottom',
  'theme': 'light',
  'max-width': 500,
  'trigger': 'hover'
}
@NgModule({
  imports: [
    MatButtonModule,
    MatChipsModule,
    MatCheckboxModule, 
    MatIconModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    MatInputModule,
    MatInputModule,
    MatTabsModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatStepperModule,
    MatExpansionModule,
    MatRadioModule,
    TooltipModule.forRoot(DefaultTooltipOptions as TooltipOptions),
    LazyLoadImagesModule,
    NgxUsefulSwiperModule
  ],
  exports: [
    MatButtonModule,
    MatChipsModule,
    MatCheckboxModule, 
    MatIconModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    MatInputModule,
    MatInputModule,
    MatTabsModule,
    MatCardModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,
    MatRadioModule,
    TooltipModule,
    LazyLoadImagesModule,
    NgxUsefulSwiperModule
    ],
  providers: []
})

export class MaterialModule {  }