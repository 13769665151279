import { Component, OnInit, Input } from '@angular/core';
import { AlgoliaInsightService } from '../../services/algolia-insight/algolia-insight.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'deal-card-template',
  templateUrl: './deal-card-template.component.html',
  styleUrls: ['./deal-card-template.component.scss']
})
export class DealCardTemplateComponent implements OnInit {

  constructor(private algoliaInsightService:AlgoliaInsightService, private router:Router) { }

  resource = environment.imagekit;
  @Input() templateClass;
  @Input() data;
  @Input() parlon_name;
  @Input() locations;
  @Input() width = 250;
  @Input() ribonStyle: any
  @Input() isLoggedIn;  
  @Input() userID;
  insightType="";      

  ngOnInit() {
  }

  getRibonStyle() {
    let backgroundColor = '#f8a5a7'
    let fontColor = 'white'


    if (this.ribonStyle != undefined) {
      backgroundColor = this.ribonStyle.background
      fontColor = this.ribonStyle.color
    }

    return {'background': backgroundColor, 'color': fontColor}
  }

  getCardStyle() {
    let cardBg = 'white'
    let cardFontColor = 'black'

    if (this.ribonStyle != undefined) {
      cardBg = this.ribonStyle.cardBg
      cardFontColor = this.ribonStyle.cardFontColor
    }

    return {'background-color': cardBg, 'color': cardFontColor}
  }
  clickedObjectIDs(){
    
    // Construct the URL with the encoded params string as a query parameter
    let urlWithParams="";
    if(this.insightType!="")
     urlWithParams = '/beauty-bag-deals/' + this.data['slug'] + '?insight-type='+this.insightType ;
    else
    urlWithParams = '/beauty-bag-deals/' + this.data['slug'];
    window.location.href = urlWithParams;            
    // const urlWithParams = '/beauty-bag-deals/' + this.data['slug'];
    // this.router.navigate([urlWithParams], { queryParams: { 'insight-type': this.insightType } });


    // FOR BASIC ALGOLIA API
    // if(this.isLoggedIn && this.userID !='')
    // {      
    //   let eventName = "When user clicks a deal without searching anything";
    //   this.insightType = "clickedObjectIDs";
    //   this.algoliaInsightService.clickedObjectIDs(this.userID.toString(),environment.deals,eventName,[this.data['id'].toString()]).subscribe((data)=>{
    //     const urlWithParams = '/beauty-bag-deals/' + this.data['slug'] + '?insight-type='+this.insightType ;
    //     window.location.href = urlWithParams;     
    //   },error=>{
    //     const urlWithParams = '/beauty-bag-deals/' + this.data['slug'] + '?insight-type='+this.insightType ;
    //     window.location.href = urlWithParams;    
    //   })       
    // }else{
    //   const urlWithParams = '/beauty-bag-deals/' + this.data['slug'] + '?insight-type='+this.insightType ;
    //   window.location.href = urlWithParams;     
    // }  
  }

}
