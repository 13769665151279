import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftingThankyouComponent } from './gifting-thankyou.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material';
import { DealCardRecommendationComponent } from './components/deal-card-recommendation/deal-card-recommendation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [GiftingThankyouComponent, DealCardRecommendationComponent]
})
export class GiftingThankyouModule { }
