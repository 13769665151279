import { Component, OnInit, ElementRef, HostListener, Inject  } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { WINDOW } from '@ng-toolkit/universal';
import { ContentService } from '../../../../services/content/content.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'beauty-bash-nav',
  templateUrl: './beauty-bash-nav.component.html',
  styleUrls: ['./beauty-bash-nav.component.scss']
})
export class BeautyBashNavComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window, 
    private route: ActivatedRoute,
    private router:Router,
    private el: ElementRef,
    private ContentService: ContentService
  ) { }

  resource = environment.resource;
  nav_display = 'block';
  startDate;
  contentData: any = [];
  daysDiff:any = 0;
  hoursDiff:any = 0;
  minDiff:any = 0;
  secDiff:any = 0;
  duration:any;
  timer_display = 'block';
  back_display = 'none';

  ngOnInit() {
    this.checkRoute();
    this.router.events.subscribe(event => {
      this.checkRoute();
    });

    let app = this;
    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentData = data;
        app.startDate = moment(this.contentData['bash_event']['to']);
        setInterval(function(){
          let currentDate = moment();
          app.duration = moment.duration(app.startDate.diff(currentDate));
          app.daysDiff = Math.floor(app.duration.asDays());
          app.hoursDiff = Math.floor(app.duration.asHours() - (app.daysDiff * 24));
          app.minDiff = Math.floor(app.duration.asMinutes() - ((app.daysDiff * 1440) + (app.hoursDiff * 60)));
          app.secDiff = Math.floor(app.duration.asSeconds() - ((app.daysDiff * 86400) + (app.hoursDiff * 3600) + (app.minDiff * 60)));
      }, 1000);
      }
    });
  }

  checkRoute(){
    if (this.router.url.includes('/beauty-bash/deals')){
      this.nav_display = 'none';
    } else {
      this.nav_display = 'block';
    }

    if (this.router.url.includes('/beauty-bash/teaser')){
      this.timer_display = 'none';
      this.back_display = 'd-inline-block'
    } else {
      this.timer_display = 'block';
      this.back_display = 'd-none';
    }

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {

    // Parlon partner mobile links
    const tabCheckpoint = document.getElementById('bash-deals-checkpoint');
    if (tabCheckpoint != null) {
      const elementOffsetTop = tabCheckpoint.offsetTop;
      if (elementOffsetTop <= this.window.pageYOffset) {
        this.nav_display = 'block';
      } else {
        this.nav_display = 'none';
      }
    }
  }

}
