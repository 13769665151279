import { Component, Inject, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'featured-deals-card',
  templateUrl: './featured-deals-card.component.html',
  styleUrls: ['./featured-deals-card.component.scss']
})
export class FeaturedDealsCardComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window, 
    private rest: RestService, 
    private route: ActivatedRoute, 
    private _shared: SharedService,
    private _title: Title
  ) { }
  @Input() featuredDeals = [];
  @Input() index: number;
  resourceLink = this.rest.resourceLink();

  parlonSlug: string;
  dealSlug: string;
  data: any[];
  parlon: any[];
  branch: any[];
  selectedDeals = [];
  isLoaded = false;


  ngOnInit() {
    this.branch = this.route.parent.snapshot.data['branch'];

    this._shared.currentParlon.subscribe((parlon) => {
      this.parlon = parlon;
      const page = this;
      this.branch['active_deals'].forEach(e => {
        const deals = {
            id: e['id'],
            deal_price: e['deal_price'],
            description: e['description'],
            thumb: this.resourceLink + e['primary_photo'],
            name: e['name'],
            original_price: e['original_price'],
            slug: e['slug'],
            discount_type: e['discount_type'],
            discount_value: e['discount_value'],
            valid_from: e['valid_from'],
            valid_to: e['valid_to']
        };
        page.selectedDeals.push(deals);
      });
    });
  }

}
