import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit, OnChanges {

  @Input() data:any;
  resource = environment.resource;

  constructor() { }

  ngOnInit() {
    
  }

  ngOnChanges() {
  }

  getLocations(data) {
    let locations = [];
    data.forEach(e => {
      locations.push(e['location_name']);
    });
    return locations;
  }

}
