
<div class="card mt-4 mb-5" >
  <div class="card-body card-background">
    <div class="row" >
      <div class="col-sm-12 text-sm-center text-md-start">
        <div class="d-flex justify-content-sm-center  justify-content-md-start align-items-center mb-3">
          <div>
            <img class="img-fluid" src="/assets/images/candy-icon.png" alt="candy_img">
          </div>
          <div class="d-flex align-items-center">
            <span  class="text-center fs-4 font-weight-bold ms-3">Parlon Candies</span>
          </div>

        </div>
        <!-- <p class="mt-1">You have {{bagInfo['candies']['allCandies'] | number}} Parlon Candies of different kinds</p> -->
      </div>  

      <div class="form">
        <mat-form-field class="w-100"  appearance="fill">
          <mat-label>Select candy kind</mat-label>
          <mat-select (selectionChange)="selectCandyType($event.value)" [(ngModel)]="selectedCandyType"  disableRipple>
            <mat-option value="parlon">Parlon Candies</mat-option>
            <!-- <mat-option value="branded" *ngIf="this.bagInfo['candies']['brandedCandies'].length > 0">Branded Candies</mat-option>             -->
          </mat-select>
        </mat-form-field>

        <div *ngIf="selectedCandyType == 'parlon' ">          
          <app-parlon-candies 
            (applyCandy)="onSubmit($event)" 
            [parlonCandies]="bagInfo['candies']['parlonCandies']" 
            [appliedCandies]="bagInfo['summary']['candies_discount']"
            [limit]="bagInfo['summary']['original_deal_price']">
          </app-parlon-candies>         
        </div>

        <div class="d-grid-branded" *ngIf="selectedCandyType == 'branded' && this.bagInfo['candies']['brandedCandies'].length > 0">
          <ng-container *ngFor="let brandedCandy of this.bagInfo['candies']['brandedCandies']">
            <app-branded-candies 
              (applyCandy)="onSubmit($event)"  
              [brandedCandy]="brandedCandy" 
              [summary]="findBrandedSummary(brandedCandy['parlon_id'])"></app-branded-candies>
          </ng-container>      
        </div>
      </div>

    </div>
  </div>
</div>




