<section class="py-5 py-md-7 overflow-x-hidden">
	<div class="container">
		<div class="row justify-content-md-start mb-4 mb-md-5">
			<div class="col-12 col-md-auto">
				<div class="row align-items-center justify-content-between">
					<div class="col">
						<div class="d-flex flex-header-content align-items-center col-gap-3 col-gap-md-4 col-gap-lg-5">
							<div class="col-auto col-logo">
								<div class="ratio ratio-header-img ratio-4x3">
									<img src="{{ resource + data['merchant_highlight_parlon_logo'] }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
								</div>
							</div>

							<div class="col">
								<h2 class="h2 fw-semibold mb-0">{{data['merchant_highlight_title']}}</h2>
							</div>
						</div>
					</div>

					<div class="col-auto d-none d-md-block">
						<a href="{{data['linkout']}}" [attr.data-pid]="data['deal_id']" class="link-secondary fs-2">
							<i class="fas fa-chevron-right fa-xs fa-fw"></i>
						</a>
					</div>
				</div>
			</div>

			<div class="col-12">
				<p class="text-header-subtitle mt-1 mb-0">{{data['merchant_highlight_description']}}</p>
			</div>
		</div>

		<div class="row flex-ft-deals-stack row-cols-1 row-cols-md-3 g-4">
			<div class="col" *ngFor="let deal of data['deals']">				
				<deal-card-template
					[userID]="userId"
					[isLoggedIn]="isLoggedIn" 
					[templateClass]="'split'" 
					[width]="450"
					[data]="deal" 
					[parlon_name]="data['merchant_highlight_parlon_name']"
					[locations]="data['locations']">
				</deal-card-template>
			</div>
		</div>

		<div class="d-md-none mt-5">
			<div class="row justify-content-center">
				<div class="col-auto">
					<a href="{{data['linkout']}}" [attr.data-pid]="data['deal_id']" mat-button class="mat-secondary">See all {{data['merchant_highlight_parlon_name']}} deals</a>
				</div>
			</div>
		</div>
	</div>
</section>