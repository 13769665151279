// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material';
import { CommonElementsModule } from '../../common';
import { NgxSiemaModule } from 'ngx-siema';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// Page Components
import { HomeComponent } from './home.component';
import { MainBannerComponent } from './components/main-banner/main-banner.component'
import { SearchComponent } from './components/search/search.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryCardsComponent } from './components/categories/category-cards/category-cards.component';
import { FeaturedParlonsComponent } from './components/featured-parlons/featured-parlons.component';
import { SliderComponent } from './components/featured-parlons/slider/slider.component';
import { AdBannerComponent } from './components/ad-banner/ad-banner.component';
import { FeaturedDealsComponent } from './components/featured-deals/featured-deals.component';
import { CategoryLoaderComponent } from './components/categories/loader/loader.component';
import { FeaturedParlonsLoaderComponent } from './components/featured-parlons/featured-parlons-loader/featured-parlons-loader.component';
import { DealsSliderComponent } from './components/featured-deals/deals-slider/deals-slider.component';
import { FeaturedServicesComponent } from './components/featured-services/featured-services.component';
import { FeaturedServicesSliderComponent } from './components/featured-services/featured-services-slider/featured-services-slider.component';
import { FeaturedParlonsGridComponent } from './components/featured-parlons/featured-parlons-grid/featured-parlons-grid.component';
import { CategorySliderComponent } from './components/categories/category-slider/category-slider.component';
import { WmBannerComponent } from './components/wm-banner/wm-banner.component';
import { PromoBannerComponent } from './components/promo-banner/promo-banner.component';
import { FeaturedParlonAppointmentsComponent } from './components/featured-parlon-appointments/featured-parlon-appointments.component';
import { FeaturedBlogsComponent } from './components/featured-blogs/featured-blogs.component';
import { ParlonBusinessComponent } from './components/parlon-business/parlon-business.component';
import { BrowseFeaturedParlonsGridComponent } from './components/featured-parlon-appointments/browse-featured-parlons-grid/browse-featured-parlons-grid.component';
import { DealsShowcaseComponent } from './components/deals-showcase/deals-showcase.component';
import { DealsCardSliderComponent } from './components/deals-showcase/deals-card-slider/deals-card-slider.component';
import { DealsCategoryComponent } from './components/deals-showcase/deals-category/deals-category.component';
import { DealsCardListComponent } from './components/deals-showcase/deals-card-list/deals-card-list.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CommonElementsModule,
    NgxSiemaModule.forRoot(),
    NgxSkeletonLoaderModule,
    RouterModule,
  ],
  declarations: [
    HomeComponent,
    MainBannerComponent,
    SearchComponent,
    CategoriesComponent,
    CategoryCardsComponent,
    FeaturedParlonsComponent,
    SliderComponent,
    AdBannerComponent,
    FeaturedDealsComponent,
    CategoryLoaderComponent,
    FeaturedParlonsLoaderComponent,
    DealsSliderComponent,
    FeaturedServicesComponent,
    FeaturedServicesSliderComponent,
    FeaturedParlonsGridComponent,
    CategorySliderComponent,
    WmBannerComponent,
    PromoBannerComponent,
    FeaturedParlonAppointmentsComponent,
    FeaturedBlogsComponent,
    ParlonBusinessComponent,
    BrowseFeaturedParlonsGridComponent,
    DealsShowcaseComponent,
    DealsCardSliderComponent,
    DealsCategoryComponent,
    DealsCardListComponent
    ]
})
export class HomeModule { }
