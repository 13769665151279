import { Component, OnInit,HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Deal } from '../../../_models/deal.model';
import { SearchService } from '../../../services/search/search.service';
import { TypesenseSearchService } from '../../../services/typesense-search/typesense-search.service';
import { environment } from '../../../../environments/environment';
import { PaginationInstance } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../../services/user-account/user-account.service';

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
    onResize() {
      this.getScreenWidth = window.innerWidth;                      
      this.getScreenHeight = window.innerHeight;
      if(this.getScreenWidth>480){
        this.router.navigate(['/search/all']);
      } 
    }
  public getScreenWidth: any;
  public getScreenHeight: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService:SearchService,
    private TypesenseSearchService:TypesenseSearchService,
    private userService: UserAccountService) { }
  popularSearches:any[];
  currentPageParlon:number =1;
  currentPageDeal:number =1;  
  deals:Deal[] = [];
  parlons:any[]= [];
  location:string="";
  locationJSONParse='';
  searchInput:string="";
  resource = environment.resource;
  isPageLoaded:boolean = false;  
  hideFirstLoadResult:number=1;
  deal_queryID="";
  parlon_queryID;
  currentPageDeals = 0;
  public deal_config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 50,
    currentPage: 0
  }
  public parlon_config: PaginationInstance = {
    id: 'custom2',
    itemsPerPage: 50,
    currentPage: 0
  }

  $userSubscription:Subscription|undefined;
  userId:string='';
  isLoggedIn:boolean = false;

  params:any;
  nbPagesDeals= 0;
  totalRecordsDeals = 0;
  totalRecordsParlons =0;

  ngOnInit() {

    // this.searchService.getPopularSearches().subscribe((data)=>{
    //   this.popularSearches = data.hits.splice(0,5);      
    // });

    this.$userSubscription= this.userService.getLoggedInUser.subscribe((data:any)=>{
      if(data){        
        if(data.data){
          this.isLoggedIn = true;
          this.userId = data.user.id;          
        }               
      }
    });

  }

  getLocation(location:any){    
    this.location = location;
    if(this.location!='')
    this.locationJSONParse = JSON.parse(this.location).location_name;
    else
    this.locationJSONParse = this.location;
    if(this.location !=""){
      this.search();
    }
  
  }


  getPopularSearch(word:string){
    this.isPageLoaded = true;              
    this.hideFirstLoadResult +=1;
    this.searchInput = word;
    this.TypesenseSearchService.search(this.searchInput).subscribe((data)=>{
      data['results'].forEach(res => {
        if(res['request_params']['collection_name'] == 'Deals') {
        this.nbPagesDeals = Math.ceil(res['found']/this.deal_config.itemsPerPage);
        this.totalRecordsDeals = res['found']; 
        this.deal_config.totalItems = res['found'];
        this.deal_config.currentPage = res['page'];
        this.currentPageDeals = res['page'];
        this.deals = res['hits'].map((item:any,index)=>{
          return{
          position:index+1,
          objectID:'',
          queryID:'',
          discountType:item['document'].discount_type,
          discountValue:item['document'].discount_value,
          deal_id: item['document'].id,
          deal_name:item['document'].name,
          deal_price:item['document'].deal_price, 
          original_price: item['document'].original_price, 
          merchant_name: item['document'].parlon, 
          merchant_count: item['document'].branches.length, 
          deal_img:item['document'].logo, 
          parlon_logo:item['document'].parlon_logo, 
          slug:item['document'].slug
          }
        })
        } else if(res['request_params']['collection_name'] == 'Parlons') {
          this.totalRecordsParlons =res['found']; 
          this.parlon_config.totalItems = res['found']; 
          this.parlon_config.currentPage = res['page'];
          this.parlons = res['hits'].map((item:any)=>({
            slug:item['document'].parlon_slug,
            services:item['document'].services.splice(0,3),
            branches:item['document'].branches,
            parlon_name:item['document'].parlon_name,
            parlon_logo:this.resource+item['document'].logo,
            queryID:'',
            objectID:''
          }));
        }
      });          
    })

  }

  getSearchInput(value:any){
    this.searchInput = value;    
    this.search();
    
  }

  search = ()=>{
    let loc:any;
    if(this.location !=''){
      
      loc = JSON.parse(this.location);
      loc =loc.location_name;
    }        
    else
      loc = "all";
    this.TypesenseSearchService.search(this.searchInput, loc).subscribe((data)=>{ 
      this.isPageLoaded = true;              
      this.hideFirstLoadResult +=1;
      data['results'].forEach(res => {
        if(res['request_params']['collection_name'] == 'Deals') {
        this.nbPagesDeals = Math.ceil(res['found']/this.deal_config.itemsPerPage);
        this.totalRecordsDeals = res['found']; 
        this.deal_config.totalItems = res['found'];
        this.deal_config.currentPage = res['page'];
        this.currentPageDeals = res['page'];
        this.deals = res['hits'].map((item:any,index)=>{
          return{
          position:index+1,
          objectID:'',
          queryID:'',
          discountType:item['document'].discount_type,
          discountValue:item['document'].discount_value,
          deal_id: item['document'].id,
          deal_name:item['document'].name,
          deal_price:item['document'].deal_price, 
          original_price: item['document'].original_price, 
          merchant_name: item['document'].parlon, 
          merchant_count: item['document'].branches.length, 
          deal_img:item['document'].logo, 
          parlon_logo:item['document'].parlon_logo, 
          slug:item['document'].slug
          }
        })
        } else if(res['request_params']['collection_name'] == 'Parlons') {
          this.totalRecordsParlons =res['found']; 
          this.parlon_config.totalItems = res['found']; 
          this.parlon_config.currentPage = res['page'];
          this.parlons = res['hits'].map((item:any)=>({
            slug:item['document'].parlon_slug,
            services:item['document'].services.splice(0,3),
            branches:item['document'].branches,
            parlon_name:item['document'].parlon_name,
            parlon_logo:this.resource+item['document'].logo,
            queryID:'',
            objectID:''
          }));
        }
      }); 
    })
  }

  pageChange(e,type) {
    if(type == 'deal') {
      this.deal_config.currentPage = e;
    } else {
      this.parlon_config.currentPage = e;
    }
    var tabs = document.getElementsByClassName("mat-tab-body-content");
    for (let i = 0; i < tabs.length; i++) {
      tabs.item(i).scrollTop = 0;
    }
  }


  goToTop = ()=>{
    var tabs = document.getElementsByClassName("mat-tab-body-content");
    for (let i = 0; i < tabs.length; i++) {
      tabs.item(i).scrollTop = 0;
    }
  }

  pageChangeEventDeals(event: number){         
    this.deal_config.currentPage= event;     
    this.TypesenseSearchService.search(this.searchInput,this.location,this.deal_config.currentPage).subscribe((data)=>{  
    data['results'].forEach(res => {
      if(res['request_params']['collection_name'] == 'Deals') {
        this.nbPagesDeals = Math.ceil(res['found']/this.deal_config.itemsPerPage);
        this.totalRecordsDeals = res['found']; 
        this.deal_config.totalItems = res['found'];
        this.deal_config.currentPage = res['page'];
        this.currentPageDeals = res['page'];
        this.deals = res['hits'].map((item:any,index)=>{
          return{
          position:index+1,
          objectID:'',
          queryID:'',
          discountType:item['document'].discount_type,
          discountValue:item['document'].discount_value,
          deal_id: item['document'].id,
          deal_name:item['document'].name,
          deal_price:item['document'].deal_price, 
          original_price: item['document'].original_price, 
          merchant_name: item['document'].parlon, 
          merchant_count: item['document'].branches.length, 
          deal_img:item['document'].logo, 
          parlon_logo:item['document'].parlon_logo, 
          slug:item['document'].slug
          }
        })
      }
    });
    this.goToTop();
  })
 }

 pageChangeEventParlons(event: number){         
  this.parlon_config.currentPage = event;
  this.TypesenseSearchService.search(this.searchInput,this.location,this.parlon_config.currentPage).subscribe((data)=>{      
    data['results'].forEach(res => {
      if(res['request_params']['collection_name'] == 'Parlons') {
        this.totalRecordsParlons =res['found']; 
        this.parlon_config.totalItems = res['found']; 
        this.parlon_config.currentPage = res['page'];
        this.parlons = res['hits'].map((item:any)=>({
          slug:item['document'].parlon_slug,
          services:item['document'].services.splice(0,3),
          branches:item['document'].branches,
          parlon_name:item['document'].parlon_name,
          parlon_logo:this.resource+item['document'].logo,
          queryID:'',
          objectID:''
        }));
      }
    });
    this.goToTop();
  })
}

replacePlusWithSpace(str) {
  return str.replace(/\+/g,' ');
}

getQueryParams(url) {
  const paramStr = url.slice(url.indexOf('?') + 1);
  if (!paramStr) return {};  
  const params = paramStr.split('&').reduce((acc, param) => {
    const [key, val] = param.split('=');
    acc[key] = decodeURIComponent(val);
    return acc;
  }, {});

  if (params['facetFilters']) {
    
    let facetFilters =  JSON.parse(params['facetFilters'].replace(/\//g, ""));
    facetFilters = this.replacePlusWithSpace(facetFilters[0]);    
    params['facetFilters'] = facetFilters;
  }  
  return params;
}

}
