import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../services/rest.service';
import { Title } from '@angular/platform-browser';

// declare var my: any;

@Component({
  selector: 'app-glife-landing-page',
  templateUrl: './glife-landing-page.component.html',
  styleUrls: ['./glife-landing-page.component.scss']
})
export class GlifeLandingPageComponent implements OnInit {

  isLoaded = false;
  parlonSlug: string;
  parlonGlife = [];
  selectedBranch = false;
  branchAddress = '';
  resourceLink = this.rest.resourceLink();
  branchSlug: string;

  branchForm: FormGroup;

  constructor(
    private rest: RestService,
    private route: ActivatedRoute,
    private _title: Title
  ) { }

  ngOnInit() {
    this.branchForm = new FormGroup({
      selectedBranch: new FormControl(null)
   });
  //  my.onMessage = function(e) {
  //    alert('message')
  //   console.log(e); //{'sendToWebView': '1'}
  // }
  //  my.postMessage({'sendToMiniProgram': '0'});
    this.getParlonGlife();
    localStorage.clear();
  }

  getParlonGlife() {
    let page = this;
    page.isLoaded = false;
    this.parlonSlug = this.route.snapshot.paramMap.get("slug")
    
    this.rest.getParlonGlife(this.parlonSlug).subscribe(data => {
      page.parlonGlife = data;
      this._title.setTitle(page.parlonGlife['business_name'] + " | Parlon");
      page.onChangeBranch(data['branches'][0]);
      page.branchForm.get("selectedBranch").patchValue(data['branches'][0]);
      page.isLoaded = true;
    })
  }

  onChangeBranch(value){
    this.selectedBranch = true;
    this.branchAddress = value.address;
    this.branchSlug = value.slug
  }
}
