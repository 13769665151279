import { Component, ElementRef, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../validators/must-match-validator';
import { mobileValidator } from '../../validators/mobile-validator';
import { dateValidator } from '../../validators/date-validator';
import { RegisterService } from '../../services/register/register.service';
import { MatDialog } from '@angular/material';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';
import { Router } from '@angular/router';
// import { HttpErrorHandlerService } from '../../services/register/http-error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserAccountService } from '../../services/user-account/user-account.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  constructor(
    private fb: FormBuilder, 
    private registerService: RegisterService,
    public dialog: MatDialog,
    private router: Router,
    private userService:UserAccountService) { }

  @ViewChild('password') inputPassword: ElementRef;
  @ViewChild('confirm_password') confirmPassword: ElementRef;
  @Output() registerEmitter = new EventEmitter();

  hideConfirmIcon:boolean = true;
  hidePasswordIcon:boolean = true;
  submittedForm:boolean = false;  
  existingEmail:string='';
  existingNumber:string='';

  $loginSub:Subscription|undefined

  notifier = new Subject()

  regForm:FormGroup = this.fb.group({
    // email:['angelito@concept-machine.net', [Validators.required, Validators.email, this.existingEmailValidator.bind(this)]],
    email:['', [Validators.required, Validators.email]],
    password:['', [Validators.required, Validators.minLength(8)]],
    confirm_password:['', Validators.required],
    user_agree:[false, Validators.requiredTrue],
    personal_info:this.fb.group({
      first_name:['', [Validators.required,Validators.minLength(3), Validators.maxLength(40)]],
      last_name:['', [Validators.required,Validators.minLength(2), Validators.maxLength(40)]],
      birthday:['',[Validators.required,dateValidator]]
    }),
    contact_info:this.fb.group({
      mobile_number:['', [Validators.required, mobileValidator, this.existingMobileValidator.bind(this)]],
      instagram:['']
    })
    },{
    validator: MustMatch('password', 'confirm_password')
  });

  get email() { return this.regForm.get('email'); }
  get password() {return this.regForm.get('password');}
  get confirm_password() {return this.regForm.get('confirm_password');}
  get p(){return this.regForm.get('personal_info')};
  get c(){return this.regForm.get('contact_info')}
  
  
  openDialog(error) {
    let json = {
      email:this.email.value,
      error
    }
    const dialogRef = this.dialog.open(ErrorDialogComponent,{width:'500px', data: json});
  }

  back(): void {
    history.back(); 
  }

  onSubmit(){
    this.submittedForm = true;
    if (this.regForm.invalid) {
      return;
    }

    let json = {
      first_name: this.p.get('first_name').value,
      last_name:  this.p.get('last_name').value,
      email: this.email.value,
      contact_number:  this.c.get('mobile_number').value,
      password:  this.password.value,
      password_confirmation: this.confirm_password.value,
      birthday:this.p.get('birthday').value == null  || this.p.get('birthday').value == '' ? null : this.p.get('birthday').value.toISOString().split('T')[0],
      instagram: this.c.get('instagram').value == '' ? null : this.c.get('instagram').value
    }

    this.registerService.register(json).pipe(takeUntil(this.notifier)).subscribe((data:any)=>{      
      this.submittedForm = false;      
      if(data.success == true){
        const now = new Date()
        const expiry =  now.setMinutes(now.getMinutes() - 5);
        localStorage.setItem('otp-expiration',JSON.stringify( expiry));
        sessionStorage.setItem("otpEmail", this.email.value);
        this.registerEmitter.emit(true);    
      }else{

        this.submittedForm = false;  
        let response = data.data;
        response =   Object.keys(response).map((item)=>{
          return response[item][0]
        });

        // response.forEach((item)=>{
        //   if(item=='The email has already been taken.'){
        //     this.existingEmail = this.email.value;
        //     this.regForm.controls['email'].setErrors({'existingEmail':true})
        //   }
        //   if(item=='The contact number has already been taken.'){
        //     this.existingNumber = this.c.get('mobile_number').value;
        //     this.regForm.controls['contact_info'].get('mobile_number').setErrors({'existingMobileNumber':true});
        //   }
        // })


        this.openDialog(data.data);       

      }      
    },(error:HttpErrorResponse) =>{    
      this.submittedForm = false;  
      let response = error.error.data;
      response =   Object.keys(response).map((item)=>{
        return response[item][0]
      });

      response.forEach((item)=>{
        if(item=='The email has already been taken.'){
          this.existingEmail = this.email.value;
          this.regForm.controls['email'].setErrors({'existingEmail':true})
        }
        if(item=='The contact number has already been taken.'){
          this.existingNumber = this.c.get('mobile_number').value;
          this.regForm.controls['contact_info'].get('mobile_number').setErrors({'existingMobileNumber':true});
        }
      })
      this.openDialog(error.error.data);       
      
    })
        
  }

  existingEmailValidator(control:FormControl){
    if(this.existingEmail==''){
      return null;
    }
    if(this.existingEmail == control.value){        
        return {'existingEmail':true}
    }
    return null;
  }

  existingMobileValidator(control:FormControl){
    if(this.existingNumber==''){
      return null
    }
    if(this.existingNumber==control.value){
      return {'existingMobileNumber':true}
    }
  }

  ngOnDestroy() {
    this.notifier.next()
    this.notifier.complete()

    if(this.$loginSub)
      this.$loginSub.unsubscribe();
      
  }

  ngOnInit() {
    
  }

}
