import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserVerificationComponent } from './user-verification.component';
import { OtpFormComponent } from './components/otp-form/otp-form.component';
import { OtpSuccessComponent } from './components/otp-success/otp-success.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material';
import { UserVerificationRouterModule } from "./user-verification-router.module";
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    UserVerificationRouterModule
  ],
  declarations: [UserVerificationComponent, OtpFormComponent, OtpSuccessComponent]
})
export class UserVerificationModule { }
