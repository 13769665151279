<div class="d-md-block d-none">
	<div class="flex-search-bar">
		<div class="area-select">
			<mat-form-field [floatLabel]="'never'" class="form-area-select">
				<span matPrefix><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
				<mat-select (selectionChange)="onSelectValueChange($event.value)" [(ngModel)]="locationSelected">
					<mat-option>
						<ngx-mat-select-search [formControl]="areaFilterCtrl" [placeholderLabel]="'Search'">
						</ngx-mat-select-search>
					</mat-option>
					<mat-option [value]="''">All Locations</mat-option>
					<mat-option [value]="toStr(area)" *ngFor="let area of filteredAreas | async">{{ area.location_name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="search-field">
			<div class="search-input">
				<mat-form-field [floatLabel]="'never'" class="form-search-input w-100">
					<!-- (ngModelChange)="changeModel()" -->
					<input type="search" id="search-input-field"
						matInput
						placeholder="Search for anything"
						[(ngModel)]="inputSearch"
						[matAutocomplete]="auto"
						[formControl]="myControl"
						(keyup.enter)="searchSubmit()" *ngIf="hideDropDown==false"/>
					
					<input type="search" id="search-input-field"
					matInput
					placeholder="Search for anything"
					[(ngModel)]="inputSearch"					
					[formControl]="myControl"
					(keyup.enter)="searchSubmit()"  *ngIf="hideDropDown == true"/>	
					<!-- <span style="background-color: teal; " matSuffix (click)="searchSubmit()"><i style="color: white; padding:0 0.6em;" class="far fa-search fa-fw"></i></span> -->

					<!-- Auto-complete dropdown -->
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectOption($event)">
						<div *ngIf="hideDropDown==false">
							<div class="d-grid gap-5">
								<div class="px-4 py-4 d-none" *ngIf="inputSearch==''">
									<p class="fs-6 fw-semibold">Popular Searches</p>
		
									<div class="flex-suggestion-chips">
										<mat-option [routerLink]="['/search','all',option.query]" class="suggestion-chip"
											*ngFor="let option of popularSearches"
											[value]="option.query">
											{{ option.query }}
										</mat-option>
									</div>
								</div>
		
								<div class="mt-n4 pb-4 py-4" *ngIf="inputSearch=='' && recentSearches.length > 0">
									<div class="px-4">
										<p class="fs-6 fw-semibold mb-2">Recent Searches</p>
									</div>
		
									<mat-option class="suggestion-opt search-query"
										*ngFor="let option of recentSearches"
										[value]="option">
										<span class="text-muted"><i class="far fa-clock-rotate-left fa-fw me-2"></i>{{ option }}</span>
									</mat-option>
								</div>
							</div>

							<div class="py-4" *ngIf="inputSearch!= ''">
								<div class="d-flex flex-column gap-5">
									<div *ngIf="inputSearch != '' && deals.length > 0">
										<div class="px-4">
											<p class="fs-6 fw-semibold mb-2">Deals</p>
										</div>
		
										<!-- Deals -->
										<mat-option (click)="clickedObjectIDs(deal)" class="suggestion-opt deal-card" 
											[value]="deal.slug"
											*ngFor="let deal of deals">
		
											<div class="row flex-suggestion-deal gx-4">
												<div class="col-img">
													<div class="ratio ratio-16x9 ratio-deal-img">
														<img src="{{ deal.deal_img }}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
		
														<div class="deal-savings-banner"
															*ngIf="deal.discountType == 'Value Discount'">
															<span>Save PHP {{ deal.discountValue | number }}</span>
														</div>
		
														<div class="deal-savings-banner"
															*ngIf="deal.discountType == 'Percentage Discount'">
															<span>{{ deal.discountValue | number }}% OFF</span>
														</div>
													</div>
												</div>
		
												<div class="col-content">
													<p class="fs-6 fw-medium text-deal-name clamp-1 mb-1">{{ deal.deal_name }}</p>
													<p class="text-deal-price mb-2">
														<span class="fw-semibold me-2">₱{{ deal.deal_price | number }}</span>
														<span class="smaller text-muted text-decor-line-through">₱{{ deal.original_price | number }}</span>
													</p>
		
													<p class="smaller text-deal-parlon mb-0">
														<span class="fw-medium">{{ deal.merchant_name }}</span><br />
														<span class="text-muted">{{ deal.merchant_count }} branches</span>
													</p>
												</div>
											</div>
										</mat-option>
									</div>
		
									<!-- Parlons -->
									<div *ngIf="inputSearch != '' && parlons.length > 0" class="pt-2">
										<div class="px-4">
											<p class="fs-6 fw-semibold mb-2">Parlons</p>
										</div>

										<mat-option [routerLink]="['/parlon', parlon.slug]"
										 [value]="parlon.slug" class="suggestion-opt parlon-card"
											*ngFor="let parlon of parlons">

											<div class="row flex-suggestion-parlon gx-5">
												<div class="col-img">
													<div class="ratio ratio-4x3">
														<img src="{{ parlon.parlon_logo }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
													</div>
												</div>

												<div class="col-content align-self-center">
													<p class="fs-6 fw-medium mb-1">{{ parlon.parlon_name }}</p>
													<p class="mb-0">{{ parlon.branch_count }} branches</p>
												</div>
											</div>
										</mat-option>
		
									</div>
								</div>

								<div class="d-grid text-center border-top mb-n4" *ngIf="countDeals.length > 5 || countParlons.length > 5">
									<a (click)="searchSubmit()" mat-button class="text-secondary text-uppercase py-2">See full results</a>
								</div>
							</div>
						</div>	
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
		
		<button mat-button class="btn-search-icon" [disabled]="locationSelected =='' && inputSearch== '' "
			(click)="searchSubmit()" *ngIf="isShowButton">
			<i class="far fa-magnifying-glass fa-fw text-white"></i>
		</button>
	</div>
</div>


<!-- for mobile -->


<div class="flex-search-bar d-sm-none d-block">
	<div class="area-select">
		<mat-form-field [floatLabel]="'never'" class="form-area-select">
			<span matPrefix><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
			<mat-select (selectionChange)="onSelectValueChange($event.value)" [(ngModel)]="locationSelected">
				<mat-option>
					<ngx-mat-select-search [formControl]="areaFilterCtrl" [placeholderLabel]="'Search'"></ngx-mat-select-search>
				</mat-option>
				<mat-option [value]="''">All</mat-option>
				<mat-option [value]="toStr(area)" *ngFor="let area of filteredAreas | async">{{area.location_name}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="search-field">
		<div class="search-input">
			<mat-form-field [floatLabel]="'never'" class="form-search-input w-100">
				<input id="search-input-field" matInput placeholder="Search for anything" 
					(keyup.enter)="searchSubmit()" (focus)="redirectIfMobile()" type="search"  [formControl]="myControl" />
				<!-- <span style="background-color: teal; " matSuffix (click)="searchSubmit()"><i style="color: white; padding:0 0.6em;" class="far fa-search fa-fw"></i></span> -->
				<!-- <mat-autocomplete #sample="matAutocomplete" (optionSelected)="selectOption($event)">
					<div *ngIf="inputSearch=='' ">
						<label> Popular Searchess </label>
						<div class="suggestionContainer d-flex flex-wrap justify-content-start">
							<mat-option class="suggestion" *ngFor="let option of popularSearches" [value]="option.query">
								{{ option.query }}
							</mat-option>
						</div>
					</div>
					<div *ngIf="inputSearch=='' && recentSearches.length>0">
						<label>
							Recent Searches
						</label>
						<mat-option *ngFor="let option of recentSearches" [value]="option">
							<mat-icon>query_builder</mat-icon>
							{{ option }}
						</mat-option>
					</div>

					<div *ngIf="inputSearch!= '' ">

						<mat-option [value]="parlon.parlon_slug" *ngFor="let parlon of parlons" [routerLink]="['/parlon', parlon.parlon_slug]"
							style="padding: 1em; margin-top:1em; height:auto; ">
							<div class="d-flex">
								<img src="{{ resource + parlon.logo }}" width="66px" height="68px">
								<div class="ms-2">
									<p style="font-size: 16px; font-weight:bold; line-height: 15px;">{{parlon.parlon_name}}</p>
									<p style="line-height: 5px;">{{parlon.branch_count}} branches</p>
								</div>
							</div>
						</mat-option>

					</div>

				</mat-autocomplete> -->
			</mat-form-field>
		</div>
	</div>
</div>