import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAccountService } from '../services/user-account/user-account.service';

@Injectable({
  providedIn: 'root'
})
export class Authv2Guard implements CanActivate {

  constructor(private userService:UserAccountService, private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var isAuthenticated = this.userService.isLogged();
      if (!isAuthenticated) {
          this.router.navigate(['/']);
      }
      return isAuthenticated;
  }


}
