import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

let authData = '';
let httpOptions = {};


@Injectable({
  providedIn: 'root'
})
export class TypesenseDealSearchService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(platformId)) {
      authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
    } else {
      authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
    }
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authData
      })
    }
  }

   configUrl=environment.typesense_url+"collections/Deals/documents/search?";



   search(config, searchInput = "*",sortOrder = null, selectedParlons:any = "", selectedCategory:any = "", selectedSubCategories:any = "", selectedLocations:any = "", geoPoint={lat:'', lng:''}, scroll = false):Observable<any>{
    let query_string = ''
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-TYPESENSE-API-KEY':environment.typesense_key
    });
    const requestOptions = { headers: headers };

    if(searchInput == '' || searchInput == null) {
      searchInput = "*";
    }
    query_string = 'q='+searchInput+'&facet_by=categories.category_name,parlon,categories.subcategories,locations&max_facet_values=100000&per_page='+config.itemsPerPage+'&query_by=name,categories.category_name,categories.subcategories,parlon';
    if(sortOrder != null) {
      if(sortOrder.value == 'relevance') {
        query_string = query_string + "&sort_by=sales:desc";
      }
      if(sortOrder.value == 'price') {
        query_string = query_string + "&sort_by=deal_price:"+sortOrder.sequence;
      }
      if(sortOrder.value == 'savings') {
        query_string = query_string + "&sort_by=saved_amount:"+sortOrder.sequence;
      }
      if(sortOrder.value == 'location' && (geoPoint.lat != '' && geoPoint.lng != '')) {
        query_string = query_string + "&sort_by=geopoint("+geoPoint.lat+","+geoPoint.lng+"):asc";
      }
    } else {
      query_string = query_string + "&sort_by=sales:desc";
    }
    if(selectedParlons.length > 0 || selectedCategory != '' || selectedSubCategories != '' || selectedLocations != '') {
      query_string = query_string + "&filter_by=";
      if(selectedParlons.length > 0)
        query_string = query_string + "parlon:=["+encodeURIComponent(selectedParlons)+"]";
      if(selectedLocations.length > 0) {
        if(selectedParlons.length > 0)
        query_string = query_string + "&&";
        query_string = query_string + "locations:=["+encodeURIComponent(selectedLocations)+"]";
      }
      if(selectedCategory != '')  {
        if(selectedParlons.length > 0 || selectedLocations.length > 0)
          query_string = query_string + "&&";
        query_string = query_string + "category:=["+encodeURIComponent(selectedCategory)+"]";
      }
        
      if(selectedSubCategories != '') {
        if(selectedParlons.length > 0 || selectedLocations.length > 0 || selectedCategory.length > 0)
          query_string = query_string + "&&";
        query_string = query_string + "categories.subcategories:=["+encodeURIComponent(selectedSubCategories)+"]";
      }
    }
    
    if(scroll == true)
      query_string = query_string + '&page='+config.currentPage;
    return this.http.get(this.configUrl+ query_string, requestOptions);
    
  }

  dealSearchSuggestions(value){
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-TYPESENSE-API-KEY':environment.typesense_key
    });
    const requestOptions = { headers: headers };
    return this.http.get(environment.typesense_url+ 'collections/DealSuggestions/documents/search?q='+value+'&query_by=q', requestOptions);
  }


}
