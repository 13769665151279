<div class="container h-100 py-9" >
	<div class="row justify-content-center">
		<div class="col-12 col-md-8 col-lg-7 col-xl-6">
			<div class="text-center">
				<img src="assets/images/user-registration-success.svg" alt="" class="img-fluid illus-registration-success">

				<h1 class="h1 fw-semibold mb-6">Your registration is successful, {{$firstName}}!</h1>

				<p class="mb-6" *ngIf="!$existing && hideSuccessMessage">A <b>PHP 250</b> code is on its way to your email... plus we have some welcome Parlon candies for you that you can use soon (stay tuned!)</p>
				<p class="mb-6" *ngIf="$existing && hideSuccessMessage"> Lovely to see you again, {{$firstName}}! It seems that you have already used Parlon. You will see your previous bookings and/or E-vouchers. A promo code is on its way to your email… plus we have surprise Parlon candies for you that you can use soon (stay tuned!).</p>
				<div class="d-grid grid-cta-btn gap-4">
					
					<button *ngIf="contentData['deals_&_beauty_bag']" routerLink="/all-parlon-deals" mat-flat-button color="secondary" class="btn-cta">
						Take me to the Parlon Deals
					</button>
					<button *ngIf="contentData['deals_&_beauty_bag'] == false" routerLink="/" mat-flat-button color="secondary" class="btn-cta">
						SEE ALL PARLONS
					</button>


					<button routerLink="/"mat-stroked-button color="secondary" class="btn-cta">
						Back to home
					</button>
				</div>
			</div>
		</div>
	</div>
</div>