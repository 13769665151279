import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../../services/rest.service';
import { DealsService } from '../../../../services/deals/deals.service';

declare var My2c2p: any;

@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {

  paymentLinkForm: FormGroup;
  paymentDetailsFormGroup: FormGroup;
  loading = false;
  submitted = false;
  @Input() details: any = [];  
  cc: object;
  form_data: object;
  isTransactionFailed = false;


  amount:number=0;
  channels:any = [];


  constructor(
    private rest: RestService,
    private el: ElementRef,
    private DealService: DealsService
  ) { 
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
    this.form_data = {
      amount: '',
      email: '',
      payment_link_code: '',
      payment_channel_code: '',
      securePayToken: ''
    };
   }


  
  checkIfPaymentChannelExist(){
    this.channels =this.details.payment_channels.map((item)=>{
      return item.channel_code
    })
  }

  ngOnInit() {
    this.checkIfPaymentChannelExist();
    this.paymentLinkForm = new FormGroup({
      'paymentDetailsFormGroup': new FormGroup({
        'amount': new FormControl(null, [
          Validators.required,
          Validators.pattern("^[0-9]*$")]
        ),
        'email': new FormControl(null),
      }),
      'paymentFormGroup': new FormGroup({
        'creditCard': new FormControl(null, Validators.required),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
        'paymentChannel': new FormControl(null, Validators.required),
        'eVoucherPolicy': new FormControl(false,Validators.requiredTrue),
      })
    });
  }

  // convenience getter for easy access to form fields
  // get f() { return this.paymentLinkForm.get('paymentDetailsFormGroup') }

  onSubmit(){
    this.submitted = true
    let app = this;

    if(app.paymentLinkForm.value.paymentDetailsFormGroup.amount == null) {
      Object.keys(app.paymentLinkForm.controls.paymentDetailsFormGroup['controls']).forEach(function(key) {
        app.paymentLinkForm.controls.paymentDetailsFormGroup['controls'][key].markAsTouched();
      });
      if(app.paymentLinkForm.controls.paymentDetailsFormGroup.status == "INVALID") {
        app.scrollToFirstInvalidControl();
        return false;
      }
    }

    if(app.paymentLinkForm.value.paymentFormGroup.paymentChannel == 'parlon_credit_card') {

      if(app.paymentLinkForm.controls.paymentFormGroup.status == null) {
        app.scrollToFirstInvalidControl();
        return false;
      }
     
      app.cc['cardnumber'] = app.paymentLinkForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      app.cc['cvv'] = app.paymentLinkForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      app.cc['month'] = app.paymentLinkForm.value.paymentFormGroup.ccMonth;
      app.cc['year'] = app.paymentLinkForm.value.paymentFormGroup.ccYear.toString();
    }

    setTimeout(() => {
      app.form_data['amount'] = app.paymentLinkForm.value.paymentDetailsFormGroup.amount;
      app.form_data['email'] = app.paymentLinkForm.value.paymentDetailsFormGroup.email;
      app.form_data['payment_link_code'] = app.details['payment_link_code'];
      app.form_data['payment_channel_code'] = app.paymentLinkForm.value.paymentFormGroup.paymentChannel;
      My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {
        app.form_data['securePayToken'] = encryptedData.encryptedCardInfo;
      });
      if(app.paymentLinkForm.value.paymentFormGroup.eVoucherPolicy == false) {
        return false;
      }

      if(app.form_data['payment_channel_code'] == 'parlon_maya') {
        let maya_fields = {
          card: {
            number:app.paymentLinkForm.value.paymentFormGroup.creditCard.replace(/\s/g, ''),
            expMonth:app.paymentLinkForm.value.paymentFormGroup.ccMonth,
            expYear:app.paymentLinkForm.value.paymentFormGroup.ccYear.toString(),
            cvc:app.paymentLinkForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '')
          }
        };
        this.loading = true;
        app.DealService.getMayaToken(maya_fields).subscribe((response) => {
            app.form_data['securePayToken'] = response['paymentTokenId'];
            app.requestPayment();
        });
        return;
      }
      app.requestPayment();
    }, 200);
  }

  requestPayment() {
    let app = this;
    app.loading = true;
    app.rest.paymentRequest(app.form_data).subscribe((data: {}) => {
      app.loading = false;
      if(data['status'] == true && data['isRedirect'] == true){
        window.location.href = data['response']['data'];
      } else {
        app.isTransactionFailed = true;
      }
    });
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid"
    );
    firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }
}
