import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numNotRound'
})
export class NumNotRoundPipe implements PipeTransform {

  transform(value: number): number {
    var num1 = Math.trunc(value);
    return num1;
  }

}
