<div class="content-list">
	<ng-container *ngFor="let list of data">
		<div class="content-panel" *ngIf="list['salons'].length > 0">
			<div class="panel-header">
				<div class="d-flex align-items-center gap-5">
					<div class="col-auto">
						<h2 id="list-{{list.letter}}" class="h2 text-header-title">{{list.letter}}</h2>
					</div>

					<div class="col">
						<hr class="panel-header-divider">
					</div>
				</div>
			</div>

			<div class="panel-body">
				<div class="row row-cols-1 gy-4">

					<div class="col" *ngFor="let salon of list['salons']">
						<div class="row flex-salon-list-item">
							<div class="parlon-name">
								<div>
									<span class="text"><a [routerLink]="['/parlon',salon['slug']]" class="link-static">{{salon['business_name']}}</a></span>
									<span class="d-flex">
										<span class="icon" *ngIf="salon['branches_with_booking'] > 0"><i class="fak fa-booking fa-fw"></i></span>
										<span class="icon" *ngIf="salon['active_deal_count'] > 0"><i class="fak fa-deals fa-fw"></i></span>
									</span>
								</div>
							</div>

							<div class="parlon-branch">
								<p><span *ngFor="let location of salon['distinct_locations']; let i = index">{{location['location_name']}}<ng-container *ngIf="i != salon['distinct_locations'].length - 1">, </ng-container> </span></p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</ng-container>
</div>