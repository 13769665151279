import { Component, OnInit, Input, ElementRef, Output, EventEmitter, OnDestroy, HostListener, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingService } from '../../../../services/booking/booking.service';
import { UserAccountService } from '../../../../services/user-account/user-account.service';
import { ContentService } from '../../../../services/content/content.service';
import { SharedService } from '../../../../services/shared.service';
import * as Rx from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { GoogleReviewService } from '../../../../services/google-review/google-review.service';
@Component({
  selector: 'global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss']
})
export class GlobalNavComponent implements OnInit, OnDestroy {

  @Input() data:any;
  @Input() cartCount:any;
  @Input() categories:any;
  @Input() parlon_inner:boolean = false;
  @Output() searchEmit = new EventEmitter();

  isLoggedIn:boolean =false;
  mobileLinksHidden:boolean =false;
  initials:string="";
  parlon: any = [];
  areas: any = [];
  branch: any = [];
  branchByAreas = [];
  contact: any = [];
  features: any = [];
  active_mega_menu = null;

  signinActive:boolean =false;

  ogMember:boolean = false;
  navEmpty = environment.emptyNav;

  $userSub:Subscription|undefined;
  $loggedInSub:Subscription|undefined;
  /** list of areas filtered by search keyword */
  public filteredBranches: Rx.ReplaySubject<{}[]> = new Rx.ReplaySubject<{}[]>(1);
  public branchFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Rx.Subject<void>();


  selectedCountry:any = "Philippines";

  isHome:boolean = false;

  showGoogleReviewTab = false;

  constructor(
    @Inject(WINDOW) private window: Window, 
    private el: ElementRef,
    private router:Router,
    private userService:UserAccountService,
    private bookingService:BookingService,
    private route: ActivatedRoute, 
    private _shared: SharedService,
    private ContentService: ContentService,
    private googleReviewService:GoogleReviewService
  ) { }

  ngOnDestroy(): void {
    if(this.$userSub)
      this.$userSub.unsubscribe()
    if(this.$loggedInSub)
      this.$loggedInSub.unsubscribe()  
  }

  selectCountry(event:any){
    if(this.selectedCountry=='Philippines')
      this.window.location.href ="https://www.parlon.ph/";
    else
      this.window.location.href = "https://www.parlon.sg/";  
    
  }

  ngOnInit() {
    this.selectedCountry = environment.country;
    
    this.router.events.subscribe(event => {
      this.isHomePage();            
    });

    this.isHomePage();

    this.googleReviewService.getActiveBranch.subscribe((data)=>{      
      if(data){
        this.googleReviewService.getGoogleReviews(data).subscribe((data:any)=>{
          if(data.reviews.data){          
            this.showGoogleReviewTab = true
          }else{                
            this.showGoogleReviewTab = false
          }   
        })
      }    
    })

    this.$userSub =this.userService.getLoggedInUser.subscribe(data=>{
      this.isLoggedIn = data ? true : false;   
      if(this.isLoggedIn){
        this.$loggedInSub =this.userService.getLoggedInUser.subscribe((data:any)=>{
          if(data){
            this.initials = data.user.first_name.charAt(0) + data.user.last_name.charAt(0);
            this.ogMember = data.og.member;
          } else {
            this.ogMember = false;
          }
        });
      }  else {
        this.ogMember = false;
      }
    })
    if(window.innerWidth <= 768 && this.router.url == '/all-parlon-deals') {
      this.mobileLinksHidden = true;
    }
    
    // Parlon inner 
    Rx.combineLatest([
      this._shared.currentParlon.pipe(map(parlon => parlon as any)),
      this._shared.currentAreas,
      this._shared.currentBranch
    ]).subscribe(([parlon,areas,branch]) => {
      this.areas = areas;
      this.parlon = parlon;
      this.branchByAreas = this.groupBranchByAreas(areas, parlon.branches);

      this.branchByAreas = this.branchByAreas.sort((a, b) => a.location_name.localeCompare(b.location_name));
      this.filteredBranches.next(this.branchByAreas);
      this.branch = branch;
      if(branch.length > 0) {
        this.contact = branch['contact_numbers'].split(";");
      }
    });
     // listen for search field value changes
     this.branchFilterCtrl.valueChanges
     .pipe(takeUntil(this._onDestroy))
     .subscribe(() => {
       this.filterAreas();
   });
   this._shared.currentBranch.subscribe((branch) => {
    this.branch = branch;
    if(branch.length > 0) {
      this.contact = branch['contact_numbers'].split(";");
    }
    });
    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
    });

    

  }

  isHomePage() {
    
    let home =  false;
    if(this.router.url == '/') {
      home = true;
    }

    this.isHome = home;

    // return home;
  }

  showSearch() {
    this.searchEmit.emit(null);
  }

  toggleActive(e,className) {
    const el = this.el.nativeElement;
    const element =  el.querySelector('#'+e.target.id);
    element.classList.toggle(className);
  }

  updateCandies(){
    this.bookingService.getBookings().subscribe(data=>{                  
      this.userService.refreshCandies(data.candies);
      const currentHour = parseInt(moment().format("HH"));
      if (currentHour >= 0 && currentHour < 12){
        this.userService.setTime('morning');
          // return "morning";
      } else if (currentHour >= 12 && currentHour < 17){
          // return "afternoon";
          this.userService.setTime('afternoon');
      }   else if (currentHour >= 17 && currentHour < 24){
          // return "evening";
          this.userService.setTime("evening")
      }

    })            
  }

  onChangeBranch(event,value){
    // if (event.isUserInput) { // ignore on deselection of the previous option
    //   this.router.navigate(['/parlon/',this.parlon.slug,value]);
    // }
    this._shared.changeSelectedAreaID(value);
    this.parlon.branches.forEach(e => {
      if(e.id === value) {
        this.router.navigate(['/parlon/',this.parlon.slug,e.slug]);
      }
    });
  }

  private groupBranchByAreas(areas, branches) {
    return areas.map(area => ({
      ...area,
      branches: branches.filter(branch => branch.location.id == area.id),
    }));
  }

  protected filterAreas() {
    // get the search keyword
    let search = this.branchFilterCtrl.value;

    if (!this.parlon.branches) {
      return;
    }
    
    if (!search) {
      this.filteredBranches.next(this.branchByAreas);
      return;
    } else {
      search = search.toLowerCase().trim();
    }

    // filter the areas and branches
    this.filteredBranches.next(
      this.branchByAreas
        .map(area => {
          const showBranchesGroup = area.location_name.toLowerCase().indexOf(search) > -1;
          let filteredBranches = area.branches;

          if (!showBranchesGroup) {
            filteredBranches = filteredBranches.filter(
              branch => branch.branch_name.toLowerCase().indexOf(search) > -1,
            );
          }

          return {
            ...area,
            branches: filteredBranches,
          };
        })
        .filter(area => !!area.branches.length),
    );
  }
  
  selectCategory(category) {
    if(category == this.active_mega_menu) {
      this.active_mega_menu = null;
    } else {
      this.active_mega_menu = category;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {

    // Parlon partner mobile links
    const tab = document.querySelector('.parlon-tabs');
    const setVisible = "visible";
    const tabCheckpoint = document.getElementById('tab-checkpoint');
    if (tabCheckpoint != null) {
      const elementOffsetTop = tabCheckpoint.offsetTop;
      if (elementOffsetTop <= this.window.pageYOffset) {
        tab.classList.add(setVisible);
      } else {
        tab.classList.remove(setVisible);
      }
    }
  }

}
