import { Component, Input, OnInit } from '@angular/core';
import { AlgoliaInsightService } from '../../services/algolia-insight/algolia-insight.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'search-result-deal-card-template',
  templateUrl: './search-result-deal-card-template.component.html',
  styleUrls: ['./search-result-deal-card-template.component.scss']
})
export class SearchResultDealCardTemplateComponent implements OnInit {

  constructor(
  private algoliaInsightService:AlgoliaInsightService,
  private router:Router) { }

  resource = environment.resource;
  @Input() deal:any;
  @Input() isLoggedIn;  
  @Input() userID;
  @Input() position;  
  insightType=""; 
  ngOnInit() {
    // console.log(this.deal);

  }

  // todo
  // searchInput
 

  clickedObjectIDs(){

    let {objectID,queryID} = this.deal;    
    let indexName = environment.deals;    
    

    const urlWithParams = '/beauty-bag-deals/' + this.deal['slug'];
    if(this.insightType!="")
    this.router.navigate([urlWithParams], { queryParams: { 'insight-type': this.insightType } });
    else
    this.router.navigate([urlWithParams]);
  } 
}
