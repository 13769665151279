<div class="py-6 py-md-7">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-11 col-md-8 col-xxl-7 text-center">
				<img src="assets/images/404_illustration.png" alt="" class="img-fluid d-block mx-auto mb-5">

				<h1 class="h1 text-primary fw-bold">Oops...</h1>
				<h3 class="h3 mb-8">Page not found</h3>

				<button mat-raised-button color="primary" routerLink="/">Back to Home</button>
			</div>
		</div>
	</div>
</div>