import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../services/rest.service';
import { ContentService } from '../../../../services/content/content.service';

@Component({
  selector: 'nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit {

  dealsVisible: boolean;
  features: any = [];

  @Input() googleReviewVisible:boolean = false;

  constructor(
    public router: Router, 
    private route: ActivatedRoute,
    private rest: RestService, 
    private ngZone: NgZone,
    private ContentService:ContentService
  ) { }

  ngOnInit() {
    this.getVisiblePageSections();
    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
    });
  }

  isActive(location) {
    let active = (this.router.url.includes(location));
    if(active) {
      return 'active';
    }
  }

  navigate(value)  {
    this.router.navigate([this.router.url.substr(0, this.router.url.lastIndexOf('/')), value]);
  }

  getVisiblePageSections() {
    this.rest.homepageSectionVisibility().subscribe(data => {
      this.ngZone.run(() => {
        const sections = data['data'];

        this.dealsVisible = sections.find(
          ({ section }) => section === 'Deals Tab'
        ).visible === 0;
      });
    })
  }
}
