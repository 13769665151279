import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DealFilterMobileServiceService } from '../../services/deal-filter-mobile-service/deal-filter-mobile-service.service';
import { environment } from '../../../environments/environment';
import { ContentService } from '../../services/content/content.service';


@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private ContentService: ContentService,
    private dealFilterService:DealFilterMobileServiceService
    ) { }

  selectedRoute:string= 'default';
  features: any = [];
  routeName = "";
  
  visible:boolean = false;

  showNav=true;  


  hidden = [
    {
      'route': '/beauty-bag-checkout',
      'type': 'full'
    },
    {
      'route': '/beauty-bag-deals',
      'type': 'dynamic'
    },
    {
      'route': '/gifting',
      'type': 'dynamic'
    },
    {
      'route': '/promo-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/register',
      'type': 'dynamic'
    },
    {
      'route': '/parlon/',
      'type': 'dynamic'
    },
    {
      'route': '/glife',
      'type': 'dynamic'
    },
    {
      'route': '/payment-purchase',
      'type': 'dynamic'
    },
    {
      'route': '/payments/',
      'type': 'dynamic'
    },
    {
      'route': '/booking-status/',
      'type': 'dynamic'
    },
    {
      'route': '/beauty-bash',
      'type': 'dynamic'
    },
    {
      'route': '/boooking-payment',
      'type': 'dynamic'
    }
  ];

  teaser = environment.teaser;

  goToDeals(){
    this.dealFilterService.updateReloadSubject(2);
    this.router.navigate(['/all-parlon-deals']);
  }



  ngOnInit() {
    
    if(this.teaser == true) {
      this.hidden.push({
        'route': '/',
        'type': 'full'
      })
    }

    let initialLoadUrl = this.router.url;
    if(initialLoadUrl.startsWith('/search/')){
      this.selectedRoute = 'search';
      
    }else if (initialLoadUrl.startsWith('/mobile/')) {
      this.selectedRoute = 'mobile';
      ;
    }
    else{
      this.selectedRoute='default';
      
    }
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (url.startsWith('/search/')) {
          this.selectedRoute = 'search';
          ;
        }else if (url.startsWith('/mobile/')) {
          this.selectedRoute = 'mobile';
          ;
        }
        else{
          this.selectedRoute='default';        
        }
        this.visible = this.checkRoutesHidden();
      }else{
        this.visible = this.checkRoutesHidden();
      }
      
    });

    this.checkRoutesHidden();

    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
      this.visible = this.checkRoutesHidden();
    });
  }

  checkRoutesHidden() {
    let visible = true;
    this.routeName = this.router.url;
    if(this.features['mobile_nav'] == true ){      
      this.showNav = true;
    }
    if(this.features['mobile_nav'] == false){
      if(this.routeName == "/"){
        this.showNav = false;
      }else{
        this.showNav = true;
      }
    }    
    this.hidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          visible = false;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          visible = false;
        }
      } 
    });
    return visible;
  }

  checkActive(value){
    if(this.router.url == value) 
      return true;
    return false;
  }

}
