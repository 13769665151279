import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GoogleReviewService {

  constructor(private http:HttpClient) { }

  private activeBranch = new BehaviorSubject<any>(null);  
  getActiveBranch = this.activeBranch.asObservable();


  getGoogleReviews(branchId:string){
    return this.http.get(environment.api + `google/${branchId}/reviews`);
  }

  setActiveBranch(branch:any){
    this.activeBranch.next(branch);    
  }


}
