<div class="row flex-account gx-0 gy-6">
	<div class="col-12">
		<h3 class="h3 fw-semibold mb-0">Beautiful {{greetMessage}}, {{first_name}}!</h3>
		<p class="mb-1">{{email}}</p>
		<p class="mb-6">You have a total of <span class="fw-semibold">{{parlonCandies | numNotRound | number: '1.0-0'}}</span> Parlon candies and <span class="fw-semibold">{{brandedCandies | numNotRound | number: '1.0-0'}}</span> branded candies for various merchants.</p>

		<div class="account-quick-links-container w-100">
			<div class="row row-cols-3 justify-content-start gx-5">
				<div class="col">
					<button routerLink="/my-bookings" mat-button class="btn-quick-link" data-bs-dismiss="offcanvas">
						<div>
							<i class="fak fa-booking fa-lg fa-fw"></i>
							<span>My Bookings</span>
						</div>
					</button>
				</div>

				<div class="col">
					<button routerLink="/my-vouchers" mat-button class="btn-quick-link" data-bs-dismiss="offcanvas">
						<div>
							<i class="fak fa-deals fa-lg fa-fw"></i>
							<span>My Vouchers</span>
						</div>
					</button>
				</div>

				<div class="col" >
					<button routerLink="/candies/my-candies" mat-button class="btn-quick-link">
						<div>
							<img src="https://parlon.s3.ap-southeast-1.amazonaws.com/Candies/candy_icon_drawer.svg" width="40">
							<span>My Candies</span>
						</div>
					</button>
				</div>

				<!-- <div class="col" *ngIf="user_data['og']['member'] == true">
					<button routerLink="/og-card" mat-button class="btn-quick-link">
						<div>
							<img src="assets/images/og_logo.svg" width="40">
							<span>My OG Card</span>
						</div>
					</button>
				</div> -->
			</div>
		</div>
	</div>

	<div class="col-12 col-hr">
		<hr class="o-50 my-0">
	</div>

	<div class="col-12" *ngIf="features['original_glowster'] == true">
		<h3 class="h3 fw-semibold mb-4">Original Glowster</h3>
		<p class="mb-5" *ngIf="user_data['og']['member'] == false">Enjoy glowing perks from over 1,000 Parlon lifestyle partners nationwide.</p>
		<p *ngIf="user_data['og']['member']" class="mb-5 highlight px-4 rounded">You are a Parlon OG until <strong class="text-secondary">{{ user_data['og']['memberUntil'] | date : "MMMM d, y" }}</strong>.</p>

		<div class="row row-cols-2 gx-4" *ngIf="user_data['og']['member'] == false">
			<div class="col">
				<div [routerLink]="['/be-an-og-member']" class="ratio ratio-16x9 ratio-og-card">
					<img src="{{resource + ogContent['nav_drawer_how_to_be_a_parlon_og_image']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
				</div>
			</div>

			<div class="col">
				<div [routerLink]="['/og-perks']"  class="ratio ratio-16x9 ratio-og-card">
					<img src="{{resource + ogContent['nav_drawer_og_perks_image']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
				</div>
			</div>
		</div>

		<div class="row" *ngIf="user_data['og']['member']">
			<div class="col">
				<div [routerLink]="['/og-perks']"  class="ratio ratio-21x9 ratio-og-card">
					<img src="{{resource + ogContent['nav_drawer_og_perks_image']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
				</div>
			</div>
		</div>
	</div>


	<div class="col-12 col-hr">
		<hr class="o-50 my-0">
	</div>

	<!-- Additional links -->
	<div class="col-12">
		<ul class="nav nav-account-links flex-column">
			<!-- <li class="nav-item">
				<a href="#" class="nav-link link-text fw-medium">Account settings</a>
			</li> -->

			<li class="nav-item">
				<a href="https://legal.parlon.ph/post/terms-of-use" class="nav-link link-text fw-medium">Terms of Use</a>
			</li>

			<li class="nav-item">
				<a href="https://legal.parlon.ph/post/privacy-notice" class="nav-link link-text fw-medium">Privacy Notice</a>
			</li>

			<li class="nav-item">
				<a style="cursor:pointer" (click)="logout()" class="nav-link link-text fw-medium">Sign out</a>
			</li>
		</ul>
	</div>
</div>