<section class="bg-light-1 py-6 py-md-8">
	<div class="container">
		<div class="row align-items-center justify-content-between justify-content-md-start mb-4 mb-md-5">
			<div class="col-auto">
				<h2 class="h2 fw-semibold mb-0">{{data['title']}}</h2>
			</div>

			<div class="col-auto">
				<a href="https://glowgetter.parlon.ph/" class="link-secondary fs-2" target="_blank">
					<i class="fas fa-chevron-right fa-xs fa-fw"></i>
				</a>
			</div>
		</div>

		<ng-container *ngIf="data['template'] == 'three_article'">
			<div class="row gx-xl-8">

				<!-- Featured article -->
				<div class="col-12 col-md-5">
					<a href="{{stories[0]['link_out']}}" class="link-text">
						<div class="row gx-4 gy-md-4">
							<div class="col-6 col-md-12">
								<div class="ratio ratio-4x3">
									<img src="{{resource + stories[0]['thumbnail']}}" alt="" loading="lazy"
										class="img-fluid obj-fit-cover">
								</div>
							</div>
	
							<div class="col-6 col-md-12 align-self-center">
								<p class="fs-3 fw-medium mb-0">{{stories[0]['title']}}</p>
							</div>
						</div>
					</a>
				</div>
	
				<!-- Article list -->
				<div class="col-12 col-md-7">
					<ul class="list-unstyled list-article-items">
						<li>
							<a href="{{stories[1]['link_out']}}" class="link-text">
								<div class="row flex-article-list-item">
									<div class="col-8 col-lg-7 col-text">
										<p class="fs-3 fw-medium clamp-3 mb-0">{{stories[1]['title']}}</p>
									</div>
	
									<div class="col-4 col-lg-5 col-img">
										<div class="ratio ratio-4x3">
											<img src="{{resource + stories[1]['thumbnail']}}" alt="" loading="lazy"
												class="img-fluid obj-fit-cover">
										</div>
									</div>
								</div>
							</a>
						</li>
	
						<li>
							<a href="{{stories[2]['link_out']}}" class="link-text">
								<div class="row flex-article-list-item">
									<div class="col-8 col-lg-7 col-text">
										<p class="fs-3 fw-medium clamp-3 mb-0">{{stories[2]['title']}}</p>
									</div>
	
									<div class="col-4 col-lg-5 col-img">
										<div class="ratio ratio-4x3">
											<img src="{{resource + stories[2]['thumbnail']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
										</div>
									</div>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</ng-container>
		
		<ng-container *ngIf="data['template'] == 'seven_article'">
			<!-- Featured article -->
			<div class="row stories-featured-article">

				<div class="col-12 col-md-4" *ngFor="let story of stories | slice:0:3; let i=index">
					<a href="{{story['link_out']}}" class="link-text">
						<div class="row gx-4 gy-md-4 featured-article">
							<div class="col-4 col-md-12 ">
								<div class="ratio ratio-img-cover">
									<img src="{{resource + story['thumbnail']}}" alt="" loading="lazy"
										class="img-fluid obj-fit-cover">
								</div>
							</div>

							<div class="col-8 col-md-12">
								<p class="fs-3 clamp-3 fw-medium mb-0">{{story['title']}}</p>
							</div>
						</div>
					</a>
				</div>

			</div>

			<hr class="my-5 d-none d-md-block">

			
			<!-- Article list -->
			<div class="row list-article-items">

				<div class="col-12 col-md-6"  *ngFor="let story of stories | slice:3:7; let i=index">
					<a href="{{story['link_out']}}" class="link-text">
						<div class="row flex-article-list-item">
							<div class="col-8 col-lg-7 col-text">
								<p class="fs-3 fw-medium clamp-3 mb-0">{{story['title']}}</p>
							</div>

							<div class="col-4 col-lg-5 col-img">
								<div class="ratio ratio-4x3">
									<img src="{{resource + story['thumbnail']}}" alt="" loading="lazy"
										class="img-fluid obj-fit-cover">
								</div>
							</div>
						</div>
					</a>
				</div>

			</div>
		</ng-container>

		<div class="mt-7 text-center">
			<a href="https://glowgetter.parlon.ph/" mat-stroked-button color="secondary" style="font-weight: bold;" >SEE ALL GLOWGETTER STORIES</a>  
		</div>
	</div>
</section>