import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit , Inject, OnDestroy} from '@angular/core';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeAnimation } from '../../../app/animations';
import { Meta, Title } from '@angular/platform-browser';
import { FacebookService,  UIParams, UIResponse  } from 'ngx-facebook';
import { AuthService } from '../../services/auth.service';
import { NetcoreService } from '../../services/netcore/netcore.service';
import { Subscription } from 'rxjs';
import { BookingService } from '../../services/booking/booking.service';
import { GoogleReviewService } from '../../services/google-review/google-review.service';
declare const google: any;
declare var gtag: any;

@Component({
  selector: 'app-parlon-inner',
  templateUrl: './parlon-inner.component.html',
  styleUrls: ['./parlon-inner.component.scss'],
  animations: [fadeAnimation]
})
export class ParlonInnerComponent implements OnInit, OnDestroy {

  slug: string;
  branchSlug: string;
  fields = "";
  parlon: any = [];
  branch: any = [];
  isLoaded = false;
  areas: any = [];
  showHidden = "";
  resourceLink = this.rest.resourceLink();
  fbparams = this._shared.fbParams();
  selectedServices: any[];
  selectedDeals: any[];
  currentUser: string;

  routesBookHidden = [
    {
      'route':'/deals',
    },
    {
      'route':'/photos',
    },
    {
      'route':'/hours',
    },
    {
      'route':'/covid19safety',
    }
  ]

  service;
  reviews = [];

  showGoogleReviewTab = false;

  showReviewSideBar = false;

  averageStarCount = 0; 

  $routeSubscription:Subscription|undefined;

  constructor(@Inject(WINDOW) private window: Window, 
    private rest: RestService, 
    private _shared: SharedService, 
    private _meta: Meta, 
    private _title: Title, 
    private route: ActivatedRoute, 
    private router: Router,
    private _fb: FacebookService,
    private auth: AuthService,
    private NetcoreService:NetcoreService,
    private BookingService:BookingService,
    private googleReviewService:GoogleReviewService
    ) {
      this.auth.currentUser.subscribe(user => this.currentUser = user['data']);
     }


  ngOnDestroy(){
    if(this.$routeSubscription)
    this.$routeSubscription.unsubscribe()
  }   
 
  ngOnInit() {

    const request = {
      placeId: 'ChIJz2CFCIPJlzMReJe73fE67DQ',
      fields: ['reviews']
    };
    // const user_id  = Math.random();
    // smartech('contact', 1, {
    //   'customerid': user_id,
    //   'email': 'test_'+user_id+'@mail.com',
    //   'mobile': '0111111',
    //   'FIRST_NAME': 'asdasd'
    // });

    this.service = new google.maps.places.PlacesService(document.getElementById('googleReviews'));
    this.service.getDetails(request, function(place, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
      }
    });

    this._fb.init(this.fbparams);
    this.selectedServices = [];
    this._shared.selectService(this.selectedServices);
    this.selectedDeals = [];
    this._shared.selectService(this.selectedDeals);
    this.route.params.subscribe(params => {
      this.updatePage();
    });
    
    this._shared.currentService.subscribe(service => {
      this.selectedServices = service;
    });

    this._shared.currentDeal.subscribe(deal => {
      this.selectedDeals = deal;
    });

    
    this.googleReviewService.getActiveBranch.subscribe((data)=>{      
      if(data){
        this.googleReviewService.getGoogleReviews(data).subscribe((data:any)=>{
          // debugger;
          if(data.reviews.data){
            this.averageStarCount = data.business_details.avg_star
            this.showGoogleReviewTab = true
          }else{            
            this.showGoogleReviewTab = false
          }
             
          if(data.business_details.avg_star=='branch star avg disabled'){
            this.showReviewSideBar = false
          }else{
            this.showReviewSideBar = true
            this.averageStarCount = data.business_details.avg_star
          }  

        })
      }    
    })

  }

  prepareRouteTransition(outlet) {
    const animation = outlet.activatedRouteData['animation'] || {};
    return animation['value'] || null;
  }

  updatePage() {
    let page = this;
    page.isLoaded = false;
    this.slug = this.route.snapshot.paramMap.get("slug");
    this.branchSlug = this.route.snapshot.paramMap.get("branch");
    this.showGoogleReviewTab = false
    
    this.fields = this.slug + '?relationships';
    if(this.branchSlug != null) {
      this.fields = this.fields + "&&activeBranch=" + this.branchSlug;
    }
    this.route.queryParams.subscribe(params => {
        this.showHidden = params['showHidden'];
    });
    this.showHidden = (this.showHidden === 'true') ? '&&showhidden' :'';
    this.rest.getParlon(this.fields, this.showHidden).subscribe((data: {}) => {
      // alert(this.branchSlug);
      this.$routeSubscription = this.route.url.subscribe(url => {        
        const segments = url.join('/').split('/');
        const segmentCount = segments.length;
        const childRoutes = this.route.children;
        // alert(segmentCount )
        if(data['data']['default_page']=='services' && segmentCount == 2  && !childRoutes[0]){
          // console.log(childRoutes[0].snapshot.url[0].path)
          this.router.navigate([`/parlon/${this.slug}/services`]);

        }
        if(data['data']['default_page']=='deals' && segmentCount == 2  && !childRoutes[0]){
          // console.log(childRoutes[0].snapshot.url[0].path)
          this.router.navigate([`/parlon/${this.slug}/deals`]);

        }
        if(data['data']['default_page']=='services' && segmentCount == 3  && !childRoutes[0]){
          // console.log(childRoutes[0].snapshot.url[0].path)
          this.router.navigate([`/parlon/${this.slug}/${this.branchSlug}/services`]);

        }

        if(data['data']['default_page']=='deals' && segmentCount == 3  && !childRoutes[0]){
          // console.log(childRoutes[0].snapshot.url[0].path)
          this.router.navigate([`/parlon/${this.slug}/${this.branchSlug}/deals`]);
        }

      });
      
      if (data['data'].length === 0) {
        this.router.navigate(['/404']);
      }
      this.parlon = data['data'];
      gtag('event', 'page_view', {
        'page_path': window.location.pathname,
        'parlon_name': this.parlon['business_name'],
        'page_type': 'Parlon'
      });
      this._shared.changeActiveParlon(this.parlon);
      this.addAreas(this.parlon['branches']);
      if(this.parlon['og_image'] != null ) {
        this._meta.updateTag({ name: 'og-url', content: 'https://www.parlon.ph/parlon/' + this.parlon['slug'] + "/services"});
        // remove when s3 image headers fixed
        if(this.parlon['slug'] == "emphasis-salon") {
          this._meta.updateTag({ name: 'og-image', content: 'https://www.parlon.ph/assets/images/emphasis_og.png' });
          this._meta.updateTag({ name: 'og-image-secure', content: 'https://www.parlon.ph/assets/images/emphasis_og.png' });
        } else {
          this._meta.updateTag({ name: 'og-image', content: this.resourceLink + this.parlon['og_image'] });
          this._meta.updateTag({ name: 'og-image-secure', content: this.resourceLink + this.parlon['og_image'] });
        }
      }
      if (this.parlon['branches'].length > 0) {
        this.branchSlug = this.parlon['active_branch']['slug'];
        this.parlon.branches.sort(function (x, y) { return x.slug == page.branchSlug ? -1 : y.slug == page.branchSlug ? 1 : 0; });
        this.branch = this.parlon['active_branch'];
        
        this.googleReviewService.setActiveBranch(this.branch.slug);
        
        this.NetcoreService.dispatch(this.branch['event_tracking_name'],
        {
          'merchant':this.parlon['business_name'],
          'branch': this.branch['branch_name'],
          'location': this.branch['location']['location_name']
        });

        this._shared.changeActiveBranch(this.branch);
        this.route.data.subscribe((currentData) => {
          currentData['branch'] = this.branch;
        });
        this._meta.updateTag({ name: 'description', content: "Buy the best and exclusive deals via Parlon - The Philippines' go-to-platform for beauty and wellness services. Check out " + this.parlon['business_name'] + " – " + this.branch['branch_name'] + " in " + this.branch['location']['location_name'] + "." });
        this._meta.updateTag({ name: 'og-description', content: "Buy the best and exclusive deals via Parlon - The Philippines' go-to-platform for beauty and wellness services. Check out " + this.parlon['business_name'] + " – " + this.branch['branch_name'] + " in " + this.branch['location']['location_name'] + "." });
        this._title.setTitle("BOOK NOW - " + this.parlon['business_name'] + " - Parlon");
        this._meta.updateTag({ name: 'title', content: "BOOK NOW - " + this.parlon['business_name'] + " - Parlon" });
        this._meta.updateTag({ name: 'og-title', content: "BOOK NOW - " + this.parlon['business_name'] + " - Parlon" });
        page.isLoaded = true;
      } else {
        page.isLoaded = true;
      }
    });
  }

  addAreas(branches) {
    const map = new Map();
    this.areas = [];
    branches.forEach(item => {
      if (!map.has(item.location.id)) {
        map.set(item.location.id, true);    // set any value to Map
        this.areas.push(item.location);
        this._shared.changeAreas(this.areas);
      }
    });
  }

  booknowHidden() {
    let isHidden = "";
    this.routesBookHidden.forEach(route => {
      if (this.router.url.includes(route['route'])){
        isHidden = 'd-none';
      }
    });
    
    return isHidden;
  }

  bookNow() {
    if(this.selectedServices.length > 0 || this.selectedDeals.length > 0) {
      let total_price = 0
      let services = [];
      this.selectedServices.forEach(e => {
        total_price = total_price + e.price;
        services.push(e.service);
      });
      // this.NetcoreService.dispatch('booking confirm merchant',
      // {
      //   'merchant':this.parlon['business_name'],
      //   'merchantbranch': this.branch['branch_name'],
      //   'bookingservices':services,
      //   'totalserviceprice':total_price
      // });
      let selectedBooking = {
        "branch_id": this.branch['id'],
        "branch_slug": this.branch['slug'],
        "salon":this.parlon['business_name'],
        "branch":this.branch['branch_name'],
        "logo":this.parlon['logo'],
        "services":this.selectedServices,
        "deals":this.selectedDeals
      }
      this.BookingService.setBookings(selectedBooking);
      this.router.navigate(['/book-appointment']);
    }
    else {
      let elements = document.getElementsByClassName('service-select');
      let select = elements[0];
      select.classList.add('pulsate');
      document.getElementById("service-list").scrollIntoView();
    }
  }
  
}
