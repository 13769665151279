<!--
	NOTE:
	- Add `d-none d-md-block` to hide the search bar on mobile. Only when floating search bar in banner is visible.
-->

<section class="bg-light-2 py-5">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
				<search-input-v2></search-input-v2>
			</div>
		</div>
	</div>
</section>