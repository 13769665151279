import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'deal-details',
  templateUrl: './deal-details.component.html',
  styleUrls: ['./deal-details.component.scss']
})
export class DealDetailsComponent implements OnInit {

  @Input() data;
  resource = environment.resource;
  imgSrc;

  constructor() { }

  ngOnInit() {
    this.imgSrc = this.resource + this.data['deal']['images'][0];
  }

  changeSrc(value) {
    this.imgSrc = value;
  }

}
