<div class="my-5 my-md-5">
  <div class="row">
    <div class="col-md-8">
      <div class="text-deals-desc mt-5 mb-lg-0">
        <div [innerHTML]="data['deal']['description']" class=""></div>
      </div>
      <p class="fs-6 fw-medium">Additional Terms and Conditions</p>

      <div class="card card-fine-print mb-5">
        <div class="card-body">
          <div [innerHTML]="data['deal']['fine_print']"></div>
        </div>
      </div>
      <!-- FORM -->
      <div class="row mb-7">
        <div class="col-md-12">
          <div class="step-header">
            <div class="step-icon">1</div>
            <p class="step-title">Who will use this deal?</p>
          </div>
          <a role="button" mat-stroked-button class="mat-radio-neutral m-2 "
            [ngClass]="buying_for == 'me' ? 'selected' : ''" (click)="changeBuyingFor('me')">
            <i class="fa-light fa-heart mx-2"></i> Me
          </a>
          <a role="button" mat-stroked-button class="mat-radio-neutral m-2"
            [ngClass]="buying_for == 'se' ? 'selected' : ''" (click)="changeBuyingFor('se')">
            <i class="fa-regular fa-gift"></i> Someone Else
          </a>
        </div>
      </div>

      <!-- Personal details -->
      <div class="row mb-7" *ngIf="is_authenticated == false">
        <div class="row">
          <div class="col-md-10">
            <div class="d-flex justify-content-between align-items-center">
              <div class="step-header">
                <div class="step-icon">2</div>
                <p class="step-title">Your details</p>
              </div>
            </div>
            <h3 class="h3 fw-semibold mb-4 mb-md-6">Sign in</h3>
            <form [formGroup]="sign_in" (ngSubmit)="submitSignin()">
              <mat-form-field appearance="fill">
                <mat-label>Email address</mat-label>
                <input matInput placeholder="Email address" formControlName="email">
              </mat-form-field>
              <!-- <div class="errors" *ngIf="email.invalid && (email.dirty || email.touched)" >					
                <mat-error *ngIf="email.errors.required" class="text-danger">
                  Email is required.
                </mat-error>
                <mat-error *ngIf="email.errors.email && email.touched">
                  Must be a valid email format.
                </mat-error>										
              </div> -->
  
              <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" id="login_password" formControlName="password"
                  [type]="hidePasswordIcon ? 'password' : 'text'">
                <button (click)="hidePasswordIcon = !hidePasswordIcon" type="button" mat-icon-button matSuffix>
                  <mat-icon>{{hidePasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
  
              <!-- <div class="errors" *ngIf="password.invalid && (password.dirty || password.touched)" >					
                <mat-error *ngIf="password.errors.required" class="text-danger">
                  Password is required.
                </mat-error>				
              </div> -->
  
              <div class="errors" *ngIf="inValidEmailOrPassword">
                <mat-error>
                  Incorrect email or password.
                </mat-error>
              </div>
  
              <button [disabled]="!sign_in.valid || sign_submitted" mat-flat-button color="secondary" class="mb-5 w-100 py-2"
                type="submit">
                SIGN IN
              </button>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-register">
            <h3 class="h3 fw-semibold mb-4 mb-md-6">Register now</h3>
        
            <p class="mb-6">Create your Parlon account and enjoy beautiful surprises. Get instant <span class="fw-semibold">PHP 250 voucher</span> when
              you register + Parlon candies (revealing this soon!) 🍬 You're welcome!</p>
        
            <button mat-flat-button color="secondary" class="py-2 w-100 mb-4" (click)="openRegisterModal()">
              CREATE ACCOUNT
            </button>
            <button mat-stroked-button color="secondary" class="py-2 w-100" (click)="is_authenticated = true">
              CONTINUE AS GUEST
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-7" *ngIf="is_authenticated">
        <div class="col-md-12">
          <div class="d-flex justify-content-between align-items-center">
            <div class="step-header">
              <div class="step-icon">2</div>
              <p class="step-title">Your details</p>
            </div>
            <a role="button" (click)="showEditModal()" class="mx-2 text-secondary" *ngIf="checkInfoEditable()">
              <i class="fas fa-edit"></i>
              EDIT DETAILS
            </a>
          </div>
          <form [formGroup]="purchaseForm">

            <div class="mb-2" formGroupName="personalDetailsFormGroup">
              <p class="fs-6 fw-medium mb-2">What's your name?</p>

              <div class="card mb-3">
                <div class="card-body gray-bg">
                  <div class="row gx-0 mb-2">
                    <div class="col-auto pe-3">
                      <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                    </div>

                    <div class="col">
                      <p class="small text-muted mb-0">
                        <span class="fw-semibold">You cannot change the name after successful purchase.</span><br />
                        Person who will redeem the package should present any valid Government-issued ID upon
                        redemption.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <mat-form-field appearance="fill" class="w-100 " [ngClass]="checkInfoEditable() ? 'disabled' : ''">
                <mat-label>First name</mat-label>
                <input matInput placeholder="First Name*" name="first_name" formControlName="first_name" required
                  [readonly]="checkInfoEditable()">
                <!-- <mat-error *ngIf="details.first_name.errors?.required">The first name field is required.</mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100 " [ngClass]="checkInfoEditable() ? 'disabled' : ''">
                <mat-label>Last name</mat-label>
                <input matInput placeholder="Last Name*" name="last_name" formControlName="last_name" required
                  [readonly]="checkInfoEditable()">
                <!-- <mat-error *ngIf="details.last_name.errors?.required">The last name field is required.</mat-error> -->
              </mat-form-field>
            </div>

            <div formGroupName="personalDetailsFormGroup">
              <p class="fs-6 fw-medium mb-2">Your email address</p>

              <div class="card mb-3">
                <div class="card-body gray-bg">
                  <div class="row gx-0 mb-2">
                    <div class="col-auto pe-3">
                      <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                    </div>

                    <div class="col">
                      <p class="small text-muted mb-0">
                        <span class="fw-semibold">E-mail is very important!</span><br />
                        You cannot change the email after successful purchase. You will receive the E-Voucher through
                        the email that you entered. Please double-check the email that you entered.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <mat-form-field appearance="fill" class="w-100 " [ngClass]="checkInfoEditable() ? 'disabled' : ''">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" id="email" name="email" formControlName="email" required
                  [readonly]="checkInfoEditable()">
                <!-- <mat-error *ngIf="details.email?.errors">
                  <div *ngIf="details.email.errors?.required">The email field is required.</div>
                  <div *ngIf="details.email.errors?.email">Email must be a valid email address</div>
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100 " [ngClass]="checkInfoEditable() ? 'disabled' : ''">
                <mat-label>Re-enter your email address</mat-label>
                <input matInput placeholder="Re-enter e-mail address (just to be sure!)*" id="re_enter_email"
                  name="re_enter_email" formControlName="re_enter_email" required [readonly]="checkInfoEditable()">
                <!-- <mat-error *ngIf="details.email?.errors">
                  <div *ngIf="details.re_enter_email?.errors.required">The email field is required.</div>
                  <div *ngIf="details.re_enter_email?.errors.email">Email must be a valid email address</div>
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100 " [ngClass]="checkInfoEditable() ? 'disabled' : ''">
                <mat-label>Your mobile number</mat-label>
                <input type="text" matInput placeholder="Your mobile number *" name="contact_number"
                  (keypress)="numberOnly($event)" formControlName="contact_number" required
                  [readonly]="checkInfoEditable()">
                <!-- <mat-error *ngIf="details.contact_number.errors?.required">The contact number field is required.
                </mat-error> -->
              </mat-form-field>
            </div>
          </form>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="step-header">
            <div class="step-icon">3</div>
            <p class="step-title">How many vouchers do you like to purchase?</p>
          </div>
          <div class="mb-3">
            <a role="button" mat-stroked-button class="mat-radio-neutral m-2  py-2"
              *ngFor="let limit of purchase_limit; let i = index" [ngClass]="purchase_count == (i+1) ? 'selected' : ''"
              (click)="changePurchaseCount(i+1)">
              {{i+1}}
            </a>
          </div>

          <div class="card mb-3">
            <div class="card-body gray-bg">
              <div class="row gx-0 mb-2">
                <div class="col-auto pe-3">
                  <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                </div>

                <div class="col">
                  <p class="small text-muted mb-0">
                    For more than 5 vouchers, please contact parlon at info@parlon.ph. Thank you.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex mt-3 mb-6" *ngIf="buying_for == 'se'">
            <div class="" *ngIf="purchase_count > 1">
              <mat-checkbox color="primary" [(ngModel)]="same_redeemer" [value]="true">
                Same redeemer for all vouchers
              </mat-checkbox>
            </div>
            <div class="mx-3">
              <mat-checkbox color="primary" [(ngModel)]="same_card" [value]="true" (change)="checkSameCard()">
                Same card for all vouchers
              </mat-checkbox>
            </div>
          </div>




          <div class="step-header mt-7">
            <div class="step-icon">4</div>
            <p class="step-title">Redeemers and Branches</p>
          </div>

          <div class="redeemer-select">
            <a role="button" mat-stroked-button class="redeemer-radio py-1"
              *ngFor="let item of purchase_count_arr; let i = index"
              [ngClass]="[redeemer_tab == (i+1) ? 'selected' : '', tabValidity(i) == true ? 'completed' : '', tabValidity(i) == false ? 'error' : '']"
              (click)="changeRedeemerTab(i+1)">
              <i class="fa-regular fa-user mx-3"></i> {{i+1}}
              <i class="fa-solid fa-circle-exclamation mx-2 error-icon"></i>
              <i class="fa-solid fa-circle-check mx-2 completed-icon"></i>
            </a>
          </div>

          <div class="mb-5" *ngIf="buying_for == 'se'">
            <h3 class="h3 fw-semibold mb-4">Select Card Design</h3>
            <div class="flickity flickity-gifting flickity-overflow row gx-4">
              <ng-container *ngFor="let card of giftingData['gift_cards']; let card_index = index">
                <div class="slide gift-card" [ngClass]="checkActiveCard() == card['id'] ? 'active' : ''"
                  (click)="showGiftCardModal(card)">
                  <div class="check">
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </div>
                  <img src="{{resource + card['file']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover ">
                  <p class="text-muted text-center my-3">
                    {{card['name']}}
                  </p>
                </div>
              </ng-container>

            </div>

            <h3 class="h3 fw-semibold mb-4">Select Branch</h3>
            <ng-container *ngIf="same_branches == true">
              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>Branch</mat-label>
                <mat-select name="branch" class="same_branch" required [(ngModel)]="same_branch_value"
                  (selectionChange)="onChangeSameBranch($event.value)">
                  <mat-option *ngFor="let branch of data['parlon']['branches']" [value]="branch" ng-selected>
                    {{ branch['name'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="text-muted mt-n3 mb-0" *ngIf="same_branch_value && same_branches == true">
                <i class="far fa-map-marker-alt fa-fw me-2"></i>
                <span>{{same_branch_value['address']}}</span>
              </p>
            </ng-container>
            <ng-container *ngFor="let item of purchase_count_arr; let i = index">
              <div [ngClass]="redeemer_tab == (i+1) ? '' : 'd-none'">
                <mat-form-field appearance="fill" class="w-100 " *ngIf="same_branches == false">
                  <mat-label>Branch</mat-label>
                  <mat-select [formControl]="redeemer_branches.controls[i]" name="branch" required
                    (selectionChange)="onChangeBranch($event.value)">
                    <mat-option *ngFor="let branch of data['parlon']['branches']" [value]="branch" ng-selected>
                      {{ branch['name'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <p class="text-muted mt-n3 mb-0" *ngIf="redeemer_branches.controls[i].value && same_branches == false">
                  <i class="far fa-map-marker-alt fa-fw me-2"></i>
                  <span>{{redeemer_branches.controls[i].value.address}}</span>
                </p>
              </div>
            </ng-container>
            <div class="mt-3 mb-6" *ngIf="purchase_count > 1">
              <mat-checkbox color="primary" [(ngModel)]="same_branches" [value]="true" (change)="checkSameBranch()">
                Same branch for all vouchers
              </mat-checkbox>
            </div>
            <ng-container *ngFor="let item of purchase_count_arr; let i = index">
              <div [ngClass]="redeemer_tab == (i+1) ? '' : 'd-none'">
                <h3 class="h3 fw-semibold my-4">
                  Redeemer Details
                </h3>

                <form [formGroup]="redeemer_details.controls[i]" *ngIf="same_redeemer == false">

                  <div class="mb-2">
                    <p class="fs-6 fw-medium mb-5">Name of Redeemer</p>

                    <div class="card mb-3">
                      <div class="card-body gray-bg">
                        <div class="row gx-0 mb-2">
                          <div class="col-auto pe-3">
                            <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                          </div>

                          <div class="col">
                            <p class="small text-muted mb-0">
                              <span class="fw-semibold">You cannot change the name after successful
                                purchase.</span><br />
                              Person who will redeem the package should present any valid Government-issued ID upon
                              redemption.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <mat-form-field appearance="fill" class="w-100 ">
                      <mat-label>First name</mat-label>
                      <input matInput placeholder="First Name*" name="first_name"
                        [formControl]="redeemer_details.controls[i].controls.first_name" required>
                      <!-- <mat-error *ngIf="f.first_name.errors?.required">The first name field is required.</mat-error> -->
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-100 ">
                      <mat-label>Last name</mat-label>
                      <input matInput placeholder="Last Name*" name="last_name"
                        [formControl]="redeemer_details.controls[i].controls.last_name" required>
                      <!-- <mat-error *ngIf="f.last_name.errors?.required">The last name field is required.</mat-error> -->
                    </mat-form-field>

                    <div class="mt-3 mb-6">
                      <mat-checkbox color="primary" [value]="true"
                        [formControl]="redeemer_details.controls[i].controls.surprise">
                        This is a surprise. Please don’t email and text the redeemer. Send e-voucher and sms to me.
                      </mat-checkbox>
                    </div>
                  </div>

                  <div *ngIf="redeemer_details.controls[i].controls.surprise.value == false">
                    <p class="fs-6 fw-medium mb-2">Redeemer's email address</p>

                    <div class="card mb-3">
                      <div class="card-body gray-bg">
                        <div class="row gx-0 mb-2">
                          <div class="col-auto pe-3">
                            <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                          </div>

                          <div class="col">
                            <p class="small text-muted mb-0">
                              <span class="fw-semibold">E-mail is very important!</span><br />
                              You cannot change the email after successful purchase. You will receive the E-Voucher
                              through the email that you entered. Please double-check the email that you entered.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <mat-form-field appearance="fill" class="w-100 ">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" id="email" name="email"
                        [formControl]="redeemer_details.controls[i].controls.email" required>
                      <!-- <mat-error *ngIf="f.email?.errors">
                        <div *ngIf="f.email.errors?.required">The email field is required.</div>
                        <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
                      </mat-error> -->
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-100 ">
                      <mat-label>Re-enter your email address</mat-label>
                      <input matInput placeholder="Re-enter e-mail address (just to be sure!)*" id="re_enter_email"
                        name="re_enter_email" [formControl]="redeemer_details.controls[i].controls.re_enter_email"
                        required>
                      <!-- <mat-error *ngIf="f.email?.errors">
                        <div *ngIf="f.re_enter_email?.errors.required">The email field is required.</div>
                        <div *ngIf="f.re_enter_email?.errors.email">Email must be a valid email address</div>
                      </mat-error> -->
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-100 ">
                      <mat-label>Your mobile number</mat-label>
                      <input type="text" matInput placeholder="Your mobile number *" name="contact_number"
                        (keypress)="numberOnly($event)"
                        [formControl]="redeemer_details.controls[i].controls.contact_number" required>
                      <!-- <mat-error *ngIf="f.contact_number.errors?.required">The contact number field is required.</mat-error> -->
                    </mat-form-field>
                  </div>
                </form>
              </div>
            </ng-container>
            <form [formGroup]="purchaseForm" *ngIf="same_redeemer == true">

              <div formGroupName="sameRedeemerFormGroup">
                <div class="mb-2">
                  <p class="fs-6 fw-medium mb-5">Name of Redeemer</p>

                  <div class="card mb-3">
                    <div class="card-body gray-bg">
                      <div class="row gx-0 mb-2">
                        <div class="col-auto pe-3">
                          <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                        </div>

                        <div class="col">
                          <p class="small text-muted mb-0">
                            <span class="fw-semibold">You cannot change the name after successful purchase.</span><br />
                            Person who will redeem the package should present any valid Government-issued ID upon
                            redemption.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>First name</mat-label>
                    <input matInput placeholder="First Name*" name="first_name" formControlName="first_name" required>
                    <!-- <mat-error *ngIf="f.first_name.errors?.required">The first name field is required.</mat-error> -->
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Last name</mat-label>
                    <input matInput placeholder="Last Name*" name="last_name" formControlName="last_name" required>
                    <!-- <mat-error *ngIf="f.last_name.errors?.required">The last name field is required.</mat-error> -->
                  </mat-form-field>

                  <div class="mt-3 mb-6">
                    <mat-checkbox color="primary" [value]="true" formControlName="surprise">
                      This is a surprise. Please don’t email and text the redeemer. Send e-voucher and sms to me.
                    </mat-checkbox>
                  </div>
                </div>

                <div *ngIf="purchaseForm.controls.sameRedeemerFormGroup.controls.surprise.value == false">
                  <p class="fs-6 fw-medium mb-2">Redeemer's Email address</p>

                  <div class="card mb-3">
                    <div class="card-body gray-bg">
                      <div class="row gx-0 mb-2">
                        <div class="col-auto pe-3">
                          <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                        </div>

                        <div class="col">
                          <p class="small text-muted mb-0">
                            <span class="fw-semibold">E-mail is very important!</span><br />
                            You cannot change the email after successful purchase. You will receive the E-Voucher
                            through the email that you entered. Please double-check the email that you entered.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" id="email" name="email" formControlName="email" required>
                    <!-- <mat-error *ngIf="f.email?.errors">
                      <div *ngIf="f.email.errors?.required">The email field is required.</div>
                      <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
                    </mat-error> -->
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Re-enter your email address</mat-label>
                    <input matInput placeholder="Re-enter e-mail address (just to be sure!)*" id="re_enter_email"
                      name="re_enter_email" formControlName="re_enter_email" required>
                    <!-- <mat-error *ngIf="f.email?.errors">
                      <div *ngIf="f.re_enter_email?.errors.required">The email field is required.</div>
                      <div *ngIf="f.re_enter_email?.errors.email">Email must be a valid email address</div>
                    </mat-error> -->
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Your mobile number</mat-label>
                    <input type="text" matInput placeholder="Your mobile number *" name="contact_number"
                      (keypress)="numberOnly($event)" formControlName="contact_number" required>
                    <!-- <mat-error *ngIf="f.contact_number.errors?.required">The contact number field is required.</mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>

          <div class="mb-5" *ngIf="buying_for == 'me'">
            <h3 class="h3 fw-semibold mb-4">Select Branch</h3>
            <ng-container *ngIf="same_branches == true">
              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>Branch</mat-label>
                <mat-select name="branch" class="same_branch" required [(ngModel)]="same_branch_value"
                  (selectionChange)="onChangeSameBranch($event.value)">
                  <mat-option *ngFor="let branch of data['parlon']['branches']" [value]="branch" ng-selected>
                    {{ branch['name'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="text-muted mt-n3 mb-0" *ngIf="same_branch_value">
                <i class="far fa-map-marker-alt fa-fw me-2"></i>
                <span>{{same_branch_value['address']}}</span>
              </p>
            </ng-container>
            <ng-container *ngFor="let item of purchase_count_arr; let i = index">
              <div [ngClass]="redeemer_tab == (i+1) ? '' : 'd-none'">
                <mat-form-field appearance="fill" class="w-100 " *ngIf="same_branches == false">
                  <mat-label>Branch</mat-label>
                  <mat-select [formControl]="redeemer_branches.controls[i]" name="branch" required
                    (selectionChange)="onChangeBranch($event.value)">
                    <mat-option *ngFor="let branch of data['parlon']['branches']" [value]="branch" ng-selected>
                      {{ branch['name'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <p class="text-muted mt-n3 mb-0" *ngIf="redeemer_branches.controls[i].value && same_branches == false">
                  <i class="far fa-map-marker-alt fa-fw me-2"></i>
                  <span>{{redeemer_branches.controls[i].value.address}}</span>
                </p>
              </div>
            </ng-container>
            <div class="mt-3 mb-6" *ngIf="purchase_count > 1">
              <mat-checkbox color="primary" [(ngModel)]="same_branches" [value]="true" (change)="checkSameBranch()">
                Same branch for all vouchers
              </mat-checkbox>
            </div>
          </div>

          <div class="card bg-gray-fa rounded-3 my-5 mt-lg-0 mb-0 d-md-none mb-7">
            <div class="card-body">
              <div class="row row-cols-1 gx-md-8">
                <div class="col">
                  <div class="col">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <p class="text-muted mb-0">Original Price</p>
                      </div>
                      <div class="col-6">
                        <p class="text-end tabular-nums mb-0"><del>₱{{data['deal']['price_before'] | number}}</del></p>
                      </div>
                    </div>

                    <hr>

                    <table class="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td class="text-muted ps-0">Deal Price</td>
                          <td class="text-end tabular-nums pe-0">₱{{data['deal']['price_current'] | number}}</td>
                        </tr>

                      </tbody>
                    </table>

                    <hr>

                    <table class="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td class="text-muted ps-0">Quantity</td>
                          <td class="text-end tabular-nums pe-0">x{{purchase_count}}</td>
                        </tr>

                      </tbody>
                    </table>

                    <hr>

                    <div class="row align-items-center">
                      <div class="col-6">
                        <p class="fw-bold mb-0">Total (inc. VAT)</p>
                      </div>
                      <div class="col-6">
                        <p class="text-end fw-bold tabular-nums mb-0">₱{{(data['deal']['price_current'] *
                          purchase_count) |
                          number}}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="submit-btn mt-4" (click)="addToCart()" [class.disabled] = "submitted == true">
            <div class="cell-1 cell">
              <p class="mb-1 fw-semibold">Total</p>
              <p class="mb-1 fw-semibold">₱{{(data['deal']['price_current'] * purchase_count) | number}}</p>
              <p class="mb-1"><del>₱{{(data['deal']['price_before'] * purchase_count) | number}}</del></p>
            </div>
            <div class="cell-2 cell">
              <p class="mb-1 fw-semibold">
                <span *ngIf="formValidity()">Add to Beauty Bag</span>
                <span *ngIf="!formValidity()">Complete all details</span>
              </p>
              <p class="mb-1">₱{{data['deal']['price_current'] | number}} each x {{purchase_count}}</p>
              <mat-icon *ngIf="submitted"><mat-spinner color="accent" diameter="20">
              </mat-spinner></mat-icon>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-md-4 d-none d-md-block">
      <div class="card bg-gray-fa rounded-3 my-5 mt-lg-0 mb-0 sticky">
        <div class="card-body">
          <div class="row row-cols-1 gx-md-8">
            <div class="col">
              <h3 class="h3 mb-2">{{data['deal']['name']}}</h3>
              <div class="d-flex mb-5 align-items-center">
                <img src="{{resource + data['parlon']['logo']}}" class="salon-logo">
                <p class="text-muted fw-semibold mx-3 mb-0">{{data['parlon']['name']}}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text-muted mb-0">Original Price</p>
                </div>
                <div class="col-6">
                  <p class="text-end tabular-nums mb-0"><del>₱{{data['deal']['price_before'] | number}}</del></p>
                </div>
              </div>

              <hr>

              <table class="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td class="text-muted ps-0">Deal Price</td>
                    <td class="text-end tabular-nums pe-0">₱{{data['deal']['price_current'] | number}}</td>
                  </tr>

                </tbody>
              </table>

              <hr>

              <table class="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td class="text-muted ps-0">Quantity</td>
                    <td class="text-end tabular-nums pe-0">x{{purchase_count}}</td>
                  </tr>

                </tbody>
              </table>

              <hr>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="fw-bold mb-0">Total (inc. VAT)</p>
                </div>
                <div class="col-6">
                  <p class="text-end fw-bold tabular-nums mb-0">₱{{(data['deal']['price_current'] * purchase_count) |
                    number}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


  <ngx-smart-modal id="successModal" #successModal identifier="successModal" [dismissable]="false" [closable]="false">
    <ng-container>
      <div class="modal-body p-2">
        <div class="row">
          <div class="col">
            <h2 class="h2 text-primary text-center mt-5"><i class="fak fa-beauty-bag fa-lg fa-fw"></i></h2>
            <h3 class="h3 mb-6 fw-semibold text-center">Added to Your Beauty Bag!</h3>
            <div class="row mb-6">
              <div class="col px-1">
                <button [routerLink]="['/all-parlon-deals']" mat-stroked-button color="secondary" class="p-1 w-100"
                  type="button">
                  BUY MORE
                </button>
              </div>
              <div class="col px-1">
                <button [routerLink]="['/beauty-bag-checkout']" mat-flat-button color="secondary" class="p-1 w-100"
                  type="button">
                  CHECKOUT
                </button>
              </div>
            </div>
            <hr class="mb-6">
            <h3 class="h3 mb-2 fw-semibold text-center">You’ll also love these deals from {{data['parlon']['name']}}
            </h3>
            <ng-container>
              <div class="row mb-4" *ngFor="let deal of response['more_deals']">
                <div class="col-md-4">
                  <a href="/beauty-bag-deals/{{deal['slug']}}"><img src="{{resource + deal['primary_photo']}}"
                      class="w-100 modal-img"></a>
                </div>
                <div class="col-md-8">
                  <a href="/beauty-bag-deals/{{deal['slug']}}" class="link-text">
                    <p class="mb-2 text-secondary">
                      {{deal['name']}}
                    </p>
                    <p class="my-1">
                      <span *ngIf="data['deal']['discount_type'] == 'Percentage Discount'" class="discount">{{
                        data['deal']['discount_value'] | number }}% OFF</span>
                      <span *ngIf="data['deal']['discount_type'] == 'Value Discount'" class="discount">Save ₱{{
                        data['deal']['discount_value'] | number}}</span>
                    </p>
                    <p class="mb-2 text-muted">
                      <span>₱{{deal['price_current'] | number}}</span>&nbsp;<span><del>₱
                          {{deal['price_before'] | number}}</del></span>
                    </p>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ngx-smart-modal>

  <ngx-smart-modal #editModal identifier="editModal" [dismissable]="false">
    <ng-container>
      <div class="modal-body p-2">
        <div class="mb-5 mb-lg-0">
          <h3 class="h3 fw-semibold mb-4">
            Edit your details
          </h3>

          <form [formGroup]="purchaseForm">

            <div class="mb-2" formGroupName="personalDetailsFormGroup">
              <p class="fs-6 fw-medium mb-2">What's your name?</p>

              <div class="card mb-3">
                <div class="card-body gray-bg">
                  <div class="row gx-0 mb-2">
                    <div class="col-auto pe-3">
                      <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                    </div>

                    <div class="col">
                      <p class="small text-muted mb-0">
                        <span class="fw-semibold">You cannot change the name after successful purchase.</span><br />
                        Person who will redeem the package should present any valid Government-issued ID upon
                        redemption.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>First name</mat-label>
                <input matInput placeholder="First Name*" name="first_name" formControlName="first_name" required>
                <!-- <mat-error *ngIf="details.first_name.errors?.required">The first name field is required.</mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>Last name</mat-label>
                <input matInput placeholder="Last Name*" name="last_name" formControlName="last_name" required>
                <!-- <mat-error *ngIf="details.last_name.errors?.required">The last name field is required.</mat-error> -->
              </mat-form-field>
            </div>

            <div formGroupName="personalDetailsFormGroup">
              <p class="fs-6 fw-medium mb-2">Your email address</p>

              <div class="card mb-3">
                <div class="card-body gray-bg">
                  <div class="row gx-0 mb-2">
                    <div class="col-auto pe-3">
                      <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                    </div>

                    <div class="col">
                      <p class="small text-muted mb-0">
                        <span class="fw-semibold">E-mail is very important!</span><br />
                        You cannot change the email after successful purchase. You will receive the E-Voucher through
                        the email that you entered. Please double-check the email that you entered.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" id="email" name="email" formControlName="email" required>
                <!-- <mat-error *ngIf="details.email?.errors">
                  <div *ngIf="details.email.errors?.required">The email field is required.</div>
                  <div *ngIf="details.email.errors?.email">Email must be a valid email address</div>
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>Re-enter your email address</mat-label>
                <input matInput placeholder="Re-enter e-mail address (just to be sure!)*" id="re_enter_email"
                  name="re_enter_email" formControlName="re_enter_email" required>
                <!-- <mat-error *ngIf="details.email?.errors">
                  <div *ngIf="details.re_enter_email?.errors.required">The email field is required.</div>
                  <div *ngIf="details.re_enter_email?.errors.email">Email must be a valid email address</div>
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100 ">
                <mat-label>Your mobile number</mat-label>
                <input type="text" matInput placeholder="Your mobile number *" name="contact_number"
                  (keypress)="numberOnly($event)" formControlName="contact_number" required>
                <!-- <mat-error *ngIf="details.contact_number.errors?.required">The contact number field is required.
                </mat-error> -->
              </mat-form-field>
            </div>
          </form>
          <div class="d-grid">
            <button mat-flat-button color="secondary" class="p-3" type="submit"
              [disabled]="loadingUpdate || !editValidity()" (click)="submitEditDetails()">
              <span>SAVE DETAILS</span>
              <mat-icon *ngIf="loadingUpdate"><mat-spinner color="accent" diameter="20">
                </mat-spinner></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ngx-smart-modal>

  <ngx-smart-modal #giftCardModal identifier="giftCardModal" [dismissable]="false">
    <ng-container *ngIf="giftCardModal.hasData()">
      <div class="modal-body p-2">
        <div class="row">
          <div class="col">
            <h3 class="h3 mb-6 fw-semibold text-center">Choose a card design</h3>
            <img src="{{resource + giftCardModal.getData().data['file']}}" class="w-100">
            <div class="text-center">
              <button mat-flat-button color="secondary" class="w-75 mx-auto mt-5"
                (click)="selectGiftCard(giftCardModal.getData().data['id'])">
                <span>Select</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ngx-smart-modal>


  <ngx-smart-modal [customClass]="'wide-modal-custom'" #registerModal identifier="registerModal" [dismissable]="false">
    <ng-container>
      <div class="modal-body p-2">
        <div class="row">
          <div class="col">
            <app-registration-form (registerEmitter)="successRegister($event)"></app-registration-form>
          </div>
        </div>
      </div>
    </ng-container>
  </ngx-smart-modal>

  <ngx-smart-modal [customClass]="'wide-modal-custom'" #otpModal identifier="otpModal" [dismissable]="false">
    <ng-container>
      <div class="modal-body p-2">
        <div class="row">
          <div class="col">
            <app-otp-form (otpEmitter)="otpEvent($event)"></app-otp-form>
          </div>
        </div>
      </div>
    </ng-container>
  </ngx-smart-modal>