<section class="bg-light-1 py-6 py-md-8 booking-section" *ngIf="isLoaded == true">
	<div class="container">
		<div class="row row-main-layout gx-xl-8 gy-4">

			<!-- Sidebar -->




			<aside class="col-12 col-lg-4">
				<div class="row align-items-center">
					<div class="col-12 col-md-4 col-lg-12">
						<img src="{{resource + selectedBooking['logo']}}?tr=w-320" alt="" loading="lazy"
							class="img-fluid img-parlon-partner-logo mb-6 mb-md-0 mb-lg-8">
					</div>

					<div class="col-12 col-md-8 col-lg-12">
						<div class="text-center text-lg-start">
							<p class="small mb-2 fw-semibold"><i>You are booking an appointment in</i> </p>
							<p>
								<span class="fs-4 fw-semibold">{{selectedBooking['salon']}}</span><br />
								<span class="fs-6 fw-semibold text-secondary">{{branch['branch_name']}}</span>
							</p>
						</div>
					</div>
				</div>
				<hr class="my-5">
				<div class="sticky-left">
					<p>
						<span class=" fw-semibold text-uppercase">Note</span><br />
						<span class="fw-semibold">{{selectedBooking['salon']}} - {{branch['branch_name']}} will get in touch
							with you <span class="fw-semibold text-secondary">to confirm</span> your booking request.</span>
					</p>
					<div class="card shadow-sm" *ngIf="settings['services'].length > 0">
						<div class="card-body">
							<p class="text-secondary fw-semibold">Services</p>
	
							<ol class="list-unstyled list-services">
								<li *ngFor="let service of settings['services']; let i = index">{{service['label']}}</li>
							</ol>
						</div>
					</div>
	
					<div class="card shadow-sm mt-5" *ngIf="settings['deals'].length > 0">
						<div class="card-body">
							<p class="text-secondary fw-semibold">Deals</p>
	
							<ol class="list-unstyled list-services">
								<li *ngFor="let deal of settings['deals']; let i = index">{{deal['label']}}</li>
							</ol>
						</div>
					</div>
				</div>
			</aside>

			<!-- Main content -->
			<main class="col-12 col-lg-8">
				<div class="card shadow-sm my-5">
					<div class="card-body px-lg-6 py-lg-5">
						<h2 class="h2 fw-semibold mb-4 text-secondary">When and what time?</h2>

						<div class="content-date-picker mb-5">
							<p class="fs-5 fw-medium text-center mb-2" id="month-year">
								{{branch['available_booking_dates'][0] | date: 'MMMM y'}}</p>

							<div class="row justify-content-center">
								<div class="col-9 col-sm-10">

									<div id="flickity-date-picker"
										class="row row-date-picker gx-4 flickity flickity-date-picker">

										<!-- Arrows -->
										<div class="flickity-arrow flickity-prev">
											<button class="btn-flickity-prev">
												<i class="prev-button fa-solid fa-circle-chevron-left fa-xl fa-fw"></i>
											</button>
										</div>

										<div class="flickity-arrow flickity-next">
											<button class="btn-flickity-next">
												<i class="fa-solid fa-circle-chevron-right fa-xl fa-fw"></i>
											</button>
										</div>

										<!-- Slide -->
										<div class="slide" id="{{date}}"
											*ngFor="let date of branch['available_booking_dates']; let i = index">
											<div class="content-date-input" (click)="selectDate(date)">
												<input type="radio" id="date-{{date}}" name="opt_dates"
													class="date-input">
												<label for="date-{{date}}" class="date-label">
													<div class="card-date-box">
														<p class="fw-medium text-uppercase mb-0">{{ date | date : "E" }}
														</p>
														<p class="fs-3 fw-semibold mb-0">{{ date | date : "d" }}</p>
													</div>
												</label>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="content-time-picker mb-6" *ngIf="timeslots.length > 0">
							<p class="fs-5 fw-medium text-center mb-2">Pick a time slot</p>

							<div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-4">
								<div class="col" *ngFor="let time of timeslots; let i = index">
									<div class="content-time-input" (click)="selectTime(time['date'])">
										<input type="radio" id="time-{{ time['date'] | date : 'HH:mm a' }}"
											name="opt_times" class="time-input">
										<label for="time-{{ time['date'] | date : 'HH:mm a' }}" class="time-label">
											<div class="card-time-box">
												<p class="fs-6 fw-medium mb-0">{{ time['date'] | militaryToStandardTime
													}}</p>
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>

						<div [formGroup]="bookingDetails">
							<mat-form-field appearance="fill" class="w-100">
								<mat-label>Note to {{selectedBooking['salon']}}</mat-label>
								<textarea matInput rows="3" placeholder="Note to {{selectedBooking['salon']}}"
									formControlName="notes"></textarea>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card shadow-sm my-5">
					<div class="card-body px-lg-6 py-lg-5">
						<h2 class="h2 fw-semibold mb-4 text-secondary"
							*ngIf="is_authenticated == false && is_guest == false">How can we reach you?</h2>

						<h3 class="h3 fw-semibold" *ngIf="!is_guest && !is_authenticated">Sign in</h3>

						<form [formGroup]="sign_in" (ngSubmit)="submitSignin()"
							*ngIf="is_authenticated == false && is_guest == false">
							<mat-form-field appearance="fill">
								<mat-label>Email address</mat-label>
								<input matInput placeholder="Email address" formControlName="email">
							</mat-form-field>
							<mat-form-field appearance="fill">
								<mat-label>Password</mat-label>
								<input matInput placeholder="Password" id="login_password" formControlName="password"
									[type]="hidePasswordIcon ? 'password' : 'text'">
								<button (click)="hidePasswordIcon = !hidePasswordIcon" type="button" mat-icon-button
									matSuffix>
									<mat-icon>{{hidePasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
								</button>
							</mat-form-field>
							<div class="errors" *ngIf="inValidEmailOrPassword">
								<mat-error>
									Incorrect email or password.
								</mat-error>
							</div>
							<button [disabled]="!sign_in.valid || sign_submitted" mat-flat-button color="secondary"
								class="mb-5 w-100 py-2 mt-2" type="submit">
								SIGN IN
							</button>
						</form>

						<div class="row" *ngIf="!is_guest && !is_authenticated">
							<div class="col-register">
								<h3 class="h3 fw-semibold mb-2">Register now</h3>

								<p class="mb-6">Create your Parlon account and enjoy beautiful surprises. Get instant
									<span class="fw-semibold">PHP 250 voucher</span> when
									you register + Parlon candies (revealing this soon!) 🍬 You're welcome!
								</p>

								<button mat-flat-button color="secondary" class="py-2 w-100 mb-4"
									(click)="openRegisterModal()">
									CREATE ACCOUNT
								</button>
								<button (click)="continueAsGuest()" mat-stroked-button color="secondary"
									class="py-2 w-100 mb-4">
									CONTINUE AS GUEST
								</button>
							</div>
						</div>

						<div class="row mt-5" *ngIf="is_authenticated == true">
							<div class="col-md-12">
								<!-- <h2 class="text-secondary">Welcome back, {{user['user']['first_name']}}</h2> -->
								<h2 class="h2 fw-semibold mb-4 ">Welcome back, {{user['user']['first_name']}}!</h2>
							</div>
						</div>

						<div class="row" *ngIf="is_guest == true">
							<div class="col-md-10">
								<h3 class="h3 fw-semibold mb-4 mb-md-6">How can we reach you?</h3>
								<form [formGroup]="bookingDetails">
									<mat-form-field appearance="fill">
										<mat-label>First name</mat-label>
										<input matInput placeholder="First name" formControlName="first_name">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Last name</mat-label>
										<input matInput placeholder="Last name" formControlName="last_name">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Contact number</mat-label>
										<input matInput placeholder="Contact number" formControlName="contact_number">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Email address</mat-label>
										<input matInput placeholder="Email address" formControlName="email">
									</mat-form-field>
								</form>
							</div>
						</div>
					</div>
				</div>
				<!-- <hr class="my-4"> -->

				<div class="card shadow-sm my-5">
					<div class="card-body px-lg-6 py-lg-5">

						<div class="row">
							<div class="col-12">
								<div class="mb-2">
									<!-- Insert login/register form here... -->
								</div>

								<div class="mb-5">
									<div
										*ngIf="settings['payment_required'] == true && selectedVoucher == '' && total_price > 0">
										<h3 class="h2 fw-semibold mb-4"><span
												*ngIf="settings['payment_type'] == 'dp'">Downpayment</span><span
												*ngIf="settings['payment_type'] == 'full'">Full Payment</span></h3>
										<div [innerHTML]="branch['booking_prepayment_description']"></div>

										<div class="mt-7 pb-4">
											<b>No-show or Cancellation Policy of {{selectedBooking['salon']}}</b>
											<div [innerHTML]="branch['booking_prepayment_cancellation_description']">
											</div>
										</div>

										<div class="content-dp-value mt-5 ">
											<p class="fw-semibold mb-0"><span
													*ngIf="settings['payment_type'] == 'dp'">Downpayment</span><span
													*ngIf="settings['payment_type'] == 'full'">Full Payment</span></p>
											<p class="fw-semibold text-end mb-0">₱<del
													*ngIf="settings['payment_amount_required'] != total_price">{{settings['payment_amount_required']
													|
													number}}</del>&nbsp;<span>{{total_price |
													number}}</span></p>
										</div>
									</div>


									<mat-tab-group class="mt-5" (selectedTabChange)="voucherTabChange($event)">
										<mat-tab label="Select Deal"
											*ngIf="vouchers.length>0 && settings['payment_required'] == true">
											<div class="mt-5">
												<h3 class="font-weight-bold">Select deal purchased</h3>
												<span>Select a deal to book an appointment</span>
												<mat-form-field appearance="fill"
													class="w-100 mt-2 select-deal rounded">
													<mat-label>Select a deal</mat-label>
													<mat-select [(ngModel)]="selectedVoucher"
														(ngModelChange)="voucherEvent()">
														<mat-option *ngFor="let voucher of vouchers"
															[value]="voucher.voucher_code">
															{{voucher.deal.name}} ({{voucher.voucher_code}})
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</mat-tab>
										<mat-tab label="Enter Code"
											*ngIf="settings['payment_required'] == true && settings['payment_amount_required'] > 0">
											<div class="container-tooltip mt-3">
												<div id="voucher-tooltip" class="card p-3 tool-tip mt-2"
													style="background-color: #dbefef; display: none;">
													<div class="card p-3">
														<span class="text-center font-weight-bold"
															style="color: #f8a5a7;">VOUCHER CODE</span>
														<p class="text-center font-weight-bold">PBF-XXXXXXXXXX-XXXX</p>
													</div>

													<div class="bottom-arrow">


													</div>
												</div>
												<div *ngIf="settings['payment_required'] == true || promoCode != '' "
													class="mt-4">
													<div class="d-flex align-items-center voucher-enter">
														<span class="font-weight-bold fs-3 me-2">Enter Code</span>
													</div>

													<span>
														<span class="fw-semibold mt-3">Got an active Parlon deal?</span>
														<br>
														Good news! No need to place payment when you enter your Parlon
														E-Voucher code. <br><br>

														<span class="fw-semibold">Got a service discount code?</span>
														<br>
														Enter the code and enjoy some savings when you prepay.
													</span>
												</div>




												<div *ngIf="settings['payment_required'] == true || promoCode != ''"
													class="mt-3">
													<form [formGroup]="promoCodeForm" (ngSubmit)="applyCode()">


														<div style="background-color:#fafafa; padding: 1rem;">
															<div class="apply-code-grid">

																<mat-form-field class="fld" [floatLabel]="'never'"
																	appearance="outline">
																	<input formControlName="code" matInput
																		placeholder="Enter code"
																		[readonly]="promoCode != ''">
																	<mat-spinner *ngIf="isLoadingApplyVoucher" matSuffix
																		[diameter]="18"
																		style="float: right; margin-left: 8px"></mat-spinner>
																	<mat-icon style="color:#46b0a9; "
																		*ngIf="promoCode != ''" matSuffix
																		class="my-icon">check_circle</mat-icon>
																	<mat-icon style="color:#FF5449; "
																		*ngIf="showInvalidPromoCode" matSuffix
																		class="my-icon">cancel</mat-icon>

																</mat-form-field>
																<!-- <input class="promo-code" matInput placeholder="Enter code" formControlName="code">													 -->


																<button class="rounded-apply-code"
																	*ngIf="promoCode == '' "
																	[disabled]="promoCodeForm.invalid || isLoadingApplyVoucher"
																	mat-stroked-button color="secondary">APPLY
																	CODE</button>
																<button class="rounded-apply-code"
																	*ngIf="promoCode != '' " type="button"
																	(click)="removeCode()" mat-stroked-button
																	color="secondary">Remove code</button>
															</div>

															<mat-error class="mt-3" *ngIf="showInvalidPromoCode">
																<div [innerHTML]="invalidPromoCodeMessage"
																	class="error-msg"></div>
															</mat-error>
														</div>


													</form>
												</div>


											</div>
										</mat-tab>
									</mat-tab-group>



									<div *ngIf="settings['payment_required'] == true && selectedVoucher == '' && total_price > 0"
										class="payment-method-accordion mt-5">
										<mat-accordion>
											<mat-radio-group>
												<ng-container *ngFor="let channel of payment_channels; let i = index;">

													<mat-expansion-panel
														*ngIf="channel.template== 'custom_credit_card'">
														<mat-expansion-panel-header
															(click)="paymentOptionClick(channel.channel_code)">
															<div
																class="d-flex w-100 justify-content-between row-payment-channel  gx-4">
																<div class="col">
																	<mat-radio-button value="{{channel?.channel_code}}">
																		{{channel.channel_name}}
																	</mat-radio-button>
																</div>
																<div class="col-auto">
																	<div class="card-logo">
																		<img src="{{resource + channel.logo}}">
																	</div>
																</div>
															</div>
														</mat-expansion-panel-header>

														<div>
															<p class="mb-2">Your payment shall be processed safely and
																securely by a third-party payment gateway provider. For
																your security, we do not store your credit card
																information.</p>
															<credit-card-form
																[paymentFormGroup]="checkoutForm.get('paymentFormGroup')"></credit-card-form>
														</div>

													</mat-expansion-panel>

													<mat-expansion-panel class="nopad"
														*ngIf="channel.template== 'generic'">
														<mat-expansion-panel-header
															(click)="paymentOptionClick(channel['channel_code'])">
															<div class="w-100 row-payment-channel">
																<div class="row">
																	<mat-radio-button
																		value="{{channel['channel_code']}}">
																		<div> {{channel['channel_name']}}</div>
																	</mat-radio-button>
																</div>
																<div>
																	<div class="card-logo ps-2 pe-2">
																		<img src="{{resource + channel['logo']}}">
																	</div>
																</div>
															</div>
														</mat-expansion-panel-header>
													</mat-expansion-panel>
												</ng-container>
											</mat-radio-group>
										</mat-accordion>
									</div>
								</div>

								<div class="mb-5">
									<mat-checkbox id="acceptTerms" color="primary" [(ngModel)]="userAgree"
										(ngModelChange)="changeModelUserAgreement()">
										By clicking the Confirm Booking Request button, I confirm that I have read and I
										agree to Parlon's <a href="https://legal.parlon.ph/post/terms-of-use"
											target="_blank" style="display: inline-block; color:#46b0a9;"
											class="nav-link link-text fw-medium">Terms of Use</a> and Parlon's
										<a href="https://legal.parlon.ph/post/privacy-notice" target="_blank"
											style="display: inline-block; color:#46b0a9;"
											class="nav-link link-text fw-medium">Privacy Notice.</a>
									</mat-checkbox>
								</div>
								<mat-error class="my-3">
									<div class="error-msg">{{submit_error}}</div>
								</mat-error>

								<button [disabled]="!bookingValidate() || isLoading" mat-flat-button color="secondary"
									class="py-2 w-100 mb-4 d-flex justify-content-center align-items-center"
									(click)="submitRequest()">
									<span>CONFIRM BOOKING REQUEST </span>
									<mat-spinner *ngIf="isLoading" color="accent" style="display: inline-block;"
										[diameter]="25"></mat-spinner>
								</button>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</section>

<form id="cc-form" style="display: none;">
	<input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br />
	<input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br />
	<input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br />
	<input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
</form>

<ngx-smart-modal [customClass]="'wide-modal-custom'" #registerModal identifier="registerModal" [dismissable]="false">
	<ng-container>
		<div class="modal-body p-2">
			<div class="row">
				<div class="col">
					<app-registration-form (registerEmitter)="successRegister($event)"></app-registration-form>
				</div>
			</div>
		</div>
	</ng-container>

</ngx-smart-modal>

<ngx-smart-modal [customClass]="'wide-modal-custom'" #otpModal identifier="otpModal" [dismissable]="false">
	<ng-container>
		<div class="modal-body p-2">
			<div class="row">
				<div class="col">
					<app-otp-form (otpEmitter)="otpEvent($event)"></app-otp-form>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>