<!-- PLEASE COPY THE ```deal-card-template``` GLOBAL COMPONENT HERE... --><!--
	NOTE:
	- To edit deal savings banner's background and text color, use `--deal-card-savings-banner-bg` and `--deal-card-savings-banner-color`; set it on the section where it's included.
-->

<div class="card card-deal-template" [ngClass]="templateClass">
		
	<div class="card-body pos-relative p-0">
		<a  [routerLink]="'/beauty-bag-deals/' + data['slug']" [attr.data-pid]="data['id']" [queryParams]="{ objectID: data['objectID'], 
		position:position, searchInput:data['searchInput'],queryID:queryID }"   class="stretched-link">
			<div class="ratio ratio-16x9 mt-3 mt-sm-4 mb-3">
				<img src="{{'https://ik.imagekit.io/parlon/' + data['primary_photo']}}?tr=w-350" alt="" loading="lazy" class="img-fluid obj-fit-cover">

				<!-- Remove this part if used in Featured Merchant Deals section -->
				<div class="deal-parlon-logo">
					<div class="ratio ratio-1x1">
						<img src="{{resource + data['parlon_logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
					</div>
				</div>
			</div>
		</a>

		<div class="content px-2 px-sm-3">
			<p class="deal-parlon small text-muted w-md-80 mb-0">{{parlon_name}}</p>
			<p class="deal-parlon-location smaller text-muted clamp-1 mb-1">
				<i class="fas fa-location-dot fa-fw me-1"></i>
				<ng-container *ngFor="let location of locations; let i = index">
					<span  [class.fw-bold]="checkLocation(location)">{{location}}</span> <ng-container *ngIf="i != locations.length - 1">, </ng-container>
				</ng-container>
			</p>
			<p class="deal-parlon small text-muted w-md-80 mb-0" *ngIf="data['distance'] != undefined">{{data['distance']['geoDistance'] | number }} m away from you</p>
			<p class="deal-name fs-6 fw-medium clamp-3 clamp-md-5 mb-5">{{data['deal_name']}}</p>

			<p class="deal-price fw-medium">₱ {{data['deal_price'] | number}}<span class="og-price ms-2">₱ {{data['orig_price']|number}}</span></p>
		</div>


		<div class="deal-savings-banner">
			<p *ngIf="data['discount_type'] == 'Percentage Discount'">{{ data['discount_value'] | number }}% OFF</p>
			<p *ngIf="data['discount_type'] == 'Value Discount'">Save PHP {{ data['discount_value'] | number }}</p>
		</div>
	</div>
</div>