import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SalonsService } from '../../services/salons/salons.service';
import { ContentService } from '../../services/content/content.service';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent implements OnInit {

  resource = environment.resource;
  field = "?hasBooking";
  alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  salonList: any = [];
  pageData;

  constructor(
    private SalonsService: SalonsService,
    private ContentService: ContentService
  ) { }


  ngOnInit() {
    this.getPageContent();
    this.getSalonList();
  }

  getPageContent(){
    const app = this;
    this.ContentService.getBookingListing().subscribe((data: {}) => {
      app.pageData = data['data'];
    });
  }

  getSalonList(){
    const app = this;
    this.SalonsService.getSalons(this.field).subscribe((data: {}) => {
      let salons = data['data'];
      this.alphabet.forEach(function (letter) {
        let obj = {
          letter: '',
          salons: []
        };
        obj.letter = letter;
        salons.forEach(function (salon, index) {
          const salon_name = salon['business_name'];
          if(obj.letter == (salon_name.charAt(0)).toUpperCase()) {
            obj.salons.push(salon);
            salons.splice(index, 1);
          }
        });
        app.salonList.push(obj);
      });
    });
  }

}
