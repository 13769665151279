import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class RestService  {

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }
 

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getCategory(category): Observable<any> {
    return this.http.get(endpoint + 'categories/' + category, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError('getCategory'))
    );
  }

  getCategories(fields = ""): Observable<any> {
    return this.http.get(endpoint + 'categories' + fields, httpOptions).pipe(
      map(this.extractData));
  }

  getParlons(fields = ""): Observable<any> {
    return this.http.get(endpoint + 'parlons' + fields, httpOptions).pipe(
      map(this.extractData));
  }

  getParlon(fields = "", showHidden = ""): Observable<any> {
    return this.http.get(endpoint + 'parlons/' + fields + showHidden, httpOptions).pipe(
      map(this.extractData),
      catchError(e => {
        // this.router.navigate(['/404']);
        return of(null);
      }));
  }

  getBranch(branch, showHidden = ""): Observable<any> {
    return this.http.get(endpoint + 'branches/' + branch + "?relationships" + showHidden, httpOptions).pipe(
      map(this.extractData),
      catchError(error => {
        // this.router.navigate(['/404']);
        return of(null);
      }));
  }

  getCategoryParlons(category): Observable<any> {
    return this.http.get(endpoint + 'categories/' + category + '/parlons', httpOptions).pipe(
      map(this.extractData));
  }

  getFeaturedDeals(): Observable<any> {
    return this.http.get(endpoint + 'deals', httpOptions).pipe(
      map(this.extractData));
  }

  getHomepageDeals(): Observable<any> {
    return this.http.get(endpoint + 'deals/get-home-deals', httpOptions).pipe(
      map(this.extractData));
  }

  getDealsLocations(): Observable<any> {
    return this.http.get(endpoint + 'deals/get-locations-with-deals', httpOptions).pipe(
      map(this.extractData));
  }

  getFeaturedServices(): Observable<any> {
    return this.http.get(endpoint + 'featured-services?relationships', httpOptions).pipe(
      map(this.extractData));
  }

  getParlonBeauties(fields = ""): Observable<any> {
    return this.http.get(endpoint + 'parlon-beauties' + fields, httpOptions).pipe(
      map(this.extractData));
  }

  getLocations(): Observable<any> {
    return this.http.get(endpoint + 'locations', httpOptions).pipe(
      map(this.extractData));
  }

  search(value, location = "all"): Observable<any> {
    location = (location == "all" ? "" :  "/" + location );
    return this.http.get(endpoint + 'search/' + value + location, httpOptions).pipe(
      map(this.extractData));
  }

  searchByLocation(location): Observable<any> {
    return this.http.get(endpoint + 'search-by-location/' + location, httpOptions).pipe(
      map(this.extractData));
  }

  searchEmpty(): Observable<any> {
    return this.http.get(endpoint + 'search-empty', httpOptions).pipe(
      map(this.extractData));
  }

  submitPartnership(data): Observable<any> {
    return this.http.post(endpoint + 'receiver/partnership', data, httpOptions).pipe(
      map(this.extractData));
  }

  submitContact(data): Observable<any> {
    return this.http.post(endpoint + 'receiver/contact', data, httpOptions).pipe(
      map(this.extractData));
  }

  pageViews(data): Observable<any> {
    return this.http.post(endpoint + 'pageviews ', data, httpOptions).pipe(
      map(this.extractData));
  }

  bookService(url): Observable<any> {
    return this.http.post(url, {}, httpOptions).pipe(
      map(this.extractData),
    );
  }

  homepageSectionVisibility(): Observable<any> {
    return this.http.get(endpoint + 'settings/get-homepage-visibility', httpOptions)
    .pipe(map(this.extractData))
  }

  getParlonsWithBooking(relationship = ""): Observable<any> {
    return this.http.get(endpoint + 'parlons' + relationship, httpOptions).pipe(
      map(this.extractData));
  }

  getDealDetails(fields = ""): Observable<any> {
    return this.http.get(endpoint + 'deals' + fields, httpOptions).pipe(
      map(this.extractData),
      catchError(e => {
        return of (null)
      }));
  }

  getDealStatus(data): Observable<any> {
    return this.http.post(endpoint + 'deals/get-deal-purchase-status', data, httpOptions).pipe(
      map(this.extractData));
  }

  getPaymentToken(data): Observable<any> {
    return this.http.post(endpoint + 'payment-link/validate-payment-link-token', data, httpOptions).pipe(
      map(this.extractData),
      catchError(e => {
        // this.router.navigate(['/404']);
        return of(null);
      }));
  }

  getDealPartners(): Observable<any> {
    return this.http.get(endpoint + 'deals/get-voucher-partners').pipe(
      map(this.extractData));
  }

  getFeaturedBlogs(): Observable<any> {
    return this.http.get(endpoint + 'blog-linkouts', httpOptions).pipe(
      map(this.extractData));
  }

  getUserBookings(): Observable<any> {
    return this.http.get(endpoint + 'user/booking').pipe(
      map(this.extractData));
  }

  // eCommerce deals/ beauty festival deals
  getAllDealsByParlon(): Observable<any> {
    return this.http.get(endpoint + 'deals/get-all-deals-by-parlon', httpOptions).pipe(
      map(this.extractData),
      catchError(e => {
        return of (null)
      })
    )
  }
  getAllDealsByCategory(): Observable<any> {
    return this.http.get(endpoint + 'deals/get-all-deals-by-category', httpOptions).pipe(
      map(this.extractData),
      catchError(e => {
        return of (null)
      })
    )
  }
  getAllDealsBySortOptions(sortBy = "", sequence = ""): Observable<any> {
    return this.http.get(endpoint + 'deals/get-all-deals-by-sort-sequence?sortBy=' + sortBy + 
    "&sequence=" + sequence, httpOptions).pipe(
      map(this.extractData), catchError(e => {
        return of (null)
      })
    )
  }

  // settings
  getPageBanner(): Observable<any> {
    return this.http.get(endpoint + 'settings/site-banner', httpOptions).pipe(
      map(this.extractData), 
      catchError(e => {
        return of (null)
      })
    )
  }

  getHomePageBanner(): Observable<any> {
    return this.http.get(endpoint + 'settings/homepage-banner', httpOptions).pipe(
      map(this.extractData), 
      catchError(e => {
        return of (null)
      })
    )
  }

  resourceLink() {
  //  return "https://s3-ap-southeast-1.amazonaws.com/parlon/";
   return environment.resource;
  }

  applyDealPromoCode(data): Observable<any> {
    return this.http.post(endpoint + 'deals/apply-promo-code', data, httpOptions).pipe(
      map(this.extractData));
  }

  applyMembershipPromoCode(data): Observable<any> {
    return this.http.post(endpoint + 'membership/apply-promo-code', data, httpOptions).pipe(
      map(this.extractData));
  }

  // deal payment request
  createPaymentRequest(data): Observable<any> {
    return this.http.post(endpoint + 'deals/create-payment-request', data, httpOptions).pipe(
      map(this.extractData));
  }

  createMembershipPaymentRequest(data): Observable<any> {
    return this.http.post(endpoint + 'membership/create-payment-request', data, httpOptions).pipe(
      map(this.extractData));
  }

  // payment request
  paymentRequest(data): Observable<any> {
    return this.http.post(endpoint + 'payment-link/create-payment-request', data, httpOptions).pipe(
      map(this.extractData));
  }

  getMembershipPurchaseStatus(data): Observable<any> {
    return this.http.post(endpoint + 'membership/get-membership-purchase-status', data, httpOptions).pipe(
      map(this.extractData));
  }

  getPaymentStatus(data): Observable<any> {
    return this.http.post(endpoint + 'payment-link/get-payment-link-purchase-status', data, httpOptions).pipe(
      map(this.extractData));
  }

  getPaymentChannels(): Observable<any> {
    return this.http.get(endpoint + 'settings/payment-channels', httpOptions).pipe(
      map(this.extractData));
  }

  // Glife request
  getParlonGlife(parlonSlug = ""): Observable<any> {
    return this.http.get(endpoint + 'glife/get-parlon-glife-data/' + parlonSlug, httpOptions).pipe(
      map(this.extractData));
  }

  getParlonBranchGlife(branchSlug = ""): Observable<any> {
    return this.http.get(endpoint + 'glife/get-parlon-branch-glife-data/' + branchSlug, httpOptions).pipe(
      map(this.extractData));
  }

  glifePayCheckout(data): Observable<any> {
    return this.http.post(endpoint + 'glife/submit-glife-checkout-data', data, httpOptions).pipe(
      map(this.extractData));
  }

  glifeBookingTime(data): Observable<any> {
    return this.http.post(endpoint + 'fetchTime', data, httpOptions).pipe(
      map(this.extractData));
  }

  glifeUserInfo(data): Observable<any> {
    return this.http.post(endpoint + 'glife/user-info', data, httpOptions);
  }


  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // send the error to remote logging infrastructure
      console.error(error); // log to console instead

      //  better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      this.router.navigate(['/']);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
