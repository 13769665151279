import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MaterialModule } from '../../material';
import { RouterModule } from '@angular/router';
import { NgxSiemaModule } from 'ngx-siema';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LightboxModule } from 'ngx-lightbox';

import { ParlonInnerComponent } from './parlon-inner.component';
import { ParlonPhotosSliderComponent } from './components/parlon-photos-slider/parlon-photos-slider.component';
import { ParlonDetailsComponent } from './components/parlon-details/parlon-details.component';
import { TabPanelComponent } from './components/tab-panel/tab-panel.component';
import { BranchListComponent } from './components/branch-list/branch-list.component';
import { ParlonServicesComponent } from './components/parlon-services/parlon-services.component';
import { ServiceCategoryCheckComponent } from './components/parlon-services/service-category/service-category-check.component';
import { ServiceCategoryComponent } from './components/parlon-services/service-category/service-category.component';
import { ServiceItemComponent } from './components/parlon-services/service-item/service-item.component';
import { ServiceLooperComponent } from './components/parlon-services/service-looper/service-looper.component';
import { ParlonInnerLoaderComponent } from './components/parlon-inner-loader/parlon-inner-loader.component';
import { ParlonPhotosComponent } from './components/parlon-photos/parlon-photos.component';
import { ParlonHoursComponent } from './components/parlon-hours/parlon-hours.component';
import { BranchFilterComponent } from './components/branch-filter/branch-filter.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { ParlonPromosComponent } from './components/parlon-promos/parlon-promos.component';
import { ParlonPressComponent } from './components/parlon-press/parlon-press.component';
import { WmDealsComponent } from './components/wm-deals/wm-deals.component';
import { WmNonparticipantComponent } from './components/wm-nonparticipant/wm-nonparticipant.component';
import { PromoBannerComponent } from './components/promo-banner/promo-banner.component';
import { ParlonDealsComponent } from './components/parlon-deals/parlon-deals.component';
import { BranchLocationListComponent } from './components/branch-location-list/branch-location-list.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ParlonGoogleReviewComponent } from './components/parlon-google-review/parlon-google-review.component';
import { GoogleReviewCardComponent } from './components/google-review-card/google-review-card.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import {MatDialogModule} from '@angular/material/dialog';
import { BookDealsSectionComponent } from './components/book-deals-section/book-deals-section.component';
@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSiemaModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    LightboxModule,
    NgxSmartModalModule, 
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    MatDialogModule
  ],
  declarations: [
    ParlonInnerComponent, 
    ParlonPhotosSliderComponent, 
    ParlonDetailsComponent, 
    TabPanelComponent, 
    BranchListComponent, 
    ParlonServicesComponent, 
    ServiceCategoryCheckComponent, 
    ServiceCategoryComponent, 
    ServiceItemComponent, 
    ServiceLooperComponent, 
    ParlonInnerLoaderComponent,
    ParlonPhotosComponent, 
    ParlonHoursComponent, 
    BranchFilterComponent, 
    TermsOfUseComponent, 
    ParlonPromosComponent, 
    ParlonPressComponent, 
    WmDealsComponent, 
    WmNonparticipantComponent, 
    PromoBannerComponent, 
    ParlonDealsComponent,
    BranchLocationListComponent,
    ParlonGoogleReviewComponent,
    GoogleReviewCardComponent,
    LightboxComponent,
    BookDealsSectionComponent
  ],
  entryComponents:[
    LightboxComponent
  ]
})
export class ParlonInnerModule { }
