import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit , Inject} from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../../services/shared.service';

@Component({
  selector: 'app-parlon-hours',
  templateUrl: './parlon-hours.component.html',
  styleUrls: ['./parlon-hours.component.scss']
})
export class ParlonHoursComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window, private rest: RestService, private route: ActivatedRoute, private _shared: SharedService, public router: Router ) { }

  branch: any[];
  parlon: any[];
  resourceLink = this.rest.resourceLink();
  website = null;

  ngOnInit() {
    this._shared.currentBranch.subscribe((branch) => {
      this.branch = branch;
    });
    this._shared.currentParlon.subscribe((parlon) => {
      this.parlon = parlon;
      if(this.parlon['website'] != null) {
        this.website = this.parlon['website'].replace(/(^\w+:|^)\/\//, '');
      }
    });
  }

  convertToDate(value) {
    return this._shared.convertToDate(value);
  }

}
