import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['./personal-details-form.component.scss']
})
export class PersonalDetailsFormComponent implements OnInit {

  loading = false;
  submitted = false;
  hide = true;

  @Input() personalDetailsFormGroup: FormGroup;

  constructor() { }

  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.personalDetailsFormGroup.controls;
  }
}
