import { Component, OnInit } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit {

  resourceLink = this.rest.resourceLink();
  token: string;
  paymentData: any = [];
  isLoaded = false;

  

  constructor(
    private rest: RestService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getTokenData();
  }

  getTokenData() {
    let page = this;
    page.isLoaded = false;
    this.token = this.route.snapshot.paramMap.get("token");
    this.rest.getPaymentToken({token:this.token}).subscribe((data: {}) => {
      page.paymentData = data;
      console.log(this.paymentData);
      page.isLoaded = true;
    });
  }

}
