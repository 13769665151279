<section class="s-book-appointment-message bg-light-1 py-8 py-md-9 d-flex align-items-center justify-content-center"
	style="--section-bg: url('https://parlon.s3.ap-southeast-1.amazonaws.com/BookingBanners/Booking%20Banner%20Desktop%20%281920%20x%20450px%29.png')">

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-lg-9 col-xl-9">
				<div class="card bg-white">
					<div class="card-body px-sm-8 pt-sm-6 pb-8">
						
						<div class="row justify-content-center">
							<div class="col-12 col-lg-11 col-xl-10">
								<div>
                  <div class="text-center">
                    <img src="/assets/images/booking-success.png" width="160" class="mb-4">
                    <h1 class="h1 fw-semibold text-heading mb-5">Your booking request has been sent to the respective salons!</h1>
    
                    <p class="fs-6 fw-medium">Get ready to enjoy a self-care session!</p>
                    <p class="mb-3">You have successfully placed a booking request. The salons will get in touch with you to confirm your booking.</p>
                    <p>Thank you for booking through Parlon.</p>
                  </div>

                  <hr>

                  <div class="row pt-5">
                    <div class="col-md-6 mb-4" *ngFor="let booking of response['bookings'];">
                      <div class="p-4 shadow-sm">
                        <span class="badge badge-success my-2" *ngIf="booking['status'] == 'Confirmed'">CONFIRMED</span>
                        <span class="badge badge-pending my-2" *ngIf="booking['status'] == 'Pending'">PENDING</span>
                        <div class="d-flex align-items-center mb-3">
                          <img src="{{resource + booking['parlon']['logo']}}" width="50" loading="lazy">
                          <p class="ms-3 mb-0 fw-semibold">{{booking['parlon']['name']}} - {{booking['parlon']['branch']}}</p>
                        </div>
                        <p class="mb-0 text-left mb-0 text-muted">Service</p>
                        <p class="mb-4" *ngFor="let service of booking['services'];">
                          {{service['name']}}
                        </p>
                        <p class="mb-0 text-left  mb-2 text-muted">Booking Details</p>
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="ms-3 mb-0"><i class="fa-regular fa-calendar text-primary me-3"></i>{{booking['date']}}</p>
                          <p class="ms-3 mb-0"><i class="fa-regular fa-clock text-primary me-3"></i>{{booking['time']}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
								</div>

								<div class="mt-6">
									<button [routerLink]="['/']" mat-stroked-button color="secondary" class="text-uppercase d-block w-80 w-sm-75 w-lg-60 mx-auto py-2">
										Back to home
									</button>
								</div>
							</div>
						</div>
	
					</div>
				</div>
			</div>
		</div>
	</div>
</section>