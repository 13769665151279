import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { env } from 'process';

const endpoint = environment.api;
const maya_token = environment.maya_token;
const maya_url = environment.maya_url;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class DealsService {

  private activeDeal = new BehaviorSubject<Array<any>>([]);
  currentdeal = this.activeDeal.asObservable();
  private cartCountSubject = new BehaviorSubject<Number>(0);
  currentCountCart = this.cartCountSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }

    private extractData(res: Response) {
      let body = res;
      return body || { };
    }


    getDeal(fields = ""): Observable<any> {
      return this.http.get(endpoint + 'beauty-bag/deals/' + fields, httpOptions).pipe(
        map(this.extractData),
        catchError(e => {
          console.log('error');
          this.router.navigate(['/404']);
          return of (null)
        }));
    }

    getHomepageDeals(): Observable<any> {
      return this.http.get(endpoint + 'deals/get-home-deals', httpOptions).pipe(
        map(this.extractData));
    }

    getDealCollections(fields = ""): Observable<any> {
      return this.http.get(endpoint + 'deals/collection/' + fields, httpOptions).pipe(
        map(this.extractData));
    }

    getRandomDeals(fields = ""): Observable<any> {
      return this.http.get(endpoint + 'beauty-bag/random-deals' + fields, httpOptions).pipe(
        map(this.extractData));
    }

    changeActiveDeal(deal) {
      this.activeDeal.next(deal);
    }

    changeCartCount(count) {
      this.cartCountSubject.next(count);
    }

    addToBag(data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/add-to-bag', data, httpOptions).pipe(
        map(this.extractData));
    }

    applyPromo(data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/apply-promo', data, httpOptions).pipe(
        map(this.extractData));
    }

    removePromo(data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/remove-promo', data, httpOptions).pipe(
        map(this.extractData));
    }

    editBagItem(bagId,data): Observable<any> {
      return this.http.put(endpoint + 'beauty-bag/update/'+bagId, data, httpOptions).pipe(
        map(this.extractData));
    }

    deleteBagItem(bagId,data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/delete/'+bagId, data, httpOptions).pipe(
        map(this.extractData));
    }

    bagCheckout(data, key): Observable<any> {
      const header = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Idempotency-Key': key
        })
      }
      return this.http.post(endpoint + 'beauty-bag/checkout', data, header).pipe(catchError(this.handleError));
    }

    getMayaToken(data): Observable<any> {
      const header = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Basic '+ maya_token
          // 'Authorization': 'Basic '+ btoa(maya_token)
        })
      }
      return this.http.post(maya_url, data, header).pipe(
        map(this.extractData));
    }

    getPurchaseStatus(id = ""): Observable<any> {
      return this.http.get(endpoint + 'beauty-bag/purchase-status/' + id, httpOptions).pipe(
      map(this.extractData));
    }

    getBagItems(id = "", token = ""): Observable<any> {
      return this.http.get(endpoint + 'beauty-bag/items/' + id + token, httpOptions).pipe(
      map(this.extractData));
    }
    getDealsLocations(): Observable<any> {
      return this.http.get(endpoint + 'deals/get-locations-with-deals', httpOptions).pipe(
        map(this.extractData));
    }

    getParlonsWithDeals(): Observable<any> {
      return this.http.get(endpoint + 'deals/get-parlons-with-deals', httpOptions).pipe(
        map(this.extractData));
    }

    editBuyer(data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/edit-buyer', data, httpOptions).pipe(
        map(this.extractData));
    }

    applyCandies(data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/apply-candies', data, httpOptions).pipe(
        map(this.extractData));
    }

    removeCandies(data): Observable<any> {
      return this.http.post(endpoint + 'beauty-bag/remove-candies', data, httpOptions).pipe(
        map(this.extractData));
    }

    placesSearch(value): Observable<any> {
      return this.http.get(endpoint + 'google/places?search='+value+'&lat=14.599512&long=120.984222', httpOptions).pipe(
        map(this.extractData));
    }
    

    


    private handleError(error: HttpErrorResponse) {
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
      }
      // Return an observable with a user-facing error message.
      return throwError(error);
    }

}
