import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { NgxSiemaOptions } from 'ngx-siema';
import { Lightbox } from 'ngx-lightbox';
import { NgxSiemaService } from 'ngx-siema';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'parlon-photos-slider',
  templateUrl: './parlon-photos-slider.component.html',
  styleUrls: ['./parlon-photos-slider.component.scss']
})
export class ParlonPhotosSliderComponent implements OnInit {

  constructor(private rest: RestService, private _lightbox: Lightbox, private _ngxSiemaService: NgxSiemaService) { }

  @Input()
  branch: any[];
  resourceLink = environment.imagekit;
  private _albums: any = [];

  ngOnInit() {
    const page = this;
    this.branch['photo'].forEach(e => {
      const album = {
          src: this.resourceLink + e['image'],
          caption: '',
          thumb: this.resourceLink + e['image'],
      };
      page._albums.push(album);
    });
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  options: NgxSiemaOptions = {
    selector: '.siema',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 1,
      768: 3,
      1024: 4,
    },
    startIndex: 0,
    draggable: 'ontouchstart' in document.documentElement,
    threshold: 20,
    loop: false,
    onInit: () => {
      
    },
    onChange: () => {
      // runs after slide change
    },
  };

  prev() {
    this._ngxSiemaService.prev(1);
  }

  next() {
    this._ngxSiemaService.next(1);
  }

}
