import { FormControl } from '@angular/forms';

// custom validator to check that two fields match
export function mobileValidator(control:FormControl){
    if(!(control && control.value)) {
      // if there's no control or no value, that's ok
      return null;
    }
    if(/^(09|\+639)\d{9}$/gm.test(control.value)){
      return null
    }else{
      return {'invalidMobileNumber':true};
    }
}