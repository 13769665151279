import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class BeautyBashService {

  constructor(private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }

    private extractData(res: Response) {
      let body = res;
      return body || { };
    }

    getBashDeals(id): Observable<any> {
      return this.http.get(endpoint + 'bash-deals/get-beauty-bash-event-deals?event_id='+id, httpOptions).pipe(
        map(this.extractData));
    }

    getResult(id): Observable<any> {
      let header = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      }
      return this.http.get(endpoint + 'bash-deals/get-bash-purchase-by-reference-id/'+id, header).pipe(
        map(this.extractData));
    }

    getBashDeal(slug,event_id,token = null): Observable<any> {
      let header = {};
      if(token != null) {
        header = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer '+ token
          })
        }
      } else {
        header = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        }
      }
      console.log(header);
      return this.http.get(endpoint + 'bash-deals/get-bash-deal?bash_event_id='+event_id+'&bash_deal_slug='+slug, header).pipe(
        map(this.extractData));
    }

    requestPayment(data,token): Observable<any> {
      const header = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer '+ token
        })
      }
      return this.http.post(endpoint + 'bash-deals/create-payment-request', data, header).pipe(
        map(this.extractData));
    }
}
