<nav mat-tab-nav-bar class="nav-tabs nav-tabs-parlon-services">
  <a mat-tab-link [ngClass]="isActive('services')" (click)="navigate('services')">
    <img src="assets/images/services2-ic.png" alt="Services" class="tab-icon me-lg-2"><span class="tab-text">Services</span>
  </a>
  <a mat-tab-link [ngClass]="isActive('deals')" (click)="navigate('deals')" *ngIf="features['deals_&_beauty_bag'] == true">
    <i class="fas fa-badge-percent fa-lg fa-fw me-lg-2"></i><span class="tab-text">Deals</span>
  </a>
  <a mat-tab-link [ngClass]="isActive('photos')" (click)="navigate('photos')">
    <i class="fas fa-images fa-lg fa-fw me-lg-2"></i><span class="tab-text">Photos</span>
  </a>
  <a mat-tab-link class="d-lg-none" [ngClass]="isActive('hours')" (click)="navigate('hours')">
    <i class="fas fa-clock fa-lg fa-fw me-lg-2"></i><span class="tab-text">Hours</span>
  </a>
  <a *ngIf="googleReviewVisible" mat-tab-link [ngClass]="isActive('google-review')" (click)="navigate('google-review')">
    <i class="fas fa-star fa-lg fa-fw me-lg-2"></i><span class="tab-text">Google Reviews</span>
  </a>
  <!-- <a mat-tab-link  [ngClass]="isActive('covid19safety')" (click)="navigate('covid19safety')">
    <i class="fas fa-head-side-mask fa-lg fa-fw me-lg-2"></i><span class="tab-text">COVID-19 Safety</span>
  </a> -->
  <!-- <a mat-tab-link [ngClass]="isActive('press')" (click)="('press')">
    <img src="assets/images/press-ic.png" alt="Press" class="tab-icon me-lg-2"><span class="tab-text">Press</span>
  </a> -->
</nav>
