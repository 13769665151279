import { Component, Input, OnChanges, OnInit  } from '@angular/core';
import { RestService } from '../../../../services/rest.service';

@Component({
  selector: 'membership-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {

  constructor(
    private rest: RestService, 
  ) { }

  @Input() membership: any = [];
  @Input() parlon: any = [];
  @Input() promo: any = {};
  hasDiscount = false;
  price = 0;
  discount = 0;
  resourceLink = this.rest.resourceLink()

  ngOnInit() {
    
  }

  ngOnChanges() {
    let page = this;
    page.hasDiscount = false;
    setTimeout(() => {
      if(!(page.promo // 👈 null and undefined check
        && Object.keys(page.promo).length === 0
        && Object.getPrototypeOf(page.promo) === Object.prototype)) {
          if(this.promo != undefined) {
            page.hasDiscount = true;
            page.discount = this.promo['promo_discount'];
            page.price = this.promo['total'];
          } else {
          page.price = this.membership['price'];
          }
      } else {
        page.discount = 0;
        page.price = this.membership['price'];
      }
    }, 100);
    
  }

}
