<section class="s-spotify py-9 py-sm-8 py-md-9">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-11 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
				<div class="text-center">
					<h2 class="h2 fw-semibold mb-1 mb-md-2">{{data['spotify_playlist_title']}}</h2>
				</div>

				<iframe style="border-radius:12px" [src]="data['spotify_playlist_embed_code'] | safeUrl"
					width="100%" height="80" frameBorder="0" allowfullscreen=""
					allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" ></iframe>
			</div>
		</div>
	</div>
</section>