<swiper [config]="config">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let branch_images of photos">
        <div class="ratio ratio-swiper-img">
          <img src="{{ resourceLink + branch_images['image'] }}" alt="" class="img-fluid img-hero-banner-desktop w-100">
          <img src="{{ resourceLink + branch_images['image'] }}" alt="" class="img-fluid img-hero-banner-tablet w-100">
          <img src="{{ resourceLink + branch_images['image'] }}" alt="" class="img-fluid img-hero-banner-mobile w-100">
        </div>
    </div>
  </div>

  <!-- Pagination -->
  <div class="swiper-pagination"></div>

  <!-- Arrows -->
  <!-- <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div> -->
</swiper>