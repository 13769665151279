<!-- STEPPER -->
<form id="GlifeCheckoutForm" [formGroup]="GlifeCheckoutForm">
	<mat-vertical-stepper [linear]="isLinear" #stepper>

		<mat-step formGroupName="personalDetailsFormGroup" [stepControl]="GlifeCheckoutForm.get('personalDetailsFormGroup')">
		<ng-template matStepLabel>Personal Details</ng-template>

		<div class="">
			<app-personal-details-form [personalDetailsFormGroup]="GlifeCheckoutForm.get('personalDetailsFormGroup')"></app-personal-details-form>
		</div>

		<hr class="my-6">

		<div id="booking-section"></div>
		<mat-tab-group (selectedTabChange)="changeDetails($event)" [ngClass]="{'d-none': branch['glife_bookable'] === 'No'}">
			<mat-tab label="Book now">
				<div class="py-6">
					<p>Have a date in mind for your appointment? Place a booking request and {{branch['parlon']['T']}} will get in touch with you to confirm your appointment.</p>
					<h4 class="h4 text-center fw-semibold mb-2">When and what time?</h4>
					<p class="text-center fw-semibold mb-1">{{ currentDate | date: 'MMMM yyyy' }}</p>

					<!-- Booking date slider -->
					<div class="mb-5">
						<glife-booking-slider [branch]="branch" (bookingDetails)="changeBooking($event)"></glife-booking-slider>
					</div>

					
				</div>

				<hr class="mt-0">
			</mat-tab>

			<mat-tab label="Book later">
				<hr class="mt-6">
			</mat-tab>
		</mat-tab-group>

		</mat-step>

		<!-- <mat-step formGroupName="promoFormGroup" [stepControl]="GlifeCheckoutForm.get('promoFormGroup')">
			<ng-template matStepLabel>Promo Code</ng-template>

			<div class="card bg-light mt-5">
				<div class="card-body">
				<h5 class="h5 fw-medium-4">Apply Promo Code</h5>

			
				<app-promo-code [promoFormGroup]="GlifeCheckoutForm.get('promoFormGroup')"></app-promo-code>
				</div>
			</div>

			<div class="mt-3 mb-6">
				<mat-checkbox id="eVoucherPolicy" color="primary" formControlName="eVoucherPolicy">
				By clicking the Buy Now button, I confirm that I have read and agree to <a href="https://legal.parlon.ph/post/terms-of-use"
					target="_blank">Parlon Terms of Use</a>, E-Voucher Additional Terms and Conditions abovementioned, and <a
					href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
				</mat-checkbox>
			</div>

			<div class="mt-3 mb-6" *ngIf="isTransactionFailed == true">
				<p class="text-danger"><strong>Oh no, we failed to process your payment / payment processing failed. </strong><br>
				We were unable to charge your account for this transaction, bestie. Kindly double check your details (if you are using a credit/debit card,
				make sure you are entering the correct expiry date format) or choose another payment method. Then, try again. Thank you!
				</p>
			</div>

			<div class="d-grid">
				<button mat-flat-button color="secondary" class="p-3" type="submit" [disabled]="loading" (click)="onSubmit()">
				<span *ngIf="isTotalZero == false">BUY NOW PHP {{ total | number }}</span>
				<span *ngIf="isTotalZero == true">CLAIM YOUR FREE DEAL</span>
				<mat-icon *ngIf="loading">
					<mat-spinner color="accent" diameter="20">
					</mat-spinner>
				</mat-icon>
				</button>
			</div>
		</mat-step> -->

	</mat-vertical-stepper>

	<div class="card bg-light mt-5">
		<div class="card-body" [formGroup]="GlifeCheckoutForm">
			<h5 class="h5 fw-medium-4">Apply Promo Code</h5>

			<!-- PROMO CODE -->
			<mat-form-field appearance="fill" [floatLabel]="'never'" class="w-100" formGroupName="promoFormGroup">
        <mat-label>Enter code</mat-label>
        <input matInput placeholder="Enter code" id="promoCode" name="promoCode" formControlName="promoCode" [value]="">
      </mat-form-field>
		</div>
	</div>

	<div class="mt-3 mb-6" formGroupName="promoFormGroup">
		<mat-checkbox id="eVoucherPolicy" color="primary" formControlName="eVoucherPolicy">
		By clicking the Buy Now button, I confirm that I have read and agree to <a href="https://legal.parlon.ph/post/terms-of-use"
			target="_blank">Parlon Terms of Use</a>, E-Voucher Additional Terms and Conditions and <a
			href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
		</mat-checkbox>
	</div>

	<div class="mt-3 mb-6" *ngIf="isTransactionFailed == true && failMessage != 'Invalid Promo Code'">
		<p class="text-danger"><strong>Oh no, we failed to process your payment / payment processing failed. </strong><br>
		We were unable to charge your account for this transaction, bestie. Kindly double check your details (if you are using a credit/debit card,
		make sure you are entering the correct expiry date format) or choose another payment method. Then, try again. Thank you!
		</p>
	</div>

	<div class="mt-3 mb-6" *ngIf="isTransactionFailed == true && failMessage == 'Invalid Promo Code'">
		<p class="text-danger"><strong>Oh no, we failed to process your payment / payment processing failed. </strong><br>
		Invalid Promo Code
		</p>
	</div>

	<div class="d-grid">
		<button mat-flat-button color="secondary" class="p-3" type="submit" [disabled]="loading" (click)="onSubmit()">
		<span *ngIf="isTotalZero == false">BUY NOW PHP {{ total | number }}</span>
		<span *ngIf="isTotalZero == true">CLAIM YOUR FREE DEAL</span>
		<mat-icon *ngIf="loading">
			<mat-spinner color="accent" diameter="20">
			</mat-spinner>
		</mat-icon>
		</button>
	</div>
</form>
