<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- To set number of slides, change it on CSS
	See example below.
-->

<section class="py-5 py-md-7 overflow-x-hidden">
	<div class="container">
		<div class="row align-items-center justify-content-between justify-content-md-start mb-4 mb-md-5">
			<div class="col-auto">
				<div class="d-flex align-items-center col-gap-3 col-gap-md-4 col-gap-lg-5">
					<img src="{{ resource + data['icon'] }}" alt="" class="img-fluid img-header-icon">
					<a href="{{data['linkout']}}" class="link-text">
						<h2 class="h2 fw-semibold mb-0">{{data['title']}}</h2>
					</a>
				</div>
			</div>

			<div class="col-auto">
				<a href="{{data['linkout']}}" class="link-secondary fs-2">
					<i class="fas fa-chevron-right fa-xs fa-fw"></i>
				</a>
			</div>

			<div class="col-12">
				<p class="text-header-subtitle mt-1 mb-0">{{data['subtitle']}}</p>
			</div>
		</div>

		<div id="flickity-ft-deals-{{index}}" class="flickity flickity-ft-deals flickity-overflow row gx-4 pt-5">
			<div class="slide" *ngFor="let deal of deals">

				<deal-card-template 
					[userID]="userId"
					[isLoggedIn]="isLoggedIn"
					[data]="deal" 
					[parlon_name]="deal['parlon_name']"
					[locations]="getLocations(deal['locations'])">
				</deal-card-template>
			</div>
		</div>
	</div>
</section>