<div class="bg-white">
	<h5 class="service-category-title px-2 px-sm-0" [class.center]="data['parent'] == 0">{{data['label']}}</h5>
	<div *ngIf="data['description'] !== ''" class="header-description fw-normal px-2 px-sm-0" [innerHTML]="data['description']"></div>

	<div class="services-container" *ngIf="data['headers'].length > 0 || data['services'].length > 0">
		<table class="table table-striped table-borderless services">
			<tbody>
				<tr *ngIf="data['headers'].length > 0" class="services-header-wrapper">
					<th class="td-filler"></th>
					<th *ngFor="let header of data['headers']" class="header-label">{{ header }}</th>
				</tr>

				<tr *ngFor="let service of data['services']; let i = index" class="service-item">
					<th class="name" *ngIf="service['type'] == 'Service' && service['visibility'] == 'show'">
						<div class="d-flex align-items-center">
							<ng-container 
								*ngIf="
									branch['booking_settings'] != null
									&& branch['has_booking'] == 'Yes'
									&& data['headers'].length === 1
								"
							>
								<service-category-check
									[headerId]="service.headers[0].id"
									[selectedServices]="selectedServices$ | async"
									[serviceId]="service.id"
									(click)="selectService(service.id, service.headers[0].id, service.service_label, service.price)"
								>
								</service-category-check>
							</ng-container>

							<ng-container
								*ngIf="
									branch['booking_settings'] != null
									&& branch['has_booking'] == 'Yes'
									&& data['headers'].length !== 1
								"
							>
								<service-category-check
									[selectedServices]="selectedServices$ | async"
									[serviceId]="service.id"
									(click)="showModal(service)"
								>
								</service-category-check>
							</ng-container>

							<div>
								<p class="service-item-title mb-0">{{ service['label'] }}</p>
								<div class="service-description" *ngIf="service['description'] !== ''" [innerHTML]="service['description']"></div>
							</div>

						</div>
					</th>

					<ng-container *ngFor="let price of service['prices']">
						<td class="service-price" *ngIf="service['visibility'] == 'show'">
							<span class="currency" *ngIf="price !== 0">{{currency}}</span>
							<span class="value">{{ ( price  == 0 ? "-" : (price | number) ) }}</span></td>
					</ng-container>

					<th *ngIf="service['type'] == 'Category' && service['visibility'] == 'show'" colspan="100%" class="service-category-sub pt-5">
						<service-category [data]="service"></service-category>
					</th>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ngx-smart-modal #serviceModal identifier="serviceModal" [dismissable] = "false">
	<ng-container *ngIf="serviceModal.hasData()">
		<div class="modal-header border-0 p-2 pt-4">
			<h5 class="modal-title text-secondary">
				{{ serviceModal.getData().serviceOptions.label }}
			</h5>
		</div>
		<div class="modal-body p-2">
			<div class="row gx-5 mb-4" *ngFor="let option of serviceModal.getData().serviceOptions.services; let i = index">
				<div class="col-8 d-flex align-items-center" *ngIf="option.price !== 0">

					<service-category-check
						[headerId]="option.header_id"
						[selectedServices]="selectedServices$ | async"
						[serviceId]="option.service_id"
						(click)="selectService(option.service_id, option.header_id, option.service_label, option.price)"
					>
					</service-category-check>
	
					<div>
						<p class="service-item-title mb-0">{{ option.name }}</p>
					</div>
			
				</div>
				<div class="col-4">
					<div class="service-price" *ngIf="option.service_visibility == 'show' && option.price !== 0">
						<span class="currency">{{currency}} </span>
						<span class="value">{{ ( option.price | number) }}</span>
					</div>
				</div>
			</div>

			<div class="row mt-6">
				<div class="col">
					<button mat-raised-button color="secondary" (click)="serviceModal.close()" data-bs-dismiss="modal" class="text-uppercase w-100">Select option</button>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>
