import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DealFilterMobileServiceService {
 
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private mySubject = new BehaviorSubject<number>(0);
  
  setData(data: any): void {
    this.dataSubject.next(data);
  }

  getData(): BehaviorSubject<any> {
    return this.dataSubject;
  }

  reloadSubject(): BehaviorSubject<any> {    
    return this.mySubject;
    // Call any other functions or perform additional logic
  }

  public updateReloadSubject(newValue: number): void {
    this.mySubject.next(newValue);
  }
  constructor() { }
}
