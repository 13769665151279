import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, Inject,} from '@angular/core';
import { distinctUntilKeyChanged, filter, publishReplay, refCount, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import * as Rx from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ContentService } from '../../services/content/content.service';
import { DealsService } from '../../services/deals/deals.service';
import { environment } from '../../../environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { WINDOW } from '@ng-toolkit/universal';
import { TypesenseDealSearchService } from '../../services/typesense-deal-search/typesense-deal-search.service';
import { PaginationInstance } from 'ngx-pagination';
import { LocationMapComponent } from '../contact/components/location-map/location-map.component';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { MatDialog, MatSelect } from '@angular/material';
import { E } from '@angular/core/src/render3';
import { DealFilterMobileComponent } from '../../components/deal-filter-mobile/deal-filter-mobile.component';
import { DealFilterMobileServiceService } from '../../services/deal-filter-mobile-service/deal-filter-mobile-service.service';

declare const google: any;

export interface Sort {
  value: string;
  viewValue: string;
  sequence: string;
}

@Component({
  selector: 'all-parlon-deals-v2',
  templateUrl: './all-parlon-deals-v2.component.html',
  styleUrls: ['./all-parlon-deals-v2.component.scss']
})
export class AllParlonDealsV2Component implements OnInit, OnDestroy {

  sort: Sort[] = [
    { value: 'relevance', viewValue: 'Relevance' , sequence : 'desc' },
    { value: 'price', viewValue: 'Price (from low to high)', sequence : 'asc' },
    { value: 'price', viewValue: 'Price (from high to low)', sequence : 'desc' },
    { value: 'savings', viewValue: 'Savings (from low to high)' , sequence : 'asc'},
    { value: 'savings', viewValue: 'Savings (from high to low)' , sequence : 'desc' },
    { value: 'location', viewValue: 'Near Me' , sequence : 'asc' },
  ];

  isLoaded = false;
  initialLoad = false;
  dealsLoaded = false;
  hasSearched = false;
  dealsLocations: any = [];
  filteredLocations: any = [];
  dealsCategories: any = [];
  resourceLink = environment.resource;
  banner: any = [];
  grabOnly: string;
  selectedCategory = 'all';
  getGrabParam = localStorage.getItem('grabOnly');
 
  filteredDealSubject$ = new Rx.BehaviorSubject<Sort | undefined>(undefined);
  filteredDeal$ = this.filteredDealSubject$.asObservable();
  // dealsSubject$ = new Rx.BehaviorSubject([]);
  deals$:any = [];

  allDeals:any = [];
  resDeals:any = [];

  selectedLocations = [];
  selectedParlons= [];
  
  sortingType = null;
  searchInput:string='';
  mapInput:string='';
  mapModalInput:string='';

  isMarker = false;

  selectedLatLng = {
    lat: '',
    lng: ''
  }

  savedSelectedLatLng = {
    lat: '',
    lng: ''
  }

  navLinks = ['Around you', 'Anywhere'];
  actNavLink = this.navLinks[0];
  isMapHasLoc = false;
  filterUrl: any = [];
  options = {
    fields: ["formatted_address", "geometry", "name", "place_id"],
    componentRestrictions: { country: ["ph"]}
  }

  pickLoc: string = '';
  modalLatlng:any = {};
  numberOfPages:any= [];
  currentPage:number =0;
  totalRecords:number =0;
  params:any;
  p=0;
  dealSubscription:Rx.Subscription;
  dealPaginationSubsription:Rx.Subscription;
  
  // orig
  // public deal_config: PaginationInstance = {
  //   id: 'custom',
  //   itemsPerPage: 50,
  //   currentPage: 0,
  //   totalItems:0
  // }

  // new
  public deal_config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 50,
    currentPage: 1,
    totalItems:0
  }
  

  isLoggedIn:boolean =false;
  userId:string='';
  queryId:string='';
  userSubscription:Rx.Subscription|undefined;


  // 05-02-2023
  locationsV2 = [];
  parlonsV2 = [];
  categoriesV2 = [];
  selectedCategories = [];
  selectedSubCategories = [];

  selectedCategoryV2:string ="";
  selectedSubCategory:string="";

  originalCategories = [];
  originalSubCategories = [];
  originalParlons = [];
  categoryNames = [];
  filterType="";

  original_facet_locations = [];
  facet_locations = [];

  isRecetParlon:boolean = false;
  isRecetCategory:boolean = false;

  showMore:boolean = false;
  searchedValue = '';
  nbPages = 0;

  cValue: string;
  pValue: string[];

  isFromOutside:boolean = false;
  getCategoryUrlFromAllParlonDealsSub:Rx.Subscription|undefined;


  userLat = "";
  userLong ="";

  addressInput = "";


  isLoading = true;
  currentScrollPos:number = 0;

  @ViewChild('mySelect') mySelect: MatSelect;

  @ViewChild("container") private container: ElementRef;


  debounceTimeoutId:any;
  isLoadingSearchInput:any;

  querySuggestions;
  addressSuggestions

  constructor(
    private smartModal: NgxSmartModalService,
    private ContentService: ContentService,
    // private dealSearchService:DealSearchService,
    private router: Router,
    private el: ElementRef,
    private userService:UserAccountService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private dealFilterService:DealFilterMobileServiceService,
    private TypesenseDealSearchService:TypesenseDealSearchService,
    private DealsService:DealsService,
    @Inject(WINDOW) private window: Window, 
  ) { }



  success(position) {
    this.userLat = position.coords.latitude;
    this.userLong = position.coords.longitude;
    // this.ifPositionEnabled();
  }
  
   error(error) {
    console.log("Error retrieving location: " + error.message);
  }

  

  dealScroll(){
    // console.log('nbPages',this.nbPages)
    // console.log('currentPage',this.deal_config.currentPage)
    if(this.nbPages != this.deal_config.currentPage ){
      this.isLoading = true;
      this.showMoreDeals();
    }    
  }  

  updateDataForMobileFilter(){
    let json = {
      selectedParlons:this.selectedParlons,
      selectedCategories:this.selectedCategories,
      parlonsV2:this.parlonsV2,
      locationsV2: this.facet_locations,
      categoriesV2:this.categoriesV2,
      dealsCategories: this.dealsCategories,
      filterByUrl:this.filterUrl,
    }
		this.dealFilterService.setData(json);	
  }

  openDialog() {
    // this.updateDataForMobileFilter();    		      

    let shouldScrollToTop = false; // Initialize a flag

    const dialogRef = this.dialog.open(DealFilterMobileComponent,{position: {
      left: '0'
    },minWidth:'90%', height:'100%', panelClass: 'no-animation'});

  

    dialogRef.componentInstance.eventEmitter.subscribe((eventData) => {
      // Handle the event here without closing the dialog
      // this.goToTop();

      if(eventData.functionName == 'getCategoriesV2'){
        this.getCategories(eventData.data);
      }

      if(eventData.functionName=='getSubCategoriesV2'){
        this.getSubCategoriesV2(eventData.data)
      }

      if(eventData.functionName=='getParlonV2'){
        this.getParlonV2(eventData.data)
      }

      if(eventData.functionName=='getLocations'){
        this.getLocation(eventData.data)
      }

      if(eventData.functionName=='resetFilter'){
        this.resetFilter('');
      }      

      // Set the flag to true when an event is emitted
      shouldScrollToTop = true;

    });


    dialogRef
  .afterClosed()
  .toPromise()
  .then(() => {
    // Scroll to top only if the flag is true (an event was emitted)
    if (shouldScrollToTop) {
      this.goToTop();
    }
  });

  }
  
  ngOnDestroy() {
    if(this.dealSubscription)
    this.dealSubscription.unsubscribe();
    if(this.dealPaginationSubsription)
    this.dealPaginationSubsription.unsubscribe();
    if(this.userSubscription)
      this.userSubscription.unsubscribe();

    if(this.getCategoryUrlFromAllParlonDealsSub)  
    this.getCategoryUrlFromAllParlonDealsSub.unsubscribe();
  }




  getCategoryUrl(){
    this.route.queryParams.pipe(take(1)).subscribe(params => {          
      if(params && Object.keys(params).length > 0){        
        this.cValue = params['c'];
        const pValueString = params['p'];
        const lValueString = params['l'];
        const subcat = params['sc'];

        // categories
        if(this.cValue){
          this.selectedCategories = this.cValue.split(',').map(p => p.trim().replace(/-/g, ' '));
        }else{  
          this.selectedCategories = []
        }  

        // subcat
        if(subcat){
          this.selectedSubCategories = subcat.split(',').map(p => p.trim().replace(/-/g, ' '));
        }                
        // parlons
        if(pValueString){
          this.selectedParlons = pValueString.split(',').map(p => p.trim().replace(/-/g, ' '));
        }else{
          this.selectedParlons = [];
        } 

        // locations
        if(lValueString){
          this.selectedLocations = lValueString.split(',').map(p => p.trim().replace(/-/g, ' '));
        }else{
          this.selectedLocations = [];
        } 
        setTimeout(() => {
          this.getInitialPage();   
        }, 1000);
      }else{
        this.selectedCategories = [];
        this.selectedParlons= [];
        this.selectedSubCategories = [];
        this.selectedLocations = [];
        this.search()
      }     
      
      // this.goToTop();
    });
  }

  getCategoryUrlFromAllParlonDeals(){
    this.getCategoryUrlFromAllParlonDealsSub =  this.route.queryParams.pipe(take(1)).subscribe(params => {   
      if(params && Object.keys(params).length > 0){  
        this.selectedCategories = [];
        this.selectedSubCategories = [];  
        
        this.cValue = params['c'];
        const pValueString = params['p'];
        const subcat = params['sc'];
        const lValueString = params['l'];
        // categories
        if(this.cValue){
          this.selectedCategories =  this.cValue.split(',').map(p => p.trim().replace(/-/g, ' '));
        }else{  
          this.selectedCategories = []
        }
        
        // subcat
        if(subcat){
          this.selectedSubCategories =  subcat.split(',').map(p => p.trim().replace(/-/g, ' '));
          // this.selectedSubCategories = [sc];
        }                
        // parlons
        if(pValueString){
          this.selectedParlons = pValueString.split(',').map(p => p.trim().replace(/-/g, ' '));
        }else{
          this.selectedParlons = [];
        } 

        if(lValueString){
          this.selectedLocations = lValueString.split(',').map(p => p.trim().replace(/-/g, ' '));
        }else{
          this.selectedLocations = [];
        } 


        setTimeout(() => {
          this.getInitialPage();   
        }, 1000);        
      }else{
        this.selectedCategories = [];
        this.selectedParlons= [];
        this.selectedSubCategories = [];
        this.selectedLocations = [];
        this.search(this.sortingType);
      }  
      
      // this.goToTop();
    });
  }
  

getCategoryQueryParams() {
  const queryString = window.location.search;
  const params = {};

  if (queryString) {
    const paramPairs = queryString.slice(1).split('&');
    for (const pair of paramPairs) {
      const [key, value] = pair.split('=');
      params[key] = decodeURIComponent(value);
    }
  }

  return params;
}




  ngOnInit() {
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // This code will be executed when a route change is complete
        let main_navbar = document.querySelector('.navbar-root'); 
        let filter_navbar = document.querySelector('#mobile-filters'); 
        let footer_nav = document.querySelector('.footer-nav'); 
        main_navbar.classList.remove('deal-mobile-scrolled');
        filter_navbar.classList.remove('is-scrolled');
        footer_nav.classList.remove('is-scrolled');
        // this.categoriesV2.forEach((item, index)=>{
        //   if(this.selectedCategories.length>0){
        //     this.categoriesV2[index].show = false;  
        //   }                        
        // })
        this.getCategoryUrlFromAllParlonDeals();
      }
    });
 
    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.originalCategories = data['category_tree'];
        this.categoriesV2 = data['category_tree'];
      }
    });
    


    this.userSubscription= this.userService.getLoggedInUser.subscribe((data:any)=>{
      if(data){        
        if(data.data){
          this.isLoggedIn = true;
          this.userId = data.user.id;          
        }               
      }
    });
        
    // to get algolia facets : categories, subcategories and parlons
    this.resetFilter('',false);
    this.getFacets();  
    // The purpose of this code is to handle the scenario where no deals appear
    // when a category is selected in the navigation drawer and the page is currently all parlon deals.     
    this.dealFilterService.reloadSubject().subscribe(data=>{          
      if(data==1){        
        this.getCategoryUrlFromAllParlonDeals()
      }else if(data==2){
        this.resetFilter('');
        // alert(1);
      }
      else{
        const hashValue = window.location.hash.substr(1);    
        if(hashValue !='' && Object.keys(hashValue).length > 0){           
          this.router.navigate(['/all-parlon-deals'], { 
            queryParams: {c:hashValue},
          }).then(()=>{
            this.getCategoryUrl();
          });
        }else{
          this.getCategoryUrl();
        }
      }      
    });
              
    this.getPageBanner();
        
    let button = document.getElementById("toTop-button");
    let search_filters = document.getElementById("mobile-filters");
    // Set the distance from the top at which the button should be shown
    let showAt = 500;
    // Listen for the scroll event on the window
    window.addEventListener("scroll", function() {
    // Check if the user has scrolled past the showAt distance
    if (window.pageYOffset > showAt) {
    // Show the button by setting its visibility to "visible"
    button.style.visibility = "visible";
    search_filters.classList.add('show');
    } else {
    // Hide the button by setting its visibility to "hidden"
    button.style.visibility = "hidden";
    search_filters.classList.remove('show');
    
    }
    });
  }


  ifPositionEnabled(){    
    let centerPos;
    if(this.userLat != "" ){   
      // alert(1);   
       centerPos = {
        lat: parseFloat(this.userLat),
        lng: parseFloat(this.userLong)
       }
    }else{
       centerPos = {
        lat: 14.5554012,
        lng: 121.0203822
      }
    }

    this.selectedLatLng.lat = centerPos.lat;
    this.selectedLatLng.lng = centerPos.lng;
   
    let geocoder = new google.maps.Geocoder();
  
    let location = new google.maps.LatLng(this.userLat, this.userLong);
    let geocoderRequest = {
      location: location
    };

    geocoder.geocode(geocoderRequest, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          let formattedAddress = results[0].formatted_address;
          this.mapInput = formattedAddress;
          this.mapModalInput = formattedAddress;
          this.isMarker = true;
          this.modalLatlng = results[0];
          this.pickLoc = formattedAddress;
          // this.modalLatlng = formattedAddress;
          // Use the formatted address as needed
        }
      }


      this.search(this.sortingType);

    }); 

    
  }

  ngAfterViewInit() {

    // this.router.events
    // .pipe(
    //   filter(event => event instanceof NavigationEnd)      
    // )
    // .subscribe(() => {
    //   this.goToTop();
    // });


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error.bind(this), { enableHighAccuracy: true });
    
    }

    const mapAutocompleteMob = document.getElementById("mapSearchmobile") as HTMLInputElement;
    const mapAutocomplete = document.getElementById("mapSearch") as HTMLInputElement;

    // attach text input to google map autocomplete
    const autoComplete = new google.maps.places.Autocomplete(mapAutocomplete, this.options)
    autoComplete.addListener('place_changed', () => {
      this.mapLatLng(autoComplete.getPlace());
      this.mapInput = mapAutocomplete.value;
      this.mapModalInput = '';
    })
    
    // attach text input to google map autocomplete (mobile view)
    const autoCompleteMob = new google.maps.places.Autocomplete(mapAutocompleteMob, this.options)
    autoCompleteMob.addListener('place_changed', () => {
      this.mapLatLng(autoCompleteMob.getPlace());
      this.mapInput = mapAutocompleteMob.value;
      this.mapModalInput = '';
    })

  }


  googleMap() {
    // google map modal function

    this.smartModal.getModal('mapModal').open();
    this.isMarker = false;
    let centerPos;
    if(this.userLat != "" && this.userLat){      
       centerPos = {
        lat: parseFloat(this.userLat),
        lng: parseFloat(this.userLong)
       }
    }else{
       centerPos = {
        lat: 14.5554012,
        lng: 121.0203822
      }
    }


    

    if ((this.selectedLatLng.lat != '' && this.selectedLatLng.lng != '') || (this.savedSelectedLatLng.lat != '' && this.savedSelectedLatLng.lng != '') ) {
      centerPos = {
        lat: Number(this.selectedLatLng.lat || this.savedSelectedLatLng.lat),
        lng: Number(this.selectedLatLng.lng || this.savedSelectedLatLng.lng),
      }
    } 

    
    let mapCanvas = document.getElementById("mapBody");
    let mapOptions = {
      center: new google.maps.LatLng(centerPos), 
      zoom: 15,
      clickableIcons: false,
      disableDefaultUI: true,
    };
    let map = new google.maps.Map(mapCanvas, mapOptions);
    let geocoder = new google.maps.Geocoder();

    let marker = new google.maps.Marker(
      {position: mapOptions,
        map: map,
        draggable: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
    marker.setMap(map);

    var mapPoi = [{
      featureType: "poi",
      elementType: "labels",
  
      stylers: [{
        visibility: "off"
      }]
    }];
  
    map.setOptions({
      styles: mapPoi
    });

    // attach text input to google map autocomplete modal
    const mapModalAutocomplete = document.getElementById('pickAdd') as HTMLInputElement
    setTimeout(() => {document.getElementById("mapSearch").blur(); document.getElementById("mapSearchmobile").blur()}, 0)

    const autoCompleteModal = new google.maps.places.Autocomplete(mapModalAutocomplete, this.options)
    autoCompleteModal.addListener('place_changed', () => {
      // this.mapLatLng(autoCompleteModal.getPlace());
      this.modalLatlng = autoCompleteModal.getPlace();
      let place = {
        lat: autoCompleteModal.getPlace().geometry.location.lat(),
        lng: autoCompleteModal.getPlace().geometry.location.lng(),
      }
      this.pickLoc = autoCompleteModal.getPlace().formatted_address;
      map.setCenter(place);
      marker.setPosition(place);
      this.isMarker = true;
    })

    // marker click listener
    google.maps.event.addListener(map, 'click', (event: any) => {
      marker.setPosition(event.latLng);
      geocoder.geocode({location: event.latLng}, (address: any) => {
        this.mapModalInput = address[0].formatted_address;
        this.isMarker = true;
        this.pickLoc = address[0].formatted_address;
        this.modalLatlng = address[0];
      })
    });
  }

  selectLoc() {
    this.mapModalInput = '';
    this.smartModal.getModal('mapModal').close();
    this.mapInput = this.pickLoc;


    this.mapLatLng(this.modalLatlng);
    this.modalLatlng = {};
  }
    
  mapLatLng(coords: any) {
    // set map coords
    this.actNavLink = this.navLinks[0];
    this.selectedLatLng = {
      lat: `${coords.geometry.location.lat()}`,
      lng: `${coords.geometry.location.lng()}`
    };
    this.savedSelectedLatLng = {
      lat: '',
      lng: ''
    };
    this.search(this.sortingType);
    
  }

  modalClose() {
    this.mapModalInput = '';
  }

  clearInput() {
    // clear map input
    this.actNavLink = this.navLinks[0];
    this.mapInput = '';
    this.mapModalInput = '';
    this.selectedLatLng = {
      lat: '',
      lng: ''
    };
    this.savedSelectedLatLng = {
      lat: '',
      lng: ''
    };
    this.search(this.sortingType);
  }

  clearModalInput() {
    this.mapModalInput = '';
  }

  navResult(link: string) {
    // nav bar control
      if (link == 'Around you') {
        this.selectedLatLng = this.savedSelectedLatLng;
      } else {
        this.selectedLatLng = {
          lat: '',
          lng: ''
        };
      }
    this.search(this.sortingType);
  }

  appendParams() {
    // append parameters in URL for filter
    let parlonArr = [], locationArr = [], categoryArr = [], subCategoryArr = [];
    let query = {p: '', c: '', l: '', sc: ''}
  
    if (this.selectedParlons.length > 0) {
      parlonArr = this.replaceToQuery(this.selectedParlons);
      query.p = parlonArr.join();
    } else {
      delete query.p
    }
  
    if (this.selectedLocations.length > 0) {
      locationArr = this.replaceToQuery(this.selectedLocations);
      query.l = locationArr.join();
    } else {
      delete query.l
    }
  
    if (this.selectedCategories.length > 0) {
      categoryArr = this.replaceToQuery(this.selectedCategories);
      query.c = categoryArr.join();
    } else {
      delete query.c
    }
    if (this.selectedSubCategories.length > 0) {
      subCategoryArr = this.replaceToQuery(this.removeDuplicates(this.selectedSubCategories));
      query.sc = subCategoryArr.join();
    } else {
      delete query.sc
    }
    if(query && typeof query === 'object' && Object.keys(query).length > 0){
      // alert(1);
      this.router.navigate(['/all-parlon-deals'], { 
        queryParams: query,
      }).then(() => {
        this.goToTop();
      }); 

      
    }else{
      this.router.navigate(['/all-parlon-deals']).then(() => {
        this.goToTop();
      });
    }
  }
  
  replaceToQuery(filter: any) {
    let arr = [];
    if(filter){
      filter.forEach((item: any) => {
        arr.push(item.replace(/\s+/g, '-').toLowerCase());
      });
    }
    
    return arr;
  }
  

  goToTop(){   
    const checkpoint: HTMLElement = this.el.nativeElement.querySelector('#all-deals-section')
    if(window.innerWidth <= 768) {
      window.scrollTo(0,0);
    } else {      
      // this.container.nativeElement.scrollIntoView({ behavior: "auto", block:'nearest' });
      const checkpoint: HTMLElement = this.el.nativeElement.querySelector('#all-deals-section');
      const topOffset = 150; // Adjust the value as needed
      window.scrollTo({
        top: checkpoint.offsetTop - topOffset      
      });
    }
  }

  getParlon(parlon:any){
  
    this.selectedParlons = parlon;
    this.search(this.sortingType);
    this.appendParams();
  }


  pageChangeEvent(event: number){
    
    this.p = event;  
    // this.dealPaginationSubsription =this.dealSearchService.searchPage(this.params,event, this.sortingType, this.selectedLatLng).subscribe((data)=>{      
    //   let deals = data.results[0].hits.map((item)=>{  
    //     return {searchInput:this.searchInput,objectID:item.objectID,slug:item.slug,primary_photo:item.logo,parlon_logo:item.parlon_logo,
    //        deal_name:item.name,discount_type:item.discount_type, locations:item.locations,
    //       discount_value:item.discount_value, orig_price:item.original_price, deal_price:item.deal_price,parlon_name:item.parlon,distance:item._rankingInfo}
    //   })
    //   this.deals$.push(...deals);      
    //   this.nbPages = data.results[0].nbPages;
    //   this.totalRecords =data.results[0].nbHits; 
    //   this.deal_config.totalItems = this.deals$.length;      
    //   this.deal_config.currentPage = data.results[0].page;            
    //   this.queryId = data.results[0].queryID;
    //   this.params = this.getQueryParams(data.results[0].params); 
    //   this.isLoading = false;           
    // })
    this.TypesenseDealSearchService.search(this.deal_config,this.searchInput,this.sortingType,this.selectedParlons,this.selectedCategories, this.selectedSubCategories, this.selectedLocations, this.savedSelectedLatLng, true).subscribe((data)=>{
      let deals = [];
      data['hits'].forEach(item => {
        deals.push({searchInput:this.searchInput,objectID:item['document'].objectID,slug:item['document'].slug,primary_photo:item['document'].logo,parlon_logo:item['document'].parlon_logo,
                 deal_name:item['document'].name,discount_type:item['document'].discount_type, locations:item['document'].locations,
                discount_value:item['document'].discount_value, orig_price:item['document'].original_price, deal_price:item['document'].deal_price,parlon_name:item['document'].parlon,distance:item['document']._rankingInfo,id:item['document'].id});
      });
      this.deals$.push(...deals);   
      this.deal_config.currentPage = data.page;
      this.deal_config.totalItems = data['found'];
      this.totalRecords = data['found'];
      this.nbPages = Math.ceil(data['found']/this.deal_config.itemsPerPage);
      this.isLoading = false;  
    });

  }

  getLocation(location:any){    
    this.selectedLocations = location;
    // this.search(this.sortingType);
    this.appendParams();
  }

  replacePlusWithSpace(str) {
    return str.replace(/\+/g,' ');
  }

  getQueryParams(url) {
    const paramStr = url.slice(url.indexOf('?') + 1);
    if (!paramStr) return {};  
    const params = paramStr.split('&').reduce((acc, param) => {
      const [key, val] = param.split('=');
      acc[key] = decodeURIComponent(val);
      return acc;
    }, {});
  
    if (params['optionalFilters']) {
      const optionalFilters = JSON.parse(params['optionalFilters'].replace(/\//g, ""));
      params['optionalFilters'] = optionalFilters.map((filter) => filter.map(this.replacePlusWithSpace));
    }
  
    if (params['facetFilters']) {
      const facetFilters = JSON.parse(params['facetFilters'].replace(/\//g, ""));
      params['facetFilters'] = facetFilters.map((filter) => filter.map(this.replacePlusWithSpace));
    }  
    return params;
  }


  // MAIN SEARCH FUNCTION
  search =(sortType={value:'relevance'},resetFacet:boolean = true, filterType="optionalFilters",
  recetParlon:boolean = true)=>{
    
      

    if(this.searchInput==""){
      filterType = "facetFilters"; 
    } else {
      this.hasSearched = true;
      this.searchedValue = this.searchInput;
    }
    this.TypesenseDealSearchService.search(this.deal_config,this.searchInput,sortType,this.selectedParlons,this.selectedCategories,this.selectedSubCategories,this.selectedLocations, this.savedSelectedLatLng).subscribe((data)=>{
      let deals = [];
      data['hits'].forEach(item => {
        deals.push({searchInput:this.searchInput,objectID:item['document'].objectID,slug:item['document'].slug,primary_photo:item['document'].logo,parlon_logo:item['document'].parlon_logo,
                 deal_name:item['document'].name,discount_type:item['document'].discount_type, locations:item['document'].locations,
                discount_value:item['document'].discount_value, orig_price:item['document'].original_price, deal_price:item['document'].deal_price,parlon_name:item['document'].parlon,distance:item['document']._rankingInfo,id:item['document'].id});
      });
      this.isLoadingSearchInput = false;  
      this.deals$ = deals;
      this.deal_config.currentPage = data.page;
      this.deal_config.totalItems = data['found'];
      this.totalRecords = data['found'];
      this.nbPages = Math.ceil(data['found']/this.deal_config.itemsPerPage);
      this.isMapHasLoc = false;
      this.isLoading = false;
      // if(this.initialLoad == false)
      //   this.goToTop();
      this.initialLoad = true;

      if(this.searchInput!=""){
        if( this.selectedCategories.length < 1 && this.selectedSubCategories.length < 1 && this.selectedParlons.length < 1 && this.selectedLocations.length < 1 ) {
          data['facet_counts'].forEach(e => {
            switch (e['field_name']) {
              case 'parlon':
                let parlons_copy = JSON.parse(JSON.stringify(this.originalParlons));
                let parlons =  [];
                e['counts'].forEach(item => {
                  let parlon = parlons_copy.find(p => p.highlighted === item.highlighted);
                  if(parlon) {
                    parlons.push(parlon);
                  }
                });
                this.parlonsV2 = parlons;
                break;
              case 'locations':
                let locations_copy = JSON.parse(JSON.stringify(this.original_facet_locations));
                let locations =  [];
                e['counts'].forEach(item => {
                  let location = locations_copy.find(l => l.highlighted === item.highlighted);
                  if(location) {
                    locations.push(location);
                  }
                });
                this.facet_locations = locations;
                break;
              case 'categories.category_name':
                let categories = [];
                e['counts'].forEach(result => {
                  let category_copy = JSON.parse(JSON.stringify(this.originalCategories));
                  let category = category_copy.find(s => s.category_name === result.value);
                  if(category) {
                    let subcat_facets = data['facet_counts'].find(f => f.field_name === "categories.subcategories");
                    let subcategories = [];
                    subcat_facets['counts'].forEach(sub_facet => {
                      let subcat = category['subcategories'].find(s => s.category_name === sub_facet.value);
                      if(subcat) {
                        subcategories.push(subcat);
                      }
                    });
                    category.subcategories = subcategories;
                    categories.push(category);
                  }
                });
                this.categoriesV2 = categories;
                break;
              default:
                break;
            }
          });
        }
      } 
      else {
        this.parlonsV2 = JSON.parse(JSON.stringify(this.originalParlons));
        this.facet_locations = JSON.parse(JSON.stringify(this.original_facet_locations));
        this.categoriesV2 = JSON.parse(JSON.stringify(this.originalCategories));
      }

      // if(this.originalParlons.length>0){
      //   this.parlonsV2 = JSON.parse(JSON.stringify(this.originalParlons));              
      // }
  
      this.parlonsV2.map(item => {
        if (this.selectedParlons.includes(item.highlighted) || this.selectedParlons.includes(item.highlighted.toLowerCase())) {
          item.completed = true;
        }
        return item;
      });  

      // if(this.original_facet_locations.length>0){
      //   this.facet_locations = JSON.parse(JSON.stringify(this.original_facet_locations));              
      // }
  
      this.facet_locations.map(item => {
        if (this.selectedLocations.includes(item.highlighted) || this.selectedLocations.includes(item.highlighted.toLowerCase())) {
          item.completed = true;
        }
        return item;
      });
      // reorder parlon if selected
      // if(this.parlonsV2.length>0){
      //   const selectedItems = this.parlonsV2.filter(item => item.completed );
      //   const unselectedItems = this.parlonsV2.filter(item => !item.completed);
      //   this.parlonsV2 = [...selectedItems, ...unselectedItems];
      // }
      
      if(this.categoriesV2){        
        this.categoriesV2.forEach((item, index)=>{
          if(this.selectedCategories.length>0){
            if(this.selectedCategories.includes((item.slug.replace(/-/g, ' ')).toLowerCase())) {
              item.show = true;
            } else {
              item.show = false;
            }
          }
          if(this.selectedSubCategories.length>0) {
            item.subcategories.forEach((sub, index)=>{
              if(this.selectedSubCategories.includes((sub.slug.replace(/-/g, ' ')).toLowerCase()))  {
                sub.show = true;
              } else {
                sub.show = false;
              }
            });
          }
        });         
      }

      // }      
      this.updateDataForMobileFilter();

    });

    // this.dealSubscription = this.dealSearchService.search(this.searchInput, 
    //   this.selectedLocations, this.selectedParlons,this.selectedCategories, sortType, this.selectedLatLng, filterType, 
    //   this.selectedSubCategories, this.categoryNames).subscribe((data)=>{    
      
    //   this.isLoadingSearchInput = false;  
    //   this.deal_config.currentPage = data.results[0].page;
    //   this.deal_config.totalItems = data.results[0].nbHits;
    //   this.params = this.getQueryParams(data.results[0].params);
    //   this.queryId = data.results[0].queryID;
    //   this.isMapHasLoc = false;
    //   if (this.selectedLatLng.lat != '' && this.selectedLatLng.lng != '') {
    //     this.isMapHasLoc = true;
    //     this.savedSelectedLatLng = this.selectedLatLng;
    //   } else if (this.savedSelectedLatLng.lat != '' && this.savedSelectedLatLng.lng != '') {
    //     this.isMapHasLoc = true;
    //   }

      
    // });


    

    // if(this.searchInput == "" && this.selectedCategories.length==0 && this.selectedParlons.length ==0){
    //   // Create a shallow copy of this.originalParlons
    //   this.parlonsV2 = JSON.parse(JSON.stringify(this.originalParlons));
    // }

   

  }
  
  changeModel(){    

    if (this.isLoadingSearchInput) {
      return;
    }
    this.resetFilter('');
    this.search(this.sortingType, true, 'optionalFilters', true);
  }

  getFacets(){
    this.TypesenseDealSearchService.search(this.deal_config).subscribe((data)=>{
      data['facet_counts'].forEach(e => {
        switch (e['field_name']) {
          case 'parlon':
            let parlons = e['counts'].sort((a,b) => (a.highlighted > b.highlighted) ? 1 : ((b.highlighted > a.highlighted) ? -1 : 0));
            parlons.forEach(e => {
              e.completed = false;
            });
            this.originalParlons = parlons;
            this.parlonsV2 = parlons;
            break;
          case 'locations':
            let locations = e['counts'].sort((a,b) => (a.highlighted > b.highlighted) ? 1 : ((b.highlighted > a.highlighted) ? -1 : 0));
            locations.forEach(e => {
              e.completed = false;
            });
            this.original_facet_locations = locations;
            this.facet_locations = locations;
            break;
          default:
            break;
        }
      });
      this.updateDataForMobileFilter();     
    });
  }

  getCategoriesV2(value){    
    this.selectedCategories = [];  
    if(value !=''){
      this.isRecetParlon = true;
      this.selectedCategories = [value];
      this.selectedSubCategories= [];
    }else{
      this.selectedCategories = [];
      this.selectedSubCategories= [];      
    }
    if((this.selectedParlons.length>0 || this.selectedCategories.length>0) && this.searchInput != "" ){
      this.search(this.sortingType,false,'facetFilters', true);   
    }
    else{
      if(this.selectedParlons.length>0){
        this.search(this.sortingType,true,'optionalFilters', true);   
      }else{
        this.search(this.sortingType,false,'optionalFilters', true);   
      }
    }    
    this.appendParams();    
  }

  getCategories(value){
    this.selectedCategories = [];
    this.selectedSubCategories = [];
    
    value.forEach(cat => {
      if(cat.show == true) {
        this.selectedCategories.push(cat.slug);
      }
      cat.subcategories.forEach(subcat => {
        if(subcat.show == true) {
          this.selectedSubCategories.push(subcat.slug);
        }
      });
    });
    this.appendParams();   
  }

  getParlonV2(value){        
    this.selectedParlons =[...value];
    this.isRecetCategory = true;  
    // if(this.selectedParlons.length>0){      
    //   this.search(this.sortingType,true,'optionalFilters', false);
    // }    
    // else {
    //   this.selectedParlons = [];
    //   this.search(this.sortingType,true,'optionalFilters', true);
    // }       
 
    this.appendParams();
    
  }

  getSubCategoriesV2(value){
    if(value !='')
    this.selectedSubCategories = [value];
    else{
      this.selectedSubCategories = [];
    }       
    this.search(this.sortingType,false,'facetFilters');   
    this.appendParams();
    // this.goToTop();
    // this.updateDataForMobileFilter();
  }


  getInitialPage(){
    if(this.selectedCategories.length>0 || this.selectedParlons.length>0 || this.selectedLocations.length > 0){     
      this.search(this.sortingType, true,'facetFilters',true);
    }else{
      this.search(this.sortingType, true,'optionalFilters',true);
    }
  }

  getPageBanner() {
    let page = this;
    this.ContentService.getPageBanner().subscribe((data: {}) => {
      this.banner = data['data']
      page.isLoaded = true;
    });
  }

  sortByFilter(value) {    
    this.sortingType = value;
    if(value.value != 'location') {
      this.search(this.sortingType,false,'optionalFilters', true);     
    } else {
      this.addressInput = '';
      this.savedSelectedLatLng = {
        lat: '',
        lng: ''
      }
    }
      
  }
   
  resetFilter(e,resetUrl=true) {
    // reset sort filter
    this.mySelect.value = this.sort[0];
    this.mySelect._onChange(this.sort[0]);
    this.sortingType = this.sort[0];
    // reset categories
    this.parlonsV2 = JSON.parse(JSON.stringify(this.originalParlons));        
    this.selectedLocations = [];
    this.selectedParlons= [];
    this.selectedCategories = []; 
    this.selectedSubCategories = [];
    this.searchedValue = '';
    
    this.filterUrl = [];
    this.actNavLink = this.navLinks[0];
    this.mapInput = '';
    this.mapModalInput = '';
    this.selectedLatLng = {
      lat: '',
      lng: ''
    };
    this.savedSelectedLatLng = {
      lat: '',
      lng: ''
    };
    this.categoriesV2.forEach((category, categoryIndex)=>{
      category.show = false;
      category.subcategories.forEach((subcat, categoryIndex)=>{
        subcat.show = false;
      });
    });
    this.parlonsV2.forEach((parlon, categoryIndex)=>{
      parlon.completed = false;
    });
    this.facet_locations.forEach((loc, categoryIndex)=>{
      loc.completed = false;
    });
    
    this.search(this.sortingType); 
    if(resetUrl) {
      this.router.navigate(['/all-parlon-deals']).then(() => {
        this.goToTop();
      });
    }    
    // this.goToTop();
  };
  
  showMoreDeals(){

    if(this.deal_config.currentPage == this.nbPages ){
      this.isLoading = false;
      return;
    }
    

    this.deal_config.currentPage +=1;
    this.pageChangeEvent(this.deal_config.currentPage);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(e) {
    {
    const main_navbar = document.querySelector('.navbar-root'); 
    const filter_navbar = document.querySelector('#mobile-filters'); 
    const footer_nav = document.querySelector('.footer-nav'); 
    if(this.router.url.startsWith('/all-parlon-deals')) {
      let newPosition = window.scrollY;
      if(newPosition > 150) {
        if (this.currentScrollPos > newPosition) {
          main_navbar.classList.remove('deal-mobile-scrolled');
          filter_navbar.classList.remove('is-scrolled');
          footer_nav.classList.remove('is-scrolled');
        } else {
          main_navbar.classList.add('deal-mobile-scrolled');
          filter_navbar.classList.add('is-scrolled');
          footer_nav.classList.add('is-scrolled');
        }
        this.currentScrollPos = newPosition;
      } else {
        main_navbar.classList.remove('deal-mobile-scrolled');
        filter_navbar.classList.remove('is-scrolled');
        footer_nav.classList.remove('is-scrolled');
      }
    }
    }
  }

  searchAutocomplete() {
    if(this.searchInput != '') {
      this.TypesenseDealSearchService.dealSearchSuggestions(this.searchInput).subscribe((data)=>{
        this.querySuggestions = data['hits'];
      });
    } else  {
      this.querySuggestions = [];
    }
    
  }

  selectAddress(value) {
    this.savedSelectedLatLng.lat = value.latitude;
    this.savedSelectedLatLng.lng = value.longitude;
    this.search(this.sortingType,false,'optionalFilters', true);
  }

  addressAutocomplete() {
    if(this.addressInput != '') {
      this.DealsService.placesSearch(this.addressInput).subscribe((data)=>{
        this.addressSuggestions = data['data'];
      });
    } else  {
      this.addressSuggestions = [];
    }
    
  }

  getOptionText(option) {
    return option.formattedAddress;
  }

  removeDuplicates(arr) {
    return arr.filter((value, index) => arr.indexOf(value) === index);
  }

} 
