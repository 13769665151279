import { Component, Input, OnChanges, OnInit } from '@angular/core';
@Component({
  selector: 'service-check',
  templateUrl: './service-check.component.html',
  styleUrls: ['./service-check.component.scss']
})
export class ServiceCheckComponent implements OnChanges {

  @Input() selectedServices = [];
  @Input() deal = null;
  @Input() services = undefined;

  isSelected = false;

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    const storedSelectedServices = JSON.parse(localStorage.getItem('selectedServices'));
    if(storedSelectedServices) {
      this.isSelected = !!this.selectedServices.find(
        service =>
          (this.deal && (service.deal && service.deal.id) === (this.deal && this.deal.id)) ||
          (this.services && (service.service && service.service.id) === (this.services && this.services.id)),
      )
    } else {
      return this.selectedServices = [];
    }
  }
}
