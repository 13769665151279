import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'bash-deal-card',
  templateUrl: './bash-deal-card.component.html',
  styleUrls: ['./bash-deal-card.component.scss']
})
export class BashDealCardComponent implements OnInit {

  extension = "";
  constructor() { }

  resource = environment.resource;

  @Input() data;

  ngOnInit() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    if(url.searchParams.get("showHidden") == 'true') 
      this.extension = "?showHidden=true";
  }

}
