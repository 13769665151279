import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import { MaterialModule } from '../../material';
import { BeautyBagThankyouComponent } from './beauty-bag-thankyou.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { DealCardRecommendationComponent } from './components/deal-card-recommendation/deal-card-recommendation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgxSmartModalModule
  ],
  declarations: [BeautyBagThankyouComponent, DealCardRecommendationComponent]
})
export class BeautyBagThankyouModule { }
