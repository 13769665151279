import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserResetPasswordComponent } from './user-reset-password.component';
import { SuccessComponent } from './components/success/success.component';
import { MatDialogModule } from '@angular/material';
import { UserResetPasswordRouterModule } from "./user-reset-password-routing.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    UserResetPasswordRouterModule
  ],
  declarations: [UserResetPasswordComponent, SuccessComponent],
  entryComponents: [SuccessComponent]
})
export class UserResetPasswordModule { }
