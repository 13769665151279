import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BookingService } from '../booking/booking.service';
import { DealsService } from '../deals/deals.service';
import { NetcoreService } from '../netcore/netcore.service';
import * as moment from 'moment';
declare var gtag: any;

let httpOptions = {};

@Injectable({
  providedIn: 'root'
})

export class UserAccountService {

  constructor(
    private http:HttpClient, 
    private bookingService:BookingService,
    private DealsService:DealsService,
    private NetcoreService:NetcoreService,
    ) { 
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      }
    }

  private candies = new BehaviorSubject<any>(!JSON.parse(localStorage.getItem('currentUser')) ? null : JSON.parse(localStorage.getItem('currentUser')).bagSummary);  
  getCandies = this.candies.asObservable();

  // FOR OTP-SUCCESS 
  private activeUser = new BehaviorSubject<any>(!JSON.parse(localStorage.getItem('userAccount')) ? null : JSON.parse(localStorage.getItem('userAccount')) );
  getActiveUser = this.activeUser.asObservable();

  // FOR LOGGED IN USER
  private loggedInUser = new BehaviorSubject<any>(!JSON.parse(localStorage.getItem('currentUser')) ? null : JSON.parse(localStorage.getItem('currentUser')) );
  getLoggedInUser = this.loggedInUser.asObservable();

  private time = new BehaviorSubject<any>("afternoon");
  getTime = this.time.asObservable();

  // check if the user is logged in
  isLogged(){
    return localStorage.getItem('currentUser') != null;
  }

  setOtpUser(form:any){
    localStorage.setItem('userAccount',JSON.stringify(form));
    this.activeUser.next(form);
  }


  refreshCandies(form:any){    
    this.candies.next(form);
  }

  verifyFBuser(data:any){
    return this.http.post(environment.api + 'social-login/auth-check', data, httpOptions);
  }

  registerWithFB(data:any){
    return this.http.post(environment.api + 'social-login/register', data, httpOptions);
  }
  setTime(time:any){
    this.time.next(time);
  }

  getVouchers(branch_id:string){
    let api_token = localStorage.getItem('currentUser');

    let header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+JSON.parse(api_token).data,
      })
    }
    return this.http.get(environment.api + `user/vouchers/?branch_id=${branch_id}`, header);
  }

  getMyCandies(){
    let api_token = localStorage.getItem('currentUser');

    let header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+JSON.parse(api_token).data,
      })
    }
    return this.http.get(environment.api + `candies/my-candies`, header);
  }




  setActiveUser(form:any){
    localStorage.setItem( 'currentUser',JSON.stringify(form));
    if(form['bagSummary'] != null) {
      localStorage.setItem('bagId',form['bagSummary']['bag_id']);
      localStorage.setItem('bagCount',form['bagSummary']['deals'].length);
      this.DealsService.changeCartCount(form['bagSummary']['deals'].length);
    }
    // this.NetcoreService.identify(form['user']['email']);
    this.loggedInUser.next(form);
    this.candies.next(form.candies);
  }


  login2(form:any){
    return this.http.post(environment.api + 'user/authenticate',form)
  }
  
  logout(){
    this.NetcoreService.clearIdentity();
    localStorage.clear();
    this.DealsService.changeCartCount(0);
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('userData');
    this.loggedInUser.next(null);
    gtag('set', 'user_id', null);
    //used for OTP-SUCCESS
    localStorage.removeItem('userAccount');
    
  }

  forgotPassword(form:any){
    return this.http.post(environment.api + 'user/forgot-password',form)
  }

  resetPassword(form:any){
    return this.http.post(environment.api + 'user/reset-password',form)
  }


}
