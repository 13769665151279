import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'brand-feature-stack',
  templateUrl: './brand-feature-stack.component.html',
  styleUrls: ['./brand-feature-stack.component.scss']
})
export class BrandFeatureStackComponent implements OnInit {

  @Input() data;
  @Input() index;

  resource = environment.resource;

  constructor(
    private el: ElementRef,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;
      // let elem = el.querySelector('.flickity-brands');
      let elem = el.querySelector('#flickity-brands-'+this.index);
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'center',
        autoPlay: true,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: false,
        percentPosition: true,
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }

}
