<ngx-skeleton-loader class="mb-2" count="1" appearance="bar" [theme]="{ height: '350px', 'border-radius': '0.5rem' }"> </ngx-skeleton-loader>
<ngx-skeleton-loader class="" count="1" appearance="bar" [theme]="{ height: '100px', 'border-radius': '0.5rem' }"> </ngx-skeleton-loader>
<section class="bg-light-3 py-7">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-xl-9 text-center">
				<ngx-skeleton-loader class="mb-4" count="1" appearance="bar" [theme]="{ height: '50px', 'border-radius': '0.5rem' }"> </ngx-skeleton-loader>

				<ngx-skeleton-loader class="mb-4" count="1" appearance="bar" [theme]="{ height: '30px', 'border-radius': '0.5rem' }"> </ngx-skeleton-loader>
			</div>
		</div>
	</div>
</section>