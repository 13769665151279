<div class="bash-checkout" *ngIf="isLoaded == true">
  <div class="nav-extension">
    <div>
      <p class="m-0">{{deal['name']}}</p>
      <div class="d-flex justify-content-center align-items-center">
        <img src="{{resource + deal['parlon']['logo']}}" width="30">
        <p class="my-0 ms-1">{{deal['parlon']['business_name']}}</p>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <p class="price my-0 me-3">₱{{deal['pivot']['selling_price'] | number}}</p>
    </div>
  </div>
  <div class="pt-7"></div>
  <div class="ratio ratio-16x9 d-sm-block d-md-none img-mobile">
    <img src="{{resource + deal['image']}}" class="img-fluid obj-fit-cover w-100 ">
  </div>
  <img src="{{resource}}BeautyBash/bg-accent.png" class="bg-accent-m d-sm-block d-md-none">
  <div class="container">
    <div class="deal-details mb-7">
      <div class="info-section">
        <div class="info-image-container d-none d-md-block">
          <div class="ratio ratio-16x9 info-image">
            <img src="{{resource + deal['image']}}" class="img-fluid obj-fit-cover w-100">
          </div>
        </div>
        <img src="{{resource}}BeautyBash/bg-accent.png" class="bg-accent d-none d-md-block">
        <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top w-100">
        <div class="sheet block w-100">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-md-8">
                  <a [routerLink]="['/beauty-bash/deals']" class="text-hot-pink fw-semibold"> <i class="fa-solid fa-chevron-left me-1 "></i> Go back to Beauty Bash</a>
                  <p class="text-muted text-uppercase fw-semibold mb-2 pt-2">You are buying</p>
                  <div class="row align-items-start">

                    <div class="col-md-3 col-4" style="position: relative;">
                      <div class="sold-out-tag" *ngIf="deal['pivot']['quantity'] < 1">
                        SOLD OUT
                      </div>
                      <img src="{{resource + deal['parlon']['logo']}}" class="salon-logo pt-6">
                    </div>
                    <div class="col-md-7 col-8">
                      <div class="mx-4 mb-3">
                        <div class="deal-discount fw-semibold">
                          <span *ngIf="deal['savings_display_type'] == 'value'">SAVE ₱{{deal['pivot']['savings'] | number}}</span>
                          <span *ngIf="deal['savings_display_type'] == 'percentage'">{{deal['savings_in_pct']}} OFF</span>
                        </div>
                        <h2 class="h2 mb-2">{{deal['name']}}</h2>
                        <p class="text-muted fw-semibold mb-1">{{deal['parlon']['business_name']}}</p>
                        <p><span class="fw-semibold">₱{{deal['pivot']['selling_price'] |
                            number}}</span>&nbsp;<small><del>₱{{deal['original_price'] | number}}</del></small></p>
                        <div class="discount-tag" [ngClass]="{'sold-out': deal['pivot']['quantity'] < 1}">
                          Get this for only ₱{{deal['pivot']['selling_price'] | number}}!
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <div class="text-deals-desc mb-lg-0">
                    <p class="text-hot-pink fw-semibold"><i class="far fa-calendar-alt mr-1"></i> Validity Period: {{
                      deal['pivot']['valid_from'] | date: 'MMMM dd, yyyy' }} - {{ deal['pivot']['valid_to'] | date:
                      'MMMM dd, yyyy' }}
                  </div>
                  <div>
                    <p class="mb-3 text-muted">
                      <i class="fas fa-location-dot fa-fw me-1"></i>
                      <span
                        *ngFor="let branch of deal['branches']; let i = index">{{branch['branch_name']}}<ng-container
                          *ngIf="i != deal['branches'].length - 1">, </ng-container> </span>
                    </p>

                    <div [innerHTML]="deal['description']" class=""></div>

                    <p class="fs-6 fw-medium">Additional Terms and Conditions</p>

                    <div class="mb-5">
                      <div [innerHTML]="deal['pivot']['additional_terms']"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot w-100">
      </div>
    </div>

    <div class="pt-7">
      <div class="row" *ngIf="has_bought == false && deal['pivot']['quantity'] > 0">
        <div class="col-md-10 offset-md-1">
          <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top w-100">
          <div class="sheet w-100 block">
            <div class="row">
              <div class="col-md-7">
                <img src="{{resource}}BeautyBash/header-1.svg" class="header-1 form-headers">

                <div *ngIf="is_authenticated == false">
                  <h3 class="h3 fw-semibold mb-4 mb-md-6">Sign in</h3>
                  <form [formGroup]="sign_in" (ngSubmit)="submitSignin()">
                    <mat-form-field appearance="fill">
                      <mat-label>Email address</mat-label>
                      <input matInput placeholder="Email address" formControlName="email">
                    </mat-form-field>
                    <!-- <div class="errors" *ngIf="email.invalid && (email.dirty || email.touched)" >					
                  <mat-error *ngIf="email.errors.required" class="text-danger">
                    Email is required.
                  </mat-error>
                  <mat-error *ngIf="email.errors.email && email.touched">
                    Must be a valid email format.
                  </mat-error>										
                </div> -->

                    <mat-form-field appearance="fill">
                      <mat-label>Password</mat-label>
                      <input matInput placeholder="Password" id="login_password"
                        [type]="hidePasswordIcon ? 'password' : 'text'" formControlName="password">
                      <button (click)="hidePasswordIcon = !hidePasswordIcon" type="button" mat-icon-button matSuffix>
                        <mat-icon>{{hidePasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </mat-form-field>

                    <!-- <div class="errors" *ngIf="password.invalid && (password.dirty || password.touched)" >					
                  <mat-error *ngIf="password.errors.required" class="text-danger">
                    Password is required.
                  </mat-error>				
                </div> -->

                    <div class="errors" *ngIf="inValidEmailOrPassword">
                      <mat-error>
                        Incorrect email or password.
                      </mat-error>
                    </div>

                    <button mat-flat-button color="secondary" class="mb-5 w-100 py-2" type="submit">
                      SIGN IN
                    </button>

                    <button mat-stroked-button color="secondary" class="py-2 w-100 mb-4" (click)="openRegisterModal()">
                      CREATE ACCOUNT
                    </button>
                  </form>
                </div>


                <div class="row mb-7" *ngIf="is_authenticated">
                  <div class="col-md-12">
                    <p class="mb-0">
                      You are signed in as {{user['user']['email']}}! <br>
                      Not you? <a mat-button class="text-hot-pink fw-semibold px-1 fw-bold" (click)="logout()">Sign in to another account.</a>
                    </p>
                    <div class="card mb-3">
                      <div class="card-body pink-bg">
                        <div class="row gx-0 mb-2">
                          <div class="col-auto pe-3">
                            <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                          </div>

                          <div class="col">
                            <p class="small text-muted-2 mb-0">
                              Hey, {{user['user']['first_name']}}! Take note that you are only allowed to buy <span class="fw-semibold">one Parlon Beauty Bash deal per bash event</span>. Use your Beauty Bash powers wisely!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <form>

                      <div class="mb-2">
                        <p class="fs-6 fw-medium mb-2">What's your name?</p>

                        <div class="card mb-3">
                          <div class="card-body yellow-bg">
                            <div class="row gx-0 mb-2">
                              <div class="col-auto pe-3">
                                <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                              </div>

                              <div class="col">
                                <p class="small text-muted-2 mb-0">
                                  <span class="fw-semibold">You cannot change the name after successful
                                    purchase.</span><br />
                                  Person who will redeem the package should present any valid Government-issued ID upon
                                  redemption.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="mb-5">
                          <h6 class="text-muted m-0">First Name*</h6>
                          <label for="">{{user['user']['first_name']}}</label>
                        </div>

                        <div class="mb-5">
                          <h6 class="text-muted m-0">Last Name*</h6>
                          <label for="">{{user['user']['last_name']}}</label>
                        </div>
                      </div>

                      <div>
                        <p class="fs-6 fw-medium mb-2">Your email address</p>

                        <div class="card mb-3">
                          <div class="card-body yellow-bg">
                            <div class="row gx-0 mb-2">
                              <div class="col-auto pe-3">
                                <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                              </div>

                              <div class="col">
                                <p class="small text-muted-2 mb-0">
                                  <span class="fw-semibold">E-mail is very important!</span><br />
                                  You cannot change the email after successful purchase. You will receive the E-Voucher
                                  through
                                  the email that you entered. Please double-check the email that you entered.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="mb-5">
                          <h6 class="text-muted m-0">Your email address*</h6>
                          <label for="">{{user['user']['email']}}</label>
                        </div>

                        <div class="mb-5">
                          <h6 class="text-muted m-0">Your mobile number*</h6>
                          <label for="">{{user['user']['contact_number']}}</label>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>

                <div class="mt-4" >
                  <img src="{{resource}}BeautyBash/header-2.svg" class="form-headers">

                  <ng-container>
                    <mat-form-field appearance="fill" class="w-100 " id="select-branch">
                      <mat-label>Branch</mat-label>
                      <mat-select name="branch" required [(ngModel)]="selected_branch" (selectionChange)="selectBranch($event.value)">
                        <mat-option *ngFor="let branch of deal['branches']; let i = index" [value]="branch">
                          {{branch['branch_name']}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p class="text-muted mt-3 mb-0" *ngIf="selected_branch != null">
                      <i class="far fa-map-marker-alt fa-fw me-2"></i>
                      <span>{{selected_branch['address']}}</span>
                    </p>
                  </ng-container>
                </div>

                <div class="mt-4">
                  <img src="{{resource}}BeautyBash/header-3.svg" class="form-headers">
                  <div class="payment-method-accordion mt-5">
                    <mat-accordion>
                      <mat-radio-group>
                        <ng-container *ngFor="let channel of payment_channels; let i = index;">

                          <mat-expansion-panel *ngIf="channel.template== 'custom_credit_card'">
                            <mat-expansion-panel-header (click)="paymentOptionClick(channel.channel_code)">
                              <div class="d-flex w-100 justify-content-between row-payment-channel  gx-4">
                                <div class="col">
                                  <mat-radio-button value="{{channel?.channel_code}}">
                                    {{channel.channel_name}}
                                  </mat-radio-button>
                                </div>
                                <div class="col-auto">
                                  <div class="card-logo">
                                    <img src="{{resource + channel.logo}}">
                                  </div>
                                </div>
                              </div>
                            </mat-expansion-panel-header>

                            <div>
                              <p class="mb-2">Your payment shall be processed safely and securely by a third-party
                                payment gateway provider. For your security, we do not store your credit card
                                information.</p>
                              <credit-card-form [paymentFormGroup]="checkoutForm.get('paymentFormGroup')"></credit-card-form>
                            </div>

                          </mat-expansion-panel>

                          <mat-expansion-panel class="nopad" *ngIf="channel.template== 'generic'  && deal['pivot']['selling_price'] > channel['minimum_value']">
                            <mat-expansion-panel-header (click)="paymentOptionClick(channel['channel_code'])">
                              <div class="w-100 row-payment-channel">
                                <div class="row">
                                  <mat-radio-button value="{{channel['channel_code']}}">
                                    <div> {{channel['channel_name']}}</div>
                                  </mat-radio-button>
                                </div>
                                <div>
                                  <div class="card-logo ps-2 pe-2">
                                    <img src="{{resource + channel['logo']}}">
                                  </div>
                                </div>
                              </div>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>



                        </ng-container>


                      </mat-radio-group>
                    </mat-accordion>
                  </div>

                  <div class="mt-3 mb-6">
                    <mat-checkbox id="eVoucherPolicy" class="eVoucherPolicy" color="primary" [(ngModel)]="policy">
                      <span>
                        By clicking the Pay Now button, I confirm that I have read and agree to <a
                          href="https://legal.parlon.ph/post/terms-of-use" target="_blank" class="text-hot-pink">Parlon
                          Terms of Use</a>, E-Voucher Additional Terms and Conditions, and <a
                          href="https://legal.parlon.ph/post/privacy-notice" target="_blank"
                          class="text-hot-pink">Parlon Privacy Notice</a>.
                      </span>

                    </mat-checkbox>
                  </div>

                  <div class="pos-relative">
                    <img src="{{resource}}BeautyBash/sparkle-4.png" alt="" class="btn-star-1">
                    <img src="{{resource}}BeautyBash/sparkle-4.png" alt="" class="btn-star-2">
                    <button mat-flat-button color="secondary" class="py-2 w-100 mb-4 d-none d-md-block pay-now-btn" [disabled]="pay_loading" (click)="submitRequest()">
                      PAY NOW
                      <mat-icon *ngIf="pay_loading"><mat-spinner color="accent" diameter="20">
                      </mat-spinner></mat-icon>
                    </button>  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot w-100">
        </div>
        <div class="col-md-4 price-breakdown">
          <div class="card bg-gold-fa my-5 mt-lg-0 mb-0 sticky">
            <div class="card-body pos-relative">
              <div class="row row-cols-1 gx-md-8">
                <div class="col">
                  <h3 class="h3 mb-2">{{deal['name']}}</h3>
                  <div class="d-flex mb-5 align-items-center">
                    <img src="{{resource + deal['parlon']['logo']}}" class="salon-logo">
                    <p class="fw-semibold mx-3 mb-0">{{deal['parlon']['business_name']}}</p>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-6">
                      <p class="mb-0">Original Price</p>
                    </div>
                    <div class="col-6">
                      <p class="text-end tabular-nums mb-0"><del>₱ {{deal['original_price'] | number}}</del></p>
                    </div>
                  </div>

                  <hr>

                  <table class="table table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td class="ps-0">Savings</td>
                        <td class="text-end tabular-nums pe-0">- ₱{{deal['pivot']['savings'] | number}}</td>
                      </tr>

                    </tbody>
                  </table>

                  <hr>

                  <div class="row align-items-center">
                    <div class="col-6">
                      <p class="fw-bold mb-0 text-hot-pink">Beauty Bash Price</p>
                    </div>
                    <div class="col-6">
                      <p class="text-end fw-bold tabular-nums mb-0 text-hot-pink">₱{{deal['pivot']['selling_price'] |
                        number}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          <div class="pay-only">
            Pay only ₱{{deal['pivot']['selling_price'] | number}}
          </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="has_bought != true && deal['pivot']['quantity'] == 0">
        <div class="col-md-10">
          <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top w-100">
          <div class="sheet w-100 block">
            <div class="row">
              <div class="col-md-8 offset-md-2 text-center">
                <img src="{{resource}}BeautyBash/bbsoldout.svg" alt="" width="150">
                <h2 class="fw-semibold mb-4">You just missed it!</h2>
                <p class="mb-2">This deal is now out of stock. Wait for the next Beauty Bash! Meanwhile check out other available deals.</p>
                <button mat-flat-button color="secondary" class="py-2 w-100 mb-4" [routerLink]="['/beauty-bash/deals']">
                  CHECK OUT BEAUTY BASH DEALS
                </button>
              </div>
            </div>
          </div>
          <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot w-100">
        </div>
      </div>

      <div class="row" *ngIf="has_bought == true">
        <div class="col-md-10">
          <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge top w-100">
          <div class="sheet w-100 block">
            <div class="row">
              <div class="col-md-8 offset-md-2 text-center">
                <img src="{{resource}}BeautyBash/bbcalendar.svg" alt="" width="150">
                <h2 class="fw-semibold mb-4">Hello again, {{user['user']['first_name']}}!</h2>
                <p class="mb-2">You already used your Beauty Bash powers last {{ bought_date | date:'MMMM dd, yyyy' }}. Take note that you can only buy one Parlon Beauty Bash deal per bash event. See you again on another Beauty Bash!</p>
                <h5 class="fw-semibold mb-4">We have so many deals in Parlon. Check them out.</h5>
                <button mat-flat-button color="secondary" class="py-2 w-100 mb-4" [routerLink]="['/all-parlon-deals']">
                  CHECK OUT PARLON DEALS
                </button>
              </div>
            </div>
          </div>
          <img src="{{resource}}BeautyBash/sheet-edge.png" alt="" class="sheet-edge bot w-100">
        </div>
      </div>
    </div>
  </div>

  

  <div class="circle top-left"></div>
  <div class="circle bot-left"></div>
  <div class="circle mid"></div>
  <img src="{{resource}}BeautyBash/sparkle-2.png" alt="" class="sparkle-1">
  <img src="{{resource}}BeautyBash/lightning.svg" alt="" class="lightning-1">
  <img src="{{resource}}BeautyBash/sparkle-2.png" alt="" class="sparkle-2">
</div>

<div class="mobile-pay-cta d-sm-block d-md-none">
  <div class="pos-relative">
    <img src="{{resource}}BeautyBash/sparkle-4.png" alt="" class="btn-star-1">
    <img src="{{resource}}BeautyBash/sparkle-4.png" alt="" class="btn-star-2">
    <button mat-flat-button color="secondary" class="py-2 w-100 pay-now-btn" [disabled]="pay_loading" (click)="submitRequest()">
      PAY NOW
      <mat-icon *ngIf="pay_loading"><mat-spinner color="accent" diameter="20">
      </mat-spinner></mat-icon>
    </button>  
  </div>
</div>

<ngx-smart-modal [customClass]="'wide-modal-custom'" #registerModal identifier="registerModal" [dismissable]="false">
	<ng-container>
		<div class="modal-body p-2">
			<div class="row">
				<div class="col">
					<app-registration-form (registerEmitter)="successRegister($event)"></app-registration-form>
				</div>
			</div>
		</div>
	</ng-container>	
	
</ngx-smart-modal>

<ngx-smart-modal [customClass]="'wide-modal-custom'" #otpModal identifier="otpModal" [dismissable]="false">
	<ng-container>
		<div class="modal-body p-2">
			<div class="row">
				<div class="col">
					<app-otp-form (otpEmitter)="otpEvent($event)"></app-otp-form>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>

<form id="cc-form" style="display: none;">
	<input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br/>
	<input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br/>
	<input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br/>
	<input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
</form>