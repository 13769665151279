import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { ParlonsListComponent } from './parlons-list.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { GridViewComponent } from './components/grid-view/grid-view.component';
import { RouterModule } from '@angular/router';
import { RouteModule } from "./routing.module";


@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    RouterModule,
    RouteModule
  ],
  declarations: [ParlonsListComponent, ListViewComponent, GridViewComponent ]
})
export class ParlonsListModule { }
