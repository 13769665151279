<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- If the Flickity has page dots, add `has-dots` class.
	- To set number of slides, change it on CSS
-->

<section class="py-6 py-md-7 overflow-hidden">
	<div class="container">
		<div class="row align-items-center justify-content-between justify-content-md-start mb-4 mb-md-5">
			<div class="col col-lg-auto">
				<h2 class="h2 fw-semibold mb-0">{{data['title']}}</h2>
			</div>

			<div class="col-12">
				<p class="text-header-subtitle mt-1 mb-0">{{data['subtitle']}}</p>
			</div>
		</div>

		<div class="flickity flickity-memberships flickity-overflow row gx-4">
			<div class="slide" *ngFor="let membership of data['memberships']">
				<div class="card card-membership-template">
					<div class="card-body pos-relative p-0">
						<a href="/membership/{{membership['parlon_slug']}}" class="stretched-link">
							<div class="ratio ratio-16x9 mb-3">
								<img src="{{resource + membership['membersip_card']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">

								<div class="deal-parlon-logo">
									<div class="ratio ratio-1x1">
										<img src="{{resource + membership['parlon_logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
									</div>
								</div>
							</div>
						</a>

						<div class="content px-2 px-sm-3">
							<p class="deal-parlon small text-muted w-md-80 mb-0">{{membership['title']}}</p>
							<p class="deal-parlon-location smaller text-muted clamp-1 mb-1">
								<i class="fas fa-location-dot fa-fw me-1"></i>
								<span *ngFor="let location of membership['locations']; let i = index">{{location}}<ng-container *ngIf="i != membership['locations'].length - 1">, </ng-container> </span></p>
							<p class="deal-name fs-6 fw-medium mb-5">{{membership['title']}}</p>

							<!-- <p class="deal-price" [innerHTML]="membership['subtitle']"></p> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>