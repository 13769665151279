import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlifeService {

  constructor() { }

  userInfo = {
      FIRST_NAME:'',
      LAST_NAME:'',
      EMAIL_ADDRESS:'',
      GCASH_NUMBER:''
  }
  
  private glifeUserInfo = new BehaviorSubject<any>(this.userInfo);
  getGlifeUserInfo = this.glifeUserInfo.asObservable();


  setGlifeUserInfo(value){
    this.glifeUserInfo.next(value);
  }

  
}
