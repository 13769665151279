import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { NetcoreService } from '../../../../services/netcore/netcore.service';
import { RestService } from '../../../../services/rest.service';
import { SharedService } from '../../../../services/shared.service';
declare var smartech: any;
@Component({
  selector: 'app-parlon-deals',
  templateUrl: './parlon-deals.component.html',
  styleUrls: ['./parlon-deals.component.scss']
})
export class ParlonDealsComponent implements OnInit {

  
  constructor(
    @Inject(WINDOW) private window: Window,
    private rest: RestService,
    private _shared: SharedService,
    private route: ActivatedRoute, 
    private _title: Title,
    public router: Router,
    private ngZone: NgZone,
    private netCoreService:NetcoreService
  ) { }

  data: any[];
  branch: any[];
  parlon: any[];
  resourceLink = this.rest.resourceLink();
  selectedDeals = [];
  isLoaded = false;
  dealsVisible: boolean;

  isScrolledDealSection= false;
  merchantName = "";

  onScroll(){

    if(!this.isScrolledDealSection && this.merchantName !=""){
      // this.netCoreService.dispatch('nudge_deal',{
      //   'nudge_deal': this.merchantName
      // })
    this.isScrolledDealSection = true;
    }

      
    
  }

  ngOnInit() {
    this.branch = this.route.parent.snapshot.data['branch'];
    this.merchantName = this.branch['branch_name'];    
    this._shared.currentParlon.subscribe((parlon) => {
      this.parlon = parlon;
      const page = this;
      this.branch['active_deals'].forEach(e => {
        const deals = {
            id: e['id'],
            deal_price: e['deal_price'],
            description: e['description'],
            thumb: this.resourceLink + e['primary_photo'],
            name: e['name'],
            original_price: e['original_price'],
            slug: e['slug'],
            discount_type: e['discount_type'],
            discount_value: e['discount_value'],
            valid_from: e['valid_from'],
            valid_to: e['valid_to']
        };
        page.selectedDeals.push(deals);
      });
    });

    this.getVisiblePageSections();
  }

  getVisiblePageSections() {
    this.rest.homepageSectionVisibility().subscribe(data => {
      this.ngZone.run(() => {
        const sections = data['data'];

        this.dealsVisible = sections.find(
          ({ section }) => section === 'Deals Tab'
        ).visible === 0;
      });
    })
  }
}
