import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OgPerksInnerComponent } from './og-perks-inner.component';

const routes: Routes = [
    { path:"", component: OgPerksInnerComponent }
];

@NgModule({
    exports: [RouterModule],
    imports:[RouterModule.forChild(routes)]
})

export class OgPerksInnerRouteModule{}