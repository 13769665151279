import { Component, Input, Output, OnInit, OnDestroy, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import * as Rx from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs/operators';


@Component({
  selector: 'personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['./personal-details-form.component.scss']
})
export class PersonalDetailsFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() personalDetailsFormGroup: FormGroup;
  @Input() branches: any = [];
  @Input() scope: string;
  @Output() selectedBranch = new EventEmitter();
  loading = false;
  submitted = false;
  hide = true;
  branch_available: any = [];
  
  public branchFilterCtrl: FormControl = new FormControl();

  constructor(
    private route: ActivatedRoute
  ) { }

   /** list of areas filtered by search keyword */
  private filteredBranchesSubject$ = new Rx.BehaviorSubject(this.branches);
  public filteredBranches$ = this.filteredBranchesSubject$.asObservable();
  private branchFilterCtrlSubscription: Rx.Subscription;

  ngOnInit() {
    this.branchFilterCtrlSubscription = this.branchFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterAreas();
      });

    this.filteredBranches$
      .pipe(
        filter(branches => !!branches.length),
        take(1),
      )
      .toPromise()
      .then(branches => {
        let branch_slug = this.route.snapshot.paramMap.get("branch-slug");
      
        if(branch_slug != null) {
          branches.forEach(branch => {
            if(branch['slug'] == branch_slug) {
              this.personalDetailsFormGroup.controls['branch_id'].setValue(branch['id']);
            }
          });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
   if ('branches' in changes && this.branches) {
     this.filterAreas();
   } 
  }

  ngOnDestroy() {
    if (this.branchFilterCtrlSubscription) {
      this.branchFilterCtrlSubscription.unsubscribe();
    }
  }

  branchChanged() {
    this.selectedBranch.emit({});
  }

  // convenience getter for easy access to form fields
  get f() { return this.personalDetailsFormGroup.controls; }

  protected filterAreas() {
    if (!this.branches) {
      return;
    }
    // get the search keyword
    let search = this.branchFilterCtrl.value;
    if (!search) {
      // console.log(this.branches);
      this.filteredBranchesSubject$.next(this.branches);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the areas
    this.filteredBranchesSubject$.next(
      this.branches.filter(branch => branch['branch_name'].toLowerCase().indexOf(search) > -1)
    );
  }
  
}
