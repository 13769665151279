import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlgoliaInsightService } from '../../../../services/algolia-insight/algolia-insight.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'deal-card-template',
  templateUrl: './deal-card-template.component.html',
  styleUrls: ['./deal-card-template.component.scss']
})
export class DealCardTemplateComponent implements OnInit, OnDestroy {
  
  constructor(private algoliaInsightService:AlgoliaInsightService, private router:Router) { }
  resource = environment.resource;
  @Input() templateClass;
  @Input() data;
  @Input() parlon_name;
  @Input() locations;
  @Input() selectedLocations;
  @Input() isLoggedIn;  
  @Input() userID;
  @Input() queryID;
  @Input() position;  

  insightType="";      

  ngOnInit() {
    console.log(this.data);
    let app = this;
    this.locations = this.locations.filter(this.onlyUnique);
    
    this.locations.sort(function(a, b){  
      return app.selectedLocations.indexOf(a.toLowerCase()) - app.selectedLocations.indexOf(b.toLowerCase());
    }).reverse();
    
  }

  ngOnDestroy(): void {      
  }

  clickedObjectIDs(){
    let {objectID} = this.data;
    let indexName = environment.deals;   
  // Construct the URL with the encoded params string as a query parameter
  // const urlWithParams = '/beauty-bag-deals/' + this.data['slug'] + '?insight-type='+this.insightType ;
  // window.location.href = urlWithParams;            
    const urlWithParams = '/beauty-bag-deals/' + this.data['slug'];
    if(this.insightType!="")
    this.router.navigate([urlWithParams], { queryParams: { 'insight-type': this.insightType } });
    else
    this.router.navigate([urlWithParams]);
  }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  checkLocation(value) {
    if(this.selectedLocations.includes(value.toLowerCase()))
      return true;
    return false;   
  }

}
