import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-redeem-modal',
  templateUrl: './redeem-modal.component.html',
  styleUrls: ['./redeem-modal.component.scss']
})
export class RedeemModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RedeemModalComponent>) { }

  ngOnInit() {
  }


  cancel(): void {
    this.dialogRef.close('cancel');
  }

  redeem(){
    this.dialogRef.close('redeem');
    
  }
}
