import { AfterViewInit, Component, OnInit, Input, ViewChild, Output,EventEmitter, OnChanges } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DealsService } from '../../../../services/deals/deals.service';
import { ContentService } from '../../../../services/content/content.service';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'deal-filters',
  templateUrl: './deal-filters.component.html',
  styleUrls: ['./deal-filters.component.scss']
})
export class DealFiltersComponent implements OnInit, OnChanges, AfterViewInit {

  dealsLocations: any = [];
  dealsParlons: any = [];
  allLocationsSelected=false;
  allParlonsSelected=false;
  @Input() selectedLocations: any = [];
  @Input() selectedParlons: any = [];
  @Input() selectedCategories: any = [];
  @Input() selectedSubCategories: any = [];
  @Input() dealsCategories: any = [];
  @Input() filterByUrl: any = {};
  panelOpenState = false;

  @ViewChild('selectLocation') selectLocation: MatSelect;
  @ViewChild('selectParlon') selectParlon: MatSelect;

  @Output() locationEvent = new EventEmitter<any>();
  @Output() parlonEvent = new EventEmitter<any>();
  @Output() categoryEvent = new EventEmitter<any>();
  @Output() resetEvent = new EventEmitter<any>();
  @Output() locationsEvent = new EventEmitter<any>();


  @Output() categoryEventV2 = new EventEmitter<any>();
  @Output() subCategoryEventV2 = new EventEmitter<any>();

  @Input() locationsV2 = [];
  @Input() parlonsV2 = [];
  @Input() categoriesV2 = [];
  @Input() facet_locations: any = [];

  filteredParlonsOption: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  parlonSearch = '';

  selectedIndex  = -1;
  selectedSubCat = "-1";
  constructor(
    private DealsService: DealsService,
    private ContentService: ContentService,
    private router: Router,
    private route: ActivatedRoute, 
  ) 
  { 
  }

  ngAfterViewInit() {    
  }



  ngOnInit() {
    this.filteredParlonsOption.next(this.parlonsV2);
  }

  ngOnChanges() {
    this.filteredParlonsOption.next(this.parlonsV2);
  }

  optionLocationClick() {
    let newStatus = true;

    let selectedOption= [];

    this.selectLocation.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }else{
        selectedOption.push(item.value);
      }
    });

    this.locationEvent.emit(selectedOption);
    this.allLocationsSelected = newStatus;
  }

  toggleAllLocationsSelection(){
    if (this.allLocationsSelected) {
      this.selectLocation.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectLocation.options.forEach((item: MatOption) => item.deselect());
    }
    this.selectLocation.selectionChange;
  }

  optionParlonClick() {
    let newStatus = true;
    let selectedOption = []
    this.selectParlon.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }else{
        selectedOption.push(item.value);
      }
    });
    
    this.parlonEvent.emit(selectedOption);
    this.allParlonsSelected = newStatus;
  }

  toggleAllParlonsSelection(){
    if (this.allParlonsSelected) {
      this.selectParlon.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectParlon.options.forEach((item: MatOption) => item.deselect());
    }
    this.selectParlon.selectionChange;
  }

  filterByCategory(value) {
    this.categoryEvent.emit(value);
  }

  sortByLocation(value) {    
    this.locationEvent.emit(value);
  }

  sortByParlon(value) {
    this.parlonEvent.emit(value);
  }

  // filterFragment() {
  //   if(this.router.parseUrl(this.router.url).fragment != null) {
  //     let selected = this.dealsCategories.find(item => item.slug === this.router.parseUrl(this.router.url).fragment);
  //     this.selectedCategories = [selected['category_name']];
  //     this.filterByCategory(this.selectedCategories);
  //   }
  //   if (this.dealsCategories.length > 0) {
  //     let category = [];
  //     this.dealsCategories.map((item) => {
  //       if (this.filterByUrl.category.includes(item.category_name.toLowerCase())) {
  //         category.push(item.category_name)
  //         this.selectedCategories = category
  //       }
  //     });
  //     this.filterByUrl.category = [];
  //   }
  // }

  resetFilters() {
    this.selectedLocations = [];
    this.selectedParlons = [];
    this.selectedCategories = [];
    this.resetEvent.emit();
  }

  resetFilter2(){
    this.selectedLocations = [];
    this.selectedParlons = [];
    // this.selectedCategories = [];
    this.parlonsV2 = [];
    this.resetEvent.emit();
  }


  sortByLocationV2(){

    let locations =this.facet_locations.filter((item)=>{
      return item.completed == true; 
    }).map((item) => {
      return item.highlighted;
    });
    this.locationEvent.emit(locations);
    // alert(1);
  }

  sortByParlonV2(){
    let parlons =this.parlonsV2.filter((item)=>{
      return item.completed == true; 
    }).map((item) => {
      return item.highlighted;
    });
    
    this.parlonEvent.emit(parlons);    
  }

  sortByCategory(category,value) {
    setTimeout(()=>{
      let findItem = this.categoriesV2.findIndex((item)=>{
        return item.slug == category.slug
      })
      if(this.categoriesV2[findItem].subcategories){
        this.categoriesV2[findItem].subcategories.forEach((subcat,indexSub)=>{
          this.categoriesV2[findItem].subcategories[indexSub].show = value;
          if(this.categoriesV2[findItem].subcategories[indexSub].slug == 'membership') {
            this.categoriesV2.forEach(cat => {
              cat.subcategories.forEach(val => {
                if(val.slug == subcat.slug) 
                  val.show = value;
              });
            });
          }
        });
      }
      let categories = this.categoriesV2.filter((item)=>{
        return item.show == true; 
      }).map((item) => {
        return item.slug;
      });
      this.categoryEventV2.emit(this.categoriesV2);
    })
    
  }

  sortBySubCategory(category,value,index,subcat) {
    setTimeout(()=>{
      let category_value = value;
      let findItem = this.categoriesV2.findIndex((item)=>{
        return item.slug == category.slug
      })
      // this.categoriesV2[findItem].subcategories.forEach((subcat,indexSub)=>{
      //   if(this.categoriesV2[findItem].subcategories[indexSub].show == true && indexSub != index)
      //     category_value = true;
      // });
      // this.categoriesV2[findItem].show = false;
      this.categoriesV2.forEach(cat => {
        cat.subcategories.forEach(val => {
          if(val.slug == subcat.slug) 
            val.show = value;
        });
      });
      this.categoryEventV2.emit(this.categoriesV2);
    })
  }


  showSub(name){    
    let findItem = this.categoriesV2.findIndex((item)=>{
      return item.slug.toLowerCase() == name.toLowerCase()
    })    

    if(this.categoriesV2[findItem].show){           
      this.categoriesV2.forEach((item,index)=>{      
          this.categoriesV2[index].show = false; 
          if(item.subcategories){
            item.subcategories.forEach((subcat,indexSub)=>{
              item.subcategories[indexSub].show =false;
            })         
          }          
      })   
      this.selectedIndex = -1;  
      this.categoryEventV2.emit('');
      this.selectedCategories = [];
    }else{
             
        this.categoriesV2[findItem].show = true;    
        this.selectedCategories = [this.categoriesV2[findItem]]    ;  
        this.categoriesV2.forEach((item,index)=>{
          if(index != findItem){
            this.categoriesV2[index].show = false;
          }  
          if(item.subcategories){
            item.subcategories.forEach((subcat,indexSub)=>{
              item.subcategories[indexSub].show =false;
            })  
          }          
          
        })
        this.selectedIndex = findItem;
        this.categoryEventV2.emit(this.categoriesV2[findItem].category_name);
      
    }

  }

  clickSub(sub){
    let catIndex =-1;
    let subCatIndex = -1;
    let subCatSlug="";
    this.categoriesV2.forEach((category, categoryIndex)=>{
      let findItem = category.subcategories.findIndex((item,index)=>{
        return item.slug == sub.slug
      })
      
      if(findItem>-1){
        catIndex = categoryIndex
        subCatIndex = findItem
        subCatSlug = this.categoriesV2[categoryIndex].subcategories[findItem].slug;
      }      
    })
    if(subCatSlug==this.selectedSubCat){
      this.categoriesV2[catIndex].subcategories[subCatIndex].show = false;
      this.selectedSubCat = '-1';
      this.subCategoryEventV2.emit('');
    }else{
      this.selectedSubCat = this.categoriesV2[catIndex].subcategories[subCatIndex].slug;      
      this.categoriesV2[catIndex].subcategories.forEach((item)=>{
        if(item.slug != this.selectedSubCat){
          item.show = false
        } else {
          this.categoriesV2[catIndex].subcategories[subCatIndex].show = true;
        }
      });
      this.subCategoryEventV2.emit(this.selectedSubCat);
    }
    
  }

  expand(option){
    if(option['expanded'] == true) {
      option['expanded'] = false;
    } else{
      option['expanded'] = true;
    }
  }

  parlonFilter(){
    if(this.parlonSearch == '') {
      this.filteredParlonsOption.next(this.parlonsV2);
    } else {
      let parlons = this.parlonsV2;
      let search =  parlons.filter(option => option.value.toLowerCase().includes(this.parlonSearch.toLowerCase()));
      this.filteredParlonsOption.next(search);
    }
  }

  sortByCategoryName(value) {
    return value.sort((a, b) => a.category_name.toLowerCase() > b.category_name.toLowerCase() ? 1 : -1);
  }

  categoryExpanded(option) {
    let expanded = false;
    option.subcategories.forEach(subcat => {
      if(subcat.show == true && subcat.category_name != 'Membership')
        expanded = true;
    });
    return expanded;
  }

  
   

}
