<div class="card card-search-result-deal-template bg-light-3">			
	<div class="card-body pos-relative">
		<div class="row flex-result-deal gx-4">
			<div class="col-5 col-img">
				<div class="ratio ratio-16x9 ratio-deal-img">
					<img src="{{ resource + deal.deal_img }}" alt="" loading="lazy" class="img-fluid obj-fit-cover">

					<div class="deal-savings-banner"
						*ngIf="deal.discountType == 'Value Discount'">
						<p>Save PHP {{ deal.discountValue | number }}</p>
					</div>

					<div class="deal-savings-banner"
						*ngIf="deal.discountType == 'Percentage Discount'">
						<p>{{ deal.discountValue | number }}% OFF</p>
					</div>

					<div class="deal-parlon-logo">
						<div class="ratio ratio-1x1">
							<img src="{{ resource + deal.parlon_logo }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
						</div>
					</div>
				</div>
			</div>

			<div class="col-7 col-content">
				<a (click)="clickedObjectIDs()" class="stretched-link link-text">
					<p class="fs-6 fw-medium text-deal-name clamp-2 mb-1">{{ deal.deal_name }}</p>
				</a>

				<p class="text-deal-price mb-2">
					<span class="fw-semibold me-2">₱{{ deal.deal_price | number }}</span>
					<span class="smaller text-muted text-decor-line-through">₱{{ deal.original_price | number }}</span>
				</p>

				<p class="smaller text-deal-parlon mb-0">
					<span class="fw-medium">{{ deal.merchant_name }}</span><br />
					<span class="text-muted">{{ deal.merchant_count }} branches</span>
				</p>
			</div>
		</div>
	</div>
</div>