import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AnnouncementbarService {

  constructor() { }
  private isAnnouncementBarExistingSubject = new BehaviorSubject<boolean>(false);
  isAnnouncementBarExisting$ = this.isAnnouncementBarExistingSubject.asObservable();

  updateAnnouncementBarExistingStatus(status: boolean): void {
    this.isAnnouncementBarExistingSubject.next(status);
  }

}
