import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material';
import { HomeRedesignComponent } from './home-redesign.component';
import { MessageBannerComponent } from './components/message-banner/message-banner.component';
import { FeaturedDealsSliderComponent } from './components/featured-deals-slider/featured-deals-slider.component';
import { FeaturedDealsGridComponent } from './components/featured-deals-grid/featured-deals-grid.component';
import { DealCategoriesComponent } from './components/deal-categories/deal-categories.component';
import { FeaturedDealsStackComponent } from './components/featured-deals-stack/featured-deals-stack.component';
import { ContentBannerComponent } from './components/content-banner/content-banner.component';
import { DealCollectionsComponent } from './components/deal-collections/deal-collections.component';
import { DealReelsComponent } from './components/deal-reels/deal-reels.component';
import { AdSpaceSliderComponent } from './components/ad-space-slider/ad-space-slider.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { FeaturedDealComponent } from './components/featured-deal/featured-deal.component';
import { SpotifyComponent } from './components/spotify/spotify.component';
import { StoriesComponent } from './components/stories/stories.component';
import { ParlonsNearbyComponent } from './components/parlons-nearby/parlons-nearby.component';
import { BrandFeatureStackComponent } from './components/brand-feature-stack/brand-feature-stack.component';
import { MembershipsSliderComponent } from './components/memberships-slider/memberships-slider.component';
import { PartnersComponent } from './components/partners/partners.component';
import { FeaturedPaymentsComponent } from './components/featured-payments/featured-payments.component';
import { HomeLoaderComponent } from './components/home-loader/home-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OgPerksGridComponent } from './components/og-perks-grid/og-perks-grid.component';
import { TeaserComponent } from './components/teaser/teaser.component';
import { StaticLoaderComponent } from './components/static-loader/static-loader.component';

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    MaterialModule,
    RouterModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    HomeRedesignComponent,
    MessageBannerComponent, 
    FeaturedDealsSliderComponent, 
    FeaturedDealsGridComponent, 
    DealCategoriesComponent, 
    FeaturedDealsStackComponent, 
    ContentBannerComponent,
    DealCollectionsComponent, 
    DealReelsComponent, 
    AdSpaceSliderComponent, 
    BookingsComponent, 
    FeaturedDealComponent, 
    SpotifyComponent, 
    StoriesComponent, 
    ParlonsNearbyComponent, 
    BrandFeatureStackComponent, 
    MembershipsSliderComponent, 
    PartnersComponent, 
    FeaturedPaymentsComponent, 
    HomeLoaderComponent, 
    OgPerksGridComponent, TeaserComponent, StaticLoaderComponent, 
  ]
})
export class HomeRedesignModule { }
