<div class="press-list">
  <ng-container *ngIf="branch['press'].length > 0">
    <div class="press-item" *ngFor="let press of branch['press']">
      <img src="{{resourceLink + press.thumbnail}}" class="thumbnail">
      <div class="details">
        <p class="press-title">{{press.title}}</p>
        <p class="publisher">{{press.publisher}}</p>
        <p class="date">{{press.date | date:'MMMM d, y'}} </p>
        <!-- <div [innerHTML]="press['text']" class="press-description"></div> -->
        <hr>
      </div>
    </div>
  </ng-container>
  
  <empty-container [text]="'No Press Available'" *ngIf="branch['press'].length === 0"></empty-container>
</div>

<ngx-smart-modal #pressModal identifier="pressModal">
  <h1>Title</h1>
  <p>Some stuff...</p>

  <button (click)="pressModal.close()">Close</button>
</ngx-smart-modal>

<!--  (click)="ngxSmartModalService.getModal('pressModal').open()" -->