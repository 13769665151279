<section style="background-color: white;" *ngIf="isLoaded == true">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="thankyou-container">
          <ng-container *ngIf="isSuccess == true">
            <img src="assets/images/gifts.svg" class="mw-100">
            <h3 class="h3 fw-semibold my-6">
              Your gift code<span *ngIf="response['purchase_count'] > 1">s</span> <span *ngIf="response['purchase_count'] > 1">&nbsp;are</span><span *ngIf="response['purchase_count'] == 1">&nbsp;is</span>  on the way!
            </h3>
            <button [routerLink]="['/gifting']" mat-flat-button color="secondary" class="px-7" *ngIf="response['parlon'] == null">
              SEND MORE GIFTS
            </button>
            <button [routerLink]="['/gifting',response['parlon']['slug']]" mat-flat-button color="secondary" class="px-7" *ngIf="response['parlon'] != null">
              SEND MORE GIFTS
            </button>
          </ng-container>
          <ng-container *ngIf="isFailed == true">
            <img src="assets/images/payment-failed.svg" class="mw-100">
            <h3 class="h3 fw-semibold my-6">
              Payment Failed!
            </h3>
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <p class="mb-6">The transaction couldn’t complete. Your selected payment method might not have enough balance or it might be experiencing issues or under maintenance. Please try again later.</p>
              </div>
            </div>
            <button [routerLink]="['/gifting']" mat-flat-button color="secondary" class="px-7" *ngIf="response['parlon'] == null">
              TRY AGAIN
            </button>
            <button [routerLink]="['/gifting',response['parlon']['slug']]" mat-flat-button color="secondary" class="px-7" *ngIf="response['parlon'] != null">
              TRY AGAIN
            </button>
          </ng-container>
          <ng-container *ngIf="isPending == true">
            <img src="assets/images/gifts.svg" class="mw-100">
            <h3 class="h3 fw-semibold my-6">
              Your gift code<span *ngIf="response['purchase_count'] > 1">s</span> <span *ngIf="response['purchase_count'] > 1">&nbsp;are</span><span *ngIf="response['purchase_count'] == 1">&nbsp;is</span> on the way!
            </h3>
            <button [routerLink]="['/gifting']" mat-flat-button color="secondary" class="px-7" *ngIf="response['parlon'] == null">
              SEND MORE GIFTS
            </button>
            <button [routerLink]="['/gifting',response['parlon']['slug']]" mat-flat-button color="secondary" class="px-7" *ngIf="response['parlon'] != null">
              SEND MORE GIFTS
            </button>
          </ng-container>
          <hr class="w-100 my-6" style="border: 1px solid #919191;">
            <h3 class="h3 fw-semibold mb-6">
              Check out these amazing <span *ngIf="response['parlon'] == null">Parlon</span><span *ngIf="response['parlon'] != null">{{response['parlon']['business_name']}}</span> deals!
            </h3>
          <div class="row">
            <div class="col-md-4"  *ngFor="let deal of response['random_deals'] | slice:0:3">
              <deal-card-recommendation [deal]="deal"></deal-card-recommendation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>