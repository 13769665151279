import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import {  ReplaySubject, Subject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SearchService } from '../../../services/search/search.service';
import { TypesenseSearchService } from '../../../services/typesense-search/typesense-search.service';
import { Deal } from '../../../_models/deal.model';
import { environment } from '../../../../environments/environment';
import { RestService } from '../../../services/rest.service';
import { SharedService } from '../../../services/shared.service';
import {  takeUntil } from 'rxjs/operators';
import { PaginationInstance } from 'ngx-pagination';
import { UserAccountService } from '../../../services/user-account/user-account.service';

@Component({
  selector: 'app-search-page-result',
  templateUrl: './search-page-result.component.html',
  styleUrls: ['./search-page-result.component.scss']
})
export class SearchPageResultComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService:SearchService,
    private rest: RestService,
    private _shared: SharedService,
    private userService:UserAccountService,
    private TypesenseSearchService:TypesenseSearchService,
    private el:ElementRef) { }
  
  @HostListener('window:resize', ['$event'])
    onResize() {
      this.getScreenWidth = window.innerWidth;                      
      this.getScreenHeight = window.innerHeight;
      if(this.getScreenWidth<=480){
        this.router.navigate(['/mobile/search']);
      } 
    }
  
  isLoading:boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  searchParam:string;
  recentSearches:string[]= [];
  recentViewed:string[]= [];
  popularSearches:any[] = [];
  location:string;
  resource = environment.resource;
  locationSelected: any = '';
  areas: any = [];
  toStr = JSON.stringify;
  myControl = new FormControl('');
  deals:Deal[] = [];
  parlons:any[]= [];  
  $userSubscription:Subscription|undefined;
  userId:string='';
  isLoggedIn:boolean = false;

        
   /** control for the MatSelect filter keyword */
   public areaFilterCtrl: FormControl = new FormControl();

   /** list of areas filtered by search keyword */
   public filteredAreas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

   protected _onDestroy = new Subject<void>();


   public deal_config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 50,
    currentPage: 0,
    totalItems:0
  }

  public parlon_config: PaginationInstance = {
    id: 'custom2',
    itemsPerPage: 50,
    currentPage: 0,
    totalItems:0
  }


  nbPagesDeals:number =0;
  nbPagesParlons: number =0;
  totalRecordsDeals:number = 0;
  totalRecordsParlons:number =0;
  params:any;

  currentPageDeals = 0;

   onSelectValueChange(value){
    
    this.parlons =[];
    this._shared.changeSearchArea(this.locationSelected);
   
  }

  discountedPrice = (price:number, discount:number) =>{
    let d = price * (discount /100);
    return price - d;
  }

  viewDeal = (deal:any) =>{
    this.searchService.setRecentViewed(this.recentViewed,deal);    
  }


  ngOnInit(): void {

    if(this.getScreenWidth<=480){
      this.router.navigate(['/mobile/search']);
    } 

    this.$userSubscription= this.userService.getLoggedInUser.subscribe((data:any)=>{
      if(data){           
        if(data.data){
          this.isLoggedIn = true;
          this.userId = data.user.id;          
        }               
      }
    });

    this.recentSearches = this.searchService.getRecentSearches();
    this.recentViewed = this.searchService.getRecentViewed();
    // this.searchService.getPopularSearches().subscribe((data)=>{
    //   this.popularSearches = data.hits.splice(0,5);      
    // })    
    this.recentSearches = this.recentSearches.splice(0,3);    

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.searchParam = params.get('value');
      let location = params.get('location');      
      this.location = location;
        this.TypesenseSearchService.search(this.searchParam, location).subscribe(data=>{          
          // let deal_queryID =data.deal_queryID;
          // let parlon_queryID = data.parlon_queryID;
          data['results'].forEach(res => {
            if(res['request_params']['collection_name'] == 'Deals') {
            this.nbPagesDeals = Math.ceil(res['found']/this.deal_config.itemsPerPage);
            this.totalRecordsDeals = res['found']; 
            this.deal_config.totalItems = res['found'];
            this.deal_config.currentPage = res['page'];
            this.currentPageDeals = res['page'];
            this.deals = res['hits'].map((item:any,index)=>{
              return{
              position:index+1,  
              searchInput:this.searchParam,
              objectID:'',
              queryID:'',
              discountType:item['document'].discount_type,
              discountValue:item['document'].discount_value,
              deal_id: item['document'].id,
              deal_name:item['document'].name,
              deal_price:item['document'].deal_price, 
              original_price: item['document'].original_price, 
              merchant_name: item['document'].parlon, 
              merchant_count: item['document'].branches.length, 
              deal_img:item['document'].logo, 
              parlon_logo:item['document'].parlon_logo, 
              slug:item['document'].slug
              }
            })
            } else if(res['request_params']['collection_name'] == 'Parlons') {
              this.nbPagesParlons = Math.ceil(res['found']/this.parlon_config.itemsPerPage);
              this.totalRecordsParlons =res['found']; 
              this.parlon_config.totalItems = res['found']; 
              this.parlon_config.currentPage = res['page'];
              this.parlons = res['hits'].map((item:any)=>({
                slug:item['document'].parlon_slug,
                services:item['document'].services.splice(0,3),
                branches:item['document'].branches,
                parlon_name:item['document'].parlon_name,
                parlon_logo:this.resource+item['document'].logo,
                queryID:'',
                objectID:'',
                searchInput:this.searchParam
              }));
            }
          });
          
          
          // this.params = this.getQueryParams(data.params);
          this.isLoading = true;  
        })            
    });


    this.rest.getLocations().subscribe((data: {}) => {
      this.areas = data['data'];
      this.filteredAreas.next(this.areas);
    });
    this._shared.searchAreaValue.subscribe(area => {
      this.locationSelected = area;
    });

    this.areaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAreas();
      });

    
  }

  protected filterAreas() {
    if (!this.areas) {
      return;
    }
    // get the search keyword
    let search = this.areaFilterCtrl.value;
    if (!search) {
      this.filteredAreas.next(this.areas);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the areas
    this.filteredAreas.next(
      this.areas.filter(area => area.location_name.toLowerCase().indexOf(search) > -1)
    );
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  goToTopDeals() {
    const checkpoint: HTMLElement = this.el.nativeElement.querySelector('#deals');
    const topOffset = 100; // Adjust the value as needed
  
    window.scrollTo({
      top: checkpoint.offsetTop - topOffset,
      behavior: 'smooth'
    });
  }

 

  goToTopParlons() {
    const checkpoint: HTMLElement = this.el.nativeElement.querySelector('#parlons');
    const topOffset = 100; // Adjust the value as needed
  
    window.scrollTo({
      top: checkpoint.offsetTop - topOffset,
      behavior: 'smooth'
    });
  }
  
  

  pageChangeEventDeals(event: number){     
    
    this.goToTopDeals();
    this.deal_config.currentPage= event; 
    this.TypesenseSearchService.search(this.searchParam,this.location,this.deal_config.currentPage).subscribe((data)=>{      
      data['results'].forEach(res => {
        if(res['request_params']['collection_name'] == 'Deals') {
        this.nbPagesDeals = Math.ceil(res['found']/this.deal_config.itemsPerPage);
        this.totalRecordsDeals = res['found']; 
        this.deal_config.totalItems = res['found'];
        this.deal_config.currentPage = res['page'];
        this.currentPageDeals = res['page'];
        this.deals = res['hits'].map((item:any,index)=>{
          return{
          position:index+1,  
          searchInput:this.searchParam,
          objectID:'',
          queryID:'',
          discountType:item['document'].discount_type,
          discountValue:item['document'].discount_value,
          deal_id: item['document'].id,
          deal_name:item['document'].name,
          deal_price:item['document'].deal_price, 
          original_price: item['document'].original_price, 
          merchant_name: item['document'].parlon, 
          merchant_count: item['document'].branches.length, 
          deal_img:item['document'].logo, 
          parlon_logo:item['document'].parlon_logo, 
          slug:item['document'].slug
          }
        })
        }
      });
    })
  }


  pageChangeEventParlons(event: number){   
    this.goToTopParlons();      
    this.parlon_config.currentPage = event;
    this.TypesenseSearchService.search(this.searchParam,this.location,this.parlon_config.currentPage).subscribe((data)=>{    
      data['results'].forEach(res => {
        if(res['request_params']['collection_name'] == 'Parlons') {
          this.nbPagesParlons = Math.ceil(res['found']/this.parlon_config.itemsPerPage);
          this.totalRecordsParlons =res['found']; 
          this.parlon_config.totalItems = res['found']; 
          this.parlon_config.currentPage = res['page'];
          this.parlons = res['hits'].map((item:any)=>({
            slug:item['document'].parlon_slug,
            services:item['document'].services.splice(0,3),
            branches:item['document'].branches,
            parlon_name:item['document'].parlon_name,
            parlon_logo:this.resource+item['document'].logo,
            queryID:'',
            objectID:'',
            searchInput:this.searchParam
          }));
        }
      });
    })
  }

  replacePlusWithSpace(str) {
    return str.replace(/\+/g,' ');
  }

  getQueryParams(url) {
    const paramStr = url.slice(url.indexOf('?') + 1);
    if (!paramStr) return {};  
    const params = paramStr.split('&').reduce((acc, param) => {
      const [key, val] = param.split('=');
      acc[key] = decodeURIComponent(val);
      return acc;
    }, {});
  
    if (params['optionalFilters']) {
      const optionalFilters = JSON.parse(params['optionalFilters'].replace(/\//g, ""));
      params['optionalFilters'] = optionalFilters.map((filter) => filter.map(this.replacePlusWithSpace));
    }
  
    if (params['facetFilters']) {
      const facetFilters = JSON.parse(params['facetFilters'].replace(/\//g, ""));
      params['facetFilters'] = facetFilters.map((filter) => filter.map(this.replacePlusWithSpace));
    }  
    return params;
  }

}
