import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BeautyBashService } from '../../services/beauty-bash/beauty-bash.service';

@Component({
  selector: 'app-beauty-bash-thankyou',
  templateUrl: './beauty-bash-thankyou.component.html',
  styleUrls: ['./beauty-bash-thankyou.component.scss']
})
export class BeautyBashThankyouComponent implements OnInit {

  resource = environment.resource;
  result:any;
  data: object;
  response: object;
  isLoaded = false;

  constructor(
    private UserAccountService: UserAccountService,
    private route: ActivatedRoute,
    private BeautyBashService:BeautyBashService,
  ) { }

  ngOnInit() {
      let page =this;
      let url_string = window.location.href;
      var url = new URL(url_string);
      page.isLoaded = false;
      let refid = url.searchParams.get("referenceID");
      this.BeautyBashService.getResult(refid).subscribe((data: {}) => {  
        this.result = data;
        this.isLoaded = true;
    });
  }

}
