<div [routerLink]="['/beauty-bag-deals', deal['slug']]">
  <img src="{{ resource + deal['primary_photo']}}" class="w-100 deal-img">
  <div class="details">
    <p class="my-2 text-secondary">{{deal['name']}}</p>
    <p class="mb-2">
      <span *ngIf="deal['discount_type'] == 'Percentage Discount'" class="discount">{{
        deal['discount_value'] | number }}% OFF</span>
      <span *ngIf="deal['discount_type'] == 'Value Discount'" class="discount">Save ₱{{
        deal['discount_value'] | number }}</span>
    </p>
    <p><span class="fw-semibold">₱{{deal['deal_price'] | number}}</span>&nbsp;<small><del>₱
          {{(deal['deal_price'] + deal['computed_discount']) | number}}</del></small></p>
  </div>
</div>