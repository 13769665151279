<div class="content-list">
	<ng-container *ngFor="let list of data">
		<div class="content-panel" *ngIf="list['salons'].length > 0">
			<div class="panel-header">
				<div class="d-flex align-items-center gap-5">
					<div class="col-auto">
						<h2 id="grid-{{list.letter}}" class="h2 text-header-title">{{list.letter}}</h2>
					</div>
	
					<div class="col">
						<hr class="panel-header-divider">
					</div>
				</div>
			</div>
	
			<div class="panel-body">
				<div class="row flex-parlons-grid row-cols-3 row-cols-sm-4 row-cols-md-5 row-cols-lg-6 gx-4 gy-5">
	
					<div class="col" *ngFor="let salon of list['salons']">
						<a [routerLink]="['/parlon',salon['slug']]" class="link-static">
							<div class="ratio ratio-4x3">
								<img src="{{resource + salon['logo']}}?tr=w-180" alt="" loading="lazy" class="img-fluid obj-fit-contain">
							</div>
						</a>
	
						<div class="d-flex gap-1 gap-md-2 mt-2">
							<span class="icon" *ngIf="salon['branches_with_booking'] > 0"><i class="fak fa-booking fa-fw"></i></span>
							<span class="icon" *ngIf="salon['active_deal_count'] > 0"><i class="fak fa-deals fa-fw"></i></span>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</ng-container>
</div>