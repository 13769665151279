import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { DealsService } from '../../../../services/deals/deals.service';
import { GiftingService } from '../../../../services/gifting/gifting.service';
import { UserAccountService } from '../../../../services/user-account/user-account.service';
import { NetcoreService } from '../../../../services/netcore/netcore.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { InvokeFunctionExpr, ThrowStmt } from '@angular/compiler';
import { of } from 'rxjs';
import { FacebookService } from 'ngx-facebook';

declare let fbq:Function;

declare var Flickity: any;

@Component({
  selector: 'deal-form',
  templateUrl: './deal-form.component.html',
  styleUrls: ['./deal-form.component.scss']
})
export class DealFormComponent implements OnInit, OnChanges {

  @Input() data:any;
  resource = environment.resource;
  buying_for:string = "me";
  bagId = localStorage.getItem('bagId');
  buyerInfo:any = localStorage.getItem('buyerInfo');
  purchase_count = 1;
  purchase_count_arr = [1];
  purchase_limit = [];  
  purchaseForm: FormGroup;
  redeemer_tab = 1;
  redeemer_fields_tab = 1;
  same_redeemer = false;
  same_branches = false;
  formValid = false;
  same_branch_value:any;
  submitted = false;
  info_editable = true;
  loadingUpdate = false;
  same_card = false;
  is_authenticated = false;
  api_token = null;
  giftingData:any;
  card_slider;
  redeemer_branches = new FormArray([
    new FormControl('')
  ]);
  response:object = {
    'more_deals': []
  };
  redeemer_details = new FormArray([
    new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'first_name': new FormControl(null, Validators.required),
      'last_name': new FormControl(null, Validators.required),
      're_enter_email': new FormControl(null, [Validators.required, Validators.email]),
      'contact_number': new FormControl(null, Validators.required),
      'surprise': new FormControl(false),
      'gift_card_id': new FormControl(null)
    }),
  ]);
  sign_in:FormGroup = this._formBuilder.group({
    email:['', [Validators.required, Validators.email]],
    password:['', Validators.required]
  });
  hidePasswordIcon:boolean = true;
  sign_submitted = false;
  get email(){return this.sign_in.get('email')};
  get password(){return this.sign_in.get('password')};
  inValidEmailOrPassword:boolean = false;
  payload: object;

  insightType="";

  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private DealService: DealsService,
    private NgxSmartModalService: NgxSmartModalService,
    private el: ElementRef,
    private UserAccountService: UserAccountService,
    private GiftingService: GiftingService,
    private NetcoreService:NetcoreService
  ) { 
    this.payload = {
      bag_id: '', 
      deal_slug: '', 
      parlon_id: '', 
      buying_for: '', 
      customer_details: {
          firstname: '', 
          lastname: '', 
          email_address: '',
          confirm_email_confirmation: '',
          mobile_number: ''
      },
      vouchers_count: '', 
      same_redeemers: true,
      same_branches: true, 
      redeemers: [ 
      ]
    }
   }

  ngOnInit() {
    this.same_branches = false;
    this.purchaseForm = new FormGroup({
      'personalDetailsFormGroup': new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        're_enter_email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
      }),
      'sameRedeemerFormGroup': new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        're_enter_email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
        'surprise': new FormControl(false)
      }),
    });
    const app = this;
    this.UserAccountService.getLoggedInUser.subscribe(data=>{
      if(data != null) {
        this.api_token = data.data;
        this.buyerInfo = {
          "firstname": data['user']['first_name'],
          "lastname":  data['user']['last_name'],
          "email_address": data['user']['email'],
          "confirm_email_confirmation": data['user']['email'],
          "mobile_number": data['user']['contact_number']
        }
        this.purchaseForm.controls.personalDetailsFormGroup.patchValue({
          email:data['user']['email'],
          re_enter_email:data['user']['email'],
          first_name:data['user']['first_name'],
          last_name:data['user']['last_name'],
          contact_number: data['user']['contact_number']
        });
        localStorage.setItem('bagId',data['bagSummary']['bag_id']);
        app.DealService.changeCartCount(data['bagSummary']['deals'].length);
        localStorage.setItem('bagCount',data['bagSummary']['deals'].length);
        localStorage.setItem('buyerInfo',JSON.stringify(data['user']));
        this.is_authenticated = true;
      }
    })
    if(this.buyerInfo != null) {
      let info;
      if(this.tryParseJSONObject(this.buyerInfo)) {
        info = JSON.parse(this.buyerInfo);
      } else {
        info = this.buyerInfo;
      }
      this.purchaseForm.controls.personalDetailsFormGroup.patchValue({
        email:info['email_address'],
        re_enter_email:info['email_address'],
        first_name:info['firstname'],
        last_name:info['lastname'],
        contact_number:info['mobile_number']
      });
    }
    this.GiftingService.getGiftingData().subscribe((data: {}) => {
      this.giftingData = data;
    });

    this.insightType = this.route.snapshot.queryParamMap.get('insight-type');
  }

  ngOnChanges() {
    if(this.data['deal']['quantity'] <= 5) {
      this.purchase_limit = Array(this.data['deal']['quantity']).fill(1).map((x,i)=>i);
    } else {
      this.purchase_limit = Array(5).fill(1).map((x,i)=>i);
    }
  }

  /* Form Functions */

  changeBuyingFor(value) {
    this.buying_for = value;
    this.setupCardSlider();
  }

  changePurchaseCount(value) {
    this.same_branches = false;
    this.same_branch_value = null;
    this.purchase_count = value;
    this.purchase_count_arr = [];
    this.purchase_count_arr = Array(this.purchase_count).fill(1).map((x,i)=>i);
    while (this.redeemer_branches.length !== 0) {
      this.redeemer_branches.removeAt(0);
    }
    while (this.redeemer_details.length !== 0) {
      this.redeemer_details.removeAt(0);
    }
    for (let index = 0; index < value; index++) {
      this.redeemer_branches.push(new FormControl(''));
      this.redeemer_details.push(
        new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        're_enter_email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
        'surprise': new FormControl(false),
        'gift_card_id': new FormControl(null)
      }));
    }
    setTimeout(() => {
      let radio: HTMLElement = this.el.nativeElement.querySelectorAll('.redeemer-radio'); 
      for (let index = 0; index < this.purchase_count; index++) {
        const e = radio[index];
        e.classList.remove('error');
        e.classList.remove('completed');
      }
      this.redeemer_tab = 1;
    }, 500);
  }

  changeRedeemerTab(value) {
    this.redeemer_tab = value;
    if(this.buying_for == 'se')  {
      this.card_slider.select(0);
    }
  }

  onChangeBranch(value){
    this.NetcoreService.dispatch('view deal branch', {
      "dealid": this.data['deal']['id'],
      "deal": this.data['deal']['name'],
      "dealcategory": this.data['deal']['category'],
      "dealprice": this.data['deal']['price_current'],
      "dealsubcategory": this.data['deal']['subcategories'].toString(),
      "merchant": this.data['parlon']['name'],
      "dealbranch": value['name']
    });
    this.same_branch_value = value;
  }

  onChangeSameBranch(value) {
    this.NetcoreService.dispatch('view deal branch', {
      "dealid": this.data['deal']['id'],
      "deal": this.data['deal']['name'],
      "dealcategory": this.data['deal']['category'],
      "dealprice": this.data['deal']['price_current'],
      "dealsubcategory": this.data['deal']['subcategories'].toString(),
      "merchant": this.data['parlon']['name'],
      "dealbranch": value['name']
    });
    for (let index = 0; index < this.redeemer_branches.length; index++) {
      const e = this.redeemer_branches.controls[index];
      e.patchValue(value);
    }
  }
  
  checkSameBranch() {
    if(this.same_branches == true) {
      const tab = this.redeemer_tab-1;
      this.same_branch_value = this.redeemer_branches.controls[tab].value;
    } else {
      for (let index = 0; index < this.redeemer_branches.length; index++) {
        const e = this.redeemer_branches.controls[index];
        e.patchValue(this.same_branch_value);
      }
    }
  }

  addToCart() {
    let app = this;
    if(!app.formValidity()) {
      this.flagInvalid();
      this.scrollToFirstInvalidControl();
      return false;
    }
    this.payload['bag_id'] = localStorage.getItem('bagId');
    this.payload['deal_slug'] = this.route.snapshot.paramMap.get("slug");
    this.payload['parlon_id'] = this.data['parlon']['id'];
    this.payload['buying_for'] = this.buying_for;
    this.payload['customer_details']['firstname'] = this.purchaseForm.value.personalDetailsFormGroup.first_name;
    this.payload['customer_details']['lastname'] = this.purchaseForm.value.personalDetailsFormGroup.last_name;
    this.payload['customer_details']['email_address'] = this.purchaseForm.value.personalDetailsFormGroup.email;
    this.payload['customer_details']['confirm_email_confirmation'] = this.purchaseForm.value.personalDetailsFormGroup.re_enter_email;
    this.payload['customer_details']['mobile_number'] = this.purchaseForm.value.personalDetailsFormGroup.contact_number;
    this.payload['vouchers_count'] = this.purchase_count;
    this.payload['same_branches'] = this.same_branches;
    if(this.buying_for == 'me') {
      this.payload['same_redeemers'] = true;
      this.payload['redeemers'] = [];
      if(this.same_branches == true) {
        for (let index = 0; index < this.purchase_count; index++) {
          this.payload['redeemers'].push({'branch_id': this.same_branch_value['id']});
        }
      } else {
        for (let control of this.redeemer_branches.controls) {
          this.payload['redeemers'].push({'branch_id': control.value.id});
        }
      }
    } else {
      this.payload['redeemers'] = [];
      if(this.same_redeemer == true) {
        this.payload['same_redeemers'] = true;
        for (let index = 0; index < this.purchase_count; index++) {
          this.payload['redeemers'].push(
            {
              "firstname": this.purchaseForm.value.sameRedeemerFormGroup.first_name,
              "lastname": this.purchaseForm.value.sameRedeemerFormGroup.last_name,
              "email_address":this.purchaseForm.value.sameRedeemerFormGroup.email,
              "confirm_email_address": this.purchaseForm.value.sameRedeemerFormGroup.re_enter_email,
              "mobile_number": this.purchaseForm.value.sameRedeemerFormGroup.contact_number,
              "surprise": this.purchaseForm.value.sameRedeemerFormGroup.surprise,
              "branch_id": null,
              "gift_card_id": this.redeemer_details.controls[index]['controls']['gift_card_id'].value
            }
          );
        }
      } else {
        this.payload['same_redeemers'] = false;
        for (let group of this.redeemer_details.controls) {
          this.payload['redeemers'].push(
            {
              "firstname": group['controls'].first_name.value,
              "lastname": group['controls'].last_name.value,
              "email_address":group['controls'].email.value,
              "confirm_email_address": group['controls'].re_enter_email.value,
              "mobile_number": group['controls'].contact_number.value,
              "surprise": group['controls'].surprise.value,
              "branch_id": null,
              "gift_card_id": group['controls'].gift_card_id.value,
            }
        );
       }
      }
      if(this.same_branches == true) {
          for (let index = 0; index < this.payload['redeemers'].length; index++) {
            this.payload['redeemers'][index]['branch_id'] = this.same_branch_value['id'];
          }
      } else {
        for (let index = 0; index < this.payload['redeemers'].length; index++) {
          this.payload['redeemers'][index]['branch_id'] = this.redeemer_branches.controls[index].value.id;
        }
      }
      
    }
    if(this.api_token != null)
      this.payload['api_token'] = this.api_token;
    const deal = this.data['deal'];
    let slug = this.route.snapshot.paramMap.get("slug");
    this.submitted = true;
    app.DealService.addToBag(this.payload).subscribe((data: {}) => {
      this.submitted = false;
      if(data['success'] == true) {
      fbq('track', 'AddToCart', { 
        value: deal['price_current'] * this.purchase_count,
        currency: environment.currency,
        content_name: deal['name'],
        num_items: this.purchase_count,
        content_type: 'product', // constant
        content_ids: deal['deal_number'] // Deal ID
      });
      app.response = data['data'];
      localStorage.setItem('bagId',data['data']['bag_id']);
      app.DealService.changeCartCount(data['data']['bag_item_count']);
      localStorage.setItem('bagCount',data['data']['bag_item_count']);
      localStorage.setItem('buyerInfo',JSON.stringify(app.payload['customer_details']));
      let  nc_branches = [];
      for (let index = 0; index < this.redeemer_branches.value.length; index++) {
        const e = this.redeemer_branches.value[index];
        if(nc_branches.indexOf(e['name']) === -1) {
          nc_branches.push(e['name']);
      }
      }
      // netcore
      let netcore_user = {
        'email': this.payload['customer_details']['email_address'],
        'mobile': this.payload['customer_details']['mobile_number'],
        'FIRST_NAME': this.payload['customer_details']['firstname'],
        'LAST_NAME':  this.payload['customer_details']['lastname'],
      }
      this.NetcoreService.identify(netcore_user);
      app.NgxSmartModalService.getModal('successModal').open();
      }
    });
  }

  flagInvalid()  {
    let app = this;
    let invalid = [];
    Object.keys(app.purchaseForm.controls.personalDetailsFormGroup['controls']).forEach(function(key) {
      app.purchaseForm.controls.personalDetailsFormGroup['controls'][key].markAsTouched();
    });
    if(this.same_branches == true) {
      if(this.same_branch_value == null || this.same_branch_value == '') {
        for (let index = 0; index < this.purchase_count; index++) {
          if (invalid.indexOf(index)==-1) invalid.push(index);      
        }
      }
    } else {
      for (let index = 0; index < this.redeemer_branches.controls.length; index++) {
        const ctrl = this.redeemer_branches.controls[index];
        ctrl.markAsTouched();
        if(ctrl.status == 'INVALID') {
          if (invalid.indexOf(index)==-1) invalid.push(index);
        }
      }
    } 
    if(this.buying_for == 'se') {
      if(this.same_redeemer == true) { 
        Object.keys(app.purchaseForm.controls.sameRedeemerFormGroup['controls']).forEach(function(key) {
          app.purchaseForm.controls.sameRedeemerFormGroup['controls'][key].markAsTouched();
        });
        for (let index = 0; index < this.purchase_count; index++) {
          if (invalid.indexOf(index)==-1) invalid.push(index);      
        }
      } else {
        for (let index = 0; index < this.redeemer_details.controls.length; index++) {
          const ctrl = this.redeemer_details.controls[index];
          Object.keys(ctrl['controls']).forEach(function(key) {
            ctrl['controls'][key].markAsTouched();
          });
          if(ctrl.status == 'INVALID') {
            if (invalid.indexOf(index)==-1) invalid.push(index);
          }
        }
      }
    }
    let radio: HTMLElement = this.el.nativeElement.querySelectorAll('.redeemer-radio'); 
    for (let index = 0; index < this.purchase_count; index++) {
      const e = radio[index];
      e.classList.remove('error');
      e.classList.remove('completed');
      if(invalid.includes(index)) {
        e.classList.add('error');
      } else {
        e.classList.add('completed');
      }
    }
  }

  tabValidity(index)  {
    let app = this;
    let valid = true;
    if(this.same_branches == true) {
      if(this.same_branch_value == null || this.same_branch_value == '') {
        valid = false;
      }
    } else {
      let branchCtrl = this.redeemer_branches.controls[index];
      branchCtrl.markAsTouched();
      if(branchCtrl.status == 'INVALID') {
        valid = false;
      }
    } 
    if(this.buying_for == 'se') {
      if(this.same_redeemer == true) { 
        Object.keys(app.purchaseForm.controls.sameRedeemerFormGroup['controls']).forEach(function(key) {
          app.purchaseForm.controls.sameRedeemerFormGroup['controls'][key].markAsTouched();
        });
        if(app.purchaseForm.controls.sameRedeemerFormGroup.status == "INVALID") {
          valid = false;
        }
      } else {
        const redeemerCtrl = this.redeemer_details.controls[index];
        redeemerCtrl.markAsTouched();
        if(redeemerCtrl.status == 'INVALID') {
          valid = false;
        }
      }
    }
    return valid;
  }


  formValidity()
  {
    const app = this;
    let valid = true;
    if(app.purchaseForm.controls.personalDetailsFormGroup.status == "INVALID") {
      valid = false;
    }
    if(this.same_branches == true) {
      if(this.same_branch_value == null || this.same_branch_value == '') {
        valid = false;
      }
    } else {
      for (let index = 0; index < this.redeemer_branches.controls.length; index++) {
        const ctrl = this.redeemer_branches.controls[index];
        if(ctrl.status == "INVALID") {
          valid = false;
        }
          
      }
    } 
    if(this.buying_for == 'se') {
      if(this.same_redeemer == true) {
        if(this.purchaseForm.controls.sameRedeemerFormGroup['controls'].surprise.value == true) {
          this.purchaseForm.controls.sameRedeemerFormGroup['controls'].email.setValidators(null);
          this.purchaseForm.controls.sameRedeemerFormGroup['controls'].re_enter_email.setValidators(null);
          this.purchaseForm.controls.sameRedeemerFormGroup['controls'].contact_number.setValidators(null);
        } else {
          this.purchaseForm.controls.sameRedeemerFormGroup['controls'].email.setValidators([Validators.required, Validators.email]);
          this.purchaseForm.controls.sameRedeemerFormGroup['controls'].re_enter_email.setValidators([Validators.required, Validators.email]);
          this.purchaseForm.controls.sameRedeemerFormGroup['controls'].contact_number.setValidators(Validators.required);
        }
        this.purchaseForm.controls.sameRedeemerFormGroup['controls'].email.updateValueAndValidity();
        this.purchaseForm.controls.sameRedeemerFormGroup['controls'].re_enter_email.updateValueAndValidity();
        this.purchaseForm.controls.sameRedeemerFormGroup['controls'].contact_number.updateValueAndValidity();
        if(app.purchaseForm.controls.sameRedeemerFormGroup.status == "INVALID") {
          valid = false;
        }
      } else {
        for (let index = 0; index < this.redeemer_details.controls.length; index++) {
          const ctrl = this.redeemer_details.controls[index];
          if(this.redeemer_details.controls[index]['controls']['surprise'].value == true) {
            this.redeemer_details.controls[index]['controls']['email'].setValidators(null);
            this.redeemer_details.controls[index]['controls']['re_enter_email'].setValidators(null);
            this.redeemer_details.controls[index]['controls']['contact_number'].setValidators(null);
          } else {
            this.redeemer_details.controls[index]['controls']['email'].setValidators([Validators.required, Validators.email]);
            this.redeemer_details.controls[index]['controls']['re_enter_email'].setValidators([Validators.required, Validators.email]);
            this.redeemer_details.controls[index]['controls']['contact_number'].setValidators(Validators.required);
          }
          this.redeemer_details.controls[index]['controls']['email'].updateValueAndValidity();
          this.redeemer_details.controls[index]['controls']['re_enter_email'].updateValueAndValidity();
          this.redeemer_details.controls[index]['controls']['contact_number'].updateValueAndValidity();
          if(ctrl.status == "INVALID")
            valid = false;
        }
      }
    }
    return valid;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid, .error"
    );
    if(firstInvalidControl[0])
      firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  /* Form Functions End */

  // Edit Buyer Functions

  checkInfoEditable() {
    if(this.buyerInfo != null && this.info_editable == true) {
      return true;
    }
    return false;
  }

  showEditModal() {
    this.NgxSmartModalService.getModal('editModal').open();
  }

  editValidity()
  {
    let valid = true;
    if(this.purchaseForm.controls.personalDetailsFormGroup.status == "INVALID") {
      valid = false;
    }
    return valid;
  }

  submitEditDetails() {
    let app = this;
    if(this.editValidity()) {
      let info;
      if(this.tryParseJSONObject(this.buyerInfo)) {
        info = JSON.parse(this.buyerInfo);
      } else {
        info = this.buyerInfo;
      }
      let payload = {
        "bag_id": this.bagId,
        "first_name" : this.purchaseForm.value.personalDetailsFormGroup.first_name,
        "last_name" : this.purchaseForm.value.personalDetailsFormGroup.last_name,
        "mobile_number": this.purchaseForm.value.personalDetailsFormGroup.contact_number,
        "new_email": this.purchaseForm.value.personalDetailsFormGroup.email, 
        "old_email": info['email_address']
      };
      if(this.api_token != null)
        payload['api_token'] = this.api_token;
      app.loadingUpdate = true;
      app.DealService.editBuyer(payload).subscribe((data: {}) => {
        if(data['success'] == true) {
          localStorage.setItem('bagId',data['data']['bag_id']);
          app.bagId = data['data']['bag_id'];
          app.DealService.changeCartCount(data['data']['deals'].length);
          localStorage.setItem('bagCount',data['data']['deals'].length);
          localStorage.setItem('buyerInfo',JSON.stringify({
            firstname:payload['first_name'],
            lastname:payload['last_name'],
            mobile_number:payload['mobile_number'],
            email_address:payload['new_email']
          }));
          this.purchaseForm.controls.personalDetailsFormGroup.patchValue({
            first_name:payload['first_name'],
            last_name:payload['last_name'],
            email:payload['new_email'],
            re_enter_email:payload['new_email'],
            contact_number:payload['mobile_number']
          });
          app.NgxSmartModalService.getModal('editModal').close();
        }
        app.loadingUpdate = false;
      });
    }
  }

  /* Edit Buyer Functions End */

  /* Gift Card Functions */

  setupCardSlider() {
    if(this.buying_for == 'se') {
      setTimeout(() => {
        const el = this.el.nativeElement;
        let elem = el.querySelector('.flickity-gifting');
        this.card_slider = new Flickity(elem, {
          contain: true,
          cellAlign: 'left',
          pageDots: false,
          prevNextButtons: true,
          imagesLoaded: true
        });
      }, 100);
    }
  }

  checkActiveCard() {
    const id = this.redeemer_details.controls[(this.redeemer_tab-1)]['controls']['gift_card_id'].value;
    return id;
  }


  showGiftCardModal(data) {
    this.NgxSmartModalService.setModalData({data
    }, 'giftCardModal', true);    
    this.NgxSmartModalService.getModal('giftCardModal').open();
  }

  checkSameCard() {
    if(this.same_card == true) {
      const tab = this.redeemer_tab-1;
      const value = this.redeemer_details.controls[tab]['controls']['gift_card_id'].value;
      this.redeemer_details.controls.forEach(e => {
        e['controls']['gift_card_id'].patchValue(value);
      });
    }
  }

  selectGiftCard(id) {
    if(this.same_card == true) {
      this.redeemer_details.controls.forEach(e => {
        e['controls']['gift_card_id'].patchValue(id);
      });
    } else {
      this.redeemer_details.controls[(this.redeemer_tab-1)]['controls']['gift_card_id'].patchValue(id);
      
    }
    this.NgxSmartModalService.getModal('giftCardModal').close();
  }

  submitSignin() {
    if (this.sign_in.invalid) {
      return;
    }
    this.inValidEmailOrPassword = false;
    this.sign_submitted = true;
    let json = {email:this.email.value, password: this.password.value};
    if(this.bagId != null)
      json['bag_id'] = this.bagId;
    let app = this;
    this.UserAccountService.login2(json).subscribe((data:any)=>{
      this.sign_submitted = false;
      if(data.success==false){
        if(data.message=="One time pin has been sent to your email. Please verify to continue."){
          const now = new Date()
          const expiry =  now.setMinutes(now.getMinutes() - 5);
          localStorage.setItem('otp-expiration',JSON.stringify( expiry));
        }      
        this.inValidEmailOrPassword = true;
      }else{
        this.UserAccountService.setActiveUser(data);
        localStorage.setItem('bagId',data['bagSummary']['bag_id']);
        app.bagId = data['bagSummary']['bag_id'];
        app.DealService.changeCartCount(data['bagSummary']['deals'].length);
        localStorage.setItem('bagCount',data['bagSummary']['deals'].length);
        localStorage.setItem('buyerInfo',JSON.stringify({
          firstname:data['user']['first_name'],
          lastname:data['user']['last_name'],
          mobile_number:data['user']['contact_number'],
          email_address:data['user']['email']
        }));
        this.buyerInfo = {
          "firstname": data['user']['first_name'],
          "lastname":  data['user']['last_name'],
          "email_address": data['user']['email'],
          "confirm_email_confirmation": data['user']['email'],
          "mobile_number": data['user']['contact_number'],
        }
        this.purchaseForm.controls.personalDetailsFormGroup.patchValue({
          first_name:data['user']['first_name'],
          last_name:data['user']['last_name'],
          email:data['user']['email'],
          re_enter_email:data['user']['email'],
          contact_number:data['user']['contact_number']
        });
        app.info_editable = true;
        app.is_authenticated = true;
      }
    })
  }

  successRegister(e){
    this.NgxSmartModalService.getModal('registerModal').close();
    this.NgxSmartModalService.getModal('otpModal').open();
  }

  openRegisterModal() {
    this.NgxSmartModalService.getModal('registerModal').open();
  }

  otpEvent(e) {
    this.NgxSmartModalService.getModal('otpModal').close();
  }

  tryParseJSONObject (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }
    return false;
  };

  get details() { 
    return this.purchaseForm.controls.personalDetailsFormGroup['controls'];
   }

}
