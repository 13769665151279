<div class="search-body-content">
	<div class="pt-4 mb-n4">
		<button mat-button [routerLink]="['/']" class="text-muted" style="min-width: unset;">
			<i class="far fa-arrow-left fa-fw me-1"></i>Back
		</button>
	</div>

	<div>
		<app-new-search-nav-bar (locationEvent)="getLocation($event)" (searchInputEvent)="getSearchInput($event)"></app-new-search-nav-bar>
	</div>

	<div class="flex-grow-1 overflow-hidden h-100">
		<section class="pb-6 d-none" *ngIf="(searchInput == '' && location == '') && hideFirstLoadResult<3 ">
			<div class="px-4">
				<p class="fs-6 fw-semibold">Popular Searches</p>
			</div>
		
			<div>
				<mat-chip-list>
					<mat-chip color="secondary" (click)="getPopularSearch(popular.query)" *ngFor="let popular of popularSearches">
						{{ popular.query }}
					</mat-chip>
				</mat-chip-list>
			</div>
		</section>

		<search-result-not-found-template style="height: inherit;" *ngIf="(parlons.length == 0 && deals.length == 0) && isPageLoaded"></search-result-not-found-template>

		<div class="h-100" >
			<div style="height: inherit;">
				<mat-tab-group mat-stretch-tabs color="secondary"  *ngIf="(parlons.length > 0 || deals.length > 0) && hideFirstLoadResult>=3 ">
					<mat-tab label="Deals">
						<div class="p-4">
							<h6 class="h6 fw-semibold" *ngIf="deals.length > 0 && searchInput !=''">Deal results for &ldquo;{{ searchInput }}&rdquo;</h6>
              <h6 class="h6 fw-semibold" *ngIf="deals.length > 0 && searchInput =='' && location !='' ">Deal results in &ldquo;{{ locationJSONParse }}&rdquo;</h6>
							<h6 class="h6 fw-semibold" *ngIf="deals.length == 0">No deals found for &ldquo;{{ searchInput }}&rdquo;</h6>
						</div>

						<div class="px-4">
							<div class="row gy-3">
								<div class="col-12" *ngFor="let deal of deals | paginate: deal_config; let i= index;">																	
									<search-result-deal-card-template 
									[position]="(i+1) + (currentPageDeals) * 50"  
									[isLoggedIn]="isLoggedIn"
							 		[userID]="userId"
									[deal]="deal"></search-result-deal-card-template>
								</div>
							</div>
						</div>

						<div class="d-flex justify-content-center my-6">
							<pagination-template #p="paginationApi" [id]="deal_config.id" (pageChange)="pageChangeEventDeals($event)">
								<div class="ngx-pagination">
									<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
										<a *ngIf="!p.isFirstPage()" (click)="p.previous()">
										</a>
									</div>
		
									<div class="page" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
										<a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
											<span>{{ page.label }}</span>
										</a>
										<div *ngIf="p.getCurrent() === page.value">
											<span>{{ page.label }}</span>
										</div>
									</div>
		
									<div class="pagination-next" [class.disabled]="p.isLastPage()">
										<a *ngIf="!p.isLastPage()" (click)="p.next()">
										</a>
									</div>
								</div>
							</pagination-template>
						</div>
					</mat-tab>
		
					<mat-tab label="Parlons">
						<div class="p-4">
							<h6 class="h6 fw-semibold" *ngIf="parlons.length > 0 && searchInput !=''">Matching &ldquo;{{ searchInput }}&rdquo;</h6>
              <h6 class="h6 fw-semibold" *ngIf="parlons.length > 0 && searchInput ==''  && location !='' ">Parlons in &ldquo;{{ locationJSONParse }}&rdquo;</h6>
							<h6 class="h6 fw-semibold" *ngIf="parlons.length == 0">No Parlons found for &ldquo;{{ searchInput }}&rdquo;</h6>
						</div>

						<div class="px-4">
							<div class="row gy-3">
								<div class="col-12" *ngFor="let parlon of parlons | paginate: parlon_config">
									<search-result-parlon-card-template [parlon]="parlon"></search-result-parlon-card-template>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-center my-6">
							<pagination-template #pg="paginationApi" [id]="parlon_config.id" (pageChange)="pageChangeEventParlons($event)">
								<div class="ngx-pagination">
									<div class="pagination-previous" [class.disabled]="pg.isFirstPage()">
										<a *ngIf="!pg.isFirstPage()" (click)="pg.previous()">
										</a>
									</div>
		
									<div class="page" *ngFor="let page of pg.pages" [class.current]="pg.getCurrent() === page.value">
										<a (click)="pg.setCurrent(page.value)" *ngIf="pg.getCurrent() !== page.value">
											<span>{{ page.label }}</span>
										</a>
										<div *ngIf="pg.getCurrent() === page.value">
											<span>{{ page.label }}</span>
										</div>
									</div>
		
									<div class="pagination-next" [class.disabled]="pg.isLastPage()">
										<a *ngIf="!pg.isLastPage()" (click)="pg.next()">
										</a>
									</div>
								</div>
							</pagination-template>
						</div>
		
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>







