import { Component, OnDestroy, OnInit, HostListener, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { environment } from '../../../environments/environment';
import { ContentService } from '../../services/content/content.service';
import { ActivatedRoute } from '@angular/router';
import * as Rx from 'rxjs';

@Component({
  selector: 'app-home-redesign',
  templateUrl: './home-redesign.component.html',
  styleUrls: ['./home-redesign.component.scss']
})
export class HomeRedesignComponent implements OnInit, OnDestroy {

  isLoaded = false;
  isRendering = false;
  data;
  homePageSectionsSubject$ = new Rx.BehaviorSubject([]);
  homePageSections$ = this.homePageSectionsSubject$.asObservable();
  pageSequence;
  buildInstanceId = null;
  teaser = environment.teaser;
  sections = [];
  routeName = "";

  userSubscription:Subscription|undefined;
  isLoggedIn:boolean = false;
  userId:string="";
  constructor(
    private ContentService: ContentService,
    private route: ActivatedRoute,
    private userService:UserAccountService,
    @Inject(WINDOW) private window: Window, 
  ) { }

  ngOnDestroy(): void {
     if(this.userSubscription)
      this.userSubscription.unsubscribe() 
  }

  ngOnInit() {


    this.userSubscription= this.userService.getLoggedInUser.subscribe((data:any)=>{
      if(data){        
        if(data.data){
          this.isLoggedIn = true;
          this.userId = data.user.id;          
        }               
      }
    });

      this.routeName =this.route.snapshot.routeConfig.path;;
      // alert(routeName)

      if (environment.teaser == true && this.routeName == "") 
      {
         window.location.href = "https://teaser.parlon.sg";
      }


    // this.route.params.subscribe(params => {
    //   page.buildInstanceId = params.instance;
    // });]

    // initial load
    this.ContentService.getHomepageSequence().subscribe((data: {}) => {
      this.isLoaded = true;
      this.pageSequence = data;
      this.renderSequence();
    });
  }

  fetchSections(sections) {
    let arr = [];
    sections.forEach( (e) => {
      arr.push(e['section_id']);
    });
    this.isRendering = true;
    this.ContentService.getHomePageBuilder(arr).subscribe((data) => {
      // console.log(81, data);
      let new_data = this.homePageSectionsSubject$.value.concat(data);
      this.homePageSectionsSubject$.next(new_data);
      this.isRendering = false;
    });
  }

  renderSequence() {
    const renderCount = 4;
    if((this.isLoaded == true && this.isRendering == false) && this.pageSequence.length > 0) {
      if(this.pageSequence.length > renderCount) {
        const slicedArray = this.pageSequence.slice(0, renderCount);
        this.pageSequence.splice(0,renderCount);
        this.fetchSections(slicedArray);
      } else {
        this.fetchSections(this.pageSequence);
        this.pageSequence = [];
      }
    }
  }

  @HostListener('document:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if(pos >= max)   {
    //Do your action here
      this.renderSequence();
    }
  }
}
