import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'featured-deals-grid',
  templateUrl: './featured-deals-grid.component.html',
  styleUrls: ['./featured-deals-grid.component.scss']
})
export class FeaturedDealsGridComponent implements OnInit {

  @Input() data: any;
  @Input() isLoggedIn:boolean = false;
  @Input() userId:string ="";

  resource = environment.resource;

  constructor() { }

  ngOnInit() {
  }

  getBackground() {
    return {'background': `url(${this.resource + this.data.merchant_highlight_bg_image})`}
  }

}
