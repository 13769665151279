import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'perk-card-img-template',
  templateUrl: './perk-card-img-template.component.html',
  styleUrls: ['./perk-card-img-template.component.scss']
})
export class PerkCardImgTemplateComponent implements OnInit {

  constructor() { }

  @Input() offerDetail:any;
  resource = environment.resource;
  ngOnInit() {
    // console.log(this.offerDetail);
  }

}
