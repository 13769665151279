<!--
	NOTE:
	- You can globally override this section's brand logo size by using the CSS Variables listed below. If null, fallback values are already set on CSS.
-->


<section class="py-6 py-md-8">
	<div class="container">
		<h2 class="h2 fw-semibold text-center mb-5">{{data.title}}</h2>
		<div id="flickity-brands-{{index}}" class="flickity flickity-brands row gx-6 gx-md-7 gx-xl-8 pt-5"
			style="
			--brand-logo-size: 3.1rem;
			--brand-logo-size-sm: 3.1rem;
			--brand-logo-size-md: 3.1rem;
			--brand-logo-size-lg: 3.1rem;
			--brand-logo-size-xl: 3.1rem;">
			<div class="slide" *ngFor="let logo of data.logos">
				<img src="{{resource + logo}}" alt="" class="img-fluid img-brand-logo">
			</div>
		</div>
	</div>
</section>