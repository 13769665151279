import { Component, OnDestroy, OnInit,SecurityContext  } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { OgService } from '../../services/og/og.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fromEvent, Observable, Subscription, of, zip } from 'rxjs';
import { debounceTime, map,startWith} from 'rxjs/operators';
import { UserAccountService } from '../../services/user-account/user-account.service';
import {MatDialog} from '@angular/material/dialog';
import {RedeemModalComponent} from './components/redeem-modal/redeem-modal.component'

import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
// import { map, merge, startWith } from 'rxjs/operators';


interface NavigatorClipboard {
  clipboard?: Clipboard;
}

interface Clipboard {
  writeText(text: string): Promise<void>;
}

interface Navigator extends NavigatorClipboard {}


@Component({
  selector: 'app-og-perks-inner',
  templateUrl: './og-perks-inner.component.html',
  styleUrls: ['./og-perks-inner.component.scss']
})



export class OgPerksInnerComponent implements OnInit, OnDestroy {
  isLoggedIn$ = new BehaviorSubject<boolean>(false);

  isMobileScreen:boolean;

  constructor(private route: ActivatedRoute, private ogService:OgService, private sanitizer: DomSanitizer,
     private router:Router, private userService:UserAccountService, public dialog: MatDialog,private breakpointObserver: BreakpointObserver ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // URL has changed, do something here...
        window.scrollTo(0, 0);

      }
    });
  }
  
  resource = environment.resource;
  offerDetail:any;
  randomOffers:any;

  $randomOfferSubscription:Subscription|undefined;
  $routeSubscription:Subscription|undefined;
  $offerDetailSubscription:Subscription|undefined;
  $breakPointSubscription:Subscription|undefined;
  $userServiceSubscription:Subscription|undefined;
  $brandCountSubscription:Subscription|undefined;

  isLoaded = false;

  isLoggedIn = false;
  isLoggedInAndOgMemberAndOfferNotRedeemed = false;
  isOfferRedeemed = false;
  isOgMember = false;

  promoCode = "";

  canBeRedeem = true;
  brandCount = 0;

  copyText="COPY VOUCHER CODE";

  showLinkout = false;
  hasVoucher = false;
  requiredLocation = false;
  locations:any = [];

  selectedAddress = "";
  selectedLocationId = "";
  showRequiredError = false;

  redemptionBranchName ="";
  redemptionAddress = "";

  selectLocation(value){
    // alert(value);
    
    let findObjectById =  this.locations.find(obj => obj.pivot.cms_og_brand_location_id === value); 
    this.selectedLocationId = findObjectById.pivot.cms_og_brand_location_id;
    this.selectedAddress =findObjectById.address

    this.showRequiredError = false;
  }
  ngOnDestroy(){
    if(this.$randomOfferSubscription)
      this.$randomOfferSubscription.unsubscribe();
    
    if(this.$offerDetailSubscription)
      this.$offerDetailSubscription.unsubscribe()
    
    if(this.$routeSubscription)
      this.$routeSubscription.unsubscribe()

    if(this.$breakPointSubscription)
      this.$breakPointSubscription.unsubscribe()
    if(this.$userServiceSubscription)
      this.$userServiceSubscription.unsubscribe()  
    if(this.$brandCountSubscription)
      this.$brandCountSubscription.unsubscribe()    
  }


  // isMobileScreen(): boolean {
  //   return window.innerWidth < 768; // or set your desired screen width
  // }

   copyContent = async () => {
    
  }

  copyCode(){
        
    (<Navigator & NavigatorClipboard>navigator).clipboard.writeText(this.promoCode)
    .then(() => console.log("Text copied to clipboard"))
    .catch((err) => console.error("Error copying text to clipboard:", err));
    
    this.copyText = "VOUCHER CODE COPIED!";
    
    setTimeout(()=>{
      this.copyText ="COPY VOUCHER CODE";
    },4000)
  }


  openDialog(){
    if(this.requiredLocation){
      if(this.selectedLocationId=='' ){
        this.showRequiredError = true;
        return;
      }            
    }

    const dialogRef = this.dialog.open(RedeemModalComponent,{minWidth:'300px', autoFocus: false} );
    dialogRef.afterClosed().subscribe(result => {
      if(result=='redeem'){
        // alert('call redeem api')
        let locationId  = this.selectedLocationId == '' ? null : this.selectedLocationId;
        this.ogService.redeem(this.offerDetail.id, locationId).subscribe((data:any)=>{
          if(data.status == 'Success'){
            this.isOfferRedeemed = true;
            this.promoCode = data.voucher_code
            this.redemptionBranchName = data.redemption_location.branch_name;
            this.redemptionAddress = data.redemption_location.address;
          }
        })
      }      
    });
  }

  checkRedemptionStatus(){
    this.$routeSubscription= this.route.params.subscribe((params:any) => {
      this.canBeRedeem = true;
      this.isLoaded = false;
      this.showLinkout = false;
      this.hasVoucher = false;
      this.requiredLocation = false;
      this.selectedAddress = "";
      this.selectedLocationId = "";
      this.showRequiredError = false;
      this.redemptionBranchName ="";
      this.redemptionAddress = "";
      // alert(params.slug);
      this.$offerDetailSubscription= this.ogService.getOfferDetail(params.slug).subscribe((data:any)=>{
        // console.log(data);
        if( data.status == 'error'){
          alert(data.message)
        }
        this.offerDetail = data.offer;       
        
        if(this.offerDetail.linkout_url !='' &&  this.offerDetail.linkout_url){
          this.showLinkout =true;
        }

        if(this.offerDetail.has_voucher == 1){
          this.hasVoucher = true;
        }

        if(this.offerDetail.require_select_location == 1){
          this.requiredLocation = true;
          this.locations = this.offerDetail.locations
        }
        

        this.userService.getLoggedInUser.subscribe((data:any)=>{          
          if(data ){                        
            // check if logged in
            this.isLoggedIn = true;                        
            // check if og member
            this.isOgMember = data.og.member ? true: false;
            
            // check redeem status
            this.ogService.checkRedeemStatus(this.offerDetail.id).subscribe((redeem:any)=>{              
              // if logged in and not offer not yet redeemed
              if(redeem.redemption_status == false && redeem.redemption_date==""){
                // this.isLoggedInAndOgMemberAndOfferNotRedeemed = true;   
                this.isOfferRedeemed = false;             
              } 

              this.isOfferRedeemed = redeem.redemption_status? true : false; 
                            
              if(redeem.success == false && redeem.message=="Not an OG User"){
                this.isOgMember = false;
              }

              if(redeem.redemption_status== true && redeem.redemption_code != ""){
                this.promoCode = redeem.redemption_code
              }else{
                this.promoCode = "";
              }

              if(redeem.redemption_branch !='' && redeem.redemption_branch != null){
                this.redemptionBranchName = redeem.redemption_branch.branch_name;
                this.redemptionAddress = redeem.redemption_branch.address;
              }
              

            })
          }else{
            this.isLoggedIn = false;
            // this.isLoggedInAndOgMemberAndOfferNotRedeemed = false;
            this.isOfferRedeemed = false;
            this.isOgMember = false;
          }
        });

        this.offerDetail.description = this.sanitizer.bypassSecurityTrustHtml(data.offer.description);
        this.isLoaded = true;

        if(!this.offerDetail.can_be_redeemed){
          this.canBeRedeem = false;
        }
                
        this.$randomOfferSubscription =this.ogService.getRandomOffers().subscribe((data:any)=>{
          data.offer.forEach(element => {
            element.name = element.title
            element.brand_logo = element.cms_og_brand.logo
            element.brand_name = element.cms_og_brand.brand_name
            delete element.title;
          });
          this.randomOffers = data.offer;
          // this.randomOffers;
       })


      })
    });
  }

  ngOnInit() {
    // this.openDialog();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    this.$breakPointSubscription =  this.breakpointObserver.observe('(max-width: 767px)').subscribe(result => {
      this.isMobileScreen = result.matches;
    });
      
    // run checkRedemptionStatus if user logged_in state change or route change but only oncce for performance
    this.$userServiceSubscription =  this.userService.getLoggedInUser.subscribe((data:any)=>{
      this.checkRedemptionStatus();  
            
    })

    this.$brandCountSubscription = this.ogService.getAllBrands().subscribe((data:any)=>{
      this.brandCount = data.brands.length;
    })
   
    // end 
             
  }

}
