import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from "ngx-spinner";
import { FacebookModule } from 'ngx-facebook';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { CommonElementsModule } from './common';
import { AllParlonDealsModule } from './modules/all-parlon-deals/all-parlon-deals.module';
import { AllParlonDealsV2Module } from './modules/all-parlon-deals-v2/all-parlon-deals-v2.module';
import { HomeModule } from './modules/home/home.module';
import { HomeRedesignModule } from './modules/home-redesign/home-redesign.module';
import { ParlonInnerModule } from './modules/parlon-inner/parlon-inner.module';
import { PageNotFoundModule } from './modules/page-not-found/page-not-found.module';
import { BrbPageModule } from './modules/brb-page/brb-page.module';
import { GoogleAnalyticsComponent } from './components/google-analytics/google-analytics.component';

import { BookingListModule } from './modules/booking-list/booking-list.module';
import { ParlonsListModule } from './modules/parlons-list/parlons-list.module';
import { MembershipCheckoutModule } from './modules/membership-checkout/membership-checkout.module';

import { UserRegistrationModule } from './modules/user-registration/user-registration.module';
import { UserVerificationModule } from './modules/user-verification/user-verification.module';
import { UserResetPasswordModule } from './modules/user-reset-password/user-reset-password.module';

import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

import { AuthGuard } from './_guards/auth.guard';

import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { PaymentLinkModule } from './modules/payment-link/payment-link.module';

import { GlifeModule } from './modules/glife/glife.module';
import { SearchPageModule } from './modules/search-page/search-page.module';
import { MobileSearchModule } from './modules/mobile-search/mobile-search.module';
import { BeautyBagDealsModule } from './modules/beauty-bag-deals/beauty-bag-deals.module';
import { BeautyBagCheckoutModule } from './modules/beauty-bag-checkout/beauty-bag-checkout.module';
import { BeautyBagThankyouModule } from './modules/beauty-bag-thankyou/beauty-bag-thankyou.module';

import { OgPerksModule } from './modules/og-perks/og-perks.module';
import { OgPerksInnerModule } from './modules/og-perks-inner/og-perks-inner.module';
import { OgInviteWelcomeModule } from './modules/og-invite-welcome/og-invite-welcome.module';

import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { OtpSuccessModule } from './modules/otp-success/otp-success.module';
import { MainNavModule } from './modules/main-nav/main-nav.module';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { GiftingModule } from './modules/gifting/gifting.module';
import { GiftingThankyouModule } from './modules/gifting-thankyou/gifting-thankyou.module';
import { DealFilterMobileComponent } from './components/deal-filter-mobile/deal-filter-mobile.component';

import { BookAppointmentModule } from './modules/book-appointment/book-appointment.module';
import { BookAppointmentMessageModule } from './modules/book-appointment-message/book-appointment-message.module';

import { MatDialogModule } from '@angular/material';

// Import modules components here
import { MembershipThankyouPageComponent } from './modules/membership-checkout/components/membership-thankyou-page/membership-thankyou-page.component';

// Social Login
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { CustomReuseStrategy } from './custom-reuse-strategy';

// Beauty Bash
import { BeautyBashTeaserModule } from './modules/beauty-bash-teaser/beauty-bash-teaser.module';
import { BeautyBashListingModule } from './modules/beauty-bash-listing/beauty-bash-listing.module';
import { BeautyBashCheckoutModule } from './modules/beauty-bash-checkout/beauty-bash-checkout.module';
import { BeautyBashThankyouModule } from './modules/beauty-bash-thankyou/beauty-bash-thankyou.module';

import { BookingPaymentModule } from './modules/booking-payment/booking-payment.module';

import { PurchasedDealsBookingModule } from './modules/purchased-deals-booking/purchased-deals-booking.module';
import { MultiBookingThankyouModule } from './modules/multi-booking-thankyou/multi-booking-thankyou.module';



const fbLoginOptions = {
  scope: 'email,public_profile,user_age_range',
  return_scopes: true,
  enable_profile_selector: true
};

const socialLoginConfig = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("537658518445716", fbLoginOptions)
  }
]);

export function provideSocialLoginConfig() {
  return socialLoginConfig;
}
 

@NgModule({
  declarations: [
    AppComponent,
    GoogleAnalyticsComponent,
    DealFilterMobileComponent,
    // insert  components here
    MembershipThankyouPageComponent
  ],
  imports:[
    FormsModule,
    RouterModule,
    CommonModule,
    NgtUniversalModule,
    BrowserModule.withServerTransition({ appId: 'parlon-site' }),
    HttpClientModule,
    AppRoutingModule,    
    NgxSmartModalModule.forRoot(),
    FacebookModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgxSpinnerModule,
    CommonElementsModule,
    AllParlonDealsModule,
    AllParlonDealsV2Module,
    HomeModule,
    HomeRedesignModule,
    ParlonInnerModule,
    PageNotFoundModule,
    BrbPageModule,
    
    SearchPageModule,
    MobileSearchModule,
    BeautyBagDealsModule,
    BeautyBagCheckoutModule,
    BeautyBagThankyouModule,
    
    
    MatDialogModule,
    // to fix as lazy load modules
    UserRegistrationModule,
    UserVerificationModule,
    UserResetPasswordModule,
    BookingListModule,
    ParlonsListModule,
    PaymentLinkModule,
    GlifeModule,
    OgPerksModule,
    OgPerksInnerModule,
    OgInviteWelcomeModule,
    MembershipCheckoutModule,
    SearchPageModule,
    MobileSearchModule,
    OtpSuccessModule,
    MainNavModule,
    GiftingModule,
    GiftingThankyouModule,
    BookAppointmentModule,
    BookAppointmentMessageModule,
    MatDialogModule,
    SocialLoginModule,
    BeautyBashTeaserModule,
    BeautyBashListingModule,
    BeautyBashCheckoutModule,
    BeautyBashThankyouModule,
    BookingPaymentModule,
    PurchasedDealsBookingModule,
    MultiBookingThankyouModule
  ],
  exports: [
    CommonElementsModule,
    MainNavModule
  ],
  providers: [ AuthGuard,
    AuthService,    
    UserService,
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    {
      provide: AuthServiceConfig,
      useFactory: provideSocialLoginConfig
    },
    {
      provide:RouteReuseStrategy,
      useClass:CustomReuseStrategy
    }
  ],
})

export class AppModule { }