import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../services/rest.service';

@Component({
  selector: 'app-membership-thankyou-page',
  templateUrl: './membership-thankyou-page.component.html',
  styleUrls: ['./membership-thankyou-page.component.scss']
})
export class MembershipThankyouPageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private rest: RestService, ) { 
      this.data = {
        transaction_status_id: ''
      }
    }

  successId: string;
  pendingId: string;
  failedId: string;
  isLoaded = false;
  isSucess = false;
  isFailed = false;
  isPending = false;
  data: object;
  response: object;

  ngOnInit() {
    this.successDetails();
  }

  successDetails() {
    let page =this;
    let url_string = window.location.href;
    var url = new URL(url_string);
    page.isLoaded = false;
    page.successId = url.searchParams.get("success");
    page.pendingId = url.searchParams.get("pending");
    page.failedId = url.searchParams.get("failed");
    if(page.successId != null) {
      page.data['transaction_status_id'] =  page.successId;
      page.rest.getMembershipPurchaseStatus(page.data).subscribe((data: {}) => {
        page.isSucess = true;
        page.response = data;
        page.isLoaded = true;
      });
    }
    if(page.pendingId != null) {
      page.data['transaction_status_id'] =  page.pendingId;
      page.rest.getMembershipPurchaseStatus(page.data).subscribe((data: {}) => {
        page.isPending = true;
        page.response = data;
        page.isLoaded = true;
      });
    }
    if(page.failedId != null) {
      page.data['transaction_status_id'] =  page.failedId;
      page.rest.getMembershipPurchaseStatus(page.data).subscribe((data: {}) => {
        page.isFailed = true;
        page.response = data;
        page.isLoaded = true;
      });
    }
    
  }

}
