import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const endpoint = environment.api;
let httpOptions = {};
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,
  ) {
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getPaymentChannels(page): Observable<any> {
    return this.http.get(endpoint + 'settings/available-payment-channels/' + page, httpOptions).pipe(
      map(this.extractData));
  }


}
