import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'search-result-not-found-template',
  templateUrl: './search-result-not-found-template.component.html',
  styleUrls: ['./search-result-not-found-template.component.scss']
})
export class SearchResultNotFoundTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
