import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit , Inject} from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-parlon-photos',
  templateUrl: './parlon-photos.component.html',
  styleUrls: ['./parlon-photos.component.scss']
})
export class ParlonPhotosComponent implements OnInit {

  private _albums: any = [];
  branch: any[];
  resourceLink = this.rest.resourceLink();

  constructor(@Inject(WINDOW) private window: Window,
  private rest: RestService, 
  private route: ActivatedRoute, 
  private _lightbox: Lightbox) {
  }
  
  ngOnInit() {
    this.branch = this.route.parent.snapshot.data['branch'];
    const page = this;
    this.branch['photo'].forEach(e => {
      const album = {
          src: this.resourceLink + e['image'],
          caption: '',
          thumb: this.resourceLink + e['image'],
      };
      page._albums.push(album);
    });
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
