import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { BookAppointmentComponent } from './book-appointment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonElementsModule } from '../../common';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { NgxCcModule } from 'ngx-cc';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  imports: [
    
    CommonModule, MaterialModule, FormsModule, CommonElementsModule, ReactiveFormsModule, NgxSmartModalModule, NgxCcModule,
    MatProgressSpinnerModule
    
  ],
  declarations: [BookAppointmentComponent, CreditCardFormComponent,]
})
export class BookAppointmentModule { }
