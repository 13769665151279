<section class="pt-5 pb-6 pt-md-6 pb-md-8" id="top">

	<app-inner-loader *ngIf="!isLoaded"></app-inner-loader>

	<!-- {{offerDetail|json}} -->
	<div class="container mb-5 mb-md-6" *ngIf="isLoaded">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
			  <li class="breadcrumb-item"><a routerLink="/og-perks" class="link-text link-underline">Parlon OG Perks</a></li>
			  <!-- <li class="breadcrumb-item"><a href="#" class="link-text link-underline">Salon and Wellness</a></li> -->
			  <li class="breadcrumb-item active" aria-current="page">{{offerDetail?.title}}</li>
			</ol>
			
		</nav>

		<!-- <div >	
			<a  *ngFor="let cat of offerDetail?.cms_og_category"><span class="tag tag-javascript tag-sm">#{{cat?.category_name}}</span></a>						
		</div> -->
		<div class="img-container w-100 mb-6" *ngIf="isLoaded">
			<div class="">
				
					<img *ngIf="!isMobileScreen"  src="{{resource+offerDetail?.desktop_image}}" alt="" loading="lazy" class="img-fluid w-100 perks-cover">
					<img *ngIf="isMobileScreen"  src="{{resource+offerDetail?.mobile_image}}" alt="" loading="lazy" class="img-fluid w-100 perks-cover">
					<div class="img-text">
						{{offerDetail.offer_type}}
					</div>
							
			</div>
		</div>
	</div>
	

	<div class="container" *ngIf="isLoaded">
		<div  class="mt-1 mb-3">	
			<a  *ngFor="let cat of offerDetail?.cms_og_category"><span class="tag tag-javascript tag-sm">#{{cat?.category_name}}</span></a>			
			<!-- <div class="tag" aria-current="page" ></div> -->
		</div>
		
		<div class="row">
			<div class="col-12">
				
				<div class="d-flex flex-wrap mb-2">
					<h3 class="h3 flex-item me-2">{{offerDetail?.title}}</h3>
					<div class="flex-item" *ngIf="isOfferRedeemed">
						<button style="cursor: default;" class="rounded redeemed"  >REDEEMED</button>
					</div>
				</div>				

				<div class="perk-content mb-4 mb-md-6" [innerHTML]="offerDetail?.description">					
				</div>

				<span class="tag tag-date tag-md rounded">
					Valid until {{offerDetail?.valid_to | date}}
				</span>							
				
			</div>
			
			<!-- if og member and logged in and offer not redeemed and can be redeem -->			
			<div class="d-flex justify-content-center mt-5"(click)="openDialog()"  *ngIf="isLoggedIn && isOgMember && !isOfferRedeemed && canBeRedeem && !hasVoucher && !requiredLocation">
				<button mat-flat-button style="background-color:#46b0a9; padding-left: 4rem; padding-right: 4rem;">REDEEM</button>
			</div>

			<div class=" mt-5" *ngIf="isLoggedIn && isOgMember && !isOfferRedeemed && canBeRedeem && hasVoucher && !requiredLocation" >
				<h2 class="text-center">Here's your voucher code! Click below to redeem it.</h2>
				<div class="flex-container justify-content-center">
					<button class="secret-code" mat-stroked-button > *********** </button>
					<button class="ms-2 " (click)="openDialog()" mat-flat-button
					 class="redeemed-code">REDEEM CODE</button>
				</div>
				
			</div>

			<div *ngIf="isLoggedIn && isOgMember && !isOfferRedeemed && canBeRedeem && hasVoucher && requiredLocation" class="p-5 mt-5" style="background-color:#fffaf7 ; ">
				<h5 class="text-center">Select Branch</h5>
				<div class="d-flex justify-content-center mb-3 ">
					<mat-form-field appearance="fill" [ngClass]="{'border-danger':showRequiredError, 'select-branch':true }"  >
						<mat-label>Select Branch</mat-label>
						<mat-select  (selectionChange)="selectLocation($event.value)">
							<mat-option [value]="location.pivot.cms_og_brand_location_id" *ngFor="let location of locations">{{location.branch_name}}</mat-option>							
						</mat-select>						
					</mat-form-field>					
				</div>
				<p class="text-center" *ngIf="showRequiredError">Please select a branch</p>
				<p class="text-center" *ngIf="selectedAddress != '' ">{{selectedAddress}}</p>
				<div class="d-flex justify-content-center">
					<button (click)="openDialog()" mat-flat-button
					 class="redeemed-code">REDEEM THIS OFFER</button>
				</div>
				
			</div>
			
			<!-- if not logged in or not an og member -->
			<div *ngIf="!isLoggedIn || !isOgMember" class="mt-5">
				<div class="row" style="background-color:#fffaf7 ; ">										
					<div class="col-md-5 col-sm-12"  style="padding: 3rem 1.5rem;">
						<h2  class="be-a-parlon" style="font-weight: bold; "> Be a Parlon Original Glowster (OG) Member!</h2>
						<div class="d-flex justify-content-center mt-5">
							<p>Instantly get <span class="font-weight-bold">300 Parlon Candies</span> and enjoy
								glowing perks from over <span class="font-weight-bold"> {{brandCount}} Parlon lifestyle partners nationwide. </span> </p>
						</div>
						<div class="mt-4 button-text-parlon">
							<button routerLink="/be-an-og-member" mat-flat-button style="background-color:#46b0a9;">I WANT TO BE A PARLON OG</button>
						</div>												
					</div>
					<div class="col-md-7 col-sm-12" style="padding: 0;">
						<div>
							<img class="img-fluid" src="/assets/images/OG/be_an_og.png" alt="">
						</div>
						
					</div>
				</div>				
			</div>

			<div  *ngIf="isOfferRedeemed" class="mt-6 ads" >
				<h4 *ngIf="promoCode != '' && promoCode != null "  class="text-center mt-5">Here's your voucher code! Click below to redeem it</h4>
				<h4 *ngIf="promoCode == '' || promoCode == null"  class="text-center mt-5">No code needed. Visit the site to get discount.</h4>
				<div class="d-flex">
					<div   [ngClass]="{'flex-container-button justify-content-center':true,'pb-5':redemptionBranchName == '',
					  'pb-5':redemptionBranchName != '' || redemptionBranchName != null, 'voucher-code':true }" >
						<button *ngIf="promoCode != '' && promoCode != null "  class="no-ripple" mat-stroked-button >{{promoCode}}</button>						
						<button *ngIf="promoCode != '' && promoCode != null " mat-stroked-button class="copy-text ms-1" 
						 (click)="copyCode()">{{copyText}}</button>	
						 <a *ngIf="showLinkout" href="{{offerDetail.linkout_url}}" class="ms-1 go-to-site"
						  mat-flat-button style="background-color: #46b0a9;"  target="_blank">
						 	{{offerDetail.linkout_label? offerDetail.linkout_label: 'GO TO SITE'}}</a>											 					
					</div>											
				</div>
				
				<div class="d-flex p-1" *ngIf="redemptionBranchName != '' &&  redemptionBranchName != null">
					<div class="ps-3 pe-3 pb-3 address">
						<span>Show this code in this branch</span>
						<span class=" d-block font-weight-bold" >{{redemptionBranchName}}</span>
						<span class="d-block">{{redemptionAddress}}</span>
					</div>
				</div>
			</div>			

			<div class="col-12">
				<hr class="my-7">
			</div>

			<div class="col-12">
				<h4 class="h4 fw-semibold mb-5 mb-md-6">More Parlon OG Perks</h4>				
				<div class="row flex-more-og-perks row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-sm-4 gx-md-6 gx-lg-4 gx-xl-5 gy-6">
					<div class="col" *ngFor="let offer of randomOffers">
						<perk-card-img-template [offerDetail]="offer"></perk-card-img-template>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</section>