import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material';
import { NgxCcModule } from 'ngx-cc';

import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { PaymentCreditCardFormComponent } from './components/payment-credit-card-form/payment-credit-card-form.component';
import { PaymentLinkComponent } from './payment-link.component';
import { RouterModule } from '@angular/router';
import { PaymentThankYouPageComponent } from './components/payment-thank-you-page/payment-thank-you-page.component';

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    FormsModule,
    MaterialModule,
    NgxCcModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    PaymentCreditCardFormComponent,
    PaymentFormComponent,
    PaymentLinkComponent,
    PaymentThankYouPageComponent,
  ]
})
export class PaymentLinkModule { }
