import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[service-looper]',
  templateUrl: './service-looper.component.html',
  styleUrls: ['./service-looper.component.scss']
})
export class ServiceLooperComponent implements OnInit {

  constructor() { }

  @Input()
  data: any[];

  ngOnInit() {
    
  }

}
