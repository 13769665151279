<!-- <nav class="navbar navbar-light bg-light fixed-top shadow-sm px-5 d-md-block d-none">
	<div class="container-fluid d-flex justify-content-between">
		<div class="d-flex justify-content-start align-items-center flex-wrap">
			<a class="navbar-brand d-none d-sm-none d-md-block" routerLink="/">
				<img src="assets/images/logo-inline.svg" alt="" class="img-fluid img-navbar-logo" />
			</a>
			<div class="border ms-3 all-location">
				<mat-form-field [floatLabel]="'never'" class="form-area-select">
					<span matPrefix><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
					<mat-select (selectionChange)="onSelectValueChange($event.value)" [(ngModel)]="locationSelected">
						<mat-option>
							<ngx-mat-select-search [formControl]="areaFilterCtrl" [placeholderLabel]="'Search'">
							</ngx-mat-select-search>
						</mat-option>
						<mat-option [value]="''">All Locations</mat-option>
						<mat-option [value]="toStr(area)" *ngFor="let area of filteredAreas | async">{{ area.location_name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="search-field border">
				<div class="search-input">
					<mat-form-field [floatLabel]="'never'" class="form-search-input w-100">
						<input id="search-input-field" matInput placeholder="Search for anything" [(ngModel)]="inputSearch"
							(ngModelChange)="changeModel()" (keyup.enter)="searchSubmit()" type="search" [formControl]="myControl" />
					</mat-form-field>
				</div>

			</div>

			<div class="ms-3">
				<button mat-button [disabled]="inputSearch.length==0" style="background-color: teal; padding: .7em 0.2em" (click)="searchSubmit()"><i
						style="color: white" class="far fa-search fa-fw"></i></button>
			</div>
		</div>
	</div>
</nav> -->

<!-- for mobile -->

<div class="d-block d-sm-none py-6">
	<div class="container">
		<div class="border">
			<div class="">
				<mat-form-field [floatLabel]="'never'" class="form-search-input w-100">
					<input matInput placeholder="Search for anything" [(ngModel)]="inputSearch" (ngModelChange)="changeModelMobile()" type="search"
						[formControl]="myControl" id="my-search"/>
				</mat-form-field>
			</div>
		</div>

		<div class="border all-location-mobile">
			<mat-form-field [floatLabel]="'never'" class="form-area-select">
				<span matPrefix><i class="far fa-location-dot fa-fw text-body mx-4"></i></span>
				<mat-select (selectionChange)="onSelectValueChange($event.value)" [(ngModel)]="locationSelected">
					<mat-option>
						<ngx-mat-select-search [formControl]="areaFilterCtrl" [placeholderLabel]="'Search'"></ngx-mat-select-search>
					</mat-option>
					<mat-option [value]="''"><span>All Locations</span></mat-option>
					<mat-option [value]="toStr(area)" *ngFor="let area of filteredAreas | async">{{ area.location_name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</div>