<a [routerLink]="'/beauty-bash/checkout/' + data['slug']" class="bash-deal-link" [queryParams]="extension">
  <div class="bash-deal-card"  [ngClass]="{'deal-sold-out': data['pivot']['quantity'] < 1}" >
    <div class="content px-2 px-sm-3">
      <div class="ratio ratio-16x9 mt-3 mt-sm-4 mb-3 pos-relative">
        <img src="{{resource + data['image']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
        <div class="salon-logo shadow">
          <img src="{{resource + data['parlon']['logo']}}" class="w-100">
        </div>
      </div>
      <p class="deal-parlon fs-7  w-md-80 mb-0 mt-3">{{data['parlon']['business_name']}}</p>
      <p class="deal-parlon-location smaller clamp-1 mb-1">
        <i class="fas fa-location-dot fa-fw me-1"></i>
        <span *ngFor="let branch of data['branches']; let i = index">{{branch['branch_name']}}<ng-container *ngIf="i != data['branches'].length - 1">, </ng-container> </span>
      </p>
      <p class="deal-name fs-6 fw-medium clamp-3 clamp-md-5 mb-3">{{data['name']}} </p>

      <p class="deal-price fw-medium">₱{{data['pivot']['selling_price'] | number}}<span class="og-price ms-2">₱{{data['original_price'] | number}}</span></p>
      <div class="deal-cta">
        Get this for only ₱{{data['pivot']['selling_price'] | number}}!
      </div>
    </div>
    <div class="deal-discount">
      <span *ngIf="data['savings_display_type'] == 'value'">SAVE ₱{{data['pivot']['savings'] | number}}</span>
      <span *ngIf="data['savings_display_type'] == 'percentage'">{{data['savings_in_pct']}} OFF</span>
    </div>
    <div class="left-count" *ngIf="data['pivot']['quantity'] == 1">
      {{data['pivot']['quantity']}} LEFT!
    </div>
    <div class="sold-out" *ngIf="data['pivot']['quantity'] < 1">
      SOLD OUT
    </div>
  </div>
</a>