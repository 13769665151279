import { Component, Input, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../../services/rest.service';
import { SharedService } from '../../../../services/shared.service';

@Component({
  selector: 'membership-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit {

  loading = false;
  submitted = false;
  paymentFormGroup: FormGroup
  data: object;
  partners: any = [];

  @Output() promoCode = new EventEmitter();

  @Input() membershipForm: FormGroup;
  @Input() selectedMembership: any;
  hasValidCode = false;
  activeCode = '';
  message = '';
  resourceLink = this.rest.resourceLink();

  constructor(
    private _formBuilder: FormBuilder,
    private rest: RestService, 
    private _shared: SharedService,
    private el: ElementRef,
  ) {
    this.data = {
      deal_number: '',
      promo_code: '',
    }
   }

  ngOnInit() {
    this.paymentFormGroup = this._formBuilder.group({
      promoCode: [''],
      creditCard: [''],
      creditCardDate: [''],
      creditCardCvv: [''],
      eVoucherPolicy: [false, [Validators.requiredTrue]]
    });
    this.rest.getDealPartners().subscribe((data: {}) => {
      this.partners = data;
    });
  }

  applyCode() {
    let page = this;
    if(page.membershipForm.controls.membershipCardOptionsFormGroup.value.membershipCardOptions == null) {
      page.membershipForm.controls.membershipCardOptionsFormGroup['controls']['membershipCardOptions'].markAsTouched();
      document.getElementById('membership-cards').scrollIntoView({
        behavior: 'smooth'
      });
      return false;
    }
    page.data['promo_code'] = this.membershipForm.value.paymentFormGroup.promoCode;
    page.data['membership_id'] = this.membershipForm.value.membershipCardOptionsFormGroup.membershipCardOptions.id;
    if(this.membershipForm.value.paymentFormGroup.promoCode != null) {
      page.rest.applyMembershipPromoCode(this.data).subscribe((data: {}) => {
        if(data['status'] == 'success') {
          page._shared.changeActiveDealPrice(data['computation']['total']);
          page.hasValidCode = true;
          this.promoCode.emit(data['computation']);
        } else {
          // alert('Promo code invalid');
          page.message = data['message'];
          page.submitted = true;
          page.hasValidCode = false;
        }
      });
    }
    
  }

  removeCode() {
    this._shared.changeActiveDealPrice(0);
    this.membershipForm.value.paymentFormGroup.promoCode = null;
    this.data['promo_code'] = '';
    this.hasValidCode = false; 
    this.promoCode.emit({});
  }

  ngOnChanges() {
    this.removeCode();
  }
   
   // convenience getter for easy access to form fields
   get f() { return this.paymentFormGroup.controls; }
}
