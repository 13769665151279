<!--
	NOTE:
	- To change background color, add `--announcement-bg`. If null, fallback value is #fabb9a;
	- To change text color, add `--announcement-color`. If null, fallback value is #814f34;
		- If you want to make it the same as default text color, use `text-body` class, inversely, you can use `text-white` class
	- To change font weights, use `fw-light`, `fw-normal`. If null, fallback value is Medium 600.
-->

<div class="alert fade show" role="alert" [style.background-color]="data['alert_bg_color']"  *ngIf="visible == true">
	<div class="message">
		<p [innerHTML]="data['alert_message']" [style.color]="data['alert_txt_color']"></p>
	</div>
</div>