import { Component, Input, OnInit,Output,EventEmitter, OnChanges } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { DealsService } from '../../../../services/deals/deals.service';

@Component({
  selector: 'app-candies-form',
  templateUrl: './candies-form.component.html',
  styleUrls: ['./candies-form.component.scss']
})
export class CandiesFormComponent implements OnInit, OnChanges {
  
  constructor(private dealService:DealsService) { }
  maxAmount = 100; // Set your desired maximum amount here
  amount = new FormControl(0, [this.amountValidator(), Validators.required]);
  @Input() api_token:string;
  @Input() bag_id:string;
  @Input() candies;
  @Input() bagInfo;

  @Input() candies_discount_api:number =0;
  totalCandies = 0;

  @Output() candiesAmountEvent = new EventEmitter<number>();
  // amount:number = 0;

  selectedCandyType = null;
  isViewAllCandiesPage  = true;
  ngOnInit() {
    this.totalCandies = Math.floor(this.bagInfo['candies']['allCandies']);
    if(this.bagInfo['summary']['candies_discount'] > 0) {
      this.selectedCandyType = 'parlon';
    }
    this.bagInfo['summary']['summaryPerParlon'] .forEach(e => {
      if(e['brandedCandiesUsed'] > 0) {
        this.selectedCandyType = 'branded';
      }
    });

  }

  ngOnChanges() {
  }


  selectCandyType(event){
    this.selectedCandyType = event;
  }

  amountValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const amountValue = control.value;
      
      if (amountValue < 0) {
        return { 'negativeValue': true };
      }
  
      if (amountValue > this.totalCandies) {
        return { 'amountExceedsTotal': true };
      }
  
      return null;
    };
  }
  

  onSubmit(e){
    let json = {
      api_token: this.api_token,
      bag_id : this.bag_id,
      candies_amount: e.candies_amount,
      brand:e.brand
    };
    this.dealService.applyCandies(json).subscribe((data)=>{
      this.candiesAmountEvent.emit(data);
    })
    // console.log(json);
    
    // alert(1);
  }


  removeCandies(){
    let json = {
      api_token: this.api_token,
      bag_id : this.bag_id,      
    };
    this.dealService.removeCandies(json).subscribe((data)=>{
      this.amount.setValue(0);
      this.candies_discount_api = data.summary.candies_discount;
      this.candiesAmountEvent.emit(this.candies_discount_api)

      // console.log(data);      
    })
  }

  findBrandedSummary(id) {
    let summary = this.bagInfo['summary']['summaryPerParlon'].filter(x => x.id === id);
    return summary[0];
  }


}
