<div class="card card-perk-template shadow p-3">
	<!-- {{offerDetail|json}} -->
	<div class="card-body pos-relative p-0">		
		<a [routerLink]="['/og-perks/perk',offerDetail.slug?offerDetail.slug: 'null' ]" class="stretched-link">
			<div class="ratio ratio-16x9 mt-3 mt-sm-4 mb-3">
				<img src="https://ik.imagekit.io/parlon/{{offerDetail.thumbnail}}?tr=w-450" alt="{{offerDetail.title}}" loading="lazy" class="img-fluid obj-fit-cover">
				<div class="deal-parlon-logo">
					<div class="ratio ratio-1x1">
						<img src="{{resource + offerDetail.cms_og_brand.logo}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
						
					</div>
				</div>
			</div>
			

		</a>

		<div class="content px-2 px-sm-3 pb-2 pb-sm-3 ">
			<p class="smaller text-muted mt-5"><i class="fa-sharp fa-solid fa-store me-2 fa-fw"></i>{{offerDetail?.cms_og_brand.brand_name}}</p>
			<p class="fw-medium mb-2 ">{{offerDetail.title}}</p>
			<p class="small">{{offerDetail.blurb}}</p>
			<p class="smaller text-perk-validity mb-0">Valid until {{offerDetail?.valid_to | date}}</p>
		</div>

		<a  *ngFor="let cat of offerDetail?.categories"><span class="tag tag-javascript tag-sm">#{{cat?.category_name}}</span></a>

		<div class="deal-perk-banner">
			<p>{{offerDetail.offer_type}}</p>
		</div>
	</div>
</div>