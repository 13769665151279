import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'search-result-parlon-card-template',
  templateUrl: './search-result-parlon-card-template.component.html',
  styleUrls: ['./search-result-parlon-card-template.component.scss']
})
export class SearchResultParlonCardTemplateComponent implements OnInit {

  constructor() { }

  resource = environment.resource;
  @Input() parlon:any[] = [];
  ngOnInit() {
  }

}
