
<ng-container *ngIf="isLoaded == true">
  <div *ngFor="let section of homePageSections$ | async; let i = index">


    <app-search-bar *ngIf="section.type == 'CmsHomepageSectionSearchBar'"></app-search-bar>

      <app-hero-banner *ngIf="section.type == 'CmsHomepageSectionBannerSlider'" [data]="section.data"></app-hero-banner>

      <message-banner *ngIf="section.type =='CmsHomepageSectionTitleBanner'" [data]="section.data">
      </message-banner>

    <featured-deals-slider [isLoggedIn]="isLoggedIn" [userId]="userId" *ngIf="section.type == 'CmsHomepageSectionDealSlider'"
      [data]="section.data" [index]="i">
    </featured-deals-slider>

      <featured-deals-grid [isLoggedIn]="isLoggedIn" [userId]="userId" *ngIf="section.type == 'CmsHomepageSectionMerchantHighlight' && section.data.mobile_display_style == 'Grid'"
        [data]="section.data">
      </featured-deals-grid>

      <featured-deals-stack [isLoggedIn]="isLoggedIn" [userId]="userId"  *ngIf="section.type == 'CmsHomepageSectionMerchantHighlight' && section.data.mobile_display_style == 'Stack'"
        [data]="section.data">
      </featured-deals-stack>

    <deal-categories *ngIf="section.type == 'CmsHomepageSectionCategoryIcon'" [data]="section.data"></deal-categories>

    <parlons-nearby  *ngIf="section.type == 'CmsHomepageSectionBranchSlider'" [index]="i" [stackable]="section.data.stack"  [data]="section.data"></parlons-nearby>

    <ad-space-slider *ngIf="section.type == 'CmsHomepageSectionAdSlider'" [index]="i" [data]="section.data"></ad-space-slider>

      <!-- <featured-deals-slider [data]="data['selected_deals'][1]" *ngIf="data['selected_deals'].length >= 2"></featured-deals-slider> -->

      <content-banner *ngIf="section.type == 'CmsHomepageSectionContentBanner'" [data]="section.data"></content-banner>

    <deal-collections *ngIf="section.type == 'CmsHomepageSectionDealCollection'" [data]="section.data"></deal-collections>

      <!-- <content-banner [data]="data['content_banner_2']"></content-banner> -->
      <!-- <featured-deals-slider [data]="data['selected_deals'][3]" *ngIf="data['selected_deals'].length >= 4"></featured-deals-slider> -->
      <spotify *ngIf="section.type == 'CmsHomepageSectionSpotify'" [data]="section.data"></spotify>
      <deal-reels *ngIf="section.type == 'CmsHomepageSectionReel'" [data]="section.data"></deal-reels>

      <memberships-slider *ngIf="section.type == 'CmsHomepageSectionMembership'" [data]="section.data"></memberships-slider>

      <!-- <parlons-nearby [data]="data['curated_branches'][1]"></parlons-nearby> -->

      <bookings *ngIf="section.type == 'CmsHomepageSectionExplorePartner'" [index]="i" [data]="section.data"></bookings>

      <!-- <featured-deal></featured-deal> -->

      <brand-feature-stack *ngIf="section.type == 'CmsHomepageSectionGenericImageSlider'" [index]="i" [data]="section.data"></brand-feature-stack>

      <!-- <partners [data]="data['partners']"></partners> -->
      <stories *ngIf="section.type == 'CmsHomepageSectionThreeFeatureBanner'" [data]="section.data"></stories>

    <featured-payments *ngIf="section.type == 'CmsHomepageSectionFeaturedPaymentMethod'" [data]="section.data"></featured-payments>
    
  </div>
  <home-loader *ngIf="isRendering == true"></home-loader>
</ng-container>
<!-- <ng-container  *ngIf="isLoaded == false">
  <static-loader></static-loader>
</ng-container> -->
<ng-container *ngIf="teaser == true && routeName=='' ">
  <home-teaser></home-teaser>
</ng-container>