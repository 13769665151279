import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRegistrationComponent } from '../modules/user-registration/user-registration.component';

import { UserAccountService } from '../services/user-account/user-account.service';


@Injectable({
  providedIn: 'root'
})
export class RegisterGuardGuard implements CanActivate {

  // constructor(private userService:UserAccountService, private router:Router){}
  // canDeactivate(component:UserRegistrationComponent){

  //   var isAuthenticated = this.userService.isLogged();
  //     if (isAuthenticated) {
  //         return isAuthenticated;
  //     }
  //     else{
  //       this.router.navigate(['/']);
  //       return isAuthenticated;
  //     }      
  // }

  constructor(private userService:UserAccountService, private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var isAuthenticated = this.userService.isLogged();
      // console.log(isAuthenticated);
      if (isAuthenticated) {
        this.router.navigate(['/']);    
        return false;          
      }else{
        return true;
      }
      // return isAuthenticated;
  }





}
