import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../../services/rest.service';
import { SharedService } from '../../../../services/shared.service';

@Component({
  selector: 'promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit, OnChanges {

  submitted = false;
  paymentFormGroup: FormGroup
  data: object;
  partners: any = [];
  promoCodeInput;
  @Input() promocodeValue;
  @Input() loading = false;
  @Output() promoCodeEmit = new EventEmitter();

  hasValidCode = false;
  activeCode = '';
  message = '';
  resourceLink = this.rest.resourceLink();

  constructor(
    private _formBuilder: FormBuilder,
    private rest: RestService, 
    private _shared: SharedService
  ) {
    this.data = {
      deal_number: '',
      promo_code: '',
    }
   }

  ngOnInit() {
    this.paymentFormGroup = this._formBuilder.group({
      promoCode: [''],
      creditCard: [''],
      creditCardDate: [''],
      creditCardCvv: [''],
      eVoucherPolicy: [false, [Validators.requiredTrue]]
    });
    this.rest.getDealPartners().subscribe((data: {}) => {
      this.partners = data;
    });
  }

  ngOnChanges() {
  }

  applyCode() {
    this.promoCodeEmit.emit(this.promoCodeInput);
  }

  removeCode() {
    this.promoCodeInput = '';
    this.promoCodeEmit.emit(null);
  }

   // convenience getter for easy access to form fields
   get f() { return this.paymentFormGroup.controls; }
}
