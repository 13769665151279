<div class="row deal-details" [attr.data-dealid]="data['deal']['id']">
  <div class="col">
    <div class="row">
      <div class="col-md-6">
        <p class="text-muted text-uppercase fw-semibold mb-2">You are buying</p>
        <div class="d-flex">
          <a [routerLink]="['/parlon', data['parlon']['slug']]">
            <img src="{{resource + data['parlon']['logo']}}" class="salon-logo">
          </a>
          <div class="mx-4 mb-3">
            <h2 class="h2 mb-2">{{data['deal']['name']}}</h2>
            <a [routerLink]="['/parlon', data['parlon']['slug']]" class="text-muted"><p class="text-muted fw-semibold mb-1">{{data['parlon']['name']}}</p></a>
            <p class="my-1">
              <span *ngIf="data['deal']['discount_type'] == 'Percentage Discount'" class="discount">{{
                data['deal']['discount_value'] | number }}% OFF</span>
              <span *ngIf="data['deal']['discount_type'] == 'Value Discount'" class="discount">Save ₱{{
                data['deal']['discount_value'] | number }}</span>
            </p>
            <p><span class="fw-semibold">₱{{data['deal']['price_current'] | number}}</span>&nbsp;<small><del>₱
                  {{data['deal']['price_before'] | number}}</del></small></p>
          </div>
          
        </div>
        <div class="text-deals-desc mb-lg-0">
          <p class="text-primary fw-semibold"><i class="far fa-calendar-alt mr-1"></i> Validity Period: {{
            data['deal']['validity']['start'] | date: 'MMMM dd, yyyy' }} - {{ data['deal']['validity']['end'] | date:
            'MMMM dd, yyyy' }}</p>
        </div>
        <div>
          <p class="mb-3 text-muted">
            <i class="fas fa-location-dot fa-fw me-1"></i> <span *ngFor="let branch of data['parlon']['branches']; let i = index">{{branch['name']}}<ng-container *ngIf="i != data['parlon']['branches'].length - 1">, </ng-container> </span>
          </p>
        </div>
        <div id="deals-checkpoint"></div>
      </div>
      <div class="col-md-6 d-none d-md-block">
        <div class="row">
          <div class="col-md-2 px-0" *ngIf="data['deal']['images'].length > 1">
            <div class="image-grid-desktop"
              [ngClass]="data['deal']['images'].length < 3 ? 'justify-content-center' : 'justify-content-between'">
              <div class="cell" *ngFor="let image of data['deal']['images']">
                <img src="{{resource + image}}" class="img-fluid obj-fit-cover" (click)="changeSrc(resource + image)">
              </div>
            </div>
          </div>
          <div [ngClass]="data['deal']['images'].length > 1 ? 'col-md-10' : 'col-md-12'">
            <div class="ratio ratio-16x9">
              <img [src]="imgSrc" class="img-fluid obj-fit-cover w-100">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-md-none">
        <div class="ratio ratio-16x9">
          <img [src]="imgSrc" class="img-fluid obj-fit-cover w-100">
        </div>
        <div class="image-grid mt-3" *ngIf="data['deal']['images'].length > 1"
          [ngClass]="data['deal']['images'].length < 3 ? 'justify-content-start' : 'justify-content-between'">
          <div class="cell" *ngFor="let image of data['deal']['images']">
            <img src="{{resource + image}}" class="img-fluid obj-fit-cover" (click)="changeSrc(resource + image)">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>