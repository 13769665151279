<!--
	NOTE:
	- To flip layout, add `inverse` class to `grid-content-banner`; remove if otherwise
	- To change text color to white, add `text-white`; remove if otherwise
	- To change link color to white (independent from the global text color above), add `link-white`, else, add `link-text` to make it dark
	- To change background color, change `--content-banner-bg` value. If null, fallback value is Parlon Pink, no need to declare since it is already written in the CSS

	See example below.
-->

<section class="mt-4">

	<div class="d-grid grid-cols grid-content-banner text-white">
		<div class="cell cell-bg">
			<div class="bg" [ngStyle]="{'background-color': data['content_banner_bg_color']}"></div>
		</div>

		<div class="cell cell-title">
			<p class="text-content-banner-title" [ngStyle]="{'color': data.font_color}">{{data['content_banner_title']}}</p>
		</div>

		<div class="cell cell-img">
			<a href="{{data['content_banner_linkout_url']}}">
				<div class="ratio ratio-4x3">
					<img src="{{ resource + data['content_banner_thumbnail'] }}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
				</div>
			</a>
		</div>

		<div class="cell cell-content pos-relative">
			<h2 class="h2 mb-4 mb-md-6" [ngStyle]="{'color': data.font_color}">{{data['content_banner_description']}}</h2>

			<a href="{{data['content_banner_linkout_url']}}" class="stretched-link" [ngStyle]="{'color': data.font_color}">{{data['content_banner_linkout_label']}} <i class="fas fa-right-long fa-fw ms-2"></i></a>
		</div>
	</div>
</section>