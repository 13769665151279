import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SalonsService } from '../../services/salons/salons.service';
import { ContentService } from '../../services/content/content.service';

@Component({
  selector: 'app-parlons-list',
  templateUrl: './parlons-list.component.html',
  styleUrls: ['./parlons-list.component.scss']
})
export class ParlonsListComponent implements OnInit {

  resource = environment.imagekit;
  field = "";
  alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  salonList: any = [];
  view = 'grid';
  pageData;
  showSearchBar:boolean = true;
  isLoadedSearchBar:boolean = false;
  constructor(
    private SalonsService: SalonsService,
    private ContentService: ContentService
  ) { }

  ngOnInit() {
    this.getPageContent();
    this.getSalonList();
  }

  changeView(view) {
    this.view = view;
  }

  getPageContent(){
    const app = this;
    this.isLoadedSearchBar = false;
    this.ContentService.getFeatures().subscribe(data=>{
      this.isLoadedSearchBar = true;
      this.showSearchBar = data['search_bar'];
      
    })

    this.ContentService.getParlonListing().subscribe((data: {}) => {
      app.pageData = data['data'];
    });
  }

  getSalonList(){
    const app = this;
    this.SalonsService.getSalons(this.field).subscribe((data: {}) => {
      let salons = data['data'];
      this.alphabet.forEach(function (letter) {
        let obj = {
          letter: '',
          salons: []
        };
        obj.letter = letter;
        salons.forEach(function (salon, index) {
          const salon_name = salon['business_name'];
          if(obj.letter == (salon_name.charAt(0)).toUpperCase()) {
            obj.salons.push(salon);
            // salons.splice(index, 1);
          }
        });
        app.salonList.push(obj);
      });
    });
  }

}
