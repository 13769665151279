<!--
	NOTE:
	- To change background overlay of each card, add `--deal-collection-item-bg` to <div class="bg">; fallback value is Parlon Pink; remove if no changes.
	- To change backround overlay opacity of each card, add `--deal-collection-item-opacity` to <div class="bg">; fallback value is .65; remove if no changes.

	See example below.
-->


<section class="pt-6 pb-7 pt-sm-7 py-md-8">
	<div class="container">
		<h2 class="h2 fw-semibold mb-5">{{data['title']}}</h2>

		<div class="d-grid grid-deal-collections">
			<div class="cell cell-{{i+1}}" *ngFor="let collection of data['collections']; let i = index">
				<a [routerLink]="['/deals-collection', collection['slug']]">
					<div class="ratio ratio-deal-collection-item">
						<img src="{{ resource + collection['mobile_image'] }}" alt="" loading="lazy" class="img-fluid mobile obj-fit-cover">
						<img src="{{ resource + collection['desktop_image'] }}" alt="" loading="lazy" class="img-fluid tablet obj-fit-cover d-none">
						<img src="{{ resource + collection['desktop_image'] }}" alt="" loading="lazy" class="img-fluid desktop obj-fit-cover">
	
						<!-- Preserved for future use -->
						<!-- <div class="overlays">
							<div class="bg" style="--deal-collection-item-bg: #9f4b4d"></div>
	
							<div class="text">
								<h3 class="h3">Achieve your balayage dreams</h3>
								<p class="fw-light mb-0">We have you covered</p>
							</div>
						</div> -->
					</div>
				</a>
			</div>
		</div>
	</div>
</section>