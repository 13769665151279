import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { SearchPageResultComponent } from './search-page-result/search-page-result.component';

import { MatAutocompleteModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from '../../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,CommonElementsModule,NgxMatSelectSearchModule, MatAutocompleteModule,MaterialModule,FormsModule,ReactiveFormsModule,
    NgxPaginationModule,RouterModule
  ],
  declarations: [SearchPageResultComponent]
})
export class SearchPageModule { }
