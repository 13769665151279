import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

  @Input() data;
  resource = environment.resource;

  stories;

  constructor() { }

  ngOnInit() {
    this.stories = this.data.stories;
  }

}
