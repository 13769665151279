import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
const aa = require('search-insights');
// import aa from 'search-insights';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaInsightService {


  algoliaApiUrl="https://insights.algolia.io/1/events?x-algolia-application-id=CWHNPDASIM&x-algolia-api-key=df6865d61bda858d789a1aabf4a7632e"; 

  constructor(private http: HttpClient) {
    aa('init', {
      appId: 'CWHNPDASIM',
      apiKey: 'df6865d61bda858d789a1aabf4a7632e'
    });
   }

   clickedObjectIDs(userToken:string, index:string,eventName:string,objectIDs:any[], queryID:string="",positions:any[]=[]){
    if(positions.length>0){
      
      aa('clickedObjectIDs', {
        userToken,
        index,
        eventName,
        objectIDs,
        queryID,
        positions
      });
    }else{     
      // events from Homepage 
      aa('clickedObjectIDs', {
        userToken,
        index,
        eventName,
        objectIDs                      
      });    
      
      
      // REFERENCE FOR ALGOLIA API INSIGHT WITHOUT USING search-insights LIBRARY
      // let request ={
      //   events: [{
      //     eventType: "click",
      //     eventName,
      //     index,
      //     userToken,
      //     objectIDs                                
      //   }]
      // };
      // return this.http.post(this.algoliaApiUrl,request)

    }    
   } 

   clickedObjectIDsAfterSearch(userToken:string, index:string,eventName:string,objectIDs:any[], queryID:string,positions:any[]){
    aa('clickedObjectIDsAfterSearch', {
      userToken,  // required for Node.js
      eventName,
      index,
      queryID,
      objectIDs,
      positions,
    });
   }

   
   convertedObjectIDs(userToken:string, index:string,eventName:string,objectIDs:any[]){
    aa('convertedObjectIDs', {
      userToken,
      index,
      eventName,
      objectIDs
    });
   }


   clickedFilters(userToken:string, index:string,eventName:string, filters:any[]){
    aa('clickedFilters', {
      userToken, // required for Node.js
      index,
      eventName,
      filters: [ 'brand:apple' ]
    });
   }

      
   
}
