import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';


let authData = '';
let httpOptions = {};


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(platformId)) {
      authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
    } else {
      authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
    }
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authData
      })
    }
  }


  register(form:any){
    return this.http.post(environment.api + 'user/register', form);
  }

  confirmOTP(form:any){
    return this.http.post(environment.api + 'user/validate-otp', form, httpOptions);
  }

  resendOTP(form:any){
    // {email}
    return this.http.post(environment.api + 'user/resend-otp', form, httpOptions);
  }

  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log(error);
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  getRegistrationContent(){
    return this.http.get(environment.api + 'registration-contents')
  }


}
