<div>
	<!-- <h2>{{activePromo['total']}}</h2> -->
	<!-- STEPPER -->
	<form id="membershipForm" [formGroup]="membershipForm">
		<mat-vertical-stepper [linear]="isLinear" #stepper>

			<mat-step formGroupName="personalDetailsFormGroup" [stepControl]="membershipForm.get('personalDetailsFormGroup')">
				<ng-template matStepLabel>Personal Details</ng-template>

				<div class="pt-4">
					<!-- PERSONAL DETAILS FORM -->
					<personal-details-form [personalDetailsFormGroup]="membershipForm.get('personalDetailsFormGroup')"
						[branches]="parlon['branches']" [scope]="parlon['membership_scope']" (selectedBranch)="updateSelectedBranch()">
					</personal-details-form>
				</div>
			</mat-step>

			<!-- MEMBERSHIP CARD BELOW -->
			<mat-step formGroupName="membershipCardOptionsFormGroup"
				[stepControl]="membershipForm.get('membershipCardOptionsFormGroup')">
				<ng-template matStepLabel>Select Membership Below</ng-template>

				<div class="pt-4">
					<div id="membership-cards"></div>
					<!-- MEMBERSHIPS FORM -->
					<div class="membership-card-selection pt-4" *ngIf="memberships.length > 0">
						<mat-radio-group class="row row-cols-1 row-cols-md-12 gx-4 gy-4 mcard-selectors"
							formControlName="membershipCardOptions" (change)="membershipSelect($event)">
							<div class="col" *ngFor="let membership of memberships">
								<div class="mcard-button">
									<mat-radio-button [value]="membership">
										<div class="card">
											<div class="card-body p-4">
												<div class="row">
													<div class="col-md-5 gx-5">
														<div class="mcard-image my-6">
															<img src="{{ resourceLink + membership.image }}" alt="{{ membership.image }}"
																class="img-fluid">
														</div>

														<p class="fw-bold mb-3">{{ membership.name }}</p>

														<!-- PRICE -->
														<p class="mb-1">
															<span class="text-primary fs-6">PHP {{ membership['price'] | number }}</span>
														</p>

														<!-- Perks and Benefits Modal -->
														<!-- <p class="text-secondary" (click)="showModal(membership.id, membership)">
															<i class="far fa-info-circle fa-fw me-2"></i>View perks and benefits
														</p> -->
													</div>
													<div class="col-md-7">

														<!-- Perks and Benefits -->
														<p class="text-muted"> Perks and benefits</p>
														<div [innerHTML]="membership['description']"></div>
													</div>
												</div>
											</div>

											<div class="checkmark">
												<i class="fas fa-check fa-fw"></i>
											</div>
										</div>
									</mat-radio-button>
								</div>
							</div>
						</mat-radio-group>

					</div>

					<div *ngIf="!(memberships.length > 0)">
						<p class="text-muted"><i>Please select a Branch</i></p>
					</div>
				</div>
			</mat-step>

			<mat-step formGroupName="paymentFormGroup" [stepControl]="membershipForm.get('paymentFormGroup')">
				<ng-template matStepLabel>Payment</ng-template>

				<div class="payment-method-accordion pt-4">
					<mat-accordion>
						<mat-radio-group formControlName="paymentChannel">
							<mat-expansion-panel
								*ngIf="isTotalZero == false && paymentChannels['parlon_credit_card']['memberships_visibility'] == true">
								<mat-expansion-panel-header (click)="paymentOptionClick('parlon_credit_card')">
									<div class="row row-payment-channel gx-4">
										<div class="col">
											<mat-radio-button value="parlon_credit_card">
												Credit/Debit Card
											</mat-radio-button>
										</div>
										<div class="col-auto">
											<div class="card-logo">
												<img src="/assets/images/payment-logos/visa.png" alt="visa-and-mastercard">
											</div>
										</div>
									</div>
								</mat-expansion-panel-header>

								<div>
									<p class="mb-2">Your payment shall be processed safely and securely by a third-party payment gateway
										provider. For your security, we do not store your credit card information.</p>
									<!-- CREDIT CARD FORM -->
									<membership-credit-card-form [paymentFormGroup]="membershipForm.get('paymentFormGroup')">
									</membership-credit-card-form>
								</div>

							</mat-expansion-panel>
																						
							<mat-expansion-panel class="nopad"
								*ngIf="isTotalZero == false && paymentChannels['parlon_gcash']['memberships_visibility'] == true">
								<mat-expansion-panel-header (click)="paymentOptionClick('parlon_gcash')">
									<div class="row row-payment-channel gx-4">
										<div class="col">
											<mat-radio-button value="parlon_gcash">
												GCash
											</mat-radio-button>
										</div>
										<div class="col-auto">
											<div class="card-logo">
												<img src="/assets/images/payment-logos/gcash.png" alt="gcash">
											</div>
										</div>
									</div>
								</mat-expansion-panel-header>
							</mat-expansion-panel>

							<mat-expansion-panel class="nopad"
								*ngIf="isTotalZero == false && paymentChannels['parlon_grabpay']['memberships_visibility'] == true">
								<mat-expansion-panel-header (click)="paymentOptionClick('parlon_grabpay')">
									<div class="row row-payment-channel gx-4">
										<div class="col">
											<mat-radio-button value="parlon_grabpay">
												Grab Pay
											</mat-radio-button>
										</div>
										<div class="col-auto">
											<div class="card-logo">
												<img src="/assets/images/payment-logos/grabpay.png" alt="grabpay">
											</div>
										</div>
									</div>
								</mat-expansion-panel-header>
							</mat-expansion-panel>

							<mat-expansion-panel class="nopad"
								*ngIf="isTotalZero == false && paymentChannels['parlon_ipp']['memberships_visibility'] == true && totalPrice>=3000">
								<mat-expansion-panel-header (click)="paymentOptionClick('parlon_ipp')">
									<div class="row row-payment-channel gx-4">
										<div class="col">
											<mat-radio-button value="parlon_webpay">
												Metrobank Installment (3 months with 0% interest)
											</mat-radio-button>
										</div>
										<div class="col-auto">
											<div class="card-logo">
												<img src="/assets/images/payment-logos/metrobank.png" alt="metrobank">
											</div>
										</div>
									</div>
								</mat-expansion-panel-header>
							</mat-expansion-panel>

							<mat-expansion-panel class="nopad"
							*ngIf="isTotalZero == false && paymentChannels['parlon_webpay']['memberships_visibility'] == true">
								<mat-expansion-panel-header (click)="paymentOptionClick('parlon_webpay')">
									<div class="row row-payment-channel gx-4">
										<div class="col">
											<mat-radio-button value="parlon_webpay">
												Direct Debit with UBP or BPI
											</mat-radio-button>
										</div>
										<div class="col-auto">
											<div class="d-flex">
												<div class="card-logo">
													<img src="/assets/images/payment-logos/bpi.png" alt="bpi">
												</div>
												<div class="card-logo">
													<img src="/assets/images/payment-logos/unionbank.png" alt="metrobank">
												</div>
											</div>
										</div>
									</div>
								</mat-expansion-panel-header>
							</mat-expansion-panel>

							<div class="card bg-light mt-5">
								<div class="card-body">
									<h5 class="h5 fw-medium-4">Apply Promo Code</h5>

									<!-- PROMO CODE -->
									<membership-promo-code [membershipForm]="membershipForm" [selectedMembership]="selected"
										(promoCode)="applyCodeHandler($event)"></membership-promo-code>
								</div>
							</div>

							<div class="mt-3 mb-6">
								<mat-checkbox id="eVoucherPolicy" color="primary" formControlName="eVoucherPolicy">
									By clicking the Buy Now button, I confirm that I have read and agree to <a
										href="https://legal.parlon.ph/post/terms-of-use" target="_blank">Parlon Terms of Use</a>, E-Voucher
									Additional Terms and Conditions and <a
										href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
								</mat-checkbox>
							</div>

							<!-- PAYMENT ERROR MESSAGE -->
							<div class="mt-3 mb-6" *ngIf="isTransactionFailed == true">
								<p class="text-danger"><strong>Oh no, we failed to process your payment / payment processing failed.
									</strong><br>
									We were unable to charge your account for this transaction, bestie. Kindly double check your details
									(if you are using a credit/debit card, make sure you are entering the correct expiry date format) or
									choose another payment method. Then, try again. Thank you!
								</p>
							</div>

							<div class="d-grid">
								<!-- BUY NOW BUTTON -->
								<button mat-flat-button color="secondary" class="p-3" type="submit" [disabled]="loading"
									(click)="onSubmit()">
									<span *ngIf="isTotalZero == false">BUY NOW</span>
									<span *ngIf="isTotalZero == true">CLAIM YOUR FREE DEAL</span>
									<mat-icon *ngIf="loading">
										<mat-spinner color="accent" diameter="20">
										</mat-spinner>
									</mat-icon>
								</button>
							</div>


						</mat-radio-group>
					</mat-accordion>
				</div>
			</mat-step>
		</mat-vertical-stepper>
	</form>
	<form id="cc-form" style="display: none;">
		<input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br />
		<input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br />
		<input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br />
		<input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
	</form>
</div>

<ngx-smart-modal #perksModal identifier="perksModal" [dismissable]="false">
	<ng-container *ngIf="perksModal.hasData()">
		<div class="modal-body p-2">
			<div class="mcard-image my-6">
				<img src="{{ resourceLink + perksModal.getData().membership.image }}"
					alt="{{ perksModal.getData().membership.image }}" class="img-fluid">
			</div>

			<!-- DISCOUNT TAG -->
			<!-- <div class="price-discount-tag">
				<p class="fw-medium mb-0"><span class="fw-semibold">PHP500</span> off</p>
			</div> -->

			<p class="fw-bold mb-3">{{ perksModal.getData().membership.name }}</p>

			<!-- PRICE -->
			<p class="mb-1">
				<span class="text-primary fs-6">PHP {{ perksModal.getData().membership.price | number }}</span>&nbsp;
				<!-- <span class="small text-muted text-decoration-line-through">PHP {{ perksModal.getData().membership.price |
					number }}</span> -->
			</p>

			<hr class="my-4">

			<!-- Perks and Benefits -->
			<p class="text-muted"> Perks and benefits</p>
			<div [innerHTML]="perksModal.getData().membership.description"></div>
		</div>
	</ng-container>
</ngx-smart-modal>

<ngx-smart-modal #voucherModal identifier="voucherModal" [dismissable]="false">
	<ng-container>
		<div class="modal-body p-2">
			<div class="row">
				<div class="col-md-6 d-flex justify-center align-center flex-column offset-md-3">
					<div class="text-center">
						<h4 class="text-secondary fw-semibold mb-2">Are you sure you want to use this Voucher Code?</h4>
						<h4 class="text-muted fw-semibold mb-4">{{activeCode}}</h4>
					</div>
					<div class="row">
						<div class="col">
							<button mat-flat-button color="secondary" class="p-1 w-100" type="button" [disabled]="loading"
								(click)="requestPayment()">
								YES
								<mat-icon *ngIf="loading">
									<mat-spinner color="accent" diameter="20">
									</mat-spinner>
								</mat-icon>
							</button>
						</div>
						<div class="col">
							<button mat-stroked-button color="primary" class="p-1 w-100" (click)="voucherModal.close()">
								NO
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>