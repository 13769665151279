<section class="bg-secondary text-white pt-5 pb-8 py-md-8" [ngClass]="theme" *ngIf="selectedRoute!='search' && selectedRoute!='mobile' && visible">
	<div class="container" *ngIf="contentLoaded">
		<div class="row flex-footer-grid gx-md-8 gx-lg-6 gx-xl-8 gy-5">

			<!-- Footer logo -->
			<div class="col-12 col-md-6 col-lg-3 col-footer-logo">
				<div class="d-none d-md-block">
					<div class="d-flex gap-3 gap-md-5 mb-3">
						<a href="/">
							<img src="{{resource+footerDetail?.footer_logo_placement_1}}" alt="" class="img-fluid img-footer-logo">
						</a>
						<img src="{{resource+footerDetail?.footer_logo_placement_2}}" alt="" class="img-fluid img-footer-logo">
						<img src="{{resource+footerDetail?.footer_logo_placement_3}}" alt="" class="img-fluid img-footer-logo">
					</div>

					<p class="fw-light mb-3">{{footerDetail?.footer_description}}</p>

					<!-- <p class="small fw-light mb-0">© {{footerDetail?.footer_copyright}}</p> -->
					<p class="small fw-light mb-0">{{footerDetail?.footer_copyright}}</p>
				</div>

				<div class="d-md-none">
					<p class="fw-medium mt-md-5 mt-lg-0 mb-3">Download the Parlon app</p>

					<div class="d-flex gap-3">
						<a href="{{footerDetail?.footer_ios_link}}" target="_blank">
							<img src="assets/images/download-on-the-app-store.svg" alt="" class="img-fluid img-store-badge">
						</a>

						<a href="{{footerDetail?.footer_android_link}}" target="_blank">
							<img src="assets/images/google-play-badge.png" alt="" class="img-fluid img-store-badge">
						</a>
					</div>
				</div>

				<hr class="o-50 mt-5 mb-0">
			</div>

			<!-- Address & Email -->
			<div class="col-12 col-md-6 col-lg-4 col-address">
				<h4 class="h4">Connect with Us!</h4>
				
					<div class="footer-address-grid"  *ngFor="let address of contentData['footer']['footer_address']">
						<div class="">
							<span class="icon fa-layers fa-fw fa-lg">
								<i class="fas fa-square text-white"></i>
								<i class="fas fa-location-dot text-secondary" data-fa-transform="shrink-7"></i>
							</span>
						</div>
	
						<div class="">
							<p class="fw-light mb-2" >{{address}}</p>
						</div>
					</div>
					
				

				<div class="row gx-2 mb-lg-4">
					<div class="col-auto">
						<span class="icon fa-layers fa-fw fa-lg">
							<i class="fas fa-square text-white"></i>
							<i class="fas fa-envelope text-secondary" data-fa-transform="shrink-7"></i>
						</span>
					</div>

					<div class="col">
						<p class="fw-light mb-0"><a href="mailto:info@parlon.ph" class="link-white link-text">{{contentData['footer']['footer_email']}}</a></p>
					</div>
				</div>

				<div class="d-none d-lg-flex gap-4">
					<a href="{{contentData['footer']['footer_ig']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-instagram fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_fb']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-facebook fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_tiktok']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-tiktok fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_linkedin']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-linkedin fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_spotify']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-spotify fa-2x fa-fw"></i>
					</a>
				</div>

				<hr class="o-50 mt-5 mb-0">
			</div>

			<!-- Newsletter -->
			<div class="col-12 col-md-10 col-lg-5 col-newsletter">
				<!-- <h4 class="h4">Making beauty and wellness accessible for everyone</h4>
				<p class="fw-light">Sign up for our newsletter to receive beautiful updates</p>

				<div class="d-flex align-items-center gap-4 mb-2">
					<mat-form-field class="form-newsletter flex-grow-1" appearance="fill" floatLabel="'never'">
						<input type="email" matInput placeholder="Enter your email address">
					</mat-form-field>

					<button mat-stroked-button>Submit</button>
				</div>

				<div>
					<p class="smaller fw-light">By clicking the Submit button, I confirm that I have read and agree to Parlon Terms of Use and Parlon Privacy Notice.</p>
				</div> -->

				<div class="d-flex d-lg-none gap-4">
					<a href="{{contentData['footer']['footer_ig']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-instagram fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_fb']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-facebook fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_tiktok']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-tiktok fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_linkedin']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-linkedin fa-2x fa-fw"></i>
					</a>

					<a href="{{contentData['footer']['footer_spotify']}}" class="link-white link-text" target="_blank">
						<i class="fab fa-spotify fa-2x fa-fw"></i>
					</a>
				</div>

				<hr class="o-50 my-5">

				<div class="d-none d-md-block">
					<p class="fw-medium mt-md-5 mt-lg-0 mb-3">Download the Parlon app</p>

					<div class="d-flex gap-3">
						<a href="{{footerDetail?.footer_ios_link}}" target="_blank">
							<img src="assets/images/download-on-the-app-store.svg" alt="" class="img-fluid img-store-badge">
						</a>

						<a href="{{footerDetail?.footer_android_link}}" target="_blank">
							<img src="assets/images/google-play-badge.png" alt="" class="img-fluid img-store-badge">
						</a>
					</div>
				</div>							
				<div class="d-md-none" *ngIf="footerDetail.footer_logo_placement_1 || footerDetail.footer_logo_placement_2 || footerDetail.footer_logo_placement_3 ">
					<div class="d-flex gap-3 gap-md-5 mb-3">
						<a href="/">
							<img src="{{resource+footerDetail?.footer_logo_placement_1}}" alt="" class="img-fluid img-footer-logo">
						</a>
						<img src="{{resource+footerDetail?.footer_logo_placement_2}}" alt="" class="img-fluid img-footer-logo">
						<img *ngIf="footerDetail.footer_logo_placement_3 !='' && footerDetail.footer_logo_placement_3 != null "  src="{{resource+footerDetail?.footer_logo_placement_3}}" alt="" class="img-fluid img-footer-logo">
					</div>

					<p class="fw-light mb-3">{{footerDetail?.footer_description}}</p>
					<p class="small fw-light mb-0">{{footerDetail?.footer_copyright}}</p>
					<!-- <p class="small fw-light mb-0">© 2022 Parlon. All rights reserved.</p> -->
				</div>
			</div>
		</div>

		<!-- HERE TOLITS -->

		<hr *ngIf="footerDetail.footer_left_link_1_url || footerDetail.footer_left_link_2_url || footer_right_link_1_url " class="border-white o-50 mt-5">

		<div *ngIf="footerDetail.footer_left_link_1_url || footerDetail.footer_left_link_2_url || footer_right_link_1_url " class="row justify-content-md-between small fw-light gy-1">
			<div class="col-12 col-md-auto">
				<div class="d-flex gap-3">
					<!-- <a routerLink="/about"class="link-white link-text">About</a>
					<a routerLink="/contact-us" class="link-white link-text">Contact us</a> -->
					<a href="{{footerDetail?.footer_left_link_1_url}}" class="link-white link-text" target="_blank">{{footerDetail?.footer_left_link_1_label}}</a>
					<a href="{{footerDetail?.footer_left_link_2_url}}" class="link-white link-text" target="_blank">{{footerDetail?.footer_left_link_2_label}}</a>
					<!-- <a href="{{footerDetail?.footer_left_link_3_url}}" class="link-white link-text" target="_blank">Terms of Use</a>	 -->
				</div>
			</div>

			<div class="col-12 col-md-auto">
				<div class="d-flex gap-3">
					<a ng-if="item.photo" href="{{footerDetail?.footer_right_link_1_url}}" class="link-white link-text" target="_blank">{{footerDetail?.footer_right_link_1_label}}</a>
					<!-- <a href="{{footerDetail?.footer_right_link_2_url}}" class="link-white link-text" target="_blank">Merchant Terms and Conditions</a> -->
					<!-- <a routerLink="/partnerships" class="link-white link-text">Partnerships</a>
					<a href="#" class="link-white link-text">Careers</a>  -->
				</div>
			</div>
		</div>
	</div>
</section>
