import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../../services/shared.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss']
})
export class BranchListComponent implements OnInit {

  constructor(
    private _shared: SharedService,
    private router: Router) { }

  @Input()
  parlon: any[];
  @Input()
  branches: any[];
  activeBranch: any[];
  selected;
  website = null;
  showLocation = environment.showLocation;
  
  @Input() starCount:number;
  @Input()showReview:boolean = false;
  totalStarCount = 83;

  formatNumber(): string {
    return this.starCount.toFixed(1);
  }


  ngOnInit() {
    // this.formatNumber();

    this._shared.currentBranch.subscribe((branch) => {
      this.activeBranch = branch;
      this.selected = branch['id'];
      if(this.parlon['website'] != null) {
        this.website = this.parlon['website'].replace(/(^\w+:|^)\/\//, '');
      }
      this._shared.changeSelectedAreaID(this.activeBranch['location']['id']);
    });
    // this._shared.selectedAreaID.subscribe(id => this.selected = id);
  }

  convertToDate(value) {
    return this._shared.convertToDate(value);
  }

}
