import { Component, OnInit, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SharedService } from '../../../../services/shared.service';

@Component({
  selector: 'book-deals-section',
  templateUrl: './book-deals-section.component.html',
  styleUrls: ['./book-deals-section.component.scss']
})
export class BookDealsSectionComponent implements OnInit {

  constructor(
    private smartModal: NgxSmartModalService,
    private SharedService: SharedService,
  ) { }

  @Input()
  description: string = 'Already purchased a deal and want to book your appointment?';
  @Input()
  deals: any[];
  selectedDeals = []

  ngOnInit() {
    this.SharedService.currentDeal.subscribe(deal => {
      this.selectedDeals = deal;
    });
  }
  
  showModal() {

    let deals = [];
    this.deals.forEach(deal => {
      let selected = false;
      if(this.selectedDeals.includes( deal['deal_id'] ))
        selected = true;
      deals.push({
        id: deal['deal_id'],
        name: deal['name'],
        selected: selected
      })
    });
    this.smartModal.setModalData({deals}, 'dealsModal', true);

    this.smartModal.getModal('dealsModal').open();
  }

  selectDeal(deal) {
    deal['selected'] = !deal['selected'];
    console.log(this.smartModal.getModal('dealsModal').getData().deals);
  }

  confirm() {
    let selected = [];
    this.smartModal.getModal('dealsModal').getData().deals.forEach(e => {
      if(e.selected)
        selected.push(e.id);
    });
    this.SharedService.selectDeal(selected);
    this.smartModal.getModal('dealsModal').close();
  }

}
