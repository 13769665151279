<form [formGroup]="personalDetailsFormGroup">

  <h5 class="h5 fw-semibold mt-5">Your details</h5>

 <div class="mb-2">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>First name</mat-label>
      <input matInput placeholder="First Name*" name="first_name" formControlName="first_name" required>
      <mat-error *ngIf="f.first_name.errors?.required">The first name field is required.</mat-error>
    </mat-form-field>
    
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Last name</mat-label>
      <input matInput placeholder="Last Name*" name="last_name" formControlName="last_name" required>
      <mat-error *ngIf="f.last_name.errors?.required">The last name field is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email*" id="email" name="email" formControlName="email" required>
      <mat-error *ngIf="f.email?.errors">
        <div *ngIf="f.email.errors?.required">The email field is required.</div>
        <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Your mobile number</mat-label>
      <input type="number" matInput placeholder="Your mobile number*" name="contact_number" formControlName="contact_number" required>
      <mat-error *ngIf="f.contact_number.errors?.required">The contact number field is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>City</mat-label>
      <input type="city" matInput placeholder="City*" name="city" formControlName="city" required>
      <mat-error *ngIf="f.city.errors?.required">The city field is required.</mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill" class="w-100" *ngIf="scope == 'Branch'"> 
      <mat-label>Branch</mat-label>
      <mat-select formControlName="branch_id" name="branch_id" (selectionChange)="branchChanged($event)" required>
        <mat-option>
          <ngx-mat-select-search [formControl]="branchFilterCtrl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'Not found'"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let branch of filteredBranches$ | async" [value]="branch['id']">
          {{ branch['branch_name'] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
 </div>
</form>