import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserAccountService } from '../user-account/user-account.service';
import { map, catchError, tap } from 'rxjs/operators';

const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  // USER VOUCHERS and BOOKINGS
  private userData = new BehaviorSubject<any>(!JSON.parse(localStorage.getItem('userData')) ? null : JSON.parse(localStorage.getItem('userData')) );
  getUserData = this.userData.asObservable();

  private selectedBooking = new BehaviorSubject<Array<any>>([]);
  getSelectedBooking = this.selectedBooking.asObservable();

  getBookings(): Observable<any>{
    let api_token = localStorage.getItem('currentUser');
    let header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+JSON.parse(api_token).data,
      })
    }
    return this.http.get(endpoint + 'user/dashboard-data', header);
  }


  getVouchers(voucherType:string): Observable<any>{
    let api_token = localStorage.getItem('currentUser');
    let header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+JSON.parse(api_token).data,
      })
    }
    return this.http.get(endpoint + `user/get-voucher/${voucherType}`, header);
  }

  getBookingDetails(data): Observable<any> {
    return this.http.post(endpoint + 'booking/get-checkout-details', data, httpOptions).pipe(
      map(this.extractData));
  }

  getTimeSlots(id = "", date = ""): Observable<any> {
    return this.http.get(endpoint + 'branches/' + id + "/" + date, httpOptions).pipe(
    map(this.extractData));
  }

  getPaymentChannels(): Observable<any> {
    return this.http.get(endpoint + 'settings/available-payment-channels/booking', httpOptions).pipe(
    map(this.extractData));
  }

  setBookings(data){
    localStorage.setItem('selectedBooking',JSON.stringify(data));
  }

  getStatus(id): Observable<any> {
    return this.http.get(endpoint + 'booking/status?reference_id='+id, httpOptions).pipe(
    map(this.extractData));
  }

  getInvoice(id): Observable<any> {
    return this.http.get(endpoint + 'booking/get-booking-invoice-details/'+id, httpOptions).pipe(
    map(this.extractData));
  }

  checkEmail(email): Observable<any> {
    return this.http.get(endpoint + 'booking/guest-check?email='+email, httpOptions).pipe(
    map(this.extractData));
  }

  requestBooking(data): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }
    return this.http.post(endpoint + 'user/booking', data, header).pipe(
      map(this.extractData));
  }

  getCheckoutDetailsForDealsPurchased(data): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }
    return this.http.post(endpoint + 'booking/get-checkout-details-for-deals-purchased', data, header).pipe(
      map(this.extractData));
  }

  requestInvoicePayment(data): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }
    return this.http.post(endpoint + 'booking/create-booking-invoice-purchase-request', data, header).pipe(
      map(this.extractData));
  }

  DealsBookingRequest(data): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }
    return this.http.post(endpoint + '/booking/create-deals-booking-request', data, header).pipe(
      map(this.extractData));
  }


  applyPromoCode(form:any){
    const header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }
    return this.http.post(endpoint + 'booking/apply-voucher-code', form, header).pipe(
      map(this.extractData));
  }

  
}
