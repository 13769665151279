<div class="container">
	<div class="row justify-content-center">		
		<div class="col-12">
			<div class="text-center">
				<img src="assets/images/logo-vertical.svg" alt="" class="img-fluid img-parlon-logo d-block d-md-none mx-auto mb-6">
				<img src="assets/images/logo-inline.svg" alt="" class="img-fluid img-parlon-logo d-none d-md-block mx-auto mb-6">

				<h1 class="h1 fw-semibold mb-4">Verify your account</h1>

				<p class="mb-7">We're almost done. Please enter the 6-digit OTP code we sent to {{email}} to verify your account.
				</p>

				<form [formGroup]="otpForm" (ngSubmit)="onSubmit()">
					<div [ngClass]="{'mb-5':!showErrors}" >
																		
						<input style="padding: .5em;" type="number" matInput class="border w-75"   placeholder=""  formControlName="digit_1">					

						<!-- <mat-form-field appearance="fill" [floatLabel]="'never'" class="form-digit-input">
							<input type="number" min="0" max="9"  matInput #digits name="digit_2" (keyup)='tabChange(2,d2)' formControlName="digit_2">
						</mat-form-field>

						<mat-form-field appearance="fill" [floatLabel]="'never'" class="form-digit-input">
							<input type="number" min="0" max="9"  matInput #digits  name="digit_3" (keyup)='tabChange(3,d3)' formControlName="digit_3">
						</mat-form-field>

						<mat-form-field appearance="fill" [floatLabel]="'never'" class="form-digit-input">
							<input type="number" min="0" max="9"  matInput #digits name="digit_4" (keyup)='tabChange(4,d4)' formControlName="digit_4">
						</mat-form-field>

						<mat-form-field appearance="fill" [floatLabel]="'never'" class="form-digit-input">
							<input type="number" min="0" max="9"  matInput #digits name="digit_5" (keyup)='tabChange(5,d5)' formControlName="digit_5">
						</mat-form-field>

						<mat-form-field appearance="fill" [floatLabel]="'never'" class="form-digit-input" >
							<input type="number" min="0" max="9"  matInput #digits  name="digit_6" (keyup)='tabChange(6,d6)' formControlName="digit_6">
						</mat-form-field> -->

					

					</div>
				
					<div *ngIf="showErrors && errorsValidation.data" class="mb-3">
						<mat-error *ngFor="let error of errorsValidation">{{error}}</mat-error>					
					</div>

					<div *ngIf="showErrors && !errorsValidation.data" class="mb-3">
						<mat-error>{{errorsValidation}}</mat-error>					
					</div>
					

					<div class=" d-flex justify-content-center">
						<button [disabled]="!otpForm.valid" mat-flat-button color="secondary" class="btn-submit mb-5 w-75" type="submit">
							Continue
						</button>
					</div>
				</form>
				<!-- {{expired}} -->
				
				<p class="text-center">You can request a new code after 5 minutes. 
					<button  class="d-flex align-items-center" mat-button [ngStyle]="{'color': expired ? '#f8a5a7' : 'grey' }"  [disabled]="!expired" (click)="resendOtp()" class="fw-semibold" >Resend code <mat-spinner *ngIf="submitOtp" style="display: inline-block;" [diameter]="18"></mat-spinner> <mat-icon *ngIf="!submitOtp && countSubmit>0 && !expired" style="display: inline-block;">check</mat-icon> </button>					
				</p>
			</div>
		</div>
	</div>
</div>