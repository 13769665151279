

<div infiniteScroll
[infiniteScrollDistance]="-2"  
[immediateCheck]="true"  
(scrolled)="onScroll()">
</div>
<div class="pt-6" *ngIf="selectedDeals.length > 0" >
    <div class="d-flex mb-2" >
        <h5 class="col h5 fw-semibold mb-0">Save and buy these deals now! Limited time only.
          <!-- <span class="fw- semibold text-secondary">{{ parlon['business_name'] }}</span> -->
        </h5>
    </div>
    <p class="mb-5">
    Purchase these deals online and present voucher when you visit {{ parlon['business_name'] }}. Enjoy!
    </p>
    <div class="featured-deals pb-6">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-md-5 gy-6">
            <div class="col" *ngFor="let deal of selectedDeals; let i = index ">
                <a [routerLink]="['/beauty-bag-deals', deal['slug']]">
                <featured-deals-card [featuredDeals]="deal" [index]="i"></featured-deals-card>
                </a>
            </div>
        </div>
    </div>

    <div class="my-4 text-center">
        <button mat-flat-button color="secondary"  [routerLink]="['/parlon', parlon['slug'], branch['slug'], 'services']">
            See full service menu
        </button>
      </div>
</div>
  

