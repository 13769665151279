import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonElementsModule } from '../../common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material';
import { NgxCcModule } from 'ngx-cc';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// page components
import { MembershipCheckoutComponent } from './membership-checkout.component';
import { MembershipFormComponent } from './components/membership-form/membership-form.component';
import { PersonalDetailsFormComponent } from './components/personal-details-form/personal-details-form.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { MembershipCheckoutRouteModule } from "./membership-checkout-routing.module";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CommonElementsModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCcModule,
    NgxSmartModalModule,
    NgxMatSelectSearchModule,
    MembershipCheckoutRouteModule
  ],
  declarations: [
    MembershipCheckoutComponent,
    MembershipFormComponent,
    PersonalDetailsFormComponent,
    CreditCardFormComponent,
    PromoCodeComponent,
    InfoBoxComponent
  ],
  exports: [
  ]
})
export class MembershipCheckoutModule { }
