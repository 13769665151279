<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- To set number of slides, change it on CSS
	
	See example below.
-->

<section  [ngClass]="{'overflow-x-hidden pb-6': stackable, 'overflow-x-hidden py-6 py-md-8 ' : !stackable} ">
	
	<div class="container">
		<div [ngClass]="{'row align-items-center justify-content-between justify-content-md-start':stackable, 'row align-items-center justify-content-between justify-content-md-start mb-4 mb-md-6': !stackable} " >
			<div class="col col-lg-auto">
				<h2 class="h2 fw-semibold mb-0" *ngIf="data.title!='_'">{{data['title']}}</h2>
			</div>
		</div>

		<div id="flickity-parlons-nearby-{{index}}"  [ngClass]="{'flick row':stackable, 'flickity-parlons-nearby': !stackable, 'flickity  flickity-overflow row gx-4 ':true }"  >			
			<div  [ngClass]="{'col-md-4': stackable, 'slide mt-7':true} " *ngFor="let branch of data['branches']">			
				<a  href="/parlon/{{branch['parlon']['slug']}}/{{branch['branch']['slug']}}">
					<div class="ratio ratio-2x1 mb-3">
						<img src="{{resource + branch['branch']['photo']}}?tr=w-500" alt="" loading="lazy" class="img-fluid obj-fit-cover">

						<div class="img-parlon-logo">
							<div class="ratio ratio-1x1">
								<img src="{{resource + branch['parlon']['logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
							</div>
						</div>
					</div>
				</a>
				<a  href="/parlon/{{branch['parlon']['slug']}}/{{branch['branch']['slug']}}">
					<h4 [ngClass]="{'fs-4 fw-medium':stackable, 'h4 mb-1': !stackable}"  style="color:#525252">{{branch['parlon']['business_name']}}</h4>
				</a>
				
				<p [ngClass]="{ 'clamp-1 mb-1 fs-6 text-parlon-location2 text-muted':stackable,' text-parlon-location clamp-1 mb-1':!stackable}" >{{branch['branch']['location']}}</p>
				<p class="small text-deal mb-3" *ngIf="branch['parlon']['active_deal_count'] > 0"><i class="fak fa-deals fa-fw me-2"></i>{{branch['parlon']['active_deal_count']}} ongoing deals</p>
				<p class="small text-muted mb-0" *ngIf="branch['parlon']['bookable_branches'] > 0"><span class="text-booking-availability">Online Booking Available</span></p>
			</div>
		</div>
	</div>
</section>