import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllParlonDealsV2Component } from './all-parlon-deals-v2.component';
import { DealFiltersComponent } from './components/deal-filters/deal-filters.component';
import { DealCardTemplateComponent } from './components/deal-card-template/deal-card-template.component';
import { MaterialModule } from '../../material';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { DealFilterMobileComponent } from '../../components/deal-filter-mobile/deal-filter-mobile.component';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    NgxPaginationModule,
    NgxSmartModalModule,
    RouterModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule
  ],
  declarations: [
    AllParlonDealsV2Component, 
    DealFiltersComponent, 
    DealCardTemplateComponent,
    
  ],
  entryComponents: [
    DealFilterMobileComponent
  ],
})
export class AllParlonDealsV2Module { }
