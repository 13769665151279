import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { BeautyBashThankyouComponent } from './beauty-bash-thankyou.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CommonElementsModule,
    MaterialModule
  ],
  declarations: [BeautyBashThankyouComponent]
})
export class BeautyBashThankyouModule { }
