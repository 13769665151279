<div class="p-lg-5 p-md-5 p-sm-2">


  <mat-dialog-content class="mat-typography" >
    <h1 class="text-center" style="font-weight: bold;">Are you sure you want to redeem this offer?</h1>

  </mat-dialog-content>


  <mat-dialog-actions align="center">
    <!-- <button mat-button mat-dialog-close>Cancel</button> -->
    <div class="col-12">
      <button  class="w-100" mat-button style="background-color: #46b0a9; color:white;" (click)="redeem()"  [mat-dialog-close]="true" >YES, I WANT TO REDEEM THIS</button>
    </div>
    
    <div class="col-12 mt-3">
      <button  class="w-100" mat-stroked-button style="border-color:#46b0a9; color:#46b0a9;" (click)="cancel()" [mat-dialog-close]="true" >CANCEL</button>
    </div>
    


  </mat-dialog-actions>

</div>