<mat-dialog-content class="mat-typography">
  <h3 class="text-danger">Oops!</h3>

  <div *ngIf="isEmailUnique">
    <div   *ngFor="let error of errors">
      <span *ngIf="error != 'The email has already been taken.' ">{{error}}</span>
          
    </div>
  </div>
  

  <div *ngIf="!isEmailUnique">
    <div>      
      <!-- <p style="line-height: 0.3em;"></p> -->
      <p >We love to see you back!  {{email}} has already a Parlon account. Please <button  (click)="login()" class="fw-semibold p-0" style="border: none; color:teal; background-color: #ffffff;" [mat-dialog-close]="true" cdkFocusInitial href="#nav-user-account" data-bs-toggle="offcanvas" role="button"
        aria-controls="nav-user-account">login</button> or <button (click)="forgotPassword()" class="fw-semibold p-0" style="border: none; color:teal; background-color: #ffffff;" [mat-dialog-close]="true" cdkFocusInitial href="#nav-user-account"  data-bs-toggle="offcanvas" role="button"
        aria-controls="nav-user-account">reset your password.</button></p>
    </div>
  </div>



</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close>Cancel</button> -->
  <button mat-button style="background-color: teal; color:white;" [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>