<div class="disclaimer">
  <h5 class="h5 fw-medium">Terms of Use</h5>
  <ol class="mb-0">
    <li>PARLON does not have a contractual relationship with the Consumer in providing or supplying Beauty and/or
      Wellness Services.</li>
    <li>Parlon Partners and Consumers shall negotiate and contract independently. Any decision by the Consumer to
      inquire and avail of Beauty and Wellness Services is Consumer’s sole discretion.</li>
    <li>PARLON solely provides services that help Consumers choose and discover Parlors/Salons/Clinics, while aiding
      them in making their Services Portfolio and Promos accessible to Consumers online.</li>
    <li>The information and photos in the PARLON Listing are provided to PARLON by the Parlon Partners, through manual
      submission or through the PARLON Partner Dashboard.</li>
  </ol>
</div>
