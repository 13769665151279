<div>
	<div class="col-10 mx-auto">
		<div class="slider">
			<mat-radio-group class="booking-date-selectors">
				<ngx-siema [options]="options" *ngIf="booking_dates && booking_dates.length > 0" selector="siema-dates">
					<ngx-siema-slide *ngFor="let date of booking_dates">
						<div class="booking-date-button">
							<mat-radio-button value="{{ date | date: 'dd/MM/yyyy' }}" (click)="selectDate(date)" name="">
								<div class="card">
									<div class="card-body p-2">
										<p class="text-date mb-0">
											<span class="day">{{ date | date:'E'  }}</span><br />
											<span class="day-number">{{ date | date:'dd'  }}</span>
										</p>
									</div>
								</div>
							</mat-radio-button>
						</div>
					</ngx-siema-slide>
				</ngx-siema>
			</mat-radio-group>
	
			<a (click)="prev()" class="prev-arrow" *ngIf="booking_dates.length > 4"><i class="fas fa-caret-circle-left fa-fw"></i></a>
			<a (click)="next()" class="next-arrow" *ngIf="booking_dates.length > 4"><i class="fas fa-caret-circle-right fa-fw"></i></a>
		</div>
	</div>
	
	<!-- Booking time -->
	<p class="text-center fw-semibold mb-1">Pick a time slot</p>
	
	<mat-radio-group class="booking-time-selectors" [(ngModel)]="selected_time">
		<div class="mx-2">
			<div class="row row-cols-3 row-cols-sm-4 g-3">
				<div class="col booking-time-button" *ngFor="let time of booking_time">
					<mat-radio-button value="{{time}}" (click)="selectTime(time)">
						<div class="card">
							<div class="card-body p-2">
								<p class="text-time mb-0">{{time}}</p>
							</div>
						</div>
					</mat-radio-button>
				</div>
			</div>
		</div>
	</mat-radio-group>
	
	<div class="mt-4">
		<mat-form-field appearance="fill" class="w-100">
			<mat-label>Notes to {{branch['parlon']['business_name']}}</mat-label>
			<textarea matInput
				placeholder="Notes to {{branch['parlon']['business_name']}}"
				cdkTextareaAutosize
				cdkAutosizeMinRows="3"
				cdkAutosizeMaxRows="5"></textarea>
		</mat-form-field>
	</div>
</div>