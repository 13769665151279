import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CategoryService } from '../../../../services/category/category.service';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../../services/content/content.service';


declare var Flickity: any;


@Component({
  selector: 'deal-categories',
  templateUrl: './deal-categories.component.html',
  styleUrls: ['./deal-categories.component.scss']
})
export class DealCategoriesComponent implements OnInit {

  @Input() data: object;

  categories: any = [];
  isLoaded = false;
  resource = environment.resource;

  constructor(
    private el: ElementRef,
    private CategoryService: CategoryService,
    private ContentService:ContentService,
    private route: ActivatedRoute) { }

    generateQueryParams(category): { [key: string]: string } {
      const subcategorySlugs = category.subcategories.map(subcategory => subcategory.slug).join(',');
      return { c: category['slug'], sc: subcategorySlugs };
    }

  ngOnInit() {

    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        // this.originalCategories = data['category_tree'];
        this.categories = data['category_tree'];
      }
    });


    // this.categories = this.data['categories'];

    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-deal-categories');
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        freeScroll: true,
        freeScrollFriction: 0.075,
        pageDots: false,
        prevNextButtons: false
      });

      // elem.addEventListener('pointerup', function() {
      //   flkty.playPlayer();
      // });
    }, 500);
  }

}
