import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeRedesignComponent } from './modules/home-redesign/home-redesign.component';
import { UserRegistrationComponent } from './modules/user-registration/user-registration.component';
import { UserVerificationComponent } from './modules/user-verification/user-verification.component';
import { UserResetPasswordComponent } from './modules/user-reset-password/user-reset-password.component';
import { ParlonInnerComponent } from './modules/parlon-inner/parlon-inner.component';
import { ParlonPhotosComponent } from './modules/parlon-inner/components/parlon-photos/parlon-photos.component';
import { ParlonServicesComponent } from './modules/parlon-inner/components/parlon-services/parlon-services.component';
import { ParlonHoursComponent } from './modules/parlon-inner/components/parlon-hours/parlon-hours.component';
import { ParlonPromosComponent } from './modules/parlon-inner/components/parlon-promos/parlon-promos.component';
import { ParlonPressComponent } from './modules/parlon-inner/components/parlon-press/parlon-press.component';
import { ParlonDealsComponent } from './modules/parlon-inner/components/parlon-deals/parlon-deals.component'
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { BrbPageComponent } from './modules/brb-page/brb-page.component';
import { BookingListComponent } from './modules/booking-list/booking-list.component';
import { ParlonsListComponent } from './modules/parlons-list/parlons-list.component';

import { AuthGuard } from './_guards/auth.guard';
import { Authv2Guard } from './_guards/authv2.guard';
// import { AllParlonDealsComponent } from './modules/all-parlon-deals/all-parlon-deals.component';
import { AllParlonDealsV2Component } from './modules/all-parlon-deals-v2/all-parlon-deals-v2.component';
// import { ThankYouPageComponent } from './modules/deals/components/thank-you-page/thank-you-page.component';
import { MembershipCheckoutComponent } from './modules/membership-checkout/membership-checkout.component';
import { MembershipThankyouPageComponent } from './modules/membership-checkout/components/membership-thankyou-page/membership-thankyou-page.component';
import { PaymentLinkComponent } from './modules/payment-link/payment-link.component';
import { PaymentThankYouPageComponent } from './modules/payment-link/components/payment-thank-you-page/payment-thank-you-page.component';
import { GlifeLandingPageComponent } from './modules/glife/components/glife-landing-page/glife-landing-page.component';
import { GlifeServiceListComponent } from './modules/glife/components/glife-service-list/glife-service-list.component';
import { GlifeCheckoutComponent } from './modules/glife/components/glife-checkout/glife-checkout.component';

import { BeautyBagDealsComponent } from './modules/beauty-bag-deals/beauty-bag-deals.component';
import { BeautyBagCheckoutComponent } from './modules/beauty-bag-checkout/beauty-bag-checkout.component';
import { BeautyBagThankyouComponent } from './modules/beauty-bag-thankyou/beauty-bag-thankyou.component';

import { OgPerksComponent } from './modules/og-perks/og-perks.component';
import { OgPerksInnerComponent } from './modules/og-perks-inner/og-perks-inner.component';
// import { OgCardComponent } from './modules/og-card/og-card.component';
// import { OgInviteFormComponent } from './modules/og-invite-form/og-invite-form.component';
import { OgInviteWelcomeComponent } from './modules/og-invite-welcome/og-invite-welcome.component';

import { SearchPageResultComponent } from './modules/search-page/search-page-result/search-page-result.component';
import { MobileSearchComponent } from './modules/mobile-search/mobile-search/mobile-search.component';
import { OtpSuccessComponent } from './modules/otp-success/otp-success.component';
// import { LoginFormComponent } from './modules/login/components/login-form/login-form.component';
import { GiftingComponent } from './modules/gifting/gifting.component';
import { GiftingThankyouComponent } from './modules/gifting-thankyou/gifting-thankyou.component';

import { BookAppointmentComponent } from './modules/book-appointment/book-appointment.component';
import { BookAppointmentMessageComponent } from './modules/book-appointment-message/book-appointment-message.component';

import { BeautyBashTeaserComponent } from './modules/beauty-bash-teaser/beauty-bash-teaser.component';
import { BeautyBashListingComponent } from './modules/beauty-bash-listing/beauty-bash-listing.component';
import { BeautyBashCheckoutComponent } from './modules/beauty-bash-checkout/beauty-bash-checkout.component';
import { BeautyBashThankyouComponent } from './modules/beauty-bash-thankyou/beauty-bash-thankyou.component';

import { BookingPaymentComponent } from './modules/booking-payment/booking-payment.component';

import { PurchasedDealsBookingComponent } from './modules/purchased-deals-booking/purchased-deals-booking.component';
import { MultiBookingThankyouComponent } from './modules/multi-booking-thankyou/multi-booking-thankyou.component';

import { RegisterGuardGuard } from './_guards/register-guard.guard';
import { RegistrationGuard } from './_guards/features/registration.guard';
import { OgGuard } from './_guards/features/og.guard';
import { DealsBeautyBagGuard } from './_guards/features/deals-beauty-bag.guard';
import { GiftingGuard } from './_guards/features/gifting.guard';
import { GlifeGuard } from './_guards/features/glife.guard';
import { ParlonPayGuard } from './_guards/features/parlon-pay.guard';
import { ParlonGoogleReviewComponent } from './modules/parlon-inner/components/parlon-google-review/parlon-google-review.component';
import { BeautyBashDealsGuard } from './_guards/features/beauty-bash-deals.guard';
import { BeautyBashTeaserGuard } from './_guards/features/beauty-bash-teaser.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeRedesignComponent
    // component: BrbPageComponent
  },
  {
    path: 'homepage/build/preview/:instance',
    pathMatch: 'full',
    component: HomeRedesignComponent
  },
  {
    path: 'register',
    pathMatch: 'full',
    component: UserRegistrationComponent,
    canActivate:[RegisterGuardGuard, RegistrationGuard]
  },
  {
    path: 'verify/:email',
    pathMatch: 'full',
    component: UserVerificationComponent,
    canActivate:[RegisterGuardGuard, RegistrationGuard]
  },
  {
    path: 'reset-my-password',
    pathMatch: 'full',
    component: UserResetPasswordComponent,
    canActivate:[RegistrationGuard]
  },
  {
    path: 'otp-success',
    pathMatch: 'full',
    component: OtpSuccessComponent
  },
  {
    path: 'booking',
    pathMatch: 'full',
    component: BookingListComponent,
    // loadChildren: () => import('./modules/booking-list/booking-list.module').then(x => x.BookingListModule)
  },
  {
    path: 'parlons',
    pathMatch: 'full',
    component: ParlonsListComponent,
    // loadChildren: () => import('./modules/parlons-list/parlons-list.module').then(x => x.ParlonsListModule)
  },
  {
    path: 'my-bookings',
    pathMatch: 'full',
    // component: MyBookingsMainComponent,
    canActivate:[Authv2Guard],
    loadChildren: './modules/my-bookings-main/my-bookings-main.module#MyBookingsMainModule'
  },
  {
    path: 'my-bookings/past',
    pathMatch: 'full',
    // component: MyBookingsPastComponent,
    canActivate:[Authv2Guard],
    loadChildren: './modules/my-bookings-past/my-bookings-past.module#MyBookingsPastModule'
  },
  {
    path: 'my-vouchers',
    pathMatch: 'full',
    // component: MyVouchersMainComponent,
    canActivate:[Authv2Guard],
    loadChildren: './modules/my-vouchers-main/my-vouchers-main.module#MyVouchersMainModule'
  },
  { path: 'candies', loadChildren: './modules/candies/candies.module#CandiesModule' },
  {
    path: 'all-parlon-deals',
    pathMatch: 'full',
    component: AllParlonDealsV2Component,
    data: { animation: { value: 'all-parlon-deals' } },
    canActivate:[DealsBeautyBagGuard]
  },
  {
    path: 'payment-purchase',
    component: PaymentThankYouPageComponent,
    data: { animation: { value: '/payment-purchase' } },
    canActivate:[DealsBeautyBagGuard],
  },
  {
    path: 'membership-purchase',
    component: MembershipThankyouPageComponent,
    loadChildren: './modules/membership-checkout/membership-checkout.module#MembershipCheckoutModule'
  },
  {
    path: 'membership/:slug/:branch-slug',
    component: MembershipCheckoutComponent,
    // loadChildren: () => import('./modules/membership-checkout/membership-checkout.module').then(x => x.MembershipCheckoutModule)
  },
  {
    path: 'membership/:slug',
    pathMatch: 'full',
    component: MembershipCheckoutComponent,
    // loadChildren: () => import('./modules/membership-checkout/membership-checkout.module').then(x => x.MembershipCheckoutModule)
  },
  {
    path: 'my-bookings',
    pathMatch: 'full',
    // component: BookingsComponent,
    data: { animation: { value: 'bookings' } },
    canActivate: [AuthGuard],
    loadChildren: './modules/bookings/bookings.module#BookingsModule'
  },
  {
    path: 'og-perks',
    pathMatch: 'full',
    component: OgPerksComponent,
    canActivate:[OgGuard],
    // loadChildren: () => import('./modules/og-perks/og-perks.module').then(x => x.OgPerksModule)
  },
  {
    path: 'og-perks/perk/:slug',
    pathMatch: 'full',
    component: OgPerksInnerComponent,
    canActivate:[OgGuard],
    // loadChildren: () => import('./modules/og-perks-inner/og-perks-inner.module').then(x => x.OgPerksInnerModule)
  },
  {
    path: 'og-card',
    pathMatch: 'full',
    // component: OgCardComponent,
    canActivate:[OgGuard],
    loadChildren: './modules/og-card/og-card.module#OgCardModule'
  },
  {
    path: 'be-an-og-member',
    pathMatch: 'full',
    // component: OgInviteFormComponent,
    canActivate:[OgGuard],
    loadChildren: './modules/og-invite-form/og-invite-form.module#OgInviteFormModule'
  },
  {
    path: 'og-membership-purchase',
    pathMatch: 'full',
    component: OgInviteWelcomeComponent,
    canActivate:[OgGuard]
  },
  {
    path: 'parlon/:slug',
    component: ParlonInnerComponent,
    data: { animation: { value: 'parlon/:slug' }, branch: [] },
    children: [
      // {
      //   path: '', redirectTo: 'services', pathMatch: 'prefix'
      // },
      {
        path: 'services',
        component:  ParlonServicesComponent
      },
      {
        path: 'deals',
        component:  ParlonDealsComponent,
        canActivate:[DealsBeautyBagGuard]
      },
      {
        path: 'photos',
        component:  ParlonPhotosComponent
      },
      {
        path:  'hours',
        component:  ParlonHoursComponent
      },
      {
        path:  'covid19safety',
        component:  ParlonPromosComponent
      },
      {
        path:  'press',
        component:  ParlonPressComponent
      },
      {
        path:  'google-review',
        component:  ParlonGoogleReviewComponent
      }
    ]
  },
  {
    path: 'parlon/:slug/:branch',
    component: ParlonInnerComponent,
    data: { animation: { value: 'parlon/:slug/:branch' }, branch: [] },
    children: [
      // {
      //   path: '', redirectTo: 'services', pathMatch: 'full'
      // },
      {
        path:  'google-review',
        component:  ParlonGoogleReviewComponent
      },
      {
        path:  'services',
        component:  ParlonServicesComponent,
      },
      {
        path: 'deals',
        component:  ParlonDealsComponent,
        canActivate:[DealsBeautyBagGuard]
      },
      {
        path:  'photos',
        component:  ParlonPhotosComponent
      },
      {
        path:  'hours',
        component:  ParlonHoursComponent
      },
      {
        path:  'covid19safety',
        component:  ParlonPromosComponent
      },
      {
        path:  'press',
        component:  ParlonPressComponent
      }
    ]
  },
  {
    path: 'payments/:token',
    component: PaymentLinkComponent,
    data: { animation: { value: 'payment-link' } },
    canActivate:[ParlonPayGuard]
  },
  {
    path: 'mobile/search',
    pathMatch: 'full',
    component: MobileSearchComponent,
    data: { animation: { value: 'search' } }
  },
  {
    path: 'search/:location',
    pathMatch: 'full',
    component: SearchPageResultComponent,
    data: { animation: { value: 'search' } }
  },
  {
    path: 'search/:location/:value',
    pathMatch: 'full',
    component: SearchPageResultComponent,
    data: { animation: { value: 'search' } }
  },

  {
    path: 'glife/:slug',
    pathMatch: 'full',
    component: GlifeLandingPageComponent,
    data: { animation: { value: 'glife/:slug' } },
    canActivate:[GlifeGuard]
  },

  {
    path: 'glife-services/:slug',
    pathMatch: 'full',
    component: GlifeServiceListComponent,
    data: { animation: { value: 'glife-services/:slug' } },
    canActivate:[GlifeGuard]
  },

  {
    path: 'glife-checkout/:slug',
    pathMatch: 'full',
    component: GlifeCheckoutComponent,
    data: { animation: { value: 'glife-checkout/:slug' } },
    canActivate:[GlifeGuard]
  },
  {
    path: 'beauty-bag-deals/:slug',
    pathMatch: 'full',
    component: BeautyBagDealsComponent,
    data: { animation: { value: 'beauty-bag-deals/:slug' } },
    canActivate:[DealsBeautyBagGuard]
  },
  {
    path: 'beauty-bag-checkout',
    pathMatch: 'full',
    component: BeautyBagCheckoutComponent,
    data: { animation: { value: 'beauty-bag-checkout' } },
    canActivate:[DealsBeautyBagGuard]
  },
  {
    path: 'beauty-bag-purchase',
    pathMatch: 'full',
    component: BeautyBagThankyouComponent,
    data: { animation: { value: 'beauty-bag-purchase' } },
    canActivate:[DealsBeautyBagGuard]
  },
  {
    path: 'gifting/:slug',
    pathMatch: 'full',
    component: GiftingComponent,
    data: { animation: { value: 'gifting/:slug' } },
    canActivate:[GiftingGuard]
  },
  {
    path: 'gifting',
    pathMatch: 'full',
    component: GiftingComponent,
    data: { animation: { value: 'gifting' } },
    canActivate:[GiftingGuard]
  },
  {
    path: 'promo-purchase',
    pathMatch: 'full',
    component: GiftingThankyouComponent,
    data: { animation: { value: 'promo-purchase' } },
    canActivate:[GiftingGuard]
  },
  {
    path: 'book-appointment',
    pathMatch: 'full',
    component: BookAppointmentComponent
  },
  {
    path: 'booking-status',
    pathMatch: 'full',
    component: BookAppointmentMessageComponent
  },
  {
    path: 'beauty-bash/teaser',
    pathMatch: 'full',
    component: BeautyBashTeaserComponent,
    canActivate:[BeautyBashTeaserGuard]
  },
  {
    path: 'beauty-bash/deals',
    pathMatch: 'full',
    component: BeautyBashListingComponent,
    canActivate:[BeautyBashDealsGuard]
  },
  {
    path: 'beauty-bash/checkout/:slug',
    pathMatch: 'full',
    component: BeautyBashCheckoutComponent,
    canActivate:[BeautyBashDealsGuard]
  },
  {
    path: 'beauty-bash/bash-event-purchase',
    pathMatch: 'full',
    component: BeautyBashThankyouComponent
  },
  {
    path: 'booking-payment/:reference_id',
    pathMatch: 'full',
    component: BookingPaymentComponent
  },
  {
    path: 'purchased-deal-appointment',
    pathMatch: 'full',
    component: PurchasedDealsBookingComponent
  },
  {
    path: 'voucher-booking-status',
    pathMatch: 'full',
    component: MultiBookingThankyouComponent
  },
  {
    path: 'opt-out-form',
    pathMatch: 'full',
    loadChildren: './modules/opt-out-form-google-reserve/opt-out-form-google-reserve.module#OptOutFormGoogleReserveModule'
  },
  { path: '404', component: PageNotFoundComponent },
  { path: 'brb', component: BrbPageComponent },
  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }),
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [ AuthGuard ]
})
export class AppRoutingModule {

 }