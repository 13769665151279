<div class="card bg-gray-fa rounded-3 my-5 mt-lg-0 mb-0">
	<div class="card-body">
		<div class="row row-cols-1 gx-md-8">
			<div class="col">
				<h4 class="h4 fw-semibold">{{membership['name']}}</h4>
				<div class="row row-parlon-with-logo align-items-center g-5 mb-3">
					<div class="col-auto col-logo">
						<img src="{{ resourceLink + parlon['logo'] }}" alt="{{ parlon['logo'] }}"
								class="img-fluid obj-fit-contain" width="70">
					</div>
					<div class="col-auto">
						<h6 class="h6 fw-normal mb-0">{{ parlon['business_name'] }}</h6>
					</div>
				</div>
				<div class="col">

					<table class="table table-borderless table-sm">
						<tbody>
							<tr>
								<td class="text-muted ps-0">Membership Price</td>
								<td class="text-end tabular-nums pe-0">PHP {{ membership['price'] | number}}</td>
							</tr>

							<tr *ngIf="hasDiscount">
								<td class="text-primary ps-0"><i class="far fa-tag mr-2"></i> Coupon Discount</td>
								<td class="text-primary text-end tabular-nums pe-0">- PHP {{ discount | number}}</td>
							</tr>

						</tbody>
					</table>

					<hr>

					<div class="row align-items-center">
						<div class="col-6">
							<p class="fw-bold mb-0">Total (inc. VAT)</p>
						</div>
						<div class="col-6">
							<p class="text-end fw-bold tabular-nums mb-0">PHP {{ price | number}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>