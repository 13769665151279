import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ElementRef, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { OgService } from '../../services/og/og.service';
import { PaginationInstance } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { MatTabGroup } from '@angular/material';
declare var Flickity: any;
declare var $:any;
@Component({
  selector: 'app-og-perks',
  templateUrl: './og-perks.component.html',
  styleUrls: ['./og-perks.component.scss']
})
export class OgPerksComponent implements OnInit, OnDestroy,AfterViewInit {


  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  constructor(
    private el: ElementRef,
    private ogService:OgService,
    private userService:UserAccountService
  ) { }

  brands = [];
  offers = [];
  categories = [];
  selectedCategories = [];
  selectedOfferTypes = [];
  appliedOfferTypes = [];
  showSelectedBrand:any;
  numberOfPages:any= [];
  currentPage:number =0;
  totalRecordsOffer:number =0;
  totalRecordsBrand:number = 0;
  paramsOffer:any;
  paramsBrand:any;
  p=0;
  searchInput:string ="";

  selectedFreebie = false;
  selectedDiscount = false;
  selectedSpecial = false;

  $searchSubscription:Subscription|undefined;
  $searchPageOfferSubscription:Subscription|undefined; 
  $searchPageBrandSubscription:Subscription|undefined;  
  $categorySubscription:Subscription|undefined;  
  $brandSubscription:Subscription|undefined;  

  $userSubscription:Subscription|undefined;  
  allBrandsForFilter = [];
  appliedBrandsForFilter = [];
  isLoading= false;

  countFilter =0;

  isLoggedIn = false;
  isOgMember = false;
  brandsCount = 0;

  showCategories = false;  
  selectedTabIndex = 0;
  
  showRedeemTab = false;

  

  onTabSelected(index: number) {
    // this.selectedTabIndex = index;
    this.tabGroup.selectedIndex = index;
  }  






  getBrandFilter(){
    this.$brandSubscription =  this.ogService.getAllBrands().subscribe((data:any)=>{
      this.allBrandsForFilter = data.brands;
      this.brands = data.brands;
      this.brandsCount = data.brands.length;
      this.allBrandsForFilter.forEach((item)=>{
        item.selected = false;
      })      
    })
  }

  ngOnDestroy(){
    if(this.$searchSubscription)
      this.$searchSubscription.unsubscribe()
    if(this.$searchPageOfferSubscription)
      this.$searchPageOfferSubscription.unsubscribe()
    if(this.$searchPageBrandSubscription)
      this.$searchPageBrandSubscription.unsubscribe()
    if(this.$categorySubscription)
      this.$categorySubscription.unsubscribe()
    if(this.$brandSubscription)
      this.$brandSubscription.unsubscribe()
    if(this.$userSubscription)
      this.$userSubscription.unsubscribe()     
  }  

  public brand_config: PaginationInstance = {
    id: 'brand_id',
    itemsPerPage: 16,
    currentPage: 0,
    totalItems:0
  }

  public offer_config: PaginationInstance = {
    id: 'offer_id',
    itemsPerPage: 30,
    currentPage: 0,
    totalItems:0
  }


  pageChangeEventOffer(event: number){ 
    let types = [];
    let brandIds = [];

    this.appliedOfferTypes.forEach((item)=>{
      if(item=='selectedFreebie')
        types.push('Freebie')
      if(item=='selectedDiscount')  
        types.push('Discount')
      if(item=='selectedSpecial')  
      types.push('Special')  
    })

    this.appliedBrandsForFilter.forEach((item)=>{
      brandIds.push(item.id);
    })

    // alert(types);


    this.offer_config.currentPage= event;
    // alert(event);
    this.ogService.searchV2(this.offer_config.currentPage, types,brandIds).subscribe((data:any)=>{
      this.offers = data.data;
      this.totalRecordsOffer =data.total;
      this.offer_config.currentPage = data.current_page
      this.offer_config.totalItems = data.total;
      // this.isLoading = true
      setTimeout(() => {
        document.getElementById('SalonAndWellness').scrollIntoView();
    }, 0);

    })

  }

  pageChangeEventBrand(event: number){    
    this.brand_config.currentPage= event;
    this.$searchPageBrandSubscription =this.ogService.searchPage(this.paramsBrand,this.brand_config.currentPage-1,'brands').subscribe((data)=>{
      this.brands = data.results[0].hits;        
      this.totalRecordsBrand =data.results[0].nbHits; 
      this.brand_config.totalItems = data.results[0].nbHits;
      this.brand_config.currentPage = data.results[0].page+1;
      this.paramsBrand = this.getQueryParams(data.results[0].params,'brands'); 
      setTimeout(() => {
        document.getElementById('SalonAndWellness').scrollIntoView();
    }, 0);
    })
  }

  onClose(){
    // alert(1);
    let findFreeBie, findDiscount, findSpecial=false;       
    this.allBrandsForFilter.forEach((brand)=>{
      let find = false;
      this.appliedBrandsForFilter.forEach((applied)=>{
        if(brand.id == applied.id){
          find = true;
        }
      })
      if(!find)
      brand.selected = false;
      else
      brand.selected = true

    })

    this.appliedOfferTypes.forEach(item=>{
      if(item=='selectedFreebie'){
        findFreeBie = true;       
      }
      if(item=='selectedDiscount'){
        findDiscount = true;
      }
      if(item=='selectedSpecial'){
        findSpecial = true;
      }

    })

    this.selectedFreebie = findFreeBie ? true : false;
    this.selectedOfferTypes = findFreeBie ? this.selectedOfferTypes : this.selectedOfferTypes.filter(item => item !== 'selectedFreebie');
    this.selectedDiscount = findDiscount ? true : false;
    this.selectedOfferTypes = findDiscount ? this.selectedOfferTypes : this.selectedOfferTypes.filter(item => item !== 'selectedDiscount');
    this.selectedSpecial = findSpecial ? true : false;
    this.selectedOfferTypes = findSpecial ? this.selectedOfferTypes : this.selectedOfferTypes.filter(item => item !== 'selectedSpecial');

  }

  resetFilter(){
    this.countFilter = 0;
    this.selectedDiscount = false;
    this.selectedFreebie = false;
    this.selectedSpecial = false;
    this.appliedOfferTypes = [];
    this.selectedOfferTypes = [];

    this.allBrandsForFilter.forEach((item)=>{
      item.selected = false;
    })
    this.appliedBrandsForFilter = [];

    this.search();
  }

  applyFilter(){
    this.countFilter = 0;
    this.appliedOfferTypes = this.selectedOfferTypes;   
    
      
    this.countFilter+=this.appliedOfferTypes.length;    
    let brands = this.allBrandsForFilter.filter(item => item.selected).map(brand => ({...brand}));
    this.appliedBrandsForFilter = brands;
    this.appliedBrandsForFilter.forEach(item=>{
      if(item.selected){
        this.countFilter+=1;
      }
    }) 
    this.search();
  } 


  updateSelection(item, isSelected) {
    // alert(isSelected);
    if (isSelected) {
      // Add the item to the selected items list
      this.selectedOfferTypes.push(item)
    } else {
      // Remove the item from the selected items list
      const index = this.selectedOfferTypes.indexOf(item);
      this.selectedOfferTypes.splice(index, 1);
    }
  }

  getQueryParams(url,type="offer") {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })

    if(type=='offer'){
      params['facetFilters']= JSON.parse(params['facetFilters'].replace(/\//g, ""));
      if(params['facetFilters'] && params['facetFilters'][0] != undefined != undefined){
        params['facetFilters'][0]=params['facetFilters'][0].map((item)=>{
          item = item.replace(/\+/g,' ')
          return item;
        });
      }
      
      if(params['facetFilters'] && params['facetFilters'][1] != undefined != undefined){
        params['facetFilters'][1]=params['facetFilters'][1].map((item)=>{
          item = item.replace(/\+/g,' ')
          return item;
        });
      }
    }
        
    return params;
  }


  search(){
    this.isLoading = false;

    let types = [];
    let brandIds = [];

    this.appliedOfferTypes.forEach((item)=>{
      if(item=='selectedFreebie')
        types.push('Freebie')
      if(item=='selectedDiscount')  
        types.push('Discount')
      if(item=='selectedSpecial')  
      types.push('Special')  
    })

    this.appliedBrandsForFilter.forEach((item)=>{
      brandIds.push(item.id);
    })


    this.$searchSubscription = this.ogService.searchV2(1,types,brandIds).subscribe((data:any)=>{
      this.offers = data.data;
      this.totalRecordsOffer =data.total;
      this.offer_config.currentPage = data.current_page
      this.offer_config.totalItems = data.total;
      this.isLoading = true
    }) 


    // this.$searchSubscription = this.ogService.search(this.searchInput, this.selectedCategories, this.appliedOfferTypes, this.appliedBrandsForFilter).subscribe((data:any)=>{
    //   this.brands = data.results[0].hits;
    //   this.offers = data.results[1].hits;        
      
    //   this.totalRecordsBrand = data.results[0].nbHits;
    //   this.brand_config.currentPage = data.results[0].page;
    //   this.brand_config.totalItems = data.results[0].nbHits;      
    //   this.paramsBrand = this.getQueryParams(data.results[0].params,'brands');

    //   this.totalRecordsOffer =data.results[1].nbHits;
    //   this.offer_config.currentPage = data.results[1].page;
    //   this.offer_config.totalItems = data.results[1].nbHits;
    //   this.paramsOffer = this.getQueryParams(data.results[1].params);
      
    //   this.isLoading = true;
    // })
  }

  changeModel(){
    this.search();
  }

  selectBrand(brand:any){
    window.open(brand.link_out_url, '_blank');
  }

  selectCategory(category:any){        
    let exists = this.selectedCategories.includes(category.category_name);
    let findCategory = this.categories.findIndex(item=>item.category_name== category.category_name);
    if(exists){
      // alert(findCategory); 
      this.categories[findCategory].is_selected = false;            
      this.selectedCategories = this.selectedCategories.filter((item:any)=> item != category.category_name);
    }else{
      this.selectedCategories.push(category.category_name);
      this.categories[findCategory].is_selected = true;
    }
    this.search();
  }


  getInitialPage(){
    this.search();
    this.$categorySubscription = this.ogService.getCategories().subscribe((data:any)=>{
      this.categories = data.categories;      

      setTimeout(() => {
        const elem = this.el.nativeElement.querySelector('.flickity-og-categories');
        // let elem = el.querySelector('.flickity-og-categories');
        let flkty_options = {
          contain: true,
          cellAlign: 'left',
          freeScroll: true,
          freeScrollFriction: 0.075,
          pageDots: false,
          prevNextButtons: false,
          wrapAround: false
        };
  
        if (matchMedia('screen and (min-width: 768px)').matches) {
          flkty_options.cellAlign = 'center';
        }      
        let flkty = new Flickity(elem, flkty_options);
      }, 500);
    })

  }  


  ngAfterViewInit() {
    
      
  }
    
  ngOnInit() {        
    this.getInitialPage();
    this.getBrandFilter();
    this.$userSubscription =  this.userService.getLoggedInUser.subscribe(data=>{
      this.isLoggedIn = false;
      this.isOgMember = false;      
      if(data){        
        if(data.data){
          this.isLoggedIn = true;
        }        
        if(data.og.member==true){
          this.isOgMember = true;
        }
      }
    });
  }

}
