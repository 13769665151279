import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { BookingPaymentComponent } from './booking-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonElementsModule } from '../../common';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { NgxCcModule } from 'ngx-cc';
import { MatProgressSpinnerModule } from '@angular/material';


@NgModule({
  imports: [
    CommonModule, MaterialModule, FormsModule, CommonElementsModule, ReactiveFormsModule, NgxCcModule,
    MatProgressSpinnerModule
  ],
  declarations: [BookingPaymentComponent, CreditCardFormComponent]
})
export class BookingPaymentModule { }
