<div class="d-flex bg-danger justify-content-center m-auto w-50">
  <div class="">
    <img class="img-fluid" src="{{data.selectedPhoto}}" alt="" srcset="">
    <div class="images mt-2" >
      
      <img   *ngFor="let photo of data.photos; let i =index" class="img-fluid mr-2" src="{{photo.src}}" alt="">
    </div>
    
  </div>
  
</div>


