<!--
	NOTE:
	- To edit section background and text color, use the following CSS Variables below. If null, fallback values are set in CSS.
	- If text color is set to white, change `link-secondary` class to `link-white`;
	- IMPORTANT: Please REMOVE each CSS Variables when there are no values to be set to prevent empty value and overriding the fallback values.

	- For deal card template-specific CSS Variables, see global <deal-card-template> component for more variables.
-->


<section class="s-ft-deals-grid py-5 py-md-7 overflow-x-hidden" [ngStyle]="getBackground()">
	<div class="container">
		<div class="row justify-content-md-start mb-4 mb-md-5">
			<div class="col-12 col-md-auto">
				<div class="row align-items-center justify-content-between">
					<div class="col">
						<div class="d-flex flex-header-content align-items-center col-gap-3 col-gap-md-4 col-gap-lg-5">
							<div class="col-auto col-logo">
								<div class="ratio ratio-header-img ratio-4x3">
									<img src="{{ resource + data['merchant_highlight_parlon_logo'] }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
								</div>
							</div>

							<div class="col">
								<h2 class="h2 text-header-title fw-semibold mb-0" [ngStyle]="{'color': data.merchant_highlight_title_color}">{{data['merchant_highlight_title']}}</h2>
							</div>
						</div>
					</div>

					<div class="col-auto d-none d-md-block">
						<a href="{{data['linkout']}}" class="link-secondary fs-2" [attr.data-pid]="data['deal_id']">
							<i class="fas fa-chevron-right fa-xs fa-fw"></i>
						</a>
					</div>
				</div>
			</div>

			<div class="col-12">
				<p class="text-parlon-location mt-2 mb-0" [ngStyle]="{'color': data.merchant_highlight_title_color}">
					<i class="fas fa-location-dot fa-fw me-1"></i>
					{{data['merchant_highlight_description']}}
				</p>
			</div>
		</div>

		<div class="row flex-ft-deals-grid row-cols-2 row-cols-md-3 row-cols-lg-4 g-4">
			<div class="col" *ngFor="let deal of data['deals']">
				<deal-card-template 
					[userID]="userId"
					[isLoggedIn]="isLoggedIn" [data]="deal" [locations]="data['locations']"
					[ribonStyle]="{'background': data.merchant_highlight_ribbon_color, color: data.merchant_highlight_text_color, cardBg: data.card_bg_color, cardFontColor: data.card_font_color}">
				</deal-card-template>
			</div>
		</div>

		<div class="d-md-none mt-5">
			<div class="row justify-content-center">
				<div class="col-auto">
					<a href="{{data['linkout']}}" [attr.data-pid]="data['deal_id']" mat-button class="mat-white">See all {{data['merchant_highlight_parlon_name']}} deals</a>
				</div>
			</div>
		</div>
	</div>
</section>