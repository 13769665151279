<div class="d-flex justify-content-between align-items-center">
  <div class="grid-container">  
    <div class="initial-avatar" [style.background]="data.backgroundColor">
      {{data.name.charAt(0)|titlecase}}
    </div>
    <div class="mt-2">
      <h5 style="font-weight: bold;">{{data.name}}</h5>
      <div class="d-flex align-items-center" style="color:#919191;">
        <!-- <span>{{data.reviewCount}} {{data.reviewCount==1? 'review': 'reviews'}}  </span>       -->
        <div *ngIf="_album.length>0" class="dot d-inline-block ms-1 me-1"></div>
        <span *ngIf="_album.length>0">{{_album.length}} {{_album.length==1? 'photo':'photos'}}</span>
      </div>
      
    </div>
  </div>

  <!-- <i class="fas fa-ellipsis-vertical">
  </i> -->
</div>
<div class="d-flex align-items-center mt-3">
  <div class="star-rating me-1" style="display: flex; align-items: center;">
    <span
      class="star"
      [class.full]="data.starCount >= 0.8"
      [class.half]="data.starCount >= 0.3 && data.starCount < 0.8"
    ></span>
    <span
      class="star"
      [class.full]="data.starCount >= 1.8"
      [class.half]="data.starCount >= 1.3 && data.starCount < 1.8"
    ></span>
    <span
      class="star"
      [class.full]="data.starCount >= 2.8"
      [class.half]="data.starCount >= 2.3 && data.starCount < 2.8"
    ></span>
    <span
      class="star"
      [class.full]="data.starCount >= 3.8"
      [class.half]="data.starCount >= 3.3 && data.starCount < 3.8"
    ></span>
    <span
      class="star"
      [class.full]="data.starCount >= 4.8"
      [class.half]="data.starCount >= 4.3 && data.starCount < 4.8"
    ></span>
  </div>

    <span style="color:#919191;">{{data.dateReviewed}}</span>
 
</div>

<div class="mt-3">
  <span>
    {{data?.reviewText}}
  </span>
</div>
<div class="flickity flickity-ft-images">
  <a  *ngFor="let photo of _album; let i =index" >
    <div class="images mt-2" (click)="open(i)">
      <img    class="img-fluid mr-2" src="{{photo.src}}" alt="" >
    </div>  
  </a>  
</div>





