import { Component, Input, OnInit, ElementRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../../services/rest.service';
import { MembershipService } from '../../../../services/membership/membership.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare var My2c2p: any;

@Component({
  selector: 'membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss']
})
export class MembershipFormComponent implements OnInit {

  @Input() parlon: any = [];
  @Output() selectedMembership = new EventEmitter();
  selected: object;
  membershipForm: FormGroup;
  isLinear = false;
  loading = false;
  submitted = false;
  isTotalZero = false;
  isTransactionFailed = false;
  resourceLink = this.rest.resourceLink();
  cc: object;
  form_data: object;
  paymentChannels: object;
  memberships: any = [];
  selected_branch_id;

  activePromo: object;
  activeCode = '';
  @Output() promoCode = new EventEmitter();

  // selected_channel:string="";

  totalPrice:number = 0;
  price:number = 0;

  constructor(
    private rest: RestService,
    private MembershipService: MembershipService,
    private smartModal: NgxSmartModalService,
    private el: ElementRef,
    private route: ActivatedRoute,
  ) {
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
    this.form_data = {
      membership_id: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      city: '',
      branch_id: '',
      payment_channel_code: '',
      securePayToken: ''
    };
   }

  ngOnInit() {
    this.getPaymentChannels();
    this.membershipForm = new FormGroup({
      'personalDetailsFormGroup': new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        'contact_number': new FormControl(null, Validators.required),
        'city': new FormControl(null, Validators.required),
        'branch_id': new FormControl(null),
      }),
      'membershipCardOptionsFormGroup': new FormGroup({
        'membershipCardOptions': new FormControl(null, Validators.required),
      }),
      'paymentFormGroup': new FormGroup({
        'creditCard': new FormControl(null, Validators.required),
        'promoCode': new FormControl(null),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
        'paymentChannel': new FormControl(null, Validators.required),
        'eVoucherPolicy': new FormControl(false,Validators.requiredTrue),
      })      
    });
    
  }

  ngOnChanges() {
    let app = this;
    if(this.parlon['membership_scope'] == 'Parlon') {
      this.memberships = this.parlon['membership'];
      // console.log(100,  this.memberships)
    } else {
      // added by angelito
      this.memberships = this.parlon['membership'];
      
      setTimeout(() => {
        app.selected_branch_id = app.membershipForm.value.personalDetailsFormGroup.branch_id;
        app.updateSelectedBranch();                        
        // console.log('app.selected_branch_id',app.selected_branch_id)
      }, 500);
    }
  }

   // convenience getter for easy access to form fields
   get f() { return this.membershipForm.get('paymentFormGroup') }
  
   showModal(membershipId, membership) {
    this.smartModal.setModalData({membership
    }, 'perksModal', true);    
    this.smartModal.getModal('perksModal').open();
  }

  onSubmit(){
    let app = this;
    // Validate Fields
    if(this.parlon['membership_scope'] == 'Branch' && app.membershipForm.value.personalDetailsFormGroup.branch_id == null) {
      Object.keys(app.membershipForm.controls.personalDetailsFormGroup['controls']).forEach(function(key) {
        app.membershipForm.controls.personalDetailsFormGroup['controls'][key].markAsTouched();
      });
      app.scrollToFirstInvalidControl();
      return false
    }
    Object.keys(app.membershipForm.controls.personalDetailsFormGroup['controls']).forEach(function(key) {
      app.membershipForm.controls.personalDetailsFormGroup['controls'][key].markAsTouched();
    });
    if(app.membershipForm.controls.personalDetailsFormGroup.status == "INVALID") {
      app.scrollToFirstInvalidControl();
      return false;
    }
    if(app.membershipForm.controls.membershipCardOptionsFormGroup.value.membershipCardOptions == null) {
      app.membershipForm.controls.membershipCardOptionsFormGroup['controls']['membershipCardOptions'].markAsTouched();
      app.scrollToFirstInvalidControl();
      return false;
    }

    if(app.membershipForm.value.paymentFormGroup.paymentChannel == 'parlon_credit_card' && app.isTotalZero == false) {

      if(app.membershipForm.controls.paymentFormGroup.status == null) {
        app.scrollToFirstInvalidControl();
        return false;
      }
     
      app.cc['cardnumber'] = app.membershipForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      app.cc['cvv'] = app.membershipForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      app.cc['month'] = app.membershipForm.value.paymentFormGroup.ccMonth;
      app.cc['year'] = app.membershipForm.value.paymentFormGroup.ccYear.toString();

    }
    setTimeout(() => {
      app.form_data['membership_id'] = app.membershipForm.value.membershipCardOptionsFormGroup.membershipCardOptions.id;
      app.form_data['first_name'] = app.membershipForm.value.personalDetailsFormGroup.first_name;
      app.form_data['last_name'] = app.membershipForm.value.personalDetailsFormGroup.last_name;
      app.form_data['email'] = app.membershipForm.value.personalDetailsFormGroup.email;
      app.form_data['mobile'] = app.membershipForm.value.personalDetailsFormGroup.contact_number;
      app.form_data['city'] = app.membershipForm.value.personalDetailsFormGroup.city;
      app.form_data['payment_channel_code'] = app.membershipForm.value.paymentFormGroup.paymentChannel;
      if(app.parlon['membership_scope'] == 'Branch') {
        app.form_data['branch_id'] = app.membershipForm.value.personalDetailsFormGroup.branch_id;
      }
      if(!(app.activePromo // 👈 null and undefined check
        && Object.keys(app.activePromo).length === 0
          && Object.getPrototypeOf(app.activePromo) === Object.prototype)) {
            if(app.activePromo != undefined) {
              if(app.activePromo['total'] == 0) {
                app.form_data['payment_channel_code'] = null;
              }
              app.form_data['promo_code'] = app.membershipForm.value.paymentFormGroup.promoCode;
            }
        }
      if(app.isTotalZero) {    
        if(app.membershipForm.value.paymentFormGroup.eVoucherPolicy == false) {
          return false;
        }
        app.smartModal.getModal('voucherModal').open();
      } else {
        My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {
          app.form_data['securePayToken'] = encryptedData.encryptedCardInfo;
        });
        if(app.membershipForm.value.paymentFormGroup.eVoucherPolicy == false) {
          return false;
        }
        app.requestPayment();
      }
    }, 200);
  }

  requestPayment() {
    let app = this;
    app.loading = true;
    app.rest.createMembershipPaymentRequest(app.form_data).subscribe((data: {}) => {
      if(data['status'] == true && data['isRedirect'] == true){
        window.location.href = data['response']['data'];
      } else {
        app.loading = false;
        app.isTransactionFailed = true;
      }
    });
  }

  applyCodeHandler(data) {
    if(data) {

      // purpose of this is to disable the metrobank payment if total price is lower than 3000
    //  if(this.activePromo['total'])
    //  this.totalPrice = this.activePromo['total'];
    //  else
    //  this.totalPrice = this.price; 
     //  end      

     this.activePromo = data;
     this.promoCode.emit(data);
     this.activeCode = this.membershipForm.value.paymentFormGroup.promoCode;
     if(this.activePromo['total'] == 0) {
       this.isTotalZero = true;
     } else {
      this.isTotalZero = false;
     }
    } else {
      this.activeCode = '';
      this.promoCode.emit({});
      this.isTotalZero = false;
    }
  }

  membershipSelect(e) {
    this.activePromo = null;
    this.price = e.value.price;
    this.totalPrice =  Number(this.price);
    this.selected = e.value;
    delete this.form_data['promo_code'];
    this.selectedMembership.emit(e.value);
  }

  getPaymentChannels() {
    this.rest.getPaymentChannels().subscribe((data: {}) => {
      this.paymentChannels = data['data'];
    });
  }

  updateSelectedBranch(){
    let app = this;
    app.selected_branch_id = app.membershipForm.value.personalDetailsFormGroup.branch_id;
    this.MembershipService.getBranchMemberships({branch_id: app.selected_branch_id}).subscribe((data: {}) => {
      app.memberships = data;
      app.membershipForm.controls.membershipCardOptionsFormGroup['controls']['membershipCardOptions'].setValue(null);
      app.membershipForm.controls.membershipCardOptionsFormGroup.updateValueAndValidity();
      this.selectedMembership.emit('');
    });
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid"
    );
    firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  paymentOptionClick(target) {
    // let element: HTMLElement = document.getElementById(target) as HTMLElement;
    // console.log(element);
    // this.selected_channel = target;
    
    this.membershipForm.controls.paymentFormGroup.patchValue({
      paymentChannel:target
    });
      
  }
  
}
