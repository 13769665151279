<div class="navbar-container pos-relative" *ngIf="isRouteHidden">
	<app-announcement-banner [data]="contentData['web_alert']" *ngIf="isVisible">
	</app-announcement-banner>

	<ng-container *ngIf="contentLoaded == true || navEmpty">
		<ng-container [ngSwitch]="(navbarType$ | async)">
			<ng-container *ngSwitchCase="NavbarType.GLOBAL">
				<global-nav [data]="contentData" [cartCount]="cartCount" [categories]="true"
					(searchEmit)="showSearch($event)"></global-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.NO_CATEGORY">
				<global-nav [data]="contentData" [cartCount]="cartCount" [categories]="false"
					(searchEmit)="showSearch($event)"></global-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.PARLON_INNER">
				<global-nav [data]="contentData" [cartCount]="cartCount" [categories]="true" [parlon_inner]="true"
					(searchEmit)="showSearch($event)"></global-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.DEALS">
				<deal-nav [cartCount]="cartCount" (searchEmit)="showSearch($event)"></deal-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.BEAUTY_BASH">
				<beauty-bash-nav></beauty-bash-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.LOGO_ONLY">
				<logoonly-nav></logoonly-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.SEARCH">
				<search-nav></search-nav>
			</ng-container>
			<ng-container *ngSwitchCase="NavbarType.MOBILE_SEARCH">
			</ng-container>
		</ng-container>
	</ng-container>


	<!-- Search dialog pop-up box -->
	<div id="search-dialog-box" class="search-dialog-box" aria-hidden="true">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
					<search-input-v2 [isShowButton]="false" [fromNav]="true" [hideDropDown]="false"></search-input-v2>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- START : To be migrated to the new navigation bar module -->
<div id="nav-user-account" [ngClass]="{ 'isAnnouncementBarExisting': isVisible, 'isAnnouncementBarNotExisting': !isVisible, 'offcanvas offcanvas-end offcanvas-account': true }"  class="" tabindex="-1">
	<div class="offcanvas-body px-0 py-6">

		<div class="btn-drawer-back">
			<a href="#" class="link-text fw-medium" data-bs-dismiss="offcanvas"><i class="fa-solid fa-xmark"></i></a>
		</div>

		<app-user-login *ngIf="!isLoggedIn && !isForgotPassword" (fbAuthEmitter)="fbAuthHandler($event)"></app-user-login>
		<app-user-drawer-dashboard *ngIf="isLoggedIn && !isForgotPassword"></app-user-drawer-dashboard>
		<app-user-forgot-password class="d-flex flex-column h-100"
			*ngIf="!isLoggedIn && isForgotPassword"></app-user-forgot-password>
	</div>
</div>
<!-- END -->

<ngx-smart-modal #fbAuthModal identifier="fbAuthModal" [dismissable]="false" [customClass]="'ngx-dialog-centered'">
  <ng-container *ngIf="fbAuthModal.hasData()">
    <div class="modal-body p-2">
      <div class="mb-5 mb-lg-0">
        <div class="text-center">
					<h3 class="h3 fw-semibold mb-4">
						Complete your details
					</h3>
					<p class="mb-4">We just need a few more of your details and we’re good to go!</p>
				</div>

				<form [formGroup]="fbRegForm" (ngSubmit)="fbRegisterSubmit()">
					<mat-form-field appearance="fill">
						<mat-label>Email address</mat-label>
						<input matInput placeholder="Email address" id="email" formControlName="email"  readonly>
						<mat-error *ngIf="f.email.errors?.required">Email is required</mat-error>
        		<mat-error *ngIf="f.email.errors?.email">You must enter a valid email address</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill" class="w-100">
						<mat-label>First name</mat-label>
						<input matInput placeholder="First name" formControlName="first_name" name="first_name" required>
						<mat-error *ngIf="f.first_name.errors?.required">The first name field is required.</mat-error>
					</mat-form-field>
		
					<mat-form-field appearance="fill" class="w-100">
						<mat-label>Last name</mat-label>
						<input matInput placeholder="Last name" formControlName="last_name" name="last_name" required>
						<mat-error *ngIf="f.last_name.errors?.required">The last name field is required.</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Mobile number</mat-label>
						<input matInput placeholder="Mobile number"  type="number" name="contact_number" formControlName="contact_number" required>
        		<mat-error *ngIf="f.contact_number.errors?.required">The contact number field is required.</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill" >
						<mat-label>Birthday</mat-label>
						<input matInput [matDatepicker]="pickerfbbday" placeholder="Birthday" formControlName="birthday">
						<mat-datepicker-toggle matSuffix [for]="pickerfbbday"></mat-datepicker-toggle>
						<mat-datepicker #pickerfbbday></mat-datepicker>						
					</mat-form-field>

					<div *ngIf="f.birthday.invalid && (f.birthday.dirty || f.birthday.touched)"  class="invalid-feedback errors mb-5">
						<mat-error class="text-danger" *ngIf="f.birthday.errors.invalidDate">You should be 18 years old and above to create a Parlon account.</mat-error>
						<!-- <mat-error class="text-danger" *ngIf="p.get('birthday').errors.invalidDate">You cannot use future date</mat-error>									 -->
						<!-- <mat-error class="text-danger" *ngIf="!p.get('birthday').errors.invalidDate">Invalid date format</mat-error>								 -->
					</div>

					<div class="mb-5">
						<mat-checkbox id="acceptTerms" color="primary" formControlName="acceptTerms"> 
							By clicking 'Continue', I confirm that I have read and agree to <a href="https://legal.parlon.ph/post/terms-of-use" target="_blank" style="display: inline-block; color:#46b0a9;" class="nav-link link-text fw-medium"> Parlon Terms of Use </a> and be bound by
							<a href="https://legal.parlon.ph/post/privacy-notice" style="display: inline-block; color:#46b0a9;" class="nav-link link-text fw-medium" target="_blank">Parlon Privacy Notice.</a> 
						</mat-checkbox>
						<mat-error *ngIf="submitted && f.acceptTerms.errors?.required">Accept Terms & Conditions is required.</mat-error>
					</div>

					<button mat-flat-button color="secondary" class="btn-submit mb-5" type="submit">
						CONTINUE
						<mat-icon *ngIf="formLoading"><mat-spinner color="accent" diameter="20">
						</mat-spinner></mat-icon>
					</button>

				</form>

      </div>
    </div>
  </ng-container>
</ngx-smart-modal>

<ngx-smart-modal #registerSelect identifier="registerSelect" [dismissable]="false" [customClass]="'ngx-dialog-centered'">
  <ng-container>
    <div class="modal-body p-4">
      <div class="mb-5 mb-lg-0">
				<h3 class="h3 fw-semibold mb-4">
					How do you want to register?
				</h3>
        <button mat-flat-button color="facebook" class="btn-submit" data-bs-dismiss="offcanvas" (click)="callFbShowAuth()">
					<i class="fa-brands fa-facebook mx-2"></i> CONTINUE WITH FACEBOOK 
				</button>
				<div class="p-3"></div>
				<button routerLink="/register" mat-flat-button color="secondary" class="btn-submit" data-bs-dismiss="offcanvas" (click)="registerSelect.close()">
					EMAIL ADDRESS
				</button>
      </div>
    </div>
  </ng-container>
</ngx-smart-modal>

<ngx-smart-modal #fbAuthFail identifier="fbAuthFail" [dismissable]="false" [customClass]="'ngx-dialog-centered'">
  <ng-container>
    <div class="modal-body p-4">
      <div class="mb-5 mb-lg-0">
				<h3 class="h3 fw-semibold mb-4">
					ERROR COPY HERE
				</h3>
      </div>
    </div>
  </ng-container>
</ngx-smart-modal>