<div class="filters-container sidebar"  style="min-height: 90vh;">

  <div>
    <div class="mb-2 d-flex justify-content-between align-items-center ">
      <div>
        <div class="fs-5 fw-semibold ">Filters</div>
      </div>
      
      <div class="d-flex align-items-center">
        <button *ngIf="selectedCategories.length >0 || selectedParlons.length > 0 || selectedLocations.length > 0 || selectedSubCategories.length >0" (click)="resetFilter2()" mat-flat-button color="secondary">Clear all<mat-icon class="ms-1" [inline]="true">clear</mat-icon> </button>
      </div>
      
    </div>
    
    

    <div class="search-category-filter-container mb-2" >
      <div class="sort-select">
        <mat-accordion mutli="false">          
          <mat-expansion-panel hideToggle="true" #panelCategory>
            <mat-expansion-panel-header>
              <mat-panel-title style="position:sticky; top:0px;" class="font-weight-bold">
                Categories <span  *ngIf="selectedCategories.length > 0" class="badge">{{selectedCategories.length}}</span>
              </mat-panel-title>
              <mat-icon >{{panelCategory.expanded? 'remove' : 'add'}}</mat-icon>
            </mat-expansion-panel-header>
            <div *ngFor="let option of sortByCategoryName(categoriesV2)" class="mb-3">    
              <!-- <div (click)="showSub(option.slug);"  style="cursor: pointer;" [ngClass]="{'text-primary font-weight-bold': option.show, 'd-flex justify-content-between':true}" >
                <span>{{option.category_name}}</span>
              </div>          -->
              <mat-accordion class="category-accordion">          
                <mat-expansion-panel [expanded]="categoryExpanded(option)" hideToggle="true">
                  <mat-expansion-panel-header  collapsedHeight="*" expandedHeight="*" (click)="expand(option)">
                    <mat-panel-title>
                      <mat-checkbox class="checkbox" (click)="$event.stopPropagation();" (ngModelChange)="sortByCategory(option,$event)" [(ngModel)]="option.show">                  
                      </mat-checkbox>
                      <span>{{option.category_name}}</span>
                    </mat-panel-title>
                    <mat-icon >{{option.expanded? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                  </mat-expansion-panel-header>
                  <div class="mt-2">
                    <div style="margin-left: 1em; cursor: pointer;" class="d-flex justify-content-between mb-2" *ngFor="let sub of sortByCategoryName(option.subcategories); index as i ">
                      <mat-checkbox class="checkbox" (ngModelChange)="sortBySubCategory(option,$event,i,sub)" [(ngModel)]="sub.show">     
                       <span>{{sub.category_name}}</span>             
                      </mat-checkbox>
                    </div>  
                  </div> 
                </mat-expansion-panel>
              </mat-accordion>
              <!-- -->
            </div>            
          </mat-expansion-panel>

          <mat-expansion-panel #panelParlon hideToggle="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="font-weight-bold">
                Parlons <span  *ngIf="selectedParlons.length > 0" class="badge">{{selectedParlons.length}}</span>
              </mat-panel-title>
              <mat-icon >{{panelParlon.expanded? 'remove' : 'add'}}</mat-icon>
            </mat-expansion-panel-header>
            <!-- <mat-icon matExpansionToggleIcon>arrow_forward_ios</mat-icon> -->
            <mat-form-field [floatLabel]="'never'" class="form-search-input w-100 tealborder mb-5">
              <input matInput placeholder="Search for Parlons" type="search" [(ngModel)]="parlonSearch" (keyup)="parlonFilter()"/>
              <span matSuffix><i style="color: #46b0a8" class="fal fa-search" ></i></span>
          </mat-form-field>
            <div *ngFor="let option of filteredParlonsOption | async; index as i" class="mb-3">
              <div class="d-flex   justify-content-between mb-1" >
                  <mat-checkbox class="checkbox" [(ngModel)]="option.completed"
                  [color]="option.color"
                  (ngModelChange)="sortByParlonV2()">                         
                  <span>{{option.highlighted}}</span>                
                </mat-checkbox>         
                <!-- <span class="badge" style="height: auto; align-self:center;" >{{option.count}}</span>     -->
              </div>
              
            </div>
            <!-- <p *ngIf="filteredParlonsOption.length < 1">No Results Found</p> -->
            <!-- <div class="d-flex justify-content-center mt-2">
              <button (click)="showMore = true;" class="border w-100" mat-raised-button *ngIf="!showMore && parlonsV2.length>9">Show more</button>
              <button (click)="showMore = false;" class="border w-100" mat-raised-button *ngIf="showMore && parlonsV2.length>9">Show less</button>
            </div> -->
            
            
          </mat-expansion-panel>

          <mat-expansion-panel #panelLocations  hideToggle="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="font-weight-bold">
                Locations <span  *ngIf="selectedLocations.length > 0" class="badge">{{selectedLocations.length}}</span>
              </mat-panel-title>
              <mat-icon >{{panelLocations.expanded? 'remove' : 'add'}}</mat-icon>
            </mat-expansion-panel-header>

            <div *ngFor="let option of facet_locations; index as i"  class="mb-3">
              <div class="d-flex   justify-content-between mb-1" >
                  <mat-checkbox class="checkbox" [(ngModel)]="option.completed"
                  [color]="option.color"
                  (ngModelChange)="sortByLocationV2()">                         
                  <span>{{option.highlighted}}</span>                
                </mat-checkbox>         
                <!-- <span class="badge" style="height: auto; align-self:center;" >{{option.count}}</span>     -->
              </div>
              
            </div>
            
            
          </mat-expansion-panel>
        </mat-accordion>
        

      </div>
    </div>

    
  </div>

  <!-- <button mat-stroked-button color="secondary" class="w-100 py-2 mb-4 " (click)="resetFilters()">
    RESET FILTERS
  </button> -->
</div>