import { Injectable } from '@angular/core';
declare const smartech:any;

@Injectable({
  providedIn: 'root'
})
export class NetcoreService {

  constructor() { }

  dispatch(eventName,payload){
    try {
      smartech('dispatch',eventName,payload);
    } catch(e){
      console.log(e)
    }
  }
  
  identify(data) {
    try {
    localStorage.setItem('netcoreId',JSON.stringify(data));
    smartech('contact', 2, data);
    smartech('identify', data['email']);
    } catch(e){
      console.log(e)
    }
  }

  clearIdentity() {
    try {
    smartech('identify', '');
    } catch(e){
      console.log(e)
    }
  }
}
