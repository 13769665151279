<div class="card card-perk-template">
	<!-- <div class="ratio ratio-16x9 mt-3 mt-sm-4 mb-3">
		<img src="{{resource+brandDetail.logo}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
	</div> -->
	<div class="card-body pos-relative p-0">
		<div class="ratio ratio-4x3 mt-3 mt-sm-4 mb-3">
			<img src="{{resource+brandDetail.logo}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
		</div>
	</div>
	
	<!-- <div class="card-body pos-relative px-2 px-sm-3 pb-2 pb-sm-3">
		<p class="smaller text-muted mb-2"><i class="fas fa-location-dot fa-fw me-2"></i>Manila</p>
		<p class="fw-medium mb-2"><a href="#" class="stretched-link link-static">Get 50% off of The 8K 1919 Grand Cafe Steak</a></p>
		<p class="small">50% off when you dine in the 1919 Grand Cafe and order the premium steak worth ₱8,000! Sulit na bestie!</p>
		<p class="smaller text-perk-validity mb-0">Valid until October 31, 2022</p>
	</div> -->
</div>