import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'spotify',
  templateUrl: './spotify.component.html',
  styleUrls: ['./spotify.component.scss']
})
export class SpotifyComponent implements OnInit {

  @Input() data: object;
  image = '';

  constructor(private DomSanitizer: DomSanitizer) { }

  ngOnInit() {  
  }
  

}
