import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../../../services/user-account/user-account.service';
import * as moment from 'moment';
import { ContentService } from '../../../../services/content/content.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-user-drawer-dashboard',
  templateUrl: './user-drawer-dashboard.component.html',
  styleUrls: ['./user-drawer-dashboard.component.scss']
})
export class UserDrawerDashboardComponent implements OnInit, OnDestroy {

  constructor(
    private userService: UserAccountService, 
    private router:Router,
    private ContentService:ContentService,
    ) { }

  parlonCandies:number=0;
  brandedCandies:number=0;
  first_name:string="";
  email:string="";

  $userSub:Subscription|undefined;
  $candiesSub:Subscription|undefined;
  ogContent:any = [];
  features: any = [];
  resource = environment.resource;
  user_data;

  greetMessage="";


  ngOnDestroy(){
    if(this.$userSub)
      this.$userSub.unsubscribe();

      if(this.$candiesSub)
      this.$candiesSub.unsubscribe();  
  }

  ngOnInit() {  
    this.$userSub = this.userService.getLoggedInUser.subscribe((data:any)=>{
      if(data){
        this.first_name = data.user.first_name;
        this.email = data.user.email;
        this.parlonCandies = data.bagSummary.candies.parlonCandies;
        this.brandedCandies = data.bagSummary.candies.allCandies - data.bagSummary.candies.parlonCandies;
      }      
    });
    this.ContentService.ogContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.ogContent = data;
      }
    });
    this.userService.getLoggedInUser.subscribe(data=>{
      if(data){ 
        this.user_data = data;
      }
    });
    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
    });
    this.greetings();
  }

  greetings() {
    this.userService.getTime.subscribe(data=>{
      this.greetMessage = data;
    })
    // const currentHour = parseInt(moment().format("HH"));
    // if (currentHour >= 0 && currentHour < 12){
    //     return "morning";
    // } else if (currentHour >= 12 && currentHour < 17){
    //     return "afternoon";
    // }   else if (currentHour >= 17 && currentHour < 24){
    //     return "evening";
    // }
  }

  logout(){
    this.userService.logout();
    this.router.navigate(['/']);
  }

}
