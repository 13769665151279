<div class="has-offset-bottom-nav app-root" *ngIf="contentLoaded == true">
  <!-- new ui elem -->
  <main-nav class="navbar-root"></main-nav>

  <!-- <app-new-search-nav-bar [ngClass]="updated_ui == false ? '' : 'd-none'"></app-new-search-nav-bar> -->

  <main id="bodyContent" [ngClass]="updated_ui ? 'bg-light-1' : ''" [@routerAnimations]="prepareRouteTransition(outlet)">
    <router-outlet (activate)="onActivate($event)" #outlet="outlet"></router-outlet>
  </main>


  <!-- <app-advisory></app-advisory> -->
  <!-- new ui elem -->
  <app-footer-nav></app-footer-nav>
  <app-footer-new></app-footer-new>
  <app-nav-drawer></app-nav-drawer>

  <!-- <app-og-floating-button></app-og-floating-button> -->
</div>