import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit, Input , Inject, NgZone, ElementRef} from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { GiftingService } from '../../../../services/gifting/gifting.service';
import { ContentService } from '../../../../services/content/content.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../../services/shared.service';
import Swal from 'sweetalert2';
import * as Rx from 'rxjs'
import { Title } from '@angular/platform-browser';
import { NetcoreService } from '../../../../services/netcore/netcore.service';

declare var Flickity: any;

declare var smartech: any;
@Component({
  selector: 'parlon-services',
  templateUrl: './parlon-services.component.html',
  styleUrls: ['./parlon-services.component.scss']
})
export class ParlonServicesComponent implements OnInit {

  constructor(
  @Inject(WINDOW) private window: Window, 
  private rest: RestService, 
  private GiftingService: GiftingService, 
  private route: ActivatedRoute, 
  private _shared: SharedService,
  private _title: Title,
  private ngZone: NgZone,
  private el: ElementRef,
  private ContentService: ContentService,
  private netCoreService: NetcoreService
  ) { }

  data: any[];
  features:any;
  parlon: any[];
  gifting: any;
  branch: any[];
  selectedDeals = [];
  isLoaded = false;
  membership_link = "";
  featuredDealsVisible: boolean;
  resourceLink = this.rest.resourceLink();
  
  isScrolledDealSection = false;

  isScrolledServiceSection = false;
  
  isScrolledMemberSection = false;
  
  isEnabledBooking = false;  

  merchantName = "";

  onScroll(type:string=''){

    if(!this.isScrolledDealSection && type == 'dealSection' && this.merchantName !=""){

      this.netCoreService.dispatch('nudge_deal',{
        'nudge_deal': this.merchantName
      })
      this.isScrolledDealSection = true;
    }

    if(!this.isScrolledServiceSection  && type == 'serviceSection' && this.merchantName !=""){

      this.netCoreService.dispatch('nudge_service',{
        'nudge_service': this.merchantName
      })
      this.isScrolledServiceSection = true;
    }

    if(!this.isScrolledMemberSection  && type == 'memberSection' && this.isEnabledBooking && this.merchantName !="" 
    && this.parlon['membership_banner_services'] != null && this.parlon['sells_membership'] == 'Yes') {
      
      this.netCoreService.dispatch('nudge_member',{
        'nudge_member': this.merchantName
      })      
      this.isScrolledMemberSection = true;
    }
   
    
  }

  ngOnInit() {
    
    this._shared.currentBranch.subscribe((branch) => {
      console.log(75,branch);
      this.data = branch['services'];
      this.merchantName = branch['branch_name'];

      this.branch = branch;
      this.isEnabledBooking = branch['has_booking'] == 'Yes'  ? true : false
    });
    this.GiftingService.getGiftingData().subscribe((data: {}) => {
      this.gifting = data;
    });
    this._shared.currentParlon.subscribe((parlon) => {
      this.parlon = parlon;
      const page = this;
      switch (parlon['membership_scope']) {
        case "Branch":
          this.membership_link = this.branch['slug'];
          break;
        default:
          this.membership_link = '';
          break;
      }
      if(this.branch['featured_deals'].length > 0 ) {
        this.branch['featured_deals'].forEach(e => {
          const deals = {
              id: e['id'],
              deal_price: e['deal_price'],
              description: e['description'],
              thumb: this.resourceLink + e['primary_photo'],
              name: e['name'],
              original_price: e['original_price'],
              slug: e['slug'],
              discount_type: e['discount_type'],
              discount_value: e['discount_value'],
              valid_from: e['valid_from'],
              valid_to: e['valid_to']
          };
          page.selectedDeals.push(deals);
        });
      } else {
        this.branch['active_deals'].forEach(e => {
          const deals = {
              id: e['id'],
              deal_price: e['deal_price'],
              description: e['description'],
              thumb: this.resourceLink + e['primary_photo'],
              name: e['name'],
              original_price: e['original_price'],
              slug: e['slug'],
              discount_type: e['discount_type'],
              discount_value: e['discount_value'],
              valid_from: e['valid_from'],
              valid_to: e['valid_to']
          };
          page.selectedDeals.push(deals);
        });
      }
    });
    // if(this.parlon['active_deal_count'] > 0 || this.parlon['sells_membership'] == 'Yes') {
    //   setTimeout(() => {
    //     const el = this.el.nativeElement;
    //     let elem = el.querySelector('.flickity-banner');
    //     let flkty = new Flickity(elem, {
    //       contain: true,
    //       autoPlay: 3500,
    //       cellAlign: 'left',
    //       pageDots: true,
    //       prevNextButtons: false
    //     });
  
    //     elem.addEventListener('pointerup', function() {
    //       flkty.playPlayer();
    //     });
    //   },500);
    // }
    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
    });
    this.getVisiblePageSections();
  }

  getVisiblePageSections() {
    this.rest.homepageSectionVisibility().subscribe(data => {
      this.ngZone.run(() => {
        const sections = data['data'];

        this.featuredDealsVisible = sections.find(
          ({ section }) => section === 'Featured Deals'
        ).visible === 0;
      });
    })
  }

  toggleClass() {
    const info = document.querySelector('.book-info');
    info.classList.toggle('active');
  }

}
