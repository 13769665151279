import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { DealsService } from '../../../../services/deals/deals.service';

declare var Flickity: any;

@Component({
  selector: 'featured-deals-slider',
  templateUrl: './featured-deals-slider.component.html',
  styleUrls: ['./featured-deals-slider.component.scss']
})
export class FeaturedDealsSliderComponent implements OnInit {

  @Input() data: object;
  @Input() index: object;
  @Input() isLoggedIn:boolean = false;
  @Input() userId:string ="";

  deals:any = [];
  isLoaded = false;
  resource = environment.imagekit;

  constructor(
    private el: ElementRef,
    private DealService: DealsService,) { }

  ngOnInit() {
    this.deals = this.data['deals'];
    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('#flickity-ft-deals-'+this.index);
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        autoPlay: true,
        freeScroll: true,
        freeScrollFriction: 0.075,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }

  getLocations(data) {
    let locations = [];
    data.forEach(e => {
      locations.push(e['location_name']);
    });
    return locations;
  }
}
