import { Component, OnInit, OnDestroy, ElementRef,Output, EventEmitter,Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { SearchService } from '../../services/search/search.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-new-search-nav-bar',
  templateUrl: './new-search-nav-bar.component.html',
  styleUrls: ['./new-search-nav-bar.component.scss']
})
export class NewSearchNavBarComponent implements OnInit,AfterViewInit{

  constructor(private router: Router,private route: ActivatedRoute, private rest: RestService, private _shared: SharedService, private el: ElementRef,
    private searchService:SearchService) { }

  @Output() locationEvent = new EventEmitter<any>();
  @Output() searchInputEvent = new EventEmitter<string>();
  @Input() isMobile:boolean = false;


  inputSearch: string = "";
  locationSelected: any = '';
  areas: any = [];
  toStr = JSON.stringify;
  myControl = new FormControl('');


  options: string[] = ['Eyelash extensions', 'Hair-color and treatment', 'Brazilian blowout','Eyelash extensions', 'Hair-color and treatment', 'Brazilian blowout'];
  // options: string[] = [];

  recentSearches:string[];
  popularSearches:any[];
  recentViewed:any;
  searchWord:string;

  location:string="";

  parlons:any[];
  resource = environment.resource;


  selectedOption:string =''
  filteredOptions: Observable<string[]>;


  /** control for the MatSelect filter keyword */
  public areaFilterCtrl: FormControl = new FormControl();

  /** list of areas filtered by search keyword */
  public filteredAreas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  debounceTimeoutId:any;
  isLoadingSearchInput:any;


  discountedPrice = (price:number, discount:number) =>{
    let d = price * (discount /100);

    return price - d;
  }


  ngAfterViewInit(): void {
    // alert(1);
    document.getElementById("my-search").focus();
  }


  changeModel(){
    // alert(1);
    if(this.inputSearch.length==0){
        this.parlons = [];
    }else{
      // console.log(typeof this.locationSelected);
      let loc:any;
      if(this.locationSelected.length!=''){
        loc = JSON.parse(this.locationSelected);
      }
      else
        loc = "";
      this.searchService.search(this.inputSearch, loc.location_name).subscribe((data)=>{
        this.parlons = data.results[0].hits;
        this.parlons.forEach((item)=>{
          item.matchedServices = item._highlightResult['services'].filter((service)=>{
            return service.matchLevel =='full';
          })
        })

      })
    }

    // this.searchInputEvent.emit(this.inputSearch);
  }

  changeModelMobile(){
    
    clearTimeout(this.debounceTimeoutId);
    // Set a new debounce timeout for 500 milliseconds (adjust the time as needed)
   this.debounceTimeoutId = setTimeout(() => {
    this.isLoadingSearchInput = true;
    // Call the search function after the debounce time has passed
    this.searchInputEvent.emit(this.inputSearch);
    }, 500); // Adjust the debounce time as needed (e.g., 500 milliseconds)

    
  }




  hello(){
    alert('click')
  }


  ngOnInit() {


    this.searchService.getPopularSearches().subscribe((data)=>{
      this.popularSearches = data.hits.splice(0,5);
    })


    this.recentSearches = this.searchService.getRecentSearches();
    // this.recentViewed = this.searchService.getRecentViewed();
    this.recentViewed = [];
    // this.recentSearches = this.recentSearches.splice(0,3);


    this.rest.getLocations().subscribe((data: {}) => {
      this.areas = data['data'];
      this.filteredAreas.next(this.areas);
    });
    this._shared.searchAreaValue.subscribe(area => {
      this.locationSelected = area;
    });

    this.areaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAreas();
      });

  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectOption(event){


    let find = this.parlons.find((item)=>{
      return item.parlon_slug == event.option.value;
    })


    if(find){
      this.router.navigate(['/', 'parlon',event.option.value]);
    }
    this.selectedOption = event.option.value
  }

  searchSubmit() {
    this._shared.changeSearchArea(this.locationSelected);
    let location = (this.locationSelected != "" ? JSON.parse(this.locationSelected) :  null );
    let slug = location != null ? location['slug'] : "all";
    if(this.inputSearch != '' || this.selectedOption != ''){
      this.router.navigate(['/search',slug,this.inputSearch || this.selectedOption]);
      this.searchService.setRecentSearches(this.recentSearches,this.inputSearch || this.selectedOption);
    }
    // else
    //   this.router.navigate(['/search',slug]);


  }

  onSelectValueChange(value){

    this.parlons =[];
    this._shared.changeSearchArea(this.locationSelected);
    this.locationEvent.emit(this.locationSelected);
  }

  searchfocusOut() {
    setTimeout(() => {
      let field = (document.getElementById('search-dialog-box') as HTMLElement);
      field.classList.remove('show');
      (document.getElementById('search-input-field') as HTMLInputElement).value = '';
    }, 500);
  }

  protected filterAreas() {
    if (!this.areas) {
      return;
    }
    // get the search keyword
    let search = this.areaFilterCtrl.value;
    if (!search) {
      this.filteredAreas.next(this.areas);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the areas
    this.filteredAreas.next(
      this.areas.filter(area => area.location_name.toLowerCase().indexOf(search) > -1)
    );
  }

}
