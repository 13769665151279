<section class="bg-light-1 py-6 py-md-8 booking-section" *ngIf="isLoaded == true">

  <div class="container">

    <div class="my-5">
      <h2 class="h2 fw-semibold mb-2">Welcome {{dealsBooking['user']['firstname']}}!</h2>
      <p>Select the date and time slot for your deal appointments. </p>
    </div>

    <ng-container *ngFor="let booking of data['data']; index as main_index">
      <div class="booking-card">
        <div class="row row-main-layout gx-xl-8 gy-4">

          <!-- Sidebar -->

          <aside class="col-12 col-lg-4">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 col-lg-12">
                <img src="{{resource + booking['parlon']['logo']}}?tr=w-320" alt="" loading="lazy"
                  class="img-fluid img-parlon-partner-logo mb-6 mb-md-0 mb-lg-8">
              </div>

              <div class="col-12 col-md-8 col-lg-12">
                <div class="text-center text-lg-start">
                  <p class="small mb-2 fw-semibold"><i>You are booking an appointment in</i> </p>
                  <p>
                    <span class="fs-4 fw-semibold">{{booking['parlon']['name']}}</span><br />
                    <span class="fs-6 fw-semibold text-secondary">{{booking['branch']['name']}}</span>
                  </p>
                </div>
              </div>
            </div>
            <hr class="my-5">
            <div class="sticky-left">
              <p>
                <span class=" fw-semibold text-uppercase">Note</span><br />
                <span class="fw-semibold">{{booking['parlon']['name']}} - {{booking['branch']['name']}} will get in
                  touch
                  with you <span class="fw-semibold text-secondary">to confirm</span> your booking request.</span>
              </p>
              <div class="card mt-5">
                <div class="card-body">
                  <p class="text-secondary fw-semibold">Deals</p>

                  <ol class="list-unstyled list-services">
                    <li *ngFor="let deal of booking['deals']; let i = index">{{deal['name']}}</li>
                  </ol>
                </div>
              </div>
            </div>
          </aside>

          <!-- Main content -->
          <main class="col-12 col-lg-8">
            <div class="card my-5">
              <div class="card-body px-lg-6 py-lg-5">
                <h2 class="h2 fw-semibold mb-4 text-secondary">When and what time?</h2>

                <div class="content-date-picker mb-5">
                  <p class="fs-5 fw-medium text-center mb-2" id="month-year">
                    {{booking['branch']['available_dates'][0] | date: 'MMMM y'}}</p>

                  <div class="row justify-content-center">
                    <div class="col-9 col-sm-10">

                      <div id="flickity-date-picker-{{main_index}}" class="row row-date-picker gx-4 flickity flickity-date-picker">

                        <!-- Arrows -->
                        <div class="flickity-arrow flickity-prev">
                          <button class="btn-flickity-prev">
                            <i class="prev-button fa-solid fa-circle-chevron-left fa-xl fa-fw"></i>
                          </button>
                        </div>

                        <div class="flickity-arrow flickity-next">
                          <button class="btn-flickity-next">
                            <i class="fa-solid fa-circle-chevron-right fa-xl fa-fw"></i>
                          </button>
                        </div>

                        <!-- Slide -->
                        <div class="slide" id="{{date}}"
                          *ngFor="let date of booking['branch']['available_dates']; let i = index">
                          <div class="content-date-input" (click)="selectDate(date,booking['branch']['id'],main_index)">
                            <input type="radio" id="date-{{main_index}}-{{date}}" name="opt_dates_{{main_index}}" class="date-input">
                            <label for="date-{{main_index}}-{{date}}" class="date-label">
                              <div class="card-date-box">
                                <p class="fw-medium text-uppercase mb-0">{{ date | date : "E" }}
                                </p>
                                <p class="fs-3 fw-semibold mb-0">{{ date | date : "d" }}</p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="content-time-picker mb-6" *ngIf="booking['timeslots'].length > 0">
                  <p class="fs-5 fw-medium text-center mb-2">Pick a time slot</p>
    
                  <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-4">
                    <div class="col" *ngFor="let time of booking['timeslots']; let i = index">
                      <div class="content-time-input" (click)="selectTime(time['date'],main_index)">
                        <input type="radio" id="time-{{main_index}}-{{ time['date'] | date : 'hh:mm a' }}"
                          name="opt_times_{{main_index}}" class="time-input">
                        <label for="time-{{main_index}}-{{ time['date'] | date : 'hh:mm a' }}" class="time-label">
                          <div class="card-time-box">
                            <p class="fs-6 fw-medium mb-0">{{ time['date'] | militaryToStandardTime}}</p>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Note to {{booking['parlon']['name']}}</mat-label>
                    <textarea matInput rows="3" placeholder="Note to {{booking['parlon']['name']}}" [(ngModel)]="selectedBookings[main_index]['notes']"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </ng-container>

    <!-- <div class="row">
      <div class="col-lg-8 col-12 offset-lg-2">
        <div class="mt-5">
          <div class="mb-5">
            <h6 class="text-muted m-0">First Name</h6>
            <label for="">{{dealsBooking['user']['firstname']}}</label>
          </div>
    
          <div class="mb-5">
            <h6 class="text-muted m-0">Last Name</h6>
            <label for="">{{dealsBooking['user']['lastname']}}</label>
          </div>
    
          <div class="mb-5">
            <h6 class="text-muted m-0">Email Address</h6>
            <label for="">{{dealsBooking['user']['email']}}</label>
          </div>
    
          <div class="mb-5">
            <h6 class="text-muted m-0">Contact Number</h6>
            <label for="">{{dealsBooking['user']['mobile_number']}}</label>
          </div>
    
        </div>
      </div>
    </div> -->

    <div class="mt-5 text-center">
      <mat-checkbox id="acceptTerms" color="primary">
        By clicking the Confirm Booking Request button, I confirm that I have read and I
        agree to Parlon's <a href="https://legal.parlon.ph/post/terms-of-use"
          target="_blank" style="display: inline-block; color:#46b0a9;"
          class="nav-link link-text fw-medium">Terms of Use</a> and Parlon's
        <a href="https://legal.parlon.ph/post/privacy-notice" target="_blank"
          style="display: inline-block; color:#46b0a9;"
          class="nav-link link-text fw-medium">Privacy Notice.</a>
      </mat-checkbox>

      <p class="text-danger">
        {{error_message}} 
      </p>

      <button [disabled]="!isFormValid || submit_loading == true" mat-flat-button color="secondary" class="py-2 my-4 d-flex justify-content-center align-items-center mx-auto" (click)="requestBooking()">
        <span>CONFIRM BOOKING REQUEST </span>
      </button>
    </div>


  </div>
</section>