<div class="service-select" [class.active]="isSelected">
  <span *ngIf="isSelected; else notSelected" >
      <i class="far fa-check"></i>
  </span>
  <ng-template #notSelected>
      <span>
          <i class="far fa-plus"></i>
      </span>
  </ng-template>
</div>
