import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { MaterialModule } from '../../material';
import { FormsModule } from '@angular/forms';
import { OgPerksComponent } from './og-perks.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CategoryComponent } from './components/category/category.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';
import { NoRedeemComponent } from './components/no-redeem/no-redeem.component';
import { RedeemedOfferComponent } from './components/redeemed-offer/redeemed-offer.component';
import { OgPerksRouteModule } from "./og-perks-routing.module";

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    MaterialModule,
    FormsModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    OgPerksRouteModule
  ],
  declarations: [
    OgPerksComponent,
    CategoryComponent,
    NoRedeemComponent,
    RedeemedOfferComponent
  ]
})
export class OgPerksModule { }
