<section class="flex-root">
	<div class="container main-section">
		<div class="row justify-content-center gy-8">
			<div class="col-9 col-md-6">
				<div class="ratio ratio-4x3 w-75 mx-auto mb-4">
					<img src="{{ resourceLink + parlonGlife['logo'] }}" alt="{{  parlonGlife['logo'] }}" class="img-fluid obj-fit-contain">
				</div>

				<div class="text-center">
					<h3 class="h3">{{  parlonGlife['business_name'] }}</h3>
					<span class="text-muted" *ngFor="let branch of parlonGlife['branches']">
						<span>• {{ branch['branch_name'] }} &nbsp;</span>
					</span>
				</div>
			</div>

			<div class="col-12 col-md-8" [formGroup]="branchForm">
				<h2 class="h2 text-center mb-4">Select Branch</h2>

				<div class="branch-select mb-3">
					<mat-form-field appearance="standard" [floatLabel]="'never'">
						<mat-select formControlName="selectedBranch" name="branch" required (selectionChange)="onChangeBranch($event.value)">
							<mat-option *ngFor="let branch of parlonGlife['branches']" [value]="branch" ng-selected>
								{{ branch['branch_name'] }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="row flex-branch-address gx-3 mb-6" *ngIf="selectedBranch == true">
					<div class="col-auto col-icon">
						<i class="fas fa-map-marker-alt fa-fw text-secondary"></i>
					</div>

					<div class="col">
						<p class="mb-0">{{ branchAddress }}</p>
					</div>
				</div>

				<div>
					<button mat-flat-button color="primary" class="text-uppercase w-50 d-block mx-auto p-3" [routerLink]="['/glife-services', branchSlug]">
						Let's Glow <i class="far fa-arrow-right fa-sm fa-fw ms-2"></i>
					</button>
				</div>

			</div>
		</div>
	</div>
	<div class="footer">
		Powered by <img src="/assets/images/logo-inline.png" width="100" class="px-3">
	</div>
</section>
