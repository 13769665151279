<div class="">
  <div *ngFor="let googleReview of googleReviews; let i = index;" style="margin: 2rem 0;">
    <!-- {{googleReview|json}} -->
    <app-google-review-card [data]="googleReview" ></app-google-review-card>

    <hr *ngIf="i+1 != googleReviews.length" style="margin-top: 2rem; color: gray;" class="shadowed-hr ">

  </div>
 

</div>