<section class="pb-7">

	
	<div class="container-fluid px-0">
		<div class="row justify-content-center m-0">
			<div class="col-12">
        <h1 class="h1 fw-semibold mb-4 text-center">Create account</h1>
				<img src="assets/images/createaccountbanner.png" alt="" class="img-fluid w-100 mb-6">
			</div>
		</div>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12">
					
					<div class="text-center">
	
	
						<p>Create your Parlon account and enjoy beautiful surprises. Get instant <span class="fw-bold">PHP 250 Code</span> when you register + Parlon candies (revealing this soon!) 🍬
						You're welcome!</p>
					</div>
	
					<form [formGroup]="regForm" (ngSubmit)="onSubmit()">
						<mat-form-field appearance="fill" [ngClass]="email.invalid &&  (email.touched || email.errors.existingEmail) ?  'border-red' : '' ">
							<mat-label>Email address</mat-label>
							<input matInput placeholder="Email address" id="email" formControlName="email" >
							
						</mat-form-field>
	
						<!-- <div class="errors" *ngIf="email.invalid && (email.dirty || email.touched)" >					
							<mat-error *ngIf="email.errors.required" class="text-danger">
								Email is required.
							</mat-error>
							<mat-error *ngIf="email.errors.email && email.touched">
								Must be a valid email format.
							</mat-error>
							<mat-error *ngIf="email.errors?.existingEmail">
								The email has already been taken.
							</mat-error>						
						</div> -->
						
						<mat-form-field appearance="fill" [ngClass]="password.invalid && password.touched?  'border-red' : '' ">
							<mat-label>Password</mat-label>
							<input id="password" matInput placeholder="Password" formControlName="password" [type]="hidePasswordIcon ? 'password' : 'text'"  >
													
	
							<button  (click)="hidePasswordIcon = !hidePasswordIcon"  type="button" mat-icon-button matSuffix>
								<mat-icon>{{hidePasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
							</button>											
	
						</mat-form-field>
	
						<!-- <div class="errors" *ngIf="password.invalid && password.touched" >					
							<mat-error *ngIf="password.errors.required" class="text-danger">
								password is required.
							</mat-error>
							<mat-error *ngIf="password.errors.minlength" class="text-danger">
								Password should be longer than 7 characters
							</mat-error>					
						</div> -->
	
						<mat-form-field appearance="fill" [ngClass]="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched) ?  'border-red' : '' ">
							<mat-label>Confirm password</mat-label>
							<input id="confirm_password" matInput placeholder="Confirm password" [type]="hideConfirmIcon ? 'password' : 'text'" formControlName="confirm_password" >
							<button (click)="hideConfirmIcon = !hideConfirmIcon" type="button" mat-icon-button matSuffix>
								<mat-icon>{{hideConfirmIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
							</button>																
						</mat-form-field>
						
						<!-- <div *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)"  class="invalid-feedback errors">
							<mat-error class="text-danger" *ngIf="confirm_password.errors.required">Confirm Password is required</mat-error>
							<mat-error class="text-danger" *ngIf="confirm_password.errors.mustMatch">Passwords must match</mat-error>
						</div> -->
						
						<div formGroupName="personal_info">
							<p class="fs-6">Personal Information</p>
							<mat-form-field appearance="fill" [ngClass]="p.get('first_name').invalid &&  p.get('first_name').touched ?  'border-red' : '' ">
								<mat-label>First name</mat-label>
								<input matInput placeholder="First name" formControlName="first_name" >
								
							</mat-form-field>
							<!-- <div *ngIf="p.get('first_name').invalid &&  p.get('first_name').touched"  class="invalid-feedbac errors">
								<mat-error class="text-danger" *ngIf="p.get('first_name').errors.required">First Name is required</mat-error>
								<mat-error class="text-danger" *ngIf="p.get('first_name').errors.minlength">Minimum of 3 characters</mat-error>
								<mat-error class="text-danger" *ngIf="p.get('first_name').errors.maxlength">Maximum of 40 characters</mat-error>								
							</div> -->
	
							<mat-form-field appearance="fill" [ngClass]="p.get('last_name').invalid &&  p.get('last_name').touched ?  'border-red' : '' ">
								<mat-label>Last name</mat-label>
								<input matInput placeholder="Last name" formControlName="last_name" >
								
							</mat-form-field>
							
							<!-- <div *ngIf="p.get('last_name').invalid &&  p.get('last_name').touched"  class="invalid-feedback errors">
								<mat-error class="text-danger" *ngIf="p.get('last_name').errors.required">Last Name is required</mat-error>	
								<mat-error class="text-danger" *ngIf="p.get('last_name').errors.minlength">Minimum of 2 characters</mat-error>
								<mat-error class="text-danger" *ngIf="p.get('last_name').errors.maxlength">Maximum of 40 characters</mat-error>							
							</div> -->
	
							<mat-form-field appearance="fill" [ngClass]="p.get('birthday').invalid && (p.get('birthday').dirty || p.get('birthday').touched) ?  'border-red' : '' " >
								<mat-label>Birthday</mat-label>
								<input matInput [matDatepicker]="picker1" placeholder="Birthday" formControlName="birthday">
								<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
								<mat-datepicker #picker1></mat-datepicker>																					
							</mat-form-field>
	
							<div *ngIf="p.get('birthday').invalid && (p.get('birthday').dirty || p.get('birthday').touched)"  class="invalid-feedback errors">
								<mat-error class="text-danger" *ngIf="p.get('birthday').errors.invalidDate">You should be 18 years old and above to create a Parlon account.</mat-error>
								<!-- <mat-error class="text-danger" *ngIf="p.get('birthday').errors.invalidDate">You cannot use future date</mat-error>									 -->
								<!-- <mat-error class="text-danger" *ngIf="!p.get('birthday').errors.invalidDate">Invalid date format</mat-error>								 -->
							</div>
						</div>
						
						<div formGroupName="contact_info">
							<p class="fs-6">Contact Information</p>
	
							<mat-form-field appearance="fill" [ngClass]="c.get('mobile_number').invalid && c.get('mobile_number').touched ?  'border-red' : '' " >
								<mat-label>Mobile number</mat-label>
								<input matInput placeholder="Mobile number" formControlName="mobile_number">
								
							</mat-form-field>
							
							<!-- <div *ngIf="c.get('mobile_number').invalid && c.get('mobile_number').touched"  class="invalid-feedback errors">
								<mat-error class="text-danger" *ngIf="c.get('mobile_number').errors.invalidMobileNumber">Must be a valid mobile number</mat-error>	
								<mat-error class="text-danger" *ngIf="c.get('mobile_number').errors.required">Mobile number is required</mat-error>
								<mat-error class="text-danger" *ngIf="c.get('mobile_number').errors.existingMobileNumber">The contact number has already been taken.</mat-error>								
							</div> -->
	
							<!-- <mat-form-field appearance="fill">
								<mat-label>Instagram (optional)</mat-label>
								<input matInput placeholder="Instagram (optional)" formControlName="instagram">
							</mat-form-field> -->
	
						</div>
						
	
						<div class="mb-5">
							<mat-checkbox  color="primary" formControlName="user_agree"> 
								By clicking 'Create Account', I confirm that I have read and agree to <a href="https://legal.parlon.ph/post/terms-of-use" target="_blank" style="display: inline-block; color:#46b0a9;" class="nav-link link-text fw-medium"> Parlon Terms of Use </a> and be bound by
								<a href="https://legal.parlon.ph/post/privacy-notice" style="display: inline-block; color:#46b0a9;" class="nav-link link-text fw-medium" target="_blank">Parlon Privacy Notice.</a> 
							</mat-checkbox>
						</div>
	
						<button [disabled]="!regForm.valid || submittedForm" mat-flat-button color="secondary" class="btn-submit mb-5 w-100" type="submit">
							Create Account
						</button>
					</form>
				</div>
	
			</div>
		</div>
	</div>
</section>