import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BeautyBashCheckoutComponent } from './beauty-bash-checkout.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { NgxCcModule } from 'ngx-cc';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxCcModule,
    NgxSmartModalModule,
    RouterModule
  ],
  declarations: [BeautyBashCheckoutComponent,CreditCardFormComponent]
})
export class BeautyBashCheckoutModule { }
