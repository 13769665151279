import { Component, OnInit, Input} from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'perk-card-template',
  templateUrl: './perk-card-template.component.html',
  styleUrls: ['./perk-card-template.component.scss']
})
export class PerkCardTemplateComponent implements OnInit {

  constructor() { }
  resource = environment.resource;
  @Input() brandDetail:any;

  ngOnInit() {
  }

}
