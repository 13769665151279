<div class="drawer">
	<div id="nav-drawer" [ngClass]="{ 'isAnnouncementBarExisting': isAnnouncementBarExisting, 'isAnnouncementBarNotExisting': !isAnnouncementBarExisting, 'offcanvas offcanvas-start offcanvas-drawer bg-light-1': true }"   tabindex="-1">
		<div class="offcanvas-body p-0">
			<div style="padding-bottom: 1em;">
				<ul class="nav nav-offcanvas flex-column my-n2">
					<div class="d-flex justify-content-between align-items-center">
						<li class="nav-item" *ngIf="features['deals_&_beauty_bag'] == true">
							<a [routerLink]="['/all-parlon-deals']" class="nav-link  link-text text-secondary"><img src="https://www.parlon.ph/assets/images/shop-deals-icon.svg" class="me-3" width="27">Shop All Deals</a>
						</li>
						<li>
							<a class="text-dark" data-bs-toggle="offcanvas" data-bs-target="#nav-drawer" aria-controls="nav-drawer"><i class="fa-sharp fa-solid fa-xmark fa-2xl me-2"></i></a>
						</li>
					</div>
					
	
					<!-- <li class="nav-item">
						<a href="#" class="nav-link  link-text"><img src="assets/images/og-icon.svg" class="me-3" width="27">What's OG Club?</a>
					</li> -->
	
					<li class="nav-item" *ngIf="features['deal_gifting'] == true">
						<a [routerLink]="['/gifting']" class="nav-link  link-text"><i class="fa-solid fa-gift me-3" width="27"></i>Gifting</a>
					</li>

					<li class="nav-item" *ngIf="features['beauty_bash_in_nav'] == true">
						<a [routerLink]="['/beauty-bash/deals']" class="nav-link  link-text"><img src="{{resource}}BeautyBash/percent-bash.png" width="14" class="ms-2 me-4">Beauty Bash</a>
					</li>
				</ul>
	
				<hr *ngIf="features['deals_&_beauty_bag'] == true">

				<ul id="nav-offcanvas-deal-categories" class="nav nav-offcanvas nav-offcanvas-deal-categories flex-column" *ngIf="contentLoaded == true && features['deals_&_beauty_bag'] == true">
					<li class="nav-item" *ngFor="let category of contentData['category_tree']">
						<a href="#collapse-nav-offcanvas-{{category['slug']}}" class="nav-link link-text" role="button"
							data-bs-toggle="collapse" aria-expanded="false">
							<div class="row flex-offcanvas-link-collapse gx-4">
								<div class="col-auto col-img">
									<div class="ratio ratio-1x1">
										<img src="{{resource + category['logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
									</div>
								</div>

								<div class="col col-content">
									<p class="fw-semibold mb-0">{{category['category_name']}}
										<span class="me-2"></span>
										<span class="ic-dropdown-caret"><i class="fa-solid fa-caret-down fa-sm fa-fw"></i></span>
									</p>
								</div>
							</div>
						</a>

						<div id="collapse-nav-offcanvas-{{category['slug']}}" class="collapse" data-bs-parent="#nav-offcanvas-deal-categories">
							<div class="pb-3">
								<ul class="nav nav-offcanvas-deal-categories-sub flex-column">
									<li class="nav-item">
										<a (click)="navigateCategory(category['slug'])"  class="nav-link link-text">View all {{category['category_name']}} deals</a>
									</li>
	
									<li class="nav-item" *ngFor="let subcat of category['subcategories']">
										<a (click)="navigateSubCategory(category['slug'],subcat['slug'])" class="nav-link link-text">{{subcat['category_name']}}</a>
									</li>
								</ul>
							</div>
						</div>
					</li>

				</ul>

				<!-- <ul class="nav nav-offcanvas nav-offcanvas-deal-categories flex-column mt-n2" *ngIf="contentLoaded == true && features['deals_&_beauty_bag'] == true">
					<li class="nav-item" *ngFor="let category of contentData['deal_categories']">
						<a [routerLink]="['/all-parlon-deals']" fragment="{{ category['slug'] }}" class="nav-link  link-text">
							<div class="d-flex flex-offcanvas-deal-category gap-4">
								<div class="ratio ratio-1x1 icon">
									<img src="{{resource + category['logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
								</div>
	
								<span>{{category['category_name']}}</span>
							</div>
						</a>
					</li>
				</ul> -->
	
				<hr *ngIf="features['deals_&_beauty_bag'] == true || features['deal_gifting'] == true">
	
				<ul class="nav nav-offcanvas flex-column my-n2">
					<li class="nav-item">
						<a [routerLink]="['/parlons']" class="nav-link link-text"><i class="fak fa-parlon fa-lg fa-fw me-3"></i>See all Parlons</a>
					</li>
	
					<li class="nav-item">
						<a [routerLink]="['/booking']" class="nav-link link-text"><i class="fak fa-booking fa-lg fa-fw me-3"></i>Book Online</a>
					</li>
	
					<li class="nav-item">
						<a href="https://glowgetter.parlon.ph/" target="_blank" class="nav-link  link-text"><i class="fak fa-glowgetter fa-lg fa-fw me-3"></i>GlowGetter Magazine</a>
					</li>

					<!-- <li class="nav-item" style="padding: 8px 0;" *ngIf="features['country_switch']">
						<mat-form-field  class=" country-nav d-flex align-items-center"  >       
							
							<div style="display: inline-block;" *ngIf="selectedCountry === 'Philippines'">
								<img class="img-country-selected me-4"    src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  > 
								<span class="link-text" style="font-weight: 600;">
								PH</span>
								
							</div>         
							<div style="display: inline-block;"  *ngIf="selectedCountry === 'Singapore'" > 
								<img class="img-country-selected me-4" src="https://ik.imagekit.io/parlon/flags/flag-sg.png"  >
								
								<span class="link-text" style="font-weight: 600;">SG</span>
								
							</div>
							
							
							<mat-select disableOptionCentering panelClass="myPanelClass" (selectionChange)="selectCountry()" [(value)]="selectedCountry" #select>
							  <mat-option *ngIf="selectedCountry=='Singapore' " class="text-center d-flex align-items-center" value="Philippines">
							  
								  <img class=" img-country"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  alt="">
							  
								<span class="ms-2" style="font-weight:bold">PH</span>
							  </mat-option>
							  <mat-option *ngIf="selectedCountry=='Philippines' " class="text-center" value="Singapore">
								<img class=" img-country"   src="https://ik.imagekit.io/parlon/flags/flag-sg.png" alt=""> <span class="ms-2" style="font-weight:bold">SG</span>
							  </mat-option>                  
							</mat-select>                            
						  </mat-form-field>  
					</li> -->
				</ul>
	
			</div>

			<hr>
			
			<div class="bg-light-1">
		
				<!-- <ul class="nav nav-offcanvas flex-column">
					<li class="nav-item">
						<a href="#" class="nav-link link-text">About us</a>
					</li>
		
					<li class="nav-item">
						<a href="#" class="nav-link link-text">Collabs and Partnerships</a>
					</li>
		
					<li class="nav-item">
						<a href="#" class="nav-link link-text">FAQs</a>
					</li>
		
					<li class="nav-item">
						<a href="#" class="nav-link link-text">Careers</a>
					</li>
		
					<li class="nav-item">
						<a href="#" class="nav-link link-text">Contact us</a>
					</li>
				</ul>
		
				<hr> -->
		
				<ul class="nav nav-offcanvas flex-column">
					<li class="nav-item">
						<a href="https://legal.parlon.ph/post/privacy-notice" class="nav-link link-text">Privacy Notice</a>
					</li>
		
					<li class="nav-item">
						<a href="https://legal.parlon.ph/post/terms-of-use" class="nav-link link-text">Terms of Use</a>
					</li>
		
					<li class="nav-item">
						<a href="https://legal.parlon.ph/post/merchant-partner-terms-and-conditions" class="nav-link link-text">Merchant Terms and Conditions</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>