<div class="d-grid-candies">
  
  <div class="d-flex align-items-center">
    <mat-icon class="text-primary">add_circle_outline</mat-icon>
    <div  class="view-all-candies-container" >  
      <div class="candy-container m-auto" >
        <div class="m-auto d-flex justify-content-center align-items-center circle-candy">
          <img height="22.64" width="41.47" src="https://parlon.s3.ap-southeast-1.amazonaws.com/Candies/candy-pink.svg" alt="">
        </div>  
        <div class="circle-parlon d-flex justify-content-center align-items-center" style="position: absolute; top:55px; left:55px;">
          <img height="24.61" width="24.27" src="{{resource + brandedCandy['logo']}}" alt="">
        </div>
      </div>
      <div>
        <div class="candy-number-view-all-candies"   >
          <span class="fs-4" style="font-weight: bold;">{{brandedCandy['candies'] | number}}</span>
        </div>
        <div class="parlon-text-view-all-candies">
          <span class="" style="justify-self: center; color: #525252; font-weight: 500">{{brandedCandy['name']}}</span>
        </div>
      </div>  
    </div>
  </div>

        
  
  <div>
      <div class=" use-points-v2  align-self-center" *ngIf="edit == true">
        <input matInput type="number" class="border p-3 rounded " [(ngModel)]="usedCandy" placeholder="Amount" >
        <button type="submit"  mat-flat-button color="accent" class="ms-2 rounded" [disabled]="usedCandy < 0 || usedCandy > brandedCandy['candies'] || usedCandy > limit" (click)="apply()">APPLY</button>
      </div>
      <div class=" use-points-v2  align-self-center" *ngIf="edit == false">
        <p class="fw-semibold mb-0 mt-4">{{appliedCandies | number}} Parlon candies will be used</p> <br>
        <a class="text-secondary fw-semibold" style="cursor: pointer; " (click)="edit=true"> <i class="far fa-edit me-3"></i> EDIT AMOUNT</a>
      </div>
  </div>
</div>
