import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: 'service-category-check',
  templateUrl: './service-category-check.component.html',
  styleUrls: ['./service-category-check.component.scss'],
})
export class ServiceCategoryCheckComponent implements OnChanges {
  @Input() selectedServices = [];
  @Input() serviceId: number;
  @Input() headerId: number = undefined;

  isSelected = false;

  ngOnChanges() {
    this.isSelected = !!this.selectedServices.find(
    service =>
      service.service_id === this.serviceId
      && (this.headerId === undefined || service.header_id === this.headerId),
    );
  }
}
