import { Component, Input, OnInit } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  resourceLink = this.rest.resourceLink();
  isLoaded = false; 

  @Input() photos = [];

  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
  }

  config: SwiperOptions = {
    autoplay: {
      delay: 3000,
    },
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true 
    },
    navigation: {
      // nextEl: '.swiper-button-next',
      // prevEl: '.swiper-button-prev'
    },
  };

}
