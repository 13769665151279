import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  constructor() { }
  private showForgotPassword = new BehaviorSubject<any>(false);
  isShowForgotPassword = this.showForgotPassword.asObservable();

  setForgotPassword(value:boolean){
    this.showForgotPassword.next(value);
  }


}
