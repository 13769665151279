import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as Rx from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SharedService } from '../../../../services/shared.service';

@Component({
  selector: 'branch-location-list',
  templateUrl: './branch-location-list.component.html',
  styleUrls: ['./branch-location-list.component.scss']
})

export class BranchLocationListComponent implements OnInit, OnDestroy {


  /** control for the selected branch for option groups */
  public selectedBranchCtrl: FormControl = new FormControl();

  public branchFilterCtrl: FormControl = new FormControl();
  

  /** list of areas filtered by search keyword */
  public filteredBranches: Rx.ReplaySubject<{}[]> = new Rx.ReplaySubject<{}[]>(1);

  protected _onDestroy = new Rx.Subject<void>();

  constructor(
    private _shared: SharedService,
    private router: Router
  ) {}


  areas: any = [];
  parlon: any = [];
  branch: any = [];
  branchByAreas = [];
  selected;

  ngOnInit() {
    this._shared.currentBranch.subscribe((branch ) => {
      this.branch = branch;
    });
    this._shared.selectedAreaID.subscribe(id => this.selected = id);

    Rx.combineLatest([
      this._shared.currentParlon.pipe(map(parlon => parlon as any)),
      this._shared.currentAreas,
    ]).subscribe(([parlon, areas]) => {
      this.areas = areas.sort((a, b) => a.location_name.localeCompare(b.location_name));;
      // console.log('areas', this.areas)
      this.parlon = parlon;
      this.branchByAreas = this.groupBranchByAreas(areas, parlon.branches);
      // sort alphabetically
      this.branchByAreas = this.branchByAreas.sort((a, b) => a.location_name.localeCompare(b.location_name));
      // console.log('branchByAreas', this.branchByAreas)

      this.filteredBranches.next(this.branchByAreas);
    });

    // listen for search field value changes
    this.branchFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAreas();
    });
  }

  
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange(event, value){
    // console.log(value)
    this._shared.changeSelectedAreaID(value);
      this.parlon.branches.forEach(e => {
        if(e.id === value) {
          this.router.navigate(['/parlon/',this.parlon.slug,e.slug]);
        }
      });
  }

  protected filterAreas() {
    // get the search keyword
    let search = this.branchFilterCtrl.value;

    if (!this.parlon.branches) {
      return;
    }
    
    if (!search) {
      this.filteredBranches.next(this.branchByAreas);
      return;
    } else {
      search = search.toLowerCase().trim();
    }

    // filter the areas and branches
    this.filteredBranches.next(
      this.branchByAreas
        .map(area => {
          const showBranchesGroup = area.location_name.toLowerCase().indexOf(search) > -1;
          let filteredBranches = area.branches;

          if (!showBranchesGroup) {
            filteredBranches = filteredBranches.filter(
              branch => branch.branch_name.toLowerCase().indexOf(search) > -1,
            );
          }

          return {
            ...area,
            branches: filteredBranches,
          };
        })
        .filter(area => !!area.branches.length),
    );
  }

  private groupBranchByAreas(areas, branches) {
    return areas.map(area => ({
      ...area,
      branches: branches.filter(branch => branch.location.id == area.id),
    }));
  }
}
