import { Component, OnDestroy, OnInit } from '@angular/core';
import { DealsService } from '../../services/deals/deals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { NetcoreService } from '../../services/netcore/netcore.service'
import { AlgoliaInsightService } from '../../services/algolia-insight/algolia-insight.service';

declare let fbq:Function;
declare var gtag: any;

const ASSETS_CDN = environment.imagekit;

@Component({
  selector: 'app-beauty-bag-deals',
  templateUrl: './beauty-bag-deals.component.html',
  styleUrls: ['./beauty-bag-deals.component.scss']
})

export class BeautyBagDealsComponent implements OnInit, OnDestroy {

  constructor(
    private DealsService: DealsService,
    private router: Router,
    private route: ActivatedRoute,
    private _meta: Meta,
    private _title: Title,
    private userService:UserAccountService,
    private algoliaInsightService:AlgoliaInsightService,
    private NetcoreService:NetcoreService,
  ) { }

  isLoaded = false;
  deal = [];
  userSubscription:Subscription|undefined;
  
  isLoggedIn:boolean = false;
  userID;
  insightType;
  ngOnDestroy(){
    if(this.userSubscription)
     this.userSubscription.unsubscribe();
  }

  clickedObjectIDs(){
    // let {objectID} = this.data;
    let indexName = environment.deals;
    
    this.route.queryParams.subscribe(params =>{
      const objectID = params['objectID'];
      const searchInput = params['searchInput'];
      const queryID = params['queryID'];
      const position = parseInt(params['position']) ;
    })
  }





  ngOnInit() {


    this.userSubscription= this.userService.getLoggedInUser.subscribe((data:any)=>{
      if(data){        
        if(data.data){
          this.isLoggedIn = true;
          this.userID = data.user.id;            
          this.clickedObjectIDs();          
        }               
      }
    });


    let page = this;
    page.isLoaded = false;
    let slug = this.route.snapshot.paramMap.get("slug");
    this.DealsService.getDeal(slug).subscribe((data: {}) => {
      if(data['success'] == true) {
        page.isLoaded = true;
        page.deal = data['data'];
        page.DealsService.changeActiveDeal(page.deal);
        page.isLoaded = true;
        console.log(page.deal);
        gtag('event', 'page_view', {
          'page_path': window.location.pathname,
          'deal_name': page.deal['deal']['name'],
          'parlon_name': page.deal['parlon']['name'],
          'page_type': 'Deal'
        });
        fbq('track', 'ViewContent', { 
          value: page.deal['deal']['price_current'],
          currency: environment.currency,
          content_name: page.deal['deal']['name'],
          content_type: 'product', // constant
          content_ids: page.deal['deal']['deal_number'] // Deal ID
        });
        this.NetcoreService.dispatch('view deal', {
          "dealid": page.deal['deal']['id'],
          "deal": page.deal['deal']['name'],
          "dealcategory": page.deal['deal']['category'],
          "dealprice": parseInt(page.deal['deal']['price_current']),
          "dealsubcategory": page.deal['deal']['subcategories'].toString(),
          "merchant": page.deal['parlon']['name']
        });
        this._meta.updateTag({ name: 'description', content: page.deal['deal'].description })
        this._meta.updateTag({ property: 'og:title', content: page.deal['deal'].name })
        this._meta.updateTag({ property: 'og:description', content: page.deal['deal'].description })
        this._meta.updateTag({ property: 'og:image', content: `${ASSETS_CDN}${page.deal['deal'].images[0]}?tr=w-1200,h-630` })
        this._title.setTitle(page.deal['deal'].name);

      }
      
    });
  }

}
