<!-- 
	NOTE:
	- Add `active` class to `<button mat-button>` when the page currently being displayed is one of the links listed on this bottom navigation.
-->

<nav class="footer-nav" *ngIf="selectedRoute!='mobile' && visible && showNav " >
	<div class="d-flex justify-content-center">
		<div class="flex-grow-1">
			<button mat-button [ngClass]="checkActive('/') ? 'active' : ''" [routerLink]="['/']">
				<div>
					<i class="fas fa-house fa-lg fa-fw"></i>
					<span>Home</span>
				</div>
			</button>
		</div>

		<div class="flex-grow-1">
			<button mat-button [ngClass]="checkActive('/parlons') ? 'active' : ''" [routerLink]="['/parlons']">
				<div>
					<i class="fak fa-parlon fa-lg fa-fw"></i>
					<span>Parlons</span>
				</div>
			</button>
		</div>

		<div class="flex-grow-1" *ngIf="features['deals_&_beauty_bag'] == true">
			<button [ngClass]="checkActive('/all-parlon-deals') ? 'active' : ''" (click)="goToDeals()" mat-button class="btn-footer-nav-circle">
				<div>
					<i class="fak fa-deals fa-2x fa-fw"></i>
				</div>
			</button>

			<div class="btn-label mt-1">
				<span style="font-size: 1.25em;">Deals</span>
			</div>
		</div>

		<div class="flex-grow-1">
			<button mat-button [ngClass]="checkActive('/booking') ? 'active' : ''" [routerLink]="['/booking']">
				<div>
					<i class="fak fa-booking fa-lg fa-fw"></i>
					<span>Booking</span>
				</div>
			</button>
		</div>

		<div class="flex-grow-1" *ngIf="features['deal_gifting'] == true">
			<button mat-button  [ngClass]="checkActive('/gifting') ? 'active' : ''" [routerLink]="['/gifting']">
				<div>
					<i class="fa-sharp fa-solid fa-gift"></i>
					<span>Gifting</span>
				</div>
			</button>
		</div>
	</div>
</nav>