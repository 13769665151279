<nav class="navbar navbar-expand bg-white py-3" aria-hidden="true">
  <div class="container desktop d-flex justify-content-between overflow-hidden">
    <div class="d-flex" style="max-width: 75%;">
      <div class="scrollable mx-3">
        <div class="upper">
          <a routerLink="/">
            <img src="assets/images/logo-inline.svg" alt="" class="img-fluid img-navbar-logo">
          </a>
        </div>
        <div class="lower">
          <a routerLink="/" >
            <img src="assets/images/logo-icon.png" width="48">
          </a>
          <div class="mx-2">
            <h6 class="h6 fw-semibold clamp-1 mb-1" style="-webkit-box-orient: vertical;">{{deal.deal?.name}}</h6>
            <div class="d-flex align-items-center">
              <img width="30" src="{{resource + deal.parlon?.logo}}">
              <p class="text-muted fw-medium clamp-1 mb-0 mx-2">{{deal.parlon?.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="links">
      <ul class="nav navbar-nav nav-desktop justify-content-end">

        <!-- <li class="nav-item">
          <a href="#nav-user-account" class="nav-link link-primary" data-bs-toggle="offcanvas" role="button"
            aria-controls="nav-user-account">
            <i class="fak fa-users fa-lg fa-fw"></i>
          </a>
        </li> -->

        <li class="nav-item">
          <a [routerLink]="['/beauty-bag-checkout']" class="nav-link link-primary nav-cart">
            <i class="fak fa-beauty-bag fa-lg fa-fw"></i>
            <span class="counter" *ngIf="cartCount > 0">{{cartCount}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>