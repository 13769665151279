<form class="cc-form" [formGroup]="paymentFormGroup">
  <mat-form-field class="ngx-cc-form-field w-100" appearance="fill">
    <mat-label>Card Number</mat-label>
    <ngx-cc name="cardNumber" placeholder="Card Number" styleClass="card" formControlName="creditCard"></ngx-cc>
    <mat-error *ngIf="f.creditCard?.invalid">Card number is not valid</mat-error>
  </mat-form-field>

  <div class="cc-date-cvv-container">
    <div class="row">
      <div class="col-sm-4 col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Month</mat-label>
          <mat-select formControlName="ccMonth">
            <mat-option *ngFor="let month of months" [value]="month" >{{month}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-8 col-md-5">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Year</mat-label>
          <mat-select formControlName="ccYear">
            <mat-option *ngFor="let year of years" [value]="year" >{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field class="ngx-cc-form-field w-100" appearance="fill">
      <mat-label>Card Code (CVC)</mat-label>
      <ngx-cc-cvv formControlName="creditCardCvv" required></ngx-cc-cvv>
      <mat-error *ngIf="f.creditCardCvv?.invalid">Security code is not valid</mat-error>
    </mat-form-field>
  </div>
</form>