import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'parlon-card-template',
  templateUrl: './parlon-card-template.component.html',
  styleUrls: ['./parlon-card-template.component.scss']
})
export class ParlonCardTemplateComponent implements OnInit {

  @Input() data: any = [];
  resource = environment.resource;

  constructor() { }

  ngOnInit() {
  }

  getSalonImage(data) {
    let src = '/assets/images/parlon_og_image_2022.png';
    if(data['first_parlon_photo'] == null) {
      if(data['first_branch']['first_photo'] != null )
        src = this.resource + data['first_branch']['first_photo']['image'];
    } else {
      src = this.resource + data['first_parlon_photo']['image'];
    }
    return src;
  }

}
