<!-- <div class="promos-item" *ngFor="let promo of branch['promos']; let i=index">
    <img src="{{resourceLink + promo.thumbnail}}" (click)="open(i)" class="thumbnail">
    <div class="details">
      <p class="promo-title">{{promo.title}}</p>
      <p class="branch">{{parlon.business_name}} - {{branch['branch_name']}}</p>
      <p class="date">Until: {{promo.end_date | date:'MMMM d, y'}} </p>
      <div [innerHTML]="promo['description']" class="promo-description"></div>
      <hr>
    </div>
  </div> -->
  <div  *ngIf="branch['covidSafety']">
    <div class="spacer"></div>
    <p [innerHTML]="branch['covidSafety']['text']"></p>
    <div class="promos-list">
      <div class="promos-item" *ngFor="let promo of branch['covidSafety']['images']; let i=index">
        <img src="{{resourceLink + promo.image}}" (click)="open(i)" class="thumbnail">
      </div> 
    </div>
  </div>

<!-- <empty-container [text]="'No Promos Available'" *ngIf="branch['promos'].length === 0"></empty-container> -->