import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DrawerService } from '../../../../services/drawer/drawer.service';
import { UserAccountService } from '../../../../services/user-account/user-account.service';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit, OnDestroy {

  constructor(
    private fb:FormBuilder,
    private userService:UserAccountService,
    private drawerService:DrawerService
    ) { }

  form:FormGroup = this.fb.group({
    email:['', [Validators.required, Validators.email]]
  })

  get email(){ return this.form.get('email')}

  isSuccess:boolean = false;
  isSubmitted:boolean = false;
  showError:boolean = false;
  messageError:string="";

  isSubmittedResend:boolean = false;

  $forgotPwSub:Subscription|undefined;
  json ={};

  onSubmit(){
    if (this.form.invalid) {
      return;
    }
    
    this.isSubmitted = true;
    this.showError = false;
    this.messageError = "";

    this.json ={ email: this.email.value, path:'/reset-my-password'};

    this.$forgotPwSub = this.userService.forgotPassword(this.json).subscribe((data:any)=>{
      if(data.success==true){
        this.isSuccess = true;
      }else{
        this.isSubmitted =false;
        this.showError = true;
        this.messageError = data.message;
      }
    },err=>{
      console.log(err);
      alert("Can't connect to server please try again");
    })

  }

  resend(){
    this.isSubmittedResend = true;
    this.showError = false;
    this.messageError = "";
    this.$forgotPwSub =this.userService.forgotPassword(this.json).subscribe((data:any)=>{
      if(data.success==false){
        this.showError = true;
        this.messageError = data.message;
      }
      this.isSubmittedResend =false;
    })
  }



  hideForgotPassword(){
    this.drawerService.setForgotPassword(false);
  }

  ngOnDestroy(): void {
    if(this.$forgotPwSub)
      this.$forgotPwSub.unsubscribe()
  }

  ngOnInit() {
  }

}
