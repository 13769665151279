<!-- <div class="mb-4">
	<div class="mb-2">
		<branch-filter></branch-filter>
	</div>

	<div class="mb-2">
		<branch-location-list></branch-location-list>
	</div>
</div> -->
<branch-location-list></branch-location-list>

<div class="list-branches mt-4">
	<div class="branch-item">
		<div>
			<div class="branch-item-content active">
				<p class="small mb-1">You are currently viewing:</p>
				<h6 class="h6 fw-semibold mb-1">
					<a routerLink="/parlon/{{ parlon['slug'] }}/{{ activeBranch['slug'] }}">{{ activeBranch['branch_name'] }}</a>
				</h6>
	
				<span class="text-muted">{{ activeBranch['address'] }}</span>

				<div class="mt-1 d-flex align-items-center" *ngIf="showReview">	
					<div class=" d-flex align-items-start star-count" >			
							{{starCount |number : '1.1-1' }}				
					</div>				
					

					<div class="star-rating ms-1">
						<span
						  class="star"
						  [class.full]="starCount >= 0.8"
						  [class.half]="starCount >= 0.3 && starCount < 0.8"
						></span>
						<span
						  class="star"
						  [class.full]="starCount >= 1.8"
						  [class.half]="starCount >= 1.3 && starCount < 1.8"
						></span>
						<span
						  class="star"
						  [class.full]="starCount >= 2.8"
						  [class.half]="starCount >= 2.3 && starCount < 2.8"
						></span>
						<span
						  class="star"
						  [class.full]="starCount >= 3.8"
						  [class.half]="starCount >= 3.3 && starCount < 3.8"
						></span>
						<span
						  class="star"
						  [class.full]="starCount >= 4.8"
						  [class.half]="starCount >= 4.3 && starCount < 4.8"
						></span>
					</div>

					<!-- <div class=" d-flex align-items-start total-star-count">			
						({{totalStarCount}})			
					</div>		 -->
					
		
				</div>

				<!-- <div class="d-flex mb-2" style="font-size: 15px; padding-left:3px" *ngIf="showReview">
					<span>3+ years in business</span>  
					<span class="ms-1 me-1" style="color:#919191">&#x2022;</span>
					<span style="color:#F8A5A7;">Open</span>
					
				</div> -->
				
				<ul class="list-unstyled list-parlon-contact-details mb-0 mt-1">
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start"
						*ngIf="activeBranch['contact_numbers'].split(';').length > 0">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-phone text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<div *ngFor="let c of activeBranch['contact_numbers'].split(';')">
								<a href="tel:{{ c }}" class="link-secondary text-decoration-underline-thin fw-medium">{{ c }}</a>
							</div>
						</div>
					</li>
		
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start" *ngIf="activeBranch['establishment'] != null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-building text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ activeBranch['establishment']['name'] }}</p>
						</div>
					</li>
		
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start" *ngIf="activeBranch['neighborhood'] != null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-city text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ activeBranch['neighborhood']['name'] }}</p>
						</div>
					</li>
	
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start"
						*ngIf="activeBranch['location_name'] !== '' && activeBranch['location_name'] !== null && showLocation == true">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="far fa-map-marker-alt text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ activeBranch['location']['location_name'] }}</p>
						</div>
					</li>
	
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start"
						*ngIf="activeBranch['email_address'] !== '' && activeBranch['email_address'] !== null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-envelope text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ activeBranch['email_address'] }}</p>
						</div>
					</li>
				</ul>


				<div *ngIf="activeBranch['gmaps_link'] !== '' || activeBranch['waze_link'] !== ''">
					<p class="mt-5 mb-2">Visit us</p>

					<div class="row justify-content-center justify-content-md-start gx-4">
						<div class="col-auto" *ngIf="activeBranch['waze_link'] !== ''">
							<div class="ic-location-container">
								<a href="{{ activeBranch['waze_link'] }}" target="_blank">
									<img src="assets/images/waze-ic.png" alt="Waze" class="img-fluid">
								</a>
							</div>
						</div>
			
						<div class="col-auto" *ngIf="activeBranch['gmaps_link'] !== ''">
							<div class="ic-location-container">
								<a href="{{ activeBranch['gmaps_link'] }}" target="_blank">
									<i class="fas fa-map fa-lg fa-fw text-white"></i>
								</a>
							</div>
						</div>
					</div>
				</div>

				<table class="table table-operating-hours table-sm table-borderless mt-5 mb-0">
					<tbody>
						<tr>
							<td>Sunday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['sunday_opening'])}} - {{convertToDate(activeBranch['sunday_closing'])}}</td>
						</tr>
						<tr>
							<td>Monday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['monday_opening'])}} - {{convertToDate(activeBranch['monday_closing'])}}</td>
						</tr>
						<tr>
							<td>Tuesday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['tuesday_opening'])}} - {{convertToDate(activeBranch['tuesday_closing'])}}</td>
						</tr>
						<tr>
							<td>Wednesday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['wednesday_opening'])}} - {{convertToDate(activeBranch['wednesday_closing'])}}</td>
						</tr>
						<tr>
							<td>Thursday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['thursday_opening'])}} - {{convertToDate(activeBranch['thursday_closing'])}}</td>
						</tr>
						<tr>
							<td>Friday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['friday_opening'])}} - {{convertToDate(activeBranch['friday_closing'])}}</td>
						</tr>
						<tr>
							<td>Saturday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(activeBranch['saturday_opening'])}} - {{convertToDate(activeBranch['saturday_closing'])}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- <div class="d-flex align-items-center mt-6 mb-4">
		<div class="me-2">
			<h5 class="h5 text-secondary fw-bold mb-0">More branches</h5>
		</div>

		<div class="flex-grow-1">
			<hr class="my-0">
		</div>
	</div>

	<div class="branch-item" *ngFor="let branch of branches; index as i">
		<div *ngIf="selected == branch.location.id && i != 0">
			<div class="branch-item-content pb-6">
				<h6 class="h6 fw-semibold mb-1">
					<a routerLink="/parlon/{{ parlon['slug'] }}/{{ branch['slug'] }}" class="link-normal">{{ branch['branch_name'] }}</a>
				</h6>
	
				<p class="text-muted">{{ branch['address'] }}</p>
	
				<ul class="list-unstyled list-parlon-contact-details mb-0">
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start"
						*ngIf="branch['contact_numbers'].split(';').length > 0">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-phone text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<div *ngFor="let c of branch['contact_numbers'].split(';')">
								<a href="tel:{{ c }}" class="link-normal">{{ c }}</a>
							</div>
						</div>
					</li>
		
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start" *ngIf="branch['establishment'] != null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-building text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ branch['establishment']['name'] }}</p>
						</div>
					</li>
		
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start" *ngIf="branch['establishment'] != null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-city text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ branch['neighborhood']['name'] }}</p>
						</div>
					</li>
	
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start"
						*ngIf="branch['location_name'] !== '' && branch['location_name'] !== null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="far fa-map-marker-alt text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ branch['location']['location_name'] }}</p>
						</div>
					</li>
	
					<li class="d-flex flex-row flex-wrap justify-content-center justify-content-md-start"
						*ngIf="branch['email_address'] !== '' && branch['email_address'] !== null">
						<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
							<i class="fas fa-square text-secondary"></i>
							<i class="fas fa-envelope text-white" data-fa-transform="shrink-7"></i>
						</span>
						
						<div>
							<p class="mb-0">{{ branch['email_address'] }}</p>
						</div>
					</li>
				</ul>

				<div *ngIf="branch['gmaps_link'] !== '' || branch['waze_link'] !== ''">
					<p class="mt-5 mb-2">Visit us</p>

					<div class="row justify-content-center justify-content-md-start gx-4">
						<div class="col-auto" *ngIf="branch['waze_link'] !== ''">
							<div class="ic-location-container">
								<a href="{{ branch['waze_link'] }}" target="_blank">
									<img src="assets/images/waze-ic.png" alt="Waze" class="img-fluid">
								</a>
							</div>
						</div>
			
						<div class="col-auto" *ngIf="branch['gmaps_link'] !== ''">
							<div class="ic-location-container">
								<a href="{{ branch['gmaps_link'] }}" target="_blank">
									<i class="fas fa-map fa-lg fa-fw text-white"></i>
								</a>
							</div>
						</div>
					</div>
				</div>

				<table class="table table-operating-hours table-sm table-borderless mt-4 mb-0">
					<tbody>
						<tr>
							<td>Sunday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['sunday_opening'])}} - {{convertToDate(branch['sunday_closing'])}}</td>
						</tr>
						<tr>
							<td>Monday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['monday_opening'])}} - {{convertToDate(branch['monday_closing'])}}</td>
						</tr>
						<tr>
							<td>Tuesday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['tuesday_opening'])}} - {{convertToDate(branch['tuesday_closing'])}}</td>
						</tr>
						<tr>
							<td>Wednesday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['wednesday_opening'])}} - {{convertToDate(branch['wednesday_closing'])}}</td>
						</tr>
						<tr>
							<td>Thursday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['thursday_opening'])}} - {{convertToDate(branch['thursday_closing'])}}</td>
						</tr>
						<tr>
							<td>Friday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['friday_opening'])}} - {{convertToDate(branch['friday_closing'])}}</td>
						</tr>
						<tr>
							<td>Saturday</td>
							<td class="text-end tabular-nums">
								{{convertToDate(branch['saturday_opening'])}} - {{convertToDate(branch['saturday_closing'])}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div> -->
</div>

<div class="mt-7">
	<p *ngIf="website != null">Website:<br />
		<a href="{{ parlon['website'] }}" target="_blank">{{ website }}</a></p>

	<p class="fw-semibold mb-2" *ngIf="parlon['facebook'] != null || parlon['twitter'] != null || parlon['instagram'] != null">Follow {{ parlon['business_name'] }}</p>

	<div class="row gx-4">
		<div class="col-auto" *ngIf="parlon['facebook'] != null">
			<a href="{{ parlon['facebook'] }}" target="_blank">
				<i class="fab fa-facebook fa-2x fa-fw"></i>
			</a>
		</div>

		<div class="col-auto" *ngIf="parlon['instagram'] != null">
			<a href="{{ parlon['instagram'] }}" target="_blank">
				<i class="fab fa-instagram fa-2x fa-fw"></i>
			</a>
		</div>

		<div class="col-auto" *ngIf="parlon['youtube'] != null">
			<a href="{{ parlon['youtube'] }}" target="_blank">
				<i class="fab fa-youtube fa-2x fa-fw"></i>
			</a>
		</div>

		<div class="col-auto" *ngIf="parlon['tiktok'] != null">
			<a href="{{ parlon['tiktok'] }}" target="_blank">
				<i class="fa-brands fa-tiktok fa-2x fa-fw"></i>
			</a>
		</div>
	</div>

	<hr class="my-6">

	<ng-container class="d-none d-lg-block">
		<terms-of-use></terms-of-use>
	</ng-container>
</div>




<!-- <div class="spacer"></div>
<div class="branch-list">

  <div *ngFor="let branch of branches" class="branch">

    <div class="content" *ngIf="selected == branch.location.id">
      <h5 class="branch-name"> <a routerLink="/parlon/{{parlon['slug']}}/{{branch['slug']}}" class="link -regular">{{branch['branch_name']}}</a></h5>

      <p>{{branch['address']}}</p>

      <ul class="contacts">
        <li *ngIf="branch['contact_numbers'].split(';').length > 0" class="numbers"><i class="fa fa-phone-square" aria-hidden="true"></i>
          <div>
            <div *ngFor="let c of branch['contact_numbers'].split(';')"><a href="tel:{{c}}" class="link -regular"><b><u>{{c}}</u></b></a></div>
          </div>
        </li>
        <li *ngIf="branch['establishment'] != null" class="numbers">
          <i class="fa fa-building" aria-hidden="true"></i>
          <div>
            <a href="#" class="link -regular">{{branch['establishment']['name']}}</a>
          </div>
        </li>
        <li *ngIf="branch['neighborhood'] != null" class="numbers">
          <i class="fa fa-city" aria-hidden="true"></i>
          <div>
            <a href="#" class="link -regular">{{branch['neighborhood']['name']}}</a>
          </div>
        </li>
        <li *ngIf="branch['location_name'] !== '' && branch['location_name'] !== null"><i class="fa fa-map-marker"
            aria-hidden="true"></i>{{branch['location']['location_name']}}</li>
        <li *ngIf="branch['email_address'] !== '' && branch['email_address'] !== null"><i class="fa fa-envelope-square" aria-hidden="true"></i><a
            href="mailto:{{branch['email_address']}}" class="link -regular line-break">{{branch['email_address']}}</a></li>
      </ul>

      <div class="is-hidden-mobile">
        <div *ngIf="branch['gmaps_link'] !== '' || branch['waze_link'] !== ''">
          <p>Visit Us</p>
          <div class="locations">
            <span *ngIf="branch['waze_link'] !== ''"><a href="{{branch['waze_link']}}" target="_blank"><img src="assets/images/waze-ic.png" alt="Waze"
                  width="35"></a></span> &nbsp;
            <span class="map-icon" *ngIf="branch['gmaps_link'] !== ''"><a href="{{branch['gmaps_link']}}" target="_blank"><i class="fas fa-map"
                  aria-hidden="true"></i></a></span>
          </div>
        </div>

        <table class="table is-fullwidth">
          <tr>
            <td>Sunday</td>
            <td>{{convertToDate(branch['sunday_opening'])}} - {{convertToDate(branch['sunday_closing'])}}</td>
          </tr>
          <tr>
            <td>Monday</td>
            <td>{{convertToDate(branch['monday_opening'])}} - {{convertToDate(branch['monday_closing'])}}</td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td>{{convertToDate(branch['tuesday_opening'])}} - {{convertToDate(branch['tuesday_closing'])}}</td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td>{{convertToDate(branch['wednesday_opening'])}} - {{convertToDate(branch['wednesday_closing'])}}</td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td>{{convertToDate(branch['thursday_opening'])}} - {{convertToDate(branch['thursday_closing'])}}</td>
          </tr>
          <tr>
            <td>Friday</td>
            <td>{{convertToDate(branch['friday_opening'])}} - {{convertToDate(branch['friday_closing'])}}</td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td>{{convertToDate(branch['saturday_opening'])}} - {{convertToDate(branch['saturday_closing'])}}</td>
          </tr>
        </table>
      </div>
    </div>

  </div>
  <div class="spacer"></div>
  <div class="content">
    <h6 *ngIf="website != null"><a href="{{parlon['website']}}" target="_blank" class="link -regular">{{website}}</a></h6>
    <h6 *ngIf="parlon['facebook'] != null || parlon['twitter'] != null || parlon['instagram'] != null">Follow {{parlon['business_name']}}</h6>
    <div class="scm-links">
      <a href="{{parlon['facebook']}}" target="_blank" mat-icon-button color="accent" *ngIf="parlon['facebook'] != null"><i
          class="fab fa-facebook-square"></i></a>
      <a href="{{parlon['youtube']}}" target="_blank" mat-icon-button color="accent" *ngIf="parlon['youtube'] != null"><i
          class="fab fa-youtube"></i></a>
      <a href="{{parlon['instagram']}}" target="_blank" mat-icon-button color="accent" *ngIf="parlon['instagram'] != null"><i
          class="fab fa-instagram"></i></a>
    </div>
  </div>
  <ng-container class="is-hidden-mobile">
    <terms-of-use></terms-of-use>
  </ng-container>
</div> -->
