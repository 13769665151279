<div class="row flex-account gx-0 gy-6 ">

	<!-- Login -->
	<div class="col-12 col-login ">	
		<h3 class="h3 fw-semibold mb-4">Sign in</h3>
		<!-- <button mat-flat-button color="facebook" class="btn-submit" data-bs-dismiss="offcanvas" (click)="fbAuthModalShow()">
			<i class="fa-brands fa-facebook mx-2"></i> CONTINUE WITH FACEBOOK 
		</button>
		<p class="my-4 text-center">or</p> -->
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<mat-form-field appearance="fill">
				<mat-label>Email address</mat-label>
				<input matInput placeholder="Email address" formControlName="email">
			</mat-form-field>
			<!-- <div class="errors" *ngIf="email.invalid && (email.dirty || email.touched)" >					
				<mat-error *ngIf="email.errors.required" class="text-danger">
					Email is required.
				</mat-error>
				<mat-error *ngIf="email.errors.email && email.touched">
					Must be a valid email format.
				</mat-error>										
			</div> -->

			<mat-form-field appearance="fill">
				<mat-label>Password</mat-label>
				<input matInput placeholder="Password" id="login_password" formControlName="password" [type]="hidePasswordIcon ? 'password' : 'text'">
				<button  (click)="hidePasswordIcon = !hidePasswordIcon"  type="button" mat-icon-button matSuffix>
					<mat-icon>{{hidePasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>

			<!-- <div class="errors" *ngIf="password.invalid && (password.dirty || password.touched)" >					
				<mat-error *ngIf="password.errors.required" class="text-danger">
					Password is required.
				</mat-error>				
			</div> -->

			<div class="errors" *ngIf="inValidEmailOrPassword" >					
				<mat-error >
					Incorrect email or password.
				</mat-error>				
			</div>

			<button [disabled]="!form.valid || submitted"  mat-flat-button color="secondary" class="btn-submit mb-5" type="submit">
				Sign in
			</button>

			<a style="cursor: pointer;" (click)="forgotPassword()" class="fw-semibold">Forgot password?</a>
		</form>
	</div>

	<div class="col-12 col-hr mt-5">
		<hr class="o-50 my-0">
	</div>

	<!-- Register -->
	<div class="col-12 col-register mt-5">
		<h3 class="h3 fw-semibold mb-4">Register now</h3>

		<p class="mb-4"><small>Create your Parlon account and enjoy beautiful surprises. Get instant <span class="fw-semibold">PHP 250 voucher</span> when
			you register + Parlon candies (revealing this soon!) 🍬 You're welcome!</small></p>
			
		<!-- <button (click)="openSelect()" mat-flat-button color="secondary" class="btn-submit" data-bs-dismiss="offcanvas">
			Create account
		</button> -->
		<a routerLink="/register"  mat-flat-button color="secondary" class="btn-submit" data-bs-dismiss="offcanvas">
			Create account
		</a>
	</div>

	<div class="col-12 col-hr mt-5">
		<hr class="o-50 my-0">
	</div>

	<!-- OG -->
	<!-- <div class="col-12 col-other-links mt-5">
		<h3 class="h3 fw-semibold mb-4">Original Glowster</h3>
		<div class="row row-cols-2 gx-4">
			<div class="col">
				<div [routerLink]="['/be-an-og-member']" class="ratio ratio-16x9 ratio-og-card">
					<img src="{{resource + ogContent['nav_drawer_how_to_be_a_parlon_og_image']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
				</div>
			</div>

			<div class="col">
				<div [routerLink]="['/og-perks']"  class="ratio ratio-16x9 ratio-og-card">
					<img src="{{resource + ogContent['nav_drawer_og_perks_image']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
				</div>
			</div>
		</div>
	</div>

	<div class="col-12 col-hr mt-5">
		<hr class="o-50 my-0">
	</div> -->

	

	<!-- Additional links -->
	<div class="col-12 col-other-links mt-5">
		<ul class="nav nav-account-links flex-column">
			<li class="nav-item">
				<a href="https://legal.parlon.ph/post/terms-of-use" class="nav-link link-text fw-medium">Terms of Use</a>
			</li>

			<li class="nav-item">
				<a href="https://legal.parlon.ph/post/privacy-notice" class="nav-link link-text fw-medium">Privacy Notice</a>
			</li>
		</ul>
	</div>

</div>