<div class="filters-container sidebar pos-relative" style="min-height: 100%;" >
  <div>
    <div class="mb-2 d-flex justify-content-between align-items-center ">
      <div>
        <div class="fs-5 fw-semibold ">Filters</div>        
      </div>      
      <div class="d-flex align-items-center">
        <button [mat-dialog-close]="true" mat-flat-button color="secondary" class="close-btn"><mat-icon [inline]="true">clear</mat-icon> </button>
      </div>      
    </div>      
    <div class="search-category-filter-container mb-2" >
      <div class="sort-select">
        <mat-accordion>          
          <mat-expansion-panel [expanded]="true" hideToggle="true" #panelCategory>
            <mat-expansion-panel-header>
              <mat-panel-title style="position:sticky; top:-24px;" class="font-weight-bold">
                Categories
              </mat-panel-title>
              <mat-icon >{{panelCategory.expanded? 'remove' : 'add'}}</mat-icon>
            </mat-expansion-panel-header>
            <div *ngFor="let option of categoriesV2; index as ind" >    
              <!-- <div (click)="showSub(option.slug);"  style="cursor: pointer;" [ngClass]="{'text-primary font-weight-bold': option.show, 'd-flex justify-content-between':true}" >
                <span>{{option.category_name}}</span>
              </div>          -->
              <mat-accordion class="category-accordion">          
                <mat-expansion-panel [expanded]="false" hideToggle="true" >
                  <mat-expansion-panel-header  collapsedHeight="*" expandedHeight="*" (click)="expand(option)">
                    <mat-panel-title>
                      <mat-checkbox class="checkbox" (click)="$event.stopPropagation();" (ngModelChange)="sortByCategory(option,$event)" [(ngModel)]="option.show">                  
                      </mat-checkbox>
                      <span>{{option.category_name}}</span>
                    </mat-panel-title>
                    <mat-icon >{{option.expanded? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                  </mat-expansion-panel-header>
                  <div class="mt-2">
                    <div style="margin-left: 1em; cursor: pointer;" class="d-flex justify-content-between mb-2" *ngFor="let sub of option.subcategories; index as i ">
                      <mat-checkbox class="checkbox" (ngModelChange)="sortBySubCategory(option,$event,i)" [(ngModel)]="sub.show">     
                       <span>{{sub.category_name}}</span>             
                      </mat-checkbox>
                    </div>  
                  </div> 
                </mat-expansion-panel>
              </mat-accordion>
              <!-- -->
            </div>            
          </mat-expansion-panel>
        </mat-accordion>

      </div>
    </div>

    <div class="search-parlons-filter-container mt-5">
      <div class="sort-select ">
        <mat-accordion>          
          <mat-expansion-panel #panelParlon [expanded]="false" hideToggle="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="font-weight-bold">
                Parlons
              </mat-panel-title>
              <mat-icon >{{panelParlon.expanded? 'remove' : 'add'}}</mat-icon>
            </mat-expansion-panel-header>            
            <div *ngFor="let option of parlonsV2; index as i"  class="">
              <div class="d-flex   justify-content-between mb-1" >
                  <mat-checkbox class="checkbox" [(ngModel)]="option.completed"
                  [color]="option.color"
                  (ngModelChange)="sortByParlonV2()">                         
                  <span>{{option.highlighted}}</span>                
                </mat-checkbox>
                <!-- <span class="badge" style="height: auto; align-self:center;" >{{option.count}}</span>                -->
              </div>              
            </div>            
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>  
    
    <div class="search-locations-filter-container mt-5">
      <div class="sort-select ">
        <mat-accordion>          
          <mat-expansion-panel #panelLocations [expanded]="false" hideToggle="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="font-weight-bold">
                Locations
              </mat-panel-title>
              <mat-icon >{{panelLocations.expanded? 'remove' : 'add'}}</mat-icon>
            </mat-expansion-panel-header>            
            <div *ngFor="let option of locationsV2; index as i"  class="">
              <div class="d-flex   justify-content-between mb-1" >
                  <mat-checkbox class="checkbox" [(ngModel)]="option.completed"
                  [color]="option.color"
                  (ngModelChange)="sortByLocationV2()">                         
                  <span>{{option.highlighted}}</span>                
                </mat-checkbox>
                <!-- <span class="badge" style="height: auto; align-self:center;" >{{option.count}}</span>                -->
              </div>              
            </div>            
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>    
  </div>
  <div class="apply-filter">
    <button mat-flat-button color="secondary" class="mt-2 w-100" [mat-dialog-close]="true">APPLY FILTERS</button>
    <button (click)="resetFilter2()" mat-button class="border mt-2 w-100 text-secondary" style="border: none!important;">RESET FILTER</button>
  </div>
</div>
