import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss']
})
export class MessageBannerComponent implements OnInit {

  @Input() data: object;

  constructor() { }

  ngOnInit() {
  }

}
