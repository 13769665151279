<div class="pt-6">
	<div class="row g-4">
		<div class="col-12 col-md-3 col-lg-4 col-xxl-3" *ngFor="let photo of branch['photo']; let i=index">
			<div class="ratio ratio-1x1">
				<img src="{{resourceLink + photo.image}}" alt="{{photo.alt}}" title="{{photo.title}}" class="img-fluid obj-fit-cover" (click)="open(i)">
			</div>
		</div>
	</div>
</div>

<empty-container [text]="'No Photos Available'" *ngIf="branch['photo'].length === 0"></empty-container>