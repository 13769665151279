import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-multi-booking-thankyou',
  templateUrl: './multi-booking-thankyou.component.html',
  styleUrls: ['./multi-booking-thankyou.component.scss']
})
export class MultiBookingThankyouComponent implements OnInit {

  response = JSON.parse(localStorage.getItem('DealsBookingResponse'));
  resource = environment.resource;

  constructor() { }

  ngOnInit() {
  }

}
