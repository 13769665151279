import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { OgPerksInnerComponent } from './og-perks-inner.component';
import { RouterModule } from '@angular/router';
import { InnerLoaderComponent } from './components/inner-loader/inner-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {MatButtonModule} from '@angular/material/button';
import { RedeemModalComponent } from './components/redeem-modal/redeem-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OgPerksInnerRouteModule } from "./og-perks-inner-routing.module";

@NgModule({
  imports: [
    CommonModule,
    OgPerksInnerRouteModule,
    RouterModule,
    CommonElementsModule,
    NgxSkeletonLoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    
  ],
  declarations: [OgPerksInnerComponent, InnerLoaderComponent, RedeemModalComponent],
  entryComponents: [RedeemModalComponent]
})
export class OgPerksInnerModule { }
