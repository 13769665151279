import { Component, OnInit, ElementRef } from '@angular/core';
import { GiftingService } from '../../../../services/gifting/gifting.service';
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import * as Rx from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonsService } from '../../../../services/salons/salons.service';

declare var Flickity: any;
declare var My2c2p: any;

@Component({
  selector: 'gifting-form',
  templateUrl: './gifting-form.component.html',
  styleUrls: ['./gifting-form.component.scss']
})
export class GiftingFormComponent implements OnInit {

  isLoaded = false;
  cc: object;
  isLinear = false;
  resource = environment.resource;
  data:any = {};
  denominations = [];
  denomination_value;
  quantity = [1,2,3,4,5];
  giftee_count = 0;
  giftee_data = []
  giftee_count_arr = [];
  giftForm: FormGroup;
  gifteesFormArray = new FormArray([]);
  total = 0;
  step = 'form';
  selected_channel:string="";
  gifteesSubject$ = new Rx.BehaviorSubject([]);
  loading = false;
  giftees$ = this.gifteesSubject$.asObservable();
  form_data: object;
  salon = null;

  constructor(
    private GiftingService: GiftingService,
    private SalonsService: SalonsService,
    private el: ElementRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
    this.form_data = {
      'payment_channel_code': '',
      'securePayToken': '',
      'buyer': {},
      'items': []
    }
   }

  ngOnInit() {
    const page = this;
    page.isLoaded = false;
    let slug = this.route.snapshot.paramMap.get("slug");
    page.giftForm = new FormGroup({
      'personalDetailsFormGroup': new FormGroup({
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
      }),
      'paymentFormGroup': new FormGroup({
        'promoCode': new FormControl(null),
        'creditCard': new FormControl(null, Validators.required),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
        'paymentChannel': new FormControl(null, Validators.required),
        'eVoucherPolicy': new FormControl(false,Validators.required),
      })
    });
    this.GiftingService.getGiftingData().subscribe((data: {}) => {
      page.data = data;
      page.denominations = data['contents']['denominations'].split(';');
      page.denomination_value = page.denominations[0];
      page.isLoaded = true;
      page.addGiftee();
    });
    if(slug != null) {
      this.SalonsService.getSalon(slug).subscribe((data: {}) => {
        if (data['data'].length === 0 || data['data'][0]['active_deal_count'] < 1) {
          this.router.navigate(['/404']);
        }
        this.salon = data['data'][0];
      });
    }
  }

  addGiftee() {
    this.giftee_count++;
    this.giftee_count_arr = [];
    this.giftee_count_arr = Array(this.giftee_count).fill(0).map((x,i)=>i);
    this.gifteesFormArray.push(
      new FormGroup({
        'denomination': new FormControl(this.denominations[0], [Validators.required]),
        'quantity': new FormControl(this.quantity[0], [Validators.required]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
        'card': new FormControl(null, Validators.required),
    }));
    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-giftee-'+(this.giftee_count - 1));
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: true,
        imagesLoaded: true
      });
    }, 100);
  }

  formNext() {
    const page = this;
    let invalid = false;
    page.giftee_data = [];
    page.total = 0;
    for (let index = 0; index < page.gifteesFormArray.controls.length; index++) {
      const ctrl = page.gifteesFormArray.controls[index];
      page.total = page.total + (ctrl['controls']['denomination']['value'] * ctrl['controls']['quantity']['value']);
      page.giftee_data.push({
        first_name:ctrl['controls']['first_name']['value'],
        last_name:ctrl['controls']['last_name']['value'],
        email:ctrl['controls']['email']['value'],
        contact_number:ctrl['controls']['contact_number']['value'],
        denomination:ctrl['controls']['denomination']['value'],
        quantity:ctrl['controls']['quantity']['value'],
        card:ctrl['controls']['card']['value'],
      });
      Object.keys(ctrl['controls']).forEach(function(key) {
        ctrl['controls'][key].markAsTouched();
      });
      const card: HTMLElement = this.el.nativeElement.querySelector('.select-card-'+index); 
      if(ctrl['controls']['card']['status'] == 'INVALID') {
        card.classList.add('error');
      } else {
        card.classList.remove('error');
      }
      if(ctrl.status == 'INVALID') {
        invalid = true;
      }
    }
    Object.keys(page.giftForm.controls.personalDetailsFormGroup['controls']).forEach(function(key) {
      page.giftForm.controls.personalDetailsFormGroup['controls'][key].markAsTouched();
    });
    if(page.giftForm.controls.personalDetailsFormGroup.status == "INVALID") {
      invalid = true;
    }
    if(invalid == true) {
      this.scrollToFirstInvalidControl();
      return false;
    }
    page.gifteesSubject$.next(page.giftee_data);
    page.step = 'summary';
   setTimeout(() => {
    window.scrollTo(0,0)
   }, 100);
  }

  selectCard(form_index, card_index, value) {
    let cards: HTMLElement = this.el.nativeElement.querySelectorAll('.flickity-giftee-'+form_index+ " .gift-card"); 
    for (let index = 0; index < this.data['gift_cards'].length; index++) {
      const e = cards[index];
      e.classList.remove('active');
    }
    cards[card_index].classList.add('active');
    this.gifteesFormArray.controls[form_index].patchValue({
      card:value,
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  paymentOptionClick(target) {
    // let element: HTMLElement = document.getElementById(target) as HTMLElement;
    // console.log(element);

    this.selected_channel = target;

    this.giftForm.controls.paymentFormGroup.patchValue({
      paymentChannel:target
    });
  }

  onSubmit(){
    let page = this;
    // paymentFormGroup credit card
    const paymentSection: HTMLElement = this.el.nativeElement.querySelector(
      ".payment-checkpoint"
    );
    if(this.selected_channel == null || this.selected_channel == '') {
      paymentSection.classList.add('error');
      paymentSection.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}); 
      return false;
    } else {
      paymentSection.classList.remove('error');
    }

    if(this.selected_channel == 'parlon_credit_card') {

      if(page.giftForm.controls.paymentFormGroup.status == null) {
        page.scrollToFirstInvalidControl();
        return false;
      }
     
      // end of validate
      page.cc['cardnumber'] = page.giftForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      page.cc['cvv'] = page.giftForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      page.cc['month'] = page.giftForm.value.paymentFormGroup.ccMonth;
      page.cc['year'] = page.giftForm.value.paymentFormGroup.ccYear.toString();

    }
    
    setTimeout(() => {
      if(this.selected_channel == '') {
        return false;
      }
      My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {
        page.form_data['securePayToken'] = encryptedData.encryptedCardInfo;
      });
      const eVoucherPolicy: HTMLElement = this.el.nativeElement.querySelector(
        ".eVoucherPolicy"
      );
      if(page.giftForm.value.paymentFormGroup.eVoucherPolicy == false) {
        eVoucherPolicy.classList.add('cb-error');
        eVoucherPolicy.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}); 
        return false;
      } else {
        eVoucherPolicy.classList.remove('cb-error');
      }
      page.form_data['payment_channel_code'] = page.selected_channel;
      page.form_data['buyer'] = {
        first_name: page.giftForm.value.personalDetailsFormGroup.first_name,
        last_name: page.giftForm.value.personalDetailsFormGroup.last_name,
        email: page.giftForm.value.personalDetailsFormGroup.email,
        mobile: page.giftForm.value.personalDetailsFormGroup.contact_number
      };
      page.form_data['items'] = [];
      for (let index = 0; index < page.gifteesFormArray.controls.length; index++) {
        const ctrl = page.gifteesFormArray.controls[index];
        page.form_data['items'].push({
          promo_value:ctrl['controls']['denomination']['value'],
          quantity:ctrl['controls']['quantity']['value'],
          first_name:ctrl['controls']['first_name']['value'],
          last_name:ctrl['controls']['last_name']['value'],
          email:ctrl['controls']['email']['value'],
          mobile:ctrl['controls']['contact_number']['value'],
          gift_card_id:ctrl['controls']['card']['value']['id'],
        });
      }
      if(page.salon != null) {
        page.form_data['parlon'] = page.salon['slug'];
      }
      page.requestPayment();
    }, 200);
    
  }

  requestPayment() {
    let app = this;
    app.loading = true;
    app.GiftingService.paymentRequest(app.form_data).subscribe((data: {}) => {
      if(data['status'] == true && data['isRedirect'] == true){
        window.location.href = data['response']['data'];
      } else {
        app.loading = false;
        // app.isTransactionFailed = true;
      }
    });
  }

  removeGiftee(index,in_summary = false) {
    this.giftee_count--;
    this.giftee_count_arr.splice(index,1);
    this.gifteesFormArray.removeAt(index);
    if(in_summary) {
      this.giftee_data.splice(index,1);
      this.gifteesSubject$.next(this.giftee_data);
    }
    this.total = 0;
    for (let i = 0; i < this.giftee_data.length; i++) {
      const d = this.giftee_data[i];
      this.total = this.total + (d['denomination'] * d['quantity']);
    }
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid, .error"
    );
    if(firstInvalidControl[0])
      firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

}
