<div *ngIf="isLoaded == true">
	<div class="bg-light py-5" >
		<div class="container">
			<div class="row">
				<div class="col-12">
					<a [routerLink]="['/glife-services', branchGlife['slug']]"><i class="fas fa-chevron-left mx-2"></i>Back</a>
					<div class="p-2"></div>
					<p class="small fw-semibold">You are buying:</p>
	
					<table class="table table-cart-items table-borderless">
						<tbody>
							<tr class="cart-item" *ngFor="let deal of deals">
								<td class="cart-item-title">{{ deal.label }}</td>
								<td class="cart-item-price">
									<p class="text-price mb-0">
										<span class="current-price">PHP {{ deal.deal_price | number}}</span>
										<br /><span class="og-price">PHP {{ deal.original_price | number }}</span>
									</p>
								</td>
							</tr>
	
							<tr class="cart-item" *ngFor="let service of services">
								<td class="cart-item-title">{{ service.name }}</td>
								<td class="cart-item-price">
									<p class="text-price mb-0">
										<span class="current-price">PHP {{ service.price | number }}</span>
										<!-- <br /><span class="og-price">PHP 1,949</span> -->
									</p>
								</td>
							</tr>
						</tbody>
					</table>
	
					<hr class="my-4">
	
					<table class="table table-cart-compute table-borderless mb-0">
						<tbody>
							<tr>
								<td class="fw-semibold">Total (inc. VAT)</td>
								<td class="text-secondary text-end fw-semibold">PHP {{ total | number }}</td>
							</tr>
	
							<tr>
								<td class="fw-semibold">Savings</td>
								<td class="text-primary text-end fw-semibold">PHP {{ originalTotal - total | number }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	
	<div class="py-5">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="mb-6">
						<h6 class="h6 text-primary text-uppercase fw-semibold mb-4">Selected branch:</h6>
						<p class="fw-semibold mb-2">{{ branchGlife['branch_name'] }}</p>
		
						<div class="row flex-branch-address gx-3">
							<div class="col-auto col-icon">
								<i class="fas fa-map-marker-alt fa-fw text-secondary"></i>
							</div>
			
							<div class="col">
								<p class="mb-0">{{ branchGlife['address'] }}</p>
							</div>
						</div>
					</div>
	
					<hr class="my-6">
	
					<div>
						<!-- Checkout form -->
						<glife-checkout-form [total]="total" [services]="services" [deals]="deals" [branch]="branchGlife" [user_info]="user_info"></glife-checkout-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<full-page-loading *ngIf="isLoaded == false"></full-page-loading>
