<!-- PLEASE! -->
<!-- Use the CSS variable below to change the background instead of using the actual CSS background attribute -->
<!-- If no background is declared, it will AUTOMATICALLY fallback to default background color (#FFF1EE); no need to declare a default, just remove the inline style. -->

<section class="s-book-appointment-message bg-light-1 py-8 py-md-9 d-flex align-items-center justify-content-center"
	style="--section-bg: url('https://parlon.s3.ap-southeast-1.amazonaws.com/BookingBanners/Booking%20Banner%20Desktop%20%281920%20x%20450px%29.png')" *ngIf="isLoaded == true && transactionStatus == 'failed'">

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-lg-9 col-xl-8">
				<div class="card bg-white">
					<div class="card-body px-sm-8 pt-sm-6 pb-8">
						
						<div class="row justify-content-center">
							<div class="col-12 col-lg-11 col-xl-10 text-center">
								<img src="/assets/images/booking-failed.png" width="160" class="mb-4">

								<div class="text-center">
									<h1 class="text-center ">Payment failed</h1>
									<p class="fs-6 fw-medium">Your payment to {{selectedBooking['salon']}} - {{selectedBooking['branch']}} failed.</p>
									<p class="mb-6">It seems like there was an issue processing your payment. You can try paying again. If the problem continues, feel free to reach out to our team at Parlon.</p>
								</div>
								<div class="d-flex justify-content-center mt-4">
									<button mat-raised-button color="secondary" routerLink="/booking">BOOK NOW</button>
								</div>
							</div>
						</div>
	
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<section class="s-book-appointment-message bg-light-1 py-8 py-md-9 d-flex align-items-center justify-content-center"
	style="--section-bg: url('https://parlon.s3.ap-southeast-1.amazonaws.com/BookingBanners/Booking%20Banner%20Desktop%20%281920%20x%20450px%29.png')" *ngIf="isLoaded == true && bookingType == 'nonPrepay' && transactionStatus == 'success'">

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-lg-9 col-xl-8">
				<div class="card bg-white">
					<div class="card-body px-sm-8 pt-sm-6 pb-8">
						
						<div class="row justify-content-center">
							<div class="col-12 col-lg-11 col-xl-10 text-center">
								<img src="{{resource + selectedBooking['logo']}}?tr=w-320" alt="" loading="lazy" class="img-fluid img-parlon-partner-logo mb-4">

								

								<div *ngIf="bookingStatus == 'Pending'">
									<h1 class="h1 fw-semibold text-heading mb-8">Your booking request has been sent to<br />
										<span class="text-primary">{{selectedBooking['salon']}} -{{selectedBooking['branch']}}</span></h1>
	
									<p class="fs-6 fw-medium">Get ready to enjoy a self-care session!</p>
									<p class="mb-6">You have successfully placed a booking request. {{selectedBooking['salon']}} will get in touch with you to confirm your booking.</p>
									<p>Thank you for booking through Parlon.</p>
								</div>

								<div *ngIf="bookingStatus == 'Confirmed'">
									<h1 class="h1 fw-semibold text-heading mb-8">
										<span class="text-primary">{{selectedBooking['salon']}} -{{selectedBooking['branch']}} confirmed your booking request on  {{selectedBooking['date'] | date : "MMM d, y ( EEEE )"}} at {{selectedBooking['time']}}</span></h1>
	
									<p class="fs-6 fw-medium">Get ready to enjoy a self-care session!</p>
									<p>Thank you for booking through Parlon.</p>
								</div>

								<div class="mt-6">
									<button [routerLink]="['/']" mat-stroked-button color="secondary" class="text-uppercase d-block w-80 w-sm-75 w-lg-60 mx-auto py-2">
										Back to home
									</button>
								</div>
							</div>
						</div>
	
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="s-book-appointment-message bg-light-1 py-8 py-md-9 d-flex align-items-center justify-content-center"
	style="--section-bg: url('https://parlon.s3.ap-southeast-1.amazonaws.com/BookingBanners/Booking%20Banner%20Desktop%20%281920%20x%20450px%29.png')" *ngIf="isLoaded == true && bookingStatus == 'Confirmed' && bookingType == 'post' && transactionStatus == 'success'">

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-lg-9 col-xl-8">
				<div class="card bg-white">
					<div class="card-body px-sm-8 pt-sm-6 pb-8">
						
						<div class="row justify-content-center">
							<div class="col-12 col-lg-11 col-xl-10 text-center">
								<img src="/assets/images/booking-success.png" width="160" class="mb-4">

								<h1 class="h1 fw-semibold text-heading mb-8">Thank you for your payment, {{selectedBooking['user']}}!</h1>

								<p class="mb-6">You successfully paid <span class="fw-bold">{{currency}}{{selectedBooking['price']}}</span> to {{selectedBooking['salon']}} - {{selectedBooking['branch']}}! <br> We look forward to serving you again.</p>
								<div class="row">
									<div class="col-md-8 offset-md-2">
										<div class="d-flex align-items-center justify-content-center mb-3">
											<img src="{{resource + selectedBooking['logo']}}?tr=w-320" width="50" loading="lazy">
											<p class="ms-3 mb-0 fw-semibold">{{selectedBooking['salon']}} - {{selectedBooking['branch']}}</p>
										</div>
										<p class="mb-2 fw-semibold text-left  mb-3">Booking Details</p>
										<div class="d-flex align-items-center justify-content-between">
											<p class="ms-3 mb-0 fw-semibold"><i class="fa-regular fa-calendar text-primary me-3"></i> {{selectedBooking['date'] | date : "MMM d, y ( EEEE )"}}</p>
											<p class="ms-3 mb-0 fw-semibold"><i class="fa-regular fa-clock text-primary me-3"></i> {{selectedBooking['time']}}</p>
										</div>
									</div>
								</div>


								<div class="mt-6">
									<button [routerLink]="['/']" mat-stroked-button color="secondary" class="text-uppercase d-block w-80 w-sm-75 w-lg-60 mx-auto py-2">
										Back to home
									</button>
								</div>
							</div>
						</div>
	
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="s-book-appointment-message bg-light-1 py-8 py-md-9 d-flex align-items-center justify-content-center"
	style="--section-bg: url('https://parlon.s3.ap-southeast-1.amazonaws.com/BookingBanners/Booking%20Banner%20Desktop%20%281920%20x%20450px%29.png')" *ngIf="isLoaded == true && bookingType == 'pre' && transactionStatus == 'success'">

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-lg-9 col-xl-8">
				<div class="card bg-white">
					<div class="card-body px-sm-8 pt-sm-6 pb-8">
						
						<div class="row justify-content-center">
							<div class="col-12 col-lg-11 col-xl-10 text-center">
								<img src="/assets/images/booking-success.png" width="160" class="mb-4">

								<h1 class="h1 fw-semibold text-heading mb-8">Thank you for your payment, {{selectedBooking['user']}}!</h1>
								<p class="mb-3">
									You successfully paid the down payment of <span class="fw-bold">{{currency}}{{selectedBooking['price']}}</span> to {{selectedBooking['salon']}} - {{selectedBooking['branch']}}! You will still pay the remaining balance of your total bill when you visit the salon on your appointment. {{selectedBooking['salon']}} <span *ngIf="bookingStatus == 'Pending'">will also get in touch with you to confirm your booking</span> <span *ngIf="bookingStatus == 'Confirmed'">confirmed your booking request</span>. 
								</p>
								<p class="mb-6">
									Thank you for booking through Parlon!
								</p>
								<div class="row">
									<div class="col-md-8 offset-md-2">
										<div class="d-flex align-items-center justify-content-center mb-3">
											<img src="{{resource + selectedBooking['logo']}}?tr=w-320" width="50" loading="lazy">
											<p class="ms-3 mb-0 fw-semibold">{{selectedBooking['salon']}} - {{selectedBooking['branch']}}</p>
										</div>
										<p class="mb-2 fw-semibold text-left  mb-3">Booking Details</p>
										<div class="d-flex align-items-center justify-content-between">
											<p class="ms-3 mb-0 fw-semibold"><i class="fa-regular fa-calendar text-primary me-3"></i> {{selectedBooking['date'] | date : "MMM d, y ( EEEE )"}}</p>
											<p class="ms-3 mb-0 fw-semibold"><i class="fa-regular fa-clock text-primary me-3"></i> {{selectedBooking['time']}}</p>
										</div>
									</div>
								</div>


								<div class="mt-6">
									<button [routerLink]="['/']" mat-stroked-button color="secondary" class="text-uppercase d-block w-80 w-sm-75 w-lg-60 mx-auto py-2">
										Back to home
									</button>
								</div>
							</div>
						</div>
	
					</div>
				</div>
			</div>
		</div>
	</div>
</section>