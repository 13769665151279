import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss']
})
export class CreditCardFormComponent implements OnInit {

  @Input() paymentFormGroup: FormGroup;

  months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  currentYear = new Date().getFullYear();
  years = [];
   

  constructor() { }

  ngOnInit() {
    for (let index = this.currentYear; index <= (this.currentYear + 10); index++) {
      this.years.push(index);
    }
  }

    // convenience getter for easy access to form fields
    get f() { return this.paymentFormGroup.controls }
}
