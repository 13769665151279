import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
let authData = '';
let httpOptions = {};


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(platformId)) {
      authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
    } else {
      authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
    }
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authData
      })
    }
   }


  recentSearches:string[];
  recentViewed:string[];


  // configUrl="https://CWHNPDASIM-dsn.algolia.net/1/indexes/dev_Parlon?query=";

  configUrl="https://CWHNPDASIM-dsn.algolia.net/1/indexes/*/queries";

  stagingUrl =environment.api;



   getPopularSearches():Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-Algolia-API-Key':'df6865d61bda858d789a1aabf4a7632e',
      'X-Algolia-Application-Id':'CWHNPDASIM'
    });
    const requestOptions = { headers: headers };
    return this.http.get('https://CWHNPDASIM-dsn.algolia.net/1/indexes/dev_Parlon_query_suggestions?query',requestOptions);

   }

   JSONDesktop(value, location){
    let json;
    if(location==""){
      json = {"requests":[{
        clickAnalytics: true,
        indexName:environment.parlons,
        params:"query="+value,
        restrictSearchableAttributes: ['parlon_name']
      },
        {
          clickAnalytics: true,
          indexName: environment.deals,
          "params": "query="+value,
          restrictSearchableAttributes: ['name']
        }]
      };
    }else{
      let str="locations:"+ location;
      str = str.replace(/"/g, "")
      json = {"requests":[{
        clickAnalytics: true,
        indexName:environment.parlons,
        params:"query="+value,
        facetFilters:[str],
        restrictSearchableAttributes:['parlon_name']
      },{ clickAnalytics: true,
          indexName: environment.deals,
         "params": "query="+value,
         "facetFilters": [str],
          restrictSearchableAttributes: ['name']  }]}

    }

    return json;
   }

   JSONMobile(value,location){
    let json;
    if(location==""){
      json = {"requests":[{
        clickAnalytics: true,
        indexName:environment.parlons,
        params:"query="+value,
        distinct: 0,
      }, {clickAnalytics: true,
        indexName: environment.deals,
        "params": "query="+value,
        hitsPerPage: 50,
        distinct: 0,
        restrictSearchableAttributes: ['name','category','parlon']  }]}
    }else{

      let str="locations:"+ location;
      str = str.replace(/"/g, "")
      json = {"requests":[{
        clickAnalytics: true,
        indexName:environment.parlons,
        params:"query="+value,
        distinct: 0,
        hitsPerPage: 50,
        facetFilters:[str]
    },{ clickAnalytics: true,indexName: environment.deals, "params": "query="+value, distinct: 0, "facetFilters": [str], restrictSearchableAttributes: ['name','category','parlon']  }]}

    }

    return json;
   }

  searchMobilePagination(params, page=0, indexName):Observable<any>{

    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-Algolia-API-Key':'df6865d61bda858d789a1aabf4a7632e',
      'X-Algolia-Application-Id':'CWHNPDASIM'
    });
    const requestOptions = { headers: headers };

    let json;

    if(indexName=='deals'){
      json = {"requests":[
      { page,
        clickAnalytics: true,
        indexName: environment.deals, 
        "params": "query="+params['query'],
        "facetFilters":params['facetFilters'],
         distinct: 0,
         hitsPerPage: 50,
         restrictSearchableAttributes: ['name','category','parlon']
       }]}
    }else{
      json = {"requests":[{
        page,
        clickAnalytics: true,
        indexName:environment.parlons,
        distinct: 0,
        hitsPerPage: 50,
        "params": "query="+params['query'],
        "facetFilters":params['facetFilters']
      }]}      
    }   
    return this.http.post(this.configUrl,json, requestOptions);
  }



  search(value:string, location:string="", isMobile?:boolean):Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-Algolia-API-Key':'df6865d61bda858d789a1aabf4a7632e',
      'X-Algolia-Application-Id':'CWHNPDASIM'
    });
    const requestOptions = { headers: headers };
    let json;

    if(isMobile == true){
      json = this.JSONMobile(value,location);
    }else{
      json = this.JSONDesktop(value,location);
    }

    console.log(json);

    return this.http.post(this.configUrl,json, requestOptions);
    // return this.http.get(this.configUrl+value,requestOptions);

  }


  searchPageV2(value,location):Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-Algolia-API-Key':'df6865d61bda858d789a1aabf4a7632e',
      'X-Algolia-Application-Id':'CWHNPDASIM'
    });
    const requestOptions = { headers: headers };
    let json;
    let facets = [];
    let facetLocation = [];
    
    if(location && location != 'all' ){
      location = location.replace(/-/g, " ");
      facetLocation.push('locations:'+location)
       facets = [facetLocation];
    }

    if(value == null){
      value ="";
    }
    

    json = {"requests":[{
      clickAnalytics: true,
      indexName:environment.parlons,
      params:"query="+value,
      "facetFilters":facets,
      distinct: 0,
    }, {clickAnalytics: true,
      indexName: environment.deals,
      "params": "query="+value,
      "facetFilters":facets,
      hitsPerPage: 50, 
      distinct: 0 }]};

    return this.http.post(this.configUrl,json, requestOptions).pipe(map((data:any)=>{
      
      let parlons = data.results[0].hits;
      parlons.forEach(element => {
        element.name = element.parlon_name
        element.id = element.parlon_slug
        element.logo = element.logo
        element.branches = element.branches
        element.branch_with_booking_count = element.branch_with_booking_count
        element.matched_services = [];
        element._highlightResult.services.forEach((item) => {
          if (item.matchedWords.length > 0) {
            element.matched_services.push(item.value);            
          }
        });
      });
      

      return {
         deals:data.results[1].hits,
         deal_queryID:data.results[1].queryID,
         dealsNbHits:data.results[1].nbHits,
         dealsPage: data.results[1].page,
         dealsNbPages:data.results[1].nbPages,
         parlonsNbHits:data.results[0].nbHits,
         parlonsPage: data.results[0].page,
         parlonsNbPages:data.results[0].nbPages,
         parlon_queryID:data.results[0].queryID,parlons,
         params:data.results[0].params}
    }));

  }


  searchPage(value,location):Observable<any>{
    if(value=='' && location != 'all'){
      return this.http.get(this.stagingUrl+'algolia-search/'+'null'+location, httpOptions);
    }

    if(location=='all'){
      return this.http.get(this.stagingUrl+'algolia-search/'+value, httpOptions);
    }else
    return this.http.get(this.stagingUrl+'algolia-search/'+value+'/'+location, httpOptions);

  }

  searchPagination(params, page=0, indexName):Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'X-Algolia-API-Key':'df6865d61bda858d789a1aabf4a7632e',
      'X-Algolia-Application-Id':'CWHNPDASIM'
    });
    const requestOptions = { headers: headers };
    
    let json;

    if(indexName == 'deals'){
      json = {"requests":[
      {
        clickAnalytics: true,
        indexName: environment.deals,
        "params": "query="+params['query'],   
        "facetFilters":params['facetFilters'], 
        page,
        distinct: 0,
        hitsPerPage: 50, }
      ]};
    }
    else{
      json = {"requests":[{
        clickAnalytics: true,
        indexName:environment.parlons,
        "params": "query="+params['query'],
        "facetFilters":params['facetFilters'],
        distinct: 0,
        page,
        hitsPerPage: 50,
      }
      ]};
    }   
    
    if(indexName == 'deals'){
      return this.http.post(this.configUrl,json, requestOptions).pipe(map((data:any)=>{                    
        return {deals:data.results[0].hits, deal_queryID:data.results[0].queryID,
          dealsNbHits:data.results[0].nbHits,
          dealsPage: data.results[0].page,
          dealsNbPages:data.results[0].nbPages, 
           params:data.results[0].params}
      }));
    }else{
      return this.http.post(this.configUrl,json, requestOptions).pipe(map((data:any)=>{
      
        let parlons = data.results[0].hits;
        parlons.forEach(element => {
          element.name = element.parlon_name
          element.id = element.parlon_slug
          element.logo = element.logo
          element.branches = element.branches
          element.branch_with_booking_count = element.branch_with_booking_count
          element.matched_services = [];
          element._highlightResult.services.forEach((item) => {
            if (item.matchedWords.length > 0) {
              element.matched_services.push(item.value);              
            }
          });
        });        
        return {parlon_queryID:data.results[0].queryID,parlons,
            parlonsNbHits:data.results[0].nbHits,
            parlonsPage: data.results[0].page,
            parlonsNbPages:data.results[0].nbPages, 
           params:data.results[0].params}
      }));
    }        
  }


  getRecentSearches = ()=>{
    this.recentSearches =JSON.parse(localStorage.getItem("recentSearches") || "[]");
    this.recentSearches = this.recentSearches.splice(0,3);

    return this.recentSearches;
  }


  getRecentViewed = () =>{
    this.recentViewed = JSON.parse(localStorage.getItem("recentViewed") || "[]");

    return this.recentViewed;
  }

  setRecentViewed = (recentViewed, input) => {
    let find = recentViewed.find((item)=>{
      return item.deal_id === input.deal_id;
    });

    if(!find){
      recentViewed.unshift(input);
      localStorage.setItem('recentViewed',JSON.stringify(recentViewed));
    }else{
      let findIndex = recentViewed.findIndex(item=>{
        return item==input
      });
      let temp = recentViewed[0];
      recentViewed[0] = recentViewed[findIndex];
      recentViewed[findIndex] = temp;
      localStorage.setItem('recentViewed',JSON.stringify(recentViewed));
    }

  }

  setRecentSearches = (recentSearches, inputSearch) =>{
    let find = recentSearches.find((item)=>{
      return item === inputSearch;
    });

    if(!find){
      recentSearches.unshift(inputSearch);
      localStorage.setItem('recentSearches',JSON.stringify(recentSearches));
    }else{
      let findIndex = recentSearches.findIndex(item=>{
        return item==inputSearch
      });
      let temp = recentSearches[0];
      recentSearches[0] = recentSearches[findIndex];
      recentSearches[findIndex] = temp;
      localStorage.setItem('recentSearches',JSON.stringify(recentSearches));
    }



  }


  getPopularDeals = () =>{

  }






}
