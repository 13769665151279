import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { CommonElementsModule } from '../../common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BeautyBagDealsComponent } from './beauty-bag-deals.component';
import { BeautyBagNavComponent } from './components/beauty-bag-nav/beauty-bag-nav.component';
import { DealDetailsComponent } from './components/deal-details/deal-details.component';
import { DealFormComponent } from './components/deal-form/deal-form.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CommonElementsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    RouterModule
  ],
  declarations: [BeautyBagDealsComponent, BeautyBagNavComponent, DealDetailsComponent, DealFormComponent]
})
export class BeautyBagDealsModule { }
