<div class="pt-6">
  <!-- Membership services banner -->
  <!-- <div class="flickity flickity-banner mb-6" *ngIf="parlon['active_deal_count'] > 0 || parlon['sells_membership'] == 'Yes'">
    <div class="ratio ratio-banner" >
      <a href="/gifting/{{parlon['slug']}}" *ngIf="parlon['active_deal_count'] > 0">
        <img src="{{resourceLink + gifting['contents']['desktop_image']}}?tr=w-2560" alt="" loading="lazy"
        class="img-fluid w-100">
      </a>
    </div>
    <div class="ratio ratio-banner" *ngIf="parlon['membership_banner_services'] != null && parlon['sells_membership'] == 'Yes'">
      <a href="/membership/{{parlon['slug']}}/{{membership_link}}">
        <img src="{{ resourceLink + parlon['membership_banner_services'] }}?tr=w-1200" alt="{{ parlon['membership_banner_services'] }}" class="img-fluid w-100">
      </a>
    </div>
  </div> -->

  <div infiniteScroll
  [infiniteScrollDistance]="0"  
  [immediateCheck]="true"  
  (scrolled)="onScroll('memberSection')">
  </div>
  <div  class="ratio ratio-banner mb-6" *ngIf="parlon['membership_banner_services'] != null && parlon['sells_membership'] == 'Yes'">
    <a href="/membership/{{parlon['slug']}}/{{membership_link}}">
      <img src="{{ resourceLink + parlon['membership_banner_services'] }}?tr=w-1200" alt="{{ parlon['membership_banner_services'] }}" class="img-fluid w-100">
    </a>
  </div>
  
<!-- && features['deals_&_beauty_bag'] == true -->
  <div class="featured-deals mb-6" *ngIf="selectedDeals.length > 0">
    <div class="d-flex mb-2" infiniteScroll
    [infiniteScrollDistance]="-2"  
    [immediateCheck]="true"  
    (scrolled)="onScroll('dealSection')">
      <h5 class="col h5 fw-semibold mb-0">Save and buy these deals now! Limited time only.
        <!-- <span class="fw- semibold text-secondary">{{ parlon['business_name'] }}</span> -->
      </h5>
		</div>
    <p class="mb-5">
      Purchase these deals online and present voucher when you visit {{ parlon['business_name'] }}. Enjoy!
    </p>

    <div class="row row-cols-1 row-cols-lg-3 gx-lg-5 gy-6">
      <div class="col-md-4" *ngFor="let deal of selectedDeals | slice:0:6; let i = index">
        <a [routerLink]="['/beauty-bag-deals', deal['slug']]">
          <!-- <a [routerLink]="['/deals']"> -->
          <featured-deals-card [featuredDeals]="deal" [index]="i"></featured-deals-card>
        </a>
      </div>
    </div>
    <div class="my-6 text-center">
      <a [routerLink]="['/parlon', parlon['slug'], branch['slug'], 'deals']" class="text-secondary fw-medium px-0 justify-content-end">
        See all deals
      </a>
    </div>
  </div>

  <p class="disclaimer-top"><span class="fw-medium">Disclaimer:</span> Details, prices, and services subject to change without prior notice. The information and photos in the PARLON listing are provided to Parlon by the Parlon Partners, through manual submission or through PARLON Partner Dashboard. PARLON does not guarantee the details, prices, or the availability of the services at {{ parlon['business_name'] }}. Found any wrong information? Feel free to contact Parlon</p>
  <img src="{{ resourceLink + parlon['services_banner'] }}" class="w-100" *ngIf="parlon['services_banner'] != null">

  <book-deals-section *ngIf="branch['menu_display_location'] == 'Top' && branch['menu_display'] == 1" [description]="branch['menu_descriptions']" [deals]="branch['active_deals']"></book-deals-section>

  <div id="service-list" infiniteScroll
  [infiniteScrollDistance]="-1"  
  [immediateCheck]="true"  
  (scrolled)="onScroll('serviceSection')"></div>
  <div  service-looper [data]="data"></div>

  <book-deals-section *ngIf="branch['menu_display_location'] == 'Bottom' && branch['menu_display'] == 1" [description]="branch['menu_descriptions']" [deals]="branch['active_deals']"></book-deals-section>


  <div class="disclaimer-bottom d-lg-none">
    <terms-of-use></terms-of-use>
  </div>
</div>

<!-- <div class="panel">

  <div id="service-list"></div>
  <div service-looper [data]="data"></div>
  <div class="disclaimer">
    <terms-of-use></terms-of-use>
  </div>
</div>
 -->
