import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss']
})
export class TeaserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
