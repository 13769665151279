<!-- Toggle `show` class to show/hide the menu -->

<div class="mega-menu-box bg-white py-6" [ngClass]="data != null ? 'show' : ''" *ngIf="data != null">
	<div class="container">
		<div class="row flex-mega-menu">
			<div class="col-md-4 col-xl-3 col-menu-links">
				<div class="d-flex flex-menu-link-header align-items-center gap-5 mb-5">
					<div class="ratio ratio-1x1">
						<img src="{{resource + data['logo']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
					</div>

					<div>
						<h5 class="h5">{{data['category_name']}}</h5>
						<a [routerLink]="['/all-parlon-deals']" [queryParams]="{c: data['slug']}" class="link-secondary link-arrow fw-semibold">See all {{data['category_name']}} deals</a>
					</div>
				</div>

				<ul class="nav nav-mega-menu-sidebar-links flex-column">
					<li class="nav-item" *ngFor="let subcat of data['subcategories']">
						<a [routerLink]="['/all-parlon-deals']" [queryParams]="{c: data['slug'], sc: subcat['slug']}" class="nav-link link-text fw-medium">{{subcat['category_name']}}</a>
					</li>
				</ul>
			</div>

			<div class="col-md-8 col-xl-9 col-deals">
				<div class="row row-cols-2 row-cols-xl-3 flex-deal-cards">
					<ng-container *ngFor="let card of data['highlights']">
						<div class="col col-deal-card" *ngIf="card['type'] == 'App\\Models\\Deal'">
							<deal-card-template 
								[data]="card" 
								[parlon_name]="card['parlon_name']"
								[locations]="getLocations(card['locations'])">
							</deal-card-template>
						</div>

						<div class="col col-article-card" *ngIf="card['type'] == 'App\\Models\\Blog'">
							<div class="card card-article-template">
								<div class="card-body p-0">
									<div class="ratio ratio-4x3 mt-sm-4 mb-3">
										<img src="{{resource + card['thumbnail']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">
									</div>
	
									<div class="content px-2 px-sm-3 pb-4">
										<a href="{{card['link_out']}}"><p class="fs-6 fw-medium clamp-3 mb-0">{{card['title']}}</p></a>
									</div>
								</div>
							</div>
						</div>
					</ng-container>			

				</div>
			</div>
		</div>
	</div>
</div>