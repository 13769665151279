import { Component, OnInit, Input } from '@angular/core';
import { V } from '@angular/core/src/render3';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AnnouncementbarService } from '../../services/announcementbar/announcementbar.service';

@Component({
  selector: 'app-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss']
})
export class AnnouncementBannerComponent implements OnInit {

  @Input() data;
  visible:boolean= true;

  hidden = [
    {
      'route': '/book-appointment',
      'type': 'dynamic'
    },
    {
      'route': '/all-parlon-deals',
      'type': 'dynamic'
    },
    {
      'route': '/beauty-bash',
      'type': 'dynamic'
    },
    {
      'route': '/booking-payment',
      'type': 'dynamic'
    }
  ];

  constructor(
    private router:Router,
    private AnnouncementbarService:AnnouncementbarService
  ) {
    this.router.events.subscribe((ev) => {
      
      if (ev instanceof NavigationEnd) {
        this.checkRoute();
      }

      });
   }

  ngOnInit() {
    this.checkRoute();
  }

  checkRoute() {
    let visible = true;
    this.hidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          visible = false;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          visible = false;
        }
      } 
    });
    this.AnnouncementbarService.updateAnnouncementBarExistingStatus(visible);
    this.visible = visible;
  }

}
