<section class="section-thank-you-page">
	<div class="container py-8 py-lg-9">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-xl-10">
				<div class="card" *ngIf="isLoaded == true">
					<div class="card-body p-md-6 p-lg-8" *ngIf="isSuccess == true">
						<div class="text-center mb-6">
							<h2 class="h2 fw-semibold mb-6">Thank you for buying, {{response['buyer']['firstname']}}!</h2>
							<div class="row">
								<div class="col-md-8 offset-md-2">
									<div class="text-center">
										<p class="mb-6">The E-Voucher is on its way to your email. <br> Please check your promotions tab or spam folder if you can't find it in your inbox.</p>
									</div>
								</div>
							</div>
							<div class="mb-2">
								<button mat-raised-button (click)="bookModal.open()" color="secondary" class="btn-min-width"> <i class="fa-solid fa-calendar me-2"></i> BOOK APPOINTMENT</button>
							</div>
							<button mat-stroked-button color="secondary" routerLink="/all-parlon-deals" class="btn-min-width">SHOP MORE DEALS</button>
						</div>

						<div *ngIf="response['active_deals'].length > 1">
							<p class="fs-6 text-center fw-medium">You might want to buy these deals</p>
							<div class="row row-cols-1 row-cols-lg-3 justify-content-center gx-lg-6 gy-6">
								<div class="col col-md-9 col-lg" *ngFor="let item of response['active_deals'] | slice:0:3; let i=index">
									<deal-card-recommendation [deal]="item"></deal-card-recommendation>
								</div>
							</div>
						</div>
					</div>

					<div class="card-body p-md-6 p-lg-8" *ngIf="isPending == true">
						<div class="text-center mb-6">
							<h2 class="h2 fw-semibold mb-6">Bestie, your transaction is being processed.</h2>
							<div class="row">
								<div class="col-md-8 offset-md-2">
									<div class="text-center">
										<p class="mb-3">Hello Bestie! We are waiting for a payment confirmation from our payment gateway. It usually takes around 30-60 seconds. Once confirmed you will receive an email containing the vouchers you purchased. You can chose to wait or close this page. </p>
									</div>

									<div class="card mb-3">
										<div class="card-body">
											 <i class="fas fa-circle-notch fa-spin" style="font-size:18px"></i>
										</div>
									</div>
								</div>
							</div>
							<!-- <button mat-raised-button color="secondary" routerLink="/all-parlon-deals">SHOP MORE DEALS</button> -->
						</div>

						<!-- <div *ngIf="response['active_deals'].length > 1">
							<p class="fs-6 text-center fw-medium">You might want to buy these deals</p>
							<div class="row row-cols-1 row-cols-lg-3 justify-content-center gx-lg-6 gy-6">
								<div class="col col-md-9 col-lg" *ngFor="let item of response['active_deals'] | slice:0:3; let i=index">
									<deal-card-recommendation [deal]="item"></deal-card-recommendation>
								</div>
							</div>
						</div> -->
					</div>

					<div class="card-body p-md-6 p-lg-8" *ngIf="isFailed == true">
						<div class="text-center mb-6">
							<h2 class="h2 fw-semibold mb-6">Oh No!</h2>
							<div class="row">
								<div class="col-md-8 offset-md-2">
									<div class="text-center">
										<p class="mb-6">We were unable to charge your account for this transaction, bestie. Kindly double check your details or choose another payment method. Then, try again.</p>
									</div>
								</div>
							</div>

							<button mat-raised-button color="secondary" routerLink="/all-parlon-deals">SHOP MORE DEALS</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<ngx-smart-modal #bookModal identifier="bookModal" [dismissable]="false">
	<ng-container *ngIf="bookModal.hasData()">
	  <div class="modal-body p-2">
		<div class="mb-5 mb-lg-0">
		  <h5 class="h5 fw-semibold mb-4">
			Select the deals you want to book
		  </h5>
		  
		  <ng-container *ngFor="let deal of response['deals_purchased']">
			<div class="booking-deals-card mb-4" [ngClass]="{'disabled': deal['has_booking'] == 'No'}" (click)="toggleBooking(deal)">
				<div class="booking-deals">
					<img src="{{resource + deal['deal_image']}}" class="deal-img">
					<div class="ms-2 deals-info">
						<p class="fw-semibold mb-2 deal-name" style="-webkit-box-orient: vertical;">{{deal['deal_name']}}</p>
						<div class="d-flex">
							<img src="{{resource + deal['parlon_logo']}}" class="parlon-logo shadow-sm">
							<div class="parlon-details ms-3">
								<p class="mb-0">{{deal['parlon_name']}}</p>
								<p class="mb-1">{{deal['branch_name']}}</p>
							</div>
						</div>
					</div>
					<div class="deal-checkbox ms-2">
						<span class="d-flex" *ngIf="deal['selected']; else notSelected" >
							<i class="fa-solid fa-circle-check"></i>
						</span>
						<ng-template #notSelected>
							<span>
							</span>
						</ng-template>
						
					</div>
				</div>
				<p class="mb-0 text-muted" *ngIf="deal['has_booking'] == 'No'"><small>This salon does not accept bookings via Parlon. Kindly reach out to them directly for booking reservations</small></p>
			  </div>
		  </ng-container>
		  

		<div class="text-center">
			<div class="mb-2">
				<button mat-raised-button color="secondary" class="btn-min-width" (click)="bookAppointments()"> <i class="fa-solid fa-calendar me-2"></i> BOOK APPOINTMENT</button>
			</div>
			<button mat-stroked-button color="secondary" (click)="bookModal.close()" class="btn-min-width">CANCEL</button>
		</div>

		<p class="mt-5">
			Note: You can always book an appointment with the deal you didn’t select by going to the salon’s profile and selecting the deal you bought, then clicking the “Book Now” button
		</p>
		  
		</div>
	  </div>
	</ng-container>
  </ngx-smart-modal>
