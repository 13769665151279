import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { CommonElementsModule } from '../../common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BeautyBagCheckoutComponent } from './beauty-bag-checkout.component';
import { BagItemsComponent } from './components/bag-items/bag-items.component';
import { BagDetailsComponent } from './components/bag-details/bag-details.component';
import { BagPaymentComponent } from './components/bag-payment/bag-payment.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { NgxCcModule } from 'ngx-cc';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { RouterModule } from '@angular/router';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { RedeemerDetailsComponent } from './components/redeemer-details/redeemer-details.component';
import { CandiesFormComponent } from './components/candies-form/candies-form.component';
import { ParlonCandiesComponent } from './components/parlon-candies/parlon-candies.component';
import { BrandedCandiesComponent } from './components/branded-candies/branded-candies.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CommonElementsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCcModule,
    RouterModule,
    NgxSmartModalModule
  ],
  declarations: [BeautyBagCheckoutComponent, BagItemsComponent, BagDetailsComponent, BagPaymentComponent, CreditCardFormComponent, PromoCodeComponent, RedeemerDetailsComponent, CandiesFormComponent, ParlonCandiesComponent, BrandedCandiesComponent]
})
export class BeautyBagCheckoutModule { }
