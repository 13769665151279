<!-- <app-new-search-nav-bar></app-new-search-nav-bar> -->

<div class="bg-white py-8" *ngIf="(parlons.length == 0 && deals.length == 0) && isLoading == true">
	<search-result-not-found-template></search-result-not-found-template>
</div>

<section class="bg-white py-6 py-md-8">
	<div class="container" id="deals">
		<div class="d-grid gap-8">

			<!-- Deal results -->			
			<div *ngIf="deals.length > 0">
				<h4 class="h4 fw-semibold mb-5" *ngIf="searchParam==null">Deals</h4>
				<!-- {{searchParam?searchParam: null}} -->
				<h4 class="h4 fw-semibold mb-5" *ngIf="searchParam">Deal results for &ldquo;{{ searchParam }}&rdquo;</h4>
				<h4 class="h4 fw-semibold mb-5" *ngIf="searchParam== null && location != 'all' ">Deals in &ldquo;{{ location[0].toUpperCase() + location.slice(1)}}&rdquo;</h4>

				<div class="row g-5">
					<div class="col-12 col-md-6 col-lg-4"
						*ngFor="let deal of deals | paginate: deal_config; index as i">
							<search-result-deal-card-template							
							 [position]="(i+1) + (currentPageDeals) * 50"
							 [isLoggedIn]="isLoggedIn"
							 [userID]="userId" 	
							 [deal]="deal">
							</search-result-deal-card-template>
					</div>
				</div>

				<div class="d-flex justify-content-center mt-6">
					<pagination-template #p="paginationApi" (pageChange)="pageChangeEventDeals($event)" [id]="deal_config.id" >
						<div class="ngx-pagination">
							<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
								<a *ngIf="!p.isFirstPage()" (click)="p.previous()">
								</a>
							</div>

							<div class="page" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
								<a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
									<span>{{ page.label }}</span>
								</a>
								<div *ngIf="p.getCurrent() === page.value">
									<span>{{ page.label }}</span>
								</div>
							</div>

							<div class="pagination-next" [class.disabled]="p.isLastPage()">
								<a *ngIf="!p.isLastPage()" (click)="p.next()">
								</a>
							</div>
						</div>
					</pagination-template>
				</div>
			</div>

			<!-- Parlon results -->
			<div *ngIf="parlons.length > 0" id="parlons">
				<h4 class="h4 fw-semibold mb-5" *ngIf="searchParam==null">Parlons</h4>
				<h4 class="h4 fw-semibold mb-5" *ngIf="searchParam">Parlons with &ldquo;{{ searchParam }}&rdquo;</h4>
				<h4 class="h4 fw-semibold mb-5" *ngIf="searchParam == null && location != 'all' ">Parlons in &ldquo;{{ location[0].toUpperCase() + location.slice(1)}}&rdquo;</h4>
				<div class="row g-5">
					<div class="col-12 col-md-6 col-lg-4"
						*ngFor="let parlon of parlons | paginate: parlon_config">
							<search-result-parlon-card-template [parlon]="parlon"></search-result-parlon-card-template>
					</div>
				</div>

				<div class="d-flex justify-content-center mt-6">
					<pagination-template #pg="paginationApi" [id]="parlon_config.id" (pageChange)="pageChangeEventParlons($event)" >
						<div class="ngx-pagination">
							<div class="pagination-previous" [class.disabled]="pg.isFirstPage()">
								<a *ngIf="!pg.isFirstPage()" (click)="pg.previous()">
								</a>
							</div>

							<div class="page" *ngFor="let page of pg.pages" [class.current]="pg.getCurrent() === page.value">
								<a (click)="pg.setCurrent(page.value)" *ngIf="pg.getCurrent() !== page.value">
									<span>{{ page.label }}</span>
								</a>
								<div *ngIf="pg.getCurrent() === page.value">
									<span>{{ page.label }}</span>
								</div>
							</div>

							<div class="pagination-next" [class.disabled]="pg.isLastPage()">
								<a *ngIf="!pg.isLastPage()" (click)="pg.next()">
								</a>
							</div>
						</div>
					</pagination-template>
				</div>
			</div>
		</div>
	</div>
</section>