<section class="section-thank-you-page">
	<div class="container py-8 py-lg-9">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11 col-xl-10">
				<div class="card" *ngIf="isLoaded == true">
					<div class="card-body p-md-6 p-lg-8" *ngIf="isSucess == true">
						<div class="text-center mb-6">
							<h2 class="h2 fw-semibold mb-6">Your payment amounting to {{response['amount']}} has been received. Thank you for using Parlon Pay at {{response['parlon']}} - {{response['branch']}}.</h2>
							<a [routerLink]="['/parlon',response['parlon_slug']]" mat-flat-button color="secondary" class="text-uppercase px-6 py-3">Back to {{response['parlon']}}</a>
						</div>

						<div *ngIf="response['active_deals'].length > 1">
							<p class="fs-6 text-center fw-medium">You might want to buy these deals from <a [routerLink]="['/parlon',response['parlon_slug']]" class="link-secondary">{{response['parlon']}}</a></p>
							<div class="row row-cols-1 row-cols-lg-3 justify-content-center gx-lg-6 gy-6">
								<div class="col col-md-9 col-lg" *ngFor="let item of response['active_deals'] | slice:0:3; let i=index">
									<deal-card-recommendation [deal]="item"></deal-card-recommendation>
								</div>
							</div>
						</div>
					</div>

					<div class="card-body p-md-6 p-lg-8" *ngIf="isPending == true">
						<div class="text-center mb-6">
							<h2 class="h2 fw-semibold mb-6">Your payment is being processed. Thank you for using Parlon Pay.</h2>
						</div>

						<div *ngIf="response['active_deals'].length > 1">
							<p class="fs-6 text-center fw-medium">You might want to buy these deals from <a [routerLink]="['/parlon',response['parlon_slug']]" class="link-secondary">{{response['parlon']}}</a></p>
							<div class="row row-cols-1 row-cols-lg-3 justify-content-center gx-lg-6 gy-6">
								<div class="col col-md-9 col-lg" *ngFor="let item of response['active_deals'] | slice:0:3; let i=index">
									<deal-card-recommendation [deal]="item"></deal-card-recommendation>
								</div>
							</div>
						</div>
					</div>


					<div class="card-body p-md-6 p-lg-8" *ngIf="isFailed == true">
						<div class="text-center mb-6">
							<h2 class="h2 fw-semibold mb-6">Oh No!</h2>
							<div class="row">
								<div class="col-md-8 offset-md-2">
									<div class="text-center">
										<p class="mb-6">We were unable to charge your account for this transaction, bestie. Kindly double check your details or choose another payment method. Then, try again.</p>
									</div>
								</div>
							</div>

							<a [routerLink]="['/parlon',response['parlon_slug']]" mat-flat-button color="secondary" class="text-uppercase px-6 py-3">Back to {{response['parlon']}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
