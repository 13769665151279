import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'militaryToStandardTime' })
export class MilitaryToStandardTimePipe implements PipeTransform {
  transform(dateTime: string): string {
    try {
      const timePart = dateTime.split(' ')[1];
      const hoursMinutes = timePart.split(':');
      const hours = parseInt(hoursMinutes[0], 10);
      const minutes = parseInt(hoursMinutes[1], 10);

      const period = hours < 12 ? 'AM' : 'PM';

      let standardHours = hours % 12;
      if (standardHours === 0) {
        standardHours = 12;
      }

      return `${standardHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    } catch (error) {
      return 'Invalid input. Please provide a valid date-time (yyyy-MM-dd HH:mm:ss.SSSSSS).';
    }
  }
}
