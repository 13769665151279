import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import {  FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, ParamMap,Router } from '@angular/router';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { RegisterService } from '../../services/register/register.service';
import { AlreadyVerifiedDialogComponent } from '../../components/already-verified-dialog/already-verified-dialog.component';
import { Subscription } from 'rxjs';
import { NetcoreService } from '../../services/netcore/netcore.service';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss']
})
export class OtpFormComponent implements OnInit, OnDestroy {

  constructor(
    private fb:FormBuilder,
    private otpService:RegisterService,
    private route: ActivatedRoute,
    private router:Router,
    private _snackBar: MatSnackBar,
    private dialog:MatDialog,
    private userService:UserAccountService,
    private NetcoreService:NetcoreService ) { }
  email = '';
  // @ViewChildren('digits') digits:QueryList<any>;
  @Output() otpEmitter = new EventEmitter();


  otpForm:FormGroup = this.fb.group({
    digit_1:['', [Validators.required,Validators.minLength(6),Validators.pattern('^[0-9]+$')]],
    // digit_2:['', [Validators.required,Validators.maxLength(1)]],
    // digit_3:['', [Validators.required,Validators.maxLength(1)]],
    // digit_4:['', [Validators.required,Validators.maxLength(1)]],
    // digit_5:['', [Validators.required,Validators.maxLength(1)]],
    // digit_6:['', [Validators.required,Validators.maxLength(1)]],
  })

  get d1(){  return this.otpForm.get('digit_1');}
  // get d2(){  return this.otpForm.get('digit_2');}
  // get d3(){  return this.otpForm.get('digit_3');}
  // get d4(){  return this.otpForm.get('digit_4');}
  // get d5(){  return this.otpForm.get('digit_5');}
  // get d6(){  return this.otpForm.get('digit_6');}

  submitted = false;
  showErrors = false;
  errorsValidation:any;

  getExpiration;
  expired:boolean = false;
  submitOtp = false;
  countSubmit=0;


  $userLoggedIn:Subscription|undefined;
  $router:Subscription|undefined;
  $confirmOtp:Subscription|undefined;
  $resendOtp:Subscription|undefined;

  bagId = localStorage.getItem('bagId');


  ngOnDestroy(){
    if(this.$userLoggedIn)
      this.$userLoggedIn.unsubscribe()
    if(this.$router)
      this.$router.unsubscribe()
    if(this.$confirmOtp)
      this.$confirmOtp.unsubscribe()
    if(this.$resendOtp)
      this.$resendOtp.unsubscribe()
  }

  openSnackBar(message:string) {
    this._snackBar.open(message,'OK',{duration:2000});
  }

  onSubmit(){
    this.submitted = true;
    this.showErrors = false;
    if (this.otpForm.invalid) {
      return;
    }
    let json = {
      email:sessionStorage.getItem("otpEmail"),
      otp:  this.d1.value.toString()
    }
    if(this.bagId != null)
      json['bag_id'] = this.bagId;

    this.$confirmOtp = this.otpService.confirmOTP(json).subscribe((data:any)=>{
      this.submitted = false;
      if(data.success == true){
        let { first_name, last_name, email, contact_number } = data.user;

        let candies ={
          usable_candies:data.candies.usable_candies
        };

        let jsonData = data;

        this.NetcoreService.identify({
          'email': data['user']['email']
        })
        this.NetcoreService.dispatch('register user', {
          'firstname':data['user']['first_name'],
          'lastname':data['user']['last_name'],
          'email':data['user']['email'],
          'contact_number':data['user']['contact_number'],
          'birthday':data['user']['birthday'],
          'type':'traditional'
        });

        this.userService.setOtpUser(jsonData);
        this.userService.setActiveUser(data);
        // this.router.navigate(['/otp-success'], {skipLocationChange:true});
        this.otpEmitter.emit(true);
      }else{

        if(data.message=="Your account has already been verified" ||data.message=="OTP already expired." || data.message=="Invalid OTP."){
          this.showErrors = true;
          this.errorsValidation = data.message;
          this.openSnackBar(this.errorsValidation);
        }else{
          this.showErrors = true;
          this.errorsValidation = data.data;
          this.errorsValidation = Object.keys(this.errorsValidation).map((item)=>{
            return this.errorsValidation[item]
          });
        }

      }
    })
  }

  resendOtp(){
    let json = {email:sessionStorage.getItem("otpEmail")};
    this.showErrors = false;
    this.submitOtp = true;
    this.$resendOtp = this.otpService.resendOTP(json).subscribe((data:any)=>{
      this.submitOtp = false;
      if(data.success==true){
        this.submitted = false;
        this.countSubmit++;
        this.otpForm.reset();
        this.otpForm.get('email').patchValue(this.email);

        this.expired= false;
        const now = new Date()
        const expiry =  now.setMinutes(now.getMinutes() + 5);
        localStorage.setItem('otp-expiration',JSON.stringify( expiry));
        this.getExpiration =JSON.parse(localStorage.getItem('otp-expiration'));
        const nowTime = new Date().getTime();
        setTimeout(()=>{
          this.expired = true;
        },this.getExpiration - nowTime );

      }else{
        this.showErrors = true;
        this.errorsValidation = data.message;
      }
    }, (error:HttpErrorResponse)=>{
      this.submitOtp = false;
      this.showErrors = true;
      this.errorsValidation = error.error.data;
      this.errorsValidation = Object.keys(this.errorsValidation).map((item)=>{
        return this.errorsValidation[item]
      });
  })
}



 tabChange = function(val:any,d:any){
    let ele:any =this.digits['_results'];
    if(d.value != ''){
      if(val!=6)
      ele[val].nativeElement.focus()
    }else if(d.value == ''){
      if(val!=1)
      ele[val-2].nativeElement.focus()
    }
 }



  ngOnInit() {
    this.email = sessionStorage.getItem("otpEmail");
    this.$router =  this.route.paramMap.subscribe((params: ParamMap) => {
      this.email = params.get('email');
     // this.otpForm.get('email').patchValue(this.email);
    });

    let json = {
      email:sessionStorage.getItem("otpEmail"),
      otp:  '111111'
    }

    // this.$confirmOtp = this.otpService.confirmOTP(json).subscribe((data:any)=>{
    //   if(data.success==false){
    //     if(data.message=='Your account has already been verified.'){
    //       const dialogRef =this.dialog.open(AlreadyVerifiedDialogComponent,{disableClose:true, data:data.message})
    //     }
    //   }
    // })

    this.getExpiration =JSON.parse(localStorage.getItem('otp-expiration'));
    const now = new Date().getTime();
    setTimeout(()=>{
      this.expired = true;
    },this.getExpiration - now );
  }

}
