<div *ngIf="isLoaded == true" id="form-container">
  <!-- Form -->
  <div *ngIf="step == 'form'">
    <section class="pos-relative mb-7">
      <div class="ratio ratio-banner">
        <img src="{{resource + data['contents']['mobile_image']}}?tr=w-2560" alt="" loading="lazy"
          class="img-fluid mobile obj-fit-cover">
        <img src="{{resource + data['contents']['desktop_image']}}?tr=w-2560" alt="" loading="lazy"
          class="img-fluid desktop obj-fit-cover">
      </div>
    </section>
    <div class="container main-section">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div *ngIf="salon == null">
            <h2 class="h2 fw-bold mb-2">Parlon Gifting</h2>
            <p class="mb-7">Send a Parlon gift code and let them choose the ultimate self-love experience</p>
          </div>
          <div *ngIf="salon != null" class="text-center">
            <div class="salon_logo">
              <img src="{{resource + salon['logo']}}" class="w-100">
            </div>
            <h2 class="h2 fw-bold mb-2">Buy Parlon Gift Codes for  {{salon['business_name']}} Parlon Deals</h2>
            <p class="mb-2">Send Parlon Gift Codes and let them choose the ultimate self-love experience from {{salon['business_name']}} </p>
            <p class="mb-7">
              <i class="fa-light fa-note"></i> Parlon Gift Codes that will be generated will be for exclusive use for {{salon['business_name']}} Parlon Deals
            </p>
          </div>
          <div>
            <div class="step-header">
              <div class="step-icon">1</div>
              <p class="step-title">Your lucky giftees</p>
            </div>
            <form>
              <div class="giftee-item" *ngFor="let giftee of giftee_count_arr; let i = index">
                <div [formGroup]="gifteesFormArray.controls[i]">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-4">Giftee {{i + 1}}</p>
                    <a class="link-primary fw-bold" *ngIf="giftee_count_arr.length > 1" (click)="removeGiftee(i)">
                      <i class="fa-regular fa-trash"></i>
                    </a>
                  </div>
                  <div class="row mb-5">
                    <div class="col-6 col-md-4">
                      <mat-form-field appearance="fill" class="w-100 no-label">
                        <mat-select name="denomination_value" required
                          [formControl]="gifteesFormArray.controls[i].controls.denomination">
                          <mat-option *ngFor="let denomination of denominations" [value]="denomination" ng-selected>
                            ₱{{ denomination | number }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                      <div class="d-flex align-items-center">
                        <p class="mb-0">QTY</p>
                        <mat-form-field appearance="fill" class="w-100 no-label mx-3">
                          <mat-select name="quantity" [formControl]="gifteesFormArray.controls[i].controls.quantity">
                            <mat-option *ngFor="let q of quantity" [value]="q" ng-selected>
                              {{ q }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">

                      <div class="mb-2">

                        <mat-form-field appearance="fill" class="w-100 ">
                          <mat-label>First name</mat-label>
                          <input matInput placeholder="First Name" name="first_name" required
                            [formControl]="gifteesFormArray.controls[i].controls.first_name">
                          <!-- <mat-error *ngIf="details.first_name.errors?.required">The first name field is required.</mat-error> -->
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-100 ">
                          <mat-label>Last name</mat-label>
                          <input matInput placeholder="Last Name" name="last_name" required
                            [formControl]="gifteesFormArray.controls[i].controls.last_name">
                          <!-- <mat-error *ngIf="details.last_name.errors?.required">The last name field is required.</mat-error> -->
                        </mat-form-field>
                      </div>

                      <div>

                        <mat-form-field appearance="fill" class="w-100 ">
                          <mat-label>Email</mat-label>
                          <input matInput placeholder="Email" id="email" name="email" required
                            [formControl]="gifteesFormArray.controls[i].controls.email">
                          <!-- <mat-error *ngIf="details.email?.errors">
                            <div *ngIf="details.email.errors?.required">The email field is required.</div>
                            <div *ngIf="details.email.errors?.email">Email must be a valid email address</div>
                          </mat-error> -->
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-100 ">
                          <mat-label>Mobile Number</mat-label>
                          <input type="number" matInput placeholder="Mobile Number" name="contact_number"
                            (keypress)="numberOnly($event)" required
                            [formControl]="gifteesFormArray.controls[i].controls.contact_number">
                          <!-- <mat-error *ngIf="details.contact_number.errors?.required">The contact number field is required.
                          </mat-error> -->
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p class="mb-3 select-card-{{i}}">Choose a card</p>
                      <div class="flickity flickity-giftee flickity-giftee-{{i}} flickity-overflow row gx-4">
                        <div class="slide gift-card" *ngFor="let card of data['gift_cards']; let card_index = index"
                          (click)="selectCard(i, card_index, card)">
                          <div class="check">
                            <i class="fa-sharp fa-solid fa-circle-check"></i>
                          </div>
                          <img src="{{resource + card['file']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover ">
                          <p class="text-muted text-center my-3">
                            {{card['name']}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <p class="add-receiver mb-6" (click)="addGiftee()">
              <i class="fa-sharp fa-solid fa-circle-plus"></i>
              <span class="mx-3">ADD ANOTHER RECEIVER</span>
            </p>
          </div>
          <div class="mb-6">
            <div class="step-header">
              <div class="step-icon">2</div>
              <p class="step-title">Your details</p>
            </div>
            <div class="row">
              <div class="col" [formGroup]="giftForm">

                <div class="mb-2" formGroupName="personalDetailsFormGroup">

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>First name</mat-label>
                    <input matInput placeholder="First Name" name="first_name" required formControlName="first_name">
                    <!-- <mat-error *ngIf="details.first_name.errors?.required">The first name field is required.</mat-error> -->
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Last name</mat-label>
                    <input matInput placeholder="Last Name" name="last_name" required formControlName="last_name">
                    <!-- <mat-error *ngIf="details.last_name.errors?.required">The last name field is required.</mat-error> -->
                  </mat-form-field>
                </div>

                <div formGroupName="personalDetailsFormGroup">

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" id="email" name="email" required formControlName="email">
                    <!-- <mat-error *ngIf="details.email?.errors">
                        <div *ngIf="details.email.errors?.required">The email field is required.</div>
                        <div *ngIf="details.email.errors?.email">Email must be a valid email address</div>
                      </mat-error> -->
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="w-100 ">
                    <mat-label>Mobile Number</mat-label>
                    <input type="number" matInput placeholder="Mobile Number" name="contact_number"
                      formControlName="contact_number" (keypress)="numberOnly($event)" required>
                    <!-- <mat-error *ngIf="details.contact_number.errors?.required">The contact number field is required.
                      </mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <button mat-flat-button color="secondary" class="p-1 w-100 btn-next" type="button" (click)="formNext()">
            NEXT
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Summary -->
  <div class="container py-3" *ngIf="step == 'summary'">
    <div class="row row-main-layout gx-lg-8 gy-6 gy-lg-0">
      <div class="col-12 col-xl-8 col-content content">

        <h2 class="h3 fw-semibold mb-2 summary-section">Summary</h2>
        <p *ngIf="salon != null">
          You are buying a Parlon Gift Code that can be exclusively used for   <strong class="text-primary">{{salon['business_name']}}</strong> Parlon deals.
        </p>
        <div class="p-2"></div>
        <ng-container *ngFor="let data of giftees$ | async;let i = index" class="mb-4">
          <div class="giftee-summary">
            <div class="d-flex mb-1 align-items-center">
              <div>
                <img src="{{resource + data['card']['file']}}" width="110">
              </div>
              <div class="mx-4 mb-3">
                <h5 class="h5 fw-semibold mb-0">₱{{data['denomination'] | number }} for {{data['first_name']}}</h5>
                <p class="mb-0">QTY: {{data['quantity']}}</p>
                <p class="mb-0 text-muted">{{data['email']}} / {{data['contact_number']}}</p>
              </div>
            </div>
            <a (click)="removeGiftee(i,true)" class="mx-2" *ngIf="(giftees$ | async).length > 1">
              <i class="fa-regular fa-trash"></i>
            </a>
          </div>
        </ng-container>

        <hr style="border-top: 2px solid;" class="my-6">
        <div class="d-md-none mb-6">
          <table class="table table-borderless table-sm">
            <tbody>
              <tr>
                <td class=" ps-0 fw-semibold h5">Total<span></span></td>
                <td class="text-end tabular-nums pe-0 fw-semibold h5">₱{{total | number}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="card bg-gray-fa rounded-3 my-5 mt-lg-0 mb-0 ">
          <div class="card-body">
            <p class="mb-0">Notes here. Text to follow. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut habitant vel mattis. Donec consequat sapien, molestie non fames aliquet pellentesque dapibus at. Nisi non faucibus nulla feugiat. Leo massa odio ligula amet, sed facilisis nisl.</p>
          </div>
        </div> -->
        <form [formGroup]="giftForm">
          <mat-vertical-stepper [linear]="isLinear" #stepper>


            <mat-step formGroupName="paymentFormGroup">
  
  
              <ng-template matStepLabel><span class="payment-checkpoint">Payment</span></ng-template>
  
              <div class="payment-method-accordion">
                <mat-accordion>
                  <mat-radio-group>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header (click)="paymentOptionClick('parlon_credit_card')">
                        <div class="row row-payment-channel gx-4">
                          <div class="col">
                            <mat-radio-button value="parlon_credit_card">
                              Credit/Debit Card
                            </mat-radio-button>
                          </div>
                          <div class="col-auto">
                            <div class="card-logo">
                              <img src="/assets/images/payment-logos/visa.png" alt="visa-and-mastercard">
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel-header>
  
                      <div>
                        <p class="mb-2">Your payment shall be processed safely and securely by a third-party payment
                          gateway
                          provider. For your security, we do not store your credit card information.</p>
                        <!-- CREDIT CARD FORM -->
                        <credit-card-form [paymentFormGroup]="giftForm.get('paymentFormGroup')"></credit-card-form>
                      </div>
  
                    </mat-expansion-panel>
  
                    <mat-expansion-panel class="nopad">
                      <mat-expansion-panel-header (click)="paymentOptionClick('parlon_gcash')">
                        <div class="row row-payment-channel gx-4">
                          <div class="col">
                            <mat-radio-button value="parlon_gcash">
                              GCash
                            </mat-radio-button>
                          </div>
                          <div class="col-auto">
                            <div class="card-logo">
                              <img src="/assets/images/payment-logos/gcash.png" alt="gcash">
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
  
                    <mat-expansion-panel class="nopad">
                      <mat-expansion-panel-header (click)="paymentOptionClick('parlon_grabpay')">
                        <div class="row row-payment-channel gx-4">
                          <div class="col">
                            <mat-radio-button value="parlon_grabpay">
                              Grab Pay
                            </mat-radio-button>
                          </div>
                          <div class="col-auto">
                            <div class="card-logo">
                              <img src="/assets/images/payment-logos/grabpay.png" alt="grabpay">
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel-header>
                      <!-- CONTENT -->
                    </mat-expansion-panel>
  
                    <mat-expansion-panel class="nopad" *ngIf="total >=3000">
                      <mat-expansion-panel-header (click)="paymentOptionClick('parlon_ipp')">
                        <div class="row row-payment-channel gx-4">
                          <div class="col">
                            <mat-radio-button value="parlon_ipp">
                              Metrobank Installment <br class="d-md-none"> (3 months with 0% interest)
                            </mat-radio-button>
                          </div>
                          <div class="col-auto">
                            <div class="card-logo">
                              <img src="/assets/images/payment-logos/metrobank.png" alt="metrobank">
                            </div>
  
                          </div>
                        </div>
                      </mat-expansion-panel-header>
  
                    </mat-expansion-panel>
  
                    <mat-expansion-panel class="nopad">
                      <mat-expansion-panel-header (click)="paymentOptionClick('parlon_webpay')">
                        <div class="row row-payment-channel gx-4">
                          <div class="col">
                            <mat-radio-button value="parlon_webpay">
                              Direct Debit <br class="d-md-none"> with UBP or BPI
                            </mat-radio-button>
                          </div>
                          <div class="col-auto">
                            <div class="d-flex">
                              <div class="card-logo">
                                <img src="/assets/images/payment-logos/bpi.png" alt="bpi" width="50">
                              </div>
                              <div class="card-logo">
                                <img src="/assets/images/payment-logos/unionbank.png" alt="metrobank" width="60">
                              </div>
                            </div>
  
                          </div>
                        </div>
                      </mat-expansion-panel-header>
  
  
                    </mat-expansion-panel>
  
                    <div class="mt-3 mb-6">
                      <mat-checkbox id="eVoucherPolicy" class="eVoucherPolicy" color="primary"
                        formControlName="eVoucherPolicy">
                        By clicking the Pay Now button, I confirm that I have read and agree to  <a
                          href="https://legal.parlon.ph/post/terms-of-use" target="_blank">Parlon Terms of Use</a> and <a
                          href="https://legal.parlon.ph/post/privacy-notice" target="_blank">Parlon Privacy Notice</a>.
                      </mat-checkbox>
                    </div>
  
                    <div class="p-4 d-md-none"></div>
  
                    <div class="d-grid pay-btn">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <td class=" ps-0  h5">
                              <p class="mb-0 fw-semibold">Total</p>
                              <p class="mb-0 text-muted"><small>{{giftee_count}} Giftee<span *ngIf="giftee_count > 1">s</span></small></p>
                            </td>
                            <td class="text-end tabular-nums pe-0 fw-semibold h5">₱{{total | number}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <button mat-flat-button color="secondary" class="p-3 " type="submit" (click)="onSubmit()">
                        <span>PAY NOW</span>
                        <mat-icon *ngIf="loading">
                          <mat-spinner color="accent" diameter="20">
                          </mat-spinner>
                        </mat-icon>
                      </button>
                    </div>
  
  
                  </mat-radio-group>
                </mat-accordion>
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </form>

      </div>

      <div class="col-12 col-xl-4 col-sidebar d-none d-lg-block">
        <div class="sidebar">
          <div class="sidebar__inner">
            <div class="card bg-gray-fa rounded-3 my-5 mt-lg-0 mb-0">
              <div class="card-body">
                <table class="table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td class=" ps-0 fw-semibold h5">Total<span></span></td>
                      <td class="text-end tabular-nums pe-0 fw-semibold h5">₱{{total | number}}</td>
                    </tr>
                  </tbody>
                </table>
                <button mat-flat-button color="secondary" class="p-1 w-100 mt-5" type="button" (click)="onSubmit()">
                  <span>PAY NOW</span>
                  <mat-icon *ngIf="loading">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<form id="cc-form" style="display: none;">
  <input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br/>
  <input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br/>
  <input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br/>
  <input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
</form>