import { Component, OnInit } from '@angular/core';
import { BookingService } from '../../services/booking/booking.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-book-appointment-message',
  templateUrl: './book-appointment-message.component.html',
  styleUrls: ['./book-appointment-message.component.scss']
})
export class BookAppointmentMessageComponent implements OnInit {

  isLoaded = false;
  bookingStatus:string = 'Pending';
  transactionStatus:string = '';
  bookingType:string = 'nonPrepay';
  isPending = false;
  refId: string;
  currency = environment.currencySign;
  resource = environment.imagekit; 
  selectedBooking = JSON.parse(localStorage.getItem('selectedBooking')) || {};

  constructor(
    private BookingService: BookingService
  ) { }

  ngOnInit() {
    let url_string = window.location.href;
    var url = new URL(url_string);
    this.isLoaded = false;
    this.refId = url.searchParams.get("reference");
    if(this.refId == 'success') {
      this.bookingStatus = url.searchParams.get("status");
      this.transactionStatus = 'success';
      this.isLoaded = true;
    } else {
      this.BookingService.getStatus(this.refId).subscribe((data: {}) => {
        this.transactionStatus = data['status'];
        this.bookingStatus = data['booking_details']['status'];
        this.selectedBooking['salon'] = data['booking_details']['parlon_details']['name'];
        this.selectedBooking['branch'] = data['booking_details']['parlon_details']['branch_name'];
        this.selectedBooking['logo'] = data['booking_details']['parlon_details']['logo'];
        this.selectedBooking['date'] = data['booking_details']['date'];
        this.selectedBooking['time'] = data['booking_details']['time'];
        this.selectedBooking['price'] = data['booking_details']['price'];
        this.selectedBooking['user'] = data['booking_details']['customer_details']['first_name'];
        this.bookingType = data['booking_details']['booking_type'];
        this.isLoaded = true;
      });
    }
    
  }

}
