import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DrawerService } from '../../services/drawer/drawer.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  constructor(
    private drawerService:DrawerService,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private NgxSmartModalService:NgxSmartModalService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  onCancel(): void {
    this.dialogRef.close();
  }

  errors:any;
  email:string="";

  isEmailUnique:boolean = true;
  
  forgotPassword(){
    this.NgxSmartModalService.closeLatestModal();
    this.drawerService.setForgotPassword(true);
  }

  login(){
    this.NgxSmartModalService.closeLatestModal();
    this.drawerService.setForgotPassword(false);
  }



  ngOnInit() {

    // console.log(this.errors);

    this.email = this.data.email;

    this.errors = Object.keys(this.data.error).map((item)=>{
      if(this.data.error[item]=='The email has already been taken.'){
        this.isEmailUnique = false;
      }
      return this.data.error[item]
    });
    
    

  }

}
