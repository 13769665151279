import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { FormsModule } from '@angular/forms';
import { UserRegistrationComponent } from './user-registration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { UserRegistrationRouterModule } from "./user-registration-routing.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    UserRegistrationRouterModule
  ],
  exports: [UserRegistrationComponent],
  declarations: [UserRegistrationComponent]
})
export class UserRegistrationModule { }
