import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const endpoint = environment.api;
let authData = '';
let httpOptions = {};

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (!isPlatformBrowser(platformId)) {
        authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
      } else {
        authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
      }
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authData
        })
      }
    }

    private extractData(res: Response) {
      let body = res;
      return body || { };
    }

    private contentData = new BehaviorSubject<Array<any>>([]);
    pulledContent = this.contentData.asObservable();

    private ogData = new BehaviorSubject<Array<any>>([]);
    ogContent = this.ogData.asObservable();

    private features = new BehaviorSubject<Array<any>>([]);
    featuresAvailability = this.features.asObservable();
    

    getHomePageBuilder(instanceId): Observable<any> {
      let params = {};
      if (instanceId !== null) {
        params = {
          section_ids: instanceId
        };
      }

      return this.http.post(endpoint + 'homepage/build', params)
        .pipe(map(this.extractData));
    }

    getHomepageSequence(): Observable<any> {
      return this.http.get(endpoint + 'homepage/get-homepage-section-sequence', httpOptions).pipe(
        map(this.extractData));
    }

    getHomepageContent(): Observable<any> {
      return this.http.get(endpoint + 'homepage/get-all-contents', httpOptions).pipe(
        map(this.extractData));
    }

    getContentGlobal(): Observable<any> {
      return this.http.get(endpoint + 'global/get-sitewide-contents', httpOptions).pipe(
        map(this.extractData));
    }

    getParlonListing(): Observable<any> {
      return this.http.get(endpoint + 'parlonpage/get-all-contents', httpOptions).pipe(
        map(this.extractData));
    }

    getBookingListing(): Observable<any> {
      return this.http.get(endpoint + 'bookingpage/get-all-contents', httpOptions).pipe(
        map(this.extractData));
    }

    getOgContent(): Observable<any> {
      return this.http.get(endpoint + 'og/content/get-og-contents', httpOptions).pipe(
        map(this.extractData));
    }

    // settings
    getPageBanner(): Observable<any> {
      return this.http.get(endpoint + 'settings/site-banner', httpOptions).pipe(
        map(this.extractData),
        catchError(e => {
          return of (null)
        })
      )
    }

  getFeatures(): Observable<any> {
    return this.http.get(endpoint + 'feature-availability', httpOptions).pipe(
      map(this.extractData),
      catchError(e => {
        return of (null)
      })
    )
  }

    getCategories(fields = ""): Observable<any> {
      return this.http.get(endpoint + 'categories' + fields, httpOptions).pipe(
        map(this.extractData));
    }

    getCategoriesDeal(fields = ""): Observable<any> {
      return this.http.get(endpoint +'deals/'+ 'categories' + fields, httpOptions).pipe(
        map(this.extractData));
    }


    updateContent(content) {
      this.contentData.next(content);
    }

    updateOgContent(content) {
      this.ogData.next(content);
    }

    updateFeaturesAvailability(content) {
      this.features.next(content);
    }


    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        // send the error to remote logging infrastructure
        console.error(error); // log to console instead

        //  better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
        this.router.navigate(['/']);
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
}
