import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../../services/booking/booking.service';
import { SalonsService } from '../../services/salons/salons.service';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { environment } from '../../../environments/environment';
import { DealsService } from '../../services/deals/deals.service';
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment';
import { NetcoreService } from '../../services/netcore/netcore.service';

declare var Flickity: any;





declare var My2c2p: any;
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss']
})
export class BookAppointmentComponent implements OnInit {

  cc: object;

  constructor(
    private el: ElementRef,
    private BookingService: BookingService,
    private SalonsService: SalonsService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private NgxSmartModalService: NgxSmartModalService,
    private UserAccountService: UserAccountService,
    private NetcoreService: NetcoreService,
    private DealsService: DealsService
  ) { 
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
  }
  
  selectedBooking = JSON.parse(localStorage.getItem('selectedBooking'));
  branch: any;
  user: any;
  payment_channels = [];
  isLoaded = false;
  selectedDate = '';
  selectedTime = '';
  selected_channel:string="";
  submit_error:string="";
  resource = environment.imagekit; 
  bagId = localStorage.getItem('bagId');
  timeslots = [];
  settings:any;
  is_authenticated = false;
  is_guest = false;
  hidePasswordIcon:boolean = true;
  existingEmail = false;
  filterExpanded = 'Categories';
  sign_in:FormGroup = this._formBuilder.group({
    email:['', [Validators.required, Validators.email]],
    password:['', Validators.required]
  });
  get email(){return this.sign_in.get('email')};
  get password(){return this.sign_in.get('password')};
  inValidEmailOrPassword:boolean = false;
  sign_submitted = false;
  checkemail_submitted = false;
  email_status:string = 'unset';
  
  bookingDetails:FormGroup = this._formBuilder.group({
    notes:[''],
    first_name:['',Validators.required],
    last_name:['', Validators.required],
    contact_number:['', Validators.required],
    email:['',[Validators.required, Validators.email]],
    userAgree:[false,Validators.requiredTrue]
  });

  promoCodeForm:FormGroup = this._formBuilder.group({
    code:['', Validators.required],    
  });


  userAgree = false;

  isLoading = false;
  checkoutForm: FormGroup;

  monthText = "";  
  
  promoCode="";
  showInvalidPromoCode:boolean=false;
  invalidPromoCodeMessage:string = "";

  vouchers = [];

  selectedVoucher = "";

  showPromoCodeForm:boolean = true;

  isLoadingApplyVoucher = false;

  total_price = 0;

  removeCode(){
    this.promoCode = '';
    this.selectedVoucher = '';
    this.promoCodeForm.reset();
    // this.promoCodeForm.markAsPristine();
    // this.promoCodeForm.markAsUntouched();
    // this.promoCodeForm.markAsPending();
    this.showInvalidPromoCode  = false
    let services = [];
    this.selectedBooking['services'].forEach(s => {
      services.push(s['service_id'] + ";"+ s['header_id'])
    });
    let payload = {
      "services": services,
      "branch": this.selectedBooking['branch_id']
    };
    this.BookingService.getBookingDetails(payload).subscribe(data => {      
      this.total_price = data['payment_amount_required'];
      console.log(data);
      this.settings = data;
    });
  }

  showToolTip(show){
    if(show)
    document.getElementById('voucher-tooltip').style.display = 'block';
    else
    document.getElementById('voucher-tooltip').style.display = 'none';
  }

  applyCode(){
    if(this.promoCodeForm.invalid)
    return;

    this.isLoadingApplyVoucher = true;

    this.showInvalidPromoCode  = false

    let services = [];
    this.selectedBooking['services'].forEach(s => {
      services.push(s['service_id'] + ";"+ s['header_id'])
    });

    let json = {
      voucher_code: this.promoCodeForm.get('code').value,
      services: services,
      device_used: "web",
      branch_id:this.selectedBooking['branch_id']
    }

    this.BookingService.applyPromoCode(json).subscribe((data:any)=>{
      this.isLoadingApplyVoucher = false;
      if(data.status){        
        this.promoCode = this.promoCodeForm.get('code').value;
        this.total_price = data['data']['total_price'];
        // this.settings['payment_required'] = false;
      }
      if(data.status == false){
        this.showInvalidPromoCode = true;
        this.invalidPromoCodeMessage = data.data.message;
      }
    },error=>{
      this.showInvalidPromoCode = true;
      this.isLoadingApplyVoucher = false;
    });
    

  }


  voucherEvent(){
    this.promoCodeForm.get('code').setValue(this.selectedVoucher);
    this.showInvalidPromoCode  = false
    // this.settings['payment_required'] = false;
    this.promoCode = this.selectedVoucher;
  }

  ngOnInit() {

    this.checkoutForm = new FormGroup({
      'paymentFormGroup': new FormGroup({
        // 'promoCode': new FormControl(null),
        'creditCard': new FormControl(null, Validators.required),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
        // 'paymentChannel': new FormControl(null, Validators.required),
        // 'eVoucherPolicy': new FormControl(false,Validators.requiredTrue),
      })
    });
    
    this.UserAccountService.getLoggedInUser.subscribe(data=>{
      if(data != null) {        
        this.user = data;        
        this.is_authenticated = true;
        this.is_guest = false;
        
        // this.showPromoCodeForm = false;

        this.UserAccountService.getVouchers(this.selectedBooking['branch_id']).subscribe((vouchers:any)=>{
          this.vouchers = vouchers.data;
          // debugger;
        })

      }
    })
    if(this.selectedBooking == null) {
      this.router.navigate(['/']);
    }
    let services = [];
    this.selectedBooking['services'].forEach(s => {
      services.push(s['service_id'] + ";"+ s['header_id'])
    });
    let payload = {
      "services": services,
      "deals": this.selectedBooking['deals'],
      "branch": this.selectedBooking['branch_id']
    };
    this.BookingService.getBookingDetails(payload).subscribe(data => {
      console.log(data);
      this.settings = data;
      this.total_price = data['payment_amount_required'];
    });
    this.SalonsService.getBranch(this.selectedBooking['branch_slug']).subscribe(data => { 
      this.branch = data['data'][0];
      
      this.isLoaded = true;
      setTimeout(() => {
        
        Flickity.prototype.getSelectedCellInnerTextNumbers = function() {
          const selectedCells = this.selectedCells;
          const numberRegex = /\d+/g; // Regular expression to match numbers
          const selectedCellInnerTextNumbers = selectedCells.map(function(cell) {
            const text = cell.element.innerText;
            const numbers = text.match(numberRegex);
            return numbers ? numbers.join('') : ''; // Concatenate matched numbers, or an empty string if none found
          });
        
          return selectedCellInnerTextNumbers;
        };
    
        Flickity.prototype.getSelectedCellInnerIds = function() {
          const selectedCells = this.selectedCells;      
          const selectedCellInnerTextNumbers = selectedCells.map(function(cell) {
            const text = cell.element.id;        
            return text;
          });
        
          return selectedCellInnerTextNumbers;
        };
    
        Flickity.prototype.getMonth  = function( selectedDates){
          selectedDates.sort();
          const highestDate = selectedDates[selectedDates.length - 1];
          const dateObj = new Date(highestDate);
          const month = dateObj.toLocaleString('default', { month: 'long' });
          const year = dateObj.getFullYear();
          const formattedDate = `${month} ${year}`;
          document.getElementById('month-year').innerHTML = formattedDate;
          return formattedDate
    
        }
        const el = this.el.nativeElement;
        let elem = el.querySelector('#flickity-date-picker');
  
        let flkty = new Flickity(elem, {
          autoPlay: false,
          contain: true,
          cellAlign: 'center',
          cellSelector: '.slide',
          pageDots: false,
          prevNextButtons: false,
          groupCells: true
        });
        let flkty_prev = el.querySelector('.btn-flickity-prev');
        let flkty_next = el.querySelector('.btn-flickity-next');
        flkty_prev.addEventListener('click', function() {                    
          flkty.previous(true);
        });

        flkty_next.addEventListener('click', function() {          
          flkty.next(true);
        });
        const carouselWidth = flkty.cells.reduce((totalWidth, cell) => totalWidth + cell.element.clientWidth, 0);
        const containerWidth = flkty.viewport.getBoundingClientRect().width;
      
        if (carouselWidth <= containerWidth) {
          flkty_prev.style.display = 'none';
          flkty_next.style.display = 'none';
        } else {
          flkty_prev.style.display = 'block';
          flkty_next.style.display = 'block';
        }

        if (flkty.selectedIndex === 0) {
          flkty_prev.setAttribute('disabled', 'true');
          flkty_prev.classList.add('disabled');
        } else {
          flkty_prev.removeAttribute('disabled');
          flkty_prev.classList.remove('disabled');
        }
        
        const selectedIds = flkty.getSelectedCellInnerIds();
        flkty.getMonth(selectedIds)
        flkty.on('change', function(item) {         
          const selectedIds = flkty.getSelectedCellInnerIds();
          flkty.getMonth(selectedIds)
          
          if (flkty.selectedIndex === 0) {
            flkty_prev.setAttribute('disabled', 'true');
            flkty_prev.classList.add('disabled');
          } else {
            flkty_prev.removeAttribute('disabled');
            flkty_prev.classList.remove('disabled');
          }
      
          if (flkty.selectedIndex === flkty.slides.length - 1) {
            flkty_next.setAttribute('disabled', 'true');
            flkty_next.classList.add('disabled');
          } else {
            flkty_next.removeAttribute('disabled');
            flkty_next.classList.remove('disabled');
          }
        });
      }, 500);
    });

    this.BookingService.getPaymentChannels().subscribe((data:any) => { 
      this.payment_channels = data;
    });

  }

  submitSignin() {
    if (this.sign_in.invalid) {
      return;
    }
    this.inValidEmailOrPassword = false;
    this.sign_submitted = true;
    let json = {email:this.email.value, password: this.password.value};
    if(this.bagId != null)
      json['bag_id'] = this.bagId;
    let app = this;
    this.UserAccountService.login2(json).subscribe((data:any)=>{
      this.sign_submitted = false;
      if(data.success==false){
        if(data.message=="One time pin has been sent to your email. Please verify to continue."){
          const now = new Date()
          const expiry =  now.setMinutes(now.getMinutes() - 5);
          localStorage.setItem('otp-expiration',JSON.stringify( expiry));
        }      
        this.inValidEmailOrPassword = true;
      }else{
        this.UserAccountService.setActiveUser(data);
        this.user = data;
        app.is_authenticated = true;
      }
    })
  }

  selectDate(date) {

    this.selectedTime = '';
    this.selectedDate = date;
    
    this.BookingService.getTimeSlots(this.selectedBooking['branch_id'], date).subscribe(data =>{
      this.timeslots = data;
    })
  }

  successRegister(e){
    this.NgxSmartModalService.getModal('registerModal').close();
    this.NgxSmartModalService.getModal('otpModal').open();
  }

  openRegisterModal() {
    this.NgxSmartModalService.getModal('registerModal').open();
  }

  otpEvent(e) {
    this.NgxSmartModalService.getModal('otpModal').close();
  }

  paymentOptionClick(target) {
    // let element: HTMLElement = document.getElementById(target) as HTMLElement;
    // console.log(element);
    this.selected_channel = target;
  }

  selectTime(time){
    this.selectedTime = moment(time).format('hh:mm a');
  }

  convertToStandardTime(time24) {
    const [timePart, period] = time24.split(' ');
  
    if (!period) {
      return time24; // No period found, return as is
    }
  
    const [hours, minutes] = timePart.split(':').map(Number);
    const standardPeriod = period.toLowerCase(); // Convert period to lowercase
    const standardHours = hours % 12 || 12;
  
    return `${standardHours}:${minutes.toString().padStart(2, '0')} ${standardPeriod}`;
  }


  

  submitRequest(){
    // return
    let app = this;
    let services = [];
    this.selectedBooking['services'].forEach(s => {
      services.push(s['service_id'] + ";"+ s['header_id'])
    });
    
    
    let payload = {
      "branch_id":this.branch['id'],
      "date": this.selectedDate,
      "time": this.convertToStandardTime(this.selectedTime),
      "notes": this.bookingDetails.controls.notes.value,
      "services":services,
      "deals":this.selectedBooking['deals'],
      "securePayToken":'',
      "device_used": "web",
      "voucher_code":this.promoCodeForm.get('code').value
      // "securePayToken": "00acPPKYaeF0AhGoT86uB/E0imxWFkTPJ//fqVNMLgIs0uUIok3bScExtsjOYDtcAJ5PbsUxpjzPAi/pvWURityHizUrj9i9gi8XNGvgE+/OwSGNDHVM0cAXygdwWZAzbLbuNJ+heV0PwknTuLkUycAq5rWvgapSCsoQUgcdcm7UA/E=U2FsdGVkX18Wup6LnK2yNcKPz5R4ctD6qlvixexe2To="
    }

    if(this.promoCode=='')
      delete payload.voucher_code

    // debugger


    
    if(this.is_authenticated == true) {
      payload["api_token"] = this.user['data'];
    } else {
      if (this.bookingDetails.invalid) {
        this.isLoading = false;
        return;
      }
      payload['first_name'] = this.bookingDetails.controls.first_name.value;
      payload['last_name'] = this.bookingDetails.controls.last_name.value;
      payload['email'] = this.bookingDetails.controls.email.value;
      payload['mobile_number'] = this.bookingDetails.controls.contact_number.value;
    }
    
    
    if(this.settings['payment_required'] == true) {
      payload["payment_channel_code"] = this.selected_channel;
    }

    if(this.selected_channel == 'parlon_credit_card') {       
      this.cc['cardnumber'] = this.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      this.cc['cvv'] = this.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      this.cc['month'] = this.checkoutForm.value.paymentFormGroup.ccMonth;
      this.cc['year'] = this.checkoutForm.value.paymentFormGroup.ccYear.toString();
      payload["payment_channel_code"] = this.selected_channel ;      
     }

     setTimeout(()=>{
      My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {      
        payload.securePayToken = encryptedData.encryptedCardInfo;            
      });


      if(payload['payment_channel_code'] == 'parlon_maya') {
        let maya_fields = {
          card: {
            number:this.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, ''),
            expMonth:this.checkoutForm.value.paymentFormGroup.ccMonth,
            expYear:this.checkoutForm.value.paymentFormGroup.ccYear.toString(),
            cvc:this.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '')
          }
        };
        this.DealsService.getMayaToken(maya_fields).subscribe((response) => {
          payload['securePayToken'] = response['paymentTokenId'];
            app.requestBooking(payload);
        });
        return;
      }
      app.requestBooking(payload);
     },200)
          
  }
  continueAsGuest(){
    this.is_guest = true;
  }

  requestBooking(payload) {
    this.isLoading = true;
    this.BookingService.requestBooking(payload).subscribe(data => { 
      this.selectedBooking['date'] = this.selectedDate;
      this.selectedBooking['time'] = this.selectedTime;
      this.BookingService.setBookings(this.selectedBooking);
      this.isLoading = false;
      this.NetcoreService.dispatch('booking request', {
        "merchant": this.selectedBooking['salon'],
        "branch": this.branch['branch_name'],
        "date": this.selectedDate,
        "time": this.selectedTime,
      });
      this.settings['services'].forEach(e => {
        this.NetcoreService.dispatch('booked services', {
          "merchant": this.selectedBooking['salon'],
          "branch": this.branch['branch_name'],
          "service": e['label'],
          "price": e['price'],
          "date": this.selectedDate,
          "time": this.selectedTime,
        });
      });
      setTimeout(() => {
        if(data['status'] == true && data['isRedirect'] == true){          
          window.location.href = data['response']['data'];
        } else {
          this.submit_error = data['message'];
        }
      }, 1000);
      
    });
  }

  changeModelUserAgreement(){
    this.bookingDetails.get('userAgree').setValue(this.userAgree); 
  }
  checkEmail(){
    this.checkemail_submitted = true;
    this.BookingService.checkEmail(this.email.value).subscribe(data => { 
      this.checkemail_submitted = false;
      this.email_status = data['user_status'];
      if(this.email_status == 'unregistered')
        this.is_guest = true;
    });
  }

  bookingValidate() {    
    let valid = true;
    const page = this;

    if(this.selectedTime=='') 
      valid = false;

    if(this.selectedDate == '' || this.selectedTime == '')
      valid = false;

    if(this.selected_channel == '' && this.settings['payment_required'] == true && this.selectedVoucher == '' && this.total_price > 0)
      valid = false;

    if(this.is_authenticated == false && this.is_guest == false){
      valid = false;      
    } 
      
    if(this.is_guest == true) {
      if(this.bookingDetails.invalid){
        valid = false;
      }
    }

    if(this.bookingDetails.controls.userAgree.value == false)
      valid = false

    return valid;
  }

  voucherTabChange(e){
    this.removeCode();
  }

  setOpenPanel(value) {
    this.filterExpanded = value;
  }

}
