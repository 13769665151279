import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

let authData = '';
const endpoint = environment.api;

let httpOptions = {};
@Injectable({
  providedIn: 'root'
})
export class OgService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(platformId)) {
      authData = 'Basic ' + Buffer.from('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s').toString('base64');
    } else {
      authData = 'Basic ' + btoa('DdMwLy%X_D3jtH[a' + ':' + 'a~L6}vkMUCKb[<3s');
    }
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authData
      })
    }
   }

   getCategories(){
    return this.http.get(environment.api + 'og/get-categories', httpOptions);
   }


   getOfferDetail(slug:string){
    return this.http.get(environment.api + 'og/offer/offer-detail/'+slug, httpOptions);
   }

   getRandomOffers(){
    return this.http.get(environment.api + 'og/offer/get-random-offers', httpOptions);
   }

   registerRequest(form:any,key){
    const header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Idempotency-Key': key
      })
    }
    return this.http.post(environment.api + 'og/register', form, header);
  }

  getPurchaseStatus(query){
    return this.http.get(environment.api + 'og/purchase-status?'+query, httpOptions);
  }

  validatePromo(promo_code){
    return this.http.get(environment.api + 'og/validate-promo-code?promo_code='+promo_code, httpOptions);
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

   getAllBrands(){
    return this.http.get(environment.api + 'og/brands/get-all-brands', httpOptions);
   }

   checkRedeemStatus(offerID){
    // og/member/check-user-perk-redemption-status
    let api_token = localStorage.getItem('currentUser');
    let header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+JSON.parse(api_token).data,
      })
    }
    let json = {offerID};
    return this.http.post(environment.api + 'og/member/check-user-perk-redemption-status',json, header);
   }

   redeem(offerID, cmsOgBrandLocationId = null){
    
    let api_token = localStorage.getItem('currentUser');
    let header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+JSON.parse(api_token).data,
      })
    }

    let json = cmsOgBrandLocationId == null ?   {offerID} : {offerID, cmsOgBrandLocationId};
    return this.http.post(environment.api + 'og/member/redeem-perk',json, header);
    
   }



  //  algolia
     
   getOffers():Observable<any>{
    let indexName = environment.og_offers;
    let json ={"requests":[{indexName: indexName, "params": "query=", distinct:0 }]};
    // json={"requests":[{page:0,distinct:0 ,indexName: environment.deals, "params": "query="+value, "facetFilters":facets }]}
    return this.http.post(environment.api+'algolia-general-search',json);
   }
   
   
   getBrands(page=0):Observable<any>{
    let indexName = environment.og_brands;
    let json ={"requests":[{page, hitsPerPage: 50,indexName: indexName, "params": "query=", distinct:0 }]};
    // json={"requests":[{page:0,distinct:0 ,indexName: environment.deals, "params": "query="+value, "facetFilters":facets }]}
    return this.http.post(environment.api+'algolia-general-search',json);
   }
   
   searchV2(page=1, filters = [], ids = []){

    let appliedFilters='';
    let appliedIds='';
    if(filters.length>0){
      appliedFilters = filters.join(';') + ';';
    }

    if(ids.length>0){
      appliedIds = ids.join(';') + ';';
    }
     


    return this.http.get(environment.api + 'og/get-offer-list?perPage=30&page='+page+'&'+'type='+appliedFilters+'&brand='+appliedIds, httpOptions);
   }

   searchPageV2(){

   }


   search(key:string="", category:any = [], offer_type:any = [], brands:any = [],  page=0):Observable<any>{
    let indexBrand = environment.og_brands;
    let indexOffers = environment.og_offers;
    let facetCategory = [];    
    category.forEach(element=>{
      facetCategory.push('categories.category_name:'+element)
    })

    let facetOfferType = [];    
    offer_type.forEach(element=>{
      if(element=='selectedFreebie'){
        facetOfferType.push('offer_type:'+'Freebie')
      }
      if(element=='selectedDiscount'){  
        facetOfferType.push('offer_type:'+'Discount')
      }
      if(element=='selectedSpecial'){  
        facetOfferType.push('offer_type:'+'Im Special')
      }
      
    })

    let facetBrand = [];    
    brands.forEach(element=>{
      facetBrand.push('brand_name:'+element.brand_name)
    })
    
    let facets= [facetCategory,facetOfferType, facetBrand];         
    let json = {
      "requests":[{page,hitsPerPage: 16,indexName: indexBrand,
       "params": "query="+key, distinct:0 }, 
      {page, hitsPerPage: 30,indexName: indexOffers, "params": "query="+key, distinct:0,"facetFilters":facets }]
    };
    
    return this.http.post(environment.api+'algolia-general-search',json);
   }
   
   searchPage(params,page=0, searchType="all"):Observable<any>{
    let indexBrand = environment.og_brands;
    let indexOffers = environment.og_offers;
    let json;
    if(searchType=='offers'){    
       json = {
        "requests":[
          {
            page,
            hitsPerPage: 30,
            indexName: indexOffers,
            "params": "query="+params['query'],
            distinct:0,
            "facetFilters":params['facetFilters'] 
          }]
      }; 
    }

    if(searchType=='brands'){    
      json = {
       "requests":[
         {
           page,
           hitsPerPage: 16,
           indexName: indexBrand,
           "params": "query="+params['query'],
           distinct:0           
         }]
     }; 
   }
  
    return this.http.post(environment.api+'algolia-general-search',json);
   }

   errorHandler(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log(error);
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


   
  

}
