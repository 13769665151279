<section class="bg-light-3 py-7">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-xl-9 text-center">
				<h1 class="display-5 fw-bold mb-4">{{data['intro_title']}}</h1>
				<p class="fs-4 fw-medium mb-0">{{data['intro_description']}}</p>				
				<button *ngIf="data['button_link'] && data['button_label'] " routerLink="{{data['button_link']}}" mat-flat-button color="secondary" class="px-8 py-1 mt-5 primary-hover">
					{{data['button_label']}}
				</button>
			</div>
		</div>
	</div>
</section>