<section class="booking-payment" *ngIf="isLoaded == true">
  <img src="/assets/images/booking-banner.jpg" alt="" class="booking-payment-banner">
  <div class="container main">
    <div class="row">
      <div class="col-md-4 text-center">
        <img src="{{resource + data['parlon_logo']}}" class="salon-logo" width="150">
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 ">
        <div class="py-5 px-5 content-card">
          <div class="text-center ">
            <p class="mb-1 text-gray pt-6">PLEASE PAY</p>
            <h3 class="mb-1 fw-semibold display-4">
              ₱{{data['amount'] | number}}
            </h3>
            <p class="mb-1 text-gray">to confirm your booking with</p>
            <h3 class="mb-1 fw-semibold h3">{{data['parlon']}}</h3>
          </div>
          <hr class="mx-2">
          <p class="mb-1 text-gray fw-600">BOOKING DATE AND TIME</p>
          <p class="mb-1 text-gray"> <i class="fa-solid fa-calendar-days me-2"></i>{{data['date'] | date:'EEEE, MMMM d, y'}}</p>
          <p class="mb-1 text-gray"> <i class="fa-solid fa-clock me-2"></i>{{data['time']}}</p>
          <p class="mb-1 text-gray">{{data['branch']}}</p>
          <hr class="mx-2">
          <p class="mb-1 text-gray fw-600">INVOICE NUMBER</p>
          <p class="mb-1 text-gray">{{data['invoice']}}</p>
          <p class="mb-1 text-gray fw-600">CONTACT INFORMATION</p>
          <p class="mb-1 text-gray"> <i class="fa-solid fa-location-pin me-2"></i> {{data['address']}}</p>
          <p class="mb-1 text-gray"><i class="fa-solid fa-phone me-2"></i> {{data['contact_numbers']}}</p>
        </div>

      </div>
      <div class="col-md-8 ">
        <div class="px-5 py-5 content-card">
          <h3 class="mb-1 fw-semibold h3">Notes</h3>
          <div [innerHTML]="data['notes']"></div>

          <h3 class="mt-4 mb-4 fw-semibold h3">Choose payment method</h3>

          <div class="payment-method-accordion">
            <mat-accordion>
              <mat-radio-group >
                <ng-container *ngFor="let channel of payment_channels">
                  
                  <mat-expansion-panel 
                    *ngIf="channel['template'] == 'custom_credit_card'">
                    <mat-expansion-panel-header (click)="paymentOptionClick(channel['channel_code'])">
                      <div class="row row-payment-channel gx-4">
                        <div class="col">
                          <mat-radio-button value="{{channel['channel_code']}}">				
                            {{channel['channel_name']}}
                          </mat-radio-button>
                        </div>
                        <div class="col-auto">
                          <div class="card-logo">
                            <img src="{{resource + channel['logo']}}">
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
        
                    <div>
                      <p class="mb-2">Your payment shall be processed safely and securely by a third-party payment gateway provider. For your security, we do not store your credit card information.</p>
                      <credit-card-form [paymentFormGroup]="checkoutForm.get('paymentFormGroup')"></credit-card-form>
                    </div>
                
                  </mat-expansion-panel>
    
                  <mat-expansion-panel class="nopad"
                  *ngIf="channel['template'] == 'generic'">
                  <mat-expansion-panel-header  (click)="paymentOptionClick(channel['channel_code'])">
                    <div class="w-100 row-payment-channel">
                      <div class="row">                              
                        <mat-radio-button value="{{channel['channel_code']}}">	
                          <div > {{channel['channel_name']}}</div>
                        </mat-radio-button>                                        
                      </div>
                      <div>
                        <div class="card-logo ps-2 pe-2">
                          <img src="{{resource + channel['logo']}}">
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
    
                  
                  
                </ng-container>
      
    
              </mat-radio-group>
            </mat-accordion>
          </div>

          <div class="mt-5 mb-6">
            <mat-checkbox id="eVoucherPolicy" class="eVoucherPolicy" color="primary" (ngModel)="terms"> 
              <span>
                By clicking the ‘PAY NOW’ button, I confirm that I have read and hereby agree to be bound by Parlon’s <a href="https://legal.parlon.ph/post/terms-of-use" target="_blank" class="text-secondary">Parlon Terms of Use</a>, the additional Terms and Conditions of the deal(s), and Parlon’s <a href="https://legal.parlon.ph/post/privacy-notice" target="_blank" class="text-secondary">Parlon Privacy Notice</a>.
              </span>
            
            </mat-checkbox> 
          </div>

          <div class="mt-3 mb-6"  *ngIf="isTransactionFailed == true">
            <p class="text-danger"><strong>Oh no, we failed to process your payment /  payment processing failed. </strong><br>
              {{error_message}} 
              </p>
          </div>

          <button [disabled]="!validate() || isLoading" mat-flat-button color="secondary" class="p-3 w-100" type="submit" (click)="onSubmit()">
            <span>PAY NOW</span>
            <mat-icon *ngIf="loading"><mat-spinner color="accent" diameter="20">
            </mat-spinner></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<form id="cc-form" style="display: none;">
  <input type="text" data-encrypt="cardnumber" name="cardnumber" [(ngModel)]="cc['cardnumber']"><br/>
  <input type="text" data-encrypt="month" name="month" [(ngModel)]="cc['month']"><br/>
  <input type="text" data-encrypt="year" name="year" [(ngModel)]="cc['year']"><br/>
  <input type="password" data-encrypt="cvv" name="cvv" [(ngModel)]="cc['cvv']">
</form>