import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CandiesService {

  constructor() { }
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Expose the BehaviorSubject as an Observable
  public isLoadingCandies$: Observable<boolean> = this.isLoadingSubject.asObservable();

  // Example method to update the value of the BehaviorSubject
  public updateBooleanValue(newValue: boolean): void {
    this.isLoadingSubject.next(newValue);
  }
}
