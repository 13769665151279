import { FormControl } from '@angular/forms';
import * as moment from 'moment';
// custom validator to check that two fields match
export function dateValidator(control: FormControl) { 
    
    const today = new Date().getTime();

    if(!(control && control.value)) {
      // if there's no control or no value, that's ok
      return null;
    }

    // if(isNaN(Date.parse(control.value))){
    //   console.log('nan')
    //   return { 'dateValidator': true };
    // }

    // if (control && control.value && !moment(control.value, 'MM/DD/YYYY', true).isValid()) {
    //   console.log('nan')

    //   return { 'dateValidator': true };
    // }  


    let now = new Date();
    let m =  now.getMonth();
    
    now.setFullYear(now.getFullYear() - 18);
    // deal with today being 29 Feb
    if (m != now.getMonth()) now.setDate(0);
    return control.value.getTime() > now.getTime()  ? {'invalidDate': true}: null; 


    return control.value.getTime() > today 
      ? {'invalidDate': true } 
      : null;


     

    
      
      

}


function over18(birthDate) {
  var now = new Date();
  var m =  now.getMonth();
  now.setFullYear(now.getFullYear() - 18);
  // deal with today being 29 Feb
  if (m != now.getMonth())
    now.setDate(0);
  
  return now > birthDate;
}