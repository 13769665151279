import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DealsService } from '../../services/deals/deals.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import {  Router } from '@angular/router';
import { NetcoreService } from '../../services/netcore/netcore.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare let fbq:Function;

@Component({
  selector: 'app-beauty-bag-thankyou',
  templateUrl: './beauty-bag-thankyou.component.html',
  styleUrls: ['./beauty-bag-thankyou.component.scss']
})
export class BeautyBagThankyouComponent implements OnInit {

  successId: string;
  pendingId: string;
  failedId: string;
  isLoaded = false;
  resource = environment.resource;
  isSuccess = false;
  isFailed = false;
  isPending = false;
  data: object;
  response: object;
  bag_status_ids = localStorage.getItem('bag_status_ids');

  constructor(
    private DealsService: DealsService,
    private router: Router,
    private NetcoreService:NetcoreService,
    private NgxSmartModalService:NgxSmartModalService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.data = {
      transaction_status_id: ''
    }
   }

   ngOnInit() {
    this.successDetails();
  }

  successDetails() {
    let page =this;
    let url_string = window.location.href;
    var url = new URL(url_string);
    page.isLoaded = false;
    page.successId = url.searchParams.get("success");
    page.pendingId = url.searchParams.get("pending");
    page.failedId = url.searchParams.get("failed");
    if(page.pendingId != null) {
      page.data['transaction_status_id'] =  page.pendingId;

      page.DealsService.getPurchaseStatus(page.data['transaction_status_id']).subscribe((data: {}) => {
        if (data['status'] == "success") {
            console.log('success')
            window.location.href = "beauty-bag-purchase?success=" + page.pendingId;
        }
        // else if (data['status'] == "failed") {
        //     console.log(data['status'])
        //     // window.location.href = "beauty-bag-purchase?failed=" + page.pendingId;
        // }
        page.isPending = true;
        page.response = data;
        page.isLoaded = true;

     });

      setTimeout(function(){
          let tries = 50;
          let statusPoll = setInterval(function(){
               tries--;
               if (tries == 0) {
                   window.location.href = "beauty-bag-purchase?failed=" + page.pendingId;
               } else {
                   page.DealsService.getPurchaseStatus(page.data['transaction_status_id']).subscribe((data: {}) => {
                       if (data['status'] == "success") {
                           console.log('success')
                           window.location.href = "beauty-bag-purchase?success=" + page.pendingId;
                       }
                       // else if (data['status'] == "failed") {
                       //      console.log(data['status'])
                       //      console.log(data)
                       //     //window.location.href = "beauty-bag-purchase?failed=" + page.pendingId;
                       // }
                  });
               }
          }, 5000)
      },5000)

    }
    if(page.successId != null) {
      page.data['transaction_status_id'] =  page.successId;
      page.DealsService.getPurchaseStatus(page.data['transaction_status_id']).subscribe((data: {}) => {
        if(data['status'] == 'success') {
          let ids = [];
          if(page.bag_status_ids != null) { 
            ids = JSON.parse(page.bag_status_ids);
          }
          if(!ids.includes(page.data['transaction_status_id'])) {
            let fb_contents = [];
            data['purchased_items'].forEach(e => {
              fb_contents.push({
                id: e['id'],
                quantity: e['quantity']
              })
            });
            fbq('track', 'Purchase', { 
              value: data['total_amount'],
              currency: environment.currency,
              contents: fb_contents,
              content_type: 'product', // constant
            });
            // this.NetcoreService.dispatch('purchased bag', data['cart_details']);
            let deal_ids = [];
            data['purchased_items'].forEach(deal => {
              deal_ids.push({
                "productid":deal['dealid'].toString(),
                "msp": deal['finalamount']
              });
              // netcore_data['promocode'] = data['cart_details']['promo_code'];
              // netcore_data['amount'] = deal['finalamount'];
              // delete netcore_data.dateUnix;
              // this.NetcoreService.dispatch('purchased deal', netcore_data);
            });
            let script = this._renderer2.createElement('script');
            script.text = `
              var BOXX_PURCHASED_PRODUCT_PROPS=`+JSON.stringify(deal_ids)+`
            `;
            this._renderer2.appendChild(this._document.body, script);
            ids.push(page.data['transaction_status_id']);
          }
          localStorage.setItem('bag_status_ids',JSON.stringify(ids));
          localStorage.removeItem('buyerInfo');
          localStorage.removeItem('bagCount');
          localStorage.removeItem('bagId');
          let modalData = data['deals_purchased'];
          this.NgxSmartModalService.setModalData({modalData}, 'bookModal', true);  
          data['deals_purchased'].forEach(e => {
            e['selected'] = false;
          });
          console.log(modalData);
          page.isSuccess = true;
          page.response = data;
          page.isLoaded = true;
          
        } else {
          this.router.navigate(['/404']);
        }

      });
    }
    if(page.failedId != null) {
      page.data['transaction_status_id'] =  page.failedId;
      page.DealsService.getPurchaseStatus(page.data['transaction_status_id']).subscribe((data: {}) => {
        page.isFailed = true;
        page.response = data;
        page.isLoaded = true;
      });
    }

  }

  toggleBooking(deal) {
    deal['selected'] = !deal['selected'];
  }

  bookAppointments(){
    let selectedDeals = []
    let url_string = window.location.href;
    let url = new URL(url_string);
    this.response['deals_purchased'].forEach(e => {
      if(e['selected'] == true) 
        selectedDeals.push(e);
    });
    localStorage.setItem('bookDeals',JSON.stringify({
      deals: selectedDeals,
      user: this.response['buyer'],
      id: url.searchParams.get("success")
    }) );
    if(selectedDeals.length > 0)
      this.router.navigate(['/purchased-deal-appointment']);
  }

}
