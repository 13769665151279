import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../services/rest.service';
import { Title } from '@angular/platform-browser';
import { GlifeService } from '../../../../services/glife/glife.service';

declare var my: any;

@Component({
  selector: 'app-glife-checkout',
  templateUrl: './glife-checkout.component.html',
  styleUrls: ['./glife-checkout.component.scss']
})
export class GlifeCheckoutComponent implements OnInit {

  selectedServices: any;
  deals: any;
  services: any;
  total: number;
  isLoaded = false;
  slug: string;
  branchGlife = [];
  originalTotal: number;
  
  user_info = {
    first_name: '',
    last_name: '',
    email: '',
    contact_number: ''
  };

  constructor(
    private route: ActivatedRoute,
    private rest: RestService,
    private _title: Title,
    private glifeService:GlifeService
  ) {
    
   }

   retryCount = 0; 

  ngOnInit() {


    // Set a timeout to check if isLoading is still false after 10 seconds
    setTimeout(() => {
      if (this.isLoaded == false) {
        location.reload();                           
      }
    }, 10000);


    this.getParlonBranchGlife();

    this.selectedServices = JSON.parse(localStorage.getItem('selectedServices'));
    this.deals = this.selectedServices.map(({ deal }) => deal).filter(deal => !!deal);
    this.services = this.selectedServices.map(({ service }) => service).filter(service => !!service);
    this.total = [...this.deals, ...this.services].reduce((total, item) =>
      total + Number(item.deal_price || item.price), 0
    );
    this.originalTotal = [...this.deals, ...this.services].reduce((total, item) =>
      total + Number(item.original_price || item.price), 0
    );
    
  }


  getParlonBranchGlife(retryCount: number = 2) {
    let page = this;
    // page.isLoaded = false;    
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.rest.getParlonBranchGlife(this.slug).subscribe(data => {
      this.branchGlife = data;
      this._title.setTitle(this.branchGlife['parlon']['business_name'] + " | Parlon");
      my.postMessage({'action':'GetInfo'});
      my.onMessage = function(e) {
        switch (e['Action']) {
          case 'Auth':
            page.rest.glifeUserInfo({branch_id: page.branchGlife['id'], authCode:e['AuthCode']}).subscribe((d: any) => {
              if(d.status == true) {
                const user_info = d.user_info;
                const isUserInfoEmpty = Object.values(user_info).some(x => !x);
                if (!isUserInfoEmpty){ // user info is valid, exit recursion
                  const userInfo = {
                    FIRST_NAME: user_info.FIRST_NAME || '',
                    LAST_NAME: user_info.LAST_NAME || '',
                    EMAIL_ADDRESS: user_info.EMAIL_ADDRESS || '',
                    GCASH_NUMBER: user_info.GCASH_NUMBER|| ''
                  };
                  page.glifeService.setGlifeUserInfo(userInfo);
                  page.isLoaded = true;                  
                }else{
                  if (retryCount < 3) { // retry up to 3 times 
                    page.isLoaded = true;                     
                    page.getParlonBranchGlife(retryCount + 1);                                        
                  } else {
                    page.isLoaded = true;                    
                  }
                }                                                 
              } 
              page.isLoaded = true;
            }, error => {
              page.isLoaded = true;
              alert(`Error message: ${error.message}, status: ${error.status}`);                            
            });
            break;
          case 'NoAuth':
            page.isLoaded = true;
            break;
          case 'Close':
            window.location.replace('/glife/'+page.branchGlife['parlon']['slug']);
            break;
          default:
            break;
        }
      }
    });
  }
  
  

  

  // WORKING BUT NOT IN THE FIRST TRY GETINFO
  // getParlonBranchGlife() {
  //   let page = this;
  //   page.isLoaded = false;

  //   this.slug = this.route.snapshot.paramMap.get('slug');
  //   this.rest.getParlonBranchGlife(this.slug).subscribe(data => {
  //     this.branchGlife = data;
  //     // console.log(this.branchGlife);
  //     this._title.setTitle(this.branchGlife['parlon']['business_name'] + " | Parlon");
     
  //     //03-25-2023 commented this to remove the delay and speed up the site
  //     // setTimeout(() => {
  //     //   page.isLoaded = true;
  //     // }, 5000);
  //     my.postMessage({'action':'GetInfo'});
  //     my.onMessage = function(e) {
  //       switch (e['Action']) {
  //         case 'Auth':
  //           page.rest.glifeUserInfo({branch_id: page.branchGlife['id'], authCode:e['AuthCode']}).subscribe((d: any) => {
  //             if(d.status == true) {
  //               const user_info = d.user_info;
  //               const userInfo = {
  //                 FIRST_NAME: user_info.FIRST_NAME || '',
  //                 LAST_NAME: user_info.LAST_NAME || '',
  //                 EMAIL_ADDRESS: user_info.EMAIL_ADDRESS || '',
  //                 GCASH_NUMBER: user_info.GCASH_NUMBER|| ''
  //               };

                
  //               page.glifeService.setGlifeUserInfo(userInfo);                
  //             }
  //             page.isLoaded = true;
  //           },error=>alert(JSON.stringify(error)));
  //           break;
  //         case 'NoAuth':
  //           page.isLoaded = true;
  //           break;
  //         case 'Close':
  //           window.location.replace('/glife/'+page.branchGlife['parlon']['slug']);
  //           break;
  //         default:
  //           break;
  //     }
  //   }
  //   });
  // }

  

}
