<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- To set number of slides, change it on CSS
	See example below.
-->

<section class="py-4 py-md-7 overflow-x-hidden">
	<div class="container">
		<div class="row align-items-center justify-content-between justify-content-md-start mb-4 mb-md-5">
			<div class="col-auto">
				<h2 class="h2 fw-semibold mb-0">{{data['title']}}</h2>
			</div>
		</div>

		<div class="row gx-0">
			<div class="col-12">
				<div class="row flex-deal-categories row-cols-4 row-cols-md-5 gx-4 gx-lg-6 gx-xl-9 gy-7">
					<div class="col" *ngFor="let category of categories">
						<a [routerLink]="['/all-parlon-deals']" [queryParams]="generateQueryParams(category)"
						class="link-text">
							<div>
								<div class="ratio ratio-1x1 w-75 w-md-100 mx-auto mb-2 mb-md-3">
									<img src="{{ resource + category.logo }}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
								</div>
		
								<p class="deal-category-title fs-8 fw-medium text-center mb-0">{{ category['category_name'] }}</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>