import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserVerificationComponent } from './user-verification.component';

const routes: Routes = [
    { path:"", component: UserVerificationComponent }
];

@NgModule({
    exports: [RouterModule],
    imports:[RouterModule.forChild(routes)]
})

export class UserVerificationRouterModule{}