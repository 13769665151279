<!--
	NOTE:
	- You can globally override this section's brand logo size by using the CSS Variables listed below. If null, fallback values are already set on CSS.
-->


<section class="py-6 py-md-8">
	<div class="container">
		<h2 class="h2 fw-semibold text-center mb-5">We prefer cashless! </h2>

		<div class="row align-items-center justify-content-center gx-5 gx-md-8 gx-xxl-9 gy-6"
			style="
				--brand-logo-size: 1.5rem;
				--brand-logo-size-sm: 1.5rem;
				--brand-logo-size-md: 3.1rem;
				--brand-logo-size-lg: 3.1rem;
				--brand-logo-size-xl: 3.1rem;">


			<div class="col-auto" *ngFor="let logo of data['logos']">
				<img src="{{resource + logo}}" alt="" class="img-fluid img-brand-logo">
			</div>
		</div>
	</div>
</section>