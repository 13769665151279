import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss']
})
export class HeroBannerComponent implements OnInit {

  resource = environment.imagekit;

  @Input() data;
  
  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-banner');
      let flkty = new Flickity(elem, {
        contain: true,
        autoPlay: 3500,
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: false
      });

      if(flkty) {
        flkty.on('ready', () => {
          alert('asd');
          // Custom logic here
        });  
      }
      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    },500);
  }

}
