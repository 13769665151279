<section id="nav-checkpoint">
<div class="py-6">
	<div class="container" *ngIf="isLoaded">
		<div class="row d-none d-lg-block">
			<div class="col-12">
				<p class="small mb-6">
					<a routerLink="/" class="breadcrumb-link">Home</a>
					<span><i class="far fa-angle-right fa-sm fa-fw mx-1 text-muted"></i></span>
					<span class="text-primary">{{ parlon.business_name }}</span>
				</p>

				<h2 class="h2 text-secondary fw-normal mb-3">{{ parlon.business_name }} - {{ branch.branch_name }}</h2>
			</div>
		</div>

		<parlon-photos-slider [branch]="branch" *ngIf="branch['photo'].length > 0"></parlon-photos-slider>

		<div  id="tab-checkpoint" class="row row-service-list-content mt-6 mt-lg-0">
			<div class="col-12 col-lg-4 col-xxl-3 col-sidebar">
				
				<parlon-details [showReview]="false" [starCount]="averageStarCount" [data]="parlon" [branch]="branch"></parlon-details>

				<branch-list [showReview]="false" [starCount]="averageStarCount"  [branches]="parlon['branches']" [parlon]="parlon" class="d-none d-lg-block"></branch-list>
			</div>

			<div class="col-12 col-lg-8 col-xxl-9 col-service-list">
				<nav-tabs [googleReviewVisible]="showGoogleReviewTab"></nav-tabs>
				
				<div [@routerAnimations]="prepareRouteTransition(outlet)">
					<router-outlet #outlet="outlet"></router-outlet>
				</div>
			</div>
		</div>

		<div id="googleReviews"></div>
	</div>
</div>

<!-- Mobile affix -->
<div class="book-actions-mobile d-lg-none" *ngIf="branch['booking_settings'] != null && branch['has_booking'] == 'Yes'" [ngClass]="booknowHidden()">
	<div class="row align-items-center gx-0">
		<div class="col px-4 py-1">
			<p class="small mb-0">Select service/s to request booking.<br />
				<span class="fw-bold">Final service prices are determined and shall be paid at {{ branch['branch_name'] }} Branch.</span></p>
		</div>

		<div class="col-auto">
			<button mat-raised-button color="secondary" class="btn-book-now-mobile text-uppercase" (click)="bookNow()">
				Book<br />Now
			</button>
		</div>
	</div>
</div>

<!-- Desktop affix -->
<div class="book-actions-desktop d-none d-lg-flex flex-column align-items-end">
	<div class="me-2 mb-3">
		<button mat-raised-button color="secondary" class="btn-book-now-desktop text-center text-uppercase" (click)="bookNow()"
			*ngIf="branch['booking_settings'] != null && branch['has_booking'] == 'Yes'" [ngClass]="booknowHidden()">
			<div class="ic-book-now">
				<i class="far fa-calendar-check fa-lg fa-fw me-2"></i>
				<span class="counter-badge" *ngIf="(selectedServices.length + selectedDeals.length) > 0">{{ selectedServices.length + selectedDeals.length }}</span>
			</div>
			Book now
		</button>
	</div>

	<div class="book-info-desktop active" *ngIf="branch['booking_settings'] != null && branch['has_booking'] == 'Yes'" (click)="toggleClass()" [ngClass]="booknowHidden()">
		<div class="row gx-4">
			<div class="col-auto">
				<i class="fal fa-info-circle fa-fw"></i>
			</div>

			<div class="col">
				<p class="mb-0">Select service/s to request booking. <span class="fw-bold">Final service prices are determined and shall be paid at {{ branch['branch_name'] }} Branch.</span></p>
			</div>
		</div>
	</div>
</div>


<!-- ****************************** -->

<!-- <div class="margin-fixer"></div>
<div class="container" *ngIf="isLoaded">
  <div class="content">
    <div class="is-hidden-mobile">
      <p> <a routerLink="/" class="link -regular">Home
        </a> <i class="fas fa-chevron-right"></i> <span class="h -primary"> {{parlon.business_name}}</span></p>
      <h1 class="h -secondary">{{parlon.business_name}} - {{branch.branch_name}}</h1>
    </div>
    <parlon-photos-slider [branch]="branch" *ngIf="branch['photo'].length > 0"></parlon-photos-slider>
  </div>
  <div class="content">
    <div class="columns">
      <div class="column is-3">
        <parlon-details [data]="parlon" [branch]="branch"></parlon-details>
        <div class="spacer is-hidden-mobile"></div>
        <branch-list [branches]="parlon['branches']" [parlon]="parlon" class="is-hidden-mobile"></branch-list>
      </div>
      <parlon-photos-slider [branch]="branch" *ngIf="branch['photo'].length > 0"></parlon-photos-slider>
    </div>
    <div class="content">
      <div class="columns">
        <div class="column is-3">
          <parlon-details [data]="parlon" [branch]="branch"></parlon-details>
          <div class="spacer is-hidden-mobile"></div>
          <branch-list [branches]="parlon['branches']" [parlon]="parlon" class="is-hidden-mobile"></branch-list>
        </div>
        <div class="column is-9" id="tab-checkpoint">
          <nav-tabs></nav-tabs>
          <div [@routerAnimations]="prepareRouteTransition(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="m-book-actions" *ngIf="branch['booking_settings'] != null && branch['has_booking'] == 'Yes'">
	<div class="info">
		Select service/s to book an appointment <br>
		Pay at {{branch['branch_name']}} Branch.
	</div>
	<div class="book-now">
		<a (click)="bookNow()"> Book <br> Now</a>
	</div>
</div> -->

<!-- <button mat-raised-button color="secondary" class="book-now-desktop" (click)="bookNow()"
*ngIf="branch['booking_settings'] != null && branch['has_booking'] == 'Yes'"> <i class="fas fa-calendar-check"></i> <span class="counter"
*ngIf="selectedServices.length > 0">{{selectedServices.length}}</span> BOOK NOW</button>
<div class="book-info active" *ngIf="branch['booking_settings'] != null && branch['has_booking'] == 'Yes'" (click)="toggleClass()">
	<i class="fal fa-info-circle info"></i>
	<i class="fas fa-chevron-circle-right close"></i>
<p class="context">Select service/s to request booking. You will pay at {{branch['branch_name']}} Branch</p>
</div> -->

<div class="container" *ngIf="isLoaded == false">
	<parlon-inner-loader></parlon-inner-loader>
</div>
</section>
