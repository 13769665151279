import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllParlonDealsComponent } from './all-parlon-deals.component';
import { DealsCardComponent } from './deals-card/deals-card.component';
import { MaterialModule } from '../../material';
import { FilterButtonsComponent } from './filter-buttons/filter-buttons.component';
import { DealsCardSubLogoComponent } from './deals-card-sub-logo/deals-card-sub-logo.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    FormsModule
  ],
  declarations: [
    AllParlonDealsComponent,
    DealsCardComponent,
    FilterButtonsComponent,
    DealsCardSubLogoComponent,
    LoaderComponent
  ]
})
export class AllParlonDealsModule { }
