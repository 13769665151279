import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasedDealsBookingComponent } from './purchased-deals-booking.component';
import { MaterialModule } from '../../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonElementsModule } from '../../common';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxCcModule } from 'ngx-cc';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule, MaterialModule, FormsModule, CommonElementsModule, ReactiveFormsModule, NgxSmartModalModule, NgxCcModule,
    MatProgressSpinnerModule
  ],
  declarations: [PurchasedDealsBookingComponent]
})
export class PurchasedDealsBookingModule { }
