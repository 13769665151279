import { Component, OnInit } from '@angular/core';
import { GiftingService } from '../../services/gifting/gifting.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-gifting-thankyou',
  templateUrl: './gifting-thankyou.component.html',
  styleUrls: ['./gifting-thankyou.component.scss']
})
export class GiftingThankyouComponent implements OnInit {


  
  successId: string;
  pendingId: string;
  failedId: string;
  isLoaded = false;
  isSuccess = false;
  isFailed = false;
  isPending = false;
  data: object;
  response: object;
  resource = environment.resource;

  constructor(
    private GiftingService: GiftingService,
  ) {
    this.data = {
      transaction_status_id: ''
    }
   }

   ngOnInit() {
    this.successDetails();
  }

  successDetails() {
    let page =this;
    let url_string = window.location.href;
    var url = new URL(url_string);
    page.isLoaded = false;
    page.successId = url.searchParams.get("success");
    page.pendingId = url.searchParams.get("pending");
    page.failedId = url.searchParams.get("failed");
    if(page.pendingId != null) {
      page.data['transaction_status_id'] =  page.pendingId;
      page.GiftingService.getPurchaseStatus(page.data).subscribe((data: {}) => {
        page.isPending = true;
        page.response = data;
        page.isLoaded = true;
      });
    }
    if(page.successId != null) {
      page.data['transaction_status_id'] =  page.successId;
      page.GiftingService.getPurchaseStatus(page.data).subscribe((data: {}) => {
        localStorage.removeItem('buyerInfo');
        localStorage.removeItem('bagCount');
        localStorage.removeItem('bagId');
        page.isSuccess = true;
        page.response = data;
        page.isLoaded = true;
      });
    }
    if(page.failedId != null) {
      page.data['transaction_status_id'] =  page.failedId;
      page.GiftingService.getPurchaseStatus(page.data).subscribe((data: {}) => {
        page.isFailed = true;
        page.response = data;
        page.isLoaded = true;
      });
    }
    
  }

}
