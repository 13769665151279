<nav class="navbar navbar-expand bg-white py-0" aria-hidden="true" [ngClass]="isHome == true ? 'is-hidden-on-home' : ''">
  <!-- Desktop -->
  <div class="w-100">
    <div class="container desktop d-none d-md-flex flex-column align-items-center w-100 py-3">
      <div class="tier-1">
        <a routerLink="/">
          <img src="assets/images/logo-inline.svg" alt="" class="img-fluid img-navbar-logo-main">
        </a>
        
        <div class="links"  *ngIf="navEmpty">
          <ul class="nav navbar-nav nav-desktop justify-content-end align-items-center">
            <li class="ps-2 pe-0" *ngIf="features['country_switch']">                          
              <mat-form-field  class="d-flex justify-content-center align-items-center  country" >                
                <img class="img-country-selected "  *ngIf="selectedCountry === 'Philippines'"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  > 
                <img class="img-country-selected " *ngIf="selectedCountry === 'Singapore'"  src="https://ik.imagekit.io/parlon/flags/flag-sg.png"  >
                
                <mat-select disableOptionCentering panelClass="myPanelClass" (selectionChange)="selectCountry()" [(value)]="selectedCountry" #select>
                  <mat-option *ngIf="selectedCountry=='Singapore' " class="text-center d-flex align-items-center" value="Philippines">
                  
                      <img class=" img-country"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  alt="">
                  
                    <span class="ms-2" style="font-weight:bold">PH</span>
                  </mat-option>
                  <mat-option *ngIf="selectedCountry=='Philippines' " class="text-center" value="Singapore">
                    <img class=" img-country"   src="https://ik.imagekit.io/parlon/flags/flag-sg.png" alt=""> <span class="ms-2" style="font-weight:bold">SG</span>
                  </mat-option>                  
                </mat-select>                            
              </mat-form-field>                         
            </li>

            <li class="nav-item li-user-account" *ngIf="features['registration'] == true">
              <a  *ngIf="!isLoggedIn" href="#nav-user-account" class="nav-link link-primary" data-bs-toggle="offcanvas" role="button"
                aria-controls="nav-user-account">
                <i class="fak fa-users fa-lg fa-fw"></i>
              </a>
    
              <a  (click)="updateCandies()"*ngIf="isLoggedIn" href="#nav-user-account" 
              class="user-account nav-link d-flex align-items-center justify-content-center" data-bs-toggle="offcanvas" role="button"
              aria-controls="nav-user-account">
              <span>{{initials}} </span>
              </a>
            </li>
          </ul>

          
        </div>

        <div class="links" *ngIf="!navEmpty">
          <ul class="nav navbar-nav nav-desktop d-flex justify-content-end align-items-center">

            <li class="nav-item" *ngIf="categories != true && features['deal_gifting'] == true">
              <a [routerLink]="['/gifting']" class="nav-link fw-semibold link-secondary-alt">Gifting</a>
            </li>
    
            <li class="nav-item d-flex" *ngIf="categories != true && features['deals_&_beauty_bag'] == true">
              <div class="divider my-auto"></div>
            </li>
    
            <li class="nav-item" *ngIf="categories != true && features['deals_&_beauty_bag'] == true">
              <a [routerLink]="['/all-parlon-deals']" class="nav-link fw-semibold link-secondary-alt">Shop all deals</a>
            </li>
    
            <li class="nav-item">
              <a  [routerLink]="['/parlons']" class="nav-link see-all-parlon">See all Parlons</a>
            </li>
    
            <li class="nav-item">
              <a  [routerLink]="['/booking']" class="nav-link nav-booking">Book</a>
            </li>
  

            <li class="nav-item"  *ngIf="features['glow_getter'] == true">
              <a href="https://glowgetter.parlon.ph/" target="_blank" class="nav-link">Glow Getter Magazine</a>
            </li>

            <li class="nav-item ms-1 me-2" *ngIf="!ogMember && features['original_glowster'] == true">
              <button routerLink="/og-perks" class="mat-rounded h-100" mat-flat-button color="primary">Be part of OG Club</button>
              <!-- <a [routerLink]="['/be-an-og-member']" class="nav-link">Be part of OG Club</a> -->
            </li>

            <li class="nav-item" *ngIf="ogMember && features['original_glowster'] == true">
              <a [routerLink]="['/og-perks']" class="nav-link">OG Perks</a>
            </li>
            
            
            <li class="nav-item nav-search" *ngIf="categories == true">
              <a  style="width: 42px;" title="Search" class="nav-link link-primary btn-nav-search " role="button" (click)="showSearch()"
                aria-expanded="false">
                <i class="fak fa-search fa-lg fa-fw"></i>
              </a>
            </li>
            <!-- <li class="nav-item nav-search " *ngIf="categories == true">
              <a title="Search" class="nav-link link-primary btn-nav-search " role="button" (click)="showSearch()"
                aria-expanded="false">
                <i class="fak fa-search fa-lg fa-fw"></i>
              </a>
            </li> -->

            
            <li class="nav-item" *ngIf="features['country_switch']">                          
              <mat-form-field  class="d-flex justify-content-center align-items-center  country" >                
                <img class="img-country-selected "  *ngIf="selectedCountry === 'Philippines'"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  > 
                <img class="img-country-selected " *ngIf="selectedCountry === 'Singapore'"  src="https://ik.imagekit.io/parlon/flags/flag-sg.png"  >
                
                <mat-select  disableOptionCentering panelClass="myPanelClass" (selectionChange)="selectCountry()" [(value)]="selectedCountry" #select>
                  <mat-option *ngIf="selectedCountry=='Singapore' " class="text-center d-flex align-items-center" value="Philippines">
                  
                      <img class=" img-country"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  alt="">
                  
                    <span class="ms-2" style="font-weight:bold">PH</span>
                  </mat-option>
                  <mat-option *ngIf="selectedCountry=='Philippines' " class="text-center" value="Singapore">
                    <img class=" img-country"   src="https://ik.imagekit.io/parlon/flags/flag-sg.png" alt=""> <span class="ms-2" style="font-weight:bold">SG</span>
                  </mat-option>                  
                </mat-select>                            
              </mat-form-field>                         
            </li>
            
    
            <li style="width:40px;" class="nav-item  d-flex justify-content-center" *ngIf="features['deals_&_beauty_bag'] == true">
              <a  [routerLink]="['/beauty-bag-checkout']" class="nav-link link-primary nav-cart">
                <i class="fak fa-beauty-bag fa-lg fa-fw"></i>
                <span class="counter" *ngIf="cartCount > 0">{{cartCount}}</span>
              </a>
            </li>
    
            <li class="nav-item li-user-account" *ngIf="features['registration'] == true">
              <a  *ngIf="!isLoggedIn" href="#nav-user-account" class="nav-link link-primary" data-bs-toggle="offcanvas" role="button"
                aria-controls="nav-user-account">
                <i class="fak fa-users fa-lg fa-fw"></i>
              </a>
    
              <a  (click)="updateCandies()"*ngIf="isLoggedIn" href="#nav-user-account" 
              class="user-account nav-link d-flex align-items-center justify-content-center" data-bs-toggle="offcanvas" role="button"
              aria-controls="nav-user-account">
              <span>{{initials}} </span>
              
              </a>
              
            </li>
          </ul>
        </div>
        
      </div>
    </div>
    <div class="desktop d-none d-md-flex flex-column w-100 bg-secondary" *ngIf="categories == true && !navEmpty">

      <div class="tier-2 container">
        <div class="links">
          <ul class="nav navbar-nav nav-desktop justify-content-start">
    
            <li class="nav-item" *ngIf="features['deal_gifting'] == true">
              <a [routerLink]="['/gifting']" class="nav-link fw-semibold">Gifting</a>
            </li>
<!--     
            <li class="nav-item d-flex" *ngIf="features['deals_&_beauty_bag'] == true && features['deal_gifting'] == true">
              <div class="divider my-auto"></div>
            </li> -->

            <li class="nav-item bash-link" *ngIf="features['beauty_bash_in_nav'] == true">
              <a [routerLink]="['/beauty-bash/deals']" class="nav-link fw-semibold">Beauty Bash</a>
            </li>
    
            <li class="nav-item" *ngIf="features['deals_&_beauty_bag'] == true">
              <a [routerLink]="['/all-parlon-deals']" class="nav-link fw-semibold">Shop all deals</a>
            </li>

            <li class="nav-item d-flex">
              <div class="divider my-auto"></div>
            </li>
    
    
            <ng-container  *ngIf="features['deals_&_beauty_bag'] == true">
              <li class="nav-item" *ngFor="let category of data['category_tree']">
                <a class="nav-link" [ngClass]="active_mega_menu == category ? 'active' : ''" 
                  (click)="selectCategory(category)">{{category['category_name']}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <div class="mega-menu-container">
        <mega-menu [data]="active_mega_menu"></mega-menu>
      </div>

    </div>
  </div>

  <!-- Mobile -->
  <div class="container-fluid flex-wrap flex-shrink-0 mobile d-md-none overflow-hidden py-3" *ngIf="!parlon_inner && !navEmpty">
    <div class="grid-container-nav-main-mobile  w-100">
      <div class="d-flex align-items-center">
        <ul class="nav navbar-nav nav-mobile nav-start">
          <li class="nav-item">
            <a href="#nav-drawer" id="nav-burger" class="nav-link link-primary" data-bs-toggle="offcanvas"
              role="button" aria-controls="nav-drawer">
              <i class="far fa-bars fa-lg fa-fw"></i>
            </a>
          </li>

          <li class="nav-item" style="padding:0 !important;">
            <a class="nav-link link-primary" [routerLink]="['/mobile/search']" ><i class="fak fa-search fa-lg fa-fw"></i></a>
          </li>
            <div class="d-flex">
              <mat-form-field  class="d-flex justify-content-center align-items-center  country" >                
                <img class="img-country-selected "  *ngIf="selectedCountry === 'Philippines'"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  > 
                <img class="img-country-selected " *ngIf="selectedCountry === 'Singapore'"  src="https://ik.imagekit.io/parlon/flags/flag-sg.png"  >
                
                <mat-select disableOptionCentering panelClass="myPanelClass" (selectionChange)="selectCountry()" [(value)]="selectedCountry" #select>
                  <mat-option *ngIf="selectedCountry=='Singapore' " class="text-center d-flex align-items-center" value="Philippines">
                  
                      <img class=" img-country"  src="https://ik.imagekit.io/parlon/flags/flag-ph.png"  alt="">
                  
                    <span class="ms-2" style="font-weight:bold">PH</span>
                  </mat-option>
                  <mat-option *ngIf="selectedCountry=='Philippines' " class="text-center" value="Singapore">
                    <img class=" img-country"   src="https://ik.imagekit.io/parlon/flags/flag-sg.png" alt=""> <span class="ms-2" style="font-weight:bold">SG</span>
                  </mat-option>                  
                </mat-select>                            
              </mat-form-field>  
            
            </div>
            
                                 

        </ul>
      </div>

      <div class="d-flex align-items-center justify-content-center ms-1">
        <a [routerLink]="['/']">
          <img src="assets/images/logo-inline.svg" alt="" class="img-fluid img-navbar-logo-main">
        </a>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <ul class="nav navbar-nav nav-mobile nav-end">

          <li class="nav-item">
            <a [routerLink]="['/og-perks']" class="nav-link link-primary nav-cart" *ngIf="ogMember && features['original_glowster'] == true">
              <img src="assets/images/og_logo.svg" width="30">
            </a>
            <a routerLink="/og-perks" class="nav-link link-primary nav-cart" *ngIf="!ogMember && features['original_glowster'] == true">
              <img src="assets/images/og_logo.svg" width="30">
            </a>
          </li>

          <li class=""  *ngIf="features['registration'] == true">
            <a  style=" width: 38.28px; height: 40px;" *ngIf="!isLoggedIn" href="#nav-user-account" class="nav-link link-primary" data-bs-toggle="offcanvas" role="button"
              aria-controls="nav-user-account" (click)="toggleActive($event,'text-secondary')">
              <i class="fak fa-users fa-lg fa-fw"></i>
            </a>

            <a (click)="updateCandies()"  style="background-color: #f8a4a8; color:white; border-radius: 100%; padding: .50rem .65rem;" *ngIf="isLoggedIn" href="#nav-user-account" class="nav-link" data-bs-toggle="offcanvas" role="button"
          aria-controls="nav-user-account">
          {{initials}}
          </a>
          </li>

          <li class="nav-item"  *ngIf="features['deals_&_beauty_bag'] == true">
            <a [routerLink]="['/beauty-bag-checkout']" class="nav-link link-primary nav-cart">
              <i class="fak fa-beauty-bag fa-lg fa-fw"></i>
              <span class="counter" *ngIf="cartCount > 0">{{cartCount}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

  </div>

  <!-- Parlon inner sg -->
  <div *ngIf="selectedCountry=='Singapore' && parlon_inner" class="row w-100 flex-wrap flex-shrink-0 p-0 container-fluid d-md-none">
    <div class="grid-container-sg w-100 py-3 px-4">
      <div class="d-flex" style="margin-left: -1em; margin-right:.4em">
        <ul *ngIf="!navEmpty" class="nav navbar-nav nav-mobile nav-start" >
          <li class="">
            <a href="#nav-drawer" id="nav-burger" class="nav-link link-primary" data-bs-toggle="offcanvas"
              role="button" aria-controls="nav-drawer">
              <i class="far fa-bars fa-lg fa-fw"></i>
            </a>
          </li>
          
        </ul>
        <div class="grid-item align-items-center ms-2">
          <a [routerLink]="['/']">
            <img src="assets/images/logo-icon.png" alt="" class="img-fluid img-navbar-logo">
          </a>
          
        </div>
      </div>
     

      <div class="d-flex align-items-center" >
        <p class="mb-0 text-parlon-name">{{ parlon.business_name }}</p>
      </div>

    </div>
    
  </div>

  <!-- Parlon Inner -->
  <div *ngIf="selectedCountry=='Philippines' && parlon_inner"  class="container-fluid flex-wrap mobile d-md-none overflow-hidden flex-shrink-0 p-0">
    <div class="grid-container w-100 py-3 px-4">
      <div class="menu-grid-item" *ngIf="!navEmpty">
        <ul class="nav navbar-nav nav-mobile nav-start">
          <li class="">
            <a href="#nav-drawer" id="nav-burger" class="nav-link link-primary" data-bs-toggle="offcanvas"
              role="button" aria-controls="nav-drawer">
              <i class="far fa-bars fa-lg fa-fw"></i>
            </a>
          </li>
        </ul>
      </div>

      <div class="grid-item align-items-center">
        <a [routerLink]="['/']">
          <img src="assets/images/logo-icon.png" alt="" class="img-fluid img-navbar-logo">
        </a>
        
      </div>
      <div class="text-parlon-div" >
        <p class="mb-0 text-parlon-name">{{ parlon.business_name }}</p>
      </div>
      <div class="d-flex" *ngIf="!navEmpty" style="justify-self: end;">
        <ul class="nav navbar-nav nav-mobile nav-end">

          <li class="nav-item"  *ngIf="features['registration'] == true">
            <a *ngIf="!isLoggedIn" href="#nav-user-account" class="nav-link link-primary px-0" data-bs-toggle="offcanvas" role="button"
              aria-controls="nav-user-account" (click)="toggleActive($event,'text-secondary')">
              <i class="fak fa-users fa-lg fa-fw"></i>
            </a>

            <a (click)="updateCandies()"  style="background-color: #f8a4a8; color:white; border-radius: 100%; padding: .50rem .65rem;" *ngIf="isLoggedIn" href="#nav-user-account" class="nav-link" data-bs-toggle="offcanvas" role="button"
          aria-controls="nav-user-account">
          {{initials}}
          </a>
          </li>

          <li class="nav-item ms-2"  *ngIf="features['deals_&_beauty_bag'] == true">
            <a [routerLink]="['/beauty-bag-checkout']" class="nav-link link-primary nav-cart px-0">
              <i class="fak fa-beauty-bag fa-lg fa-fw"></i>
              <span class="counter" *ngIf="cartCount > 0">{{cartCount}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="bg-primary p-2 w-100 px-4">
      <div class="services-location-picker">
        <div class="nav-branch-select">
          <mat-form-field [floatLabel]="'never'" *ngIf="parlon?.branches?.length < 5">
            <mat-select [(value)]="branch['id']" (selectionChange)="onChangeBranch($event.value, branch['id'])">
              <mat-optgroup *ngFor="let area of areas;index as i" [label]="area['location_name']">
                <ng-container *ngFor="let branch of parlon.branches; index as i">
                  <mat-option *ngIf="area['id'] == branch.location.id" [value]="branch['id']">
                    {{ branch.branch_name }}   
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>

          <mat-form-field [floatLabel]="'never'" *ngIf="parlon?.branches?.length >= 5">
            <mat-select class="mat-branch-select" [(value)]="branch.id" (selectionChange)="onChangeBranch($event.value, branch.id)">
              <ngx-mat-select-search [formControl]="branchFilterCtrl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'Not found'"></ngx-mat-select-search>
            
              <mat-optgroup *ngFor="let area of filteredBranches | async; index as i" [label]="area['location_name']">
                <mat-option *ngFor="let b of area['branches']" [value]="b.id">
                {{ b['branch_name'] }}
                </mat-option>
              </mat-optgroup>
              
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="navbar-parlon-contact-info text-white text-center mt-2">
        <p class="small text-contact-phone mb-2">
          <span *ngIf="branch?.contact_numbers?.split(';').length > 0"><i class="fas fa-phone fa-fw me-2"></i></span>
          <ng-container *ngFor="let c of branch.contact_numbers?.split(';'); let i = index">
            <a href="tel:{{ c }}" class="text-white text-nowrap fw-semibold">{{ c }}</a>
            <span *ngIf="i < branch?.contact_numbers?.split(';').length - 1">&nbsp;/&nbsp;</span>
          </ng-container>
        </p>

        <p class="text-branch-address mb-0">{{ branch.address }}</p>
      </div>
    </div>

    <div class="parlon-tabs">
      <nav-tabs [googleReviewVisible]="showGoogleReviewTab"></nav-tabs>
    </div>

  </div>
</nav>