<div class="p-3">
<mat-dialog-content class="mat-typography" class=" d-flex flex-column justify-content-center align-items-center">
  <i class="fas fa-circle-check fa-4x fa-fw mb-6" style="color: #46b0a9;" ></i>
  <h2>You now have a new password!</h2>
  <h3 class="text-center mt-2">Sign in and enjoy the Parlon life.</h3>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- <button mat-button mat-dialog-close>Cancel</button> -->
  <button mat-stroked-button color="secondary" style="font-weight: bold;" [mat-dialog-close]="true" >Explore Parlon</button>  
</mat-dialog-actions>
</div>