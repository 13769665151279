<!--
	NOTE:
	- (Optional) Add `flickity-overflow` class to `flickity` to show overflowing slides.
	- To set number of slides, change it on CSS
	See example below.
-->


<section class="py-6 py-md-7 overflow-hidden">
	<div class="container">
		<h2 class="h2 fw-semibold mb-5">{{data['title']}}</h2>
	</div>

	<div class="container px-0 px-sm-4">
		<div id="flickity-ad-banners-{{index}}" class="flickity flickity-ad-banners flickity-overflow row gx-4">
			<div class="slide" *ngFor="let ad of data['ads']">
				<a href="{{ad['linkout']}}">
					<div class="ratio ratio-ad-banner">
						<img src="{{resource + ad['file_path']}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">		
					</div>
				</a>
			</div>
		</div>
	</div>
</section>