import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-loader',
  templateUrl: './inner-loader.component.html',
  styleUrls: ['./inner-loader.component.scss']
})
export class InnerLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
