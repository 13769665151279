import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'parlon-inner-loader',
  templateUrl: './parlon-inner-loader.component.html',
  styleUrls: ['./parlon-inner-loader.component.scss']
})
export class ParlonInnerLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
