import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileSearchComponent } from './mobile-search/mobile-search.component';

import { MatAutocompleteModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from '../../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonElementsModule } from '../../common';
import { NgxPaginationModule } from 'ngx-pagination';
import {RouterModule} from '@angular/router';
// import { SafeHtmlPipe} from './safehtml.pipe';

@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,NgxMatSelectSearchModule, MatAutocompleteModule,MaterialModule,FormsModule,ReactiveFormsModule,
    NgxPaginationModule,RouterModule
  ],
  declarations: [MobileSearchComponent]
})
export class MobileSearchModule { }
