<!-- Use `*ngIf` to toggle view -->

<section class="py-7 py-md-8" *ngIf="!isSuccess">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-7 col-xl-6">
				<div class="text-center">
					<img src="assets/images/logo-vertical.svg" alt="" class="img-fluid img-parlon-logo d-block d-md-none mx-auto mb-6">
					<img src="assets/images/logo-inline.svg" alt="" class="img-fluid img-parlon-logo d-none d-md-block mx-auto mb-6">

					<h1 class="h1 fw-semibold mb-4">Reset your password</h1>

					<p>Please enter your new password.</p>
				</div>

				<form [formGroup]="form" (ngSubmit)="onSubmit()">
					<mat-form-field appearance="fill">
						<mat-label>New password</mat-label>
						<input matInput placeholder="New password"  formControlName="password" [type]="hidePasswordIcon ? 'password' : 'text'">

						<button (click)="hidePasswordIcon = !hidePasswordIcon" type="button" mat-icon-button matSuffix>
							<mat-icon>{{hidePasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Confirm new password</mat-label>
						<input matInput placeholder="Password" formControlName="confirm_password" [type]="hideConfirmPasswordIcon ? 'password' : 'text'">
						<button (click)="hideConfirmPasswordIcon = !hideConfirmPasswordIcon" type="button" mat-icon-button matSuffix>
							<mat-icon>{{hideConfirmPasswordIcon ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>

					<div class="mb-2">
						<mat-error *ngFor="let error of errorsValidation">
							<span *ngIf="error=='The password confirmation does not match.' ">
								The passwords do not match. Please try again.
							</span>
							<span *ngIf="error!='The password confirmation does not match.' ">
								{{error}}	
							</span>

												
						</mat-error>
					</div>

					<button [disabled]="!form.valid"  mat-flat-button color="secondary" class="btn-submit" type="submit">
						Create new password
					</button>
				</form>
			</div>
		</div>
	</div>
</section>

<section class="py-9" *ngIf="isSuccess">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-7 col-xl-6">
				<div class="text-center">
					<i class="fas fa-circle-check fa-4x fa-fw text-success mb-6"></i>

					<h3 class="h3 fw-semibold mb-4">Password changed successfully!</h3>
					<p>Your password has been successfully reset. Click below to log in again.</p>
				</div>

				<button href="#nav-drawer" data-bs-toggle="offcanvas"  mat-flat-button color="secondary" class="btn-submit">
					Sign in
				</button>
			</div>
		</div>
	</div>
</section>