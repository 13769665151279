import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { SuccessComponent } from './components/success/success.component';
@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordComponent implements OnInit, OnDestroy {

  constructor(private route:ActivatedRoute,
    private fb: FormBuilder, 
    private router:Router,
    private dialog:MatDialog,
    private userService:UserAccountService) { }
   

  form:FormGroup = this.fb.group({
    password:['', [Validators.required, Validators.minLength(8)]],
    confirm_password:['', Validators.required],
    email:['', [Validators.required, Validators.email]],
    api_token:['', Validators.required]
  });

  $subscription:Subscription|undefined;

  hidePasswordIcon:boolean = true;
  hideConfirmPasswordIcon:boolean = true;

  get password(){return this.form.get('password')}
  get confirm_password(){return this.form.get('confirm_password')}
  get api_token(){return this.form.get('api_token')}
  get email(){return this.form.get('email')};

  errorsValidation = [];
  
  isSuccess:boolean = false;

  ngOnInit() {  
    this.$subscription = this.route.queryParams.subscribe(data=>{
      this.form.get('email').setValue(data.email);
      this.form.get('api_token').setValue(data.token);
    })
  }

  ngOnDestroy(): void {
    if(this.$subscription){
      this.$subscription.unsubscribe();
    }
  }

  onSubmit(){
    if (this.form.invalid) {
      return;
    }

    let json ={email:this.email.value,
      token:this.api_token.value,
      password: this.password.value,
      password_confirmation: this.confirm_password.value
    };


    this.userService.resetPassword(json).subscribe((data:any)=>{
      if(data.success==true){
        // this.isSuccess = true;
        const dialogRef =this.dialog.open(SuccessComponent,{disableClose:true})
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/']);
        });
      }else{
        
        if(data.message=="Invalid reset password link"){
          this.errorsValidation = ['Invalid reset password link']
        }else{
          this.errorsValidation = data.data;
          this.errorsValidation = Object.keys(this.errorsValidation).map((item)=>{
            return this.errorsValidation[item]
          });
        }
      }

    }, error=>{
    })


  }

}
