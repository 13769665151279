import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  @Input() data;
  @Input() index:any;

  resource = environment.resource;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      const el = this.el.nativeElement;

      // let elem = el.querySelector('.flickity-bookings');

      let elem = el.querySelector('#flickity-bookings-'+this.index);
      let flkty = new Flickity(elem, {
        autoPlay: 3500,
        adaptiveHeight: true,
        contain: true,
        pageDots: false,
        pauseAutoPlayOnHover: false,
        prevNextButtons: false,
        selectedAttraction: 0.1,
        friction: 0.5
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }
}
