import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSiemaOptions } from 'ngx-siema';
import { RestService } from '../../../../../../services/rest.service';
import { NgxSiemaService } from 'ngx-siema';
import * as moment from 'moment';

@Component({
  selector: 'glife-booking-slider',
  templateUrl: './booking-slider.component.html',
  styleUrls: ['./booking-slider.component.scss']
})
export class BookingSliderComponent implements OnInit {

  isLoaded = false;
  booking_dates = [];
  booking_time: any = [];
  selected_date = '';
  selected_time = '';
  @Output() bookingDetails = new EventEmitter();

  @Input() branch: any;

  constructor(
    private rest: RestService,
    private ngxSiemaService: NgxSiemaService
  ) { }

  ngOnInit() {
    this.branch['booking_dates'].forEach(e => {
      let newDate = new Date(e);
      this.booking_dates.push(newDate);
    });
  }

  options: NgxSiemaOptions = {
    selector: '.siema-deals',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 4,
      768: 4,
      // 1280: 5
    },
    startIndex: 0,
    draggable: false,
    threshold: 20,
    loop: false,
    onInit: () => {
      // runs immediately after first initialization
    },
    onChange: () => {
      // runs after slide change
    },
  };

  prev() {
    this.ngxSiemaService.prev(1)
      .subscribe((data: any) => data);
  }
 
  next() {
    this.ngxSiemaService.next(1)
      .subscribe((data: any) => data);
  }

  selectDate(date) {
    let d = new Date(date);
    let date_string = moment(date).format('MM/DD/YYYY');
    let app = this;
    this.selected_time = '';
    this.selected_date = date_string;
    this.bookingDetails.emit({
      booking_date: this.selected_date,
      booking_time: this.selected_time
    });
    let postData = {
      branch: this.branch['slug'],
      date: date_string,
      first: true
    }
    app.rest.glifeBookingTime(postData).subscribe((data: {}) => {
      app.booking_time = data;
    });
  }

  selectTime(time) {
    this.selected_time = time;
    this.bookingDetails.emit({
      booking_date: this.selected_date,
      booking_time: this.selected_time
    });
  }


}
