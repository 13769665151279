<div class="branch-select">
  <mat-form-field appearance="standard" [floatLabel]="'never'" *ngIf="parlon.branches?.length < 5">
    <mat-select class="mat-branch-select" [(value)]="branch.id" (selectionChange)="onChange($event.value, branch.id)">
      <mat-optgroup *ngFor="let area of areas;index as i" [label]="area['location_name']">
        <ng-container *ngFor="let branch of parlon.branches; index as i">
          <mat-option *ngIf="area['id'] == branch.location.id" [value]="branch.id">
            {{ branch.branch_name }}   
          </mat-option>
        </ng-container>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard" [floatLabel]="'never'" *ngIf="parlon.branches?.length >= 5">
    <mat-select class="mat-branch-select" [(value)]="branch.id" (selectionChange)="onChange($event.value, branch.id)">
      <ngx-mat-select-search [formControl]="branchFilterCtrl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'Not found'"></ngx-mat-select-search>

      <mat-optgroup *ngFor="let area of filteredBranches | async; index as i" [label]="area['location_name']">
        <mat-option *ngFor="let branch of area['branches']" [value]="branch.id">
          {{ branch['branch_name'] }}
        </mat-option>
      </mat-optgroup>
      
    </mat-select>
  </mat-form-field>
</div>
