import { Component, OnInit , Inject} from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../../services/shared.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-parlon-press',
  templateUrl: './parlon-press.component.html',
  styleUrls: ['./parlon-press.component.scss']
})
export class ParlonPressComponent implements OnInit {

  constructor(private rest: RestService, 
    private route: ActivatedRoute,
    private _shared: SharedService, 
    public ngxSmartModalService: NgxSmartModalService, 
    public router: Router) { }

  branch: any[];
  parlon: any[];
  resourceLink = this.rest.resourceLink();

  ngOnInit() {
    this._shared.currentBranch.subscribe((branch) => {
      this.branch = branch;
    });

    this._shared.currentParlon.subscribe((parlon) => {
      this.parlon = parlon;
    });
  }

}
