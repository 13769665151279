import { Component, OnInit , Inject} from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../../services/shared.service';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'parlon-promos',
  templateUrl: './parlon-promos.component.html',
  styleUrls: ['./parlon-promos.component.scss']
})
export class ParlonPromosComponent implements OnInit {

  constructor(private rest: RestService, private route: ActivatedRoute, private _shared: SharedService, public router: Router, private _lightbox: Lightbox) { }

  branch: any[];
  parlon: any[];
  private _albums: any = [];
  resourceLink = this.rest.resourceLink();

  ngOnInit() {
    this._shared.currentBranch.subscribe((branch) => {
      this.branch = branch;
      const page = this;
      if(this.branch['covidSafety']) {
        this.branch['covidSafety']['images'].forEach(e => {
          const album = {
              src: this.resourceLink + e['image'],
              caption: '',
              thumb: this.resourceLink + e['image'],
          };
          page._albums.push(album);
        });
      }
    });

    this._shared.currentParlon.subscribe((parlon) => {
      this.parlon = parlon;
    });
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

}
