import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleReviewService } from '../../../../services/google-review/google-review.service';

@Component({
  selector: 'app-parlon-google-review',
  templateUrl: './parlon-google-review.component.html',
  styleUrls: ['./parlon-google-review.component.scss']
})
export class ParlonGoogleReviewComponent implements OnInit {

  constructor(private googleReviewService:GoogleReviewService,
    private router:Router,
    private activatedRoute:ActivatedRoute) { }
  slug = "";
  googleReviews:any[] = [
    // {
    // backgroundColor:'#416729',
    // name:'Hanica Cabato',
    // starCount: 4,
    // dateReviewed:'6 months ago',
    // reviewCount:1,
    // reviewText:'Great service by Ms. Jo and Ana. First time to have my nails cleaned here. The place is clean and has a nice ambience. Recommended and would definitely coming back.',
    // photos:['https://ik.imagekit.io/parlon/Branch%20Photos/1085/Copy%20of%20FullSizeRender%2017.jpeg',
    // 'https://ik.imagekit.io/parlon/Branch%20Photos/1085/Copy%20of%20FullSizeRender%2012.jpeg',
    // 'https://ik.imagekit.io/parlon/Branch%20Photos/1085/Copy%20of%20FullSizeRender%2012.jpeg',
    // 'https://ik.imagekit.io/parlon/Branch%20Photos/1085/Copy%20of%20FullSizeRender%2012.jpeg',
    // 'https://ik.imagekit.io/parlon/Branch%20Photos/1085/Copy%20of%20FullSizeRender%2012.jpeg',
    // 'https://ik.imagekit.io/parlon/Branch%20Photos/1085/Copy%20of%20FullSizeRender%2012.jpeg']
    // },
    // {
    //   backgroundColor:'#3B877B',
    //   name:'Kris Cee',
    //   starCount: 5,
    //   dateReviewed:'5 months ago',
    //   reviewCount:2,
    //   reviewText:'Ma’am Susie, Ate Jo and Ate Lyn are so so accommodating and provided the best service. It was so quick to book an appointment and they were so flexible when I asked if I can come in earlier than my booked time. Ate Jo and Ate Lyn were able to provide suggestions on what services to get and I’m super happy with the results. Definitely coming back again! Thank you so much Buffed staff. 🥰❤️',      
    //   }
  ];
  ngOnInit() {
    // console.log(this.activatedRoute.parent.snapshot.data['branch']) ;
    this.slug = this.activatedRoute.parent.snapshot.data['branch']['slug'];
    this.googleReviewService.getGoogleReviews(this.slug).subscribe((data:any)=>{

      console.log(data);
      // debugger;

      if(data.reviews.data && data.reviews.data.length>0){
        this.googleReviews = data.reviews.data.map((item)=>{
          return {
            backgroundColor: "#3B877B",
            name: item.name,
            starCount: item.star_rating,
            reviewText: item.original_comment,
            dateReviewed:item.date_posted
          }
        });
        
      }else{
        const currentUrl = window.location.href;

        // Get the base URL dynamically
        const baseUrl = window.location.origin;

        // Extract the relative path without the base URL
        const relativePath = currentUrl.replace(baseUrl, '');

        // Check if "google-review" is present in the relative path
        if (relativePath.includes('google-review')) {
          // Replace "google-review" with "services" in the relative path
          const newRelativePath = relativePath.replace('google-review', 'services');

          // Navigate to the updated route using Angular's router
          this.router.navigateByUrl(newRelativePath);
        }
        
      }   
    })
  }

}
