import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../../../services/rest.service';

@Component({
  selector: 'deal-card-recommendation',
  templateUrl: './deal-card-recommendation.component.html',
  styleUrls: ['./deal-card-recommendation.component.scss']
})
export class DealCardRecommendationComponent implements OnInit {

  constructor(
    private rest: RestService, 
  ) { }

  resourceLink = this.rest.resourceLink();
  @Input() deal: any = [];

  ngOnInit() {
  }

}
