<section *ngIf="isLoaded == true">
	<div class="d-grid grid-splash">
		<div class="cell cell-content">
			<div class="container" *ngIf="isSuccess == true">
				<div class="row justify-content-center">
					<div class="col-11 col-sm-10 col-md-7 col-lg-6 col-xl-5">
						<div class="ratio ratio-1x1 mb-6">
							<img src="{{resource + ogContent['og_signup_success_image']}}" alt="" loading="lazy" class="img-fluid obj-fit-contain">
						</div>


					</div>

					<div class="col-12"></div>

					<div class="col-12 col-sm-11 col-md-8 col-lg-7 col-xl-6">
						<h1 class="h1 fw-semibold text-center mb-4">Welcome to the club, {{data['user']['first_name']}}!</h1>
						<p class="text-center">{{ogContent['og_signup_success_copy']}}</p>
					</div>

					<div class="col-12"></div>

					<div class="col-12 col-sm-11 col-md-auto">
						<div class="d-flex mt-8 justify-content-center">
							<!-- <div>
								<button mat-flat-button color="secondary" class="btn-submit">Invite my Besties to be a Parlon OG</button>
								<p class="small text-center mt-2 mb-md-0">Get 100 Parlon Candies for every successful invite</p>
							</div> -->

							<div>
								<button [routerLink]="['/og-perks']" mat-stroked-button color="secondary" class="btn-submit">Show me Parlon OG Perks</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container" *ngIf="isFailed == true">
				<div class="card-body p-md-6 p-lg-8">
					<div class="text-center mb-6">
						<h2 class="h2 fw-semibold mb-6">Oh No!</h2>
						<div class="row">
							<div class="col-md-8 offset-md-2">
								<div class="text-center">
									<p class="mb-6">We were unable to charge your account for this transaction, bestie. Kindly double check your details or choose another payment method. Then, try again.</p>
								</div>
							</div>
						</div>

						<button mat-raised-button color="secondary" routerLink="/be-an-og-member">Back to OG Form</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
