import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ContentService } from '../../services/content/content.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class BeautyBashTeaserGuard implements CanActivate {
  constructor(private router: Router,private ContentService: ContentService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Promise<boolean>(resolve => {
        this.ContentService
        .getContentGlobal()
        .toPromise()
        .then((res) => {
          let currentDate = moment();
          let from = res['data']['bash_event']['from'];
          let to = res['data']['bash_event']['to']
          
          if(currentDate.isBetween(from, to)) {
            this.router.navigate(["/beauty-bash/deals"]);
            resolve(true);
          } else if(currentDate.isBefore(from)) {
            resolve(true);
            
          } else {
            this.router.navigate(["/"]);
            resolve(false); 
          }
       })
       .catch(() => {
         this.router.navigate(["/"]);
         resolve(false);
        });
     });
  }
}
