<!--
	NOTE:
	- To edit deal savings banner's background and text color, use `--deal-card-savings-banner-bg` and `--deal-card-savings-banner-color`; set it on the section where it's included.
-->


<div class="card card-deal-template" [ngClass]="templateClass" [ngStyle]="getCardStyle()" >
	<div class="card-body pos-relative p-0">
		<a href="/beauty-bag-deals/{{data['slug']}}" class="stretched-link" [attr.data-pid]="data['deal_id']">
			<div class="ratio ratio-16x9 mt-3 mt-sm-4 mb-3">
				<img src="{{resource + data['primary_photo']}}?tr=w-{{width}}" alt="" loading="lazy" class="img-fluid obj-fit-cover">

				<!-- Remove this part if used in Featured Merchant Deals section -->
				<div class="deal-parlon-logo">
					<div class="ratio ratio-1x1">
						<img src="{{resource + data['parlon_logo']}}?tr=w-72" alt="" loading="lazy" class="img-fluid obj-fit-contain">
					</div>
				</div>
			</div>
		</a>

		<div class="content px-2 px-sm-3">
			<p class="deal-parlon small text-muted w-md-80 mb-0">{{parlon_name}}</p>
			<p class="deal-parlon-location smaller text-muted clamp-1 mb-1">
				<i class="fas fa-location-dot fa-fw me-1"></i>
				<span *ngFor="let location of locations; let i = index">{{location}}<ng-container *ngIf="i != locations.length - 1">, </ng-container> </span></p>
			<p class="deal-name fs-6 fw-medium clamp-3 clamp-md-5 mb-5">{{data['deal_name']}}</p>

			<p class="deal-price fw-medium">₱ {{data['deal_price'] | number}}<span class="og-price ms-2">₱ {{data['orig_price'] | number}}</span></p>
		</div>


		<div class="deal-savings-banner" [ngStyle]="getRibonStyle()">
			<p *ngIf="data['discount_type'] == 'Percentage Discount'">{{ data['discount_value'] | number}}% OFF</p>
			<p *ngIf="data['discount_type'] == 'Value Discount'">Save ₱{{ data['discount_value'] | number }}</p>
		</div>
	</div>
</div>