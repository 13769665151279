<div class="p-5 text-center bg-light-1">
  <img src="assets/images/calendar-icn.svg" alt="" width="40">
  <h3 class="fw-semibold">Book Deals</h3>
  <p>{{description}}</p>
  <button mat-stroked-button color="secondary" (click)="showModal()" >
    Select a deal to book an appointment
  </button>
</div>

<ngx-smart-modal #dealsModal identifier="dealsModal" [dismissable] = "false">
	<ng-container *ngIf="dealsModal.hasData()">
		<div class="modal-header border-0 p-2 pt-4">
			<h5 class="modal-title">
				Select deal/s to book an appointment
			</h5>
		</div>
		<div class="modal-body p-2">
			<div class="row gx-5 mb-4">
				<div class="col-12 d-flex align-items-center">

					<table class="table table-striped table-borderless services mtt-5">
            <tbody>
              <tr *ngFor="let deal of dealsModal.getData().deals" class="p-3">
                <td>
                  <div class="d-flex align-items-center justify-content-between">
                    <span>{{deal['name']}}</span>
                    <div class="service-select ms-3" [ngClass]="{active: deal['selected']}" (click)="selectDeal(deal)">
                      <span *ngIf="deal['selected']; else notSelected" >
                        <i class="far fa-check"></i>
                    </span>
                    <ng-template #notSelected>
                        <span>
                            <i class="far fa-plus"></i>
                        </span>
                    </ng-template>
                      <!-- <i class="far fa-check" *ngIf="deal['selected']"></i>
                      <i class="far fa-plus" *ngIf="!deal['selected']"></i> -->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
			</div>

			<div class="row mt-6">
				<div class="col text-center">
					<button mat-raised-button color="secondary" (click)="confirm()" data-bs-dismiss="modal" class="text-uppercase w-100">CONFIRM SELECTION</button>
          <a mat-button class="text-secondary mt-4" (click)="dealsModal.close()">CANCEL</a>
				</div>
			</div>
		</div>
	</ng-container>
</ngx-smart-modal>