import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'deal-card-recommendation',
  templateUrl: './deal-card-recommendation.component.html',
  styleUrls: ['./deal-card-recommendation.component.scss']
})
export class DealCardRecommendationComponent implements OnInit {

  @Input() deal: any = [];
  resource = environment.resource;

  constructor() { }

  ngOnInit() {
  }

}
