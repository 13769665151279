<div class="row row-parlon-details justify-content-center g-4" >
	<div class="col-6 col-md-5 col-lg-12 col-logo align-self-start align-self-lg-start">
		<div class="mb-md-3 square-container" >
			<img src="{{ resourceLink + data['logo'] }}" alt="{{ data['logo_alt'] }}" title="{{ data['logo_title'] }}"
				class="img-fluid obj-fit-contain square-image">
		</div>
	</div>

	<div class="col-6 col-details d-lg-none mb-6" >
		<h3 class="h3  fw-bold mb-1">{{ data['business_name'] }}</h3>
		<div class="mt-2 d-flex align-items-center" *ngIf="showReview">	
			<div class=" d-flex align-items-start star-count" >			
				{{starCount |number : '1.1-1' }}					
			</div>							
			<div class="star-rating ms-1">
				<span
				  class="star"
				  [class.full]="starCount >= 0.8"
				  [class.half]="starCount >= 0.3 && starCount < 0.8"
				></span>
				<span
				  class="star"
				  [class.full]="starCount >= 1.8"
				  [class.half]="starCount >= 1.3 && starCount < 1.8"
				></span>
				<span
				  class="star"
				  [class.full]="starCount >= 2.8"
				  [class.half]="starCount >= 2.3 && starCount < 2.8"
				></span>
				<span
				  class="star"
				  [class.full]="starCount >= 3.8"
				  [class.half]="starCount >= 3.3 && starCount < 3.8"
				></span>
				<span
				  class="star"
				  [class.full]="starCount >= 4.8"
				  [class.half]="starCount >= 4.3 && starCount < 4.8"
				></span>
			</div>			
		</div>

		<ul class="list-unstyled list-parlon-contacts" style="margin-bottom: 0;">
			<li class="d-flex flex-row flex-wrap" *ngIf="contact.length > 0">
				<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem; margin-left: 0;">
					<i class="fas fa-square text-secondary"></i>
					<i class="fas fa-phone text-white" data-fa-transform="shrink-7" style="margin: auto;"></i>
				</span>
				
				<div>
					<div *ngFor="let c of contact">
						<a href="tel:{{ c }}" class="link-secondary text-decoration-underline-thin fw-medium">{{ c }}</a>
					</div>
				</div>
			</li>

			<li class="d-flex flex-row flex-wrap" *ngIf="branch['establishment'] != null">
				<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
					<i class="fas fa-square text-secondary"></i>
					<i class="fas fa-building text-white" data-fa-transform="shrink-7"></i>
				</span>
				
				<div>
					<p class="mb-0">{{ branch['establishment']['name'] }}</p>
				</div>
			</li>

			<li class="d-flex flex-row flex-wrap" *ngIf="branch['establishment'] != null">
				<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
					<i class="fas fa-square text-secondary"></i>
					<i class="fas fa-city text-white" data-fa-transform="shrink-7"></i>
				</span>
				
				<div>
					<p class="mb-0">{{ branch['neighborhood']['name'] }}</p>
				</div>
			</li>
		</ul>

		<div class="d-flex mb-3 mt-2">
			<span class="fa-layers fa-fw fa-lg me-1" style="margin-top: .125rem;">
				<i class="fas fa-square text-secondary"></i>
				<i class="far fa-map-marker-alt text-white" data-fa-transform="shrink-7"></i>
			</span>
			<p class="mb-0">{{ branch['location']['location_name'] }}</p>
		</div>

		

		

		<!-- <div class="mb-2">
			{{ branch['address'] }}
		</div> -->
			

		<div class="row gx-4">
			<div class="col-auto" *ngIf="branch['waze_link'] !== ''">
				<div class="ic-location-container">
					<a href="{{ branch['waze_link'] }}" target="_blank">
						<img src="assets/images/waze-ic.png" alt="Waze" class="img-fluid">
					</a>
				</div>
			</div>

			<div class="col-auto" *ngIf="branch['gmaps_link'] !== ''">
				<div class="ic-location-container">
					<a href="{{ branch['gmaps_link'] }}" target="_blank">
						<i class="fas fa-map fa-lg fa-fw text-white"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>