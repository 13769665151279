<div class="bb-checkout-container" *ngIf="isLoaded == true">
  <div class="container py-3 mt-4" *ngIf="(deals$ | async).length > 0">
    <div class="row row-main-layout gx-lg-8 gy-6 gy-lg-0">
      <div class="col-12 col-xl-8 col-content content">

        <h2 class="h3 fw-semibold mb-5">Beauty Bag</h2>

        <ng-container *ngFor="let deal of deals$ | async" class="mb-4">
          <div class="deal-container">
            <div class="d-flex mb-1">
              <div>
                <a href="/beauty-bag-deals/{{deal['slug']}}">
                  <img src="{{resource + deal['image']}}" class="deal-img">
                </a>
                <p class="mt-4 mb-0"><a class="details deal-{{deal['id']}}" (click)="toggleSection('deal-'+deal['id'])">Redeemer<span *ngIf="deal['redeemers'].length > 1">s</span>
                  &nbsp;<i class="fa-solid fa-caret-up caret"></i></a></p>
              </div>
              <div class="mx-4 mb-3">
                <p class="fw-semibold mb-0 deal-name">{{deal['name']}}</p>
                <p class="text-muted mb-2">{{deal['parlon']}}</p>
                <p class="mb-0"><del>₱{{deal['original_price'] | number}}</del></p>
                <p class="mb-0">₱{{deal['deal_price'] | number}} x {{deal['redeemers_count']}} =  ₱{{deal['total_price'] | number}}</p>
              </div>
            </div>
            <div class="details-container deal-{{deal['id']}}">
              <div class="d-flex justify-content-between mb-2" *ngFor="let redeemer of deal['redeemers']; let i = index;">
                <div class="redeemer-details">
                  <div>
                    <span class="redeemer-pill text-muted">
                      <i class="fa-regular fa-user"></i> {{i+1}}
                    </span>
                  </div>
                  <div class="">
                    <p class="text-muted mb-0">{{redeemer['name']}}</p>
                    <p class="text-muted mb-0">{{redeemer['email']}} / {{redeemer['mobile_number']}}</p>
                    <p class="text-muted mb-0">{{redeemer['branch']}}</p>
                  </div>
                </div>
                <div>
                  <a (click)="showEditModal(deal,redeemer)" class="mx-2" *ngIf="deal['buying_for'] == 'se'" [class.disabled]="loadingUpdate == true">
                    <i class="fas fa-edit"></i>
                  </a>
                  <a (click)="deleteItem(deal,redeemer)" class="mx-2" [class.disabled]="loadingUpdate == true">
                    <i class="fa-regular fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <bag-payment [data]="response" [promoCodeLoading]="promoCodeLoading"  (candyEvent)="applyCandies($event)" (promoCodeEmit)="applyCode($event)"></bag-payment>


      </div>

      <div class="col-12 col-xl-4 col-sidebar d-none d-lg-block">
        <div id="sidebar" class="sidebar">
          <div class="sidebar__inner">
            <bag-details [data]="response['summary']"></bag-details>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-3 mt-4" *ngIf="(deals$ | async).length < 1">
    <div class="row row-main-layout gx-lg-8 gy-6 gy-lg-0">
      <div class="col-12 col-xl-8 col-content content">
        <h2 class="h3 fw-semibold mb-3">Beauty Bag</h2>
        <p>Your beauty bag is currently empty. <a [routerLink]="['/all-parlon-deals']"><u>Start
              shopping now! <i class="fa-solid fa-arrow-right"></i></u></a>
        </p>
      </div>
    </div>
    <div class="pb-6">
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-md-4 gy-6">
          <div class="col" *ngFor="let deal of randomdeals; let i = index ">
            <deal-card-template
              [data]="templateCardFormat(deal)"
              [parlon_name]="deal['parlon']['business_name']"
              [locations]="getLocations(deal['parlon']['distinct_locations'])">
            </deal-card-template>
          </div>
      </div>
  </div>
  </div>
</div>

<ngx-smart-modal #editModal identifier="editModal" [dismissable]="false">
  <ng-container *ngIf="editModal.hasData()">
    <div class="modal-body p-2">
      <div class="mb-5 mb-lg-0">
        <h3 class="h3 fw-semibold mb-4">
          Redeemer Details
        </h3>

        <form [formGroup]="editForm">

          <div class="mb-2" formGroupName="RedeemerFormGroup">
            <p class="fs-6 fw-medium mb-5">Name of Redeemer</p>

            <div class="card mb-3">
              <div class="card-body gray-bg">
                <div class="row gx-0 mb-2">
                  <div class="col-auto pe-3">
                    <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                  </div>

                  <div class="col">
                    <p class="small text-muted mb-0">
                      <span class="fw-semibold">You cannot change the name after successful purchase.</span><br />
                      Person who will redeem the package should present any valid Government-issued ID upon redemption.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <mat-form-field appearance="fill" class="w-100 neutral">
              <mat-label>First name</mat-label>
              <input matInput placeholder="First Name*" name="first_name"
                formControlName="first_name" required>
              <!-- <mat-error *ngIf="f.first_name.errors?.required">The first name field is required.</mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100 neutral">
              <mat-label>Last name</mat-label>
              <input matInput placeholder="Last Name*" name="last_name"
                formControlName="last_name" required>
              <!-- <mat-error *ngIf="f.last_name.errors?.required">The last name field is required.</mat-error> -->
            </mat-form-field>

            <div class="mt-3 mb-6" *ngIf="editModal.getData().deal['buying_for'] != 'me'">
              <mat-checkbox color="primary" [value]="true"
                formControlName="surprise">
                This is a surprise. Please don’t email and text the redeemer. Send e-voucher and sms to me.
              </mat-checkbox>
            </div>
          </div>

          <div formGroupName="RedeemerFormGroup" *ngIf="editForm.controls.RedeemerFormGroup.controls.surprise.value == false">
            <p class="fs-6 fw-medium mb-2">Email address</p>

            <div class="card mb-3">
              <div class="card-body gray-bg">
                <div class="row gx-0 mb-2">
                  <div class="col-auto pe-3">
                    <i class="fal fa-info-circle fa-lg fa-fw text-muted"></i>
                  </div>

                  <div class="col">
                    <p class="small text-muted mb-0">
                      <span class="fw-semibold">E-mail is very important!</span><br />
                      You cannot change the email after successful purchase. You will receive the E-Voucher through the
                      email that you entered. Please double-check the email that you entered.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <mat-form-field appearance="fill" class="w-100 neutral">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" id="email" name="email"
                formControlName="email" required >
              <!-- <mat-error *ngIf="f.email?.errors">
                <div *ngIf="f.email.errors?.required">The email field is required.</div>
                <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
              </mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100 neutral">
              <mat-label>Re-enter your email address</mat-label>
              <input matInput placeholder="Re-enter e-mail address (just to be sure!)*" id="re_enter_email"
                name="re_enter_email" formControlName="re_enter_email" required>
              <!-- <mat-error *ngIf="f.email?.errors">
                <div *ngIf="f.re_enter_email?.errors.required">The email field is required.</div>
                <div *ngIf="f.re_enter_email?.errors.email">Email must be a valid email address</div>
              </mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100 neutral">
              <mat-label>Your mobile number</mat-label>
              <input type="number" matInput placeholder="Your mobile number *" name="contact_number"
                (keypress)="numberOnly($event)" formControlName="contact_number"
                required>
              <!-- <mat-error *ngIf="f.contact_number.errors?.required">The contact number field is required.</mat-error> -->
            </mat-form-field>
          </div>
        </form>
        <div class="d-grid">
          <button mat-flat-button color="secondary" class="p-3" type="submit" [disabled]="loadingUpdate" (click)="updateItem()"
            [disabled]="!editValidity()">
            <span>SAVE DETAILS</span>
            <mat-icon *ngIf="loadingUpdate"><mat-spinner color="accent" diameter="20">
            </mat-spinner></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ngx-smart-modal>