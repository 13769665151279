import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonElementsModule } from '../../common';
import { PageNotFoundComponent } from './page-not-found.component';
import { MaterialModule } from '../../material';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    CommonElementsModule,
    MaterialModule,
    RouterModule
  ],
  declarations: [PageNotFoundComponent]
})
export class PageNotFoundModule { }
