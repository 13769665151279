import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSiemaModule } from 'ngx-siema';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MaterialModule } from '../../material';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BookingSliderComponent } from './components/glife-checkout/components/booking-slider/booking-slider.component';
import { CarouselComponent } from '../../../app/components/carousel/carousel.component';
import { GlifeLandingPageComponent } from './components/glife-landing-page/glife-landing-page.component';
import { GlifeServiceListComponent } from './components/glife-service-list/glife-service-list.component';
import { GlifeCheckoutComponent } from './components/glife-checkout/glife-checkout.component';
import { GlifeCheckoutFormComponent } from './components/glife-checkout/components/glife-checkout-form/glife-checkout-form.component';
import { PersonalDetailsFormComponent } from '../../components/personal-details-form/personal-details-form.component';
import { PromoCodeComponent } from '../../components/promo-code/promo-code.component';
import { ServiceCheckComponent } from '../../components/service-check/service-check.component';
import { CommonElementsModule } from '../../common';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgxSmartModalModule,
    NgxSiemaModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    CommonElementsModule
  ],
  declarations: [ 
    BookingSliderComponent,
    CarouselComponent,
    GlifeLandingPageComponent, 
    GlifeServiceListComponent, 
    GlifeCheckoutComponent,
    GlifeCheckoutFormComponent,
    PersonalDetailsFormComponent,
    PromoCodeComponent,
    ServiceCheckComponent
  ]
})
export class GlifeModule { }
