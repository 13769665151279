<section id="membership-checkpoint">
	<!-- Membership banner -->
	<div>
		<a href="#">
			<img src="{{ resourceLink + parlon['membership_banner_checkout'] }}?tr=w-2560" alt="" class="img-fluid img-hero-banner-desktop w-100">
			<img src="{{ resourceLink + parlon['membership_banner_checkout'] }}?tr=w-1200" alt="" class="img-fluid img-hero-banner-tablet w-100">
			<img src="{{ resourceLink + parlon['membership_banner_checkout'] }}" alt="" class="img-fluid img-hero-banner-mobile w-100">
		</a>
	</div>

  <div class="container py-6 py-md-7">
		<div class="mb-4">
			<a [routerLink]="['/parlon',parlon['slug']]"><i class="far fa-chevron-left fa-fw me-3"></i>Back</a>
		</div>

		<div class="row row-membership-main-layout gx-lg-8 gy-6 gy-lg-0">
			<!-- Main content -->
			<div class="col-12 col-xl-8 col-content content">
				<div class="row align-items-center gx-4">
					<div class="col-12 col-md-5 col-lg-12 order-md-2 d-lg-none">
						<div class="mb-5">
							<div class="ratio ratio-3x2">
								<img src="{{ resourceLink + parlon['membership_image'] }}" alt="{{ parlon['membership_image'] }}" class="img-fluid obj-fit-cover">
							</div>
						</div>		
					</div>

					<div class="col-12 col-md-7 col-lg-12 order-md-1">
						<h1 class="h1 mb-3">Membership Information</h1>
				
						<!-- <p class="text-primary fw-semibold mb-2">
							<i class="far fa-calendar-alt me-1"></i>
							Expiration: <br class="d-md-none">
							{{ parlon['valid_from'] | date: 'MMMM dd, yyyy' }} - {{ parlon['valid_to'] | date: 'MMMM dd, yyyy' }}
						</p> -->
		
						<div [innerHTML]="parlon['membership_description']"></div>
					</div>
				</div>

				<hr class="my-6">

				<!-- Form -->
				<membership-form [parlon]="parlon" (selectedMembership)="selectMembership($event)" (promoCode)="applyCodeHandler($event)"></membership-form>

			</div>

			<!-- Right sidebar -->
			<div class="col-12 col-xl-4 col-sidebar d-none d-lg-block" *ngIf="hasSelected == true">
				<div id="sidebar" class="sidebar">
					<div class="sidebar__inner">
            <membership-info-box [parlon]="parlon" [membership]="selectedMembership" [promo]="activePromo"></membership-info-box>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>